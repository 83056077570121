import { forEach, get } from "lodash";
import * as moment from "moment";
import * as Browser from "detect-browser";

export const defaultEvaluationOptions = {
    hide_video: false,
    hide_personal_details: false,
    disguise_voice: false,
    hide_feedback: false
};

export const eBrandingType = {
    ID: "branding_id",
    IS_DEFAULT: "is_default_for_new_job",
    NAME: "branding_name",
    PROPERTIES: "properties"
};

export const eBrandingItemType = {
    TEXT: "text",
    COLOR: "color",
    IMAGE: "image", // for saving
    IMAGE_FILE: "file", // for file upload
    IMAGE_INPUT: "input" // for input
};

export const eDeadlineType = {
    NONE: "none",
    FIXED: "fixed",
    ONGOING: "ongoing"
};

export const defaultOngoingDeadlineDays = 5;

export const eRecommendationTypes = {
    YES: "yes",
    MAYBE: "maybe",
    NO: "no",
    IN_PROGRESS: "in-progress"
};

export const eQuestionContainerTypes = {
    PRE_INTERVIEW: "preinterview",
    INTERVIEW: "interview"
};

export const eMediaTypes = {
    RECORDING: "recording",
    TRANSCODED: "transcoded",
    AUDIO: "audio",
    DISGUISED_VOICE: "disguised-voice"
};

export const eDecisionTypes = {
    NONE: null,
    ACCEPTED: "accepted",
    DECLINED: "declined"
};

export const eDeadlineDay = {
    YESTERDAY: "yesterday",
    TODAY: "today"
}

export const eCandidateSortTypes = {
    CANDIDATE: "candidate",
    CANDIDATE_FIRST_NAME: "first_name",
    CANDIDATE_LAST_NAME: "last_name",
    EMAIL: "email",
    PHONE: "phone",
    QUESTION_SCORE: "q",
    Q_SCORE: "q_score",
    ATTR_SCORE: "attr_score",
    TOTAL_SCORE: "total_score",
    LAST_INVITE_SENT_ON: "last_invite_sent_on",
    DEADLINE: "deadline",
    RECOMMENDATION: "recommendation",
    RECOMMENDATION_YES: "recommendation_yes",
    RECOMMENDATION_MAYBE: "recommendation_maybe",
    RECOMMENDATION_NO: "recommendation_no",
    EVALUATION_STATUS: "evaluation_status",
    DECISION: "decision",
    INTERVIEW_AT: "interview_at"
};

export const eCandidateTableTypes = {
    NEW: "new",
    INVITED: "invited",
    RESPONDED: "responded",
    EVALUATING: "evaluating",
    LIVE_INTERVIEW: "live-interview",
    LIVE_INTERVIEW_EVALUATING: "live-interview-evaluating"
};

export const eCandidateActionTypes = {
    EDIT: "edit",
    EXTEND: "extend",
    RESEND: "resend"
};

export const eCandidateLiveInterviewType = {
    PROFILE: "profile",
    EVALUATION: "evaluation"
};

export const eEvaluationStatusTypes = {
    FINALISED: "finalised",
    EDITABLE: "editable"
};

export const eDropDownPosition = {
    TOP_LEFT: "top-left",
    TOP_RIGHT: "top-right",
    BOTTOM_LEFT: "bottom-left",
    BOTTOM_RIGHT: "bottom-right"
};

export const eInterviewStepType = {
    MESSAGE: "message",
    QUESTION: "question",
    PRACTICE: "practice"
};

export const eJobDraftSteps = {
    JOB_DETAILS: "Job Details",
    // INTRO_VIDEO: "Intro Video",
    QUESTIONS: "Questions",
    CANDIDATES: "Candidates",
    EVALUATORS: "Evaluators",
    EMAILS: "Emails",
    REVIEW: "Review"
};

export const eJobStageTypes = {
    ONE_WAY: "one-way",
    LIVE: "live"
};

export const eTemplatesType = {
    INTERVIEW: "templates-interview",
    EMAIL: "templates-email",
    SMS: "templates-sms"
};

export const eTemplatePlaceholders = {
    CANDIDATE_INTERVIEW_FIRST_NAME: "{{candidate_interview.first_name}}",
    CANDIDATE_INTERVIEW_DEADLINE: "{{candidate_interview.deadline}}",

    JOB_TITLE: "{{job.title}}",
    JOB_INTERVIEW_TERMINOLOGY: "{{job.interview_terminology}}",

    TENANT_DISPLAY_NAME: "{{tenant.display_name}}",

    LEARN_MORE_LINK: "{{LearnMoreLink}}",
    INTERVIEW_LINK: "{{InterviewLink}}",

    DEVICES_SECTION: "{{DevicesSection}}",
    TECH_REQ_SECTION: "{{TechnicalRequirementsSection}}",
    TECH_SUPPORT_SECTION: "{{TechnicalSupportSection}}",

    // JOB_TITLE_OLD: "%job_title%",
    // CANDIDATE_FIRST_NAME: "%first_name%",
    // COMPANY_NAME: "%company_name%",
    // COMPANY_NAME_2: "%Company_name%",
    // INTERVIEW_CLOSING_DATE: "%interview_closing_date%",
    // EMAIL: "%email%",
    // JOB_LINK: "%job_link%",
    // UNSUBSCRIBE_URL: "%unsubscribe_url%",

    APPLICANT_NAME: "%applicant-name%",
    INTERVIEW_CLOSING_TIME: "%interview-closing-time%",
    COMPANY_NAME_SMS: "%company-name%"
};

export const eSMSTemplatePlaceholders = {
    APPLICANT_NAME: "%applicant-name%",
    INTERVIEW_CLOSING_TIME: "%interview-closing-time%",
    COMPANY_NAME_SMS: "%company-name%"
};

export const EmailInvitationPlaceholders = [
    {id: 'candidate_interview.first_name', label: 'Candidate First Name'},
    {id: 'job.title', label: 'Job Title'},
    {id: 'candidate_interview.deadline', label: "Deadline", invitationOnly: true},
    {id: 'LearnMoreLink', label: "'Learn More' link", invitationOnly: true},
    {id: 'InterviewLink', label: "'Start Interview/Assessment' button", invitationOnly: true},
    {id: 'job.interview_terminology', label: 'interview/assessment'},
    {id: 'TechnicalRequirementsSection', label: 'Technical Requirements section', invitationOnly: true},
    {id: 'DevicesSection', label: 'Devices section', invitationOnly: true},
    {id: 'DevicesNoPhoneSection', label: 'Devices section (no phone)', invitationOnly: true},
    {id: 'DevicesNoPhoneWarningSection', label: 'Devices section (no phone, warning)', invitationOnly: true},
    {id: 'TechnicalSupportSection', label: 'Technical Support section', invitationOnly: true},
    {id: 'NonTechnicalSupportSection', label: 'Non Technical Support section', invitationOnly: true},
    {id: 'tenant.display_name', label: 'Customer Display Name'},
];

export const EmailNonInvitationPlaceholders = EmailInvitationPlaceholders.filter(({ invitationOnly = false }) => !invitationOnly);

export const JOB_STAGES_FIELDS = {
    DEADLINE_TYPE: "deadline_type",
    DEADLINE: "deadline",
    ONGOING_DEADLINE_DAYS: "ongoing_deadline_days",

    INTERVIEW_TEMPLATE_ID: "interview_template_id",
    INTERVIEW_TEMPLATE_NAME: "interview_template_name",
    INTERVIEW_TEMPLATE_IS_REUSABLE: "interview_template_is_reusable",
    JOB_INTERVIEW_STEPS: "job_interview_steps",
    ASK_FOR_FEEDBACK: "ask_for_feedback",
    REQUIRES_RESPONSE_EVALUATION: "requires_response_evaluation",
    EVALUATION_ATTRIBUTES: "evaluation_attributes",
    EVALUATION_ATTRIBUTES_DISPLAY: "evaluation_attributes_display",
    EVALUATION_ATTRIBUTES_ENABLED: "evaluation_attributes_enabled",

    IS_OPEN_REGISTRATION: "is_open_registration",
    SKIP_CV_UPLOAD: "skip_cv_upload",
    CANDIDATES: "candidates",

    EVALUATORS: "evaluators",

    INVITATION_SMS_TEMPLATE_ID: "invitation_sms_template_id",
    REMINDER_SMS_TEMPLATES: "reminder_sms_templates",

    SENDS_INVITATION_EMAIL: "sends_invitation_email",
    SENDS_ACKNOWLEDGEMENT_EMAIL: "sends_acknowledgement_email",
    SENDS_SUCCESSFUL_EMAIL: "sends_successful_email",
    SENDS_UNSUCCESSFUL_EMAIL: "sends_unsuccessful_email",

    INVITATION_EMAIL_TEMPLATE_ID: "invitation_email_template_id",
    ACKNOWLEDGEMENT_EMAIL_TEMPLATE_ID: "acknowledgement_email_template_id",
    SUCCESSFUL_EMAIL_TEMPLATE_ID: "successful_email_template_id",
    UNSUCCESSFUL_EMAIL_TEMPLATE_ID: "unsuccessful_email_template_id",

    // POSITION_DESCRIPTION: "position_description"
};

export const eJobsType = {
    ACTIVE: "active",
    ARCHIVED: "archived",
    DRAFT: "draft"
};

export const eJobsTypeVerb = {
    active: "reactivate",
    archived: "archive"
};

export const eLibraryAssetType = {
    QUESTION: "question",
    MESSAGE: "message"
};

export const eQuestionItemState = {
    ADDING: "adding",
    EDITING: "editing"
};

export const eLibrarySection = {
    QUESTIONS: "questions",
    MESSAGES: "messages"
};

export const eLibraryQuestionType = {
    CUSTOM: "custom",
    STOCK: "stock"
};

export const eQuestionDisplayType = {
    SELECTING: "selecting", // e.g. Job Draft's "add questions" window
    EDITING: "editing", // e.g. customising times, evaluation guide & reordering
    VIEWING: "viewing", // e.g. Job Draft review
    LISTING: "listing", // e.g. Library page
    UPDATING: "updating" // e.g. Extend candidate times
};

export const eQuillToolbarSize = {
    SMALL: 'small',
    MEDIUM: 'medium'
};

export const eUserTypes = {
    EVALUATOR: 'evaluator',
    MANAGER: 'manager'
};

export const eResponseStatus = {
    INITIAL: "",
    SUCCESS: "success",
    ERROR: "error"
};

export const eStatusMessageType = {
    INFO: "info",
    SUCCESS: "success",
    WARNING: "warning"
};

export const eEndEvaluationType = {
    SUBMIT: "submit",
    SAVE: "save"
};

export const eStatusMessageStyle = {
    INLINE: "inline",
    BAR: "bar",
    FIXED_BAR: "fixed-bar"
};

export const eConfirmDialogResponseType = {
    QUESTION: "question",
    SUCCESS: "success",
    ERROR: "error",
    INFO: "info"
};

export const eTextAreaType = {
    STANDARD: "standard",
    RICH: "rich"
};

export const eMediaType = {
    VIDEO: "video",
    TEXT: "text"
};

export const eQuestionType = {
    VIDEO: "video"
    // ... more to come
};

export const eResponseType = {
    VIDEO: "video",
    TEXT: "text",
    DOCUMENT: "document",
    SELECTION: "selection"
};

export const eVerificationType = {
    PASSWORD: "password",
    VERIFICATION_CODE: "email_verification_code",
    OAUTH_ONLY: "oauth_only"
};

export const integrationPartner = {
    JOB_ADDER: "jobadder",
    WORLD_MANAGER: "worldmanager"
};

export const eDateFormatTypes = {
    FULL_WITH_SECONDS_AM_PM: "DD MMM YYYY hh:mm:ss A",
    FULL_WITH_AM_PM : "DD MMM YYYY hh:mm A",
    FULL: "DD MMM YYYY HH:mm",
    SIMPLER: "DD-MMM-YY HH:mm",
    DATE: "YYYY MM DD",
    DATE_DASHED: "YYYY-MM-DD",
    TIME: "hh:mm A"
};

export const eInterviewTerminologies = {
    INTERVIEW: "interview",
    // VIDEO_INTERVIEW: "video interview",
    ASSESSMENT: "assessment",
    APPLICATION: "application",
    INTRODUCTION: "introduction",
    WORK_SAMPLE_EXERCISE: "work sample exercise",
    WRITTEN_ASSESSMENT: "written assessment"
};

export const eStructuredInterviewTypes = {
    LIVE: "Live Interview",
    VIDEO: "Video Interview"
};

export const eCompanyFeatures = {
    INTEGRATIONS: "integrations",
    // LIVE_INTERVIEW: "live_interview",
    TEMPLATES_EMAIL: "templates-email",
    STRUCTURED_INTERVIEWING_EVALUATIONS: "structured-interviewing-evaluations"
};

export const eCompanyPages = {
    DETAILS: "company-details",
    BRANDING: "company-branding",
    STAFF: "company-staff",
    MARKETPLACE: "company-marketplace",
    REPORTING: "company-reporting"
};

export const eUserSettings = {
    APPLY_POST_PROCESSING: "video-transcoding-toggle",
    REPORTING: "reporting",
    // LIVE_INTERVIEW: "live-interview",
    // CLEAR_EVALUATION_RATING: "clear-evaluation-rating",
    CANDIDATE_CONTACT: "candidate-contact",
    INTERVIEW_COMPLETED_TIMESTAMP: "interview-completed-timestamp",
    JOB_TEMPLATES: "mark_as_job_templates",
    EDIT_LIBRARY_ITEMS: "edit-library-items",
    UNSCORE_QUESTION: "unscore-question",
    CLEAR_DECISION: "clear-decision",
    EDIT_CANDIDATE_DETAILS: "edit-candidate-details",
    DELETE_CANDIDATE_REASON: "delete-candidate-reason-(WIP)",
    // SKIP_CV_UPLOAD: "skip-cv-upload-(WIP)",
    TEMPLATES_EMAIL: "email_templates",
    TEMPLATES_SMS: "sms_templates",
    JOB_EDIT_EDIT_QUESTIONS: "(job_edit)-edit_questions",
    TENANT_INTEGRATION_TYPE: "tenant_integration_type-(WIP)",
    CANDIDATE_DEADLINE_TIMEZONE: "candidate_deadline_timezone"
};

export const eInterviewTemplateFields = {
    INTERVIEW_TERMINOLOGY: "interviewTerminology",
    IS_LIVE: "isLive"
};

export const eSMSTemplateTypes = {
    INVITATION: "invitation",
    REMINDER: "reminder"
};

export const eSMSTemplateFields = {
    ID: "sms_template_id",
    TYPE: "sms_template_type",
    NAME: "sms_template_name",
    CONTENT: "content"
};

export const eEmailTemplateTypes = {
    INVITATION: "invitation",
    ACKNOWLEDGEMENT: "acknowledgement",
    SUCCESSFUL: "successful",
    UNSUCCESSFUL: "unsuccessful"
};

export const eEmailTemplateFields = {
    ID: "email_template_id",
    TYPE: "email_template_type",
    SUBJECT: "subject",
    CONTENT: "content",
    HEADER_BANNER: "header_banner_img_src",
    FOOTER_BANNER: "footer_banner_img_src"
};

export const eEvaluationStatus = {
    SUBMITTED: "submitted",
    UNSUBMITTED: "unsubmitted"
};

export const jobDefaultEvaluationAttributes = [
    'Culture Add',
    'Communication',
    'Motivation',
    'Professionalism',
    'Expertise'
];

export const KEYBOARDKEYCODES = {
    "BACKSPACE": 8,
    "ENTER": 13,
    "SPACE_BAR": 32,
    "LEFT": 37,
    "UP": 38,
    "RIGHT": 39,
    "DOWN": 40,
    "DELETE": 46,
    "COMMA": 188
};

export const CATEGORY_GROUP_ORDER = {
    'General': 1,
    'Competencies': 2,
    'Personalities': 3,
    'Other': 4
};

export const EXCLUDED_CATEGORY_GROUP = "General";

export const unsafePasswordPhrases = [
    "alcami"
];

export const libraryAllTag = "all";
export const defaultLibraryCategory = "General Questions";

export const eDropdownTypes = {
    BRANDING: "branding",
    CATEGORY: "category",
    PREPARATION_TIME: "preparation_time",
    ANSWER_TIME: "answer_time",
    NUMBER_OF_ATTEMPTS: "number_of_attempts",
    SMS_INTERVAL: "sms_interval",
    STAFF: "staff",
    TIMEZONES: "timezones",
    INTERVIEW_TERMINOLOGY: "interview_terminology"
};

export const eDropdownItemType = {
    SELECTOR: "selector",
    SELECTION: "selection"
};

export const PREPARATION_TIMES = [
    {name: '00:15', value: 15},
    {name: '00:30', value: 30},
    {name: '01:00', value: 60},
    {name: '02:00', value: 120},
    {name: '05:00', value: 300},
    {name: '10:00', value: 600}
];

export const PREP_TIMES = PREPARATION_TIMES;

export const ANSWER_TIMES = [
    {name: '00:30', value: 30},
    {name: '01:00', value: 60},
    {name: '02:00', value: 120},
    {name: '05:00', value: 300},
    {name: '10:00', value: 600}
];

export const ANSWER_TIMES_WITH_UNTIMED_OPTION = [
    {name: 'Untimed', value: null},
    ...ANSWER_TIMES
];

export const NUMBER_OF_ATTEMPTS = [
    {name: '1', value: 1},
    {name: '2', value: 2},
    {name: '3', value: 3}
];

export const SMS_INTERVALS = [
    {name: '8 hours', value: "08:00:00"},
    {name: '14 hours', value: "14:00:00"},
    {name: '1 day 8 hours', value: "32:00:00"},
    {name: '1 day 14 hours', value: "38:00:00"}
];

export const getSMSIntervalName = (val) => {
    return (SMS_INTERVALS.find(({value}) => value === val) || {}).name;
}

export const durationAsSeconds = (duration) => {
    const durationArray = ["00", ...duration.split(":")];
    const durationSplit = durationArray.slice(Math.max(durationArray.length - 3, 0));
    return (parseInt(durationSplit[0]) * 60 * 60) + (parseInt(durationSplit[1]) * 60) + parseInt(durationSplit[2]);
};

export const asFormData = function(obj) {
    const formData = new FormData();
    Object.keys(obj).forEach((key) => {
        formData.append(key, obj[key]);
    });
    return formData;
};

export const convertDateToPickerFormat = function(date) {
    // getting rid of the GMT portion to avoid the date being shown as next day's date
    if (!!date) {
        return moment(typeof date === "object" ? new Date(date) : date).format("YYYY-MM-DD");
    }
    return null;
};

export const convertDayToCommonFormat = function(day) {
    let date = day;

    if (day === eDeadlineDay.TODAY) {
        date = new Date();
    }
    if (day === eDeadlineDay.YESTERDAY) {
        date = new Date();
        date.setDate(date.getDate() - 1);
    }

    return convertDateToCommonFormat(date);
};

export const convertDateToCommonFormat = function(date) {
    if (!date) {
        return date;
    }
    return moment(date).format("YYYY-MM-DD") + 'T23:59:59';
};

export const getLaterDate = function(firstDate, secondDate) {
    if (firstDate > secondDate) {
        return firstDate;
    }
    return secondDate;
};

export const formatDate = (date) => {
    return date ? moment.utc(date).format("DD MMM YYYY") : null;
};

export const formatDateTime = (date, format = eDateFormatTypes.FULL) => {
    return date ? moment(date).format(format) : "";
};

export const eRoundTime = {
    QUARTER: 15,
    HALF_HOUR: 30,
    HOUR: 60
};

export const roundTime = (date, format = eDateFormatTypes.DATE, rounding = eRoundTime.HALF_HOUR) => {
    const start = moment(date);
    const remainder = rounding - (start.minute() % rounding);

    return moment(start).add(remainder, "minutes").format(format);
};

export const addDays = (days, date = new Date()) => {
    return convertDateToCommonFormat(date.setDate(date.getDate() + days));
};

export const generateTemporaryId = () => {
    return "temp_" +
        (new Date().getTime()) +
        ("00000" + (Math.random() * 100000)).substr(-6);
};

export const getDateTimeDisplay = (dateUTC) => {
    return (!(dateUTC instanceof Date) || isNaN(dateUTC.valueOf())) ? "" :
        shiftUTCDateToLocalTime(dateUTC).toLocaleDateString("en-AU", {
            day: "2-digit",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            hour12: false,
            minute: "2-digit"
        });
};

export const getQueryParam = function(param, url = window.location.href) {
    const sanitisedUrl = url.replace(/#/g, "hash");
    const searchableURL = new URL(sanitisedUrl);
    const token = searchableURL.searchParams.get(param);
    return token ? token.replace(/hash/g, "#") : "";
};

export const isValidEmail = function(email) {
    const emailRegex = /^[^\s]+@[-a-zA-Z0-9.]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
};

export const isValidPassword = function(password) {
    return password === null || !!(password || "").length;
}

export const isValidURL = (value = '', allow_empty_value = true) => {
    if ((!value || value === '') && allow_empty_value) {
        return true;
    }

    const r = new RegExp(/^(http|https):\/\/[^ "]+$/);
    return r.test(value);
}

export const isValidImageURL = (value = '') => {
    const isURLValid = isValidURL(value);
    const isDataURL = value.substring(0, 11) === "data:image/";
    return isURLValid || isDataURL;
}

export const objectToArray = (obj) => {
    return Object.keys(obj).map((key) => {
        return obj[key];
    });
};

export const objectToDropdownItems = (obj) => {
    return Object.keys(obj).map((key) => {
        return {
            label: obj[key],
            value: obj[key]
        };
    });
};

export const shiftUTCDateToLocalTime = (dateUTC) => {
    const theDate = new Date(dateUTC);
    return new Date(
        theDate.getUTCFullYear(),
        theDate.getUTCMonth(),
        theDate.getUTCDate(),
        theDate.getUTCHours(),
        theDate.getUTCMinutes(),
        theDate.getUTCSeconds(),
        theDate.getUTCMilliseconds()
    );
};

export const toTimeFormat = function(input) {
    const sec_num = parseInt(input, 10); // don't forget the second param
    let minutes = Math.floor(sec_num / 60);
    let seconds = sec_num - (minutes * 60);

    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return minutes+':'+seconds;
};

export const fancyTimeFormat = (time) => {
    if (!time) {
        return "None";
    }

    // Hours, minutes and seconds
    const hrs = ~~(time / 3600);
    const mins = ~~((time % 3600) / 60);
    const secs = ~~time % 60;

    // Output like "01:01" or "04:03:59" or "123:03:59"
    let ret = "";

    if (hrs > 0) {
        ret += ("00" + hrs).slice(Math.min(-2, (hrs.toString().length * -1))) + ":";
    }

    ret += ("00" + mins).slice(-2) + ":";
    ret += ("00" + secs).slice(-2);
    return ret;
};

export const isValidTime = (time = "") => {
    const regex = /^(0\d|[1-9]|1[0-2]):([0-5]\d)\s(AM|PM)?$/i;
    return regex.test(time);
};

export const isValidDuration = (duration = "") => {
    const regex = /^((0[0-5]):)?([0-5]\d):([0-5]\d)$/i;
    return regex.test(duration);
};

export const combineDateAndTime = (date_field, time_field) => {
    const time = timeIn24HourFormat(time_field);
    return `${date_field.replace(/\s/g, "-")}T${time}:00.000`;
};

export const scrollToTop = (container = null) => {
    const el = container ? document.querySelector(container) : document;
    el.scrollTo({ top: 0 });
};

export const scrollToID = (id, container = null) => {
    const parent = container ? document.querySelector(container) : document;
    const el = parent.querySelector(`#${id}`);
    if (!!el) {
        el.scrollIntoView();
    }
};

export const average = (arr, precision = 1) => {
    if (!arr.length) {
        return 0;
    }
    const avg = arr.reduce((a,b) => a + b, 0) / arr.length;
    const multiplier = Math.pow(10, precision);

    return Math.round(avg * multiplier) / multiplier;
};

export const validateCandidates = (candidates, jobTimezone = null) => {
    let hasErrors = false;
    const candidatesEmails = candidates.map(({email}) => email);

    const returnedCandidates = candidates.map((candidate) => {
        const {
            email,
            invited_at,
            phone,
            deadline_timezone
        } = candidate;

        candidate.phone = phone || null;
        if (deadline_timezone || jobTimezone) {
            candidate.deadline_timezone = deadline_timezone || jobTimezone;
        }

        if (!!invited_at) {
            return candidate;
        }

        const isEmailValid = isValidEmail(email);
        candidate.isEmailError = !isEmailValid;
        if (!isEmailValid) {
            hasErrors = true;
        }
        candidate.isEmailDuplicate = candidatesEmails.filter(candidateEmail => candidateEmail === email).length > 1;

        return candidate;
    });

    return { hasErrors, candidates: returnedCandidates };
};

export const isNewCandidate = (candidate_id) => {
    return ("" + candidate_id).substring(0,4) === "temp";
};

export const sanitiseCandidates = (candidates) => {
    return candidates?.map((candidate) => {
        const { candidate_id } = candidate;
        const returnValue = {
            ...candidate,
            candidate_id: isNewCandidate(candidate_id) ? null : candidate_id
        };
        delete returnValue.isEmailDuplicate;
        return returnValue;
    });
};

const getLocalStorage = () => {
    let returnValue = null;
    try {
        returnValue = localStorage;
    } catch (error) {
        console.error('setUserData: ', { error });
    }
    return returnValue;
}

export const getUserData = () => {
    const userData = getLocalStorage()?.getItem('ngStorage-userData');
    const parsedUserData = userData ? JSON.parse(userData) : {};
    return get(parsedUserData, ["details"], {});
};

export const setUserData = (data) => {
    getLocalStorage()?.setItem('ngStorage-userData', JSON.stringify({
        details: {
            ...data
        }
    }));
};

export const isAlcamiStaff = () => {
    const userData = getUserData();
    return get(userData, ["is_alcami_staff"], false);
};

export const isCompanyFeatureEnabled = (feature, features = []) => {
    return features.includes(feature);
}

export const isAdminUserSettingEnabled = (item) => {
    return isAlcamiStaff() && getUserSetting(item);
}

export const getValue = (variable, defaultValue) => {
    return variable || defaultValue;
}

export const getUserSetting = (item) => {
    const parsedUserSettings = getUserSettings();
    return get(parsedUserSettings, [item], null);
};

export const getUserSettings = () => {
    const userSettings = getLocalStorage()?.getItem('userSettings');
    return userSettings? JSON.parse(userSettings) : {};
}

export const setUserSetting = (item, value) => {
    const userSettings = getUserSettings();
    userSettings[item] = value;

    getLocalStorage()?.setItem('userSettings', JSON.stringify(userSettings));
};

export const timeIn24HourFormat = (value = "") => {
    const [ hour, minute ] = value.replace(/AM|PM/, '').split(":");
    const theHour = parseInt(hour)
    let addition = 0;

    if (value.includes("PM") && theHour < 12) {
        addition = 12;
    } else if (value.includes("AM") && theHour === 12) {
        addition = -12;
    }

    const hourIn24HourFormat = `0${theHour + addition}`.slice(-2);

    return `${hourIn24HourFormat}:${minute.trim()}`;
};

export const isValidDateTime = (date, time) => {
    return moment(`${date} ${time}`).isValid();
};

export const isUpcomingDate = (date) => {
    return moment(date).isAfter(moment());
}

export const isUpcomingDateTime = (theDate, theTime) => {
    if (!isValidDateTime(theDate, theTime)) {
        return false;
    }
    return moment(`${theDate} ${theTime}`).isAfter(moment());
};

export const isColorRGB = (color) => {
    return /^rgb[(](?:\s*0*(?:\d\d?(?:\.\d+)?(?:\s*%)?|\.\d+\s*%|100(?:\.0*)?\s*%|(?:1\d\d|2[0-4]\d|25[0-5])(?:\.\d+)?)\s*(?:,(?![)])|(?=[)]))){3}[)]$/i.test(color); //NOSONAR
};

export const isColorRGBA = (color) => {
    return /^rgba[(](?:\s*0*(?:\d\d?(?:\.\d+)?(?:\s*%)?|\.\d+\s*%|100(?:\.0*)?\s*%|(?:1\d\d|2[0-4]\d|25[0-5])(?:\.\d+)?)\s*(?:,(?![)])|(?=[)]))){4}[)]$/i.test(color); //NOSONAR
};

export const isColorHex = (color) => {
    return /^#([a-f0-9]{3}){1,2}$/i.test(color);
};

export const isColorHSL = (color) => {
    // https://gist.github.com/sethlopezme/d072b945969a3cc2cc11
    return /^hsl\((0|360|35\d|3[0-4]\d|[12]\d\d|0?\d?\d)\s*,\s*(0|100|\d{1,2})%\s*,\s*(0|100|\d{1,2})%\)$/i.test(color); //NOSONAR
};

export const isColorHSLA = (color) => {
    // https://gist.github.com/sethlopezme/d072b945969a3cc2cc11
    return /^hsla\((0|360|35\d|3[0-4]\d|[12]\d\d|0?\d?\d)\s*,\s*(0|100|\d{1,2})%\s*,\s*(0|100|\d{1,2})%\s*,\s*(0?\.\d|1(\.0)?)\)$/i.test(color); //NOSONAR
};

const colorNames = {
    "aliceblue": "#f0f8ff",
    "antiquewhite": "#faebd7",
    "aqua": "#00ffff",
    "aquamarine": "#7fffd4",
    "azure": "#f0ffff",
    "beige": "#f5f5dc",
    "bisque": "#ffe4c4",
    "black": "#000000",
    "blanchedalmond": "#ffebcd",
    "blue": "#0000ff",
    "blueviolet": "#8a2be2",
    "brown": "#a52a2a",
    "burlywood": "#deb887",
    "cadetblue": "#5f9ea0",
    "chartreuse": "#7fff00",
    "chocolate": "#d2691e",
    "coral": "#ff7f50",
    "cornflowerblue": "#6495ed",
    "cornsilk": "#fff8dc",
    "crimson": "#dc143c",
    "cyan": "#00ffff",
    "darkblue": "#00008b",
    "darkcyan": "#008b8b",
    "darkgoldenrod": "#b8860b",
    "darkgray": "#a9a9a9",
    "darkgreen": "#006400",
    "darkkhaki": "#bdb76b",
    "darkmagenta": "#8b008b",
    "darkolivegreen": "#556b2f",
    "darkorange": "#ff8c00",
    "darkorchid": "#9932cc",
    "darkred": "#8b0000",
    "darksalmon": "#e9967a",
    "darkseagreen": "#8fbc8f",
    "darkslateblue": "#483d8b",
    "darkslategray": "#2f4f4f",
    "darkturquoise": "#00ced1",
    "darkviolet": "#9400d3",
    "deeppink": "#ff1493",
    "deepskyblue": "#00bfff",
    "dimgray": "#696969",
    "dodgerblue": "#1e90ff",
    "firebrick": "#b22222",
    "floralwhite": "#fffaf0",
    "forestgreen": "#228b22",
    "fuchsia": "#ff00ff",
    "gainsboro": "#dcdcdc",
    "ghostwhite": "#f8f8ff",
    "gold": "#ffd700",
    "goldenrod": "#daa520",
    "gray": "#808080",
    "green": "#008000",
    "greenyellow": "#adff2f",
    "honeydew": "#f0fff0",
    "hotpink": "#ff69b4",
    "indianred ": "#cd5c5c",
    "indigo": "#4b0082",
    "ivory": "#fffff0",
    "khaki": "#f0e68c",
    "lavender": "#e6e6fa",
    "lavenderblush": "#fff0f5",
    "lawngreen": "#7cfc00",
    "lemonchiffon": "#fffacd",
    "lightblue": "#add8e6",
    "lightcoral": "#f08080",
    "lightcyan": "#e0ffff",
    "lightgoldenrodyellow": "#fafad2",
    "lightgrey": "#d3d3d3",
    "lightgreen": "#90ee90",
    "lightpink": "#ffb6c1",
    "lightsalmon": "#ffa07a",
    "lightseagreen": "#20b2aa",
    "lightskyblue": "#87cefa",
    "lightslategray": "#778899",
    "lightsteelblue": "#b0c4de",
    "lightyellow": "#ffffe0",
    "lime": "#00ff00",
    "limegreen": "#32cd32",
    "linen": "#faf0e6",
    "magenta": "#ff00ff",
    "maroon": "#800000",
    "mediumaquamarine": "#66cdaa",
    "mediumblue": "#0000cd",
    "mediumorchid": "#ba55d3",
    "mediumpurple": "#9370d8",
    "mediumseagreen": "#3cb371",
    "mediumslateblue": "#7b68ee",
    "mediumspringgreen": "#00fa9a",
    "mediumturquoise": "#48d1cc",
    "mediumvioletred": "#c71585",
    "midnightblue": "#191970",
    "mintcream": "#f5fffa",
    "mistyrose": "#ffe4e1",
    "moccasin": "#ffe4b5",
    "navajowhite": "#ffdead",
    "navy": "#000080",
    "oldlace": "#fdf5e6",
    "olive": "#808000",
    "olivedrab": "#6b8e23",
    "orange": "#ffa500",
    "orangered": "#ff4500",
    "orchid": "#da70d6",
    "palegoldenrod": "#eee8aa",
    "palegreen": "#98fb98",
    "paleturquoise": "#afeeee",
    "palevioletred": "#d87093",
    "papayawhip": "#ffefd5",
    "peachpuff": "#ffdab9",
    "peru": "#cd853f",
    "pink": "#ffc0cb",
    "plum": "#dda0dd",
    "powderblue": "#b0e0e6",
    "purple": "#800080",
    "rebeccapurple": "#663399",
    "red": "#ff0000",
    "rosybrown": "#bc8f8f",
    "royalblue": "#4169e1",
    "saddlebrown": "#8b4513",
    "salmon": "#fa8072",
    "sandybrown": "#f4a460",
    "seagreen": "#2e8b57",
    "seashell": "#fff5ee",
    "sienna": "#a0522d",
    "silver": "#c0c0c0",
    "skyblue": "#87ceeb",
    "slateblue": "#6a5acd",
    "slategray": "#708090",
    "snow": "#fffafa",
    "springgreen": "#00ff7f",
    "steelblue": "#4682b4",
    "tan": "#d2b48c",
    "teal": "#008080",
    "thistle": "#d8bfd8",
    "tomato": "#ff6347",
    "turquoise": "#40e0d0",
    "violet": "#ee82ee",
    "wheat": "#f5deb3",
    "white": "#ffffff",
    "whitesmoke": "#f5f5f5",
    "yellow": "#ffff00",
    "yellowgreen": "#9acd32"
};

const hexToRGBValues = (color) => {
    let c = color.substring(1).split('');
    if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',');
};

const hslToRGBValues = (h, s, l) => {
    // https://css-tricks.com/converting-color-spaces-in-javascript/
    // Must be fractions of 1
    s /= 100;
    l /= 100;

    let c = (1 - Math.abs(2 * l - 1)) * s,
        x = c * (1 - Math.abs((h / 60) % 2 - 1)),
        m = l - c/2,
        r = 0,
        g = 0,
        b = 0;

    if (0 <= h && h < 60) {
        r = c;
        g = x;
    } else if (60 <= h && h < 120) {
        r = x;
        g = c;
    } else if (120 <= h && h < 180) {
        g = c;
        b = x;
    } else if (180 <= h && h < 240) {
        g = x;
        b = c;
    } else if (240 <= h && h < 300) {
        r = x;
        b = c;
    } else if (300 <= h && h < 360) {
        r = c;
        b = x;
    }
    r = Math.round((r + m) * 255);
    g = Math.round((g + m) * 255);
    b = Math.round((b + m) * 255);

    return `${r},${g},${b}`;
};

export const convertToRGBValues = (color) => {
    let returnValue;
    if (isValidRGBValues(color)) {
        returnValue = color;
    } else if (isColorHex(color)) {
        returnValue = hexToRGBValues(color);
    } else if (isColorRGB(color) || isColorRGBA(color)) {
        const colorArray = color.split(/[(,)]/);
        const r = colorArray[1],
            g = colorArray[2],
            b = colorArray[3];
        returnValue = `${r}, ${g}, ${b}`;
    } else if (isColorHSL(color) || isColorHSLA(color)) {
        const colorArray = color.split(/[(,)]/);
        const h = colorArray[1],
            s = parseFloat(colorArray[2]),
            l = parseFloat(colorArray[3]);
        returnValue = hslToRGBValues(h, s, l);
    } else if (colorNames[`${color}`] !== undefined) {
        returnValue = hexToRGBValues(colorNames[color]);
    } else {
        // invalid color value
        returnValue = null;
    }
    return returnValue;
};

export const convertToRGBA = (color, opacity = 1) => {
    const rgbValues = convertToRGBValues(color);
    return !!rgbValues ? `rgba(${rgbValues},${opacity})` : null;
};

export const isValidColor = (color) => {
    return isColorHex(color) ||
        isColorRGB(color) || isColorRGBA(color) ||
        isColorHSL(color) || isColorHSLA(color) ||
        colorNames[`${color}`] !== undefined;
};

export const isValidRGBValues = (color) => {
    let returnValue = false;
    try {
        const [r,g,b] = color.split(",");
        if (
            0 <= r && r <= 255 &&
            0 <= g && g <= 255 &&
            0 <= b && b <= 255
        ) {
            returnValue = true;
        }
    } catch {
    }
    return returnValue;
};

export const updateURL = (url = "") => {
    /**
     * window.history.pushState doesn't update the `path` prop in App component
     * and consequently is not enough, so we will use window.location.href for now.
     */
    window.location.href = url;
};

export const replaceURL = (url = "") => {
    window.history.replaceState({}, document.title, url);
};

export const sanitiseURL = (url = window.location.href) => {
    const [ path, queryParamsString = "" ] = url.split("?");
    const queryParamsArray = queryParamsString ? queryParamsString.split("&") : [];

    const newQueryParams = [];
    queryParamsArray.forEach((queryParam) => {
        if (!(
            queryParam.includes("uid=")
            || queryParam.includes("verification_code=")
            || queryParam.includes("authNonce")
            // || queryParam.includes("token=")
        )) {
            newQueryParams.push(queryParam);
        }
    });
    const sanitisedURL = `${path}${newQueryParams.length ? "?" : ""}${newQueryParams.join("&")}`;
    replaceURL(sanitisedURL);
};

/* PAGE FEATURES
 * Use this to control displays for various integration partners.
 * It is separate from company-level features which is integration-agnostic.
 */

// this is the default features (i.e. standalone)
const defaultPageFeatures = {
    /* GLOBAL */
    showHeader: true,
    /* LOGIN */
    loginToShowErrorInstead: false,
    /* JOB DRAFT */
    showJobTemplatesSelection: false,
    selectLibraryQuestionCloseText: '',
    showCandidatesStep: true,
    /* JOB DETAILS */
    showBreadcrumbs: true,
    /* CANDIDATE EVALUATION */
    showCandidateProfileLink: false,
    showCandidateListInEvaluation: true,
    returnToJobDetailsAfterEvaluation: true,
    /* LIBRARY */
    enableLibraryRecording: true
};

export const INTEGRATION_PROVIDER = {
    DEFAULT: "integration",
    JOB_ADDER: "jobadder",
    PAGE_UP: "pageup",
    BULLHORN: "bullhorn",
    CRITERIA: "criteria"
};

// this is the customised features for integration providers
const integrationPageFeatures = [
    {
        provider: [INTEGRATION_PROVIDER.DEFAULT, INTEGRATION_PROVIDER.JOB_ADDER, INTEGRATION_PROVIDER.BULLHORN],
        showHeader: false,
        // loginToShowErrorInstead: false,
        showJobTemplatesSelection: true,
        // selectLibraryQuestionCloseText: '',
        showCandidatesStep: false,
        showBreadcrumbs: false,
        showCandidateProfileLink: true,
        // showCandidateListInEvaluation: true,
        // returnToJobDetailsAfterEvaluation: true,
        // enableLibraryRecording: true
    },
    {
        provider: [INTEGRATION_PROVIDER.CRITERIA],
        showHeader: false,
        loginToShowErrorInstead: true,
        showJobTemplatesSelection: true,
        selectLibraryQuestionCloseText: 'Next',
        showCandidatesStep: false,
        showBreadcrumbs: false,
        // showCandidateProfileLink: false,
        showCandidateListInEvaluation: false,
        returnToJobDetailsAfterEvaluation: false,
        enableLibraryRecording: false
    }
];

export const getPageFeatures = (integrationProvider) => {
    const featureOverrides = integrationPageFeatures.find(({provider}) => provider.includes(integrationProvider));
    return {...defaultPageFeatures, ...featureOverrides};
}

export const isInIFrame = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
};

export const eWindowPositions = {
    LEFT: "left",
    RIGHT: "right"
};

export const openInNewWindow = (url, position = eWindowPositions.LEFT, target = "_blank") => {
    const width = window.screen.width / 2,
        height = window.screen.height,
        left = position === eWindowPositions.RIGHT ? width : 0;
    const features = `width=${width},height=${height},left=${left}`;
    window.open(url, target, features);
};

export const openLiveInterview = (company_id, staff_id, job_id, job_stage_id, candidate_id) => {
    const interview_url = `/api/Company/${company_id}/Staff/${staff_id}/Job/${job_id}/Stage/live/${job_stage_id}/Candidate/${candidate_id}/live-interview`;
    openInNewWindow(interview_url);
};

export const isInternetExplorer = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");

    return (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./));
};

export const getIntegrationProviderFromPath = (path) => {
    const pathToCheck = path || window.location.href.split(window.location.host)?.[1].split("#/")?.[1];
    return pathToCheck?.split("/")?.[0];
};

/*
 * UserInfoTypes
 */
export const UserInfoTypes = {
    DETAILS: "details",
    ENDPOINTS: "endpoints"
};

export class UserInfo {
    constructor() {
        this.key = 'ngStorage-userData';
    }

    get(type, name) {
        const item = getLocalStorage()?.getItem(this.key)
        const user = item ? JSON.parse(item) : {};
        if (type && objectToArray(UserInfoTypes).includes(type)) {
            if (name) {
                return user[type][name] || {};
            } else {
                return user[type] || {};
            }
        } else {
            return user || {};
        }
    }

    setDetails(value) {
        this.set(UserInfoTypes.DETAILS, null, value);
    }

    setEndpoints(value) {
        this.set(UserInfoTypes.ENDPOINTS, null, value);
    }

    set(type, name, value) {
        if (!objectToArray(UserInfoTypes).includes(type)) {
            return false;
        }

        const user = this.get();

        if (name) {
            user[type][name] = value;
        } else {
            user[type] = value;
        }

        getLocalStorage()?.setItem(this.key, JSON.stringify(user));
    }
}

export const enableLazyLoading = (selector = "lazy-load", data = "data-image") => {
    // https://codepen.io/imagekit_io/pen/RBXVrW
    let lazyloadImages;
    if ("IntersectionObserver" in window) {
        lazyloadImages = document.querySelectorAll(`.${selector}`);
        const imageObserver = new IntersectionObserver(function(entries) {
            entries.forEach(function(entry) {
                if (entry.isIntersecting) {
                    const image = entry.target;
                    const backgroundImage = image.getAttribute(data);
                    if (!!backgroundImage) {
                        image.setAttribute("style", `background-image: url(${backgroundImage})`);
                    }
                    image.classList.remove(selector);
                    imageObserver.unobserve(image);
                }
            });
        });

        lazyloadImages.forEach(function(image) {
            imageObserver.observe(image);
        });
    }
}

export const getDeadlineValue = (deadline_type, deadline, ongoing_deadline_days) => {
    if (deadline_type === eDeadlineType.NONE) {
        return '-';
    }
    if (deadline_type === eDeadlineType.FIXED) {
        return formatDate(deadline);
    }

    if (deadline_type === eDeadlineType.ONGOING) {
        return `${ongoing_deadline_days} Day${ongoing_deadline_days > 1 ? 's' : ''}`;
    }

    return 'Ongoing';
};

export const hasDuplicateSMSReminders = (arr = []) => {
    if (!arr) {
        return false;
    }

    const list = arr.map(({sms_template_id, interval_before_deadline}) => (`${sms_template_id}|${interval_before_deadline}`));
    return list.length !== new Set(list).size;
};

export const hasIncompleteSMSReminders = (arr = []) => {
    if (!arr) {
        return false;
    }

    let returnValue = false;
    forEach(arr, ({sms_template_id, interval_before_deadline}) => {
        if (!sms_template_id || !interval_before_deadline) {
            returnValue = true;
            return false;
        }
    });

    return returnValue;
}

export const formatTemplateSubjectPlaceholders = (content) => {
    if (!content) {
        return '';
    } else {
        return EmailInvitationPlaceholders.reduce((item, placeholder) => (
            item.replace(new RegExp("\\{\\{" + placeholder.id.replace(/\./g, '\\.') + "\\}\\}", 'g'), `{{` + placeholder.label + `}}`) //NOSONAR
        ), content);
    }
};

export const deformatTemplateSubjectPlaceholders = (content) => {
    if (!content) {
        return '';
    } else {
        return EmailInvitationPlaceholders.reduce((item, placeholder) => (
            item.replace(new RegExp("\\{\\{" + placeholder.label.replace(/\./g, '\\.') + "\\}\\}", 'g'), `{{` + placeholder.id + `}}`) //NOSONAR
        ), content);
    }
};

export const formatTemplateContentPlaceholders = (content) => {
    if (!content) {
        return '';
    } else {
        return EmailInvitationPlaceholders.reduce((item, placeholder) => (
            item.replace(new RegExp("\\{\\{" + placeholder.id.replace(/\./g, '\\.') + "\\}\\}", 'g'), `<span class="ql-placeholder-content" data-id="${placeholder.id}" data-label="${placeholder.label}" spellcheck="false">﻿<span contenteditable="false">{{placeholder.label}}</span>﻿</span>`) //NOSONAR
        ), content);
    }
}

export const deformatTemplateContentPlaceholders = (content) => {
    if (!content) {
        return '';
    } else {
        return content.replace(/<span class="ql-placeholder-content" data-id="([^"]+)" data-label="([^"]+)" spellcheck="false">﻿<span contenteditable="false">([^<]+)<\/span>﻿<\/span>/g, '{{$1}}');
    }
}

export const getFullName = (first_name, last_name) => {
    return (first_name + (last_name ? ` ${last_name}` : '')).trim();
}

export const isSafari = () => {
    const { userAgent } = navigator;
    /* apparently Chrome (at least on Mac) has 'Safari' on its userAgent string,
     * but since Safari doesn't have 'Chrome' on its userAgent string
     * we can use this to check.
     */
    return userAgent.includes("Safari") && !userAgent.includes("Chrome");
}

export const copyToClipboard = (value) => {
    const el = document.createElement("textarea");
    el.value = value;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
}

export const DEFAULT_CANDIDATE_FOR_EMAIL_PREVIEW = {
    email: 'rachel@alcami.com',
    first_name: 'Rachel',
    deadline: '2021-09-21T23:59'
}

export const getEndOfDay = (additional_days = 0) => {
    const d = new Date();
    d.setDate(d.getDate() + additional_days);

    const year = d.getFullYear();
    const month = `0${d.getMonth() + 1}`.slice(-2);
    const date = `0${d.getDate()}`.slice(-2);

    return `${year}-${month}-${date}T23:59:59`;
}

/*
 * NOTE: This is a duplicate of a fe-applicants app behaviour, so
 * please keep the two in sync
 */
const browser = Browser.detect();
const isIosOrSafari =
    browser && (['safari', 'ios'].includes(browser.name) || browser.os === 'iOS');

/**
 * MediaRecorder MIME types to try, in preference order.
 *
 * We assume that if and when iOS does support WebM for MediaRecorder, it's
 * still going to be preferable to ask iOS/Safari for MP4, whereas other
 * browsers will natively support WebM better, even if they add support for MP4
 * in future.
 */
const mimeTypePreferences = isIosOrSafari
    ? [
        'video/mp4',
        'video/webm;codecs=h264,opus',
        'video/webm;codecs=h264',
        'video/webm'
    ]
    : [
        'video/webm;codecs=h264,opus',
        'video/webm;codecs=h264',
        'video/webm',
        'video/x-matroska;codecs=h264,aac',
        'video/x-matroska;codecs=h264,mp3',
        'video/x-matroska;codecs=h264,opus',
        'video/x-matroska;codecs=h264',
        'video/mp4'
    ];

const testSupportsMediaRecorder = (mimeType) => {
    try {
        return MediaRecorder.isTypeSupported(mimeType);
    } catch (ex) {
        return false;
    }
}

export const preferredMediaRecorderMimeType = mimeTypePreferences.find(
    testSupportsMediaRecorder
);

export const queryStringToObject = (queryString) => {
    const urlParams = new URLSearchParams(queryString);
    const entries = urlParams.entries();
    const result = {};
    for (const [key, value] of entries) { // each 'entry' is a [key, value] tupple
        result[key] = value;
    }
    return result;
};

export const objectToQueryString = (object) => {
    return new URLSearchParams(object).toString();
}

export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getLibraryItemById = (items, libraryAssetType, id) => {
    return items.find(({library_asset_type, is_stock_library_question, tenant_library_question_id, tenant_library_message_id}) => {
        if (library_asset_type !== libraryAssetType) {
            return false;
        }

        if (libraryAssetType === eLibraryAssetType.QUESTION) {
            return !is_stock_library_question && (tenant_library_question_id === id);
        }

        if (libraryAssetType === eLibraryAssetType.MESSAGE) {
            return tenant_library_message_id === id;
        }

        return false;
    });
}
