import React from "react";
import PropTypes from 'prop-types';
import {capitalize, cloneDeep, isEqual, uniq} from "lodash";
import CustomModal from "../../../../components/CustomModal";
import Loader from "../../../../components/Loader";
import Dropdown from "../../../../components/Dropdown";
import CustomTextArea from "../../../../components/Form/CustomTextArea";
import {
    eTemplatesType,
    eSMSTemplateFields,
    eSMSTemplateTypes,
    eSMSTemplatePlaceholders,
    objectToArray,
    eConfirmDialogResponseType
} from "../../../../utils";
import ConfirmDialogResponse from "../../../../components/ConfirmDialog/Response";
import TemplateDetails from "../../../../components/Templates/details";

import './style.scss';


const MAX_SMS_SIZE = 320;

const placeholdersList = objectToArray(eSMSTemplatePlaceholders);

class SMSTemplateDetails extends TemplateDetails {
    constructor(props) {
        super(props);

        this.getSMSTemplateTypes = this.getSMSTemplateTypes.bind(this);

        this.onInputChange = this.onInputChange.bind(this);
        this.getInvalidPlaceholders = this.getInvalidPlaceholders.bind(this);

        this.isSubmitEnabled = this.isSubmitEnabled.bind(this);

        this.state = {
            initialTemplate: cloneDeep(this.props.template),
            template: cloneDeep(this.props.template),
            invalidPlaceholders: []
        };
    }

    getSMSTemplateTypes() {
        const { template: { sms_template_type = null } = {} } = this.state;
        return objectToArray(eSMSTemplateTypes).map((type) => {
            return {
                id: type,
                firstLine: capitalize(type),
                selected: type === sms_template_type,
                onClick: () => this.onInputChange({target: {id: eSMSTemplateFields.TYPE, value: type}})
            };
        });
    }

    onInputChange(e) {
        const { target: { id, value } } = e;
        const { template } = this.state;

        const updatedState = {
            template: {
                ...template,
                [id]: value
            }
        }

        if (id === eSMSTemplateFields.CONTENT) {
            updatedState.invalidPlaceholders = this.getInvalidPlaceholders(value);
        }

        this.setState(updatedState);
    }

    getInvalidPlaceholders(content) {
        const regex = /(%.*?%)/g;
        const placeholders = [...content.matchAll(regex)].map((arr) => arr[0]);

        const invalidPlaceholders = [];

        placeholders.forEach((placeholder) => {
            if (!placeholdersList.includes(placeholder)) {
                invalidPlaceholders.push(placeholder);
            }
        });

        return uniq(invalidPlaceholders);
    }

    isSubmitEnabled() {
        const { initialTemplate, template, invalidPlaceholders } = this.state;
        const { sms_template_type, content } = template;

        return !isEqual(initialTemplate, template)
            && !!sms_template_type
            // && this.isTemplateNameUnique()
            && !!content
            && !invalidPlaceholders.length;
    }

    render() {
        const {
            template,
            invalidPlaceholders,
            confirmDialogResponseProps
        } = this.state;

        if (!template) {
            return (<Loader />);
        }

        const { onClose } = this.props;
        const { sms_template_id, sms_template_type, content } = template;
        const is_create = !sms_template_id;
        const sms_template_name = `${capitalize(sms_template_type)} SMS Template ${sms_template_id}`;

        const helperText = !!invalidPlaceholders.length ?
            <div className={`invalid-placeholders`}>
                Invalid placeholders: {
                invalidPlaceholders.map((placeholder) => (<span className={`placeholder`}>{placeholder}</span>))
            }
            </div> :
            null;

        return (
            <React.Fragment>
                <ConfirmDialogResponse {...confirmDialogResponseProps} />
                {(!confirmDialogResponseProps || confirmDialogResponseProps?.type === eConfirmDialogResponseType.ERROR) && (
                    <CustomModal
                        title={is_create ? 'Create SMS Template' : sms_template_name}
                        classes={`template-details sms-template-details open`}
                        closeOnOverlayClick={false}
                        onClose={onClose}
                    >
                        {is_create && (
                            <Dropdown
                                required={true}
                                styles="floating-label fixed-label"
                                classes="templates_type_dropdown"
                                label="Template Type"
                                items={this.getSMSTemplateTypes()}
                            />
                        )}
                        <CustomTextArea
                            id={eSMSTemplateFields.CONTENT}
                            name={`content`}
                            required={true}
                            classes={`content`}
                            label={`Content`}
                            value={content}
                            maxLength={MAX_SMS_SIZE}
                            resizable={false}
                            isError={content === ""}
                            helperText={helperText}
                            showCharacterCount={true}
                            onChange={this.onInputChange}
                        />
                        <div className={`actions`}>
                            <button className={`react-button cancel button-link`} onClick={() => onClose?.()}>Cancel</button>
                            <button
                                className={`react-button submit ${this.isSubmitEnabled() ? 'active ripple' : 'disabled'}`}
                                onClick={() => this.isSubmitEnabled() && this.onSubmit(eTemplatesType.SMS)}
                            >
                                {is_create ? 'Create' : 'Save'}
                            </button>
                        </div>
                    </CustomModal>
                )}
            </React.Fragment>
        )
    }
}

SMSTemplateDetails.propTypes = {
    templates: PropTypes.array,
    template: PropTypes.object,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func
};

SMSTemplateDetails.defaultProps = {
    templates: [],
    template: null,
    onSubmit: null,
    onClose: null
};

export default SMSTemplateDetails;