import React from "react";
import * as PropTypes from "prop-types";
import { range } from "lodash";
import {eUserSettings, isAdminUserSettingEnabled, objectToArray} from "../../utils";

import './style.scss';
import { IconCrossThin, IconStar } from "../Icons";


export const eRatingsType = {
    STAR: "star",
    NUMERIC: "numeric"
};

const tooltipContent = {
    5: "An excellent response provided, question answered with enough detail and insight and communication considered strong.",
    4: "A good answer provided, included sufficient detail/depth, communicated well.",
    3: "Adequate answer provided, may have lacked enough detail/depth, communication was considered adequate.",
    2: "Limited answer or details that did not address the question/ demonstrated communication below expectation.",
    1: "Did not answer the question/poor communication."
};

const Ratings = ({
    id = parseInt("" + Math.random() * 10000, 0),
    type,
    name,
    classes,
    active,
    score,
    maximumScore,
    showTooltip,
    onChange
}) => {
    const elementName = name || `rating-${id}`;

    const isStar = type === eRatingsType.STAR;

    const rangeArray = isStar ? range(maximumScore,0) : range(1, maximumScore + 1);

    const clear_rating_enabled = !!onChange && isAdminUserSettingEnabled(eUserSettings.CLEAR_EVALUATION_RATING);

    return (
        <div className={`ratings ${type}-ratings ${active ? 'active' : 'inactive'} ${classes} ${clear_rating_enabled ? 'has-clear' : ''}`}>
            {clear_rating_enabled && (
                <span className={`clear`} onClick={(e) => onChange(e, null)}>{IconCrossThin}</span>
            )}
            {rangeArray.map((i) => (
                <React.Fragment key={i}>
                    <input type="radio" id={`ratings-${id}-${i}`} name={elementName} value={i} checked={i === score} onChange={(e) => active && onChange(e, i)} />
                    <label htmlFor={`ratings-${id}-${i}`} title={`${i}${isStar ? '-star' : ''}`}>
                        {isStar ? IconStar : i}
                    </label>
                    {showTooltip && (
                        <span className={`tooltip`}>{tooltipContent[i]}</span>
                    )}
                </React.Fragment>
            ))}
        </div>
    );
};

Ratings.propTypes = {
    type: PropTypes.oneOf(objectToArray(eRatingsType)),
    name: PropTypes.string,
    classes: PropTypes.string,
    active: PropTypes.bool,
    score: PropTypes.number,
    maximumScore: PropTypes.number,
    showTooltip: PropTypes.bool,
    onChange: PropTypes.func
};

Ratings.defaultProps = {
    type: eRatingsType.STAR,
    name: "",
    classes: "",
    active: false,
    score: 0,
    maximumScore: 5,
    showTooltip: false,
    onChange: null
};

export default Ratings;
