import React from "react";

import './style.scss';


const LabelValueTile = ({classes, label, value, subLabel, children}) => {
    const content =
        (
            value !== undefined &&
            value !== null &&
            !!(value.toString() || "").length &&
            value.toString()
        ) ||
        (!!children && children) ||
        "-";
    return (
        <div className={`label-value-tile ${classes}`}>
            {!!label && (<span className="label">{label}</span>)}
            <span className="value">{content}</span>
            <span className="subLabel">{subLabel}</span>
        </div>
    )
};

export default LabelValueTile;
