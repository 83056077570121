import React from "react";
import * as PropTypes from "prop-types";
import {eRecommendationTypes, objectToArray} from "../../../utils";

import "./style.scss";


const Recommendation = ({ value, is_read_only, onUpdate }) => (
    <div className={`recommendation`}>
        <div className={`recommendation-title`}>Recommendation</div>
        <div className={`recommendation-actions`}>
            <span
                id={`recommendation-yes`}
                className={`react-button ${is_read_only ? 'readOnly' : 'ripple'} ${value === eRecommendationTypes.YES ? 'selected' : 'outline'} active`}
                onClick={(e) => !is_read_only && onUpdate(e, eRecommendationTypes.YES)}
            >
                {eRecommendationTypes.YES}
            </span>
            <span
                id={`recommendation-no`}
                className={`react-button ${is_read_only ? 'readOnly' : 'ripple'} ${value === eRecommendationTypes.NO ? 'selected' : 'outline'} warning`}
                onClick={(e) => !is_read_only && onUpdate(e, eRecommendationTypes.NO)}
            >
                {eRecommendationTypes.NO}
            </span>
            <span
                id={`recommendation-maybe`}
                className={`react-button ${is_read_only ? 'readOnly' : 'ripple'} ${value === eRecommendationTypes.MAYBE ? 'selected' : 'outline'} pending`}
                onClick={(e) => !is_read_only && onUpdate(e, eRecommendationTypes.MAYBE)}
            >
                {eRecommendationTypes.MAYBE}
            </span>
        </div>
    </div>
);

Recommendation.propTypes = {
    value: PropTypes.oneOf(objectToArray(eRecommendationTypes)),
    is_read_only: PropTypes.bool,
    onUpdate: PropTypes.func
};

Recommendation.defaultProps = {
    value: null,
    is_read_only: false,
    onUpdate: null
};

export default Recommendation;