import React from 'react';
import * as PropTypes from 'prop-types';

import Dropdown from "../Dropdown";

import './style.scss';
import Avatar from "../Avatar";
import LabelValueTile from "../LabelValueTile";
import { sortBy } from "lodash";
import { IconCrossThin } from "../Icons";


class StaffSelection extends React.Component {
    constructor(props) {
        super(props);

        this.isSelectedStaffInStaffRecords = this.isSelectedStaffInStaffRecords.bind(this);
    }

    getStaffAsDropdownData() {
        const { staffRecords, staffFilter, selectedStaffId, onInputChange, selectionIdProp } = this.props;

        return sortBy(staffFilter ? staffRecords.filter(staffFilter) : staffRecords, ['first_name', 'last_name'])
            .map(({staff_id, first_name, last_name, picture_url, position}) => {
                return {
                    staff_id,
                    avatar: {id: staff_id, first_name, last_name, picture_url},
                    firstLine: (first_name + " " + last_name).trim(),
                    secondLine: position,
                    selected: staff_id === selectedStaffId,
                    onClick: () => onInputChange({target: { id: selectionIdProp, value: staff_id }})
                };
            });
    }

    isSelectedStaffInStaffRecords() {
        const { staffRecords, selectedStaffId } = this.props;
        const staffRecordIds = staffRecords.map(({staff_id}) => staff_id);
        return staffRecordIds.includes(selectedStaffId);
    }

    render() {
        const { staffRecords, selectedStaffId, header, placeholder, readOnly, onRemove } = this.props;

        const selectedStaff = staffRecords.find(({staff_id}) => staff_id === selectedStaffId);
        const selectedStaffName = selectedStaff ? `${selectedStaff.first_name} ${selectedStaff.last_name}`.trim() : '';
        const selectedStaffPosition = selectedStaff ? selectedStaff.position : '';

        return (
            <div className={`staff-selection`}>
                <div className={`staff-selection-header`}>{header}</div>
                {!readOnly && (
                    <Dropdown
                        classes="staff-selection-dropdown"
                        label={selectedStaffId === null || this.isSelectedStaffInStaffRecords() ? placeholder : 'Unknown'}
                        items={this.getStaffAsDropdownData()}
                        itemIdProp="staff_id"
                    />
                )}
                {readOnly && (
                    <LabelValueTile classes="staff-selection-value" value={selectedStaff ? null : '-'}>
                        <div className="avatar"><Avatar data={selectedStaff} /></div>
                        <div className="name">{selectedStaffName}</div>
                        {!!selectedStaffPosition && (
                            <div className="position">{selectedStaffPosition}</div>
                        )}
                        {!!onRemove && (
                            <div className="remove-icon" onClick={() => onRemove(selectedStaffId)}>{IconCrossThin}</div>
                        )}
                    </LabelValueTile>
                )}
            </div>
        );
    }
}

StaffSelection.propTypes = {
    header: PropTypes.string,
    placeholder: PropTypes.string,
    staffRecords: PropTypes.array,
    staffFilter: PropTypes.func,
    selectedStaffId: PropTypes.number,
    onInputChange: PropTypes.func,
    selectionIdProp: PropTypes.string,
    readOnly: PropTypes.bool,
    onRemove: PropTypes.func
};

StaffSelection.defaultProps = {
    header: "Staff Selection",
    placeholder: "Select Staff",
    staffRecords: [],
    staffFilter: null,
    selectedStaffId: null,
    onInputChange: null,
    selectionIdProp: "owner_staff_id",
    readOnly: false,
    onRemove: null
};

export default StaffSelection;
