import React from 'react';
import * as PropTypes from 'prop-types';
import CustomModal from '../CustomModal';

import './style.scss';


const ConfirmDialog = ({ classes, isLoading, title, message, actions, children }) => {
    if (!title && !message) {
        return null;
    }

    return (
        <CustomModal classes={`confirm-dialog open ${classes}`} isLoading={isLoading} title={title} closeOnOverlayClick={false}>
            <div className={`confirm-dialog`}>
                {message && (
                    <div className="message">{message}</div>
                )}
                {!!children && (
                    <div className={`confirm-dialog-children`}>
                        {children}
                    </div>
                )}
                <div className="actions">
                    {actions.map(({label, classes: classNames, autoFocus, disabled, onClick}) => {
                        let buttonClassNames = `react-button ${label} ${classNames} ripple`;
                        if (disabled) {
                            buttonClassNames += ' disabled';
                        }

                        return (
                            <button
                                key={label}
                                className={buttonClassNames}
                                onClick={!disabled ? onClick : null}
                                autoFocus={autoFocus}
                            >
                                {label}
                            </button>
                        );
                    })}
                </div>
            </div>
        </CustomModal>
    );
}

ConfirmDialog.propTypes = {
    title: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            classes: PropTypes.string,
            onClick: PropTypes.func
        })
    )
};

ConfirmDialog.defaultProps = {
    title: null,
    message: null,
    actions: []
};

export default ConfirmDialog;
