import React from "react";
import * as PropTypes from "prop-types";
import { resendInvites } from '../../api/';
import { eJobStageTypes, eResponseStatus, objectToArray } from "../../utils";

import './style.scss';


const eProgressTypes = {
    INITIAL: "",
    PROCESSING: "processing",
    SUCCESS: "success",
    ERROR: "error",
    RETRY: "retry"
};

class SendInvite extends React.Component {
    constructor(props) {
        super(props);
        this.doSendInvite = this.doSendInvite.bind(this);
        this.showStatus = this.showStatus.bind(this);
        this.hideStatus = this.hideStatus.bind(this);
        this.state = {
            status: eProgressTypes.INITIAL
        };
    }

    async doSendInvite(e) {
        e.stopPropagation();
        const { job_stage: {job_id, job_stage_type, job_stage_id}, candidate_id, onInviteSuccess } = this.props;
        if (!!job_id && !!candidate_id) {
            this.showStatus(eProgressTypes.PROCESSING);
            const response = await resendInvites(job_id, job_stage_type, job_stage_id, [candidate_id]);
            const { status, timestamp } = response;
            this.showStatus(status);
            if (status === eResponseStatus.SUCCESS) {
                onInviteSuccess?.(job_stage_id, candidate_id, timestamp);
            }
        }

    }

    showStatus(status) {
        this.setState({
            status
        });
        if (status === eProgressTypes.ERROR) {
            setTimeout(() => this.showStatus(eProgressTypes.RETRY), 1000);
        } else if (status === eProgressTypes.SUCCESS) {
            setTimeout(() => this.hideStatus(), 2000);
        }
    }

    hideStatus() {
        this.setState({
            status: ""
        });
    }

    render() {
        const {
            classes,
            label,
            job_stage: { job_id },
            candidate_id,
            isDisabled,
        } = this.props;
        const { status } = this.state;
        if (!job_id || !candidate_id) {
            return null;
        } else {
            return (
                <React.Fragment>
                    <div className={`send-invite ${classes}`}>
                        <button className={`label disabled-${!!isDisabled} ${status !== "" ? "mask" : ""}`} onClick={!isDisabled && this.doSendInvite}>{label}</button>
                        <div className={`outcome ${status === eProgressTypes.PROCESSING ? "processing" : ""}`}>Sending...</div>
                        <div className={`outcome ${status === eProgressTypes.SUCCESS ? "success" : ""}`}>Sent!</div>
                        <div className={`outcome ${status === eProgressTypes.ERROR ? "error" : ""}`}>Error!</div>
                        <button className={`outcome ${status === eProgressTypes.RETRY ? "infinite" : ""}`} onClick={this.doSendInvite}>Retry?</button>
                    </div>
                </React.Fragment>
            );
        }
    }
}

SendInvite.propTypes = {
    classes: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    job_stage: PropTypes.shape({
        job_id: PropTypes.number.isRequired,
        job_stage_type: PropTypes.oneOf(objectToArray(eJobStageTypes)),
        job_stage_id: PropTypes.number.isRequired
    }),
    candidate_id: PropTypes.number.isRequired,
    isDisabled: PropTypes.bool,
    onInviteSuccess: PropTypes.func
};

export default SendInvite;
