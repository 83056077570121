import React from 'react';
import * as PropTypes from 'prop-types';

import {
    convertDateToCommonFormat,
    defaultOngoingDeadlineDays,
    eDeadlineDay,
    eDeadlineType,
    objectToArray
} from "../../utils";
import CustomDatePicker from "../Pickers/CustomDatePicker";
import Dropdown from "../Dropdown";

import './style.scss';


class JobDeadline extends React.Component {
    constructor(props) {
        super(props);

        this.getOngoingDeadlineDropdown = this.getOngoingDeadlineDropdown.bind(this);
        this.setDeadlineType = this.setDeadlineType.bind(this);
    }

    getOngoingDeadlineDropdown() {
        const { ongoing_deadline_days, onInputChange } = this.props;

        const days = Array.from({length: 14}, (_, i) => i + 1);

        return days.map((day) => {
            return {
                id: `day_${day}`,
                firstLine: `${day} Day${day > 1 ? 's' : ''}`,
                selected: day === ongoing_deadline_days,
                onClick: () => onInputChange({ target: { id: "ongoing_deadline_days", value: day } })
            };
        });
    }

    setDeadlineType(deadlineType) {
        const { onInputChange } = this.props;
        onInputChange({target: {id: `deadline_type`, value: deadlineType}});
    }

    render() {
        const { deadline_type, deadline, showDeadlineDescription, isError, onInputChange } = this.props;
        return (
            <div className={`job-deadline`}>
                <div className={`deadline-header`}>Deadline</div>
                {showDeadlineDescription && (
                    <div className={`deadline-description`}>
                        Deadline change will only apply to new candidates.
                        Existing candidates will maintain their current deadlines.
                    </div>
                )}
                <div className={`deadline-selector`}>
                    <div className={`deadline-type ${deadline_type === eDeadlineType.NONE ? 'active' : ''}`}
                         onClick={() => this.setDeadlineType(eDeadlineType.NONE)}
                    >
                        {eDeadlineType.NONE}
                    </div>
                    <div className={`deadline-type ${deadline_type === eDeadlineType.FIXED ? 'active' : ''}`}
                         onClick={() => this.setDeadlineType(eDeadlineType.FIXED)}
                    >
                        {eDeadlineType.FIXED}
                    </div>
                    <div className={`deadline-type ${deadline_type === eDeadlineType.ONGOING ? 'active' : ''}`}
                         onClick={() => this.setDeadlineType(eDeadlineType.ONGOING)}
                    >
                        {eDeadlineType.ONGOING}
                    </div>
                </div>
                {deadline_type === eDeadlineType.NONE && (
                    <div className={`deadline-description deadline-${eDeadlineType.NONE}`}>
                        No deadline is being set for this job.
                    </div>
                )}
                {deadline_type === eDeadlineType.FIXED && (
                    <React.Fragment>
                        <CustomDatePicker
                            classes={`deadline`}
                            id={`deadline`}
                            required={true}
                            label={`Select Date`}
                            value={deadline}
                            isError={isError}
                            minDate={eDeadlineDay.YESTERDAY}
                            minMessage="Please select a valid deadline."
                            onChange={(e) => onInputChange({
                                target: {
                                    id: `deadline`,
                                    value: convertDateToCommonFormat(e)
                                }
                            })}
                        />
                        <div className={`deadline-description`}>
                            Fixed same-day deadline for all candidates.
                        </div>
                    </React.Fragment>
                )}
                {deadline_type === eDeadlineType.ONGOING && (
                    <React.Fragment>
                        <Dropdown
                            required={true}
                            styles="floating-label fixed-label"
                            classes="ongoing-deadline-days"
                            label="Respond Within"
                            items={this.getOngoingDeadlineDropdown()}
                        />
                        <div className={`deadline-description`}>
                            Number of days each candidate will have to complete their interview. Job will remain open until manually archived.
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

JobDeadline.propTypes = {
    deadline_type: PropTypes.oneOf(objectToArray(eDeadlineType)),
    deadline: PropTypes.string,
    ongoing_deadline_days: PropTypes.number,
    showDeadlineDescription: PropTypes.bool,
    isError: PropTypes.bool,
    onInputChange: PropTypes.func
};

JobDeadline.defaultProps = {
    deadline_type: eDeadlineType.FIXED,
    deadline: "",
    ongoing_deadline_days: defaultOngoingDeadlineDays,
    showDeadlineDescription: false,
    isError: false,
    onInputChange: null
};

export default JobDeadline;
