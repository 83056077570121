import React from "react";
import * as PropTypes from "prop-types";
import {eMediaTypes, eResponseType, eTextAreaType, eUserTypes, getValue, isAlcamiStaff, objectToArray} from "../../../utils";
import { findLast } from 'lodash';
import { IconCrossThin, IconParagraph, IconQuestionMark, IconWordCount } from "../../Icons";
import MediaPreview from "../../MediaPreview";
import Ratings, { eRatingsType } from "../../Ratings";
import Tooltip from '../../Tooltip';
import CustomTextArea from "../../Form/CustomTextArea";
import CustomToggleField, { eToggleSize} from "../../Form/CustomToggleField";
import Avatar from "../../Avatar";
import CandidateResponseQuestion from "./CandidateResponseQuestion";
import RatingsTooltip from "./RatingsTooltip";
import { words } from "lodash";

import './style.scss';


class CandidateResponses extends React.Component {
    constructor(props) {
        super(props);

        this.getVideo = this.getVideo.bind(this);
        this.getNextItemContent = this.getNextItemContent.bind(this);
        this.getEvaluationsComponentClassNames = this.getEvaluationsComponentClassNames.bind(this);
        this.getResponseVideoDisplay = this.getResponseVideoDisplay.bind(this);
        this.getResponseDocumentDisplay = this.getResponseDocumentDisplay.bind(this);
        this.getRatingsTitle = this.getRatingsTitle.bind(this);
        this.toggleTranscript = this.toggleTranscript.bind(this);
        this.getEvaluator = this.getEvaluator.bind(this);

        this.state = {
            isTranscriptShown: false,
            viewRawRecording: {}
        };
    }

    getVideo(media, mediaType) {
        if (media?.length) {
            return media.find(({ type }) => type === mediaType);
        }
        return undefined;
    }

    getNextItemContent(questions, questionNumber, hasAttributes) {
        if (questionNumber === questions.length) {
            return {
                id: "assessment-and-comments",
                label: `↓ ${hasAttributes ? "Final Assessment" : "Recommendation"}`
            };
        }

        return {
            id: `evaluation-item-${questionNumber + 1}`,
            label: "↓ Next Question"
        };
    }

    getEvaluationsComponentClassNames(showEvaluationGuide) {
        const { view_type, requires_star_rating } = this.props;

        return `evaluations ${view_type}
            ${requires_star_rating ? 'has-rating' : ''}
            ${showEvaluationGuide ? 'has-evaluation-guide' : ''}
        `;
    }

    getResponseVideoDisplay(stepId, media, thumbnail, transcript, isResponseCompleted) {
        const { isTranscriptShown } = this.state;

        const hasMedia = media?.length;
        const rawRecordingVideo = this.getVideo(media, eMediaTypes.RECORDING);
        const transcodedVideo = this.getVideo(media, eMediaTypes.TRANSCODED);

        let mediaToShow = [rawRecordingVideo];
        let showViewRawRecordingToggle = true;
        let viewRawRecordingToggleChecked = true;
        let viewRawRecordingToggleDisabled = false;
        if (!rawRecordingVideo) {
            /* There's no choice available if we're a disguised-voice evaluator, for example. */
            mediaToShow = media;
            showViewRawRecordingToggle = false;
        } else if (!transcodedVideo) {
            viewRawRecordingToggleDisabled = true;
        } else if (!this.state.viewRawRecording[stepId]) {
            mediaToShow = [transcodedVideo];
            viewRawRecordingToggleChecked = false;
        }

        if (hasMedia) {
            return (
                <React.Fragment>
                    {isAlcamiStaff() && !!transcript && (
                        <div className={`transcript-text-container transcript-display-${!!isTranscriptShown}`}>
                            <span className={`transcript-toggle`} onClick={this.toggleTranscript}>
                                {isTranscriptShown ? IconCrossThin : IconParagraph}
                            </span>
                            <span className={`transcript-text`}>
                                {transcript}
                            </span>
                        </div>
                    )}
                    <MediaPreview
                        media={mediaToShow}
                        thumbnail={thumbnail}
                        isInline={true}
                        autoplay={false}
                        pause={isTranscriptShown}
                        showPlaybackSpeed={true}
                    />
                    {showViewRawRecordingToggle && (
                        <div className='view-raw-recording'>
                            <CustomToggleField
                                size={eToggleSize.X_SMALL}
                                id={`view-raw-recording-${stepId}`}
                                label='View raw recording'
                                checked={viewRawRecordingToggleChecked}
                                isDisabled={viewRawRecordingToggleDisabled}
                                onChange={(evt) => {
                                    this.setState({ viewRawRecording: { ...this.state.viewRawRecording, [stepId]: evt.target.value }});
                                }}
                            />
                            <Tooltip
                                positioning='onRight'
                                item={IconQuestionMark}
                                description={
                                    <div className="content">
                                        This may help if the video does not play back properly, or is glitchy.
                                    </div>
                                }
                            />
                        </div>
                    )}
                </React.Fragment>
            );
        }

        return (
            <span className={'response-pending'}>
                {isResponseCompleted ?
                    "(The candidate’s response for this question is not yet ready for playback.)" :
                    "(The candidate has not yet completed a response for this question.)"
                }
            </span>
        );
    }

    getResponseDocumentDisplay(url) {
        if (url) {
            return (
                <a
                    className={`response-document-link`}
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                >
                    Response Document
                </a>
            );
        }

        return <span>-</span>;
    }

    getRatingsTitle(hasRatings) {
        if (hasRatings) {
            return <React.Fragment>
                Rating*
                <RatingsTooltip />
            </React.Fragment>;
        }

        return <span>Evaluation</span>;
    }

    toggleTranscript() {
        const { isTranscriptShown } = this.state;
        this.setState({
            isTranscriptShown: !isTranscriptShown
        });
    }

    getEvaluator(staffId) {
        const { evaluators } = this.props;
        return evaluators.find(({staff_id}) => staff_id === staffId);
    }

    render() {
        const {
            view_type,
            questions,
            responses,
            requires_star_rating,
            evaluations,
            // evaluation_attributes,
            // evaluation_structure,
            onEvaluationChange,
            onEvaluationUpdate,
            submitted_at
        } = this.props;

        const isManagerView = view_type === eUserTypes.MANAGER,
            isEvaluatorView = view_type === eUserTypes.EVALUATOR;

        // for evaluator view
        const { attributes = [], questions: questions_evaluations = [] } = evaluations[0] || {};
        const hasAttributes = !!(attributes?.length);

        return (
            <div className={`candidate-responses`}>
                {questions.map((question, index) => {
                    const questionNumber = index + 1;
                    const {
                        job_interview_step_id: stepId,
                        display_text,
                        html_content,
                        response_type,
                        evaluation_guide,
                        exclude_from_scoring
                    } = question;
                    const selectedCandidateResponse = getValue(responses.find(({job_interview_step_id}) => job_interview_step_id === stepId), {});
                    const {
                        is_completed,
                        media,
                        thumbnail,
                        response_text,
                        response_document_url,
                        response_video_transcript_text
                    } = selectedCandidateResponse;

                    const showEvaluationGuide = !exclude_from_scoring && !!evaluation_guide;
                    const nextItem = this.getNextItemContent(questions, questionNumber, hasAttributes);

                    return (
                        <div className={`evaluation-item`}
                             key={`evaluation-item-${questionNumber}`}
                             id={`evaluation-item-${questionNumber}`}
                        >
                            <CandidateResponseQuestion
                                classes={`question`}
                                index={questionNumber}
                                display_text={display_text}
                                html_content={html_content}
                            />

                            <div className={`response-and-evaluations`}>
                                <div className={`response response-${response_type}`}>
                                    <div className={`response-content`}>
                                        {response_type === eResponseType.VIDEO &&
                                            this.getResponseVideoDisplay(stepId, media, thumbnail, response_video_transcript_text, is_completed)
                                        }
                                        {response_type === eResponseType.TEXT &&
                                            getValue(response_text, <span className={'no-response'}>No response received.</span>)
                                        }
                                        {response_type === eResponseType.DOCUMENT &&
                                            this.getResponseDocumentDisplay(response_document_url)
                                        }
                                    </div>

                                    {response_type === eResponseType.TEXT && (
                                        <Tooltip
                                            classes="word-count"
                                            positioning={`onTop`}
                                            item={IconWordCount}
                                            description={
                                                <React.Fragment>
                                                    {words(response_text || '').length} words<br />
                                                    {(response_text || '').length} characters
                                                </React.Fragment>
                                            }
                                        />
                                    )}
                                </div>

                                <div className={this.getEvaluationsComponentClassNames(showEvaluationGuide)}>
                                    {isEvaluatorView && [1].map((i) => {
                                        const { score, notes } = questions_evaluations.find(({job_interview_step_id}) => job_interview_step_id === stepId) || {};
                                        const hasRatings = !!requires_star_rating && !exclude_from_scoring;

                                        return (
                                            <React.Fragment key={`evaluator-evaluation-${i}`}>
                                                {showEvaluationGuide && (
                                                    <div className={`evaluation-guide`}>
                                                        <div className={`guide-header section-title`}>Evaluation Guide</div>
                                                        <div className={'guide-content'}>{evaluation_guide}</div>
                                                    </div>
                                                )}
                                                <div className={`ratings-title`}>
                                                    {this.getRatingsTitle(hasRatings)}
                                                </div>
                                                {hasRatings && (
                                                    <Ratings
                                                        id={`${stepId}`}
                                                        type={eRatingsType.STAR}
                                                        classes={`evaluator-rating`}
                                                        active={!submitted_at}
                                                        score={score}
                                                        showTooltip={false}
                                                        onChange={(e, value) => onEvaluationChange(e, stepId, "score", value)} />
                                                )}
                                                <CustomTextArea
                                                    classes={`evaluator-comment`}
                                                    type={eTextAreaType.STANDARD}
                                                    id={`${stepId}-comment`}
                                                    label="Comment"
                                                    value={notes}
                                                    readOnly={!!submitted_at}
                                                    onChange={(e) => onEvaluationChange(e, stepId, "notes", e.target.value)}
                                                    onBlur={(e) => !submitted_at && onEvaluationUpdate(e)}
                                                />
                                                <button className={`link next-question`} onClick={() => document.getElementById(nextItem.id).scrollIntoView({ behavior: 'smooth'})}>
                                                    {nextItem.label}
                                                </button>
                                            </React.Fragment>
                                        );
                                    })}

                                    {isManagerView && evaluations.map((evaluation, i) => {
                                        const { questions: qs, staff_id } = evaluation;
                                        const {first_name, last_name, picture_url} = this.getEvaluator(staff_id);
                                        const avatarData = {
                                            id: staff_id,
                                            first_name,
                                            last_name,
                                            picture_url
                                        };

                                        const {notes, score} = findLast(qs, ({job_interview_step_id}) => job_interview_step_id === stepId) || {};

                                        return (
                                            <div key={`evaluation-${i}`} className={`evaluation`}>
                                                <Avatar classes={`evaluator-avatar`} size="xs" data={avatarData} />
                                                <div className={`evaluator-name`}>
                                                    {first_name} {last_name}</div>
                                                <div className={`notes-and-ratings`}>
                                                    <Ratings type={eRatingsType.STAR} classes={`rating`} score={score} />
                                                    <div className={`notes`}>{notes}</div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

CandidateResponses.propTypes = {
    view_type: PropTypes.oneOf(objectToArray(eUserTypes)),
    questions: PropTypes.array,
    responses: PropTypes.array,
    requires_star_rating: PropTypes.bool,
    evaluators: PropTypes.array,
    evaluations: PropTypes.array,
    // evaluation_attributes: PropTypes.array,
    // evaluation_structure: PropTypes.array,
    onEvaluationChange: PropTypes.func,
    onEvaluationUpdate: PropTypes.func,
    submitted_at: PropTypes.string
};

CandidateResponses.defaultProps = {
    view_type: eUserTypes.MANAGER,
    questions: [],
    responses: [],
    requires_star_rating: false,
    evaluators: [],
    evaluations: [],
    // evaluation_attributes: [],
    // evaluation_structure: [],
    onEvaluationChange: null,
    onEvaluationUpdate: null,
    submitted_at: ''
};

export default CandidateResponses;
