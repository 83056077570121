import React from "react";
import * as PropTypes from "prop-types";
import LabelValueTile from "../../../components/LabelValueTile";

import './style.scss';


class CompanyDetails extends React.Component {
    render() {
        const {
            address,
            email,
            business_number,
            country,
            post_interview_destination_href,
            logo_url,
            postcode,
            state,
            suburb
        } = this.props;
        return (
            <div className="company-details">
                <div className="column-left">
                    <img className="logo" src={logo_url} alt="Company logo" />
                </div>
                <div className="column-right">
                    <LabelValueTile classes="phone" label="Phone" value={business_number} />
                    <LabelValueTile classes="email" label="Email" value={email} />
                    <LabelValueTile classes="address" label="Address">
                        <span>{address}</span>
                        <span>{((suburb || "") + " " + (state || "") + " " + (postcode || "")).trim()}</span>
                        <span>{country}</span>
                    </LabelValueTile>
                    <LabelValueTile classes="website" label="Website">
                        <a href={post_interview_destination_href} target="_blank" rel="noopener noreferrer">{post_interview_destination_href}</a>
                    </LabelValueTile>
                </div>
            </div>
        );
    }
}

CompanyDetails.propTypes = {
    address: PropTypes.string,
    email: PropTypes.string,
    business_number: PropTypes.string,
    country: PropTypes.string,
    post_interview_destination_href: PropTypes.string,
    logo_url: PropTypes.string,
    postcode: PropTypes.string,
    state: PropTypes.string,
    suburb: PropTypes.string
};

CompanyDetails.defaultProps = {
    address: null,
    email: null,
    business_number: null,
    country: null,
    post_interview_destination_href: null,
    logo_url: null,
    postcode: null,
    state: null,
    suburb: null
};

export default CompanyDetails;
