import React from 'react';

import './style.scss';

const MaintenancePage = () => {
    return (
        <div className={`maintenance-page`}>
            The system is temporarily offline for maintenance.
        </div>
    );
}

export default MaintenancePage;