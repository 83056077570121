import React from "react";
import * as PropTypes from "prop-types";
import CustomModal from "../CustomModal";

import './style.scss';


class Notice extends React.Component {
    constructor(props) {
        super(props);

        this.openNotice = this.openNotice.bind(this);
        this.closeNotice = this.closeNotice.bind(this);

        this.state = {
            isNoticeOpen: false
        };
    }

    shallShowNotice() {
        const { tenant_id } = this.props;
        const excludedTenants = [
            'bomcm',
            'justicevic',
            'niaa',
            'svitzer',
            'servicesaustralia'
        ];

        if (tenant_id) {
            const isExcludedTenant = excludedTenants.includes(tenant_id);
            return !isExcludedTenant;
        }

        return false;
    }
    openNotice() {
        this.setState({
            isNoticeOpen: true
        });
    }
    closeNotice() {
        this.setState({
            isNoticeOpen: false
        });
    }
    render() {
        const { isNoticeOpen } = this.state;

        if (!this.shallShowNotice()) {
            return null;
        }

        return (
            <>
                {isNoticeOpen && (
                    <CustomModal classes={`notice-dialog open`} >
                        <div className={'notice-dialog-header'}>
                            &nbsp;
                        </div>
                        <div className={'notice-dialog-content'}>
                            <span className={'notice-dialog-content-header'}>We're moving!</span>
                            <br/><br/>
                            Your Account Manager will be reaching out to migrate this account, its users, and
                            content libraries to the new Criteria Video Platform.<br />
                            <br />
                            To learn more about this process, migrate sooner, or schedule training on the new platform,
                            please contact us at:<br/>
                            <a href="mailto:APAC.success@criteriacorp.com">APAC.success@criteriacorp.com</a>
                            <br/><br/>
                            <div className={`cta`}>
                                <button className={'react-button'} onClick={this.closeNotice}>I understand</button>
                            </div>
                        </div>
                    </CustomModal>
                )}
                <div className={'notice-banner'} onClick={this.openNotice}>
                    <strong>We're moving!</strong> Click here to learn more.
                </div>
            </>
        );
    }
}

Notice.propTypes = {
    tenant_id: PropTypes.string,
    integration_providers: PropTypes.array
};

export default Notice;
