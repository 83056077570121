import React from "react";
import CustomTextField from "../Form/CustomTextField";
import {
    asFormData,
    eConfirmDialogResponseType,
    eDropdownTypes,
    eUserSettings,
    generateTemporaryId,
    isAdminUserSettingEnabled,
    isValidURL
} from "../../utils";
import TypedDropdown from "../TypedDropdown";
import {createTenant, getTenant, getTenants, updateTenant} from "../../api";
import Container from "../Container";
import LabelValueTile from "../LabelValueTile";
import CustomRadioButton from "../Form/CustomRadioButton";
import {sortBy} from "lodash";

import './style.scss';


const PAGE_MODES = {
    CREATE: "create",
    UPDATE: "update"
};

const INTEGRATION_TYPES = {
    NONE: "",
    CRITERIA: "criteria",
    ATS: "ats"
};

const DEFAULT_TENANT_STATE = {
    tenantId: null,
    displayName: null,
    postInterviewDestinationHref: null,

    logoFile: null,
    logoImage: null,
    logoUrl: null,

    defaultTimezone: null,

    emailFromName: null,
    interviewTerminologyForNewJobs: null,

    address: null,
    suburb: null,
    state: null,
    postcode: null,
    country: null,
    businessNumber: null,

    phoneCountry: null,
    integrationType: INTEGRATION_TYPES.NONE,
    criteriaCompanyAccountId: null,

    confirmDialogProps: null,
    confirmDialogResponseProps: null,

    pageMode: PAGE_MODES.CREATE,
    isNoticeShown: false
}

class Tenant extends React.Component {
    constructor(props) {
        super(props);

        this.retrieveTenants = this.retrieveTenants.bind(this);
        this.loadTenant = this.loadTenant.bind(this);
        this.clearTenant = this.clearTenant.bind(this);
        this.isCreateMode = this.isCreateMode.bind(this);
        this.isUpdateMode = this.isUpdateMode.bind(this);
        this.toggleNotice = this.toggleNotice.bind(this);
        this.renderNotice = this.renderNotice.bind(this);
        this.renderTenantsList = this.renderTenantsList.bind(this);
        this.renderIntegrationTypes = this.renderIntegrationTypes.bind(this);
        this.isFieldError = this.isFieldError.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onTimezoneSelect = this.onTimezoneSelect.bind(this);
        this.handleLogoUpload = this.handleLogoUpload.bind(this);
        this.removeLogo = this.removeLogo.bind(this);
        this.isTenantIdValid = this.isTenantIdValid.bind(this);
        this.isFormSubmittable = this.isFormSubmittable.bind(this);
        this.getSubmitButtonClassNames = this.getSubmitButtonClassNames.bind(this);
        this.getSubmitButtonAction = this.getSubmitButtonAction.bind(this);
        this.confirmFormSubmission = this.confirmFormSubmission.bind(this);
        this.cancelConfirmFormSubmission = this.cancelConfirmFormSubmission.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.getUpdatedTenants = this.getUpdatedTenants.bind(this);
        this.showDialog = this.showDialog.bind(this);

        this.state = {
            tenants: [],
            ...DEFAULT_TENANT_STATE,
            temporaryId: generateTemporaryId()
        }
    }

    async retrieveTenants() {
        const tenants = await getTenants();

        this.setState({
            tenants: sortBy(tenants, "tenant_id")
        });
    }

    async loadTenant(tenantId) {
        const tenant = await getTenant(tenantId);
        this.setState({
            ...DEFAULT_TENANT_STATE,
            ...tenant,
            temporaryId: generateTemporaryId(),
            pageMode: PAGE_MODES.UPDATE
        });
    }

    clearTenant() {
        this.setState({
            ...DEFAULT_TENANT_STATE,
            temporaryId: generateTemporaryId()
        });
    }

    isCreateMode() {
        const { pageMode } = this.state;
        return pageMode === PAGE_MODES.CREATE;
    }

    isUpdateMode() {
        const { pageMode } = this.state;
        return pageMode === PAGE_MODES.UPDATE;
    }

    toggleNotice() {
        const { isNoticeShown } = this.state;

        const updatedState = {
            isNoticeShown: !isNoticeShown
        };

        if (!isNoticeShown) {
            updatedState.confirmDialogProps = {
                classes: "notice",
                message: this.renderNotice(),
                actions: [
                    {
                        label: "OK",
                        onClick: () => this.toggleNotice()
                    }
                ]
            }
        } else {
            updatedState.confirmDialogProps = null;
        }

        this.setState(updatedState);
    }

    renderNotice() {
        return (
            <div className={`notice`}>
                <p className={`header`}>IMPORTANT</p>
                <p>
                    Once you have picked a tenant ID, we pretty much can't change it, and obviously can't reuse it
                    for any other customer.  Please have a careful think about whether the tenant ID you pick is:<br />
                    (a) appropriate, and<br/>
                    (b) not going to be problematic if/when another client with a similar name comes along
                    (For example:  it's a good thing we picked "nztreasury" rather than
                    just "treasury" when that client came on board.)
                </p>
                <p>
                    Going with a customer's
                    domain name is often pretty reasonable.  If the customer is an RPO customer, it may not
                    be appropriate to use their end customer's name, given that it's not impossible we
                    end up with that end customer as a customer of ours in future, and find that the name
                    is taken.  (Eg our problems with homeaffairs and depthomeaffairs.)
                </p>
                <p>If you have any doubt about whether a particular tenant ID is appropriate, ask before you create anything.</p>
            </div>
        );
    }

    renderTenantsList() {
        const { tenants, tenantId } = this.state;
        return (
            <div className={`list`}>
                <div className={`list-header`}>Tenants</div>
                <div className={`tenant-item tenant-new selected-${this.isCreateMode()}`} onClick={this.clearTenant}>Create New Tenant...</div>
                <div className={`list-items`}>
                    {tenants.map(({tenant_id, display_name}) => {
                        const isSelected = tenant_id === tenantId;
                        return (
                            <div className={`tenant-item selected-${isSelected}`} onClick={() => this.loadTenant(tenant_id)}>
                                <div className={`display_name`}>{display_name}</div>
                                <div className={`tenant_id`}>{tenant_id}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        )
    }

    renderIntegrationTypes() {
        const { integrationType, criteriaCompanyAccountId, temporaryId } = this.state;
        return (
            <div className={`integrationType`}>
                <div className={`value`}>
                    {isAdminUserSettingEnabled(eUserSettings.TENANT_INTEGRATION_TYPE) && (
                        <React.Fragment>
                            <CustomRadioButton
                                id={'integrationType-none'}
                                classes="selection selection-none"
                                value={`None`}
                                selected={integrationType === INTEGRATION_TYPES.NONE}
                                onChange={() => this.onInputChange({target: {id: "integrationType", value: INTEGRATION_TYPES.NONE}})}
                            />
                            <CustomRadioButton
                                id={'integrationType-ats'}
                                classes="selection selection-ats"
                                value={`ATS Integration`}
                                selected={integrationType === INTEGRATION_TYPES.ATS}
                                onChange={() => this.onInputChange({target: {id: "integrationType", value: INTEGRATION_TYPES.ATS}})}
                            />
                            <CustomRadioButton
                                id={'integrationType-criteria'}
                                classes="selection selection-criteria"
                                value={`Criteria`}
                                selected={integrationType === INTEGRATION_TYPES.CRITERIA}
                                onChange={() => this.onInputChange({target: {id: "integrationType", value: INTEGRATION_TYPES.CRITERIA}})}
                            />
                        </React.Fragment>
                    )}
                    <CustomTextField
                        key={`criteriaCompanyAccountId-${temporaryId}`}
                        id={`criteriaCompanyAccountId`}
                        classes={`criteriaCompanyAccountId ${temporaryId}`}
                        name={`criteriaCompanyAccountId`}
                        required={integrationType === INTEGRATION_TYPES.CRITERIA}
                        disabled={isAdminUserSettingEnabled(eUserSettings.TENANT_INTEGRATION_TYPE) && integrationType !== INTEGRATION_TYPES.CRITERIA}
                        label={`Criteria Company Account ID`}
                        value={criteriaCompanyAccountId}
                        onChange={this.onInputChange}
                    />
                </div>
            </div>
        );
    }

    isFieldError(field) {
        return field !== null && !field;
    }

    onInputChange(e) {
        const {target: {id, value}} = e;
        this.setState({
            [id]: value
        });
    }

    onTimezoneSelect(value) {
        this.setState({
            defaultTimezone: value
        });
    }

    handleLogoUpload(event) {
        const file = event.target.files[0];

        const reader  = new FileReader();

        reader.addEventListener("load", () => {
            this.setState({
                logoFile: file,
                logoImage: reader.result,
                logoUrl: null
            });
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    removeLogo() {
        this.setState({
            logoFile: null,
            logoImage: null,
            logoUrl: null
        });
    }

    isTenantIdValid() {
        const { tenantId, tenants } = this.state;

        if (this.isUpdateMode() || !tenantId) {
            return true;
        }

        const tenantIdValue = tenantId.trim();
        return !!tenantIdValue &&
            !tenants.find(({tenant_id}) => tenant_id === tenantIdValue) &&
            tenantIdValue.match(/^[a-z0-9](?:[a-z0-9_-]*[a-z0-9])?$/);
    }

    isFormSubmittable() {
        const {
            displayName,
            postInterviewDestinationHref,
            defaultTimezone,
            integrationType,
            criteriaCompanyAccountId
        } = this.state;
        return this.isTenantIdValid() &&
            !!displayName &&
            postInterviewDestinationHref && isValidURL(postInterviewDestinationHref) &&
            defaultTimezone &&
            (
                !integrationType ||
                integrationType === INTEGRATION_TYPES.ATS ||
                (integrationType === INTEGRATION_TYPES.CRITERIA && !!criteriaCompanyAccountId)
            );
    }

    getSubmitButtonClassNames() {
        let returnValue = `react-button create-tenant-button`;

        if (!this.isFormSubmittable()) {
            returnValue += ` disabled`;
        }

        return returnValue;
    }

    getSubmitButtonAction() {
        if (this.isFormSubmittable()) {
            const { pageMode } = this.state;
            if (pageMode === PAGE_MODES.CREATE) {
                return this.submitForm;
            } else {
                return this.confirmFormSubmission;
            }
        }

        return null;
    }

    confirmFormSubmission() {
        this.setState({
            confirmDialogProps: {
                title: "Update Tenant",
                message: "Are you sure you want to submit these changes?",
                actions: [
                    {
                        label: "Cancel",
                        classes: "cancel-submit-button",
                        autoFocus: true,
                        onClick: this.cancelConfirmFormSubmission
                    },
                    {
                        label: "Submit",
                        // classes: "primary",
                        onClick: () => {
                            this.cancelConfirmFormSubmission();
                            this.submitForm();
                        }
                    }
                ]
            }
        }, );
    }

    cancelConfirmFormSubmission() {
        this.setState({
            confirmDialogProps: null
        });
    }

    addIntegrationDetailsToFormData(data) {
        const { integrationType, criteriaCompanyAccountId } = this.state;
        if (isAdminUserSettingEnabled(eUserSettings.TENANT_INTEGRATION_TYPE)) {
            if (integrationType) {
                data.integrationType = integrationType;

                if (integrationType === INTEGRATION_TYPES.CRITERIA) {
                    data.criteriaCompanyAccountId = criteriaCompanyAccountId;
                }
            }
        } else if (!!criteriaCompanyAccountId) {
            data.criteriaCompanyAccountId = criteriaCompanyAccountId;
        }

        return data;
    }

    async submitForm() {
        const {
            tenantId,
            displayName,
            emailFromName,
            interviewTerminologyForNewJobs,
            postInterviewDestinationHref,
            defaultTimezone,

            address,
            suburb,
            state,
            postcode,
            country,
            businessNumber,

            // phoneCountry,
            // integrationType,
            // criteriaCompanyAccountId,
            logoFile,

            pageMode
        } = this.state;

        let data = {
            tenantId: tenantId.trim(),
            displayName,
            postInterviewDestinationHref,
            defaultTimezone
        };

        if (emailFromName) {
            data.emailFromName = emailFromName;
        }

        if (interviewTerminologyForNewJobs) {
            data.interviewTerminologyForNewJobs = interviewTerminologyForNewJobs;
        }

        if (address) {
            data.address = address;
        }
        if (suburb) {
            data.suburb = suburb;
        }
        if (state) {
            data.state = state;
        }
        if (postcode) {
            data.postcode = postcode;
        }
        if (country) {
            data.country = country;
        }
        if (businessNumber) {
            data.businessNumber = businessNumber;
        }

        if (logoFile) {
            data.logoImage = logoFile;
        }

        data = this.addIntegrationDetailsToFormData(data);

        const formData = asFormData(data);

        const response = this.isCreateMode() ?
            await createTenant(formData) :
            await updateTenant(tenantId, formData);

        if (response.error) {
            this.showDialog(eConfirmDialogResponseType.ERROR, `${response.error}`);
        } else {
            const tenants = this.getUpdatedTenants(response);

            this.setState({
                ...response,
                tenants: sortBy(tenants, "tenant_id"),
                pageMode: PAGE_MODES.UPDATE
            });
            this.showDialog(eConfirmDialogResponseType.SUCCESS, `Tenant ${pageMode}d.`);
        }
    }

    getUpdatedTenants(response) {
        let { tenants } = this.state;

        if (this.isCreateMode()) {
            tenants.push({tenant_id: response.tenantId, display_name: response.displayName});
        } else {
            tenants = tenants.map((tenant) => {
                if (tenant.tenant_id === response.tenantId) {
                    return {
                        ...tenant,
                        display_name: response.displayName
                    }
                } else {
                    return tenant;
                }
            })
        }

        return tenants;
    }

    showDialog(type, sentence) {
        this.setState({
            confirmDialogResponseProps: {
                type,
                sentence,
                onClose: () => {
                    this.setState({confirmDialogResponseProps: null})
                }
            }
        });
    }

    async componentDidMount() {
        await this.retrieveTenants();
        this.toggleNotice();
    }

    render() {
        const {
            tenantId,
            displayName,
            postInterviewDestinationHref,
            defaultTimezone,
            logoImage,
            logoUrl,
            emailFromName,
            interviewTerminologyForNewJobs,
            address,
            suburb,
            state,
            postcode,
            country,
            businessNumber,
            // phoneCountry,
            confirmDialogProps,
            confirmDialogResponseProps,
            temporaryId
        } = this.state;

        const hasLogo = !!logoImage || !!logoUrl;
        const submitButtonLabel = this.isCreateMode() ? "Create" : "Update";

        return (
            <Container
                loading={false}
                confirmDialogProps={confirmDialogProps}
                confirmDialogResponseProps={confirmDialogResponseProps}
            >
                <div className={`tenant`}>
                    {this.renderTenantsList()}

                    <div className={`form-container`}>
                        <div className={`form`}>
                            <div className={`section section-one`}>
                                <div className={`sub-title`}>Tenant Details</div>
                                {this.isCreateMode() && (
                                    <CustomTextField
                                        id={`tenantId`}
                                        name={`tenantId`}
                                        required={true}
                                        label={`Tenant ID`}
                                        helperText={`This will become part of the domain name`}
                                        value={tenantId}
                                        isError={this.isFieldError(tenantId) || !this.isTenantIdValid()}
                                        onChange={this.onInputChange}
                                    />
                                )}
                                {this.isUpdateMode() && (
                                    <LabelValueTile classes="tenantId" label="Tenant ID" value={tenantId} />
                                )}
                                <CustomTextField
                                    key={`displayName-${temporaryId}`}
                                    id={`displayName`}
                                    name={`displayName`}
                                    required={true}
                                    label={`Display Name`}
                                    value={displayName}
                                    isError={this.isFieldError(displayName)}
                                    onChange={this.onInputChange}
                                />
                                <CustomTextField
                                    key={`postInterviewDestinationHref-${temporaryId}`}
                                    id={`postInterviewDestinationHref`}
                                    name={`postInterviewDestinationHref`}
                                    required={true}
                                    label={`Post Interview URL`}
                                    value={postInterviewDestinationHref}
                                    isError={this.isFieldError(postInterviewDestinationHref) || !isValidURL(postInterviewDestinationHref, false)}
                                    onChange={this.onInputChange}
                                />
                                <TypedDropdown
                                    id={"defaultTimezone"}
                                    key={`defaultTimezone-${temporaryId}`}
                                    label={`Timezone`}
                                    dropdownType={eDropdownTypes.TIMEZONES}
                                    required={true}
                                    initialValue={defaultTimezone}
                                    isMultipleSelection={false}
                                    showDropdownOnInitialFocus={true}
                                    onUpdate={this.onInputChange}
                                />
                                <div className={`sub-title`}>Email Details</div>
                                <CustomTextField
                                    id={`emailFromName`}
                                    name={`emailFromName`}
                                    required={false}
                                    label={`From Name`}
                                    helperText={`For example: 'Careers at MyCompanyName'. If you leave this empty, the From Name
                                     will use the Display Name above and prepend 'Careers at ', e.g. 'Careers at MyCompanyName'`}
                                    value={emailFromName}
                                    isError={this.isFieldError(emailFromName)}
                                    onChange={this.onInputChange}
                                />
                                <div className={`sub-title`}>Job Details</div>
                                <TypedDropdown
                                    id={`interviewTerminologyForNewJobs`}
                                    key={`interviewTerminology-${temporaryId}`}
                                    label={`Interview Terminology`}
                                    dropdownType={eDropdownTypes.INTERVIEW_TERMINOLOGY}
                                    helperText={`Applies to new jobs only (i.e. change will not affect existing jobs, including draft jobs). The default value is 'interview'.`}
                                    required={false}
                                    initialValue={interviewTerminologyForNewJobs}
                                    isMultipleSelection={false}
                                    showDropdownOnInitialFocus={true}
                                    onUpdate={this.onInputChange}
                                />

                            </div>

                            <div className={`section section-two`}>
                                <div className={`sub-title`}>Company Details</div>
                                <CustomTextField
                                    id={`address`}
                                    name={`address`}
                                    required={false}
                                    label={`Street`}
                                    value={address}
                                    onChange={this.onInputChange}
                                />
                                <CustomTextField
                                    id={`suburb`}
                                    name={`suburb`}
                                    required={false}
                                    label={`Suburb`}
                                    value={suburb}
                                    onChange={this.onInputChange}
                                />
                                <div className={`state-postcode`}>
                                    <CustomTextField
                                        id={`state`}
                                        name={`state`}
                                        required={false}
                                        label={`State`}
                                        value={state}
                                        onChange={this.onInputChange}
                                    />
                                    <CustomTextField
                                        id={`postcode`}
                                        name={`postcode`}
                                        required={false}
                                        label={`Postcode`}
                                        value={postcode}
                                        onChange={this.onInputChange}
                                    />
                                </div>
                                <CustomTextField
                                    id={`country`}
                                    name={`country`}
                                    required={false}
                                    label={`Country`}
                                    value={country}
                                    onChange={this.onInputChange}
                                />
                                <CustomTextField
                                    id={`businessNumber`}
                                    name={`businessNumber`}
                                    required={false}
                                    label={`Business Number`}
                                    value={businessNumber}
                                    onChange={this.onInputChange}
                                />
                                <div className={`logo-image`}>
                                    <div className={`logo-label`}>Logo</div>
                                    {!hasLogo && (
                                        <div className={`logo-upload`}>
                                            <span className={`logo-upload-link`}>
                                                Upload
                                            </span>
                                            <input
                                                type="file"
                                                onClick={(e) => e.target.value = null}
                                                onChange={this.handleLogoUpload}
                                                accept="image/*"
                                                title=""
                                            />
                                        </div>
                                    )}
                                    {hasLogo && (
                                        <React.Fragment>
                                            <img className={`logo-preview`} src={logoImage || logoUrl} alt={`logo`} />
                                            <div className={`logo-remove`} onClick={this.removeLogo}>Remove</div>
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>

                            <div className={`section section-three`}>
                                <div className={`sub-title`}>Integration Details</div>
                                {this.renderIntegrationTypes()}
                            </div>

                            <div
                                className={this.getSubmitButtonClassNames()}
                                onClick={this.getSubmitButtonAction()}
                            >
                                {submitButtonLabel}
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        )
    }
}

export default Tenant;
