import React from 'react';
import * as PropTypes from "prop-types";
import Loader, { eLoaderSize } from "../Loader";
import {objectToArray} from "../../utils";
import ConfirmDialog from "../ConfirmDialog";
import ConfirmDialogResponse from "../ConfirmDialog/Response";

import './style.scss';

export const eErrorPageTypes = {
    NONE: "",
    NOT_AVAILABLE: "not-available",
    NOT_FOUND: "not-found"
};

const errorMessages = {
    "not-available": "This page is currently not available.",
    "not-found": "This page cannot be found."
};

const Container = ({loading, confirmDialogProps, confirmDialogResponseProps, error, children}) => (
    <div className={`react-container ${loading ? 'loading' : ''}`}>
        {loading && (
            <Loader size={eLoaderSize.PAGE_WITH_HEADER} />
        )}
        {!loading && (
            <React.Fragment>
                {error && (
                    <div className={`page-error ${error}`}>
                        {errorMessages[error] || errorMessages[eErrorPageTypes.NOT_AVAILABLE]}
                    </div>
                )}
                {!error && children}
            </React.Fragment>
        )}
        <ConfirmDialog {...confirmDialogProps} />
        <ConfirmDialogResponse {...confirmDialogResponseProps} />
    </div>
);

Container.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.oneOf(objectToArray(eErrorPageTypes))
};

Container.defaultProps = {
    loading: true,
    error: eErrorPageTypes.NONE
};

export default Container;
