import React from "react";
import * as PropTypes from "prop-types";
import {eLibraryQuestionType, eQuestionDisplayType, libraryAllTag, objectToArray} from "../../utils";
import QuestionThumbnail from "../QuestionThumbnail";
import MediaPreview from "../MediaPreview";
import { isEqual } from "lodash";


export class QuestionThumbnails extends React.Component {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.openVideo = this.openVideo.bind(this);
        this.changeVideo = this.changeVideo.bind(this);
        this.closeVideo = this.closeVideo.bind(this);

        this.state = {
            isVideoOpen: false,
            selectedVideoId: null
        };
    }

    openVideo(videoId) {
        this.setState({
            isVideoOpen: true,
            selectedVideoId: videoId
        });
    }

    onClick(videoId, areMultipleVideos = true) {
        if (videoId) {
            if (areMultipleVideos) {
                this.onSelect(videoId);
            }
            this.openVideo(videoId);
        }
    }

    onSelect(videoId) {
        const { onSelect, questionType, questionId } = this.props;
        if (onSelect) {
            onSelect(questionType, questionId, videoId);
        }
    }

    changeVideo(videoId) {
        this.setState({
            selectedVideoId: videoId
        });
    }

    closeVideo() {
        this.setState({
            isVideoOpen: false,
            selectedVideoId: null
        });
    }

    /*getSortedVideos() {
        const { videos } = this.props;
        return sortBy(videos, ({video_tags}) => (video_tags[0] || "zzz").toLowerCase());
    }*/

    shouldComponentUpdate(nextProps, nextState) {
        return !isEqual(this.state, nextState) || !isEqual(this.props, nextProps);
    }

    render() {
        const {
            classes,
            questionType,
            questionId,
            displayText,
            videos,
            displayType,
            selectedStockVideoId,
            selectedTenantVideoId,
            selectedVideoTags,
            questionNumber
        } = this.props;

        const { isVideoOpen, selectedVideoId } = this.state;

        const isStockLibraryQuestion = questionType === eLibraryQuestionType.STOCK;
        const areMultipleVideos = videos.length > 1;

        return (
            <div className={`question-thumbnails ${classes}`}>
                {videos.map(
                    ({
                         image,
                         video,
                         stock_library_question_video_id,
                         tenant_library_question_video_id,
                         video_tags
                     }) => {
                        const videoId = isStockLibraryQuestion ? stock_library_question_video_id : tenant_library_question_video_id;

                        // if question is not selected, then select the first item
                        const selected = isStockLibraryQuestion ?
                            stock_library_question_video_id === selectedStockVideoId :
                            tenant_library_question_video_id === selectedTenantVideoId;

                        const key = `${questionType}-${questionId}-${videoId}`;
                        const tagsUnselected = (selectedVideoTags.length === 0 || selectedVideoTags.includes(libraryAllTag)) ?
                            false :
                            !video_tags.find(video_tag => selectedVideoTags.includes(video_tag));

                        if (displayType === eQuestionDisplayType.SELECTING) {
                            return (
                                <QuestionThumbnail
                                    key={key}
                                    questionNumber={questionNumber}
                                    image={image}
                                    text={displayText}
                                    mediaURL={video}
                                    selected={areMultipleVideos && selected}
                                    tagsUnselected={tagsUnselected}
                                    onClick={!!video ? () => this.onClick(videoId, areMultipleVideos) : null}
                                    onSelect={areMultipleVideos ? () => this.onSelect(videoId) : null}
                                />
                            );
                        } else if (displayType === eQuestionDisplayType.EDITING) {
                            return (
                                <QuestionThumbnail
                                    key={key}
                                    questionNumber={questionNumber}
                                    image={image}
                                    text={displayText}
                                    mediaURL={video}
                                    // tagsUnselected={tagsUnselected}
                                    // onClick={!isEditing && this.openVideo(videoId)}
                                    // selected={selected}
                                    // onSelect={() => onVideoSelect(questionType, questionId, videoId)}
                                />
                            );
                        } else {
                            return (
                                <QuestionThumbnail
                                    key={key}
                                    questionNumber={questionNumber}
                                    image={image}
                                    // text={displayText}
                                    mediaURL={video}
                                    tagsUnselected={tagsUnselected}
                                    // onClick={!isEditing && this.openVideo(videoId)}
                                    // selected={selected}
                                    // onSelect={() => onVideoSelect(questionType, questionId, videoId)}
                                />
                            );
                        }
                    }
                )}
                {isVideoOpen && (
                    <MediaPreview
                        items={videos}
                        displayText={displayText}
                        selectedItemId={selectedVideoId}
                        onChange={this.onClick}
                        onClose={this.closeVideo}
                    />
                )}
            </div>
        )
    }
}

QuestionThumbnails.propTypes = {
    classes: PropTypes.string,
    questionType: PropTypes.string,
    questionId: PropTypes.number,
    displayText: PropTypes.string,
    videos: PropTypes.array,
    isStockLibraryQuestion: PropTypes.bool,
    displayType: PropTypes.oneOf(objectToArray(eQuestionDisplayType)),
    selectedStockVideoId: PropTypes.number,
    selectedTenantVideoId: PropTypes.number,
    selectedVideoTags: PropTypes.array,
    questionNumber: PropTypes.number,
    onSelect: PropTypes.func
};

QuestionThumbnails.defaultProps = {
    classes: "",
    questionType: "",
    questionId: null,
    displayText: "",
    videos: [],
    isStockLibraryQuestion: true,
    displayType: eQuestionDisplayType.VIEWING,
    selectedStockVideoId: null,
    selectedTenantVideoId: null,
    selectedVideoTags: [],
    questionNumber: null,
    onSelect: null
};

export default QuestionThumbnails;
