import React from "react";
import * as PropTypes from "prop-types";
import CustomModal from "../../../CustomModal";
import { InterviewTemplateRow } from "../../../../pages/templates/interview";

import './style.scss';


class InterviewTemplateSelection extends React.Component {

    render() {
        const { items, selectedInterviewTemplateId, onSelect, onClose } = this.props;
        return (
            <CustomModal
                title={`Interview Template Selection`}
                classes={`interview-template-selection open`}
                closeOnOverlayClick={false}
                onClose={onClose}
            >
                <div className={`interview-templates`}>
                    <div className={`interview-templates-header header-row`}>
                        <div className={`header-column name`}>Name</div>
                        <div className={`header-column terminology`}>Terminology</div>
                        <div className={`header-column created-at`}>Date Created</div>
                        <div className={`header-column created-by`}>Created By</div>
                        <div className={`header-column jobs-associated`}>Jobs Associated</div>
                        <div className={`header-column questions`}>Questions</div>
                        <div className={'header-column action'}>&nbsp;</div>
                    </div>
                    {items.map((interview) => {
                        return (
                            <InterviewTemplateRow
                                interview={interview}
                                isSelected={interview?.interviewTemplateId === selectedInterviewTemplateId}
                                onSelect={() => onSelect(interview)}
                            />
                        );
                    })}
                </div>
            </CustomModal>
        );
    }
}

InterviewTemplateSelection.propTypes = {
    items: PropTypes.array,
    selectedInterviewTemplateId: PropTypes.number,
    onSelect: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};

InterviewTemplateSelection.defaultProps = {
    items: [],
    selectedInterviewTemplateId: null,
    onSelect: null,
    onClose: null
};

export default InterviewTemplateSelection;