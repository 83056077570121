import React from "react";
import * as PropTypes from "prop-types";
import { get, orderBy, reduce } from "lodash";
import AttributesScoring from "../CandidateProfile/AttributesScoring";
import CandidateDetails from "../CandidateProfile/CandidateDetails";
import CandidateFeedback from "../CandidateProfile/CandidateFeedback";
import CandidateResponses from "../CandidateProfile/CandidateResponses";
import CandidateLiveInterview from "../CandidateProfile/CandidateLiveInterview";
import ActionDropdown from "../ActionDropdown";
import { IconExclamationMark } from "../Icons";
import CandidatesPanel from "../CandidateProfile/CandidatesPanel";
import FinalComment from "../CandidateProfile/FinalComment";
import Recommendation from "../CandidateProfile/Recommendation";
import StatusPanel from "../CandidateProfile/StatusPanel";
import Container from "../Container";
import InterviewGuide from "./InterviewGuide";
import StatusMessage from "../StatusMessage";
import CandidateInterview from "../CandidateInterview";
import {
    getCandidate,
    unsubmitEvaluation,
    updateEvaluation
} from "../../api"
import {
    eCandidateLiveInterviewType,
    eDateFormatTypes,
    eInterviewStepType,
    eJobStageTypes,
    eRecommendationTypes,
    eUserTypes,
    eStatusMessageStyle,
    eStatusMessageType,
    eEndEvaluationType,
    isInIFrame,
    formatDateTime,
    objectToArray,
    getPageFeatures,
    eConfirmDialogResponseType
} from "../../utils";

import './style.scss';


class CandidateEvaluation extends CandidateInterview {
    constructor(props) {
        super(props);

        this.getManageProfileLink = this.getManageProfileLink.bind(this);
        this.loadData = this.loadData.bind(this);
        this.getComponentContainerClassNames = this.getComponentContainerClassNames.bind(this);
        this.prepareInformation = this.prepareInformation.bind(this);

        this.getInterviewQuestions = this.getInterviewQuestions.bind(this);
        this.getSelectedCandidate = this.getSelectedCandidate.bind(this);
        this.getSelectedEvaluation = this.getSelectedEvaluation.bind(this);
        this.switchToCandidateId = this.switchToCandidateId.bind(this);

        this.getCompletedCandidateInterviews = this.getCompletedCandidateInterviews.bind(this);

        this.onEvaluationChange = this.onEvaluationChange.bind(this);

        this.getEvaluationAttributes = this.getEvaluationAttributes.bind(this);
        this.onAttributeChange = this.onAttributeChange.bind(this);
        this.updateRecord = this.updateRecord.bind(this);

        this.isEvaluationComplete = this.isEvaluationComplete.bind(this);
        this.isMaybeRecommendation = this.isMaybeRecommendation.bind(this);
        this.getNextCandidateId = this.getNextCandidateId.bind(this);
        this.getActionItems = this.getActionItems.bind(this);

        this.getSubmitButtonClassNames = this.getSubmitButtonClassNames.bind(this);
        this.getSubmitButtonAction = this.getSubmitButtonAction.bind(this);
        this.getSubmitButtonLabel = this.getSubmitButtonLabel.bind(this);

        this.getSaveButtonClassNames = this.getSaveButtonClassNames.bind(this);
        this.getSaveButtonAction = this.getSaveButtonAction.bind(this);
        this.getSaveButtonLabel = this.getSaveButtonLabel.bind(this);

        this.updateStatusMessage = this.updateStatusMessage.bind(this);
        this.endEvaluation = this.endEvaluation.bind(this);
        this.unsubmitEvaluation = this.unsubmitEvaluation.bind(this);

        this.clearEvaluationConfirmation = this.clearEvaluationConfirmation.bind(this);
        this.clearEvaluationCancel = this.clearEvaluationCancel.bind(this);
        this.clearEvaluation = this.clearEvaluation.bind(this);

        this.showCompletedDialog = this.showCompletedDialog.bind(this);

        this.state = {
            loading: true,
            candidates: [],
            candidate: {},

            statusMessage: {
                type: null,
                message: null,
                duration: null,
                reset: null
            },

            // evaluation
            recommendation: null,
            submitted_at: null,
            is_unsubmittable: false,
            isSubmissionInProgress: false,
            isSavingInProgress: false,

            isError: false,
            confirmDialogResponseProps: null,
            confirmDialogProps: null
        };
    }

    async loadData() {
        const { staff_id, candidate_id } = this.props;

        // 01. Job Summary
        const retrieveJobSummaryCompleted = await this.retrieveJobSummary();

        if (!retrieveJobSummaryCompleted) {
            return false;
        }

        // 02. Job Stage
        const { evaluators } = this.getJobStage();
        const isStaffAnEvaluator = evaluators?.find((evaluator) => evaluator.staff_id === staff_id);

        if (!isStaffAnEvaluator) {
            this.setState({
                loading: false,
                isError: true,
                confirmDialogProps: {
                    title: "Not an Evaluator",
                    message: "You have not been assigned as an evaluator for this job.",
                    actions: [
                        {
                            label: "Back",
                            onClick: () => window.history.go(-1)
                        }
                    ]
                }
            });
            return false;
        }

        // 03. Candidates Summary
        await this.retrieveCandidateSummary();
        const { candidates } = this.state;
        const doesCandidateExist = candidates.find((candidate) => candidate.candidate_id === candidate_id);
        if (!doesCandidateExist) {
            this.setState({
                loading: false,
                isError: true,
                confirmDialogProps: {
                    title: "Candidate Not Found",
                    message: "You candidate evaluation you are trying to access cannot be found.",
                    actions: [
                        {
                            label: "Back",
                            onClick: () => window.history.go(-1)
                        }
                    ]
                }
            });
            return false;
        }

        // 04. Continue...
        return this.prepareInformation();
    }

    async prepareInformation() {
        this.setState({loading: true, confirmDialogProps: null});

        const {
            job_id,
            job_stage_id,
            candidate_id,
            staff_id
        } = this.props;

        const { job_stage_type } = this.getJobStage();

        const updatedState = {loading: false};

        const candidate = await getCandidate(job_id, job_stage_type, job_stage_id, candidate_id, staff_id);

        let evaluation = this.getSelectedEvaluation(candidate);

        // check if evaluations contains the current evaluation
        // if no evaluations, then create an empty evaluation
        if (!evaluation) {
            // Create an empty evaluation.
            evaluation = await updateEvaluation(staff_id, job_id, job_stage_type, job_stage_id, candidate_id, {});

            if (evaluation.error) {
                if (job_stage_type === eJobStageTypes.LIVE) {
                    // TASK: Enable API support
                    evaluation = {
                        candidate_id,
                        staff_id,
                        live_interview_notes: "",
                        submitted_at: null
                    };
                } else {
                    this.setState({
                        loading: false,
                        isError: true,
                        confirmDialogProps: {
                            title: "Application Not Found",
                            message: "The application you are trying to access cannot be found.",
                            actions: [
                                {
                                    label: "Back to Job Details",
                                    onClick: () => window.location.href = this.getAssessJobDetailsURL()
                                }
                            ]
                        }
                    });
                    return false;
                }
            }

            updatedState.candidate = {
                ...candidate,
                evaluations: [...(candidate.evaluations || []), evaluation]
            }
        } else {
            updatedState.candidate = candidate;
        }

        this.setState(updatedState);
        return true;
    }

    getComponentContainerClassNames() {
        const { integrationProvider } = this.props;
        const { showCandidateListInEvaluation } = getPageFeatures(integrationProvider);

        let returnValue = 'candidate-evaluation-container';

        if (integrationProvider) {
            returnValue += ' integration';
        }

        if (this.hasStatusPanel()) {
            returnValue += ' has-status-panel';
        }

        if (showCandidateListInEvaluation) {
            returnValue += ' has-list';
        }

        return returnValue;
    }

    getManageProfileLink() {
        const { candidate } = this.state;
        const { is_manager, integrationProvider } = this.props;
        const { showCandidateProfileLink } = getPageFeatures(integrationProvider);
        if (is_manager && showCandidateProfileLink) {
            return this.getManageCandidateProfileURL(candidate.candidate_id)
        }

        return null;
    }

    getManageCandidateProfileURL(candidate_id) {
       const { integrationProvider, job_id, job_stage_type, job_stage_id, urlFor } = this.props;
       return urlFor('manage-candidate-evaluation', { integrationProvider,
                                                      job_id,
                                                      job_stage_type,
                                                      job_stage_id,
                                                      candidate_id
                                                    }
       );
    }

    getCompletedCandidateInterviews() {
        const { candidates } = this.state;
        const completedInterviews = candidates?.filter(({is_completed}) => is_completed);

        const sortOrder = {
            "maybe": 1,
            "yes": 2,
            "no": 3
        };

        return orderBy(completedInterviews, ({submitted_at, recommendation}) => {
            return (!submitted_at ? 0 : 100) + (recommendation === null ? 0 : sortOrder[recommendation]);
        });
    }

    getInterviewQuestions() {
        const { candidate: { interview_steps } } = this.state;
        return (interview_steps || [])
            .filter(({interview_step_type}) => interview_step_type === eInterviewStepType.QUESTION);
    }

    getSelectedCandidate() {
        const { candidate_id } = this.props;
        const { candidates = [] } = this.getJobStage();
        return candidates.find((candidate) => candidate.candidate_id === candidate_id);
    }

    getSelectedEvaluation(payload = null) {
        const { staff_id } = this.props;
        const { candidate } = this.state
        return get(payload || candidate, "evaluations", []).find(
            (evaluation) => evaluation.staff_id === staff_id
        ) || {};
    }

    switchToCandidateId(candidate_id) {
        const { integrationProvider, job_id, urlFor, urlHasTrailingEvaluate } = this.props;
        window.location.href = urlFor('assess-candidate-evaluation', {
            integrationProvider,
            job_id,
            candidate_id,
            hasTrailingEvaluate: urlHasTrailingEvaluate
        });
    }

    onEvaluationChange(e, job_interview_step_id, name, value) {
        const { staff_id } = this.props;
        const { candidate } = this.state;

        this.setState({
            candidate: {
                ...candidate,
                evaluations: candidate.evaluations.map((evaluation) => {
                    if (evaluation.staff_id === staff_id) {
                        return {
                            ...evaluation,
                            questions: evaluation.questions.map((evaluation_item) => {
                                if (evaluation_item.job_interview_step_id === job_interview_step_id) {
                                    return {
                                        ...evaluation_item,
                                        [name]: value
                                    }
                                } else {
                                    return evaluation_item;
                                }
                            })
                        }
                    } else {
                        return evaluation;
                    }
                })
            }
        },() => {
            if (name === "score") {
                this.updateRecord(e);
            }
        });
    }

    getEvaluationAttributes() {
        const { evaluation_attributes = [] } = this.getJobStage();
        const { attributes = [] } = this.getSelectedEvaluation();
        return evaluation_attributes.map((evaluationAttribute) => {
            const matchedAttribute = attributes.find(({attribute}) => attribute === evaluationAttribute) || {};
            return {
                attribute: evaluationAttribute,
                score: matchedAttribute.score || null
            };
        });
    }

    onAttributeChange(e, name, value) {
        const attributes = this.getEvaluationAttributes();

        const updatedEvaluationAttributes = attributes.map(({attribute, score}) => {
            return {
                attribute: attribute,
                score: attribute === name ? value : score
            };
        });

        this.updateEvaluation(e, "attributes", updatedEvaluationAttributes);
    }

    updateEvaluation(e, name, value, sync = true) {
        e.persist();
        const { candidate } = this.state;
        const { staff_id } = this.props;

        let updatedEvaluation = {};

        this.setState({
            candidate: {
                ...candidate,
                evaluations: candidate.evaluations.map((evaluation) => {
                    if (evaluation.staff_id === staff_id) {
                        updatedEvaluation = {...evaluation, [name]: value};
                        return updatedEvaluation;
                    } else {
                        return evaluation;
                    }
                })
            }
        }, () => sync && this.updateRecord(e));

        return updatedEvaluation;
    }

    async updateRecord(e, submit = false) {
        const id = get(e, ["target", "id"], "unspecified");

        const { isSubmissionInProgress } = this.state;

        if (!submit && isSubmissionInProgress) {
            // if submission is already in progress, then any onBlur record update is unnecessary.
            return false;
        }

        const { job_id, staff_id, candidate_id, job_stage_type, job_stage_id } = this.props;
        const evaluation = {
            ...this.getSelectedEvaluation(),
            id,
            submit
        };
        const updated_evaluation = await updateEvaluation(staff_id, job_id, job_stage_type, job_stage_id, candidate_id, evaluation);

        // check if error
        if (updated_evaluation.error) {
            this.updateStatusMessage(
                <span>We are unable to save your changes. This evaluation might have already been submitted. Please <a href={window.location.href} onClick={() => window.location.reload(false)}>reload this page</a> and try again.</span>,
                eStatusMessageType.WARNING
            );
            return false;
        }

        if (submit) {
            const { submitted_at } = updated_evaluation;
            const { candidate: { candidate_id: candidateId }, candidates } = this.state;
            this.setState({
                candidates: candidates.map((candidate) => {
                    return candidate.candidate_id === candidateId ?
                        {...candidate, submitted_at } :
                        candidate;
                })
            });
            return this.updateEvaluation(e, `submitted_at`, submitted_at, false);
        } else {
            return updated_evaluation;
        }
    }

    isEvaluationComplete() {
        const jobStage = this.getJobStage();
        const evaluation = this.getSelectedEvaluation();
        const { recommendation } = evaluation;
        const { job_stage_type } = jobStage;

        if (job_stage_type === eJobStageTypes.ONE_WAY) {
            const { requires_response_evaluation } = jobStage;
            const { questions = [], attributes = [] } = evaluation;
            const scorableQuestionIds = this.getInterviewQuestions()
                .filter(({exclude_from_scoring}) => !exclude_from_scoring)
                .map(({job_interview_step_id}) => ({job_interview_step_id}));

            const areAllScorableQuestionsScored = reduce(scorableQuestionIds, (result, {job_interview_step_id: questionId}) => {
                const scoreRecord = questions.find(({job_interview_step_id}) => job_interview_step_id === questionId) || {};
                const isScored = get(scoreRecord, ["score"], null);
                return result && !!isScored;
            }, true);

            const areAllAttributesScored = reduce(attributes, (result, {score}) => {
                return result && !!score;
            }, true);

            const isRecommendationGiven = !!recommendation;

            return isRecommendationGiven && (
                (areAllScorableQuestionsScored || !requires_response_evaluation) &&
                (areAllAttributesScored || !attributes.length)
            );
        } else if (job_stage_type === eJobStageTypes.LIVE) {
            return !!recommendation;
        }
    }

    isMaybeRecommendation() {
        const { recommendation } = this.getSelectedEvaluation();
        return recommendation === eRecommendationTypes.MAYBE;
    }

    getNextCandidateId() {
        const { candidate_id: selectedCandidateId, integrationProvider } = this.props;
        const { showCandidateListInEvaluation } = getPageFeatures(integrationProvider);
        const interviews = this.getCompletedCandidateInterviews().filter(({submitted_at}) => !submitted_at);
        const currentIndex = interviews.findIndex(({candidate_id}) => candidate_id === selectedCandidateId);

        if (showCandidateListInEvaluation && currentIndex + 1 < interviews.length) {
            return interviews[currentIndex + 1].candidate_id;
        }

        return null;
    }

    getActionItems() {
        const returnValue = [];

        if (isInIFrame()) {
            returnValue.push({
                id: "burger-save-draft-and-close",
                firstLine: 'Close',
                onClick: this.getSaveButtonAction()
            });
        }

        const { submitted_at } = this.getSelectedEvaluation();

        if (!submitted_at) {
            returnValue.push({
                id: "clear",
                firstLine: "Clear Evaluation...",
                onClick: this.clearEvaluationConfirmation
            });
        }

        return returnValue;
    }

    getSubmitButtonClassNames() {
        const { isSubmissionInProgress } = this.state;

        let returnValue = `react-button evaluation-submit-button`;

        if (!isSubmissionInProgress && this.isEvaluationComplete()) {
            returnValue += ' active ripple';
        } else {
            returnValue += ' disabled';
        }

        return returnValue;
    }

    getSubmitButtonAction() {
        const { isSubmissionInProgress } = this.state;
        const nextCandidateId = this.getNextCandidateId();

        return async (e) => {
            if (this.isEvaluationComplete() && !isSubmissionInProgress) {
                await this.endEvaluation(e, eEndEvaluationType.SUBMIT, nextCandidateId);
            }
        }
    }

    getSubmitButtonLabel() {
        const next_candidate_id = this.getNextCandidateId();

        let returnValue = 'Submit & ';

        if (!!next_candidate_id) {
            returnValue += 'Evaluate Next';
        } else {
            returnValue += 'Finish Evaluating';
        }

        return returnValue;
    }

    getSaveButtonClassNames() {
        const { isSavingInProgress } = this.state;

        let returnValue = `react-button outline evaluation-save-button`;

        if (!isSavingInProgress) {
            returnValue += ' active ripple';
        } else {
            returnValue += ' disabled';
        }

        return returnValue;
    }

    getSaveButtonAction() {
        const { isSavingInProgress } = this.state;
        const nextCandidateId = this.getNextCandidateId();

        return async (e) => {
            if (!isSavingInProgress) {
                await this.endEvaluation(e, eEndEvaluationType.SAVE, nextCandidateId);
            }
        }
    }

    getSaveButtonLabel() {
        const { integrationProvider } = this.props;
        const next_candidate_id = this.getNextCandidateId();

        let returnValue = 'Save Draft & ';

        if (integrationProvider) {
            returnValue += 'Close';
        } else {
            if (!!next_candidate_id) {
                returnValue += 'Evaluate Next';
            } else {
                returnValue += 'Finish Evaluating';
            }
        }

        return returnValue;
    }

    updateStatusMessage(message, type = "", duration = null) {
        this.setState({
            statusMessage: {
                type,
                message,
                duration,
                reset: new Date()
            }
        })
    }

    async endEvaluation(e, type = eEndEvaluationType.SAVE, next_candidate_id = null) {
        const isSubmitting = type === eEndEvaluationType.SUBMIT,
            isSaving = type === eEndEvaluationType.SAVE;

        this.setState({
            isSubmissionInProgress: isSubmitting,
            isSavingInProgress: isSaving
        });

        const response = await this.updateRecord(e, isSubmitting);

        this.setState({
            isSubmissionInProgress: false,
            isSavingInProgress: false
        });

        if (response) {
            const { integrationProvider } = this.props;
            const { returnToJobDetailsAfterEvaluation } = getPageFeatures(integrationProvider);

            if (returnToJobDetailsAfterEvaluation) {
                if (!!next_candidate_id) {
                    const { job_id, urlFor, urlHasTrailingEvaluate } = this.props;
                    window.location.href = urlFor(`assess-candidate-evaluation`, {
                        integrationProvider,
                        job_id,
                        candidate_id: next_candidate_id,
                        hasTrailingEvaluate: urlHasTrailingEvaluate
                    });
                } else {
                    window.location.href = this.getAssessJobDetailsURL();
                }
            } else {
                if (integrationProvider) {
                    if (isSubmitting) {
                        window.parent.postMessage("evaluation_submitted", "*");
                    }
                    if (isSaving) {
                        window.parent.postMessage("modal_closed", "*");
                    }
                }

                const sentence =
                    isSubmitting ? "Candidate evaluation submitted." :
                    isSaving ? "Candidate evaluation saved." :
                    '';

                this.showCompletedDialog(sentence);
            }
        }
    }

    unsubmitEvaluation(e) {
        e.persist();
        const { staff_id, candidate_id, job_id, job_stage_type, job_stage_id } = this.props;

        unsubmitEvaluation(staff_id, job_id, job_stage_type, job_stage_id, candidate_id).then(() => {
            const { candidates } = this.state;
            this.setState({
                candidates: candidates.map((candidate) =>
                    candidate.candidate_id === candidate_id ? {...candidate, submitted_at: null} : candidate
                )
            });

            this.updateEvaluation(e, `submitted_at`, null);
        });
    }

    clearEvaluationConfirmation() {
        this.setState({
            confirmDialogProps: {
                title: "Clear Evaluation",
                message: "Are you sure you want to clear this evaluation? This action cannot be undone.",
                actions: [
                    {
                        label: "Cancel",
                        classes: "button-link",
                        onClick: this.clearEvaluationCancel
                    },
                    {
                        label: "Clear Evaluation",
                        classes: "warning",
                        onClick: this.clearEvaluation
                    }
                ]
            }
        });
    }

    clearEvaluationCancel() {
        this.setState({
            confirmDialogProps: null
        });
    }

    clearEvaluation() {
        // reset everything and update
        const { candidate } = this.state;
        const { staff_id } = this.props;

        const e = {
            target: {
                id: "clear"
            }
        };

        this.setState({
            candidate: {
                ...candidate,
                evaluations: candidate.evaluations.map((evaluation) => {
                    if (evaluation.staff_id === staff_id) {
                        return {
                            ...evaluation,
                            attributes: evaluation.attributes?.map((item) => ({
                                attribute: item.attribute,
                                score: null
                            })),
                            final_comment: null,
                            questions: evaluation.questions.map((question) => ({
                                job_interview_step_id: question.job_interview_step_id,
                                notes: "",
                                score: null
                            })),
                            recommendation: null
                        };
                    } else {
                        return evaluation;
                    }
                })
            }
        }, () => this.updateRecord(e) && this.clearEvaluationCancel());

        this.updateStatusMessage(<span>Evaluation successfully cleared.</span>, null, 5000);
    }

    showCompletedDialog(sentence = `Candidate evaluation updated.`) {
        this.setState({
            confirmDialogResponseProps: {
                type: eConfirmDialogResponseType.SUCCESS,
                sentence,
                closeLabel: "Exit Evaluation",
                onClose: () => {
                    this.setState({confirmDialogResponseProps: null});
                    window.parent.postMessage("modal_closed", "*");
                }
            }
        });
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        const { candidate_id: previous_candidate_id } = prevProps;
        const { candidate_id } = this.props;

        if (candidate_id !== previous_candidate_id) {
            this.prepareInformation();
        }
    }

    render() {
        const {
            loading,
            job = {},
            candidate,

            statusMessage: { type, message, duration, reset },

            isError,
            confirmDialogResponseProps,
            confirmDialogProps
        } = this.state;

        const { title, location } = job;

        const {
            viewType,
            job_stage_type,
            candidate_id: selectedCandidateId,
            integrationProvider
        } = this.props;

        const isOneWayStage = job_stage_type === eJobStageTypes.ONE_WAY,
            isLiveInterviewStage = job_stage_type === eJobStageTypes.LIVE;

        const { ask_for_feedback } = this.getJobStage();

        const { candidate_responses, feedback, completed_at } = candidate;

        const { showCandidateListInEvaluation } = getPageFeatures(integrationProvider);

        const avatarData = {
            ...candidate,
            email: null,
            phone: null,
            id: candidate.candidate_id,
            manageProfileLink: this.getManageProfileLink()
        };

        const evaluation_attributes = this.getEvaluationAttributes();
        const hasAttributes = !!evaluation_attributes?.length;

        // for one-way interview
        const jobStage = this.getJobStage();
        const questions = this.getInterviewQuestions();
        const {
            evaluators,
            requires_response_evaluation
        } = jobStage;
        const evaluation = this.getSelectedEvaluation();
        const { final_comment, is_unsubmittable, submitted_at, recommendation } = evaluation;

        // for live interview
        const { interview_guide_summary, interview_guide } = candidate;

        const candidateList = this.getCompletedCandidateInterviews();

        const isEvaluationComplete = this.isEvaluationComplete();

        const hasStatusPanel = this.hasStatusPanel();
        const actionItems = this.getActionItems();

        return (
            <Container
                loading={loading}
                confirmDialogProps={confirmDialogProps}
                confirmDialogResponseProps={confirmDialogResponseProps}
            >
                {!isError && !confirmDialogResponseProps && (
                    <React.Fragment>
                        <StatusMessage
                            type={type}
                            style={eStatusMessageStyle.BAR}
                            message={message}
                            duration={duration}
                            reset={reset}
                        />

                        <div className={this.getComponentContainerClassNames()}>
                            {showCandidateListInEvaluation && (
                                <CandidatesPanel
                                    backLinkURL={this.getAssessJobDetailsURL()}
                                    title={title}
                                    location={location}
                                    candidates={candidateList}
                                    selectedCandidateId={selectedCandidateId}
                                    onCandidateClick={this.switchToCandidateId}
                                />
                            )}

                            {hasStatusPanel && (
                                <StatusPanel
                                    is_completed={true}
                                    showCompletedTimestamp={true}
                                    completed_at={formatDateTime(completed_at, eDateFormatTypes.FULL_WITH_AM_PM)}
                                />
                            )}

                            <div className={`candidate-evaluation`}>
                                {!!submitted_at && (
                                    <div className={`submitted`}>
                                        <div className={`submission-date`}>
                                            This evaluation was submitted on {formatDateTime(submitted_at)}.
                                        </div>
                                        {is_unsubmittable && (
                                            <div>
                                                <br/>
                                                To withdraw this evaluation and make changes before resubmitting it, <span id={`unsubmit`} className={`unsubmit`} onClick={(e) => this.unsubmitEvaluation(e)}>click here</span>.
                                            </div>
                                        )}
                                    </div>
                                )}

                                <CandidateDetails candidate={avatarData} />

                                {!!(actionItems?.length) && (
                                    <ActionDropdown key={`evaluation-action-dropdown`} items={this.getActionItems()} />
                                )}

                                {isOneWayStage && ask_for_feedback && feedback !== null && (
                                    <CandidateFeedback feedback={feedback} />
                                )}

                                {isLiveInterviewStage && (
                                    <InterviewGuide summary={interview_guide_summary} content={interview_guide} />
                                )}

                                <div className={`evaluations-panel`}>
                                    {isOneWayStage && (
                                        <CandidateResponses
                                            view_type={viewType}
                                            questions={questions}
                                            responses={candidate_responses}
                                            requires_star_rating={requires_response_evaluation}
                                            evaluators={evaluators}
                                            evaluations={[evaluation]}
                                            onEvaluationChange={this.onEvaluationChange}
                                            onEvaluationUpdate={this.updateRecord}
                                            submitted_at={submitted_at}
                                        />
                                    )}
                                    {isLiveInterviewStage && (
                                        <CandidateLiveInterview
                                            type={eCandidateLiveInterviewType.EVALUATION}
                                            live_interview_notes={evaluation.live_interview_notes}
                                            readOnly={!!evaluation.submitted_at}
                                            onChange={(e) => this.updateEvaluation(e.target.id, e.target.value)}
                                            onBlur={this.updateRecord}
                                        />
                                    )}
                                </div>

                                <div id={`assessment-and-comments`}
                                     className={`
                                    assessment-and-comments
                                    ${hasAttributes ? 'has-assessment' : ''}
                                    ${'has-decisions'}
                                    ${'editable'}
                                `}>
                                    {hasAttributes && (
                                        <AttributesScoring
                                            items={evaluation_attributes}
                                            onChange={!submitted_at ? this.onAttributeChange : null}
                                        />
                                    )}
                                    <FinalComment
                                        value={final_comment}
                                        is_read_only={!!submitted_at}
                                        onChange={(e, value) => this.updateEvaluation(e, "final_comment", value, false)}
                                        onUpdate={(e, value) => this.updateEvaluation(e, "final_comment", value)}
                                    />
                                    <Recommendation
                                        value={recommendation}
                                        is_read_only={!!submitted_at}
                                        onUpdate={(e, value) => this.updateEvaluation(e,"recommendation", value)}
                                    />
                                </div>

                                <div className={`evaluation-actions`}>
                                    {!submitted_at && (
                                        <React.Fragment>
                                            {!isEvaluationComplete && (
                                                <div className="submit-message">
                                                    {IconExclamationMark} You need to complete all the required fields to submit this evaluation.
                                                </div>
                                            )}
                                            <div className={`evaluation-buttons`}>
                                                <button
                                                    id={`submit`}
                                                    className={this.getSubmitButtonClassNames()}
                                                    onClick={this.getSubmitButtonAction()}
                                                >
                                                    {this.getSubmitButtonLabel()}
                                                </button>
                                                <button
                                                    id={`save-draft-and-close`}
                                                    className={this.getSaveButtonClassNames()}
                                                    onClick={this.getSaveButtonAction()}
                                                >
                                                    {this.getSaveButtonLabel()}
                                                </button>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </Container>
        )
    }
}

CandidateEvaluation.propTypes = {
    viewType: PropTypes.oneOf(objectToArray(eUserTypes)),
    staff_id: PropTypes.number.isRequired,
    is_alcami_staff: PropTypes.bool,
    integrationProvider: PropTypes.string,
    job_id: PropTypes.number.isRequired,
    job_stage_type: PropTypes.oneOf(objectToArray(eJobStageTypes)),
    job_stage_id: PropTypes.number.isRequired,
    candidate_id: PropTypes.number.isRequired,
    urlFor: PropTypes.func.isRequired,
    urlHasTrailingEvaluate: PropTypes.bool
};

CandidateEvaluation.defaultProps = {
    viewType: eUserTypes.MANAGER,
    integrationProvider: "",
    candidate_id: null,
    urlHasTrailingEvaluate: false
};

export default CandidateEvaluation;
