import React from "react";
import * as PropTypes from "prop-types";

import Avatar from "../../Avatar";
import LabelValueTile from "../../LabelValueTile";
import { IconShare, IconClose } from "../../Icons";
import { getFullName, isInIFrame } from "../../../utils";

import './style.scss';


const CandidateDetails = ({ candidate, onEdit, onShareCandidate }) => {
    const { first_name, last_name, email, phone, cv, manageProfileLink } = candidate;
    const showEmail = !!email;
    const showPhone = !!phone;

    return (
        <div className={`candidate-details ${showEmail ? 'show-email' : ''}`}>
            <Avatar
                classes={`avatar`}
                data={candidate}
                size={`${showEmail ? 'xl' : 'm'}`}
                selectable={false}
                onClick={onEdit || null}
            />
            <LabelValueTile classes="fullName" label="Name" value={getFullName(first_name, last_name) || "-"} />
            <LabelValueTile classes="firstName" label="First Name" value={first_name || "-"} />
            <LabelValueTile classes="lastName" label="Last Name" value={last_name || "-"} />
            {showEmail && (
                <LabelValueTile classes="email" label="Email" value={email || "-"} />
            )}
            {showPhone && (
                <LabelValueTile classes="phone" label="Phone" value={phone || "-"} />
            )}
            {cv && (
                <div className={`cv`}>
                    <a className="cv-link" href={cv} target="_blank" rel="noopener noreferrer">Download CV</a>
                </div>
            )}
            {manageProfileLink && (
                <LabelValueTile classes="actionItem" label="">
                    <a className="manageProfileLink-link" href={manageProfileLink}>
                        View this candidate's evaluations
                    </a>
                </LabelValueTile>
            )}
            {!!onShareCandidate && (
                <div className={`actionItem btn`} onClick={onShareCandidate}>
                    Share {IconShare}
                </div>
            )}
            {!!onShareCandidate && isInIFrame() && (
                <div className={`closeItem`} onClick={() => window.parent.postMessage("modal_closed", "*")}>
                    {IconClose}
                </div>
            )}
        </div>
    );
}

CandidateDetails.propTypes = {
    candidate: PropTypes.shape({
        id: PropTypes.number,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
        cv: PropTypes.string
    }),
    onEdit: PropTypes.func,
    onShareCandidate: PropTypes.func
};

CandidateDetails.defaultProps = {
    candidate: {},
    onEdit: null,
    onShareCandidate: null
};

export default CandidateDetails;
