import React from 'react';
import * as PropTypes from "prop-types";
import CustomTextField from "../Form/CustomTextField";
import { isValidTime } from "../../utils";

class CustomTimePicker extends React.Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.checkValue = this.checkValue.bind(this);

        this.state = {
            isError: false
        };
    }

    onChange(e) {
        const { onChange } = this.props;
        const { isError } = this.state;

        if (isError) {
            const { target : { value } = {} } = e;
            if (isValidTime(value)) {
                this.setState({
                    isError: false
                });
            }
        }

        !!onChange && onChange(e);
    }

    checkValue() {
        const { value } = this.props;
        const { isError }= this.state;

        if (isValidTime(value) === isError) {
            this.setState({
                isError: !isError
            });
        }
    }

    render() {
        const { id, classes, required, label, value } = this.props;
        const { isError } = this.state;
        return (
            <CustomTextField
                id={id}
                classes={classes}
                required={required}
                label={label}
                placeholder="11:30 AM"
                isError={isError}
                value={value}
                onChange={this.onChange}
                onBlur={this.checkValue}
            />);
    }
}

CustomTimePicker.propTypes = {
    id: PropTypes.string,
    classes: PropTypes.string,
    required: PropTypes.bool,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func
};

CustomTimePicker.defaultProps = {
    id: "",
    classes: "",
    required: false,
    label: "",
    value: "",
    onChange: null
};

export default CustomTimePicker;
