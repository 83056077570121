import React from "react";
import * as PropTypes from "prop-types";
import {
    convertDateToCommonFormat,
    defaultOngoingDeadlineDays,
    eDeadlineType,
    eInterviewStepType,
    eInterviewTerminologies,
    eJobDraftSteps,
    eLibraryAssetType,
    eStatusMessageStyle,
    eStatusMessageType,
    eUserSettings,
    getUserSetting,
    isAlcamiStaff,
    isValidEmail,
    jobDefaultEvaluationAttributes,
    objectToArray,
    sanitiseCandidates,
    validateCandidates,
    JOB_STAGES_FIELDS,
    hasDuplicateSMSReminders,
    hasIncompleteSMSReminders,
    eCompanyFeatures,
    isCompanyFeatureEnabled,
    convertDayToCommonFormat,
    eDeadlineDay
} from "../../utils";
import {
    addLibraryItemToLibraryArray,
    addEvaluationAttributesToggleAndDisplay,
    getCurrentJobStage,
    getJobItem,
    sanitiseJobStageData,
    updateLibraryItemVideoSelection
} from "../../utils/job";
import {
    getCompanyDetails,
    getJobSummary,

    saveJob,
    updateJob,
    updateJobStage,
    deleteJob,
    inviteJobCandidates,
    getBrandings,
    getJobTemplates,
    cloneJob,
    applyJobAsTemplate
} from "../../api";
import {IconTickThin, IconPlay} from "../Icons";
import {get} from "lodash";
import Container from "../Container";
import StatusMessage from "../StatusMessage";
import StepJobDetails from "./StepJobDetails";
import StepQuestions from "./StepQuestions";
import StepCandidates from "./StepCandidates";
import StepEvaluators from "./StepEvaluators";
import StepEmails from "./StepEmails";
import StepReview from "./StepReview";
import CustomModal from "../CustomModal";
import StepTemplates from "./StepTemplates";
import Job from "../Job";

import './style.scss';


const eStepType = {
    PREV: "prev",
    NEXT: "next"
};

const jobDraftStepsDescription = {
    [eJobDraftSteps.JOB_DETAILS]: "",
    [eJobDraftSteps.QUESTIONS]: "Build your Interview to include Messages (e.g. intro and outro) and Questions.",
    [eJobDraftSteps.CANDIDATES]: "",
    [eJobDraftSteps.EVALUATORS]: "",
    [eJobDraftSteps.EMAILS]: "",
    [eJobDraftSteps.REVIEW]: ""
};

const JobDraftSteps = ({steps = [], currentStep = steps[0], stepsCompleted = []}) => {
    return (
        <div className="job-draft-steps">
            {steps.map((step, index) => {
                const isStepActive = step === currentStep;
                const isStepCompleted = stepsCompleted.includes(step);
                return (
                    <div key={index} className={`step ${isStepActive ? 'active' : ''} ${isStepCompleted ? 'completed' : ''}`}>
                        <div className="step-info">
                            {isStepCompleted && (
                                <span className="step-icon">{IconTickThin}</span>
                            )}
                            {!isStepCompleted && (
                                <span className="step-number">{index + 1}</span>
                            )}
                        </div>
                        <div className="step-name">{step}</div>
                    </div>
                );
            })}
        </div>
    );
};

JobDraftSteps.propTypes = {
    steps: PropTypes.arrayOf(PropTypes.oneOf(objectToArray(eJobDraftSteps))),
    currentStep: PropTypes.oneOf(objectToArray(eJobDraftSteps))
};

JobDraftSteps.defaultProps = {
    steps: [],
    currentStep: 0
};

const defaultJob = {
    owner_staff_id: null,
    allows_template_selection: false,
    job_id: null,
    title: "",
    position_description: "",
    location: "",
    branding_id: null,
    status: "draft",
    job_stage_ids: [1],
    job_stages: [{
        job_stage_id: 1,
        job_stage_name: null,
        job_stage_type: "one-way",
        job_stage_order: 1,
        interview_terminology: eInterviewTerminologies.INTERVIEW,
        deadline_type: eDeadlineType.FIXED,
        deadline: null,
        ongoing_deadline_days: defaultOngoingDeadlineDays,

        job_interview_steps: [],
        ask_for_feedback: true,

        requires_response_evaluation: true,
        evaluation_attributes: jobDefaultEvaluationAttributes,
        evaluation_attributes_enabled: true,

        is_open_registration: false,
        candidates: [],

        evaluators: []
    }]
};

class JobDraft extends Job {
    constructor(props) {
        super(props);

        this.loadData = this.loadData.bind(this);
        this.shallRedirectToJobEdit = this.shallRedirectToJobEdit.bind(this);
        this.showTemplateSelection = this.showTemplateSelection.bind(this);
        this.onTemplateSelectionConfirmed = this.onTemplateSelectionConfirmed.bind(this);
        this.onTemplateSelectionCancelled = this.onTemplateSelectionCancelled.bind(this);
        this.showEmailTemplates = this.showEmailTemplates.bind(this);
        this.getSteps = this.getSteps.bind(this);
        this.getStepNumber = this.getStepNumber.bind(this);
        this.getStepName = this.getStepName.bind(this);
        this.getStepDescription = this.getStepDescription.bind(this);
        this.goToStep = this.goToStep.bind(this);
        this.getCurrentStepContent = this.getCurrentStepContent.bind(this);
        this.onReviewEdit = this.onReviewEdit.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onVideoSelectionChange = this.onVideoSelectionChange.bind(this);
        this.onJobInputChange = this.onJobInputChange.bind(this);
        this.onCandidateChange = this.onCandidateChange.bind(this);
        this.onLibraryAdd = this.onLibraryAdd.bind(this);
        this.isDeadlineValid = this.isDeadlineValid.bind(this);
        this.previousButtonEnabled = this.previousButtonEnabled.bind(this);
        this.progressButtonEnabled = this.progressButtonEnabled.bind(this);
        this.updateStatusMessage = this.updateStatusMessage.bind(this);
        this.refreshJob = this.refreshJob.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.cancelDelete = this.cancelDelete.bind(this);
        this.deleteJob = this.deleteJob.bind(this);

        this.getProgressButtonClassNames = this.getProgressButtonClassNames.bind(this);

        this.handleResponseError = this.handleResponseError.bind(this);

        const steps = this.getSteps();

        this.state = {
            job: defaultJob,
            job_templates: [],
            is_job_template_selection_shown: true,
            steps,
            currentStep: steps[0],
            currentJobStageId: 1,
            library: null,
            brandings: [],
            statusMessage: {
                message: null,
                reset: null
            },
            stepsCompleted: [],
            loading: true,
            processing: false,
            confirmDialogProps: null,
            integrationProviders: [],
            isCandidateContactNameError: false,
            isCandidateContactEmailError: false
        };
    }

    async loadData() {
        const { job_id, user: { staff_id }, showJobTemplatesSelection } = this.props;
        const { job } = this.state;

        this.setState({
            loading: true
        });

        const brandings = await getBrandings();

        const updatedState = {
            loading: false,
            brandings
        };

        const defaultBrandingId =
            (
                brandings.find(({is_default_for_new_job}) => !!is_default_for_new_job) ||
                brandings[0] ||
                { branding_id: null }
            ).branding_id;

        const { integration_providers, interview_terminology_for_new_jobs } = await getCompanyDetails();

        if (integration_providers?.length) {
            updatedState.integrationProviders = integration_providers;
        }

        if (!!job_id) {
            const response = await getJobSummary(job_id);

            if (response) {
                const updatedStateJob = {...job, ...response};

                if (!updatedStateJob.branding_id && !!defaultBrandingId) {
                    updatedStateJob.branding_id = defaultBrandingId;
                }

                updatedState.job = updatedStateJob;

                updatedState.currentJobStageId = job.job_stage_ids[0];
            }
        } else {
            // new job draft
            const updatedStateJob = {...defaultJob, owner_staff_id: staff_id};

            if(!!defaultBrandingId) {
                updatedStateJob.branding_id = defaultBrandingId;
            }

            if (!!interview_terminology_for_new_jobs) {
                updatedStateJob.job_stages[0].interview_terminology = interview_terminology_for_new_jobs;
            }

            updatedStateJob.allows_template_selection = showJobTemplatesSelection;

            updatedState.job = updatedStateJob;
        }

        updatedState.job_templates = !!updatedState.job.allows_template_selection ? await getJobTemplates() : [];

        this.shallRedirectToJobEdit(updatedState.job);

        this.setState(updatedState);
    }

    shallRedirectToJobEdit(job) {
        if (job?.active_at) {
            const { urlFor, integration: { provider: integrationProvider } } = this.props;
            window.location.href = urlFor('manage-job-edit', { integrationProvider, job_id: job.job_id });
        }
    }

    componentDidMount() {
        this.loadData();
    }

    async componentDidUpdate(prevProps) {
        const { autoSave } = this.state;
        const { job_id: previousJobId } = prevProps;
        const { job_id } = this.props;

        // reload job draft when moving from existing job draft to a fresh job draft
        if (previousJobId !== job_id) {
            await this.loadData();

            const steps = this.getSteps();

            this.setState({
                currentStep: steps[0]
            });
        }

        if (autoSave) {
            this.setState({
                autoSave: false
            });
            this.saveCurrentJob();
        }
    }

    showTemplateSelection() {
        this.setState({
            is_job_template_selection_shown: true
        });
    }

    onTemplateSelectionConfirmed(template_job_id) {
        const { job: { job_id } } = this.state;

        if (!!job_id) {
            // existing job
            applyJobAsTemplate(template_job_id, job_id).then((response) => {
                this.setState({
                    job: {
                        ...this.state.job,
                        ...response
                    },
                    is_job_template_selection_shown: false
                });
            });
        } else {
            // new job
            const { urlFor, integration: { provider: integrationProvider } } = this.props;
            cloneJob(template_job_id).then((response) => {
                window.location.href = urlFor('manage-existing-job-draft', { integrationProvider, job_id: response.job_id });
            });
        }
    }

    onTemplateSelectionCancelled() {
        this.setState({
            is_job_template_selection_shown: false
        });
    }

    showEmailTemplates() {
        const { features } = this.props;

        return (isAlcamiStaff() && getUserSetting(eUserSettings.TEMPLATES_EMAIL)) ||
            isCompanyFeatureEnabled(eCompanyFeatures.TEMPLATES_EMAIL, features);
    }

    getSteps() {
        const { showCandidatesStep } = this.props;

        const steps = [];

        steps.push(eJobDraftSteps.JOB_DETAILS);
        steps.push(eJobDraftSteps.QUESTIONS);

        if (showCandidatesStep) {
            steps.push(eJobDraftSteps.CANDIDATES);
        }

        steps.push(eJobDraftSteps.EVALUATORS);
        steps.push(eJobDraftSteps.REVIEW);

        return steps;
    }

    getStepNumber() {
        const { steps, currentStep } = this.state;
        return "Step " + (steps.indexOf(currentStep) + 1);
    }

    getStepName() {
        const { currentStep } = this.state;
        return currentStep;
    }

    getStepDescription() {
        const stepName = this.getStepName();
        return jobDraftStepsDescription[stepName];
    }

    getCurrentStepContent() {
        const {
            currentStep,
            currentJobStageId,
            job,
            steps,
            brandings,
            integrationProviders,
            isCandidateContactNameError,
            isCandidateContactEmailError
        } = this.state;
        const { integration: { active: integrationActive, provider: integrationProvider } } = this.props;

        const currentJobStage = getCurrentJobStage(job, currentJobStageId);
        const showEmailTemplates = this.showEmailTemplates();
        const classes = integrationActive ? 'no-header' : '';

        if (currentStep === eJobDraftSteps.JOB_DETAILS) {
            const { location, position_description, branding_id, title } = job;
            const { interview_terminology, deadline_type, deadline, ongoing_deadline_days } = currentJobStage;
            return <StepJobDetails
                title={title}
                location={location}
                position_description={position_description}
                branding_id={branding_id}

                interview_terminology={interview_terminology}
                deadline_type={deadline_type}
                deadline={deadline}
                ongoing_deadline_days={ongoing_deadline_days}

                classes={classes}
                brandings={brandings}
                onInputChange={this.onJobInputChange}
            />;
        }

        if (currentStep === eJobDraftSteps.QUESTIONS) {
            return <StepQuestions
                classes={classes}
                currentJobStageId={currentJobStageId}
                job={job}
                onVideoSelectionChange={this.onVideoSelectionChange}
                onInputChange={this.onJobInputChange}
                onTemplateSelection={this.onTemplateSelection}
                onLibraryAdd={this.onLibraryAdd}
                integrationProvider={integrationProvider}
            />;
        }

        if (currentStep === eJobDraftSteps.CANDIDATES) {
            return <StepCandidates
                classes={classes}
                job={job}
                job_stage={currentJobStage}
                integrationProviders={integrationProviders}
                showEmailTemplates={showEmailTemplates}
                onCandidateChange={this.onCandidateChange}
                onInputChange={this.onJobInputChange}
                onRefresh={this.refreshJob}
            />;
        }

        if (currentStep === eJobDraftSteps.EVALUATORS) {
            const jobInterviewSteps = getJobItem(job, JOB_STAGES_FIELDS.JOB_INTERVIEW_STEPS);
            return <StepEvaluators
                job={job}
                questions={jobInterviewSteps.filter(({interview_step_type}) => interview_step_type === "question")}
                isCandidateContactNameError={isCandidateContactNameError}
                isCandidateContactEmailError={isCandidateContactEmailError}
                onInputChange={this.onJobInputChange}
            />;
        }

        if (currentStep === eJobDraftSteps.EMAILS) {
            return <StepEmails
                emails={job.email_contents}
                onInputChange={this.onJobInputChange}
            />;
        }

        if (currentStep === eJobDraftSteps.REVIEW) {
            return <StepReview
                job={job}
                job_stage={currentJobStage}
                steps={steps}
                brandings={brandings}
                integrationActive={integrationActive}
                showEmailTemplates={showEmailTemplates}
                onEdit={this.onReviewEdit}
            />;
        }

        return null;
    }

    onReviewEdit(step) {
        const { job, stepsCompleted } = this.state;

        const newStepsCompleted = [...stepsCompleted];
        const reviewStep = eJobDraftSteps.REVIEW;
        if (!(newStepsCompleted.includes(reviewStep))) {
            newStepsCompleted.push(reviewStep);
        }

        const updatedState = {
            currentStep: step,
            stepsCompleted: newStepsCompleted
        }

        if (step === eJobDraftSteps.QUESTIONS) {
            updatedState.job = addEvaluationAttributesToggleAndDisplay(job);
        }

        this.setState(updatedState);
    }

    async goToStep(type) {
        this.setState({
            processing: true,
            error: null
        });

        const { integration: { active: integrationActive, provider: integrationProvider } } = this.props;
        const { job, steps, currentStep, stepsCompleted } = this.state;

        const isLastStep = steps[steps.length - 1] === currentStep;

        if (isLastStep && type === eStepType.NEXT) {
            const { job_id } = job;
            await inviteJobCandidates(job_id);

            window.location.href = integrationActive ?
                this.props.urlFor('integration-job-creation-success', { integrationProvider }) :
                this.props.urlFor('manage-job-details', { integrationProvider, job_id });

            return false;
        }

        // no need to save Review step (nothing's changed)
        const response = isLastStep ? true : await this.saveCurrentJob();

        if (response) {
            const hasReviewStepBeenSeen = stepsCompleted.includes(eJobDraftSteps.REVIEW);

            let newStep;

            if (type === eStepType.PREV) {
                newStep = steps[Math.max(steps.indexOf(currentStep) - 1, 0)];
            } else if (hasReviewStepBeenSeen) {
                newStep = eJobDraftSteps.REVIEW;
            } else {
                newStep = steps[steps.indexOf(currentStep) + 1];
            }

            let newStepsCompleted = [...stepsCompleted];
            if (!(stepsCompleted.includes(currentStep))) {
                newStepsCompleted.push(currentStep);
            }

            const updatedState = {
                processing: false,
                currentStep: newStep,
                stepsCompleted: newStepsCompleted
            };

            if (newStep === eJobDraftSteps.QUESTIONS) {
                // need to retrieve job from state as it has already been updated
                updatedState.job = addEvaluationAttributesToggleAndDisplay(this.state.job);
            }

            this.setState(updatedState);
        }

        return true;
    }

    onInputChange(event) {
        const field = event.target.id;
        const value = event.target.value;

        const newState = {};
        newState[field] = value;

        this.setState(newState);
    }

    onVideoSelectionChange(questionType, questionId, videoId) {
        const { library } = this.state;
        const updatedLibrary = updateLibraryItemVideoSelection(library, questionType, questionId, videoId);

        if (!!updatedLibrary) {
            this.setState({
                library: updatedLibrary
            });
        }
    }

    updateJobStages(jobStages, currentJobStageId, id, value) {
        return jobStages.map((jobStage) => {
            const { job_stage_id } = jobStage;

            if (job_stage_id === currentJobStageId) {
                const returnValue = {
                    ...jobStage,
                    [id]: value
                };

                if (
                    id === JOB_STAGES_FIELDS.IS_OPEN_REGISTRATION &&
                    !!value
                ) {
                    returnValue[JOB_STAGES_FIELDS.SENDS_INVITATION_EMAIL] = true;
                }

                return returnValue;
            }

            return jobStage;
        });
    }

    onJobInputChange(event, autoSave) {
        const { target : { id, value } } = event;

        const {
            job,
            currentJobStageId,
            isCandidateContactNameError: previousNameError,
            isCandidateContactEmailError: previousEmailError
        } = this.state;

        const updatedJobState = {...job};

        if (objectToArray(JOB_STAGES_FIELDS).includes(id)) {
            const { job_stages } = job;
            updatedJobState.job_stages = this.updateJobStages(job_stages, currentJobStageId, id, value);
        } else {
            updatedJobState[id] = value;
        }

        let isCandidateContactNameError = previousNameError,
            isCandidateContactEmailError = previousEmailError;

        if (['candidate_contact_name','candidate_contact_email'].includes(id)) {
            const candidate_contact_name = id === 'candidate_contact_name' ? value : job.candidate_contact_name;
            const candidate_contact_email = id === 'candidate_contact_email' ? value : job.candidate_contact_email;

            isCandidateContactNameError = !!candidate_contact_email?.trim() && !candidate_contact_name?.trim();
            isCandidateContactEmailError = (!!candidate_contact_name?.trim() && !candidate_contact_email?.trim()) ||
                (!!candidate_contact_email?.trim() && !isValidEmail(candidate_contact_email));
        }

        this.setState({
            job: updatedJobState,
            isCandidateContactNameError,
            isCandidateContactEmailError,
            autoSave
        });
    }

    onCandidateChange(_job_stage_id, candidates, autoSave) {
        this.onJobInputChange({target: { id: "candidates", value: candidates } }, autoSave);
    }

    onLibraryAdd(newItem) {
        const { library } = this.state;
        const updatedLibrary = addLibraryItemToLibraryArray(library, newItem);

        this.setState({
            library: updatedLibrary
        });
    }

    isDeadlineValid() {
        const { job } = this.state;
        const { deadline_type, deadline, ongoing_deadline_days } = getCurrentJobStage(job);

        if (deadline_type === eDeadlineType.FIXED) {
            const currentDate = convertDayToCommonFormat(eDeadlineDay.YESTERDAY);
            const interviewClosing = convertDateToCommonFormat(deadline);

            return currentDate <= interviewClosing;
        }

        if (deadline_type === eDeadlineType.ONGOING) {
            return !!ongoing_deadline_days;
        }

        return true;
    }

    previousButtonEnabled() {
        const { job, currentStep } = this.state;

        let returnValue = true;

        if (currentStep === eJobDraftSteps.CANDIDATES) {
            const reminder_sms_templates = getJobItem(job, JOB_STAGES_FIELDS.REMINDER_SMS_TEMPLATES);
            returnValue = !hasDuplicateSMSReminders(reminder_sms_templates) && !hasIncompleteSMSReminders(reminder_sms_templates);
        }

        return returnValue;
    }

    progressButtonEnabled() {
        const { job, currentStep } = this.state;

        if (currentStep === eJobDraftSteps.JOB_DETAILS) {
            const { title, location } = job;
            const hasTitle = !!title?.trim();
            const hasLocation = !!location?.trim();

            return hasTitle && hasLocation && this.isDeadlineValid();
        }

        if (currentStep === eJobDraftSteps.QUESTIONS) {
            const job_interview_steps = getJobItem(job, JOB_STAGES_FIELDS.JOB_INTERVIEW_STEPS);
            const interviewQuestions = job_interview_steps.filter(
                ({library_asset_type, interview_step_type}) =>
                    library_asset_type === eLibraryAssetType.QUESTION &&
                    (
                        interview_step_type === undefined ||
                        interview_step_type === eInterviewStepType.QUESTION
                    )
            );

            return !!interviewQuestions.length;
        }

        if (currentStep === eJobDraftSteps.CANDIDATES) {
            const reminder_sms_templates = getJobItem(job, JOB_STAGES_FIELDS.REMINDER_SMS_TEMPLATES);

            return !hasDuplicateSMSReminders(reminder_sms_templates) && !hasIncompleteSMSReminders(reminder_sms_templates);
        }

        if (currentStep === eJobDraftSteps.EVALUATORS) {
            const showCandidateContact = isAlcamiStaff() && getUserSetting(eUserSettings.CANDIDATE_CONTACT);
            const { isCandidateContactNameError, isCandidateContactEmailError } = this.state;

            return !showCandidateContact || (!isCandidateContactNameError && !isCandidateContactEmailError);
        }

        return true;
    }

    async saveCurrentJob() {
        const { job, currentStep, currentJobStageId } = this.state;
        let { job_id, job_timezone } = job;

        if (currentStep === eJobDraftSteps.QUESTIONS) {
            const { evaluation_attributes_enabled, evaluation_attributes_display } = getCurrentJobStage(job, currentJobStageId);
            let value = evaluation_attributes_enabled ? evaluation_attributes_display.filter((attribute) => attribute !== "") : [];
            await this.onJobInputChange({target: {id: JOB_STAGES_FIELDS.EVALUATION_ATTRIBUTES, value}});
        }

        if (currentStep === eJobDraftSteps.CANDIDATES) {
            const { hasErrors, candidates } = validateCandidates(getJobItem(job, JOB_STAGES_FIELDS.CANDIDATES) || [], job_timezone);

            if (hasErrors) {
                await this.onJobInputChange({ target: { id: JOB_STAGES_FIELDS.CANDIDATES, value: candidates } });
                this.updateStatusMessage("There are some errors in the candidates records. Please amend and try again.");
                return false;
            }
        }

        if ([eJobDraftSteps.JOB_DETAILS, eJobDraftSteps.QUESTIONS, eJobDraftSteps.CANDIDATES].includes(currentStep)) {
            await this.cleanseJobStage(currentJobStageId, currentStep);
        }

        // update job
        let payload = this.state.job; // job object might have been updated by steps above, thus the repeat retrieval
        const job_response = !job_id ? await saveJob(payload) : await updateJob(payload);

        if (this.handleResponseError(job_response)) {
            return false;
        }

        if (!job_id) {
            job_id = job_response.job_id;

            // will update the location to include jobId
            const newURL = this.props.urlFor('manage-existing-job-draft', {
                integrationProvider: this.props.integrationProvider,
                job_id
            });
            window.history.pushState({ path: newURL }, '', newURL);
        }

        // update job stage
        payload = getCurrentJobStage(this.state.job, currentJobStageId);

        if (currentStep === eJobDraftSteps.CANDIDATES) {
            payload = {
                ...payload,
                candidates: sanitiseCandidates(payload.candidates)
            };
        }

        const { job_stage_type, job_stage_id } = payload;
        const job_stage_response = await updateJobStage(job_id, job_stage_type, job_stage_id, payload);

        if (this.handleResponseError(job_stage_response)) {
            return false;
        }

        const updatedJobState = {
            job: {
                ...job,
                ...job_response,
                job_stages: job.job_stages.map((job_stage) => job_stage_id === job_stage_response.job_stage_id ? sanitiseJobStageData(job_stage_response) : job_stage)
            }
        };

        this.setState(updatedJobState);

        return true;
    }

    refreshJob(job) {
        this.setState({
            job
        });
    }

    updateStatusMessage(message) {
        this.setState({
            processing: false,
            statusMessage: {
                message,
                reset: new Date()
            }
        });
    }

    confirmDelete(job_id, e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            confirmDialogProps: {
                title: "Delete Job",
                message: "Are you sure you want to delete this job?",
                actions: [
                    {
                        label: "Cancel",
                        classes: "outline",
                        onClick: () => this.cancelDelete()
                    },
                    {
                        label: "Delete",
                        classes: "warning",
                        onClick: () => this.deleteJob(job_id)
                    }
                ]
            }
        });
    }

    cancelDelete() {
        this.setState({
            confirmDialogProps: null
        });
    }

    async deleteJob(job_id) {
        const response = await deleteJob({job_id});

        if (response.error) {
            this.setState({
                confirmDialogProps: null
            });

            const errorMessage = get(response, ["error", "response", "statusText"]) ||
                get(response, ["error", "response", "data"]) ||
                get(response, ["error", "message"]);
            this.updateStatusMessage(errorMessage);
        } else {
            this.setState({
                confirmDialogProps: null
            });

            window.location.href = "#/all/jobs/active";
        }
    }

    getProgressButtonClassNames(type) {
        const { currentStep, processing } = this.state;
        let returnValue = `react-button ${type}`;

        if (type === eStepType.PREV) {
            returnValue += ` info`;
        } else if (type === eStepType.NEXT) {
            returnValue += ` active`;

            if (currentStep === eJobDraftSteps.REVIEW) {
                returnValue += ' final';
            }
        }

        if (
            (type === eStepType.PREV && !this.previousButtonEnabled()) ||
            (type === eStepType.NEXT && !this.progressButtonEnabled()) ||
            processing
        ) {
            returnValue += ' disabled';
        } else {
            returnValue += ' ripple';
        }

        return returnValue;
    }

    getProgressButtonAction(type) {
        const { processing } = this.state;
        if (
            type === eStepType.PREV &&
            this.previousButtonEnabled() &&
            !processing
        ) {
            return () => this.goToStep(eStepType.PREV)
        } else if (
            type === eStepType.NEXT &&
            this.progressButtonEnabled() &&
            !processing
        ) {
            return () => this.goToStep(eStepType.NEXT);
        }

        return null;
    }

    handleResponseError(response) {
        if (response.error) {
            const errorMessage = get(response, ["error", "response", "statusText"]) ||
                get(response, ["error", "response", "data"]) ||
                get(response, ["error", "message"]);
            this.updateStatusMessage(errorMessage);
            return true;
        }

        return false;
    }

    render() {
        const { integration: { active: integrationActive, provider: integrationProvider } } = this.props;
        const {
            job,
            job_templates,
            is_job_template_selection_shown,
            steps,
            currentStep,
            statusMessage: { message, reset },
            stepsCompleted,
            loading,
            confirmDialogProps
        } = this.state;
        const { allows_template_selection, job_id, title } = job;

        const should_show_template_selection =
            allows_template_selection // backend allows template selection
            && job_templates?.length // there are job templates to choose from
            && is_job_template_selection_shown;

        const classes = integrationActive ? 'no-header' : '';

        return (
            <Container
                loading={loading}
                confirmDialogProps={confirmDialogProps}
            >
                <StatusMessage
                    type={eStatusMessageType.WARNING}
                    style={eStatusMessageStyle.BAR}
                    message={message}
                    duration={5000}
                    reset={reset}
                />
                {!should_show_template_selection && (
                    <div className={`job-draft ${classes} ${integrationProvider}`}>
                        <div className={`job-panel job-panel-${currentStep.toLowerCase().replace(/ /g, "-")}`}>
                            <div className="header-panel">
                                <div className="step-number">{this.getStepNumber()}</div>
                                <div className="step-name">{this.getStepName()}</div>
                                <div className="step-description">{this.getStepDescription()}</div>
                            </div>
                            <div className={`main-panel main-panel-${currentStep.toLowerCase().replace(/ /g, "-")}`}>
                                {this.getCurrentStepContent()}
                            </div>
                        </div>
                        <div className="progress-panel">
                            <div className="steps-panel">
                                <div className={`job-name ${title ? '' : 'blank'} ${!!integrationActive ? 'no-delete' : ''}`}>
                                    {title}
                                    {!integrationActive && !!job_id && (
                                        <div className={`job-delete`}>
                                            <span className={`delete-link`} onClick={(e) => this.confirmDelete(job_id, e)}>Delete</span>
                                        </div>
                                    )}
                                </div>

                                <div className="steps">
                                    <JobDraftSteps
                                        steps={steps}
                                        currentStep={currentStep}
                                        stepsCompleted={stepsCompleted}
                                    />
                                </div>
                            </div>
                            <div className={`actions-panel step-${steps.indexOf(currentStep) + 1}`}>
                                {steps.indexOf(currentStep) !== 0 && (
                                    <button
                                        className={this.getProgressButtonClassNames(eStepType.PREV)}
                                        onClick={this.getProgressButtonAction(eStepType.PREV)}
                                    >
                                        {IconPlay}
                                        <span className="label">Prev</span>
                                    </button>
                                )}
                                <button
                                    className={this.getProgressButtonClassNames(eStepType.NEXT)}
                                    onClick={this.getProgressButtonAction(eStepType.NEXT)}
                                >
                                    <span className="label">{currentStep === eJobDraftSteps.REVIEW ? "Create" : "Next"}</span>
                                    {IconPlay}
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {should_show_template_selection && (
                    <CustomModal
                        classes={`job-template-selection open`}
                        closeOnOverlayClick={false}
                    >
                        <StepTemplates
                            title={integrationActive ? 'Video Assessment Setup' : 'New Job'}
                            content={integrationActive ? 'To set a video assessment for this job, please select one of your templates below or create one from scratch.' : ''}
                            job_templates={job_templates}
                            onInputChange={this.onJobInputChange}
                            onTemplateSelectionConfirmed={this.onTemplateSelectionConfirmed}
                            onTemplateSelectionCancelled={this.onTemplateSelectionCancelled}
                        />
                    </CustomModal>
                )}
            </Container>
        )
    }
}

JobDraft.propTypes = {
    integration: PropTypes.shape({
        active: PropTypes.bool,
        provider: PropTypes.string
    }),
    showCandidatesStep: PropTypes.bool,
    user: PropTypes.object,
    job_id: PropTypes.number,
    urlFor: PropTypes.func.isRequired
};

JobDraft.defaultProps = {
    integration: {},
    showCandidatesStep: true,
    user: null,
    job_id: null,
    urlFor: null
};

export default JobDraft;
