import React from "react";
import {IconExclamationMark} from "../Icons";

import "./style.scss";


const NO_INVITATION_EMAIL_MESSAGE = "There is no invitation email currently set for this job, " +
    "so candidate will not receive an email for any deadline changes. To change this, " +
    "please edit the job before making further changes."

const NoInvitationEmailMessage = () => {
    return (
        <div className={`no-invitation-email-message`}>
            {IconExclamationMark}
            <div className={'no-invitation-email-phrase'}>{NO_INVITATION_EMAIL_MESSAGE}</div>
        </div>
    );
};

export default NoInvitationEmailMessage;
