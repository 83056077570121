import React from 'react';
import { get } from 'lodash';
import {
    postAuthenticate,
    getStaff,
    getCompanyDetails,
    serverLogout,
    getAuthenticate,
    setAlcamiAuthToken
} from './api';

import {
    eCandidateActionTypes,
    eUserTypes,
    eVerificationType,
    setUserData,
    getUserSettings,
    setUserSetting,
    updateURL,
    getPageFeatures,
    getQueryParam,
    sanitiseURL,
    getValue,
    INTEGRATION_PROVIDER
} from './utils';

import { getDefaultPage, parseRoute, urlFor as urlForRouting } from './routing';

import Loader, { eLoaderSize } from './components/Loader';
import LoginPage, { eLoginPageType, eStaffLoginMethods } from './pages/login';
import Navigation from './components/Navigation';
import Manage from './components/Manage';
import JobDetails from './components/JobDetails';
import JobDraft from './components/JobDraft';
import IntegrationJobCreationSuccess from './components/IntegrationJobCreationSuccess';
import JobEdit from './components/JobEdit';
import CandidateProfile from './components/CandidateProfile';
import CandidateProfileResponsesOnly from "./components/CandidateProfile/CandidateProfileResponsesOnly";
import CandidateEvaluation from './components/CandidateEvaluation';
import Assess from './components/Assess';
import IntegrationEvaluationsCompleted from './components/IntegrationEvaluationsCompleted';
import CompanyPage from './pages/company';
import LibraryPage from './pages/library';
import TemplatesPage from "./pages/templates";
import MaintenancePage from "./pages/maintenance";
import Intercom from "./components/Intercom";
import Container, {eErrorPageTypes} from "./components/Container";
import InterviewTemplateDetails from "./pages/templates/interview/details";
import JobSummary from "./components/JobSummary";
import CandidateAction from "./components/CandidateAction";
import Notice from "./components/Notice";

class App extends React.Component {
    constructor(props) {
        super(props);

        this.retrievePageFeatures = this.retrievePageFeatures.bind(this);
        this.isLoginPage = this.isLoginPage.bind(this);
        this.isResetPasswordPage = this.isResetPasswordPage.bind(this);
        this.onLoggedIn = this.onLoggedIn.bind(this);
        this.onUpdateUserSetting = this.onUpdateUserSetting.bind(this);
        this.onLogout = this.onLogout.bind(this);

        this.state = {
            loginPageAttributes: {},
            checkingLoggedIn: true,
            uid: null,
            company: null,
            userSettings: null,
            pageFeatures: null
        };
    }

    retrievePageFeatures() {
        const { path, queryString } = this.props;
        const { user, company, userSettings } = this.state;
        const parseRouteResult = parseRoute({ user, company, userSettings }, path, queryString);

        const { routeState: { integrationProvider = null } = {} } = parseRouteResult;
        this.setState({
            pageFeatures: getPageFeatures(integrationProvider)
        });
    }

    isLoginPage() {
        const { path } = this.props;
        return ["", "/", "/login"].includes(path);
    }

    isResetPasswordPage() {
        const { path } = this.props;
        return path === "/passwd";
    }

    async onLoggedIn(response) {
        const { staff_id } = response;

        // set user details
        const user = await getStaff(staff_id);
        const company = await getCompanyDetails();
        const userSettings = getUserSettings();

        /**
         * need to set user data first prior to updating state
         * as assess-job-list relies on staff_id value from user data
         */
        setUserData({...user, staff_id});

        const { path } = this.props;
        if (!path || this.isLoginPage() || this.isResetPasswordPage()) {
            const defaultPage = getDefaultPage(user);
            updateURL(defaultPage);
        }

        this.setState({
            checkingLoggedIn: false,
            user,
            company,
            userSettings
        }, this.retrievePageFeatures);
    }

    onUpdateUserSetting(item, value) {
        this.setState({ userSettings: { ...this.state.userSettings, [item]: value } });
        setUserSetting(item, value);
    }


    async onLogout() {
        await serverLogout();
        /**
         * updateURL will reload the page to homepage
         * as we need to reset the path
         * and therefore don't need to clear the user/company data
         * as it would look like the page gets reset twice.
         * instead we will display a spinner (i.e. checkingLoggedIn)
         */
        this.setState({
            checkingLoggedIn: true
        });
        setUserData();
        updateURL('');
    }

    setPayload(uid, verificationCode, authNonce) {
        let payload = null;

        if (!!uid) {
            payload = {};
            payload.uid = uid;

            if (!!verificationCode) {
                payload.verification_code = verificationCode;
            }
        }

        if (!!authNonce) {
            if (payload === null) {
                payload = {};
            }

            payload.authNonce = authNonce;
        }

        return payload;
    }

    async authenticate(payload) {
        if (payload) {
            return postAuthenticate(payload);
        }
        return getAuthenticate();
    }

    getVerificationType(next) {
        let returnValue = eVerificationType.PASSWORD;

        if (next === eStaffLoginMethods.VERIFICATION_CODE) {
            returnValue = eVerificationType.VERIFICATION_CODE;
        }

        return returnValue;
    }

    async componentDidMount() {
        const uid = getQueryParam("uid"),
            verification_code = getQueryParam("verification_code"),
            authNonce = getQueryParam("authNonce");

        sanitiseURL();

        if (this.isResetPasswordPage()) {
            return true;
        }

        const payload = this.setPayload(uid, verification_code, authNonce);

        const response = await this.authenticate(payload);

        if (response.error) {
            if (response.error.response.status === 503) {
                return this.setState({
                    isMaintenanceMode: true
                });
            }

            this.setState({checkingLoggedIn: false});

            // error might also mean 'additional information required'
            const data = get(response.error, ["response", "data"]);

            if (!!data && typeof data === "string") {
                console.info('No logged-in user found.');
                return false;
            }

            /* possible responses:
             * {"next": "ask_for_password"} or {"next": "ask_for_verification_code"}
             */
            const { next } = data;

            const loginPageAttributes = {
                pageShown: eLoginPageType.PASSWORD,
                currentLoginModes: {
                    can_login_with_password: next === eStaffLoginMethods.PASSWORD,
                    can_login_with_email: next === eStaffLoginMethods.VERIFICATION_CODE,
                },
                verificationType: this.getVerificationType(next)
            };

            if (!!uid) {
                loginPageAttributes.uid = uid;
            }

            this.setState({
                loginPageAttributes
            });
        } else {
            const { alcami_auth_token } = response;
            if (response) {
                setAlcamiAuthToken(alcami_auth_token);
            }
            await this.onLoggedIn(response);
        }
    }

    componentDidUpdate(prevProps) {
        const { path: prevPath } = prevProps;
        const { path } = this.props;

        if (prevPath !== path) {
            this.retrievePageFeatures();
        }
    }

    render() {
        const { path, queryString } = this.props;
        const { isMaintenanceMode, loginPageAttributes, checkingLoggedIn, user, company, userSettings, pageFeatures } = this.state;
        const { route, routeState } = parseRoute({ user, company, userSettings }, path, queryString);
        const { integrationProvider } = routeState;

        const contents = [];

        if (isMaintenanceMode) {
            contents.push(<MaintenancePage />);
            return contents;
        }

        if (!integrationProvider && company) {
            contents.push(<Intercom path={path} company={company} user={user} />);
        }

        let mainContent;
        if (this.isResetPasswordPage()) {
            mainContent = (<LoginPage pageShown={eLoginPageType.RESET} onLoggedIn={this.onLoggedIn} />);
        } else if (checkingLoggedIn) {
            mainContent = (<Loader size={eLoaderSize.PAGE} />);
        } else if (!user) {
            const token = getQueryParam("token");
            if (route === 'manage-candidate-profile-responses-only' && token) {
                mainContent = (<CandidateProfileResponsesOnly
                    integrationProvider={getValue(routeState.integrationProvider,"")}
                    job_id={routeState.job_id}
                    job_stage_type={routeState.job_stage_type}
                    job_stage_id={routeState.job_stage_id}
                    candidate_id={routeState.candidate_id}
                />);
            } else {
                mainContent = (<LoginPage attr={loginPageAttributes} path={path} onLoggedIn={this.onLoggedIn} />);
            }
        } else if (this.isLoginPage()) {
            // already logged in and accessing the login page
            const defaultPage = getDefaultPage(user);
            updateURL(defaultPage);
            mainContent = (<Loader size={eLoaderSize.PAGE} />);
        } else {
            const urlForProp = (r, rs) => urlForRouting({ user, company, userSettings }, r, rs);

            if (get(pageFeatures, "showHeader")) {
                contents.push(
                    <div className='navigation-directive'>
                        <Navigation
                            urlFor={urlForProp}
                            activeRoute={route}
                            company={company}
                            user={user}
                            userSettings={userSettings}
                            onUpdateUserSetting={this.onUpdateUserSetting}
                            onLogout={this.onLogout}
                        />
                    </div>
                );
            }

            if (
                user.is_manager &&
                (!integrationProvider || integrationProvider !== INTEGRATION_PROVIDER.CRITERIA) &&
                company?.tenant_id !== "nbcuniversal"
            ) {
                contents.push(<Notice
                    tenant_id={company?.tenant_id}
                    integration_providers={company?.integration_providers}
                />);
            }

            mainContent = (
                <LoggedInApp urlFor={urlForProp} user={user} company={company} route={route} routeState={routeState} />
            );
        }

        if (mainContent) {
            contents.push(mainContent);
        }

        return contents;
    }
}



function LoggedInApp({ urlFor, user, company, route, routeState }) {
    const { integrationProvider, job_id, interview_template_id, scrollToCandidateId } = routeState;
    const integration = integrationProvider ? { active: true, provider: integrationProvider } : { active: false };
    const {
        showJobTemplatesSelection,
        showCandidatesStep,
        showBreadcrumbs
    } = getPageFeatures(integrationProvider);

    switch (route) {
        case 'manage-job-list':
            return (
                <Manage
                    activeRoute={route}
                    jobStatus={routeState.jobStatus}
                    urlFor={urlFor}
                />
            );

        case 'manage-job-details-summary':
            return (
                <JobSummary
                    jobId={job_id}
                    viewType={eUserTypes.MANAGER}
                    urlFor={urlFor}
                    integrationProvider={integrationProvider}
                    staffId={user.staff_id}
                />
            )

        case 'manage-job-details':
            return (
                <JobDetails
                    key={job_id}
                    viewType={eUserTypes.MANAGER}
                    integrationProvider={integrationProvider}
                    company_id={company.old_company_id}
                    staff_id={user.staff_id}
                    is_alcami_staff={user.is_alcami_staff}
                    job_id={job_id}
                    scrollToCandidateId={scrollToCandidateId}
                    showBreadcrumbs={showBreadcrumbs}
                    urlFor={urlFor}
                    features={company.features}
                />
            );

        case 'manage-new-job-draft': {
            return (
                <JobDraft
                    integration={integration}
                    features={company.features}
                    showJobTemplatesSelection={showJobTemplatesSelection}
                    showCandidatesStep={showCandidatesStep}
                    user={user}
                    job_id={null}
                    urlFor={urlFor}
                />
            );
        }

        case 'manage-existing-job-draft': {
            return (
                <JobDraft
                    integration={integration}
                    features={company.features}
                    showJobTemplatesSelection={showJobTemplatesSelection}
                    showCandidatesStep={showCandidatesStep}
                    user={user}
                    job_id={routeState.job_id}
                    urlFor={urlFor}
                />
            );
        }

        case 'integration-job-creation-success':
            return (<IntegrationJobCreationSuccess />);

        case 'manage-job-edit':
            return (
                <JobEdit
                    key={routeState.job_id}
                    integration={!!routeState.integrationProvider}
                    features={company.features}
                    job_id={routeState.job_id}
                    urlFor={urlFor}
                />
            );

        case 'manage-candidate-profile':
            return (
                <CandidateProfile
                    viewType={eUserTypes.MANAGER}
                    staff_id={user.staff_id}
                    is_alcami_staff={user.is_alcami_staff}
                    integrationProvider={routeState.integrationProvider || ""}
                    job_id={routeState.job_id}
                    job_stage_type={routeState.job_stage_type}
                    job_stage_id={routeState.job_stage_id}
                    candidate_id={routeState.candidate_id}
                    urlFor={urlFor}
                />
            );

        case 'manage-candidate-profile-responses-only':
            return (
                <CandidateProfileResponsesOnly
                    staff_id={user.staff_id}
                    is_manager={user.is_manager}
                    integrationProvider={routeState.integrationProvider || ""}
                    job_id={routeState.job_id}
                    job_stage_type={routeState.job_stage_type}
                    job_stage_id={routeState.job_stage_id}
                    candidate_id={routeState.candidate_id}
                    urlFor={urlFor}
                />
            );

        case 'manage-candidate-edit':
            return (
                <CandidateAction
                    useCustomModal={false}
                    actionType={eCandidateActionTypes.EXTEND}
                    candidate_id={routeState.candidate_id}
                    job_id={job_id}
                    job_stage_type={routeState.job_stage_type}
                    job_stage_id={routeState.job_stage_id}
                />
            );

        case 'assess-job-list':
            return (
                <Assess
                    staff_id={user.staff_id}
                    urlFor={urlFor}
                />
            );

        case 'assess-job-details-summary':
            return (
                <JobSummary
                    jobId={job_id}
                    viewType={eUserTypes.EVALUATOR}
                    urlFor={urlFor}
                    integrationProvider={integrationProvider}
                    staffId={user.staff_id}
                />
            )

        case 'assess-job-details':
            return (
                <JobDetails
                    key={routeState.job_id}
                    viewType={eUserTypes.EVALUATOR}
                    integrationProvider={integrationProvider}
                    is_alcami_staff={user.is_alcami_staff}
                    staff_id={user.staff_id}
                    job_id={routeState.job_id}
                    scrollToCandidateId={scrollToCandidateId}
                    showBreadcrumbs={showBreadcrumbs}
                    urlFor={urlFor}
                    features={company.features}
                />
            );

        case 'assess-candidate-evaluation':
            /*
             * The use of `key` here is a hack to ensure we re-render when we change
             * candidates by submitting and evaluating next, because the component
             * is ignoring its props.
             *
             * TASK: CandidateProfile should ideally be able to cope with its
             * `candidate_id` prop changing, without needing a remount.
             */
            return (
                <CandidateEvaluation
                    key={routeState.job_id}
                    viewType={eUserTypes.EVALUATOR}
                    staff_id={user.staff_id}
                    is_alcami_staff={user.is_alcami_staff}
                    is_manager={user.is_manager}
                    integrationProvider={routeState.integrationProvider || ""}
                    job_id={routeState.job_id}
                    job_stage_type={routeState.job_stage_type}
                    job_stage_id={routeState.job_stage_id}
                    candidate_id={routeState.candidate_id}
                    urlFor={urlFor}
                    urlHasTrailingEvaluate={routeState.hasTrailingEvaluate}
                />
            );

        case 'integration-evaluations-completed':
            return (<IntegrationEvaluationsCompleted />);

        case 'company-details': // deliberate fallthroughs
        case 'company-branding':
        case 'company-staff':
        case 'company-marketplace':
        case 'company-reporting':
        case 'company-emails':
            return (
                <CompanyPage
                    activeRoute={route}
                    is_alcami_staff={user.is_alcami_staff}
                    urlFor={urlFor}
                    companyDetails={company}
                />
            );

        case 'library-custom': // deliberate fallthroughs
        case 'library-stock':
        case 'library-messages':
            return (<LibraryPage activeRoute={route} urlFor={urlFor} features={company.features} />);

        case 'integration-library-custom':
        case 'integration-library-stock':
        case 'integration-library-messages':
            return (<LibraryPage activeRoute={route} urlFor={urlFor} features={company.features} integrationProvider={integrationProvider} />);

        case 'templates-interview':
        case 'templates-email':
        case 'templates-sms':
            return (
                <TemplatesPage
                    activeRoute={route}
                    is_alcami_staff={user.is_alcami_staff}
                    urlFor={urlFor}
                    integrationProvider={integrationProvider}
                />
            );

        case 'templates-interview-edit':
            return (
                <InterviewTemplateDetails
                    interview_template_id={interview_template_id}
                    showInModal={false}
                    integrationProvider={integrationProvider}
                />
            );

        case 'not-found':
            return (<Container loading={false} error={eErrorPageTypes.NOT_FOUND}/>);

        default:
            throw Error('Unexpected route ' + route + ' with routeState "' + JSON.stringify(routeState) + '"');
    }
}



export default App;
