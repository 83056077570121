import React from 'react';
import MediaPreview from "../MediaPreview";
import {IconCrossThin, IconProfile} from "../Icons";
import CustomRadioButton from "../Form/CustomRadioButton";
import Loader from "../Loader";
import { isEqual } from "lodash";

import './style.scss';


const transparentImage = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";

export const eMediaTypes = {
    AUDIO: "audio/mpeg",
    VIDEO: "video/mp4"
};

class QuestionThumbnail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            isImageError: false,
            displayMedia: false
        };

        this.handleClick = this.handleClick.bind(this);
        this.displayMedia = this.displayMedia.bind(this);
        this.hideMedia = this.hideMedia.bind(this);
        // this.onImageLoad = this.onImageLoad.bind(this);
        // this.onImageError = this.onImageError.bind(this);
    }

    handleClick(e) {
        const { onClick } = this.props;

        if (!!onClick) {
            const targetClassList = Array.from(e.target.classList);
            if (!(targetClassList.includes("selection"))) {
                onClick();
            }
        }
    }

    /*
    onImageLoad() {

        this.setState({
            isLoading: false
        });
    }

    onImageError() {
        this.setState({
            isLoading: false,
            isImageError: true
        });

        this.image.style.display = "none";
    }
    */

    displayMedia(e) {
        const targetClassList = Array.from(e.target.classList);

        if (!(targetClassList.includes("selection"))) {
            this.setState({
                displayMedia: true
            });
        }
    }

    hideMedia() {
        this.setState({
            displayMedia: false
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
    }

    render() {
        const {
            name = "",
            classes = "",
            questionNumber = "",
            image = "",
            text = "",
            size = "",
            mediaURL = "",
            mediaType = "video/mp4", // "audio/mpeg",
            // rawMediaURL = "",
            // rawMediaType = "",
            showPlaybackSpeed = false,
            value = null,
            selected = null,
            tagsUnselected = false,
            onClick = null,
            onSelect = null,
            onDelete = null
        } = this.props;

        const {
            isLoading,
            // isImageError,
            displayMedia
        } = this.state;

        // for mp3, it is an audio-only response and we do not need to attempt to load thumbnail fallback
        // const fallbackImage = !!mediaURL && mediaType === "video/mp4" ? mediaURL.replace(/\.mp4/, ".jpg") : '';
        const isMediaBlob = !!mediaURL && (
            mediaURL instanceof Blob ||
            (typeof mediaURL === "string" && (mediaURL.substring(0,4) === "blob" || mediaURL.substring(0,4) === "data"))
        );

        const selectable = !!onSelect || selected !== null;

        return (
            <React.Fragment>
                <div className={`question-thumbnail ${classes} ${size} ${mediaURL ? 'clickable' : ''} ${selectable ? 'selectable' : ''} ${selected ? 'selected' : ''} ${tagsUnselected ? 'tags-unselected' : ''}`}
                    onClick={onClick ? this.handleClick : mediaURL ? this.displayMedia : () => null}>
                    {!isMediaBlob && (
                        <React.Fragment>
                            {isLoading && (
                                <Loader />
                            )}
                            {!isLoading ? (
                                <React.Fragment>
                                    <span className="media-thumbnail" style={{ backgroundImage: 'url(' + image + '), url(' + transparentImage + ')' }}>
                                        {!image && !text ? IconProfile : ''}
                                    </span>
                                    {!image && !!text && (
                                        <span className="text-thumbnail">{text}</span>
                                    )}
                                </React.Fragment>
                            ) : null}
                            {/*<img className="media-thumbnail" ref={img => this.image = img} src={image} onLoad={this.onImageLoad} onError={this.onImageError} />*/}
                        </React.Fragment>
                    )}
                    {isMediaBlob && !image && (
                        <video className="video-thumbnail" src={typeof mediaURL === "string" ? mediaURL : URL.createObjectURL(mediaURL)} />
                    )}
                    {mediaURL && (
                        <span className={`play-button ${mediaType === "audio/mpeg" ? 'audio' : ''}`} />
                    )}
                    {questionNumber && questionNumber !== "" && (
                        <span className="question-number">{questionNumber}</span>
                    )}
                    {!!onSelect && (
                        <CustomRadioButton
                            id={value}
                            classes="selection"
                            value={value}
                            displayValue={false}
                            selected={selected}
                            onChange={onSelect}
                        />
                    )}
                    {!!onDelete && (
                        <span className="remove" onClick={(e) => {e.stopPropagation(); !!onDelete && onDelete(name);}}>{IconCrossThin}</span>
                    )}
                </div>
                {displayMedia && (
                    <MediaPreview
                        mediaURL={typeof mediaURL === "string" ? mediaURL : URL.createObjectURL(mediaURL)}
                        mediaType={mediaType}
                        // rawMediaURL={rawMediaURL}
                        // rawMediaType={rawMediaType}
                        showPlaybackSpeed={showPlaybackSpeed}
                        onClose={this.hideMedia} />
                )}
            </React.Fragment>
        );
    }
}

export default QuestionThumbnail;
