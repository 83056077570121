import React from 'react';

import './style.scss';


function IntegrationEvaluationsCompleted() {
  return (
    <div className="evaluations-completed">
      Evaluation(s) completed.
    </div>
  );
}

export default IntegrationEvaluationsCompleted;
