import React from "react";
import * as PropTypes from "prop-types";
import { getStaff } from '../../../api';
import { sortBy } from "lodash";
import EvaluatorSelection from "../../EvaluatorSelection";
import Evaluators from "../../Evaluators";
import Container from "../../Container";
import {
    defaultEvaluationOptions,
    eUserSettings,
    getUserSetting,
    isAlcamiStaff,
    JOB_STAGES_FIELDS
} from "../../../utils";

import './style.scss';
import StaffSelection from "../../StaffSelection";
import { getJobItem } from "../../../utils/job";
import CustomTextField from "../../Form/CustomTextField";
import { IconRefresh } from "../../Icons";


class StepEvaluators extends React.Component {
    constructor(props) {
        super(props);

        this.retrieveStaffRecords = this.retrieveStaffRecords.bind(this);
        this.getAvailableStaff = this.getAvailableStaff.bind(this);
        this.addEvaluator = this.addEvaluator.bind(this);
        this.removeEvaluator = this.removeEvaluator.bind(this);
        this.getInvitedEvaluators = this.getInvitedEvaluators.bind(this);

        this.state = {
            staff: [],
            loading: true
        };
    }

    async retrieveStaffRecords() {
        const staff = await getStaff();
        this.setState({
            staff: sortBy(staff, ['first_name', 'last_name']),
            loading: false
        });
    }

    getAvailableStaff() {
        const { staff } = this.state;
        const evaluators = this.getInvitedEvaluators();

        const evaluatorIds = evaluators.map(({staff_id}) => (staff_id));

        return staff.filter(({staff_id}) => !evaluatorIds.includes(staff_id));
    }

    addEvaluator(staffId) {
        const { staff } = this.state;
        const { onInputChange } = this.props;
        const evaluators = this.getInvitedEvaluators();

        const newEvaluator = staff.find(({staff_id}) => staff_id === staffId);

        const newEvaluators = Array.from(evaluators);
        newEvaluators.push({
            ...newEvaluator,
            evaluation_options: defaultEvaluationOptions
        });

        onInputChange({
            target: {
                id: "evaluators",
                value: newEvaluators
            }
        });
    }

    removeEvaluator(staffId) {
        const { onInputChange } = this.props;
        const evaluators = this.getInvitedEvaluators();

        const newEvaluators = Array.from(evaluators).filter(({staff_id}) => staff_id !== staffId);

        onInputChange({
            target: {
                id: "evaluators",
                value: newEvaluators
            }
        });
    }

    getInvitedEvaluators() {
        const { job } = this.props;
        return getJobItem(job, JOB_STAGES_FIELDS.EVALUATORS);
    }

    componentDidMount() {
        this.retrieveStaffRecords();
    }

    render() {
        // TASK: support contact: confirm the correct field names and if it is on job or job_stage level.
        const {
            job : { owner_staff_id, candidate_contact_name, candidate_contact_email },
            questions,
            onInputChange,
            isCandidateContactNameError,
            isCandidateContactEmailError
        } = this.props;
        const { loading, staff } = this.state;
        const evaluators = this.getInvitedEvaluators();

        const showCandidateContact = isAlcamiStaff() && getUserSetting(eUserSettings.CANDIDATE_CONTACT);

        return (
            <Container loading={loading}>
                <div className={`step-evaluators`}>
                    <StaffSelection
                        header={<div><span>Job Owner</span><span title={`Refresh`} onClick={this.retrieveStaffRecords}>{IconRefresh}</span></div>}
                        classNames={`job-owner`}
                        staffRecords={staff}
                        staffFilter={({is_manager}) => !!is_manager}
                        selectedStaffId={owner_staff_id}
                        onInputChange={onInputChange}
                        selectionIdProp="owner_staff_id"
                    />
                    {showCandidateContact && (
                        <div className={`candidate-contact`}>
                            <div className={`candidate-contact-header`}>
                                Candidate Support Contact
                            </div>
                            <div className={`candidate-contact-note`}>
                                Please enter the name and email address of someone in your organisation who would be able
                                to assist the candidates with any enquiries they may have. The name and email address
                                provided below will be made available to the candidates in the invitation emails. Our
                                support team will also direct non-technical enquiries to this contact.
                            </div>
                            <CustomTextField
                                classes={`candidate-contact-name`}
                                id={`candidate_contact_name`}
                                required={false}
                                label={`Name`}
                                value={candidate_contact_name}
                                isError={isCandidateContactNameError}
                                onChange={onInputChange}
                            />
                            <CustomTextField
                                classes={`candidate-contact-email`}
                                id={`candidate_contact_email`}
                                required={false}
                                label={`Email`}
                                value={candidate_contact_email}
                                isError={isCandidateContactEmailError}
                                onChange={onInputChange}
                            />
                        </div>
                    )}
                    <div className={`job-evaluators`}>
                        <EvaluatorSelection
                            header={<div><span>Evaluators</span><span title={`Refresh`} onClick={this.retrieveStaffRecords}>{IconRefresh}</span></div>}
                            evaluators={this.getAvailableStaff()}
                            invited={evaluators}
                            onAdd={this.addEvaluator}
                        />
                        <div className={`evaluator-list-container`}>
                            <Evaluators
                                classes={`evaluator-list`}
                                evaluators={evaluators}
                                questions={questions}
                                editable={true}
                                removable={true}
                                onRemove={this.removeEvaluator}
                                onUpdate={onInputChange}
                            />
                        </div>
                    </div>
                </div>
            </Container>
        );
    }
}

StepEvaluators.propTypes = {
    job: PropTypes.object,
    questions: PropTypes.array,
    onInputChange: PropTypes.func,
    isCandidateContactNameError: PropTypes.bool,
    isCandidateContactEmailError: PropTypes.bool
};

StepEvaluators.defaultProps = {
    job: {},
    questions: [],
    onInputChange: null,
    isCandidateContactNameError: false,
    isCandidateContactEmailError: false
};

export default StepEvaluators;
