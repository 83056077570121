import React from "react";
import PropTypes from 'prop-types';
import {getJobSummary} from "../../api";
import {eUserTypes, getDeadlineValue, objectToArray} from "../../utils";

import './style.scss';
import Container from "../Container";
import LabelValueTile from "../LabelValueTile";
import EvaluatorsThumbnails from "../EvaluatorsThumbnails";


class JobSummary extends React.Component {
    constructor(props) {
        super(props);

        this.retrieveJobSummary = this.retrieveJobSummary.bind(this);

        this.state = {
            job: null
        };
    }

    async retrieveJobSummary(id) {
        const { viewType } = this.props;
        const response = await getJobSummary(id);
        const first_job_stage = (response.job_stages || []).find(({job_stage_id}) => job_stage_id === 1);

        this.setState({
            job: {
                ...response,
                ...first_job_stage,
                evaluated_count: viewType === eUserTypes.MANAGER ? first_job_stage.evaluated_count : first_job_stage.evaluated_by_me_count
            }
        });
    }

    async componentDidMount() {
        const { jobId } = this.props;
        await this.retrieveJobSummary(jobId);
    }

    render() {
        const { job } = this.state;
        const {
            job_id,
            deadline,
            deadline_type,
            ongoing_deadline_days,
            invited_count = "-",
            received_count = "-",
            evaluated_count = "-",
            accepted_count = "-",
            evaluators = [],
            status
        } = job || {};
        const { viewType, urlFor, integrationProvider, staffId } = this.props;

        const isLoading = !job;
        const deadlineValue = getDeadlineValue(deadline_type, deadline, ongoing_deadline_days)

        let isCurrentUserAnEvaluator = false;

        if (!!evaluators.length) {
            isCurrentUserAnEvaluator = !!staffId && evaluators.map(({staff_id}) => staff_id).includes(staffId);
        }

        return (
            <Container loading={isLoading}>
                {!isLoading && (
                    <div className={`job-summary ${viewType}`}>
                        {status === "draft" && (
                            <div className={`draft-message`}>
                                Go to the Alcami tab to setup your video interview.
                            </div>
                        )}
                        {status !== "draft" && !!job && (
                            <React.Fragment>
                                {viewType === eUserTypes.MANAGER && (
                                    <LabelValueTile classes="invited" label="Invited" value={invited_count} />
                                )}
                                <LabelValueTile classes="received" label="Received" value={received_count} />
                                <LabelValueTile classes="evaluated" label="Evaluated" value={evaluated_count} />
                                {viewType === eUserTypes.MANAGER && (
                                    <LabelValueTile classes="accepted" label="Accepted" value={accepted_count} />
                                )}
                                <LabelValueTile classes="deadline" label="Deadline" value={deadlineValue} />
                                {viewType === eUserTypes.MANAGER && (
                                    <EvaluatorsThumbnails evaluators={evaluators} />
                                )}
                                {isCurrentUserAnEvaluator && (
                                    <a className={`react-button active evaluate ${!received_count ? 'disabled' : ''}`}
                                       href={!received_count ? null : urlFor('assess-job-details', {
                                             integrationProvider,
                                             job_id
                                         })}
                                       target={`_blank`}
                                       rel={`noopener noreferrer`}
                                    >
                                        Evaluate
                                    </a>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                )}
            </Container>
        )
    }
}

JobSummary.propTypes = {
    jobId: PropTypes.number,
    viewType: PropTypes.oneOf(objectToArray(eUserTypes)),
    urlFor: PropTypes.func,
    integrationProvider: PropTypes.string,
    staffId: PropTypes.number
};

JobSummary.defaultProps = {
    jobId: null,
    viewType: null,
    urlFor: null,
    integrationProvider: null,
    staffId: null
};

export default JobSummary;