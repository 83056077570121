import React from 'react';
import { isEqual } from "lodash";
import * as PropTypes from 'prop-types';
import {updateCandidateInterviewOverrides, resendInvites, getCandidate} from '../../api/';
import CustomDatePicker from '../Pickers/CustomDatePicker';
import CustomModal from '../CustomModal';
import QuestionItem from "../JobDraft/StepQuestions/QuestionItem";
import {
    convertDateToCommonFormat,
    eCandidateActionTypes,
    eConfirmDialogResponseType,
    eDeadlineDay,
    eDropdownTypes,
    eInterviewStepType,
    eJobStageTypes,
    eQuestionDisplayType,
    eResponseStatus,
    eUserSettings,
    formatDate,
    getFullName,
    isAdminUserSettingEnabled,
    objectToArray
} from "../../utils";
import ConfirmDialogResponse from "../ConfirmDialog/Response";
import NoInvitationEmailMessage from "../NoInvitationEmailMessage";
import TypedDropdown from "../TypedDropdown";

import './style.scss';


class CandidateAction extends React.Component {
    constructor(props) {
        super(props);

        this.getComponentTitle = this.getComponentTitle.bind(this);
        this.getComponentClassNames = this.getComponentClassNames.bind(this);
        this.getDeadlineTimezoneInitialValue = this.getDeadlineTimezoneInitialValue.bind(this);
        this.getInterviewSteps = this.getInterviewSteps.bind(this);
        this.getCandidates = this.getCandidates.bind(this);
        this.shouldClosingDateBeDisabled = this.shouldClosingDateBeDisabled.bind(this);
        this.shouldShowQuestions = this.shouldShowQuestions.bind(this);
        this.getQuestionDisplayType = this.getQuestionDisplayType.bind(this);
        this.isClosingDateEditable = this.isClosingDateEditable.bind(this);

        this.onInputChange = this.onInputChange.bind(this);
        this.onQuestionItemInputChange = this.onQuestionItemInputChange.bind(this);

        this.isFormSubmittable = this.isFormSubmittable.bind(this);
        this.hasDeadlineChanged = this.hasDeadlineChanged.bind(this);
        this.hasQuestionOverridesChanged = this.hasQuestionOverridesChanged.bind(this);

        this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this);
        this.handleUpdateCandidateError = this.handleUpdateCandidateError.bind(this);
        this.resendEmail = this.resendEmail.bind(this);

        const {
            deadline: candidateDeadline,
            deadline_timezone,
            question_overrides
        } = props.candidates?.[0];

        const deadline = convertDateToCommonFormat(candidateDeadline) || null;

        this.state = {
            candidate: null,
            interview_steps: [],
            original_deadline: deadline,
            original_deadline_timezone: deadline_timezone,
            deadline,
            deadline_timezone: deadline_timezone,
            original_question_overrides: question_overrides,
            question_overrides: question_overrides || [],
            status: "", // "", "sending", "success", "fail",
            confirmDialogResponseProps: null
        };
    }

    getComponentTitle() {
        const { actionType, title } = this.props;

        if (!!title) {
            return title;
        }

        if (actionType === eCandidateActionTypes.EXTEND) {
            return `Extend Candidates' Deadlines`;
        } else if (actionType === eCandidateActionTypes.RESEND) {
            return `Resend Candidates' Invites`;
        }

        return '';
    }

    getComponentClassNames() {
        let returnValue = 'candidate-action open';

        if (this.areMultipleCandidates()) {
            returnValue += ' multiple';
        }

        return returnValue;
    }

    getDeadlineTimezoneInitialValue() {
        const { job_timezone } = this.props;
        const { original_deadline_timezone } = this.state;
        return original_deadline_timezone || job_timezone;
    }

    async getInterviewSteps() {
        const { job_id, job_stage_type, job_stage_id, candidates, candidate_id: propsCandidateId } = this.props;
        const candidateId = propsCandidateId || candidates?.[0].candidate_id;
        const candidate = await getCandidate(job_id, job_stage_type, job_stage_id, candidateId);

        if (candidate) {
            const {
                candidate_id,
                first_name,
                last_name,
                deadline_text,
                deadline_timezone,
                interview_steps,
                question_overrides,
            } = candidate;
            this.setState({
                candidate: {
                    candidate_id: candidate_id,
                    first_name: first_name,
                    last_name: last_name
                },
                original_deadline: deadline_text,
                deadline: deadline_text,
                original_deadline_timezone: deadline_timezone,
                deadline_timezone: deadline_timezone,
                interview_steps: interview_steps,
                original_question_overrides: question_overrides,
                question_overrides: question_overrides
            });
        }
    }

    getCandidates() {
        const { candidates } = this.props;
        const { candidate } = this.state;
        return candidate ? [candidate] : candidates;
    }

    shouldShowQuestions() {
        const { interview_steps } = this.state;
        const { actionType } = this.props;

        return actionType === eCandidateActionTypes.EXTEND &&
            !this.areMultipleCandidates() &&
            !!(interview_steps?.length);
    }

    shouldClosingDateBeDisabled() {
        const { isDisabled } = this.props;
        return isDisabled || !this.isClosingDateEditable();
    }

    getQuestionDisplayType() {
        const { isDisabled } = this.props;
        return isDisabled ? eQuestionDisplayType.VIEWING : eQuestionDisplayType.UPDATING;
    }

    isClosingDateEditable() {
        const { candidate_id } = this.props;
        return !candidate_id;
    }

    onInputChange(el, value) {
        this.setState({[el]: value});
    }

    onQuestionItemInputChange(_assetType, _questionType, job_interview_step_id, el, value) {
        if (value === "") {
            return false;
        }

        const { interview_steps, question_overrides } = this.state;

        const { preparation_time, answer_time, number_of_attempts } = interview_steps.find(
            (step) => step.job_interview_step_id === job_interview_step_id
        );

        const existingQuestionOverride = question_overrides.find(
            (override) => override.job_interview_step_id === job_interview_step_id
        );

        const newQuestionOverride = {
            job_interview_step_id,
            preparation_time,
            answer_time,
            number_of_attempts,
            ...existingQuestionOverride,
            [el]: value
        };

        const updatedQuestionOverrides = question_overrides.map(
            (override) => override.job_interview_step_id === job_interview_step_id ? newQuestionOverride : override
        ).filter((override) => {
            // delete overrides that are the same as the actual question.
            return !(override.preparation_time === preparation_time &&
                override.answer_time === answer_time &&
                override.number_of_attempts === number_of_attempts);
        });

        if (!existingQuestionOverride) {
            updatedQuestionOverrides.push(newQuestionOverride);
        }

        this.setState({ question_overrides: updatedQuestionOverrides });
    }

    isEmailResendRequired() {
        const { actionType } = this.props;
        const { deadline } = this.state;
        return actionType === eCandidateActionTypes.RESEND ||
            this.areMultipleCandidates() ||
            (!!deadline && this.hasDeadlineChanged());
    }

    async onSubmitButtonClick() {
        if (["processing", "success"].includes(this.state.status)) {
            return;
        }

        // send to backend and await response
        const { interview_steps, deadline, deadline_timezone, question_overrides, candidate } = this.state;
        const {
            actionType,
            candidates,
            job_id,
            job_stage_type,
            job_stage_id,
            job_timezone,
            onUpdate,
            onClose
        } = this.props;

        const postData = {};
        const candidateIds = candidate ?
            [candidate.candidate_id] :
            candidates.map(({candidate_id}) => candidate_id);

        if (actionType === eCandidateActionTypes.EXTEND) {
            const legitQuestionOverrides = question_overrides.filter((override) => {
                const job_interview_step = interview_steps.find((step) => step.job_interview_step_id === override.job_interview_step_id);
                return job_interview_step.preparation_time !== override.preparation_time
                    || job_interview_step.answer_time !== override.answer_time
                    || job_interview_step.number_of_attempts !== override.number_of_attempts;
            });

            postData.deadline = deadline;
            postData.deadline_timezone = deadline_timezone || job_timezone;

            postData.question_overrides = legitQuestionOverrides;

            const updateCandidateStatus = await updateCandidateInterviewOverrides(job_id, job_stage_type, job_stage_id, candidateIds, postData);
            if (this.handleUpdateCandidateError(updateCandidateStatus)) {
                return true;
            }
        }

        const updatedData = {job_stage_id, candidates};

        if (actionType === eCandidateActionTypes.EXTEND) {
            if (this.hasDeadlineChanged()) {
                updatedData.deadline = postData.deadline;
                updatedData.deadline_timezone = postData.deadline_timezone;
            }
            if (this.hasQuestionOverridesChanged()) {
                updatedData.question_overrides = postData.question_overrides;
            }
        }

        const resendEmailStatus = await this.resendEmail(job_id, job_stage_type, job_stage_id, candidateIds);
        const { status, timestamp } = resendEmailStatus;

        if (status === eResponseStatus.SUCCESS) {
            updatedData.timestamp = timestamp;
        }

        onUpdate?.(updatedData);

        this.setState({
            confirmDialogResponseProps: {
                type: eConfirmDialogResponseType.SUCCESS,
                sentence: `Record updated${timestamp ? ' and email invite sent' : ''}.`,
                onClose
            }
        });
    }

    areMultipleCandidates() {
        const { candidates } = this.props;
        return candidates.length > 1;
    }

    isFormSubmittable() {
        return (
            this.hasDeadlineChanged() ||
            (this.areMultipleCandidates() || this.hasQuestionOverridesChanged())
        );
    }

    hasDeadlineChanged() {
        const {
            original_deadline,
            deadline,
            original_deadline_timezone,
            deadline_timezone
        } = this.state;
        return !isEqual(original_deadline, deadline) ||
            !isEqual(original_deadline_timezone, deadline_timezone);
    }

    hasQuestionOverridesChanged() {
        return !isEqual(this.state.original_question_overrides, this.state.question_overrides);
    }

    handleUpdateCandidateError(updateCandidateStatus) {
        const {error} = updateCandidateStatus;
        if (error) {
            this.setState({
                confirmDialogResponseProps: {
                    type: eConfirmDialogResponseType.ERROR,
                    sentence: <span>Cannot successfully update record.<br/>Please try again.</span>,
                    onClose: () => this.setState({confirmDialogResponseProps: null})
                }
            });

            return true;
        }

        return false;
    }

    resendEmail(job_id, job_stage_type, job_stage_id, candidateIds) {
        if (this.isEmailResendRequired()) {
            return resendInvites(job_id, job_stage_type, job_stage_id, candidateIds);
        }
        return {};
    }

    async componentDidMount() {
        if (!this.areMultipleCandidates()) {
            await this.getInterviewSteps();
        }
    }

    render() {
        const {
            useCustomModal,
            actionType,
            isDisabled,
            hasInvitationEmail,
            onClose
        } = this.props;

        const {
            interview_steps,
            deadline,
            question_overrides,
            status,
            confirmDialogResponseProps
        } = this.state;

        const deadlineTimezoneInitialValue = this.getDeadlineTimezoneInitialValue();

        const shouldShowQuestions = this.shouldShowQuestions();

        const candidates = this.getCandidates();
        const shouldClosingDateBeDisabled = this.shouldClosingDateBeDisabled();

        const mainContent = (
            <React.Fragment>
                <div className="candidate-labels">
                    <div className="label">Candidate{candidates?.length > 1 ? "s": ""}</div>
                    <div className="values">
                        {candidates?.map(({candidate_id, first_name, last_name}, index) => (
                            <div key={`${candidate_id}-${index}`} className="value">{getFullName(first_name, last_name)}</div>
                        ))}
                    </div>
                </div>
                {actionType === eCandidateActionTypes.EXTEND && (
                    <React.Fragment>
                        <div className={`closing-date disabled-${shouldClosingDateBeDisabled}`}>
                            {shouldClosingDateBeDisabled && (
                                <React.Fragment>
                                    <div className="label">Closing Date</div>
                                    <div className="value-disabled">{formatDate(deadline)}</div>
                                </React.Fragment>
                            )}
                            {!shouldClosingDateBeDisabled && (
                                <CustomDatePicker
                                    label="Closing Date"
                                    value={deadline}
                                    minDate={eDeadlineDay.YESTERDAY}
                                    minMessage="Please select a valid deadline."
                                    onChange={(e) => this.onInputChange("deadline", convertDateToCommonFormat(e))}
                                    disabled={isDisabled}
                                />
                            )}
                        </div>
                        {isAdminUserSettingEnabled(eUserSettings.CANDIDATE_DEADLINE_TIMEZONE) && (
                            <TypedDropdown
                                classes={`deadline-timezone`}
                                label={`Timezone`}
                                dropdownType={eDropdownTypes.TIMEZONES}
                                required={false}
                                initialValue={deadlineTimezoneInitialValue}
                                isMultipleSelection={false}
                                showDropdownOnInitialFocus={true}
                                onUpdate={(e) => this.onInputChange("deadline_timezone", e.target.value)}
                            />
                        )}
                    </React.Fragment>
                )}
                {shouldShowQuestions && (
                    <div className="questions">
                        {interview_steps
                            .filter(({interview_step_type}) => interview_step_type === eInterviewStepType.QUESTION)
                            .map((interview_step) => {
                                const { job_interview_step_id } = interview_step;
                                const thisQuestionOverrides = question_overrides.find((override) => override.job_interview_step_id === job_interview_step_id);
                                return (
                                    <QuestionItem
                                        keyProp={job_interview_step_id}
                                        displayType={this.getQuestionDisplayType()}
                                        item={{
                                            ...interview_step,
                                            ...thisQuestionOverrides
                                        }}
                                        onChange={this.onQuestionItemInputChange}
                                    />
                                );
                            })}
                    </div>
                )}
                {actionType === eCandidateActionTypes.EXTEND && !shouldClosingDateBeDisabled && !hasInvitationEmail && (
                    <NoInvitationEmailMessage />
                )}
                <div className={`actions`}>
                    {isDisabled && (
                        <button
                            type="button"
                            className="btn"
                            onClick={onClose}
                        >
                            OK
                        </button>
                    )}
                    {!isDisabled && (
                        <button
                            disabled={actionType === eCandidateActionTypes.RESEND ? false : !this.isFormSubmittable()}
                            type="submit"
                            className={`btn ${status}`}
                            onClick={this.onSubmitButtonClick}
                        >
                            {this.isEmailResendRequired() && hasInvitationEmail ? "Resend Invite" : "Save"}
                        </button>
                    )}
                </div>
            </React.Fragment>
        );

        const content = useCustomModal ?
            (
                <CustomModal
                    title={this.getComponentTitle()}
                    classes={this.getComponentClassNames()}
                    onClose={onClose}
                >
                    {mainContent}
                </CustomModal>
            ) :
            (
                <div className={`candidate-action no-modal`}>
                    {mainContent}
                </div>
            );

        return (
            <React.Fragment>
                {(!confirmDialogResponseProps || confirmDialogResponseProps?.type === eConfirmDialogResponseType.ERROR) &&
                    content
                }
                <ConfirmDialogResponse {...confirmDialogResponseProps} />
            </React.Fragment>
        );
    }
}

CandidateAction.propTypes = {
    useCustomModal: PropTypes.bool,
    title: PropTypes.string,
    actionType: PropTypes.oneOf(objectToArray(eCandidateActionTypes)),

    // either pass on candidates data or just one candidate_id
    candidates: PropTypes.arrayOf(
        PropTypes.shape({
            candidate_id: PropTypes.number,
            first_name: PropTypes.string,
            last_name: PropTypes.string,
            email: PropTypes.string,
            deadline: PropTypes.string,
            question_overrides: PropTypes.array,
        })
    ),
    candidate_id: PropTypes.number,

    job_id: PropTypes.number,
    job_stage_type: PropTypes.oneOf(objectToArray(eJobStageTypes)),
    job_stage_id: PropTypes.number,
    job_timezone: PropTypes.string,
    hasInvitationEmail: PropTypes.bool,
    deadline: PropTypes.string,

    isDisabled: PropTypes.bool,
    onUpdate: PropTypes.func,
    onClose: PropTypes.func
};

CandidateAction.defaultProps = {
    useCustomModal: true,
    title: '',
    actionType: eCandidateActionTypes.EXTEND,

    candidates: [{
        id: null,
        first_name: "",
        last_name: "",
        email: "",
        deadline: null,
        question_overrides: [],
    }],
    candidate_id: null,

    job_id: null,
    job_stage_type: null,
    job_stage_id: null,
    job_timezone: null,
    hasInvitationEmail: false,
    deadline: null,

    isDisabled: false,
    onUpdate: null,
    onClose: null
};

export default CandidateAction;
