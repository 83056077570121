import React from 'react';
import ReactDOM from 'react-dom';

import './styles/quill.snow-v1.3.7.css';
import './styles/materialize.css';
import './styles/C0_admin.scss';

import App from './App';

function doRender() {
    // get rid of leading '#', separate params from query string à la window.location
    const [path, queryString] = window.location.hash.substring(1).split('?');

    ReactDOM.render(
        <React.StrictMode>
            <App path={path} queryString={queryString} />
        </React.StrictMode>,
        document.getElementById('root')
    );
}


window.addEventListener("hashchange", doRender);
doRender();
