import React from "react";
import * as PropTypes from "prop-types";

import './style.scss';


const InterviewGuide = ({ summary, content }) => {
    if (!summary && !content) {
        return (<div />);
    }

    return (
        <div className={`interview-guide`}>
            <div className={`section-title`}>Interview Guide</div>
            {summary && (
                <div className={`interview-guide-summary`}>{summary}</div>
            )}
            {content && (
                <div className={`interview-guide-content`} dangerouslySetInnerHTML={{ __html: content}} />
            )}
        </div>
    );
}

InterviewGuide.propTypes = {
    summary: PropTypes.string,
    content: PropTypes.string
};

InterviewGuide.defaultProps = {
    summary: null,
    content: null
};

export default InterviewGuide;