import {
    eInterviewStepType,
    eLibraryAssetType,
    eLibraryQuestionType,
    eQuestionType,
    JOB_STAGES_FIELDS,
    jobDefaultEvaluationAttributes,
    objectToArray
} from "./index";
import {cloneDeep, isEqual, sortBy} from "lodash";

export const getCurrentJobStage = (job, stage_id) => {
    const { job_stages = [], job_stage_ids = [] } = job;
    const default_job_stage_id = 1;
    const selected_job_stage_id = stage_id || job_stage_ids[0] || job_stages[0]?.job_stage_id || default_job_stage_id;

    return job_stages.find(({job_stage_id}) => job_stage_id === selected_job_stage_id) || {};
};

export const isJobStageUsingInterviewTemplate = (job, job_stage_id) => {
    const job_stage = getCurrentJobStage(job, job_stage_id);
    const { job_interview_steps, interview_template_steps } = job_stage;
    return !!job_stage.interview_template_id && isEqual(job_interview_steps, interview_template_steps);
}

export const getJobItem = (job, item) => {
    const isJobStageField = objectToArray(JOB_STAGES_FIELDS).includes(item);
    return (!isJobStageField ? job[item] : null) ||
        ((job.job_stages || []).find((job_stage) => !!job_stage[item]) || [])[item];
};

export const addEvaluationAttributesToggleAndDisplay = (job) => {
    return {
        ...job,
        job_stages: job.job_stages?.map((job_stage) => {
            const evaluation_attributes_enabled = job_stage.evaluation_attributes?.length > 0;

            return {
                ...job_stage,
                evaluation_attributes_enabled,
                evaluation_attributes_display: evaluation_attributes_enabled ? job_stage.evaluation_attributes : jobDefaultEvaluationAttributes
            };
        })
    };
}

export const sanitiseJobStageData = (job_stage) => {
    const { interview_template_id, job_interview_steps } = job_stage;
    const updatedJobStage = {...job_stage};

    if (!!interview_template_id) {
        updatedJobStage.interview_template_steps = cloneDeep(job_interview_steps);
    } else {
        updatedJobStage.interview_template_steps = [];
    }

    return {
        ...updatedJobStage
    };
}

export const getPracticeQuestionIndex = (jobInterviewSteps) => {
    return jobInterviewSteps.findIndex(({interview_step_type}) => interview_step_type === eInterviewStepType.PRACTICE);
};

export const getPreInterviewQuestions = (jobInterviewSteps) => {
    return jobInterviewSteps.slice(0, getPracticeQuestionIndex(jobInterviewSteps) + 1);
};

export const getInterviewQuestions = (jobInterviewSteps) => {
    let questionNumber = 0;

    return jobInterviewSteps
        .slice(getPracticeQuestionIndex(jobInterviewSteps) + 1, jobInterviewSteps.length)
        .filter(({interview_step_type}) =>
            interview_step_type === undefined ||
            interview_step_type === eInterviewStepType.MESSAGE ||
            interview_step_type === eInterviewStepType.QUESTION
        ).map((step) => {
            const { library_asset_type } = step;
            const isQuestion = library_asset_type === eLibraryAssetType.QUESTION;
            questionNumber += isQuestion && 1;

            return {
                ...step,
                question_number: isQuestion ? questionNumber : null
            };
        });
};

export const getMatchingInterviewStep = (interviewSteps, item) => {
    const { is_stock_library_question, stock_library_question_id, tenant_library_question_id } = item;
    return interviewSteps.find((step) => {
        return step.question_type === eQuestionType.VIDEO &&
            (
                is_stock_library_question ?
                    step.stock_library_question_id === stock_library_question_id :
                    step.tenant_library_question_id === tenant_library_question_id
            );
    });
};

export const setLibraryItemDefaultVideoSelection = (library, interview_steps = []) => {
    return library.map((item) => {
        const { video, question_type } = item;
        if (video) {
            // old data structure
            return item;
        }

        // only worry if question_type is "video"
        if (question_type !== eQuestionType.VIDEO) {
            return item;
        }

        const { videos = [], is_stock_library_question } = item;

        let returnValue = {...item};

        // If the question has already been selected in the interview steps,
        // then we use the selected video_id from interview_step
        let video_id = null;

        if (!!interview_steps.length) {
            const matchingStep = getMatchingInterviewStep(interview_steps, item);

            if (matchingStep) {
                video_id = matchingStep.stock_library_question_video_id || matchingStep.tenant_library_question_video_id;
            }
        }

        // if video_id is still not found, then we'll default to the first video found on the item
        if (!video_id) {
            const sortedVideos = sortBy(videos, ({video_tags}) => (video_tags?.[0] || "zzz").toLowerCase());
            const firstVideo = sortedVideos[0];
            video_id = firstVideo?.stock_library_question_video_id || firstVideo?.tenant_library_question_video_id || null;
        }

        if (video_id) {
            returnValue.stock_library_question_video_id = is_stock_library_question ? video_id : null;
            returnValue.tenant_library_question_video_id = !is_stock_library_question ? video_id : null;
        }

        return returnValue;
    });
};

const findLibraryItem = (library, questionType, questionId) => {
    return library.find((item) => {
        const {
            id,
            stock_library_question_id,
            tenant_library_question_id
        } = item;

        if (!!id) {
            return id === questionId;
        } else if (questionType === eLibraryQuestionType.STOCK) {
            return stock_library_question_id === questionId;
        }

        return tenant_library_question_id === questionId;
    });
}

export const updateLibraryItemVideoSelection = (library, questionType, questionId, videoId) => {
    const question = findLibraryItem(library, questionType, questionId);

    if (
        !!question &&
        (
            (question.is_stock_library_question && question.stock_library_question_video_id !== videoId) ||
            (!question.is_stock_library_question && question.tenant_library_question_video_id !== videoId)
        )
    ) {
        return library.map((item) => {
            if (item === question) {
                const { id } = item;
                const newItem = {...item};

                if (!!id) {
                    newItem.id = videoId;
                } else if (item.is_stock_library_question) {
                    newItem.stock_library_question_video_id = videoId;
                } else {
                    newItem.tenant_library_question_video_id = videoId;
                }

                return newItem;
            } else {
                return item;
            }
        });
    }

    return null;
};

export const addLibraryItemToLibraryArray = (library, newItem, isUpdate = false) => {
    const newItemToAdd = {...newItem};
    const { videos, image, video, tenant_library_question_video_id } = newItem;

    if (!!videos && !!videos.length) {
        const { video : newVideo, image : newImage, tenant_library_question_video_id: newVideoId } = videos[0];
        if (!video) {
            newItemToAdd.video = newVideo;
        }
        if (!image) {
            newItemToAdd.image = newImage;
        }
        if (!tenant_library_question_video_id) {
            newItemToAdd.tenant_library_question_video_id = newVideoId;
        }
    }

    if (isUpdate) {
        const { library_asset_type, tenant_library_question_id } = newItem;
        library = library.map((item) => {
            if (item.library_asset_type === library_asset_type && item.tenant_library_question_id === tenant_library_question_id) {
                return newItem;
            }
            return item;
        });
    } else {
        library.push(newItemToAdd);
    }

    return library;
};
