import React from "react";
import * as PropTypes from "prop-types";
import { IconCrossThin } from "../../Icons";

import './style.scss';


const CustomChip = ({classes, icon, value, onDelete}) => (
    <div
        key={value}
        className={`custom-chip ${classes}`}
    >
        {!!icon && (<span className={'icon'}>{icon}</span>)}
        <span className={'value'}>{value}</span>
        <span className={'remove'} onClick={onDelete}>{IconCrossThin}</span>
    </div>
);

CustomChip.propTypes = {
    id: PropTypes.string,
    classes: PropTypes.string,
    icon: PropTypes.object,
    value: PropTypes.string,
    onDelete: PropTypes.func
};

CustomChip.defaultProps = {
    id: "",
    classes: "",
    icon: null,
    value: "",
    onDelete: null
};

export default CustomChip;
