import React from "react";
import * as PropTypes from "prop-types";
import CustomTextField from "../../Form/CustomTextField";
import CustomTextArea from "../../Form/CustomTextArea";
import { findIndex } from "lodash";
import { eTextAreaType } from "../../../utils";

import './style.scss';


const idTitleMap = {
    "applicant-interview-invitation": "Invitation Email",
    "applicant-interview-finalised": "Completed Interview Receipt Email",
    "applicant-accepted": "Accepted Notification Email",
    "applicant-rejected": "Rejected Notification Email"
};

class StepEmails extends React.Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange(emailId, field, value) {
        const { emails, onInputChange } = this.props;

        const emailIndex = findIndex(emails, ({id}) => id === emailId);

        const emailToUpdate = emails[emailIndex];
        const newEmail = {
            ...emailToUpdate,
            [field]: value
        };

        const newEmails = Array.from(emails);
        // remove old email
        newEmails.splice(emailIndex, 1);

        // add new email
        newEmails.splice(emailIndex, 0, newEmail);

        onInputChange({
            target: {
                id: "email_contents",
                value: newEmails
            }
        });
    }

    render() {
        const { emails } = this.props;
        return(
            <div className={`step-emails`}>
                {!!emails && emails.map(({id, subject, from_name, content}) => {
                        return (
                            <div key={id} className={`email-template`}>
                                <span className="header">{idTitleMap[id]}</span>
                                <CustomTextField
                                    id={`${id}-subject`}
                                    required={true}
                                    isError={subject.length === 0}
                                    label="Subject"
                                    value={subject}
                                    onChange={(e) => this.onChange(id, "subject", e.target.value)}
                                />
                                <CustomTextField
                                    id={`${id}-from-name`}
                                    required={true}
                                    isError={from_name.length === 0}
                                    label="Sender Name"
                                    value={from_name}
                                    onChange={(e) => this.onChange(id, "from_name", e.target.value)}
                                />
                                <CustomTextArea
                                    type={eTextAreaType.RICH}
                                    id={`${id}-content`}
                                    required={true}
                                    isError={content === "<p><br></p>"}
                                    label="Description"
                                    value={content}
                                    onChange={(e) => this.onChange(id, "content", e.target.value)}
                                />
                            </div>
                        );
                    }
                )}
            </div>
        );
    }
}

StepEmails.propTypes = {
    emails: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            content: PropTypes.string,
            subject: PropTypes.string,
            from_name: PropTypes.string
        })
    ),
    onInputChange: PropTypes.func
};

StepEmails.defaultProps = {
    emails: [],
    onInputChange: null
};

export default StepEmails;
