import React from 'react';
import './style.scss';

export const eLoaderTypes = {
    LINEAR: 'linear',
    TRIPLE_BOX: "triple-box"
}

export const eLoaderSize = {
    DEFAULT: '',
    PAGE: 'page',
    PAGE_WITH_HEADER: 'page with-header'
}

const Loader = ({type = eLoaderTypes.TRIPLE_BOX, size = eLoaderSize.DEFAULT}) => {
    if (type === eLoaderTypes.TRIPLE_BOX) {
        return (
            <div className={`loader triple-box ${size}`}>
                <div></div>
                <div></div>
                <div></div>
            </div>
        );
    }

    return (
        <div className="loader linear">
            <div className="loader-progress"></div>
        </div>
    );
}

export default Loader;