import React from "react";
import PropTypes from 'prop-types';
import SubNav from "../../components/SubNav";
import InterviewTemplates from "./interview";
import SMSTemplates from "./sms";
import {eTemplatesType, objectToArray} from "../../utils";
import EmailTemplates from "./email";

import './style.scss';


const subNavMainItems = [{
    label: "Interview",
    route: eTemplatesType.INTERVIEW
}, {
    label: "Email",
    route: eTemplatesType.EMAIL
}, {
    label: "SMS",
    route: eTemplatesType.SMS
}];

class TemplatesPage extends React.Component {
    constructor(props) {
        super(props);

        this.renderRouteContent = this.renderRouteContent.bind(this);
    }

    renderRouteContent(activeRoute) {
        const { integrationProvider, urlFor } = this.props;
        switch (activeRoute) {
            case 'templates-interview':
                return <InterviewTemplates hasNavigationMenu={!integrationProvider} />;
            case 'templates-email':
                return (
                    <EmailTemplates urlFor={urlFor} />
                );
            case 'templates-sms':
                return (<SMSTemplates />);
            default:
                throw Error('Unexpected route "' + activeRoute + '".');
        }
    }

    render() {
        const { activeRoute, is_alcami_staff, urlFor } = this.props;

        return (
            <div className="templates">
                <SubNav
                    activeRoute={activeRoute}
                    urlFor={urlFor}
                    mainItems={subNavMainItems}
                />
                {this.renderRouteContent(activeRoute, is_alcami_staff, urlFor)}
            </div>
        );
    }
}


TemplatesPage.propTypes = {
    activeRoute: PropTypes.oneOf(objectToArray(eTemplatesType)).isRequired,
    is_alcami_staff: PropTypes.bool.isRequired,
    urlFor: PropTypes.func.isRequired,
    integrationProvider: PropTypes.string,
    showSubNavigation: PropTypes.bool
};

export default TemplatesPage;