import React from 'react'
import * as PropTypes from "prop-types";
import { sendEvaluationEmail } from "../../api";
import CustomModal from "../CustomModal";
import Avatar from "../Avatar";
import CustomCheckbox, { eCustomCheckboxSize } from "../Form/CustomCheckbox";
import ConfirmDialogResponse from "../ConfirmDialog/Response";
import {eConfirmDialogResponseType} from "../../utils";

import './style.scss';


class EvaluatorsInvite extends React.Component {
    constructor(props) {
        super(props);

        this.getAvailableEvaluators = this.getAvailableEvaluators.bind(this);
        this.selectAllEvaluators = this.selectAllEvaluators.bind(this);
        this.deselectAllEvaluators = this.deselectAllEvaluators.bind(this);
        this.toggleStaffId = this.toggleStaffId.bind(this);
        this.isReadyToSubmit = this.isReadyToSubmit.bind(this);
        this.emailEvaluators = this.emailEvaluators.bind(this);

        this.state = {
            staff_ids: [],
            errorMessage: null,
            confirmDialogResponseProps: null
        };
    }

    getAvailableEvaluators() {
        const { job: { job_stages }, job_stage_id }= this.props;
        const job_stage = job_stages.find((stage) => stage.job_stage_id === job_stage_id) || {};

        return job_stage.evaluators || [];
    }

    selectAllEvaluators() {
        const evaluators = this.getAvailableEvaluators();
        this.setState({
            staff_ids: evaluators.map(({staff_id}) => staff_id)
        });
    }

    deselectAllEvaluators() {
        this.setState({
            staff_ids: []
        });
    }

    toggleStaffId(staff_id) {
        const { staff_ids } = this.state;

        let updated_staff_ids = [...staff_ids];

        if (staff_ids.includes(staff_id)) {
            updated_staff_ids = updated_staff_ids.filter((id) => id !== staff_id);
        } else {
            updated_staff_ids.push(staff_id);
        }

        this.setState({
            staff_ids: updated_staff_ids
        });
    }

    isReadyToSubmit() {
        const { staff_ids } = this.state;

        return !!staff_ids.length;
    }

    async emailEvaluators() {
        const { job: { job_id }, onClose, integrationProvider, urlFor } = this.props;
        const { staff_ids } = this.state;
        const deep_link_path = urlFor('assess-job-details', { integrationProvider, job_id });
        const context = `my-jobs`;
        const response = await sendEvaluationEmail(staff_ids, deep_link_path, context);

        if (response.error) {
            this.setState({
                confirmDialogResponseProps: {
                    type: eConfirmDialogResponseType.ERROR,
                    sentence: `Error occurred. Please try again.`,
                    onClose: () => this.setState({confirmDialogResponseProps: null})
                }
            });
            return false;
        } else {
            this.setState({
                confirmDialogResponseProps: {
                    type: eConfirmDialogResponseType.SUCCESS,
                    sentence: `Evaluation invite sent.`,
                    onClose
                }
            });
            return true;
        }
    }

    componentDidMount() {
        const evaluators = this.getAvailableEvaluators();

        if (evaluators.length) {
            this.setState({
                staff_ids: evaluators.map(({staff_id}) => staff_id)
            });
        }
    }

    render() {
        const { staff_ids, confirmDialogResponseProps } = this.state;

        const { onClose } = this.props;
        const evaluators = this.getAvailableEvaluators();
        const isReadyToSubmit = this.isReadyToSubmit();

        const isAllSelected = !!staff_ids.length && evaluators.length === staff_ids.length;

        return (
            <React.Fragment>
                <ConfirmDialogResponse {...confirmDialogResponseProps} />
                {(!confirmDialogResponseProps || confirmDialogResponseProps?.type === eConfirmDialogResponseType.ERROR) && (
                    <CustomModal
                        title={`Email Evaluators`}
                        classes={`evaluators-invite-modal open`}
                        closeOnOverlayClick={false}
                        onClose={onClose}
                    >
                        <div className={`evaluators-invite`}>
                            <div className={`message`}>
                                Please select the evaluators to send email to:
                            </div>
                            <div className={`bulk-select`}>
                                <button className={`link ${isAllSelected ? 'disabled' : ''}`} onClick={this.selectAllEvaluators}>Select all</button> | <button className={`link ${!staff_ids.length ? 'disabled' : ''}`} onClick={this.deselectAllEvaluators}>Deselect all</button>
                            </div>
                            <div className={`evaluators-list`}>
                                {evaluators.map((evaluator) => {
                                    const { staff_id, first_name, last_name, position } = evaluator;
                                    const isSelected = staff_ids.includes(staff_id);
                                    return (
                                        <div className={`evaluator-selection ${isSelected ? 'selected' : ''}`} onClick={() => this.toggleStaffId(staff_id)}>
                                            <CustomCheckbox
                                                size={eCustomCheckboxSize.SMALL}
                                                key={`evaluator-selection-toggle-${staff_id}`}
                                                id={`evaluator-selection-toggle-${staff_id}`}
                                                checked={staff_ids.includes(staff_id)}
                                            />
                                            <Avatar key={evaluator.staff_id} data={{id: evaluator.staff_id, ...evaluator}} bordered={false} />
                                            <div className={`evaluator-details`}>
                                                <span className={`name`}>{`${first_name} ${last_name}`.trim()}</span>
                                                <span className={`position`}>{position}</span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className={`actions`}>
                                <div className={`number-selected`}>
                                    <span>{staff_ids.length}</span> of <span>{evaluators.length}</span> selected
                                </div>
                                <div className={`react-button text-link cancel`} onClick={onClose}>Cancel</div>
                                <div
                                    className={`react-button ${isReadyToSubmit ? 'active' : 'disabled'}`}
                                    onClick={isReadyToSubmit ? this.emailEvaluators : null}
                                >
                                    Send
                                </div>
                            </div>
                        </div>
                    </CustomModal>
                )}
            </React.Fragment>
        );
    }
}

EvaluatorsInvite.propTypes = {
    job: PropTypes.object,
    job_stage_id: PropTypes.string,
    integrationProvider: PropTypes.string,
    urlFor: PropTypes.func
};

EvaluatorsInvite.defaultProps = {
    job: null,
    job_stage_id: null,
    integrationProvider: null,
    urlFor: null
};

export default EvaluatorsInvite;