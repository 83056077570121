import React from "react";
import * as PropTypes from "prop-types";

import CustomTextArea from "../../Form/CustomTextArea";
import {eCandidateLiveInterviewType, eTextAreaType, objectToArray} from "../../../utils";

import './style.scss';


const CandidateLiveInterview = ({ type, live_interview_notes, readOnly, onChange, onBlur }) => (
    <div className={`candidate-live-interview`}>
        <div className={`evaluation-item`}>
            <div className='recording'>
                <div className='in-progress'>LIVE INTERVIEW<br/>IN PROGRESS</div>
                <div className='wait-message'>Recording will appear here shortly<br/> after interview is finished</div>
            </div>

            <div className='interview-notes'>
                <div className={`interview-notes-title`}>Interview Notes</div>
                {type === eCandidateLiveInterviewType.PROFILE &&
                    live_interview_notes.map(([avatar, name, comment], index) => (
                        <div key={`evaluator-comment-${index}`} className={`evaluator-comment-profile`}>
                            {avatar}
                            <div className={`evaluator-name`}>{name}</div>
                            <div className={`evaluator-comment`}>
                                {comment}
                            </div>
                        </div>
                    ))
                }
                {type === eCandidateLiveInterviewType.EVALUATION && (
                    <CustomTextArea
                        classes={`evaluator-comment-evaluation`}
                        type={eTextAreaType.STANDARD}
                        id="live_interview_notes"
                        value={live_interview_notes}
                        readOnly={readOnly}
                        onChange={onChange}
                        onBlur={(e) => onBlur(e.target.value)}
                    />
                )}
            </div>
        </div>
    </div>
);

CandidateLiveInterview.propTypes = {
    type: PropTypes.oneOf(objectToArray(eCandidateLiveInterviewType)),
    live_interview_notes: PropTypes.string,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func
};

CandidateLiveInterview.defaultProps = {
    type: eCandidateLiveInterviewType.PROFILE,
    live_interview_notes: "",
    readOnly: true,
    onChange: null,
    onBlur: null
};

export default CandidateLiveInterview;