import React from 'react';
import PropTypes from 'prop-types';
import LabelValueTile from '../LabelValueTile';
import {
    eJobsType,
    eUserTypes,
    shiftUTCDateToLocalTime,
    objectToArray,
    getDeadlineValue
} from "../../utils";
import { getJobSummary } from "../../api";
import { isEqual } from "lodash";
import EvaluatorsThumbnails from "../EvaluatorsThumbnails";

import './style.scss';


class JobTile extends React.Component {
    constructor(props) {
        super(props);
        this.retrieveJobSummary = this.retrieveJobSummary.bind(this);
        this.handleClick = this.handleClick.bind(this);

        this.state = {
            job: props.job
        };
    }

    componentWillUnmount() {
        const { realtimeDeadline } = this.props;
        if (realtimeDeadline) {
            this.removeDeadlineTimer();
        }
    }

    setDeadlineTimer(seconds) {
        this.intervalID = setInterval(
            () => {
                this.removeDeadlineTimer();
                this.updateStatus();
            },
            seconds
        );
    }

    removeDeadlineTimer() {
        clearInterval(this.intervalID);
    }

    updateStatus() {
        this.forceUpdate();
    }

    async retrieveJobSummary(id) {
        const { viewType } = this.props;
        const response = await getJobSummary(id);
        const first_job_stage = (response.job_stages || [])[0];

        this.setState({
            job: {
                ...response,
                ...first_job_stage,
                evaluated_count: viewType === eUserTypes.MANAGER ? first_job_stage.evaluated_count : first_job_stage.evaluated_by_me_count
            }
        });
    }

    handleClick() {
        const { clickAction, job } = this.props;
        if (clickAction) {
            clickAction(job.job_id);
        }
    }

    async componentDidMount() {
        const { job: { id, title } } = this.state;
        if (!title) {
            await this.retrieveJobSummary(id);
        }
    }

    async componentDidUpdate() {
        const { job: { id } } = this.props;
        if (this.state.job.job_id !== id) {
            await this.retrieveJobSummary(id);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (!isEqual(this.state, nextState) || !isEqual(this.props, nextProps));
    }

    render() {
        const {
            type,
            viewType,
            realtimeDeadline,
            urlFor,
            integrationProvider
        } = this.props;

        const {
            job: {
                job_id,
                title,
                location = "-",
                deadline,
                deadline_type,
                ongoing_deadline_days,
                invited_count = "-",
                received_count = "-",
                evaluated_count = "-",
                accepted_count = "-",
                evaluators = []
            }
        } = this.state;

        const millisecondsToDeadline = shiftUTCDateToLocalTime(deadline) - new Date();

        if (millisecondsToDeadline > 0 && realtimeDeadline) {
            this.setDeadlineTimer(millisecondsToDeadline);
        }

        const deadlineValue = getDeadlineValue(deadline_type, deadline, ongoing_deadline_days);

        const expired = millisecondsToDeadline <= 0;

        let route;

        if (type === 'draft') {
            route = 'manage-existing-job-draft';
        } else if (viewType === eUserTypes.MANAGER) {
            route = 'manage-job-details';
        } else {
            route = 'assess-job-details';
        }

        const titleLink = !urlFor ? null : urlFor(route, { integrationProvider, job_id });

        return (
            <a
                key={`jobTile-${job_id}`}
                id={`jobTile-${job_id}`}
                className={`job-tile ${viewType} ${expired ? 'expired': ''}`}
                href={titleLink}
            >
                {viewType === eUserTypes.MANAGER && (
                    <div className="id">
                        #{job_id}
                    </div>
                )}
                <span className="title">{title}</span>
                {viewType === eUserTypes.MANAGER && (
                    <LabelValueTile classes="invited" label="Invited" value={invited_count} />
                )}
                <LabelValueTile classes="received" label="Received" value={received_count} />
                <LabelValueTile classes="evaluated" label="Evaluated" value={evaluated_count} />
                {viewType === eUserTypes.MANAGER && (
                    <LabelValueTile classes="accepted" label="Accepted" value={accepted_count} />
                )}
                <LabelValueTile classes="location" label="Location" value={location} />
                <LabelValueTile classes="deadline" label="Deadline" value={deadlineValue} />
                {viewType === eUserTypes.MANAGER && (
                    <EvaluatorsThumbnails evaluators={evaluators} />
                )}
            </a>
        );
    }
}

JobTile.propTypes = {
    job: PropTypes.shape({
        job_id: PropTypes.number,
        title: PropTypes.string,
        location: PropTypes.string,
        deadline: PropTypes.string,
        invited_count: PropTypes.number,
        received_count: PropTypes.number,
        evaluated_count: PropTypes.number,
        accepted_count: PropTypes.number,
        evaluators: PropTypes.arrayOf(
            PropTypes.shape({
                staff_id: PropTypes.number,
                first_name: PropTypes.string,
                last_name: PropTypes.string,
                picture_url: PropTypes.string,
            })
        )
    }).isRequired,
    clickAction: PropTypes.func,
    type: PropTypes.oneOf(objectToArray(eJobsType)),
    viewType: PropTypes.oneOf(objectToArray(eUserTypes)),
    realtimeDeadline: PropTypes.bool,
    urlFor: PropTypes.func.isRequired,
    integrationProvider: PropTypes.string
};

JobTile.defaultProps = {
    job: {
        id: null,
        title: "",
        location: "",
        deadline: null,
        invited_count: null,
        received_count: null,
        evaluated_count: null,
        accepted_count: null,
        evaluators: [],
    },
    clickAction: null,
    type: null,
    viewType: eUserTypes.EVALUATOR,
    realtimeDeadline: false,
    urlFor: null,
    integrationProvider: ""
};

export default JobTile;
