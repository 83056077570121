import React from "react";
import * as PropTypes from "prop-types";
import {
    CATEGORY_GROUP_ORDER,
    EXCLUDED_CATEGORY_GROUP,
    defaultLibraryCategory,
    eLibraryAssetType,
    eLibraryQuestionType,
    eQuestionDisplayType,
    libraryAllTag,
    objectToArray,
    eInterviewStepType,
    eQuestionContainerTypes,
    scrollToID,
    getUserSetting,
    eUserSettings,
    eResponseType,
    getIntegrationProviderFromPath,
    INTEGRATION_PROVIDER
} from "../../utils";
import {compact, flatten, flattenDeep, forEach, isEqual, omit, pull, sortBy, uniq, uniqWith} from "lodash";
import CustomTextField from "../Form/CustomTextField";
import CustomCheckbox, { eCustomCheckboxSize } from "../Form/CustomCheckbox";
import QuestionItem from "../JobDraft/StepQuestions/QuestionItem";
import ConfirmDialog from "../ConfirmDialog";

import './style.scss';


class Library extends React.Component {
    constructor(props) {
        super(props);

        this.updateSearchPhrase = this.updateSearchPhrase.bind(this);
        this.clearSearchPhrase = this.clearSearchPhrase.bind(this);
        this.updateHasVideoFilter = this.updateHasVideoFilter.bind(this);

        this.getItems = this.getItems.bind(this);
        this.getSortedGroupedItems = this.getSortedGroupedItems.bind(this);

        this.jumpToCategory = this.jumpToCategory.bind(this);

        this.toggleQuestionTagSelection = this.toggleQuestionTagSelection.bind(this);
        this.isQuestionTagChecked = this.isQuestionTagChecked.bind(this);
        this.isQuestionAllTagChecked = this.isQuestionAllTagChecked.bind(this);

        this.toggleVideoTag = this.toggleVideoTag.bind(this);
        this.toggleAllVideoTags = this.toggleAllVideoTags.bind(this);
        this.isVideoTagChecked = this.isVideoTagChecked.bind(this);

        this.confirmRemoval = this.confirmRemoval.bind(this);
        this.cancelRemoval = this.cancelRemoval.bind(this);

        this.setTagsAndCategories = this.setTagsAndCategories.bind(this);

        this.getGroupsAndCategories = this.getGroupsAndCategories.bind(this);
        this.getSelectedQuestion = this.getSelectedQuestion.bind(this);
        this.hasPracticeQuestion = this.hasPracticeQuestion.bind(this);
        this.isQuestionSelected = this.isQuestionSelected.bind(this);
        this.isQuestionDisabled = this.isQuestionDisabled.bind(this);

        this.doesCategoryHaveShownItems = this.doesCategoryHaveShownItems.bind(this);

        this.groupNameCheck = this.groupNameCheck.bind(this);

        this.state = {
            questionTags: [libraryAllTag],
            videoTags: [],
            searchPhrase: "",
            hasVideo: false,
            searchQuestionTags: [libraryAllTag],
            searchVideoTags: [],
            isAddingItem: false,
            selectedCategory: null,
            confirmDialogProps: null
        };
    }

    updateSearchPhrase(e) {
        this.setState({
            searchPhrase: e.target.value
        });
    }

    clearSearchPhrase() {
        this.setState({
            searchPhrase: ""
        });
    }

    updateHasVideoFilter(e) {
        this.setState({
            hasVideo: e.target.checked
        })
    }

    getItems() {
        const { items, libraryAssetType } = this.props;
        const { searchPhrase, hasVideo /*searchQuestionTags, searchVideoTags*/ } = this.state;
        let returnedItems = [...items];

        const filterText = searchPhrase.trim().toLowerCase();

        returnedItems = returnedItems.map((item) => {
            let isShown = true;
            if (filterText) {
                if (libraryAssetType === eLibraryAssetType.QUESTION) {
                    const { display_text } = item;
                    isShown = isShown && ((display_text || "").toLowerCase()).includes(filterText);
                }
                if (libraryAssetType === eLibraryAssetType.MESSAGE) {
                    const { page_title, html_content } = item;
                    isShown = isShown && (
                        ((page_title || "").toLowerCase()).includes(filterText) ||
                        ((html_content || "").toLowerCase()).includes(filterText)
                    );
                }
            }

            if (hasVideo) {
                if (libraryAssetType === eLibraryAssetType.QUESTION) {
                    isShown = isShown && (hasVideo ? !!item.videos?.length : true);
                }
                if (libraryAssetType === eLibraryAssetType.MESSAGE) {
                    isShown = isShown && (hasVideo ? !!item.video : true);
                }
            }

            /*
            if (libraryAssetType === eLibraryAssetType.QUESTION) {
                if (!!searchQuestionTags.length) {
                    if (!(searchQuestionTags.includes(libraryAllTag))) {
                        const tagsToSearch = pull(searchQuestionTags, libraryAllTag);

                        isShown = isShown && question_tags.some((tag) => tagsToSearch.includes(tag));
                    }
                }

                if (!!searchVideoTags.length) {
                    isShown = isShown && (video_tags || []).some((video_tag) => searchVideoTags.includes(video_tag));
                }
            }
            */

            return {
                ...item,
                isShown,
                category: item.category || defaultLibraryCategory
            };
        });

        return returnedItems;
    }

    getSortedGroupedItems(items) {
        const { libraryAssetType, libraryQuestionType } = this.props;

        let returnedItems = items || this.getItems();

        if (libraryAssetType === eLibraryAssetType.QUESTION) {
            if (libraryQuestionType === eLibraryQuestionType.STOCK) {
                returnedItems = sortBy(returnedItems, (({category_group}) => CATEGORY_GROUP_ORDER[category_group]), "category");
            } else if (libraryQuestionType === eLibraryQuestionType.CUSTOM) {
                returnedItems = sortBy(returnedItems, "category");
            }
        }

        return returnedItems;
    }

    jumpToCategory(category) {
        this.setState({
            selectedCategory: category
        });
        const el = document.getElementById(category);
        if (el) {
            el.scrollIntoView();
        }
    }

    toggleQuestionTagSelection(tag) {
        const { searchQuestionTags } = this.state;

        if (this.isQuestionTagChecked(tag)) {
            // un-check
            const newSearchQuestionTags = pull([...searchQuestionTags], tag);

            this.setState({
                searchQuestionTags: newSearchQuestionTags
            });
        } else {
            // check
            const newSearchQuestionTags = [...searchQuestionTags, tag];
            this.setState({
                searchQuestionTags: newSearchQuestionTags
            });
        }
    }

    isQuestionTagChecked(tag) {
        const { searchQuestionTags } = this.state;
        return searchQuestionTags.includes(tag);
    }

    isQuestionAllTagChecked() {
        const { searchQuestionTags } = this.state;
        return searchQuestionTags.includes(libraryAllTag);
    }

    toggleVideoTag(tag) {
        const { searchVideoTags } = this.state;

        if (this.isVideoTagChecked(tag)) {
            // un-check
            this.setState({
                searchVideoTags: pull([...searchVideoTags], tag)
            });
        } else {
            // check
            this.setState({
                searchVideoTags: [...searchVideoTags, tag]
            });
        }
    }

    toggleAllVideoTags() {
        const { videoTags, searchVideoTags } = this.state;

        if (searchVideoTags.length === 0) {
            // add all
            this.setState({
                searchVideoTags: videoTags
            });
        } else {
            // remove all
            this.setState({
                searchVideoTags: []
            });
        }
    }

    isVideoTagChecked(tag) {
        const { searchVideoTags } = this.state;
        return searchVideoTags.includes(tag);
    }

    confirmRemoval(libraryAssetType, questionId, e) {
        const { onRemove } = this.props;
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            confirmDialogProps: {
                title: "Remove Item",
                message: "Are you sure you want to delete this item?",
                actions: [
                    {
                        label: "Cancel",
                        classes: "outline",
                        onClick: () => this.cancelRemoval()
                    },
                    {
                        label: "Remove",
                        classes: "warning",
                        onClick: () => {
                            if (!!onRemove) {
                                onRemove(libraryAssetType, questionId);
                            }
                            this.cancelRemoval()
                        }
                    }
                ]
            }
        });
    }

    setTagsAndCategories() {
        const { items } = this.props;

        const updatedQuestionTags = uniq(
            compact(
                flatten(
                    [...(items.map(item => item.question_tags))]
                )
            )
        );

        const updatedVideoTags = uniq(
            compact(
                flattenDeep(
                    items.map(({videos}) => flatten((videos || []).map(({video_tags}) => video_tags)))
                )
            ).sort()
        );

        this.setState({
            questionTags: updatedQuestionTags,
            videoTags: updatedVideoTags
        });
    }

    getGroupsAndCategories() {
        const { items } = this.props;

        return uniqWith(
            this.getSortedGroupedItems(items).map(
                ({category, category_group}) => ({category_group, category})
            ),
            isEqual
        );
    }

    getSelectedQuestion(libraryAssetType, libraryQuestionType, questionId) {
        const { interview_steps = [] } = this.props;
        if (!interview_steps) {
            return false;
        }

        return interview_steps.find((question) => {
            const {
                stock_library_question_id,
                tenant_library_question_id,
                tenant_library_message_id
            } = question;

            return libraryAssetType === eLibraryAssetType.MESSAGE ?
                tenant_library_message_id === questionId :
                libraryQuestionType === eLibraryQuestionType.STOCK ?
                    stock_library_question_id === questionId :
                    tenant_library_question_id === questionId;
        });
    }

    hasPracticeQuestion() {
        const { interview_steps = [] } = this.props;
        if (!interview_steps) {
            return false;
        }

        return !!interview_steps.find(({interview_step_type}) => interview_step_type === eInterviewStepType.PRACTICE);
    }

    isQuestionSelected(libraryAssetType, libraryQuestionType, questionId) {
        return !!this.getSelectedQuestion(libraryAssetType, libraryQuestionType, questionId);
    }

    isQuestionDisabled(libraryAssetType, libraryQuestionType, questionId, responseType) {
        const { selectionType } = this.props;
        const isPreInterview = selectionType === eQuestionContainerTypes.PRE_INTERVIEW;

        if (isPreInterview && responseType !== eResponseType.VIDEO) {
            return true;
        }

        const selectedQuestion = this.getSelectedQuestion(libraryAssetType, libraryQuestionType, questionId),
            isSelectedQuestion = !!selectedQuestion,
            selectedQuestionInterviewStepType = (selectedQuestion || {})["interview_step_type"],
            hasPracticeQuestion = this.hasPracticeQuestion();

        return isSelectedQuestion ?
            isPreInterview ? selectedQuestionInterviewStepType === eInterviewStepType.QUESTION : selectedQuestionInterviewStepType === eInterviewStepType.PRACTICE :
            isPreInterview ? hasPracticeQuestion /*|| selectedQuestionInterviewStepType === eInterviewStepType.QUESTION*/ : false;
    }

    doesCategoryHaveShownItems(items, category) {
        return !!items.find(({category: itemCategory, isShown}) => itemCategory === category && isShown);
    }

    groupNameCheck() {
        const { displayType } = this.props;
        const { selectedCategory } = this.state;
        const groupNameElements = document.querySelectorAll("div.category-name");
        let activeElement = selectedCategory;

        forEach(groupNameElements, (element) => {
            const offset = displayType === eQuestionDisplayType.SELECTING ? 146 :
                Math.max(0, Math.floor(193 - window.scrollY));

            if (Math.floor(element.getBoundingClientRect().top) <= offset) {
                activeElement = element.id;
            } else {
                return false;
            }
        });

        if (activeElement !== selectedCategory) {
            this.setState({
                selectedCategory: activeElement
            });
        }
    }

    cancelRemoval() {
        this.setState({
            confirmDialogProps: null
        });
    }

    checkForScroll() {
        const { selectedQuestionId } = this.props;

        if (!!selectedQuestionId) {
            scrollToID(selectedQuestionId, ".library-list");
        }
    }

    componentDidMount() {
        const { displayType } = this.props;

        if (displayType === eQuestionDisplayType.LISTING) {
            window.addEventListener('scroll', this.groupNameCheck);
        }

        this.checkForScroll();

        this.setTagsAndCategories();
    }

    componentDidUpdate(prevProps) {
        const { libraryQuestionType: prevLibraryQuestionType, selectedQuestionId: prevSelectedQuestionId } = prevProps;
        const { libraryAssetType, libraryQuestionType, selectedQuestionId, items } = this.props;

        if (libraryAssetType === eLibraryAssetType.QUESTION && prevLibraryQuestionType !== libraryQuestionType) {
            this.setTagsAndCategories(items);
        }

        if (selectedQuestionId && (selectedQuestionId !== prevSelectedQuestionId)) {
            this.checkForScroll();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.groupNameCheck);
    }

    render() {
        const { questionTags, videoTags, searchPhrase, hasVideo, searchVideoTags, selectedCategory, confirmDialogProps } = this.state;
        const { isLive, classes, libraryAssetType, libraryQuestionType, displayType, selectionType, onSelect, onDeselect, onEdit, onRemove, onVideoSelect } = this.props;

        const groupsAndCategories = this.getGroupsAndCategories();

        const hasQuestionTags = false; // !!(questionTags.length) && !isEqual(questionTags, [libraryAllTag]);
        const hasVideoTags = false; // !!(videoTags.length) && !isEqual(videoTags, [libraryAllTag]);
        const hasCategories = libraryAssetType === eLibraryAssetType.QUESTION && groupsAndCategories.length > 1;

        let currentCategoryGroup = null, // for category list
            currentCategoryName = null; // for library list

        const shownItems = this.getSortedGroupedItems();

        return (
            <div className={`library ${classes} ${libraryAssetType} ${hasQuestionTags || hasVideoTags || hasCategories ? 'has-filters' : ''}`}>
                {confirmDialogProps && (
                    <ConfirmDialog {...confirmDialogProps} />
                )}

                <div className={`library-search`}>
                    <CustomTextField
                        id={`search-phrase`}
                        label={`Search`}
                        value={searchPhrase}
                        onChange={this.updateSearchPhrase}
                    />
                    {!isLive && (
                        <CustomCheckbox
                            id={`has-video-filter`}
                            size={eCustomCheckboxSize.MEDIUM}
                            classes="has-video-checkbox"
                            checked={hasVideo}
                            label={'With video(s)'}
                            onChange={this.updateHasVideoFilter}
                        />
                    )}
                </div>

                {libraryAssetType === eLibraryAssetType.QUESTION && (hasQuestionTags || hasVideoTags || hasCategories) && (
                    <div className={`library-filter-container`}>
                        <div className={`library-filter`}>
                            {hasCategories && (
                                <div className={`categories-list`}>
                                    <div className={`category-list`}>
                                        {groupsAndCategories.map((item) => {
                                            const { category, category_group } = item;
                                            let showCategoryGroup = false;
                                            if (
                                                libraryQuestionType === eLibraryQuestionType.STOCK &&
                                                category_group !== EXCLUDED_CATEGORY_GROUP &&
                                                currentCategoryGroup !== category_group
                                            ) {
                                                showCategoryGroup = true;
                                                currentCategoryGroup = category_group;
                                            }
                                            const isActive = this.doesCategoryHaveShownItems(shownItems, category);

                                            return (
                                                <React.Fragment key={`${category_group}-${category}`}>
                                                    {showCategoryGroup && (
                                                        <div className={`group-name`}>
                                                            &nbsp;
                                                        </div>
                                                    )}
                                                    {!!category && (
                                                        <div key={`category-${category}`} className={`category`}>
                                                            <span
                                                                className={`category-name ${isActive ? 'active' : ''} ${selectedCategory === category ? 'selected' : ''}`}
                                                                onClick={() => isActive && this.jumpToCategory(category)}
                                                            >
                                                                {category}
                                                            </span>
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                            {hasQuestionTags && false && (
                                <div className="tags-list">
                                    <div className={`filter-header`}>
                                        <div className={`filter-name`}>Tags</div>
                                    </div>
                                    {questionTags
                                        .filter((tag) => tag === libraryAllTag)
                                        .map((tag) => (
                                            <CustomCheckbox
                                                key={`question-${tag}`}
                                                id={tag}
                                                label={tag}
                                                checked={this.isQuestionTagChecked(tag)}
                                                onChange={() => this.toggleQuestionTagSelection(tag)}
                                            />
                                        ))
                                    }
                                    <div className={`tag-list`}>
                                        {questionTags
                                            .filter((tag) => tag !== libraryAllTag)
                                            .map((tag) => (
                                                <CustomCheckbox
                                                    key={tag}
                                                    id={tag}
                                                    label={tag}
                                                    value={tag}
                                                    checked={this.isQuestionTagChecked(tag)}
                                                    disabled={tag === libraryAllTag ? false : !!this.isQuestionAllTagChecked()}
                                                    onChange={() => this.toggleQuestionTagSelection(tag)}
                                                />
                                            ))
                                        }
                                    </div>
                                </div>
                            )}
                            {hasVideoTags && (
                                <div className={`tags-list`}>
                                    <div className={`filter-header`}>
                                        <div className={`filter-name`}>Filters</div>

                                    </div>
                                    <div className={`tag-list`}>
                                        {videoTags
                                            .map((tag) => (
                                                <CustomCheckbox
                                                    key={`video-${tag}`}
                                                    id={tag}
                                                    label={tag}
                                                    value={tag}
                                                    checked={this.isVideoTagChecked(tag)}
                                                    onChange={() => this.toggleVideoTag(tag)}
                                                />
                                            ))
                                        }
                                        <span
                                            className={`video-tag-all`}
                                            onClick={() => this.toggleAllVideoTags()}
                                        >
                                            {!searchVideoTags.length ? 'Select all' : 'Clear' }
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                <div className={`library-list`} onScroll={displayType === eQuestionDisplayType.SELECTING ? this.groupNameCheck : null}>
                    {shownItems.map((item) => {
                        const {
                            category,

                            is_stock_library_question,
                            stock_library_question_id,
                            tenant_library_question_id,
                            tenant_library_message_id,

                            library_asset_type,
                            response_type,
                            isShown
                        } = item;

                        const libraryQuestionType = is_stock_library_question ? eLibraryQuestionType.STOCK : eLibraryQuestionType.CUSTOM;
                        const questionId =
                                library_asset_type === eLibraryAssetType.MESSAGE ?
                                    tenant_library_message_id :
                                    is_stock_library_question ?
                                        stock_library_question_id :
                                        tenant_library_question_id;
                        const isRemovable = !!onRemove && !is_stock_library_question;

                        let showCategoryName = false;

                        if (currentCategoryName !== category) {
                            showCategoryName = this.doesCategoryHaveShownItems(shownItems, category);
                            currentCategoryName = category;
                        }

                        const canEditQuestion =
                            (
                                getIntegrationProviderFromPath() === INTEGRATION_PROVIDER.CRITERIA ||
                                getUserSetting(eUserSettings.EDIT_LIBRARY_ITEMS)
                            ) &&
                            library_asset_type === eLibraryAssetType.QUESTION;

                        return (
                            <React.Fragment key={`library-${library_asset_type}-${libraryQuestionType}-${questionId}`}>
                                {showCategoryName && (
                                    <div id={category} className={`category-name`}>
                                        {library_asset_type === eLibraryAssetType.MESSAGE ? 'Messages' : category}
                                    </div>
                                )}
                                <div className={`question-item-container ${isShown ? '' : 'filtered'}`}>
                                    <QuestionItem
                                        isLive={isLive}
                                        displayType={displayType}
                                        item={omit(item, "isShown")}
                                        selectionType={selectionType}
                                        isSelected={this.isQuestionSelected(library_asset_type, libraryQuestionType, questionId)}
                                        isDisabled={this.isQuestionDisabled(library_asset_type, libraryQuestionType, questionId, response_type)}
                                        selectedVideoTags={searchVideoTags}
                                        onSelect={onSelect}
                                        onDeselect={onDeselect}
                                        onEdit={canEditQuestion ? onEdit : null}
                                        onRemove={isRemovable ? (e) => this.confirmRemoval(library_asset_type, questionId, e) : null}
                                        optionsAlwaysDisplayed={false}
                                        onVideoSelect={onVideoSelect}
                                    />
                                </div>
                            </React.Fragment>
                        );
                    })}
                    {!shownItems.length && (
                        <span className="no-result">Custom interview {libraryAssetType === eLibraryAssetType.MESSAGE ? 'messages' : 'questions'} you create will be added to your library here.  To create a new interview {libraryAssetType === eLibraryAssetType.MESSAGE ? 'message' : 'question'} click 'Add New'.</span>
                    )}
                </div>
            </div>
        );
    }
}

Library.propTypes = {
    classes: PropTypes.string,
    interview_steps: PropTypes.array,
    items: PropTypes.array,
    libraryAssetType: PropTypes.oneOf(objectToArray(eLibraryAssetType)),
    libraryQuestionType: PropTypes.oneOf(objectToArray(eLibraryQuestionType)),
    displayType: PropTypes.oneOf(objectToArray(eQuestionDisplayType)),
    selectionType: PropTypes.oneOf(objectToArray(eQuestionContainerTypes)),
    selectedQuestionId: PropTypes.string,
    onSelect: PropTypes.func,
    onDeselect: PropTypes.func,
    onEdit: PropTypes.func,
    onRemove: PropTypes.func,
    onVideoSelect: PropTypes.func
};

Library.defaultProps = {
    classes: "",
    interview_steps: [],
    items: [],
    libraryAssetType: eLibraryAssetType.QUESTION,
    libraryQuestionType: null,
    displayType: eQuestionDisplayType.LISTING,
    selectionType: eQuestionContainerTypes.INTERVIEW,
    selectedQuestionId: null,
    onSelect: null,
    onDeselect: null,
    onEdit: null,
    onRemove: null,
    onVideoSelect: null
};

export default Library;
