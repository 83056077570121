import React, { useState } from "react";
import * as PropTypes from "prop-types";

import CustomModal from "../../../CustomModal";

import './style.scss';


const initialQuestionDialogState = {
    isShown: false,
    title: null,
    content: null
};

const CandidateResponseQuestion = ({ classes, index, display_text, html_content }) => {
    const [{ isShown, title, content }, setQuestionDialog ] = useState(() => (initialQuestionDialogState));

    const toggleQuestionDialog = (newTitle = null, newContent = null) => {
        setQuestionDialog((prevDialog) => ({
            isShown: !prevDialog.isShown,
            title: newTitle,
            content: newContent
        }));
    };

    return (
        <div className={`candidate-response-question ${classes}`}>
            <span className={`display-text`}>
                {`Q${index}. ` + display_text}
            </span>
            {!!html_content && (
                <span
                    className={`extend-link`}
                    onClick={() => toggleQuestionDialog(
                        `Q${index}. ${display_text}`,
                        <div className={`library-html_content`} dangerouslySetInnerHTML={{__html: html_content}} />
                    )}
                >
                Info
            </span>
            )}
            {isShown && (
                <CustomModal
                    classes={`question-dialog open`}
                    title={title}
                    onClose={toggleQuestionDialog}
                    closeOnOverlayClick={true}
                >
                    {content}
                </CustomModal>
            )}
        </div>
    );
}

CandidateResponseQuestion.propTypes = {
    classes: PropTypes.string,
    index: PropTypes.number,
    display_text: PropTypes.string,
    html_content: PropTypes.string
};

CandidateResponseQuestion.defaultProps = {
    classes: "",
    index: null,
    display_text: "",
    html_content: ""
};

export default CandidateResponseQuestion;