import React from "react";
import Avatar from "../../Avatar";

import "./style.scss";


const CandidatesPanel = ({
    title,
    location,
    candidates,
    selectedCandidateId,
    onCandidateClick,
    backLinkURL
}) => {
    let watchAgainCount = 0,
        submittedCount = 0;

    return (
        <div className={`candidates-panel`}>
            <div className={`job-details-panel`}>
                <div className={`job-title-container`}>
                    {backLinkURL && (
                        <a className={`back-link`} href={backLinkURL}>&lt; <span>back</span></a>
                    )}
                    <span className={`job-title`}>{title}</span>
                </div>
                <div className={`job-location`}>{location}</div>
            </div>
            {candidates && (
                <div key={`candidate-list`} className={`candidate-list`}>
                    {candidates.map((candidateInterview) => {
                        const {candidate_id, first_name, last_name, picture_url, recommendation, other_staff_in_progress_evaluation_count, other_staff_submitted_evaluation_count = null, submitted_at} = candidateInterview;
                        const avatarData = {
                            id: candidate_id,
                            first_name,
                            last_name,
                            picture_url
                        };
                        const isCandidateSelected = candidate_id === selectedCandidateId;
                        const watchAgain = !!recommendation;

                        if (watchAgain) {
                            watchAgainCount++;
                        }

                        if (!!submitted_at) {
                            submittedCount++;
                        }

                        return (
                            <React.Fragment key={`responded-candidate-${candidate_id}`}>
                                {watchAgainCount === 1 && submittedCount === 0 && (
                                    <span className={`watch-again`}>
                                            <span className={`watch-again-text`}>Watch Again</span>
                                        </span>
                                )}
                                {submittedCount === 1 && (
                                    <span className={`evaluated`}>
                                            <span className={`evaluated-text`}>Evaluated</span>
                                        </span>
                                )}
                                <span
                                    className={`candidate-item ${isCandidateSelected ? 'selected' : ''}`}
                                    onClick={() => (candidate_id !== selectedCandidateId) && onCandidateClick(candidate_id)}
                                >
                                        <Avatar classes={`candidate-avatar ${isCandidateSelected ? 'active' : ''}`} size="s" data={avatarData}
                                                badgeText={`${other_staff_submitted_evaluation_count || (other_staff_in_progress_evaluation_count ? '...' : '')}`}/>
                                        <span className={`firstName`}>{first_name}</span>
                                        <span className={`lastName`}>{last_name}</span>
                                    </span>
                            </React.Fragment>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

export default CandidatesPanel;