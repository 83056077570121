import React from 'react';
import * as PropTypes from "prop-types";
import Avatar from "../Avatar";
import { eRecommendationTypes, objectToArray } from "../../utils";

import './style.scss';


class AvatarWithLabel extends React.Component {
    render() {
        const { classes, data, size, selectable, selected, bordered, onClick, label} = this.props;
        const avatarData = { classes, data, size, selectable, selected, bordered, onClick };
        return (
            <div className={`avatar-with-label ${label}`}>
                <div className={`avatar-label ${label}`}>
                    <span>{label === eRecommendationTypes.IN_PROGRESS ? '...' : label}</span>
                </div>
                <Avatar {...avatarData} />
            </div>
        );
    }
}

AvatarWithLabel.propTypes = {
    classes: PropTypes.string,
    data: PropTypes.object,
    size: PropTypes.string,
    selectable: PropTypes.bool,
    selected: PropTypes.bool,
    bordered: PropTypes.bool,
    onClick: PropTypes.func,
    label: PropTypes.oneOf(objectToArray(eRecommendationTypes))
};

AvatarWithLabel.defaultProps = {
    classes: "",
    data: {},
    size: "",
    selectable: false,
    selected: false,
    bordered: false,
    onClick: null,
    label: null
};

export default AvatarWithLabel;
