import React from "react";
import * as PropTypes from "prop-types";
import CustomColorPicker from "../Form/CustomColorPicker";
import CustomCheckbox, { eCustomCheckboxSize } from "../Form/CustomCheckbox";
import {IconParagraph} from "../Icons";
import {compact, forEach, isEqual} from "lodash";
import {
    convertDateToCommonFormat,
    convertToRGBA,
    eBrandingItemType,
    eInterviewTerminologies,
    formatDate,
    objectToArray
} from "../../utils";

import './style.scss';

const defaultJob = {
    title: "Designer",
    deadline: convertDateToCommonFormat(new Date()),
    interview_terminology: eInterviewTerminologies.INTERVIEW
};

class BrandingPreview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isCheckboxChecked: true
        };

        this.applyBranding = this.applyBranding.bind(this);
        this.getBrandingPropertyValue = this.getBrandingPropertyValue.bind(this);
        this.getPresetColors = this.getPresetColors.bind(this);
        this.getBrandingItemType = this.getBrandingItemType.bind(this);
        this.toggleCheckbox = this.toggleCheckbox.bind(this);
    }

    applyBranding() {
        const { branding : { properties } } = this.props;
        const brandingStyle = document.getElementsByClassName("branding-preview")[0].style;

        forEach(properties, ({name, value}) => {
            const type = this.getBrandingItemType(name);

            if (type === eBrandingItemType.IMAGE_FILE || type === eBrandingItemType.IMAGE_INPUT) {
                // discard -- only needed for upload purposes
            } else if (type === eBrandingItemType.IMAGE) {
                brandingStyle.setProperty(`${name}`, `url(${value})`);
            } else {
                brandingStyle.setProperty(`${name}`, this.getBrandingPropertyValue(name));
            }
        });
    }

    getBrandingPropertyValue(name) {
        const { branding : { properties } } = this.props;
        const property = properties.find(({name: id}) => {
            return id === name;
        }) || {};

        const { value } = property;
        let renderedValue = value;

        if (!!value && value.includes("rgba(var(--color-")) {
            const regex = value.match(/rgba\(var\((--color-\d-rgb)\), ([\d.]+)\)/),
                colorName = regex[1],
                opacity = regex[2];
            const color = this.getPresetColors().find(({name: n}) => n === colorName).value;
            renderedValue = convertToRGBA(color, opacity);
        }

        return renderedValue;
    }

    getPresetColors() {
        const presetColorNames = ["--color-1-rgb", "--color-2-rgb", "--color-3-rgb", "--color-4-rgb", "--color-5-rgb", "--color-6-rgb"];
        return compact(
            presetColorNames.map((name) => ({
                name: name,
                value: this.getBrandingPropertyValue(name)
            }))
        );
    }

    getBrandingItemType(key) {
        const images = ["--welcome-logo", "--welcome-background-image"],
            image_inputs = ["--welcome-logo-input", "--welcome-background-image-input"],
            image_files = ["--welcome-logo-file", "--welcome-background-image-file"];

        if (!key) {
            return null;
        }
        if (images.includes(key)) {
            return eBrandingItemType.IMAGE;
        }
        if (image_files.includes(key)) {
            return eBrandingItemType.IMAGE_FILE;
        }
        if (image_inputs.includes(key)) {
            return eBrandingItemType.IMAGE_INPUT;
        }
        if (key.split("-").includes("color")) {
            return eBrandingItemType.COLOR;
        }

        return key.split("-").pop()
    }

    toggleCheckbox() {
        const { isCheckboxChecked } = this.state;
        this.setState({
            isCheckboxChecked: !isCheckboxChecked
        });
    }

    componentDidMount() {
        this.applyBranding();
    }

    componentDidUpdate(prevProps) {
        const { branding : { properties: prevProperties } } = prevProps;
        const { branding : { properties } } = this.props;

        if (!isEqual(prevProperties, properties)) {
            this.applyBranding();
        }
    }

    render() {
        const { job = {}, onInputChange } = this.props;
        const { title: jobTitle, deadline: jobDeadline, interview_terminology: jobInterviewTerminology } = job;
        const { title: defaultJobTitle, deadline: defaultJobDeadline, interview_terminology: defaultJobInterviewTerminology } = defaultJob;

        const title = jobTitle || defaultJobTitle,
            deadline = jobDeadline || defaultJobDeadline,
            interview_terminology = jobInterviewTerminology || defaultJobInterviewTerminology;

        const { isCheckboxChecked } = this.state;
        return (
            <div className={`branding-preview`}>
                <div className={`welcome-details`}>
                    <div className={`welcome-details-logo`}>
                        <div className={`logo`} />
                    </div>
                    <div className={`welcome-details-background`}>
                        {!!onInputChange && (
                            <CustomColorPicker
                                key={`welcome-details-background-color`}
                                id={`--welcome-details-background-color`}
                                showManualInput={false}
                                presetColors={this.getPresetColors()}
                                value={this.getBrandingPropertyValue(`--welcome-details-background-color`)}
                                onChange={onInputChange}
                            />
                        )}
                    </div>
                    <div className={`welcome-details-content`}>
                        {/*<div className={`welcome-details-title`}>
                            Video {interview_terminology} Details
                            {!!onInputChange && (
                                <CustomColorPicker
                                    key={`welcome-details-color`}
                                    id={`--welcome-details-color`}
                                    showManualInput={false}
                                    presetColors={this.getPresetColors()}
                                    value={this.getBrandingPropertyValue(`--welcome-details-color`)}
                                    onChange={onInputChange}
                                />
                            )}
                        </div>*/}
                        <div className={`welcome-details-info`}>
                            <div className={`welcome-details-info-label`}>
                                <span className={`label`}>Position</span>
                                {!!onInputChange && (
                                    <CustomColorPicker
                                        key={`welcome-details-label-color`}
                                        id={`--welcome-details-label-color`}
                                        showManualInput={false}
                                        presetColors={this.getPresetColors()}
                                        value={this.getBrandingPropertyValue(`--welcome-details-label-color`)}
                                        onChange={onInputChange}
                                    />
                                )}
                            </div>
                            <div className={`welcome-details-info-value`}>
                                <span className={`value`}>{title}</span>
                                {!!onInputChange && (
                                    <CustomColorPicker
                                        key={`welcome-details-value-color`}
                                        id={`--welcome-details-value-color`}
                                        showManualInput={false}
                                        presetColors={this.getPresetColors()}
                                        value={this.getBrandingPropertyValue(`--welcome-details-value-color`)}
                                        onChange={onInputChange}
                                    />
                                )}
                            </div>

                            <div className={`welcome-details-info-label`}>
                                <span className={`label`}>Deadline</span>
                            </div>
                            <div className={`welcome-details-info-value`}>
                                <span className={`value`}>{formatDate(deadline)}</span>
                            </div>

                            <div className={`welcome-details-info-label`}>
                                <span className={`label`}>Estimated Time</span>
                            </div>
                            <div className={`welcome-details-info-value`}>
                                <span className={`value`}>15 minutes</span>
                            </div>
                        </div>
                        <div className={`welcome-details-position-description`}>
                            {!!onInputChange && (
                                <React.Fragment>
                                    <CustomColorPicker
                                        key={`welcome-position-description-border-color`}
                                        id={`--welcome-position-description-border-color`}
                                        classes={`position-description-border-color-picker`}
                                        showManualInput={false}
                                        presetColors={this.getPresetColors()}
                                        value={this.getBrandingPropertyValue(`--welcome-position-description-border-color`)}
                                        onChange={onInputChange}
                                    />
                                    <CustomColorPicker
                                        key={`welcome-position-description-background-color`}
                                        id={`--welcome-position-description-background-color`}
                                        classes={`position-description-background-color-picker`}
                                        showManualInput={false}
                                        presetColors={this.getPresetColors()}
                                        value={this.getBrandingPropertyValue(`--welcome-position-description-background-color`)}
                                        onChange={onInputChange}
                                    />
                                    <CustomColorPicker
                                        key={`welcome-position-description-color`}
                                        id={`--welcome-position-description-color`}
                                        classes={`position-description-color-picker`}
                                        showManualInput={false}
                                        presetColors={this.getPresetColors()}
                                        value={this.getBrandingPropertyValue(`--welcome-position-description-color`)}
                                        onChange={onInputChange}
                                    />
                                    <CustomColorPicker
                                        key={`welcome-position-description-icon-color`}
                                        id={`--welcome-position-description-icon-color`}
                                        classes={`position-description-icon-color-picker`}
                                        showManualInput={false}
                                        presetColors={this.getPresetColors()}
                                        value={this.getBrandingPropertyValue(`--welcome-position-description-icon-color`)}
                                        onChange={onInputChange}
                                    />
                                </React.Fragment>
                            )}
                            <div className={`welcome-details-description`}>
                                {IconParagraph}Position Description
                            </div>
                        </div>
                    </div>
                    <div className={`welcome-details-powered`}>
                        Powered by Criteria
                    </div>
                </div>
                <div className={`welcome-form`}>
                    <div className={`greeting`}>
                        Congratulations on being selected to participate in our one-way video {interview_terminology}.
                    </div>
                    <div className={`links`}>
                        <span>Learn about the process</span>
                        {!!onInputChange && (
                            <CustomColorPicker
                                key={`link-color`}
                                id={`--link-color`}
                                showManualInput={false}
                                presetColors={this.getPresetColors()}
                                value={this.getBrandingPropertyValue(`--link-color`)}
                                onChange={onInputChange}
                            />
                        )}
                        <span className={`second-link`}>Practice Question</span>
                    </div>
                    <div className={`terms`}>
                        <CustomCheckbox
                            id={`terms-checkbox`}
                            size={eCustomCheckboxSize.MEDIUM}
                            classes={`terms-checkbox`}
                            label={<span className={`terms-label`}>I agree to the <a href="https://www.ondemandassessment.com/home/privacy">privacy terms</a></span>}
                            checked={isCheckboxChecked}
                            onChange={this.toggleCheckbox}
                        />
                    </div>
                    <div className={`start-button-container`}>
                        {!!onInputChange && (
                            <CustomColorPicker
                                classes={`button-background-color`}
                                key={`button-background-color`}
                                id={`--button-background-color`}
                                showManualInput={false}
                                presetColors={this.getPresetColors()}
                                value={this.getBrandingPropertyValue(`--button-background-color`)}
                                onChange={onInputChange}
                            />
                        )}
                        <div className={`start-button`}>
                            Let's Get Started
                            <CustomColorPicker
                                key={`button-color`}
                                id={`--button-color`}
                                classes={`hide`}
                                showManualInput={false}
                                presetColors={this.getPresetColors()}
                                value={this.getBrandingPropertyValue(`--button-color`)}
                                onChange={onInputChange}
                            />
                        </div>
                    </div>


                </div>
                <div className={`page-banner`}>
                    <div className={`page-banner-title`}>
                        <div className={`welcome-message-line-1`}>
                            {!!onInputChange && (
                                <CustomColorPicker
                                    key={`welcome-message-first-line-override-color`}
                                    id={`--welcome-message-first-line-override-color`}
                                    classes={`welcome-message-first-line-override-color`}
                                    colorPickerPosition={`top left`}
                                    showManualInput={false}
                                    presetColors={this.getPresetColors()}
                                    value={this.getBrandingPropertyValue(`--welcome-message-first-line-override-color`)}
                                    onChange={onInputChange}
                                />
                            )}
                            <div className={`first-line`}>Welcome</div>
                            {!!onInputChange && (
                                <CustomColorPicker
                                    key={`welcome-message-background-color`}
                                    id={`--welcome-message-background-color`}
                                    classes={`welcome-message-background-color`}
                                    colorPickerPosition={`top right`}
                                    showManualInput={false}
                                    presetColors={this.getPresetColors()}
                                    value={this.getBrandingPropertyValue(`--welcome-message-background-color`)}
                                    onChange={onInputChange}
                                />
                            )}
                        </div>
                        <div className={`welcome-message-line-2`}>
                            <div className={`second-line`}>to your Video {interview_terminology}</div>
                            {!!onInputChange && (
                                <CustomColorPicker
                                    key={`welcome-message-color`}
                                    id={`--welcome-message-color`}
                                    classes={`welcome-message-color`}
                                    colorPickerPosition={`top right`}
                                    showManualInput={false}
                                    presetColors={this.getPresetColors()}
                                    value={this.getBrandingPropertyValue(`--welcome-message-color`)}
                                    onChange={onInputChange}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

BrandingPreview.propTypes = {
    job: PropTypes.shape({
        title: PropTypes.string,
        deadline: PropTypes.string,
        interview_terminology: PropTypes.oneOf(objectToArray(eInterviewTerminologies)),
        branding_id: PropTypes.number
    }),
    branding: PropTypes.object,
    onInputChange: PropTypes.func
};

BrandingPreview.defaultProps = {
    job: undefined,
    branding: {},
    onInputChange: null
};

export default BrandingPreview;
