import React from "react";
import * as PropTypes from "prop-types";
import {
    eJobDraftSteps,
    eUserSettings,
    getDeadlineValue,
    getSMSIntervalName,
    getUserSetting,
    isAlcamiStaff
} from "../../../utils";
import LabelValueTile from "../../LabelValueTile";
import Evaluators from "../../Evaluators";
import { getSMSTemplates, getStaff } from "../../../api";

import './style.scss';
import StaffSelection from "../../StaffSelection";
import InterviewTemplate from "../../InterviewTemplate";


class StepReview extends React.Component {
    constructor(props) {
        super(props);

        this.getStepsToShow = this.getStepsToShow.bind(this);
        this.getCurrentBrandingName = this.getCurrentBrandingName.bind(this);
        this.getInterviewTemplateName = this.getInterviewTemplateName.bind(this);
        this.getFeedbackContent = this.getFeedbackContent.bind(this);
        this.getStarRatingDescription = this.getStarRatingDescription.bind(this);
        this.getCandidatesDescription = this.getCandidatesDescription.bind(this);
        this.getEmailTemplates = this.getEmailTemplates.bind(this);
        this.shallShowCandidateContact = this.shallShowCandidateContact.bind(this);
        this.getSMSTemplateName = this.getSMSTemplateName.bind(this);

        this.state = {
            staff: [],
            smsTemplateItems: []
        };
    }

    getStepsToShow() {
        const { steps = [], integrationActive } = this.props;

        const returnValue = steps.filter((name) => {
            return ![eJobDraftSteps.EMAILS, eJobDraftSteps.REVIEW].includes(name);
        });

        if (integrationActive && !returnValue.includes(eJobDraftSteps.CANDIDATES)) {
            returnValue.push(eJobDraftSteps.CANDIDATES);
        }

        return returnValue;
    }

    getCurrentBrandingName() {
        const { job : { branding_id }, brandings } = this.props;

        const brandingName = (brandings.find(({branding_id : id}) => id === branding_id) || {}).branding_name;

        return brandingName || "-";
    }

    getInterviewTemplateName() {
        const { job_stage: { interview_template_name, interview_template_is_reusable } = {} } = this.props;

        if (interview_template_is_reusable) {
            return interview_template_name;
        }

        return '';
    }

    getFeedbackContent() {
        const { job_stage: { ask_for_feedback } = {} } = this.props;
        if (ask_for_feedback) {
            return (
                <div>
                    Candidates will be given an opportunity to provide feedback for the interview.
                </div>
            );
        }

        return (
            <div>
                Candidates will <span className="emphasis">not</span> be providing feedback for the interview.
            </div>
        );
    }

    getStarRatingDescription() {
        const { job_stage: { requires_response_evaluation } = {} } = this.props;
        return (
            <span>
                This job {requires_response_evaluation ? "requires" : <span className="emphasis">does not require</span>} evaluators
                to rate each one of a candidate’s responses (from one to five stars).
            </span>
        );
    }

    getCandidatesDescription() {
        const { job_stage: { is_open_registration, candidates } = {} } = this.props;
        return (
            <div className={`candidates-description`}>
                {is_open_registration && (
                    <span className={`open-registration`}>This job is open to any candidate.</span>
                )}
                {!candidates?.length && (
                    <span>No candidates to be invited.</span>
                )}
                {!!candidates?.length && (
                    <span>{candidates.length} candidate{candidates.length > 1 ? "s": ""} to be invited.</span>
                )}
            </div>
        );
    }

    getEmailTemplates() {
        const { job_stage: {
            sends_invitation_email,
            sends_acknowledgement_email,
            sends_successful_email,
            sends_unsuccessful_email,

            invitation_email_template_id,
            acknowledgement_email_template_id,
            successful_email_template_id,
            unsuccessful_email_template_id
        } = {} } = this.props;

        return (
            <div className={`email-list`}>
                <div className={`header`}>Emails</div>
                {sends_invitation_email && (
                    <div className={`value`}>
                        Invitation email(s) {invitation_email_template_id && (
                            <span> using <strong>Email Template #{invitation_email_template_id}</strong></span>
                        )} will be sent.
                    </div>
                )}
                {sends_acknowledgement_email && (
                    <div className={`value`}>
                        Acknowledgement email(s) {acknowledgement_email_template_id && (
                            <span> using <strong>Email Template #{acknowledgement_email_template_id}</strong></span>
                        )} will be sent.
                    </div>
                )}
                {sends_successful_email && (
                    <div className={`value`}>
                        Successful email(s) will be sent{successful_email_template_id && (
                        ` using Email Template #${successful_email_template_id}`
                    )}.
                    </div>
                )}
                {sends_unsuccessful_email && (
                    <div className={`value`}>
                        Unsuccessful email(s) will be sent{unsuccessful_email_template_id && (
                        ` using Email Template #${unsuccessful_email_template_id}`
                    )}.
                    </div>
                )}
                {!sends_invitation_email && !sends_acknowledgement_email && !sends_successful_email && !sends_unsuccessful_email && (
                    <div>(No emails set)</div>
                )}
            </div>
        );
    }

    getEvaluationAttributes() {
        const { job_stage: { evaluation_attributes = [] } = {} } = this.props;

        if (evaluation_attributes.length) {
            return (
                <div className={`attributes`}>
                    This job requires evaluators to score each candidate for each of the following attributes:<br/>
                    <ol className="attribute-list">
                        {evaluation_attributes.map((attribute) => (<li key={attribute} className={`attribute-item`}>{attribute}</li>))}
                    </ol>
                </div>
            );
        }

        return null;
    }

    shallShowCandidateContact() {
        const { job: { candidate_contact_name, candidate_contact_email } = {} } = this.props;
        return isAlcamiStaff() &&
            getUserSetting(eUserSettings.CANDIDATE_CONTACT) &&
            (candidate_contact_name || candidate_contact_email);
    }

    getSMSTemplateName(id) {
        const { smsTemplateItems } = this.state;
        const smsTemplateItem = smsTemplateItems.find(({sms_template_id}) => sms_template_id === id);

        if (smsTemplateItem) {
            const { sms_template_type } = smsTemplateItem;
            return (
                <span>
                    <span className="template-type">{sms_template_type}</span> SMS Template #{id}
                </span>
            );
        } else {
            return (
                <span>
                    (N/A)
                </span>
            );
        }
    }

    setVideoURL(url) {
        this.setState({
            videoURL: url
        });
    }

    async componentDidMount() {
        const staff = await getStaff();
        const smsTemplateItems = await getSMSTemplates();
        this.setState({
            staff,
            smsTemplateItems
        });
    }

    render() {
        const { job = {}, job_stage = {}, integrationActive, showEmailTemplates, onEdit } = this.props;
        const { staff } = this.state;
        const {
            title,
            location,
            owner_staff_id,
            candidate_contact_name = '',
            candidate_contact_email = ''
        } = job;
        const {
            interview_terminology,
            job_interview_steps,

            deadline_type,
            deadline,
            ongoing_deadline_days,

            evaluators,

            invitation_sms_template_id,
            reminder_sms_templates
        } = job_stage;

        return (
            <div className={`step-review`}>
                {this.getStepsToShow().map(
                    (step, index) => {
                        return (
                            <div key={`step-${index+1}`} className={`step`}>
                                <div className={`step-header-text`}>
                                    <span>
                                        {step}
                                    </span>
                                </div>
                                <div className={`step-edit`}>
                                    {(!integrationActive || step !== eJobDraftSteps.CANDIDATES) && (
                                        <span
                                            className={`step-edit-text`}
                                            onClick={() => onEdit(step)}
                                        >
                                            Edit
                                        </span>
                                    )}
                                </div>
                                <div className={`step-content`}>
                                    {step === eJobDraftSteps.JOB_DETAILS && (
                                        <React.Fragment>
                                            <LabelValueTile classes="job-title" label="Job Title" value={title} />
                                            <LabelValueTile classes="location" label="Location" value={location} />
                                            <LabelValueTile classes="deadline" label="Deadline" value={getDeadlineValue(deadline_type, deadline, ongoing_deadline_days)} />
                                            <LabelValueTile classes="branding-name" label="Branding" value={this.getCurrentBrandingName()} />
                                        </React.Fragment>
                                    )}
                                    {step === eJobDraftSteps.QUESTIONS && (
                                        <React.Fragment>
                                            <InterviewTemplate
                                                is_in_job_context={true}
                                                interview_template_name={this.getInterviewTemplateName()}
                                                interview_terminology={interview_terminology}
                                                job_interview_steps={job_interview_steps}
                                                isReadOnly={true}
                                            />
                                            <div className={`ask-for-feedback`}>
                                                {this.getFeedbackContent()}
                                            </div>

                                            <div className={`star-rating-description`}>
                                                {this.getStarRatingDescription()}
                                            </div>

                                            {this.getEvaluationAttributes()}
                                        </React.Fragment>
                                    )}
                                    {step === eJobDraftSteps.CANDIDATES && (
                                        <React.Fragment>
                                            {this.getCandidatesDescription()}
                                            {showEmailTemplates && this.getEmailTemplates()}
                                            {isAlcamiStaff() && getUserSetting(eUserSettings.TEMPLATES_SMS) && (
                                                <div className={`sms-list`}>
                                                    <div className={`header`}>Invitation SMS</div>
                                                    <div className={`value`}>{this.getSMSTemplateName(invitation_sms_template_id)}</div>

                                                    <div className={`header`}>
                                                        Reminder SMS <span className={`subheader`}>(applicable when deadline is specified)</span>
                                                    </div>
                                                    {!reminder_sms_templates.length && (
                                                        <div className={`value`}>(No reminder SMS set)</div>
                                                    )}
                                                    {reminder_sms_templates.map((reminder, i) => {
                                                        const { sms_template_id, interval_before_deadline } = reminder;

                                                        return (
                                                            <div className={`value`}>
                                                                {reminder_sms_templates.length > 1 ? `${i + 1}. ` : ''}
                                                                Send <strong>
                                                                    {this.getSMSTemplateName(sms_template_id)}
                                                                </strong> at <strong>
                                                                    {getSMSIntervalName(interval_before_deadline)}
                                                                </strong> before deadline.
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            )}
                                        </React.Fragment>
                                    )}
                                    {step === eJobDraftSteps.EVALUATORS && (
                                        <div className={`evaluators`}>
                                            <StaffSelection
                                                header={`Job Owner`}
                                                staffRecords={staff}
                                                staffFilter={({is_manager}) => !!is_manager}
                                                selectedStaffId={owner_staff_id}
                                                readOnly={true}
                                                selectionIdProp="owner_staff_id"
                                            />
                                            {this.shallShowCandidateContact() && (
                                                <div className={`candidate-contact`}>
                                                    <div className={`candidate-contact-header`}>
                                                        Candidate Support
                                                    </div>
                                                    <div className={`candidate-contact-name`}>{candidate_contact_name}</div>
                                                    <div className={`candidate-contact-email`}>{candidate_contact_email}</div>
                                                </div>
                                            )}
                                            {!!evaluators.length && (
                                                <React.Fragment>
                                                    <div className={`job-evaluators-header`}>Job Evaluators</div>
                                                    <Evaluators
                                                        classes={`evaluator-list`}
                                                        evaluators={evaluators}
                                                        questions={job_interview_steps}
                                                        editable={false}
                                                        removable={false}
                                                    />
                                                </React.Fragment>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
}

StepReview.propTypes = {
    job: PropTypes.object,
    job_stage: PropTypes.object,
    steps: PropTypes.array,
    brandings: PropTypes.array,
    integrationActive: PropTypes.bool,
    showEmailTemplates: PropTypes.bool,
    onEdit: PropTypes.func
};

StepReview.defaultProps = {
    job: {},
    job_stage: {},
    steps: [],
    brandings: [],
    integrationActive: false,
    showEmailTemplates: false,
    onEdit: null
};

export default StepReview;
