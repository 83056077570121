import React from "react";
import PropTypes from 'prop-types';
import {capitalize, cloneDeep, isEqual} from "lodash";
import InterviewTemplate from "../../../../components/InterviewTemplate";
import CustomModal from "../../../../components/CustomModal";
import CustomTextField from "../../../../components/Form/CustomTextField";
import {getInterviewTemplates} from "../../../../api";
import {
    eConfirmDialogResponseType,
    eInterviewStepType,
    eInterviewTemplateFields,
    eInterviewTerminologies,
    eTemplatesType,
    JOB_STAGES_FIELDS,
    getValue,
    objectToArray,
    eStructuredInterviewTypes
} from "../../../../utils";
import Loader from "../../../../components/Loader";
import Dropdown from "../../../../components/Dropdown";
import ConfirmDialogResponse from "../../../../components/ConfirmDialog/Response";
import TemplateDetails from "../../../../components/Templates/details";
import {IconExclamationMark} from "../../../../components/Icons";

import './style.scss';


class InterviewTemplateDetails extends TemplateDetails {
    constructor(props) {
        super(props);

        this.getTerminologyTypes = this.getTerminologyTypes.bind(this);
        this.getStructuredInterviewTypes = this.getStructuredInterviewTypes.bind(this);

        this.onInputChange = this.onInputChange.bind(this);
        this.isTemplateNameUnique = this.isTemplateNameUnique.bind(this);

        this.hasInterviewQuestions = this.hasInterviewQuestions.bind(this);
        this.isSubmitEnabled = this.isSubmitEnabled.bind(this);

        this.getSubmitButtonClassNames = this.getSubmitButtonClassNames.bind(this);

        this.state = {
            initialTemplate: cloneDeep(this.props.template),
            template: cloneDeep(this.props.template),
            confirmDialogResponseProps: null
        };
    }

    getTerminologyTypes() {
        const { template: { interviewTerminology = null } = {} } = this.state;
        return objectToArray(eInterviewTerminologies).map((type) => {
            return {
                id: type,
                firstLine: capitalize(type),
                selected: type === interviewTerminology,
                onClick: () => this.onInputChange({target: {id: eInterviewTemplateFields.INTERVIEW_TERMINOLOGY, value: type}})
            };
        });
    }

    getStructuredInterviewTypes() {
        const { template: { isLive } = {} } = this.state;
        return objectToArray(eStructuredInterviewTypes).map((type) => {
            return {
                id: type,
                firstLine: capitalize(type),
                selected:
                    (type === eStructuredInterviewTypes.LIVE && isLive) ||
                    (type === eStructuredInterviewTypes.VIDEO && !isLive)
                ,
                onClick: () => this.onInputChange({target:
                    {
                        id: eInterviewTemplateFields.IS_LIVE,
                        value: type === eStructuredInterviewTypes.LIVE
                    }
                })
            };
        });
    }

    getComponentClassNames() {
        const { template } = this.state;
        const { interviewTemplateId } = template;
        const isCreate = !interviewTemplateId;

        let returnValue = `interview-template-details open`;

        if (isCreate) {
            returnValue += ` create`;
        }

        return returnValue;
    }

    onInputChange(e) {
        const { target: { id, value } } = e;
        const { template } = this.state;

        const idToUpdate =
            id === JOB_STAGES_FIELDS.JOB_INTERVIEW_STEPS ?
            "interviewSteps" : id;

        this.setState({
            template: {
                ...template,
                [idToUpdate]: value
            }
        })
    }

    isTemplateNameUnique() {
        const { template } = this.state;
        const { templates } = this.props;
        const { interviewTemplateId, interviewTemplateName } = template;
        return !templates.filter((item) => item.interviewTemplateId !== interviewTemplateId)
            .map(({interviewTemplateName: name}) => name).includes((interviewTemplateName || '').trim());
    }

    hasInterviewQuestions() {
        const { template } = this.state;
        const { interviewSteps } = template;

        const nonPracticeInterviewSteps = interviewSteps?.filter(
            ({interview_step_type}) => {
                return interview_step_type !== eInterviewStepType.PRACTICE;
            });

        return !!(nonPracticeInterviewSteps.length);
    }

    isSubmitEnabled() {
        const { initialTemplate, template } = this.state;
        const { interviewTemplateId, interviewTemplateName, interviewTerminology } = template;

        const is_create = !interviewTemplateId;

        return !isEqual(initialTemplate, template)
            && !!interviewTemplateName
            && this.isTemplateNameUnique()
            && !!interviewTerminology
            && (is_create ? true : this.hasInterviewQuestions());
    }

    getSubmitButtonClassNames() {
        return `react-button submit ${this.isSubmitEnabled() ? 'active ripple' : 'disabled'}`;
    }

    async componentDidMount() {
        // we need this for when the component is accessed directly from App
        const { initialTemplate } = this.state;
        const { interview_template_id } = this.props;

        if (!initialTemplate && interview_template_id) {
            const templates = await getInterviewTemplates();
            const template = templates.find((item) => parseInt(item.interviewTemplateId) === interview_template_id);

            this.setState({
                templates,
                initialTemplate: cloneDeep(template),
                template: cloneDeep(template)
            });
        }
    }

    componentDidUpdate(prevProps) {
        // This is to allow newly created template to go straight to edit details.
        const { template: prevTemplate } = prevProps;
        const { interviewTemplateId: prevInterviewTemplateId } = prevTemplate || {};
        const { template } = this.props;
        const { interviewTemplateId } = template || {};
        if (!prevInterviewTemplateId && !!interviewTemplateId) {
            this.setState({
                initialTemplate: cloneDeep(template),
                template: cloneDeep(template)
            })
        }
    }

    render() {
        const { template, confirmDialogResponseProps } = this.state;

        if (!template) {
            return (<Loader />);
        }

        const { integrationProvider, showInModal, onClose } = this.props;
        const { interviewTemplateId, interviewTemplateName, interviewSteps, isLive } = template;
        const isCreate = !interviewTemplateId;

        const content = <React.Fragment>
            <div className={`interview-template-content ${isLive ? 'is-live' : ''}`}>
                <CustomTextField
                    classes={`interview_template_name`}
                    id={`interviewTemplateName`}
                    name={`interview_template_name`}
                    required={true}
                    label={`Interview Template Name`}
                    value={getValue(interviewTemplateName, "")}
                    isError={interviewTemplateName === "" || !this.isTemplateNameUnique()}
                    autoFocus={true}
                    onChange={this.onInputChange}
                    helperText={!this.isTemplateNameUnique() ? <span className={`helper-error`}>Template name must be unique.</span> : ''}
                />
                <Dropdown
                    required={true}
                    styles="floating-label fixed-label"
                    classes="type_dropdown"
                    label="Structured Interview Type"
                    disabled={true}
                    items={this.getStructuredInterviewTypes()}
                />
                <Dropdown
                    required={true}
                    styles="floating-label fixed-label"
                    classes="terminology_dropdown"
                    label="Terminology"
                    items={this.getTerminologyTypes()}
                />
                {!isCreate && (
                    <InterviewTemplate
                        integrationProvider={integrationProvider}
                        interview_template_id={interviewTemplateId}
                        isLive={isLive}
                        job_interview_steps={interviewSteps}
                        onChange={this.onInputChange}
                    />
                )}
            </div>
            <div className={`actions`}>
                {!isCreate && !this.hasInterviewQuestions() && (
                    <div className={`no-interview-questions`}>{IconExclamationMark} Please add interview question(s) to continue.</div>
                )}
                {!!onClose && (
                    <button className={`react-button cancel button-link`} onClick={onClose}>Cancel</button>
                )}
                <button
                    className={this.getSubmitButtonClassNames()}
                    onClick={() => this.isSubmitEnabled() && this.onSubmit(eTemplatesType.INTERVIEW)}
                >
                    {!isCreate ? 'Save' : 'Create'}
                </button>
            </div>
        </React.Fragment>;

        return (
            <React.Fragment>
                <ConfirmDialogResponse {...confirmDialogResponseProps} />
                {(!confirmDialogResponseProps || confirmDialogResponseProps?.type === eConfirmDialogResponseType.ERROR) &&
                    <React.Fragment>
                        {showInModal && (
                            <CustomModal
                                title={isCreate ? `Create Interview Template`: `Edit Interview Template`}
                                classes={`interview-template-details open ${isCreate ? 'create': ''} ${integrationProvider}`}
                                closeOnOverlayClick={false}
                                onClose={onClose}
                            >
                                {content}
                            </CustomModal>
                        )}
                        {!showInModal && (
                            <div className={`interview-template-details no-modal ${isCreate ? 'create' : ''} ${integrationProvider}`}>
                                {content}
                            </div>
                        )}
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

InterviewTemplateDetails.propTypes = {
    integrationProvider: PropTypes.string,
    templates: PropTypes.array,
    template: PropTypes.object,
    interview_template_id: PropTypes.number,
    showInModal: PropTypes.bool,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func
};

InterviewTemplateDetails.defaultProps = {
    integrationProvider: "",
    templates: [],
    template: null,
    interview_template_id: null,
    showInModal: true,
    onSubmit: null,
    onClose: null
};

export default InterviewTemplateDetails;