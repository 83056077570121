import React from "react";
import PropTypes from 'prop-types';
import Dropdown from "../Dropdown";
import { eDropDownPosition } from "../../utils";

import './style.scss';

class ActionDropdown extends React.Component {
    render() {
        const { classes, items, hasBorder, autoToggleOpen, autoToggleClose } = this.props;

        if (!items?.length) {
            return null;
        }

        return (
            <Dropdown
                styles="popup no-border no-arrow"
                classes={`action-dropdown ${classes} ${hasBorder ? 'has-border' : ''}`}
                label="..."
                showArrow={false}
                showUnderline={false}
                items={items}
                itemSettings={{
                    positioning: eDropDownPosition.TOP_RIGHT
                }}
                autoToggleOpen={autoToggleOpen}
                autoToggleClose={autoToggleClose}
            />
        );
    }
}

ActionDropdown.propTypes = {
    classes: PropTypes.string,
    items: PropTypes.array,
    hasBorder: PropTypes.bool,
    autoToggleOpen: PropTypes.bool,
    autoToggleClose: PropTypes.bool
};

ActionDropdown.defaultProps = {
    classes: "",
    items: [],
    hasBorder: false,
    autoToggleOpen: false,
    autoToggleClose: false
};

export default ActionDropdown;