import React from "react";
import {eConfirmDialogResponseType, eUserSettings, getUserSetting, isAlcamiStaff} from "../../utils";
import {getJobStageCandidateSummary, getJobSummary} from "../../api";

class CandidateInterview extends React.Component {
    constructor(props) {
        super(props);

        this.toggleCandidateSharing = this.toggleCandidateSharing.bind(this);
        this.showCompletedDialog = this.showCompletedDialog.bind(this);
    }

    async retrieveJobSummary() {
        const { job_id } = this.props;

        const job = await getJobSummary(job_id);
        // stop if job API returns an error
        if (job.error) {
            this.setState({
                loading: false,
                isError: true,
                confirmDialogProps: {
                    title: "Job Not Found",
                    message: "The job you are trying to access cannot be found.",
                    actions: [
                        {
                            label: "Back",
                            onClick: () => window.history.go(-1)
                        }
                    ]
                }
            });
            return false;
        }

        this.setState({job});
        return true;
    }

    async retrieveCandidateSummary() {
        const { staff_id, job_id, job_stage_type, job_stage_id } = this.props;
        const candidates = await getJobStageCandidateSummary(job_id, job_stage_type, job_stage_id, staff_id);

        this.setState({ candidates });
    }

    getJobStage() {
        const { job_stage_id } = this.props;
        const { job: { job_stages = [] } = {} } = this.state;

        return job_stages.find((jobStage) => jobStage.job_stage_id === job_stage_id) || {};
    }

    getJobDetailsURL(route = '') {
        const { integrationProvider, job_id, urlFor } = this.props;
        return urlFor(route, { integrationProvider, job_id });
    }

    getAssessJobDetailsURL() {
        return this.getJobDetailsURL('assess-job-details');
    }

    shallShowCompletedTimestamp() {
        const { candidate: { completed_at } = {} } = this.state;
        return isAlcamiStaff() && getUserSetting(eUserSettings.INTERVIEW_COMPLETED_TIMESTAMP) && !!completed_at;
    }

    hasStatusPanel() {
        const { candidate: { is_completed } = {} } = this.state;
        return this.shallShowCompletedTimestamp() || !is_completed;
    }

    toggleCandidateSharing() {
        this.setState({
            isCandidateSharingPanelShown: !this.state.isCandidateSharingPanelShown
        });
    }

    showCompletedDialog() {
        this.setState({
            confirmDialogResponseProps: {
                type: eConfirmDialogResponseType.SUCCESS,
                sentence: `Candidate responses shared.`,
                onClose: () => this.setState({confirmDialogResponseProps: null})
            }
        });
    }
}

export default CandidateInterview;
