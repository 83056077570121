import React from "react";
import * as PropTypes from "prop-types";
import CustomRadioField from "../../Form/CustomRadioField";
import Candidates from "../../Candidates";
import CustomCheckbox, { eCustomCheckboxSize } from "../../Form/CustomCheckbox";
import {
    eUserSettings,
    DEFAULT_CANDIDATE_FOR_EMAIL_PREVIEW,
    eDeadlineType,
    getEndOfDay,
    isAdminUserSettingEnabled
} from "../../../utils";
import EmailTemplateSelection from "../../EmailTemplateSelection";
import SMSTemplateSelection from "../../SMSTemplateSelection";

import './style.scss';


class StepCandidates extends React.Component {
    constructor(props) {
        super(props);

        this.getCandidates = this.getCandidates.bind(this);

        this.toggleCVUpload = this.toggleCVUpload.bind(this);
        this.clearCandidateForm = this.clearCandidateForm.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.canCandidateBeAdded = this.canCandidateBeAdded.bind(this);
        this.addCandidate = this.addCandidate.bind(this);
        this.updateCandidate = this.updateCandidate.bind(this);
        this.removeCandidate = this.removeCandidate.bind(this);

        this.showImportScreen = this.showImportScreen.bind(this);
        this.hideImportScreen = this.hideImportScreen.bind(this);

        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            deadline: null,

            isImportScreenShown: false
        };
    }

    getCandidates() {
        const { job_stage } = this.props;
        return job_stage.candidates;
    }

    toggleCVUpload() {
        const { job_stage: { skip_cv_upload }, onInputChange } = this.props;
        onInputChange({ target: { id: "skip_cv_upload", value: !skip_cv_upload } });
    }

    clearCandidateForm() {
        this.setState({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            deadline: null
        });
    }

    onFormChange(e) {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    canCandidateBeAdded() {
        const { firstName, lastName, email } = this.state;
        return firstName !== "" && lastName !== "" && email !== "";
    }

    addCandidate() {
        const { onInputChange } = this.props;
        const candidates = this.getCandidates();
        const { firstName: first_name, lastName: last_name, email, phone, deadline } = this.state;

        candidates.push({
            candidate_id: null,
            first_name,
            last_name,
            email,
            phone,
            deadline
        });

        onInputChange({
            target: {
                id: "candidates",
                value: candidates
            }
        }, true);

        this.clearCandidateForm();
    }

    updateCandidate(e) {
        const { target : { id, value } } = e;

        const [fieldName, candidateId] = id.split("-");

        const { onInputChange } = this.props;
        const candidates = this.getCandidates();

        const updatedCandidates = [...candidates].map((candidate) => {
            if (candidate.candidate_id === parseInt(candidateId)) {
                const updatedCandidate = {...candidate};
                updatedCandidate[fieldName] = value;
                return updatedCandidate;
            } else {
                return candidate;
            }
        });

        onInputChange({
            target: {
                id: "candidates",
                value: updatedCandidates
            }
        });
    }

    removeCandidate(candidate) {
        const { onInputChange } = this.props;
        const candidates = this.getCandidates();

        const updatedCandidates = candidates.filter((item) => item !== candidate);

        onInputChange({
            target: {
                id: "candidates",
                value: updatedCandidates
            }
        }, true);
    }

    showImportScreen() {
        this.setState({
            isImportScreenShown: true
        });
    }

    hideImportScreen() {
        this.setState({
            isImportScreenShown: false
        });
    }

    render() {
        const { classes = "", job, job_stage, integrationProviders, showEmailTemplates, onCandidateChange, onInputChange, onRefresh } = this.props;
        const { is_open_registration, skip_cv_upload = false, deadline_type, deadline, ongoing_deadline_days } = job_stage;

        let candidate = DEFAULT_CANDIDATE_FOR_EMAIL_PREVIEW;

        if (deadline_type === eDeadlineType.FIXED) {
            candidate.deadline = deadline;
        } else if (deadline_type === eDeadlineType.ONGOING) {
            candidate.deadline = getEndOfDay(ongoing_deadline_days);
        }

        return (
            <div className={`step-candidates ${classes}`}>
                <CustomRadioField
                    classes="is_open_registration"
                    id="is_open_registration"
                    required={true}
                    label="Candidate Registration Type"
                    items={[
                        {
                            id: "invite-only",
                            label: "Invite Only",
                            value: !is_open_registration
                        },
                        {
                            id: "open-registration",
                            label: "Open Registration",
                            value: is_open_registration
                        }
                    ]}
                    onChange={(e) => {
                        const id = e.target.id;
                        onInputChange({
                            target: {
                                id: "is_open_registration",
                                value: id === "open-registration"
                            }
                        })
                    }}
                />
                {isAdminUserSettingEnabled(eUserSettings.SKIP_CV_UPLOAD) && (
                    <CustomCheckbox
                        id={`skip-cv-checkbox`}
                        size={eCustomCheckboxSize.MEDIUM}
                        classes={`skip-cv-upload`}
                        label={'Skip CV Upload'}
                        checked={skip_cv_upload}
                        disabled={!is_open_registration}
                        onChange={this.toggleCVUpload}
                    />
                )}
                <Candidates
                    job={job}
                    job_stage={job_stage}
                    candidates={job_stage.candidates}
                    areExistingCandidatesRemovable={true}
                    integrationProviders={integrationProviders}
                    onCandidateChange={onCandidateChange}
                    onInputChange={onInputChange}
                    onRefresh={onRefresh}
                />

                {showEmailTemplates && (
                    <EmailTemplateSelection
                        job={job}
                        job_stage={job_stage}
                        candidate={candidate}
                        is_open_registration={is_open_registration}
                        onChange={onInputChange}
                    />
                )}

                {isAdminUserSettingEnabled(eUserSettings.TEMPLATES_SMS) && (
                    <SMSTemplateSelection
                        invitation_sms_template_id={job_stage.invitation_sms_template_id}
                        reminder_sms_templates={job_stage.reminder_sms_templates}
                        onChange={onInputChange}
                    />
                )}
            </div>
        );
    }
}

StepCandidates.propTypes = {
    classes: PropTypes.string,
    job: PropTypes.object,
    job_stage: PropTypes.object,
    integrationProviders: PropTypes.array,
    showEmailTemplates: PropTypes.bool,
    onCandidateChange: PropTypes.func,
    onInputChange: PropTypes.func,
    onRefresh: PropTypes.func
};

StepCandidates.defaultProps = {
    classes: "",
    job: {},
    job_stage: {},
    integrationProviders: [],
    showEmailTemplates: false,
    onCandidateChange: null,
    onInputChange: null,
    onRefresh: null
};

export default StepCandidates;
