import React from "react";
import * as PropTypes from "prop-types";
import {eTextAreaType, generateTemporaryId, preferredMediaRecorderMimeType, toTimeFormat} from "../../utils";

import './style.scss';
import CustomTextArea from "../Form/CustomTextArea";


const eRecordingAction = {
    RECORD: "record",
    STOP: "stop"
};

const eDisplayState = {
    RECORDING: "recording",
    PREVIEW: "preview"
};

class RecordVideo extends React.Component {
    constructor(props) {
        super(props);

        this.initialiseRecording = this.initialiseRecording.bind(this);
        this.addVideo = this.addVideo.bind(this);
        this.closeStream = this.closeStream.bind(this);
        this.getRecordButtonClassNames = this.getRecordButtonClassNames.bind(this);
        this.retakeRecording = this.retakeRecording.bind(this);
        this.onUpload = this.onUpload.bind(this);
        this.onClose = this.onClose.bind(this);

        this.state = {
            displayState: eDisplayState.RECORDING,
            isRecordingEnabled: false,
            isRecording: false,
            recordedVideos: [],
            timer: 0
        };
    }

    componentDidMount() {
        this.initialiseRecording();
    }

    async initialiseRecording() {
        let mediaRecorder, recordedBlobs;

        const cameraPreview = this.cameraPreview,
            recordButton = this.recordButton;

        const mediaSettings = {
            audio: true,
            video: {
                facingMode: "user",
                width: { min: 640, ideal: 1280, max: 1920 },
                height: { min: 400, ideal: 720, max: 1080 },
                frameRate: { min: 15, ideal: 25, max: 30 }
            }
        };

        const stream = await navigator.mediaDevices.getUserMedia(mediaSettings);
        
        if (stream) {
            this.setState({
                isRecordingEnabled: true
            });
        } else {
            alert("Cannot initiate recording.");
            return false;
        }

        window.stream = stream;
        cameraPreview.srcObject = stream;

        recordButton.addEventListener("click", async () => {
            const { isRecording } = this.state;
            const action = isRecording ? eRecordingAction.STOP : eRecordingAction.RECORD;

            if (action === eRecordingAction.RECORD) {
                this.setState({
                    isRecording: true
                });

                recordedBlobs = [];

                let options = {
                    mimeType: preferredMediaRecorderMimeType
                };

                if (!mediaRecorder) {
                    mediaRecorder = new MediaRecorder(window.stream, options);
                }

                mediaRecorder.ondataavailable = (e) => {
                    if (e.data && e.data.size > 0) {
                        recordedBlobs.push(e.data);
                    }
                };

                this.timerInterval = setInterval(
                    () => {
                        const { timer } = this.state;
                        this.setState({timer: timer + 1});
                    }, 1000
                );

                mediaRecorder.start(100);
            } else {
                mediaRecorder.stop();

                clearInterval(this.timerInterval);

                const blob = new Blob(recordedBlobs, {type: preferredMediaRecorderMimeType});

                await this.addVideo(blob);

                this.setState({
                    isRecording: false,
                    displayState: eDisplayState.PREVIEW,
                    timer: 0
                });
            }
        });
    }

    addVideo(video) {
        this.setState({
            recordedVideos: [video]
        });
    }

    closeStream() {
        window.stream.getTracks().forEach(function(track) {
            track.stop();
        });
    }

    getRecordButtonClassNames() {
        const { isRecordingEnabled, isRecording } = this.state;

        let returnValue = 'record-button';

        if (!isRecordingEnabled) {
            returnValue += ' disabled';
        } else if (isRecording) {
            returnValue += ' recording';
        }

        return returnValue;
    }

    retakeRecording() {
        this.setState({
            displayState: eDisplayState.RECORDING,
        });
    }

    onUpload() {
        const { onUpload } = this.props;
        const { recordedVideos } = this.state;

        const videoFile = {
            name: generateTemporaryId(),
            file: recordedVideos[0]
        };

        this.closeStream();

        !!onUpload && onUpload(videoFile);
    }

    onClose() {
        const { onClose } = this.props;

        this.closeStream();
        onClose?.();
    }

    render() {
        const { display_text, html_content } = this.props;
        const { displayState, isRecording, recordedVideos, timer } = this.state;
        const lastRecordedVideo = recordedVideos[recordedVideos.length - 1];

        return (
            <div className={`record-video`}>
                {(display_text || html_content) && (
                    <div className={`teleprompter`}>
                        {display_text && (
                            <div className={`display_text`}>{display_text}</div>
                        )}
                        {html_content && (
                            <CustomTextArea
                                classes="html-content"
                                type={eTextAreaType.RICH}
                                showToolbarToggle={false}
                                id="html_content"
                                readOnly={true}
                                value={html_content}
                            />
                        )}
                    </div>
                )}
                <div className={`video-recording-container ${displayState !== eDisplayState.RECORDING ? 'hide' : ''}`}>
                    <video
                        id={`camera-preview`}
                        className={`camera-preview`}
                        ref={(i) => this.cameraPreview = i}
                        playsInline={true}
                        autoPlay={true}
                        muted={true}
                    >
                    </video>
                    <div id={`cancel`} className={`cancel`} onClick={this.onClose}>&lt; Cancel</div>
                    <div id={`record`}
                         className={this.getRecordButtonClassNames()}
                         ref={(i) => this.recordButton = i}
                    >
                        <div className={`record-button-inner`} />
                    </div>
                    {isRecording && (
                        <div id={`timer`} className={`timer`}>
                            {toTimeFormat(timer)}
                        </div>
                    )}
                </div>

                {displayState === eDisplayState.PREVIEW && !!lastRecordedVideo && (
                    <div className={`recorded-video-preview-container`}>
                        <video
                            id={`recorded-preview`}
                            className={`recorded-preview`}
                            ref={(i) => this.recordedPreview = i}
                            src={URL.createObjectURL(lastRecordedVideo)}
                            playsInline={true}
                            controls={true}
                        />
                        <div className={`retake-button`} onClick={this.retakeRecording}>&lt; Retake</div>
                        <div className={`cancel-button`}>or <span className={`cancel-link`} onClick={this.onClose}>Cancel</span></div>
                        <div className={`react-button upload-button`} onClick={this.onUpload}>Upload</div>
                    </div>
                )}
            </div>
        );
    }
}

RecordVideo.propTypes = {
    display_text: PropTypes.string,
    html_content: PropTypes.object,
    onUpload: PropTypes.func,
    onClose: PropTypes.func
};

RecordVideo.defaultProps = {
    display_text: PropTypes.string,
    html_content: null,
    onUpload: null,
    onClose: null
};

export default RecordVideo;
