import React from "react";
import PropTypes from 'prop-types';
import {
    createEmailTemplate,
    createInterviewTemplate,
    createSMSTemplate,
    updateEmailTemplate,
    updateInterviewTemplate,
    updateSMSTemplate
} from "../../../api";
import {
    deformatTemplateContentPlaceholders,
    deformatTemplateSubjectPlaceholders,
    eConfirmDialogResponseType,
    eSMSTemplateTypes,
    eTemplatesType,
    SMS_INTERVALS
} from "../../../utils";

class TemplateDetails extends React.Component {
    sanitiseRecord(type, item) {
        return type === eTemplatesType.INTERVIEW ?
                {
                    ...item,
                    interviewTemplateName: item.interviewTemplateName.trim()
                } :
            type === eTemplatesType.EMAIL ?
                {
                    ...item,
                    subject: deformatTemplateSubjectPlaceholders(item.subject.trim()),
                    content: deformatTemplateContentPlaceholders(item.content.trim()),
                    header_banner_img_src: item.header_banner_img_src || null,
                    footer_banner_img_src: item.footer_banner_img_src || null
                } :
            type === eTemplatesType.SMS ?
                {
                    ...item,
                    content: item.content.trim(),
                    interval_before_deadline: item.sms_template_type === eSMSTemplateTypes.REMINDER ? SMS_INTERVALS[0].value : null,
                    is_default_for_new_job: false
                } :
           item;
    }

    async onSubmit(type) {
        const { template } = this.state;
        const { onSubmit, onClose } = this.props;

        const template_id =
            type === eTemplatesType.INTERVIEW ? template.interviewTemplateId :
            type === eTemplatesType.EMAIL ? template.email_template_id :
            type === eTemplatesType.SMS ? template.sms_template_id :
                null;

        const is_new_record = !template_id;
        const sanitised_record = this.sanitiseRecord(type, template);

        const response = is_new_record ?
            (
                type === eTemplatesType.INTERVIEW ? await createInterviewTemplate(sanitised_record) :
                type === eTemplatesType.EMAIL ? await createEmailTemplate(sanitised_record) :
                type === eTemplatesType.SMS ? await createSMSTemplate(sanitised_record) :
                    {}
            ) :
            (
                type === eTemplatesType.INTERVIEW ? await updateInterviewTemplate(template_id, sanitised_record) :
                type === eTemplatesType.EMAIL ? await updateEmailTemplate(template_id, sanitised_record) :
                type === eTemplatesType.SMS ? await updateSMSTemplate(template_id, sanitised_record) :
                    {}
            );

        if (response.error) {
            this.setState({
                confirmDialogResponseProps: {
                    type: eConfirmDialogResponseType.ERROR,
                    sentence: <span>Cannot {is_new_record ? 'create' : 'update'} template.<br/>Please try again.</span>,
                    onClose: () => this.setState({confirmDialogResponseProps: null})
                }
            });

            return false;
        }

        onSubmit?.(response, is_new_record);

        this.setState({
            confirmDialogResponseProps: {
                type: eConfirmDialogResponseType.SUCCESS,
                sentence: `Template ${is_new_record ? 'created' : 'updated'}.`,
                onClose
            }
        });
    }
}

TemplateDetails.propTypes = {
    onSubmit: PropTypes.func,
    onClose: PropTypes.func
};

TemplateDetails.defaultProps = {
    onSubmit: null,
    onClose: null
};

export default TemplateDetails;