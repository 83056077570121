import React from "react";
import * as PropTypes from 'prop-types';
import LabelValueTile from "../../LabelValueTile";

import "./style.scss";
import {eJobStageTypes, eUserTypes, objectToArray} from "../../../utils";
import EvaluatorsThumbnails from "../../EvaluatorsThumbnails";

class JobStageHeader extends React.Component {
    render() {
        const {
            view_type,
            job_stage_type,
            job_stage_menu,

            invited_count,
            received_count,
            evaluated_by_me_count,
            evaluated_count,
            declined_count,
            accepted_count,
            deadline_value,
            evaluators,
            interview_terminology
        } = this.props;

        const isManagerView = view_type === eUserTypes.MANAGER;

        return (
            <div className={`job-stage-header`}>
                <div className={`job-stage-title`}>
                    {
                        job_stage_type === eJobStageTypes.LIVE ?
                            `Live ${interview_terminology}s` :
                            `One-Way ${interview_terminology}s`
                    }
                </div>
                {isManagerView && job_stage_menu}

                {job_stage_type === eJobStageTypes.ONE_WAY && (
                    <div className={`job-stage-details`}>
                        <div className="candidates">
                            <div className="candidates-label">Candidates</div>
                            <div className={`tiles`}>
                                {isManagerView && (
                                    <LabelValueTile classes="invited" subLabel="Invited" value={invited_count} />
                                )}
                                <LabelValueTile classes="received" subLabel="Received" value={received_count} />
                                <LabelValueTile classes="evaluated" subLabel="Evaluated" value={isManagerView ? evaluated_count : evaluated_by_me_count} />
                                {isManagerView && (
                                    <React.Fragment>
                                        <LabelValueTile classes="declined" subLabel="Declined" value={declined_count} />
                                        <LabelValueTile classes="accepted" subLabel="Accepted" value={accepted_count} />
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                        <LabelValueTile classes="deadline" label="Deadline" value={deadline_value} />
                        <EvaluatorsThumbnails evaluators={evaluators} />
                    </div>
                )}
            </div>
        );
    }
}

JobStageHeader.propTypes = {
    view_type: PropTypes.oneOf(objectToArray(eUserTypes)),
    job_stage_type: PropTypes.oneOf(objectToArray(eJobStageTypes)),
    job_stage_menu: PropTypes.object,

    invited_count: PropTypes.number,
    received_count: PropTypes.number,
    evaluated_by_me_count: PropTypes.number,
    evaluated_count: PropTypes.number,
    declined_count: PropTypes.number,
    accepted_count: PropTypes.number,
    deadline_value: PropTypes.string,
    evaluators: PropTypes.arrayOf(PropTypes.object),
    interview_terminology: PropTypes.string
};

JobStageHeader.defaultProps = {
    view_type: eUserTypes.EVALUATOR,
    job_stage_type: eJobStageTypes.ONE_WAY
}

export default JobStageHeader;
