import React from "react";
import * as PropTypes from "prop-types";

import './style.scss';
import StaffSelection from "../StaffSelection";


class EvaluatorSelection extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.onStaffSelected = this.onStaffSelected.bind(this);
        this.addSelectedEvaluator = this.addSelectedEvaluator.bind(this);

        this.state = {
            selectedEvaluatorId: null
        };
    }

    onStaffSelected(e) {
        const { target : { value } } = e;
        this.setState({
            selectedEvaluatorId: value
        });
    }

    addSelectedEvaluator() {
        const { selectedEvaluatorId } = this.state;
        if (!!(selectedEvaluatorId.toString().length)) {
            const { onAdd } = this.props;
            onAdd(selectedEvaluatorId);
            this.setState({
                selectedEvaluatorId: null
            });
        }
    }

    handleChange(event) {
        this.setState({
            selectedEvaluatorId: event.target.value
        });
    }

    render() {
        const { evaluators, staffFilter, header = `Evaluators`, placeholder = `Select Evaluator` } = this.props;
        const { selectedEvaluatorId } = this.state;

        return (
            <div className="evaluator-selection">
                {evaluators && !!(evaluators.length) && (
                    <React.Fragment>
                        <StaffSelection
                            header={header}
                            placeholder={placeholder}
                            staffRecords={evaluators}
                            staffFilter={staffFilter}
                            selectedStaffId={selectedEvaluatorId}
                            onInputChange={this.onStaffSelected}
                        />
                        <button className={`react-button add-button ${selectedEvaluatorId ? 'active ripple' : 'disabled'}`}
                                onClick={() => selectedEvaluatorId && this.addSelectedEvaluator()}>
                            Add
                        </button>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

EvaluatorSelection.propTypes = {
    evaluators: PropTypes.array,
    staffFilter: PropTypes.func,
    onAdd: PropTypes.func
};

EvaluatorSelection.defaultProps = {
    evaluators: [],
    staffFilter: null,
    onAdd: null
};

export default EvaluatorSelection;
