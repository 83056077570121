import React from "react";
import * as PropTypes from "prop-types";

import Ratings, {eRatingsType} from "../../Ratings";

import "./style.scss";


const AttributesScoring = ({ items, onChange }) => (
    <div className={`attributes-scoring`}>
        <div className={`attributes-scoring-title`}>Final Assessment*</div>
        <div className={`attributes-scoring-section`}>
            <div className={`attributes-table`}>
                {items?.map(({attribute, score}) => (
                    <div key={`evaluator-attributes-row-${attribute}`} className={`attributes-row`}>
                        <div className={`header`}>{attribute}</div>
                        <div className={`attributes-item`}>
                            <Ratings
                                id={attribute.toLowerCase().replace(/ /g, '-')}
                                type={eRatingsType.NUMERIC}
                                classes={`attribute-rating`}
                                active={!!onChange}
                                score={score}
                                maximumScore={10}
                                showTooltip={false}
                                onChange={(e, value) => onChange(e, attribute, value)} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
);

AttributesScoring.propTypes = {
    items: PropTypes.array,
    onChange: PropTypes.func
};

AttributesScoring.defaultProps = {
    items: [],
    onChange: null
};

export default AttributesScoring;