import React from 'react';
import * as PropTypes from "prop-types";
import { orderBy } from 'lodash';
import { getJobsForEvaluator } from '../../api';
import Container from '../Container';
import JobTile from '../JobTile';

import './style.scss';


class Assess extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            jobs: []
        };
    }

    componentDidMount() {
        getJobsForEvaluator(this.props.staff_id)
            .then(response => {
               this.setState({
                   loading: false,
                   jobs: orderBy(response, ['deadline', 'id'], ['desc', 'asc'])
               });
            });
    }

    render() {
        const { loading, jobs } = this.state;

        return (
            <Container loading={loading}>
                <div className={`assess`}>
                    {!jobs && (
                        <div>You don’t have any evaluations assigned to you right now.</div>
                    )}
                    {jobs && (
                        <div className="job-tiles">
                            {jobs.map(job => (
                                <JobTile
                                    key={job.job_id}
                                    job={{
                                        ...job,
                                        received_count: job.received,
                                        evaluated_count: job.evaluated
                                    }}
                                    type="active"
                                    viewType="evaluator"
                                    urlFor={this.props.urlFor}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </Container>
        );
    }
}

Assess.propTypes = {
    staff_id: PropTypes.number.isRequired,
    urlFor: PropTypes.func.isRequired
};

export default Assess;
