import React from "react";
import * as PropTypes from "prop-types";
import { generateTemporaryId, objectToArray } from "../../../utils";

import './style.scss';


const eCustomTextFieldType = {
    TEXT: "text",
    PASSWORD: "password"
};

const CustomTextField = ({
    id = generateTemporaryId(),
    type,
    inputRef,
    classes,
    style,
    required = false,
    disabled,
    label = "",
    value = "",
    maxLength,
    isWarning,
    isError,
    helperText,
    placeholder,
    autoComplete,
    onKeyDown,
    onChange,
    onBlur,
    autoFocus = false,
    onFocus
}) => {
    return (
        <div className={`customTextField ${classes || ''} ${isWarning ? 'warning' : ''} ${isError ? 'error' : ''}`} style={style || ''}>
            <div className="input-field">
                <input
                    ref={inputRef}
                    type={type}
                    id={id}
                    className={`${classes} ${!value ? 'blank' : ''}`}
                    value={value}
                    maxLength={maxLength}
                    disabled={disabled}
                    placeholder={placeholder}
                    autoComplete={autoComplete}
                    onFocus={onFocus}
                    required={required}
                    onKeyDown={onKeyDown}
                    onChange={onChange}
                    onBlur={onBlur}
                    autoFocus={autoFocus}
                />
                <label className={`active`} htmlFor={id}>{label}{label && required ? '*' : ''}</label>
                {helperText && (
                    <span className={`helper-text`}>{helperText}</span>
                )}
            </div>
        </div>
    );
};

CustomTextField.propTypes = {
    type: PropTypes.oneOf(objectToArray(eCustomTextFieldType)),
    inputRef: PropTypes.object,
    id: PropTypes.string,
    classes: PropTypes.string,
    style: PropTypes.object,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    isError: PropTypes.bool,
    placeholder: PropTypes.string,
    autoComplete: PropTypes.string,
    helperText: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    onKeyDown: PropTypes.func,
    onChange: PropTypes.func,
    onBlur: PropTypes.func
};

CustomTextField.defaultProps = {
    type: eCustomTextFieldType.TEXT,
    inputRef: null,
    id: null,
    classes: "",
    style: {},
    required: false,
    disabled: false,
    isError: false,
    placeholder: "",
    autoComplete: "off",
    helperText: "",
    label: "",
    value: "",
    onKeyDown: null,
    onChange: null,
    onBlur: null
};

export default CustomTextField;
