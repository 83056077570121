import React from "react";
import PropTypes from 'prop-types';
import {
    eInterviewStepType,
    eTemplatesType,
    formatDateTime
} from "../../../utils";
import Container from "../../../components/Container";
import ActionDropdown from "../../../components/ActionDropdown";
import InterviewTemplateDetails from "./details";
import {IconPlus, IconTickThin} from "../../../components/Icons";
import Templates from "../../../components/Templates";

import './style.scss';


export class InterviewTemplateRow extends React.Component {
    constructor(props) {
        super(props);

        this.getActionItems = this.getActionItems.bind(this);
    }

    getActionItems(interviewTemplateId) {
        const { onEdit, onDelete } = this.props;
        const actionItems = [];

        if (!!onEdit) {
            actionItems.push({
                id: `edit-${interviewTemplateId}`,
                firstLine: "Edit",
                // disabled: status === eJobsType.ARCHIVED,
                onClick: onEdit
            });
        }

        if (!!onDelete) {
            actionItems.push({
                id: "delete",
                firstLine: "Delete",
                // disabled: status === eJobsType.ARCHIVED,
                onClick: onDelete
            });
        }

        return actionItems;
    }

    render() {
        const { interview = {}, isSelected, onSelect } = this.props;
        const {
            interviewTemplateId,
            interviewTemplateName,
            interviewTerminology,
            createdAt = null,
            createdBy,
            interviewSteps,
            usedByJobDetailIds
        } = interview;

        const { firstName = "", lastName = "" } = createdBy || {};

        const question_count = interviewSteps?.filter(({interview_step_type}) => interview_step_type === eInterviewStepType.QUESTION).length;
        const created_by = (firstName + " " + lastName).trim();

        return (
            <div className={`interview-template-row`}>
                <div className={`template-name`}>{interviewTemplateName}</div>
                <div className={`terminology`}>{interviewTerminology}</div>
                <div className={`created-at`}>
                    {!!createdAt ? formatDateTime(createdAt) : '-'}
                </div>
                <div className={`created-by`}>{created_by ? <React.Fragment><span className={`by`}>By </span><span className={`name`}>{created_by}</span></React.Fragment> : '-'}</div>
                <div className={`jobs-associated`}>{(usedByJobDetailIds || []).length || '-'}</div>
                <div className={`questions`}>{question_count}</div>
                <div className={`interview-template-actions`}>
                    {!!onSelect && (
                        <div className={`react-button ${isSelected ? 'selected outline' : ''} active`} onClick={!isSelected ? onSelect : null}>{isSelected ? IconTickThin : 'Select'}</div>
                    )}
                    {!onSelect && (
                        <ActionDropdown items={this.getActionItems(interviewTemplateId)} hasBorder={true} />
                    )}
                </div>
            </div>
        );
    }
}

InterviewTemplateRow.propTypes = {
    interview: PropTypes.object,
    isSelected: PropTypes.bool,
    onEdit: PropTypes.func,
    onSelect: PropTypes.func
};

class InterviewTemplates extends Templates {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            items: [],
            library: [],
            selectedTemplate: null,
            confirmDialogResponseProps: null
        };
    }

    async componentDidMount() {
        await this.retrieveTemplates(eTemplatesType.INTERVIEW);
    }

    render() {
        const {
            loading,
            confirmDialogResponseProps,
            items,
            selectedTemplate
        } = this.state;

        const { hasNavigationMenu } = this.props;

        return (
            <Container
                loading={loading}
                confirmDialogResponseProps={confirmDialogResponseProps}
            >
                {!!items.length && (
                    <div className={`interview-templates ${hasNavigationMenu ? 'has-navigation-menu' : ''}`}>
                        <div className={`interview-templates-header header-row`}>
                            <div className={`header-column name`}>Name</div>
                            <div className={`header-column terminology`}>Terminology</div>
                            <div className={`header-column created-at`}>Date Created</div>
                            <div className={`header-column created-by`}>Created By</div>
                            <div className={`header-column jobs-associated`}># of Jobs Associated</div>
                            <div className={`header-column questions`}># of Questions</div>
                            <div className={'header-column action'}>
                                <div className={`new-button`} onClick={() => this.onNew(eTemplatesType.INTERVIEW)}>{IconPlus}</div>
                            </div>
                        </div>
                        {items.map((interview) => {
                            return (
                                <InterviewTemplateRow
                                    key={`interview-template-row-${interview.interviewTemplateId}`}
                                    interview={interview}
                                    onEdit={() => this.toggleTemplateDetails(interview)}
                                    onDelete={() => this.deleteTemplateConfirmation(eTemplatesType.INTERVIEW, interview)}
                                />
                            );
                        })}
                    </div>
                )}

                {!items.length && (
                    <div className={`no-items`}>
                        There are no interview templates.<br /><br />
                        <div className={`react-button create-button`} onClick={() => this.onNew(eTemplatesType.INTERVIEW)}>
                            Create new
                        </div>
                    </div>
                )}

                {!!selectedTemplate && (
                    <InterviewTemplateDetails
                        templates={items}
                        template={selectedTemplate}
                        showInModal={true}
                        onSubmit={(response, is_new_record) => this.onTemplateSubmit(eTemplatesType.INTERVIEW, response, is_new_record)}
                        onClose={() => this.toggleTemplateDetails(null)}
                    />
                )}
            </Container>
        );
    }
}

InterviewTemplates.propTypes = {
    hasNavigationMenu: PropTypes.bool
};

InterviewTemplates.defaultProps = {
    hasNavigationMenu: true
};

export default InterviewTemplates;

