import React from "react";
import * as PropTypes from "prop-types";
import CustomTextField from "../../Form/CustomTextField";
import {
    convertDateToCommonFormat,
    convertDayToCommonFormat,
    eDeadlineDay,
    eDropdownTypes,
    generateTemporaryId,
    getLaterDate,
    isUpcomingDate,
    isValidEmail
} from "../../../utils";
import CustomDatePicker from "../../Pickers/CustomDatePicker";
import TypedDropdown from "../../TypedDropdown";

import './style.scss';


class CandidateAdd extends React.PureComponent {
    constructor(props) {
        super(props);

        this.clearCandidateForm = this.clearCandidateForm.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.isDeadlineError = this.isDeadlineError.bind(this);
        this.canCandidateBeAdded = this.canCandidateBeAdded.bind(this);
        this.addCandidate = this.addCandidate.bind(this);

        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            deadline: null,
            deadline_timezone: null,
            isEmailError: false,
            emailErrorText: "",
            isEmailDuplicate: false,
            emailDuplicateText: "",
            timestamp: generateTemporaryId()
        };
    }

    clearCandidateForm() {
        this.setState({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            isEmailError: false,
            emailErrorText: "",
            isEmailDuplicate: false,
            emailDuplicateText: "",
            timestamp: generateTemporaryId()
        });
    }

    onFormChange(e) {
        const { target: { id, value } } = e;

        const state = {
            [id]: value
        };

        this.setState(state, () => {
            if (id === "email") {
                // if error, we'll resolve as soon as possible
                const { isEmailError, isEmailDuplicate } = this.state;
                if (isEmailError || isEmailDuplicate) {
                    this.validateEmail();
                }
            }
        });
    }

    validateEmail() {
        const { beforeAdd } = this.props;
        const { email } = this.state;

        const candidate = { email };

        const isEmailValid = isValidEmail(email);
        this.setState({
            isEmailError: !isEmailValid,
            emailErrorText: !isEmailValid ? "Please enter a valid email address." : null
        });

        const isCandidateValid = beforeAdd(candidate);

        if (!isCandidateValid) {
            this.setState({
                isEmailDuplicate: true,
                emailDuplicateText: "This email has previously been added to this job."
            });
        } else {
            this.setState({
                isEmailDuplicate: false,
                emailDuplicateText: ""
            });
        }
    }

    isDeadlineError() {
        const { deadline } = this.state;
        const { deadline: jobDeadline } = this.props;

        return deadline === null ? false : new Date(deadline) < new Date(jobDeadline);
    }

    canCandidateBeAdded() {
        const { firstName, email } = this.state;
        return firstName !== ""
            && isValidEmail(email)
            // TASK: maybe need to check deadline based on deadline_timezone too?
            && !this.isDeadlineError();
    }

    addCandidate() {
        const { onAdd } = this.props;
        const { firstName, lastName, email, phone, deadline, deadline_timezone } = this.state;

        const candidate = {
            candidate_id: generateTemporaryId(),
            first_name: firstName,
            last_name: lastName,
            email,
            phone,
            deadline,
            deadline_timezone
        };

        !!onAdd && onAdd(candidate);

        this.clearCandidateForm();
    }

    componentDidMount() {
        const { deadline: jobDeadline, deadlineTimezone } = this.props;

        this.setState({
            deadline: isUpcomingDate(jobDeadline) ? jobDeadline : null,
            deadline_timezone: deadlineTimezone
        });
    }

    render() {
        const { classes, isDisabled, deadline: jobDeadline, showCandidateDeadlineTimezone, deadlineTimezone } = this.props;
        const { firstName, lastName, email, phone, deadline, isEmailError, emailErrorText, isEmailDuplicate, emailDuplicateText, timestamp } = this.state;

        const minimumDeadline = getLaterDate(jobDeadline, convertDayToCommonFormat(eDeadlineDay.YESTERDAY));

        let emailHelperText = null;
        if (isEmailError) {
            emailHelperText = emailErrorText;
        } else if (isEmailDuplicate) {
            emailHelperText = emailDuplicateText;
        }

        return (
            <div className={`candidate-add ${classes} has-candidate-deadline-timezone-${showCandidateDeadlineTimezone}`}>
                <CustomTextField
                    classes="first-name"
                    id="firstName"
                    required={true}
                    disabled={isDisabled}
                    label="First Name"
                    value={firstName}
                    onChange={this.onFormChange}
                />
                <CustomTextField
                    classes="last-name"
                    id="lastName"
                    disabled={isDisabled}
                    label="Last Name"
                    value={lastName}
                    onChange={this.onFormChange}
                />
                <CustomTextField
                    classes={`email`}
                    id="email"
                    required={true}
                    disabled={isDisabled}
                    label="Email"
                    value={email}
                    isError={isEmailError}
                    isWarning={isEmailDuplicate}
                    helperText={emailHelperText}
                    onChange={this.onFormChange}
                    onBlur={this.validateEmail}
                />
                <CustomTextField
                    classes="phone"
                    id="phone"
                    required={false}
                    disabled={isDisabled}
                    label="Phone"
                    value={phone}
                    onChange={this.onFormChange}
                />
                <CustomDatePicker
                    id="deadline"
                    classes={`deadline`}
                    required={false}
                    disabled={isDisabled}
                    label="Deadline"
                    value={deadline}
                    isError={this.isDeadlineError()}
                    minDate={minimumDeadline}
                    minMessage="Please select a valid deadline."
                    onChange={(e) => this.onFormChange({
                        target: {
                            id: "deadline",
                            value: convertDateToCommonFormat(e)
                        }
                    })}
                />
                {showCandidateDeadlineTimezone && (
                    <TypedDropdown
                        key={`deadline-timezone-${timestamp}`}
                        id={"deadline_timezone"}
                        classes={'deadline-timezone'}
                        label={`Timezone`}
                        dropdownType={eDropdownTypes.TIMEZONES}
                        initialValue={deadlineTimezone}
                        required={false}
                        isMultipleSelection={false}
                        showDropdownOnInitialFocus={true}
                        onUpdate={this.onFormChange}
                    />
                )}
                <button
                    className={`add-button react-button ${this.canCandidateBeAdded() ? 'active ripple' : 'disabled'}`}
                    onClick={() => this.canCandidateBeAdded() && this.addCandidate()}
                >
                    Add
                </button>
            </div>
        );
    }
}

CandidateAdd.propTypes = {
    classes: PropTypes.string,
    isDisabled: PropTypes.bool,
    deadline: PropTypes.string,
    beforeAdd: PropTypes.func,
    onAdd: PropTypes.func
};

CandidateAdd.defaultProps = {
    classes: "",
    isDisabled: false,
    deadline: "",
    beforeAdd: null,
    onAdd: null
};

export default CandidateAdd;
