import React from "react";
import * as PropTypes from "prop-types";
import {convertDateToCommonFormat} from "../../../utils";
import * as moment from "moment";
import CustomDatePicker from "../../Pickers/CustomDatePicker";
import CustomTextField from "../../Form/CustomTextField";
import { getWorldManagerJob } from "../../../api/";
import Loader from "../../Loader";

import './style.scss';


class CandidatesImport extends React.Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            fromDate: null,
            toDate: null,
            positionGroupId: "",
            isLoading: false
        };
    }

    onChange(e) {
        const { target: { id, value} } = e;
        this.setState({
            [id]: value
        });
    }

    onSubmit() {
        const { jobId, onRefresh, onClose } = this.props;
        const { fromDate, toDate, positionGroupId } = this.state;
        const formattedFromDate = moment(fromDate).format("YYYY-MM-DD");
        const formattedToDate = moment(toDate).format("YYYY-MM-DD");

        getWorldManagerJob(jobId, formattedFromDate, formattedToDate, positionGroupId).then((response) => {
            onRefresh?.(response);
            onClose?.();
        });
    }

    render() {
        const { fromDate, toDate, positionGroupId, isLoading } = this.state;

        return (
            <div className={`candidates-import`}>
                <div className={`candidates-import-form`}>
                    <CustomDatePicker
                        id={`fromDate`}
                        required={true}
                        label={`From`}
                        value={fromDate}
                        onChange={(e) => this.onChange({
                            target: {
                                id: `fromDate`,
                                value: convertDateToCommonFormat(e)
                            }
                        })}
                    />
                    <CustomDatePicker
                        id={`toDate`}
                        required={true}
                        label={`To`}
                        value={toDate}
                        onChange={(e) => this.onChange({
                            target: {
                                id: `toDate`,
                                value: convertDateToCommonFormat(e)
                            }
                        })}
                    />
                    <CustomTextField
                        id={`positionGroupId`}
                        required={true}
                        label={`Position Group ID`}
                        value={positionGroupId}
                        onChange={this.onChange}
                    />
                    <button
                        className={`submit-button react-button info ${isLoading ? 'is-loading' : ''}`}
                        onClick={this.onSubmit}
                    >
                        {isLoading ? <Loader /> : <span>Import</span>}
                    </button>
                </div>
            </div>
        );
    }
}

CandidatesImport.propTypes = {
    jobId: PropTypes.number,
    onRefresh: PropTypes.func,
    onClose: PropTypes.func
};

CandidatesImport.defaultProps = {
    jobId: null,
    onRefresh: null,
    onClose: null
};

export default CandidatesImport;
