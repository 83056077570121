import React from "react";
import * as PropTypes from "prop-types";

import CustomToggleField, { eToggleSize } from "../../Form/CustomToggleField";

import {
    eLibraryAssetType,
    eLibraryQuestionType,
    JOB_STAGES_FIELDS
} from "../../../utils";
import {getCurrentJobStage, isJobStageUsingInterviewTemplate} from "../../../utils/job";
import EvaluationAttributes from "../../EvaluationAttributes";
import InterviewTemplate from "../../InterviewTemplate";
import {getInterviewTemplates} from "../../../api";
import InterviewTemplateSelection from "./InterviewTemplateSelection";

import './style.scss';


class StepQuestions extends React.Component {
    constructor(props) {
        super(props);

        this.getInterviewTemplateName = this.getInterviewTemplateName.bind(this);
        this.getJobInterviewSteps = this.getJobInterviewSteps.bind(this);
        this.toggleTemplateSelection = this.toggleTemplateSelection.bind(this);
        this.isItemSelected = this.isItemSelected.bind(this);

        this.state = {
            interviewTemplateItems: false,
            isTemplateSelectionShown: false,
            selectionType: null,
            preInterviewQuestion: null
        };
    }

    getInterviewTemplateName() {
        const { job, currentJobStageId } = this.props;

        const { interview_template_is_reusable, interview_template_name } = getCurrentJobStage(job, currentJobStageId);
        return interview_template_is_reusable ? interview_template_name : null;
    }

    getJobInterviewSteps() {
        const { job = {}, currentJobStageId } = this.props;
        const { job_interview_steps = [] } = getCurrentJobStage(job, currentJobStageId);
        return job_interview_steps;
    }

    toggleTemplateSelection() {
        this.setState({
            isTemplateSelectionShown: !this.state.isTemplateSelectionShown
        });
    }

    isItemSelected(questionType, questionId) {
        const job_interview_steps = this.getJobInterviewSteps();
        return !!(job_interview_steps.find((job_interview_step) => {
            const { job_interview_step_id, library_asset_type } = job_interview_step;
            if (!library_asset_type && !!job_interview_step_id) {
                // old data way
                return job_interview_step_id === questionId;
            } else {
                // new data way
                const {
                    stock_library_question_id,
                    tenant_library_question_id
                } = job_interview_step;

                if (library_asset_type !== eLibraryAssetType.QUESTION) {
                    return false;
                }

                return questionType === eLibraryQuestionType.STOCK ?
                    stock_library_question_id === questionId :
                    tenant_library_question_id === questionId;
            }
        }));
    }

    async componentDidMount() {
        const interviewTemplateItems = await getInterviewTemplates();
        this.setState({
            interviewTemplateItems
        });
    }

    render() {
        const { interviewTemplateItems, isTemplateSelectionShown } = this.state;
        const { classes, job, areInterviewStepsReadOnly, areAttributesReadOnly, currentJobStageId, onInputChange, onTemplateSelection, integrationProvider } = this.props;
        const {
            interview_template_id, interview_terminology, job_interview_steps, requires_response_evaluation, ask_for_feedback,
            evaluation_attributes_enabled, evaluation_attributes_display
        } = getCurrentJobStage(job, currentJobStageId);

        const isUsingInterviewTemplate = isJobStageUsingInterviewTemplate(job, currentJobStageId);

        return (
            <React.Fragment>
                <div className={`step-questions ${classes} ${areInterviewStepsReadOnly ? 'read-only' : ''}`}>
                    {!!interviewTemplateItems.length && !areInterviewStepsReadOnly && (
                        <div className={`template-selection`}>
                            <div
                                className={`react-button interview-template-selection-button`}
                                onClick={this.toggleTemplateSelection}
                            >
                                Select Template
                            </div>
                        </div>
                    )}
                    <InterviewTemplate
                        is_in_job_context={true}
                        interview_template_name={isUsingInterviewTemplate ? this.getInterviewTemplateName() : ''}
                        interview_terminology={interview_terminology}
                        job_interview_steps={job_interview_steps}
                        isReadOnly={areInterviewStepsReadOnly}
                        onChange={onInputChange}
                        integrationProvider={integrationProvider}
                    />
                    <div className="assessment">
                        <div className={`section-header`}>Interview</div>
                        <CustomToggleField
                            classes="ask-for-feedback"
                            id="ask_for_feedback"
                            label="Candidate Feedback"
                            size={eToggleSize.X_SMALL}
                            checked={ask_for_feedback}
                            isDisabled={areAttributesReadOnly}
                            onChange={areAttributesReadOnly ? null : onInputChange}
                        />
                        <div className="section-header">Assessment</div>
                        <CustomToggleField
                            classes="star-rating"
                            id="requires_response_evaluation"
                            label="Star Rating"
                            size={eToggleSize.X_SMALL}
                            checked={requires_response_evaluation}
                            isDisabled={areAttributesReadOnly}
                            onChange={areAttributesReadOnly ? null : onInputChange}
                        />
                        <CustomToggleField
                            classes="attribute-evaluation"
                            id={JOB_STAGES_FIELDS.EVALUATION_ATTRIBUTES_ENABLED}
                            label="Attributes"
                            size={eToggleSize.X_SMALL}
                            checked={evaluation_attributes_enabled}
                            isDisabled={areAttributesReadOnly}
                            onChange={areAttributesReadOnly ? null : onInputChange}
                        />
                        {evaluation_attributes_enabled && (
                            <EvaluationAttributes
                                id={JOB_STAGES_FIELDS.EVALUATION_ATTRIBUTES_DISPLAY}
                                items={evaluation_attributes_display}
                                isEditable={!areAttributesReadOnly && !!evaluation_attributes_enabled}
                                onSave={onInputChange}
                            />
                        )}
                    </div>
                </div>
                {isTemplateSelectionShown && (
                    <InterviewTemplateSelection
                        items={interviewTemplateItems}
                        selectedInterviewTemplateId={isUsingInterviewTemplate ? interview_template_id : null}
                        onSelect={(interview) => {onTemplateSelection(interview); this.toggleTemplateSelection();}}
                        onClose={this.toggleTemplateSelection}
                    />
                )}
            </React.Fragment>
        );
    }
}

StepQuestions.propTypes = {
    classes: PropTypes.string,
    job: PropTypes.object,
    areInterviewStepsReadOnly: PropTypes.bool,
    areAttributesReadOnly: PropTypes.bool,
    currentJobStageId: PropTypes.number,
    onInputChange: PropTypes.func,
    onTemplateSelection: PropTypes.func,
    integrationProvider: PropTypes.string
};

StepQuestions.defaultProps = {
    classes: "",
    job: {},
    areInterviewStepsReadOnly: false,
    areAttributesReadOnly: false,
    currentJobStageId: null,
    onInputChange: null,
    onTemplateSelection: null,
    integrationProvider: ""
};

export default StepQuestions;
