import React from "react";
import * as PropTypes from "prop-types";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {
    eInterviewStepType,
    eInterviewTerminologies,
    eLibraryAssetType,
    eLibraryQuestionType,
    eQuestionContainerTypes,
    eQuestionDisplayType,
    eResponseType,
    getPageFeatures,
    JOB_STAGES_FIELDS,
    objectToArray
} from "../../utils";
import {QuestionItem} from "../JobDraft/StepQuestions/QuestionItem";
import {
    getPracticeQuestionIndex,
    getPreInterviewQuestions,
    getInterviewQuestions,
    setLibraryItemDefaultVideoSelection,
    updateLibraryItemVideoSelection,
    addLibraryItemToLibraryArray
} from "../../utils/job";
import Loader from "../Loader";
import InterviewItemsSelection from "../JobDraft/StepQuestions/InterviewItemsSelection";
import CustomModal from "../CustomModal";
import {getLibrary, getInterviewTemplate} from "../../api";
import {IconChain} from "../Icons";

import './style.scss';


class List extends React.Component {
    render() {
        const { provided, innerRef, classes, children } = this.props;
        return (
            <div {...provided.droppableProps} ref={innerRef} className={`list ${classes}`}>
                {children}
            </div>
        );
    }
}

class InterviewTemplate extends React.Component {
    constructor(props) {
        super(props);

        this.onDragEnd = this.onDragEnd.bind(this);

        this.showItemSelection = this.showItemSelection.bind(this);
        this.hideItemSelection = this.hideItemSelection.bind(this);

        this.onVideoSelectionChange = this.onVideoSelectionChange.bind(this);
        this.onLibraryUpdate = this.onLibraryUpdate.bind(this);

        this.onItemChange = this.onItemChange.bind(this);
        this.onItemSelect = this.onItemSelect.bind(this);
        this.onItemDeselect = this.onItemDeselect.bind(this);
        this.onVideoSelect = this.onVideoSelect.bind(this);
        
        this.isItemMatching = this.isItemMatching.bind(this);

        this.state = {
            library: null,
            isSelecting: false,
            selectedPreInterviewQuestion: null
        };
    }

    onDragEnd = result => {
        const { source, destination, draggableId } = result;
        const { library } = this.state;
        const { job_interview_steps, onChange } = this.props;
        if (!destination) {
            // no destination
            return;
        }

        if (destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            // not moving
            return;
        }

        const sourceArea = source.droppableId;

        // get item in question
        const [id, assetType, questionType, questionId]  = draggableId.split("-");
        const list = (sourceArea === "library" ? library : job_interview_steps);
        const item = list.find((item) => {
            const {
                job_interview_step_id,
                library_asset_type,
                stock_library_question_id,
                tenant_library_question_id,
                tenant_library_message_id
            } = item;
            const questionIdAsNumber = parseInt(questionId);
            // newly added item will not have job_interview_step_id
            return (!!id ? job_interview_step_id === parseInt(id) : true) &&
                library_asset_type === assetType && (
                    library_asset_type === eLibraryAssetType.MESSAGE ?
                        tenant_library_message_id === questionIdAsNumber :
                        questionType === eLibraryQuestionType.STOCK ?
                            stock_library_question_id === questionIdAsNumber :
                            tenant_library_question_id === questionIdAsNumber
                );
        });

        const practiceQuestionIndex = getPracticeQuestionIndex(job_interview_steps);
        const updatedInterviewQuestions = [...job_interview_steps];
        const sourceIndex = source.index +
            (sourceArea === eQuestionContainerTypes.INTERVIEW ?
                    practiceQuestionIndex + 1 : 0
            );

        updatedInterviewQuestions.splice(sourceIndex, 1);

        const destinationArea = destination.droppableId;
        const destinationIndex = destination.index +
            (destinationArea === eQuestionContainerTypes.INTERVIEW ?
                    practiceQuestionIndex + (sourceArea === eQuestionContainerTypes.PRE_INTERVIEW ? 0 : 1) :
                    0
            );

        updatedInterviewQuestions.splice(destinationIndex, 0, item);

        // update state
        onChange({
            target: {
                id: JOB_STAGES_FIELDS.JOB_INTERVIEW_STEPS,
                value: updatedInterviewQuestions
            }
        })
    };

    showItemSelection(type = eQuestionContainerTypes.INTERVIEW, question = null) {
        let libraryQuestionType;
        if (type === eQuestionContainerTypes.PRE_INTERVIEW && question) {
            const { job_interview_steps, onChange } = this.props;
            const { stock_library_question_id } = question;
            libraryQuestionType = !!stock_library_question_id ? eLibraryQuestionType.STOCK : eLibraryQuestionType.CUSTOM;

            // delete pre-interview question
            const updatedSteps = Array.from(job_interview_steps).filter(
                ({stock_library_question_id, tenant_library_question_id, tenant_library_message_id}) =>
                    !(
                        stock_library_question_id === question.stock_library_question_id &&
                        tenant_library_question_id === question.tenant_library_question_id &&
                        tenant_library_message_id === question.tenant_library_message_id
                    )
            );
            onChange({
                target: {
                    id: JOB_STAGES_FIELDS.JOB_INTERVIEW_STEPS,
                    value: updatedSteps
                }
            });
        }
        this.setState({
            isSelecting: true,
            selectionType: type,
            selectedPreInterviewQuestion: question,
            libraryQuestionType
        });
    }

    hideItemSelection() {
        const { selectedPreInterviewQuestion } = this.state;

        if (!!selectedPreInterviewQuestion) {
            const { job_interview_steps, onChange } = this.props;
            const preInterviewQuestions = getPreInterviewQuestions(job_interview_steps);

            if (!preInterviewQuestions.length) {
                // re-instate pre-interview question if there is none selected
                const updatedSteps = Array.from(job_interview_steps);
                const practiceQuestionIndex = Math.max(getPracticeQuestionIndex(job_interview_steps), 0);
                updatedSteps.splice(practiceQuestionIndex, 0, selectedPreInterviewQuestion);
                onChange({
                    target: {
                        id: JOB_STAGES_FIELDS.JOB_INTERVIEW_STEPS,
                        value: updatedSteps
                    }
                });
            } else {
                // make sure preparation time and answer time is maintained from previous practice question
                const updatedSteps = job_interview_steps.map((step) => {
                    if (step.interview_step_type === eInterviewStepType.PRACTICE) {
                        return {
                            ...step,
                            preparation_time: selectedPreInterviewQuestion.preparation_time,
                            answer_time: selectedPreInterviewQuestion.answer_time
                        };
                    } else {
                        return step;
                    }
                });
                onChange({
                    target: {
                        id: JOB_STAGES_FIELDS.JOB_INTERVIEW_STEPS,
                        value: updatedSteps
                    }
                });
            }
        }
        this.setState({
            isSelecting: false,
            selectionType: null,
            selectedPreInterviewQuestion: null
        });
    }

    onVideoSelectionChange(questionType, questionId, videoId) {
        const { library } = this.state;
        const updatedLibrary = updateLibraryItemVideoSelection(library, questionType, questionId, videoId);

        if (!!updatedLibrary) {
            this.setState({
                library: updatedLibrary
            });
        }
    }

    onLibraryUpdate(item, isUpdate = false) {
        const { library } = this.state;

        const updatedLibrary = addLibraryItemToLibraryArray(library, item, isUpdate);

        this.setState({
            library: updatedLibrary
        });

        // also update the relevant question in the template
        const { job_interview_steps, onChange } = this.props;
        const {
            library_asset_type,
            question_type,
            tenant_library_question_id,
            tenant_library_message_id,
            display_text,
            html_content,
            attachments
        } = item;

        const updatedSteps = Array.from(job_interview_steps).map((step) => {
            if (this.isItemMatching(step, library_asset_type, question_type, tenant_library_message_id || tenant_library_question_id)) {
                step.display_text = display_text;
                step.html_content = html_content;
                step.attachments = attachments;
            }
            return step;
        });

        onChange({
            target: {
                id: JOB_STAGES_FIELDS.JOB_INTERVIEW_STEPS,
                value: updatedSteps
            }
        })
    }

    onItemChange(libraryAssetType, libraryQuestionType, questionId, fieldName, value) {
        const { job_interview_steps, onChange } = this.props;

        const newSteps = Array.from(job_interview_steps).map((step) => {
            if (this.isItemMatching(step, libraryAssetType, libraryQuestionType, questionId)) {
                step[fieldName] = value;
            }
            return step;
        });

        onChange({
            target: {
                id: JOB_STAGES_FIELDS.JOB_INTERVIEW_STEPS,
                value: newSteps
            }
        })
    }

    onItemSelect(libraryAssetType, libraryQuestionType, questionId, selectionType = eQuestionContainerTypes.INTERVIEW, questionVideoId = null) {
        const { library } = this.state;
        const { job_interview_steps, onChange } = this.props;

        const newStep = library.find((item) => {
            return this.isItemMatching(item, libraryAssetType, libraryQuestionType, questionId);
        });

        if (newStep) {
            const newSteps = Array.from(job_interview_steps);

            if (selectionType === eQuestionContainerTypes.PRE_INTERVIEW) {
                newStep.interview_step_type = eInterviewStepType.PRACTICE;

                if (!!questionVideoId) {
                    if (libraryQuestionType === eLibraryQuestionType.STOCK) {
                        newStep.stock_library_question_video_id = questionVideoId;
                    } else if (libraryQuestionType === eLibraryQuestionType.CUSTOM) {
                        newStep.tenant_library_question_video_id = questionVideoId;
                    }
                }

                const practiceQuestionIndex = Math.max(getPracticeQuestionIndex(job_interview_steps), 0);
                newSteps.splice(practiceQuestionIndex, 0, newStep);
            } else {
                newStep.interview_step_type = libraryAssetType;
                if (newStep.response_type === eResponseType.VIDEO) {
                    newStep.number_of_attempts = newStep.number_of_attempts || 1;
                }
                newSteps.push({...newStep});
            }

            // update state
            onChange({
                target: {
                    id: JOB_STAGES_FIELDS.JOB_INTERVIEW_STEPS,
                    value: newSteps
                }
            });
        }
    }

    onItemDeselect(libraryAssetType, libraryQuestionType, questionId) {
        const { job_interview_steps, onChange } = this.props;

        const newSteps = Array.from(job_interview_steps).filter((step) => {
            return !this.isItemMatching(step, libraryAssetType, libraryQuestionType, questionId);
        });

        // update state
        onChange({
            target: {
                id: JOB_STAGES_FIELDS.JOB_INTERVIEW_STEPS,
                value: newSteps
            }
        })
    }

    onVideoSelect(questionType, questionId, videoId) {
        this.onVideoSelectionChange(questionType, questionId, videoId);
        const { job_interview_steps, onChange } = this.props;

        // if question is not yet selected, we'll update the selection in the library
        const question = [...job_interview_steps].find((job_interview_step) => {
            const {
                stock_library_question_id,
                tenant_library_question_id
            } = job_interview_step;
            return questionType === eLibraryQuestionType.STOCK ?
                stock_library_question_id === questionId :
                tenant_library_question_id === questionId;
        });

        if (!!question) {
            const newSteps = [...job_interview_steps].map((job_interview_step) => {
                if (job_interview_step === question) {
                    const newStep = {...job_interview_step};

                    const is_stock_library_question = !!job_interview_step.stock_library_question_id;

                    newStep.stock_library_question_video_id = is_stock_library_question ? videoId : null;
                    newStep.tenant_library_question_video_id = is_stock_library_question ? null : videoId;

                    // need to delete the old 'image' and 'video
                    newStep.image = null;
                    newStep.video = null;

                    const { library } = this.state;
                    const library_item = library.find((item) =>
                        item.question_type === "video" &&
                        (
                            questionType === eLibraryQuestionType.STOCK ?
                                item.stock_library_question_id === questionId :
                                item.tenant_library_question_id === questionId
                        )
                    );

                    newStep.videos = library_item.videos;

                    return newStep;
                } else {
                    return job_interview_step;
                }
            });

            onChange({
                target: {
                    id: JOB_STAGES_FIELDS.JOB_INTERVIEW_STEPS,
                    value: newSteps
                }
            });
        }
    }

    isItemMatching(item, libraryAssetType, libraryQuestionType, questionId) {
        const {
            stock_library_question_id,
            tenant_library_question_id,
            tenant_library_message_id
        } = item;

        return libraryAssetType === eLibraryAssetType.MESSAGE ?
            tenant_library_message_id === questionId :
            libraryQuestionType === eLibraryQuestionType.STOCK ?
                stock_library_question_id === questionId :
                tenant_library_question_id === questionId;
    }

    async componentDidMount() {
        const { library, interview_template_id, job_interview_steps, onChange } = this.props;

        const updatedState = {};

        if (!library) {
            const response = await getLibrary();
            updatedState.library = setLibraryItemDefaultVideoSelection(response, job_interview_steps);
        }

        this.setState(updatedState);

        if (interview_template_id) {
            const response = await getInterviewTemplate(interview_template_id);
            if (!response.error) {
                onChange({
                    target: {
                        id: JOB_STAGES_FIELDS.JOB_INTERVIEW_STEPS,
                        value: response.interviewSteps
                    }
                })
            }
        }
    }

    render() {
        const { library, isSelecting, selectedPreInterviewQuestion } = this.state;
        const { integrationProvider, is_in_job_context, interview_template_name, interview_terminology, job_interview_steps, isLive, isReadOnly } = this.props;

        const preInterviewQuestions = getPreInterviewQuestions(job_interview_steps);
        const preInterviewQuestion = (preInterviewQuestions || [])[0];
        const interviewQuestions = getInterviewQuestions(job_interview_steps);
        const areInterviewQuestionsSelected = !!interviewQuestions.length;

        const { selectLibraryQuestionCloseText } = getPageFeatures(integrationProvider);

        return (
            <React.Fragment>
                {!isReadOnly && (
                    <div className={`interview-template-edit-button`}>
                        <button
                            className={`react-button info ${!library ? 'disabled' : ''}`}
                            onClick={!!library ? this.showItemSelection : null}
                        >
                            {!library ? <Loader /> : "Add from Library"}
                        </button>
                    </div>
                )}
                <div className={`interview-template ${is_in_job_context ? 'is-in-job-context' : ''} ${interview_template_name ? 'using-interview-template' : ''}`}>
                    {is_in_job_context && (
                        <div className={`job-context`}>
                            <div className={`terminology`}>
                                <label>Terminology</label>
                                <div className={`value`}>{interview_terminology}</div>
                            </div>
                            {interview_template_name && (
                                <div className={`interview-template-name`}>
                                    {IconChain} <strong>{interview_template_name}</strong>
                                </div>
                            )}
                        </div>
                    )}
                    <DragDropContext
                        onDragEnd={this.onDragEnd}
                    >
                        {!isLive && (
                            <div className={`pre-interview-section`}>
                                <div className="interview-template-section-header">Pre-Interview</div>
                                <div className="pre-interview-questions">
                                    <Droppable droppableId={eQuestionContainerTypes.PRE_INTERVIEW} isDropDisabled={true} >
                                        {provided => (
                                            <List provided={provided} innerRef={provided.innerRef} classes="editing-list">
                                                {preInterviewQuestions.map((item, index) => {
                                                    const {
                                                        job_interview_step_id = '',
                                                        library_asset_type,
                                                        stock_library_question_id,
                                                        tenant_library_question_id,
                                                        tenant_library_message_id,
                                                        interview_step_type
                                                    } = item;
                                                    const questionId = library_asset_type + '-' +
                                                        (!!stock_library_question_id ?
                                                            `stock-${stock_library_question_id}` :
                                                            !!tenant_library_question_id ?
                                                                `custom-${tenant_library_question_id}` :
                                                                `custom-${tenant_library_message_id}`);
                                                    const isPracticeQuestion = interview_step_type === eInterviewStepType.PRACTICE;

                                                    const onSwap = library && isPracticeQuestion ?
                                                        () => this.showItemSelection(eQuestionContainerTypes.PRE_INTERVIEW, preInterviewQuestion) :
                                                        null;

                                                    return (
                                                        <Draggable key={`${job_interview_step_id}-${questionId}-draggable`} draggableId={`${job_interview_step_id}-${questionId}`} index={index} isDragDisabled={true}>
                                                            {(provided, snapshot) => (
                                                                <QuestionItem
                                                                    keyProp={`${job_interview_step_id}-${questionId}`}
                                                                    displayType={isReadOnly ? eQuestionDisplayType.VIEWING : eQuestionDisplayType.EDITING}
                                                                    provided={provided}
                                                                    snapshot={snapshot}
                                                                    innerRef={provided.innerRef}
                                                                    item={item}
                                                                    isDraggable={false}
                                                                    onSwap={onSwap}
                                                                    onDeselect={this.onItemDeselect}
                                                                    onChange={this.onItemChange}
                                                                />
                                                            )}
                                                        </Draggable>
                                                    );
                                                })}
                                                {provided.placeholder}
                                            </List>
                                        )}
                                    </Droppable>
                                </div>
                            </div>
                        )}

                        <div className="interview-section">
                            <div className="interview-template-section-header">Interview</div>

                            {!areInterviewQuestionsSelected && (
                                <div className={'add-item-container'}>
                                        <span
                                            className={`add-item-message ${!library ? 'disabled' : ''}`}
                                            onClick={!!library ? this.showItemSelection : null}
                                        >
                                            {!library ? <Loader /> : "Add Messages and Interview Questions from the Library"}

                                        </span>
                                </div>
                            )}

                            {areInterviewQuestionsSelected && (
                                <React.Fragment>
                                    <div className="interview-questions">
                                        <Droppable droppableId={eQuestionContainerTypes.INTERVIEW}>
                                            {provided => (
                                                <List provided={provided} innerRef={provided.innerRef} classes="editing-list">
                                                    {interviewQuestions.map((item, index) => {
                                                        const {
                                                            job_interview_step_id = '',
                                                            library_asset_type,
                                                            stock_library_question_id,
                                                            tenant_library_question_id,
                                                            tenant_library_message_id
                                                        } = item;
                                                        const questionId = library_asset_type + '-' +
                                                            (!!stock_library_question_id ?
                                                                `stock-${stock_library_question_id}` :
                                                                !!tenant_library_question_id ?
                                                                    `custom-${tenant_library_question_id}` :
                                                                    `custom-${tenant_library_message_id}`);

                                                        return (
                                                            <Draggable key={`${job_interview_step_id}-${questionId}-draggable`} draggableId={`${job_interview_step_id}-${questionId}`} index={index} isDragDisabled={isReadOnly}>
                                                                {(provided, snapshot) => (
                                                                    <QuestionItem
                                                                        keyProp={`${job_interview_step_id}-${questionId}`}
                                                                        isLive={isLive}
                                                                        displayType={isReadOnly ? eQuestionDisplayType.VIEWING : eQuestionDisplayType.EDITING}
                                                                        provided={provided}
                                                                        snapshot={snapshot}
                                                                        innerRef={provided.innerRef}
                                                                        item={item}
                                                                        onDeselect={this.onItemDeselect}
                                                                        onChange={this.onItemChange}
                                                                    />
                                                                )}
                                                            </Draggable>
                                                        );
                                                    })}
                                                    {provided.placeholder}
                                                </List>
                                            )}
                                        </Droppable>
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </DragDropContext>
                </div>
                {isSelecting && (
                    <CustomModal classes={`question-selection open`} closeText={selectLibraryQuestionCloseText} onClose={this.hideItemSelection} closeOnOverlayClick={false}>
                        <InterviewItemsSelection
                            isLive={isLive}
                            items={library}
                            interview_steps={job_interview_steps}
                            selectedQuestion={selectedPreInterviewQuestion}
                            onLibraryUpdate={this.onLibraryUpdate}
                            onSelect={this.onItemSelect}
                            onDeselect={this.onItemDeselect}
                            onVideoSelect={this.onVideoSelect}
                            integrationProvider={integrationProvider}
                        />
                    </CustomModal>
                )}
            </React.Fragment>
        );
    }
}

InterviewTemplate.propTypes = {
    integrationProvider: PropTypes.string,
    is_in_job_context: PropTypes.bool,
    interview_template_id: PropTypes.number,
    interview_template_name: PropTypes.string,
    interview_terminology: PropTypes.oneOf(objectToArray(eInterviewTerminologies)),
    job_interview_steps: PropTypes.array,
    isReadOnly: PropTypes.bool,
    onChange: PropTypes.func
};

InterviewTemplate.defaultProps = {
    integrationProvider: "",
    is_in_job_context: false,
    interview_template_id: null,
    interview_template_name: "",
    interview_terminology: null,
    job_interview_steps: [],
    isReadOnly: false,
    onChange: null
};

export default InterviewTemplate;