import React from 'react';
import IconComment from '../../images/ico-comment-2x.png';
import IconCopy from '../../images/ico-copy.png';
import IconFunnel from '../../images/icons-filters.png';
import IconTickThinImport from '../../images/IconTickThin.svg';
import IconThumbUp from '../../images/ico-thumb-up.png';
import IconThumbDown from '../../images/ico-thumb-down.png';
import IconPageUp from '../../images/ico-pageup.png';

import LogoAlcamiBlue from '../../images/logo-alcami-blue.png';
import LogoInsideSherpa from '../../images/logo-insidesherpa.png';
import LogoJobAdder from '../../images/logo-jobadder.svg';
import LogoPageUp from '../../images/logo-pageup.png';
import LogoSAP from '../../images/logo-sap.svg';
import LogoSmartRecruiters from '../../images/logo-smartrecruiters.svg';

import './style.scss';

const IconArrowDown = (
    <svg className="icon-arrow-down" width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.664906 0H9.33509C9.62634 0 9.82797 0.154093 9.93999 0.462278C10.052 0.770463 10.0072 1.0355 9.80557 1.2574L5.47048 6.02811C5.33605 6.17604 5.17923 6.25 5 6.25C4.82077 6.25 4.66395 6.17604 4.52952 6.02811L0.194431 1.2574C-0.00720122 1.0355 -0.0520082 0.770463 0.0600095 0.462278C0.172027 0.154093 0.37366 0 0.664906 0Z" fill="#53565A"/>
    </svg>
)

const IconAttachment = (
    <svg className="icon-attachment" width="11" height="12" viewBox="0 0 11 12">
        <title>Attachment</title>
        <g id="Screens" stroke="none" strokeWidth="0.5" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <g transform="translate(-224.000000, -306.000000)" stroke="#00A3D1">
                <path
                    d="M232.437184,315 L227.437184,315 C225.787184,315 224.437184,313.65 224.437184,312 C224.437184,310.343
                    225.780184,309 227.437184,309 L233.937184,309 C235.042184,309 235.937184,309.895 235.937184,311 C235.937184,312.105
                    235.042184,313 233.937184,313 L227.437184,313 C226.885184,313 226.437184,312.552 226.437184,312 C226.437184,311.448
                    226.885184,311 227.437184,311 L232.437184,311"
                    id="attachment"
                    transform="translate(230.187184, 312.000000) rotate(-45.000000) translate(-230.187184, -312.000000)"
                />
            </g>
        </g>
    </svg>
);

const IconChain = (
    <svg className={`icon-chain`} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
        <path d="M6.188 8.719c.439-.439.926-.801 1.444-1.087 2.887-1.591 6.589-.745 8.445 2.069l-2.246 2.245c-.644-1.469-2.243-2.305-3.834-1.949-.599.134-1.168.433-1.633.898l-4.304 4.306c-1.307 1.307-1.307 3.433 0 4.74 1.307 1.307 3.433 1.307 4.74 0l1.327-1.327c1.207.479 2.501.67 3.779.575l-2.929 2.929c-2.511 2.511-6.582 2.511-9.093 0s-2.511-6.582 0-9.093l4.304-4.306zm6.836-6.836l-2.929 2.929c1.277-.096 2.572.096 3.779.574l1.326-1.326c1.307-1.307 3.433-1.307 4.74 0 1.307 1.307 1.307 3.433 0 4.74l-4.305 4.305c-1.311 1.311-3.44 1.3-4.74 0-.303-.303-.564-.68-.727-1.051l-2.246 2.245c.236.358.481.667.796.982.812.812 1.846 1.417 3.036 1.704 1.542.371 3.194.166 4.613-.617.518-.286 1.005-.648 1.444-1.087l4.304-4.305c2.512-2.511 2.512-6.582.001-9.093-2.511-2.51-6.581-2.51-9.092 0z"/>
    </svg>
);

const IconCircleCheckAnimated = (
    <svg className={`icon-circle-check-animated`} viewBox="0 0 130.2 130.2">
        <circle
            className="path circle"
            fill="none"
            stroke="#73AF55"
            strokeWidth="6"
            strokeMiterlimit="10"
            cx="65.1"
            cy="65.1"
            r="62.1"
        />
        <polyline
            className="path check"
            fill="none"
            stroke="#73AF55"
            strokeWidth="6"
            strokeLinecap="round"
            strokeMiterlimit="10"
            points="100.2,40.2 51.5,88.8 29.8,67.5 "
        />
    </svg>
);

const IconClock = (
    <svg width="16" height="16" className="icon-extend-time">
        <g transform="translate(.5 .5)" fill="none">
            <circle className="icon-bg" fill="#FFF" cx="7.5" cy="7.5" r="6.5"/>
            <path
                className="icon-highlight"
                d="M7.5 0a7.5 7.5 0 1 1 0 15 7.5 7.5 0 0 1 0-15zm0 1a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13zm0 2a.5.5 0 0 1 .5.5V7h3a.5.5 0 1 1 0 1H7.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5z"
                fill="#6A7B83"
            />
        </g>
    </svg>
);

const IconClose = (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24">
        <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/>
    </svg>
);

const IconCross = (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" className={`icon-cross`}>
        <path
            d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313
            3.666 3.666 8.237-8.318 8.285 8.203z"
        />
    </svg>
);

const IconCrossAnimated = (
    <div className={`icon-cross-animated`}>
        <svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" className={`icon-cross-animated-character`}>
            <g id="🔍-Product-Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="ic_fluent_dismiss_28_regular" fill="red" fillRule="nonzero">
                    <path
                        d="M3.52499419,3.71761187 L3.61611652,3.61611652 C4.0717282,3.16050485 4.79154862,3.13013074
                        5.28238813,3.52499419 L5.38388348,3.61611652 L14,12.233 L22.6161165,3.61611652 C23.1042719,3.12796116
                        23.8957281,3.12796116 24.3838835,3.61611652 C24.8720388,4.10427189 24.8720388,4.89572811
                        24.3838835,5.38388348 L15.767,14 L24.3838835,22.6161165 C24.8394952,23.0717282 24.8698693,23.7915486
                        24.4750058,24.2823881 L24.3838835,24.3838835 C23.9282718,24.8394952 23.2084514,24.8698693
                        22.7176119,24.4750058 L22.6161165,24.3838835 L14,15.767 L5.38388348,24.3838835 C4.89572811,24.8720388
                        4.10427189,24.8720388 3.61611652,24.3838835 C3.12796116,23.8957281 3.12796116,23.1042719
                        3.61611652,22.6161165 L12.233,14 L3.61611652,5.38388348 C3.16050485,4.9282718 3.13013074,4.20845138
                        3.52499419,3.71761187 L3.61611652,3.61611652 L3.52499419,3.71761187 Z"
                        id="🎨-Color"
                    />
                </g>
            </g>
        </svg>
    </div>
);

const IconCrossThin = (
    <svg width="12" height="13" className={`icon-cross-thin`}>
        <path
            d="M11.2.182l.8.8-5.201 5.199L12 11.382l-.8.8-5.201-5.201L.8 12.182l-.8-.8 5.2-5.201L0 .982l.8-.8 5.2 5.2 5.2-5.2z"
            fill="red"
            fillRule="evenodd"
        />
    </svg>
);

const IconCV = (
    <svg width="20" height="22">
        <path
            d="M18.482 19.602l-1.613-.434-.183-.793c.449-.434.75-1.087.797-1.737h.019a.403.403 0 0 0 .389-.37l.098-.843c.028-.25-.151-.47-.385-.473l.048-1.055.196-.21c.253-.29.253-.638 0-1.055-.255-.418-.698-.632-1.32-.632-.327 0-.897.019-1.42.422-1.576.017-1.76.724-1.76 1.475 0 .265.092 1.054.092 1.054h-.04c-.236 0-.418.223-.389.474l.098.843c.025.211.191.37.389.37h.063c.047.633.332 1.27.761 1.703l-.192.827-1.612.434c-.893.24-1.018 1.407-1.018 2.398h8c0-.99-.126-2.158-1.018-2.398zm-2.318-9.96V1.768H1.732V20.19H8.37c.476 0 .865.397.865.884 0 .486-.39.884-.865.884H.866A.878.878 0 0 1 0 21.074V.884C0 .398.39 0 .866 0H17.03c.476 0 .866.398.866.884v8.758c0 .486-.39.884-.866.884a.878.878 0 0 1-.866-.884zm-3.175-4.484H5.196a.878.878 0 0 0-.866.884c0 .486.39.884.866.884h7.793a.878.878 0 0 0 .866-.884.878.878 0 0 0-.866-.884zm.866 4.747a.878.878 0 0 0-.866-.884H5.196a.878.878 0 0 0-.866.884c0 .487.39.884.866.884h7.793a.878.878 0 0 0 .866-.884zm-8.66 2.832a.878.878 0 0 0-.865.884c0 .486.39.884.866.884h3.309a.878.878 0 0 0 .866-.884.878.878 0 0 0-.866-.884h-3.31z"
            fill="#00A1DF"
            fillRule="nonzero"
        />
    </svg>
);

const IconDisguiseVoice = (
    <svg width="20" height="16" className="icon-disguise-voice">
        <path
            d="M2.389 0C1.063 0 0 1.126 0 2.5v11C0 14.874 1.063 16 2.389 16H17.61C18.937 16 20 14.874 20 13.5v-11C20 1.126 18.937 0 17.611 0H2.39zM17.61 1C18.371 1 19 1.666 19 2.5v11c0 .834-.629 1.5-1.389 1.5H2.39C1.629 15 1 14.334 1 13.5v-11C1 1.666 1.629 1 2.389 1H17.61zm-5.603 2.412l-1.233 6.932L9.58 6.356a.5.5 0 0 0-.926-.08l-1.415 2.83-.722-1.083A.5.5 0 0 0 6.1 7.8h-3a.5.5 0 0 0 0 1h2.732l1.052 1.577a.5.5 0 0 0 .863-.053L8.983 7.85l1.438 4.793c.151.504.88.462.971-.056l1.092-6.142.523 3.136a.5.5 0 0 0 .877.238l.85-1.02H16.9a.5.5 0 1 0 0-1h-2.4a.5.5 0 0 0-.384.18l-.302.362-.82-4.924c-.093-.554-.888-.559-.986-.006z"
            fill="#000"
            className="icon-highlight"
        />
    </svg>
);

const IconDomino = (
    <svg width="9" height="19">
        <path
            d="M2 15.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm-5-5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm-5-5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm-5-5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3z"
            fill="#CCC"
            fillRule="evenodd"
        />
    </svg>
);

const IconExclamationMark = (
    <div className={`icon-exclamation-mark`}>
        !
    </div>
);

const IconFile = (
    <svg fill="#000000" viewBox="0 0 16 16" width="16px" height="16px" className="icon-file">
        <path d="M 4.5 2 C 3.675781 2 3 2.675781 3 3.5 L 3 12.5 C 3 13.324219 3.675781 14 4.5 14 L 11.5 14 C 12.324219 14 13 13.324219 13 12.5 L 13 5.292969 L 9.707031 2 Z M 4.5 3 L 9 3 L 9 6 L 12 6 L 12 12.5 C 12 12.78125 11.78125 13 11.5 13 L 4.5 13 C 4.21875 13 4 12.78125 4 12.5 L 4 3.5 C 4 3.21875 4.21875 3 4.5 3 Z M 10 3.707031 L 11.292969 5 L 10 5 Z"/>
    </svg>
);

const IconFilter = (
    <svg width="12" height="7" className="icon-filter">
        <path
            d="M7 6a.5.5 0 0 1 .09.992L7 7H5a.5.5 0 0 1-.09-.992L5 6h2zm2-3a.5.5 0 0 1 .09.992L9 4H3a.5.5 0 0 1-.09-.992L3 3h6zm2-3a.5.5 0 0 1 .09.992L11 1H1A.5.5 0 0 1 .91.008L1 0h10z"
            fill="#000"
            fillRule="nonzero"
        />
    </svg>
);

const IconGlobe = (
    <svg x="0px" y="0px" viewBox="0 0 512 512" className="icon-globe">
        <g>
            <g>
                <path d="M444.943,347.699c-4.394-3.522-10.815-2.815-14.337,1.582c-4.661,5.818-9.703,11.489-14.982,16.858
            c-3.95,4.016-3.895,10.475,0.12,14.424c1.986,1.953,4.569,2.927,7.151,2.927c2.64,0,5.277-1.018,7.273-3.047
            c5.761-5.859,11.265-12.052,16.356-18.406C450.047,357.64,449.339,351.222,444.943,347.699z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M404.879,390.097c-3.403-4.489-9.802-5.371-14.291-1.966c-38.569,29.235-85.309,46.179-135.164,49
                    c-5.624,0.318-9.925,5.135-9.607,10.759c0.307,5.428,4.804,9.624,10.174,9.624c0.194,0,0.389-0.005,0.585-0.017
                    c53.918-3.051,104.52-21.416,146.335-53.11C407.4,400.984,408.281,394.587,404.879,390.097z"/>
            </g>
        </g>
        <g>
            <g>
                <path d="M504.755,199.948c0-0.001,0-0.001-0.001-0.002c-5.817-22.133-15.065-43.336-27.491-63.023
                    c-17.666-27.99-40.935-51.967-69.158-71.266C363.825,35.381,311.228,19.378,256,19.378c-15.448,0-30.959,1.291-46.103,3.837
                    c-42.923,7.216-83.441,24.689-117.168,50.531c-49.576,37.985-81.659,91.227-90.339,149.919C0.804,234.386,0,245.264,0,256
                    c0,42.127,12.129,83.49,35.075,119.617c22.189,34.936,53.913,64.224,91.743,84.698c0.003,0.002,0.006,0.004,0.009,0.005
                    c21.464,11.616,44.583,20.243,68.715,25.645c19.732,4.417,40.074,6.656,60.458,6.656c49.509,0,97.548-13.086,138.924-37.842
                    c40.419-24.183,72.565-58.22,92.963-98.427C503.888,324.811,512,291.048,512,256C512,237.098,509.562,218.24,504.755,199.948z
                     M412.152,94.092c12.785,10.373,24.232,21.918,34.257,34.545l-20.162-4.63c-6.466-1.485-13.11,0.087-18.227,4.308
                    c-5.118,4.222-7.922,10.448-7.691,17.08c0.01,0.274-0.127,0.539-0.359,0.689l-14.81,9.673c-0.249,0.162-0.566,0.172-0.824,0.024
                    L363.474,143.9c-1.856-1.058-2.813-3.249-2.325-5.33l4.694-20.037c0.503-2.146,2.393-3.686,4.596-3.747l17.674-0.492
                    C399.968,113.964,409.836,105.307,412.152,94.092z M126.715,414.119l-2.982,20.807C59.64,394.883,20.398,327.546,20.398,256
                    c0-6.33,0.308-12.715,0.919-19.083l13.65,2.206c3.437,0.556,6.949,0.838,10.436,0.838h6.914c9.584,0,19.103,3.144,26.802,8.852
                    l10.476,7.766c0.896,0.664,1.48,1.682,1.599,2.792l1.42,13.137c0.229,2.125-1.244,4.055-3.355,4.393
                    c-11.911,1.905-20.745,12.437-20.55,24.497l0.746,46.244c0.085,5.254,1.814,10.239,5.002,14.415l16.623,21.78
                    c7.843,10.278,18.449,17.937,30.671,22.152C125.135,407.156,127.223,410.575,126.715,414.119z M192.423,464.242
                    c-17.254-4.427-33.891-10.659-49.622-18.587l4.105-28.639c1.895-13.213-5.889-25.96-18.506-30.311
                    c-8.41-2.9-15.708-8.172-21.105-15.244l-16.622-21.779c-0.524-0.686-0.808-1.506-0.822-2.369l-0.746-46.243
                    c-0.032-1.983,1.421-3.714,3.378-4.027c12.844-2.055,21.81-13.795,20.412-26.728l-1.42-13.138
                    c-0.729-6.75-4.276-12.941-9.731-16.985l-10.476-7.766c-11.19-8.295-25.023-12.864-38.951-12.864h-6.914
                    c-2.4,0-4.815-0.194-7.181-0.576l-13.932-2.252c9.889-49.492,38.323-94.217,80.844-126.797
                    c29.971-22.964,65.793-38.765,103.851-45.846c5.773,4.803,10.241,10.963,13.03,18.011l0.578,1.463
                    c1.448,3.659,2.412,7.497,2.863,11.407l0.514,4.45c0.913,7.915-0.298,15.955-3.5,23.25l-5.546,12.635
                    c-3.631,8.268-9.588,15.183-17.229,19.993l-6.91,4.351c-7.001,4.407-11.094,12.279-10.682,20.542l0.934,18.667
                    c0.047,0.953-0.396,1.586-0.776,1.95c-0.38,0.363-1.031,0.774-1.983,0.687c-0.841-0.08-1.582-0.572-1.983-1.316l-8.487-15.761
                    c-2.938-5.459-8.049-9.545-14.021-11.212l-29.996-8.372c-7.883-2.2-16.329-1.546-23.779,1.84l-7.143,3.247
                    c-15.028,6.831-23.241,23.464-19.529,39.547c1.469,6.369,4.69,12.18,9.311,16.801l19.489,19.49
                    c1.099,1.097,2.152,2.268,3.133,3.478l13.361,16.479c5.9,7.277,14.647,11.446,24.013,11.445c0.032,0,0.063,0,0.095,0l36.401-0.111
                    c0.011,0,0.021,0,0.033,0c3.726,0,7.205,1.997,9.083,5.218c4.37,7.493,11.742,12.834,20.226,14.652l13.639,2.922
                    c2.999,0.643,5.561,2.555,7.029,5.248l6.587,12.076c2.641,4.842,6.636,8.722,11.553,11.221l30.957,15.731
                    c2.546,1.294,4.017,4.066,3.664,6.9c-0.205,1.642-1,3.15-2.24,4.246l-11.759,10.402c-3.315,2.932-5.889,6.673-7.444,10.818
                    l-2.564,6.836c-0.78,2.083-2.406,3.766-4.462,4.618l-12.525,5.193c-10.668,4.423-17.563,14.745-17.563,26.295
                    c0,1.298-0.319,2.594-0.922,3.745L192.423,464.242z M256,472.223c-14.387,0-28.747-1.203-42.851-3.584l29.352-55.996
                    c2.129-4.06,3.255-8.631,3.255-13.216c0-3.273,1.954-6.199,4.977-7.453l12.526-5.193c7.254-3.009,12.994-8.95,15.749-16.299
                    l2.563-6.832c0.389-1.036,1.032-1.971,1.86-2.704l11.759-10.402c4.96-4.388,8.143-10.424,8.965-16.993
                    c1.419-11.339-4.473-22.437-14.662-27.616l-30.958-15.731c-1.228-0.624-2.227-1.594-2.886-2.804l-6.587-12.076
                    c-4.316-7.915-11.848-13.536-20.663-15.426l-13.638-2.922c-2.886-0.618-5.394-2.436-6.881-4.984
                    c-5.523-9.467-15.749-15.338-26.704-15.338c-0.032,0-0.063,0-0.095,0l-36.4,0.111c-0.011,0-0.021,0-0.033,0
                    c-3.186,0-6.162-1.418-8.169-3.893l-13.362-16.479c-1.426-1.759-2.959-3.461-4.555-5.056l-19.488-19.488
                    c-1.915-1.914-3.249-4.322-3.858-6.962c-1.538-6.667,1.866-13.561,8.094-16.392l7.143-3.247c3.089-1.403,6.589-1.675,9.857-0.763
                    l29.996,8.372c0.657,0.184,1.219,0.633,1.543,1.234l8.488,15.762c3.638,6.756,10.37,11.223,18.009,11.951
                    c6.629,0.633,13.197-1.646,18.013-6.249c4.816-4.603,7.386-11.06,7.054-17.714l-0.934-18.667c-0.045-0.91,0.405-1.776,1.176-2.261
                    l6.91-4.351c11.105-6.992,19.763-17.039,25.039-29.055l5.546-12.635c4.654-10.602,6.413-22.285,5.086-33.789l-0.514-4.45
                    c-0.655-5.68-2.055-11.257-4.16-16.575l-0.579-1.464c-1.955-4.938-4.486-9.571-7.515-13.834h0.013
                    c7.483-0.654,15.01-0.983,22.52-0.983c49.28,0,96.25,13.738,136.193,39.782l0.076,9.449c0.021,2.626-2.098,4.822-4.723,4.896
                    l-17.673,0.492c-11.454,0.318-21.278,8.332-23.89,19.487l-4.694,20.035c-2.534,10.816,2.438,22.211,12.09,27.708l20.861,11.881
                    c3.274,1.864,6.887,2.792,10.492,2.792c4.031,0,8.053-1.16,11.581-3.463l14.81-9.673c6.172-4.03,9.846-11.11,9.59-18.475
                    c-0.004-0.106-0.014-0.391,0.287-0.638c0.304-0.249,0.578-0.183,0.68-0.161l22.874,5.253c0.568,0.131,1.149,0.239,1.733,0.322
                    l16.17,2.321c7.032,11.706,12.847,23.961,17.384,36.627l-15.799-3.819c-0.63-0.152-1.274-0.283-1.915-0.386l-17.252-2.782
                    c-18.733-3.021-37.783,3.341-50.941,17.026l-2.596,2.7c-7.696,8.004-12.903,17.992-15.059,28.884l-6.578,33.245
                    c-2.294,11.589-1.02,23.443,3.683,34.281c8.211,18.921,26.041,32.128,46.531,34.466l3.196,0.365
                    c8.762,1.002,17.784-0.043,26.085-3.017l10.879-3.897c1.938-0.695,4.115-0.086,5.416,1.52l1.428,1.763
                    c0.667,0.824,1.05,1.86,1.082,2.917l0.516,17.729C429.455,424.098,346.343,472.223,256,472.223z M482.538,315.632l-1.189-1.47
                    c-4.887-6.037-12.171-9.364-19.652-9.364c-2.844,0-5.717,0.481-8.497,1.477l-10.879,3.896c-5.377,1.927-11.222,2.603-16.893,1.954
                    l-3.195-0.365c-13.27-1.515-24.817-10.068-30.134-22.32c-3.047-7.018-3.872-14.695-2.387-22.2l6.578-33.245
                    c1.396-7.054,4.768-13.522,9.753-18.705l2.596-2.7c8.521-8.861,20.856-12.983,32.99-11.025l17.25,2.782
                    c0.122,0.02,0.245,0.044,0.368,0.074l27.225,6.579c3.406,14.75,5.129,29.859,5.129,44.999
                    C491.602,276.427,488.563,296.38,482.538,315.632z"/>
            </g>
        </g>
    </svg>
);

const IconHamburger = (
    <svg className="icon-hamburger" viewBox="0 0 18 15">
        <path fill="#FFFFFF" d="M18,1.484c0,0.82-0.665,1.484-1.484,1.484H1.484C0.665,2.969,0,2.304,0,1.484l0,0C0,0.665,0.665,0,1.484,0 h15.031C17.335,0,18,0.665,18,1.484L18,1.484z"/>
        <path fill="#FFFFFF" d="M18,7.516C18,8.335,17.335,9,16.516,9H1.484C0.665,9,0,8.335,0,7.516l0,0c0-0.82,0.665-1.484,1.484-1.484 h15.031C17.335,6.031,18,6.696,18,7.516L18,7.516z"/>
        <path fill="#FFFFFF" d="M18,13.516C18,14.335,17.335,15,16.516,15H1.484C0.665,15,0,14.335,0,13.516l0,0 c0-0.82,0.665-1.484,1.484-1.484h15.031C17.335,12.031,18,12.696,18,13.516L18,13.516z"/>
    </svg>
);

const IconHideVideo = (
    <svg width="20" height="16" className="icon-hide-video">
        <path
            d="M2.389 0C1.063 0 0 1.126 0 2.5v11C0 14.874 1.063 16 2.389 16H17.61C18.937 16 20 14.874 20 13.5v-11C20 1.126 18.937 0 17.611 0H2.39zM17.61 1C18.371 1 19 1.666 19 2.5v11c0 .834-.629 1.5-1.389 1.5H2.39C1.629 15 1 14.334 1 13.5v-11C1 1.666 1.629 1 2.389 1H17.61zm-6.747 1.646L7.532 5.978H5a.5.5 0 0 0-.5.5v3.044a.5.5 0 0 0 .5.5h2.532l3.332 3.332a.5.5 0 0 0 .853-.354V3a.5.5 0 0 0-.853-.354zm-.147 1.561v7.586L8.093 9.168a.5.5 0 0 0-.354-.146H5.5V6.978h2.24a.5.5 0 0 0 .353-.146l2.624-2.625zm3.671 1.134a.5.5 0 0 0 0 .707 2.765 2.765 0 0 1 0 3.904.5.5 0 0 0 .708.707 3.765 3.765 0 0 0 0-5.318.5.5 0 0 0-.708 0zm-1.23 1.23a.5.5 0 0 0-.057.637l.058.07c.367.367.396.948.085 1.348l-.085.097a.5.5 0 1 0 .707.707 2.023 2.023 0 0 0 0-2.86.5.5 0 0 0-.707 0z"
            fill="#000"
            className="icon-highlight"
        />
    </svg>
);

const IconInformation = (
    <div className={`icon-information`}>
        i
    </div>
);

const IconInformationAnimated = (
    <div className={`icon-information-animated`}>
        i
    </div>
);

const IconLive = (
    <svg width="8" height="13">
        <path
            d="M.361 6.701l-.031.07a.375.375 0 00.357.492l2.453-.001-.576 5.037c-.046.405.495.583.698.23l3.75-6.546.031-.07a.375.375
            0 00-.356-.491L4.232 5.42 4.809.385c.047-.405-.495-.583-.698-.23L.361 6.702z"
            fill="#FFF"
            fillRule="nonzero"
        />
    </svg>
);

const IconMail = (
    <svg className="icon-mail">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fill="#212121" fillRule="nonzero">
                <path
                    d="M37.75,9 C40.6494949,9 43,11.3505051 43,14.25 L43,33.75 C43,36.6494949 40.6494949,39 37.75,39
                    L10.25,39 C7.35050506,39 5,36.6494949 5,33.75 L5,14.25 C5,11.3505051 7.35050506,9 10.25,9 L37.75,9
                    Z M40.5,18.351 L24.6023984,27.0952699 C24.2689733,27.2786537 23.8727436,27.2990297 23.5253619,27.1563978
                    L23.3976016,27.0952699 L7.5,18.351 L7.5,33.75 C7.5,35.2687831 8.73121694,36.5 10.25,36.5 L37.75,36.5
                    C39.2687831,36.5 40.5,35.2687831 40.5,33.75 L40.5,18.351 Z M37.75,11.5 L10.25,11.5 C8.73121694,11.5
                    7.5,12.7312169 7.5,14.25 L7.5,15.499 L24,24.573411 L40.5,15.498 L40.5,14.25 C40.5,12.7312169
                    39.2687831,11.5 37.75,11.5 Z"
                />
            </g>
        </g>
    </svg>
);

const IconMailDelivered = (
    <svg className="icon-mail-delivered">
        <g id="icon-mail-delivered-envelope" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fill="#212121" fillRule="nonzero">
                <path
                    d="M37.75,9 C40.6494949,9 43,11.3505051 43,14.25 L43,33.75 C43,36.6494949 40.6494949,39 37.75,39
                    L10.25,39 C7.35050506,39 5,36.6494949 5,33.75 L5,14.25 C5,11.3505051 7.35050506,9 10.25,9 L37.75,9
                    Z M40.5,18.351 L24.6023984,27.0952699 C24.2689733,27.2786537 23.8727436,27.2990297 23.5253619,27.1563978
                    L23.3976016,27.0952699 L7.5,18.351 L7.5,33.75 C7.5,35.2687831 8.73121694,36.5 10.25,36.5 L37.75,36.5
                    C39.2687831,36.5 40.5,35.2687831 40.5,33.75 L40.5,18.351 Z M37.75,11.5 L10.25,11.5 C8.73121694,11.5
                    7.5,12.7312169 7.5,14.25 L7.5,15.499 L24,24.573411 L40.5,15.498 L40.5,14.25 C40.5,12.7312169
                    39.2687831,11.5 37.75,11.5 Z"
                />
            </g>
        </g>
        <g id="icon-mail-delivered-tick" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
                d="M23 17.5C23 20.5376 20.5376 23 17.5 23C14.4624 23 12 20.5376 12 17.5C12 14.4624 14.4624 12 17.5
                12C20.5376 12 23 14.4624 23 17.5ZM19.8536 15.6464C19.6583 15.4512 19.3417 15.4512 19.1464 15.6464L16.5
                18.2929L15.6036 17.3964C15.4083 17.2012 15.0917 17.2012 14.8964 17.3964C14.7012 17.5917 14.7012 17.9083
                14.8964 18.1036L16.1464 19.3536C16.3417 19.5488 16.6583 19.5488 16.8536 19.3536L19.8536 16.3536C20.0488
                16.1583 20.0488 15.8417 19.8536 15.6464Z"
                fill="#212121"
            />
        </g>
    </svg>
);

const IconMailOpen = (
    <svg className="icon-mail-open">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g fill="#212121" fillRule="nonzero">
                <path
                    d="M24.628836,6.16969203 L39.3911111,14.7626582 C41.0063813,15.70289 42,17.4309589 42,19.2999516
                    L42,34.75 C42,37.0972102 40.0972102,39 37.75,39 L10.25,39 C7.90278981,39 6,37.0972102 6,34.75
                    L6,19.2999516 C6,17.4309589 6.99361871,15.70289 8.60888888,14.7626582 L23.371164,6.16969203
                    C23.7598603,5.94343599 24.2401397,5.94343599 24.628836,6.16969203 Z M39.5,20.232 L24.6256287,28.8321685
                    C24.2815658,29.0310799 23.8663561,29.0531812 23.5064829,28.8984723 L23.3743713,28.8321685 L8.5,20.233
                    L8.5,34.75 C8.5,35.7164983 9.28350169,36.5 10.25,36.5 L37.75,36.5 C38.7164983,36.5 39.5,35.7164983
                    39.5,34.75 L39.5,20.232 Z M24,8.69634682 L9.86656084,16.9232741 C9.53978057,17.1134895 9.2615733,17.3652628
                    9.04316411,17.6590718 L24,26.3061399 L38.9568359,17.6590718 C38.7384267,17.3652628 38.4602194,17.1134895
                    38.1334392,16.9232741 L24,8.69634682 Z"
                    id="🎨-Color"
                />
            </g>
        </g>
        <g id="icon-mail-delivered-tick" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
                d="M23 17.5C23 20.5376 20.5376 23 17.5 23C14.4624 23 12 20.5376 12 17.5C12 14.4624 14.4624 12 17.5
                12C20.5376 12 23 14.4624 23 17.5ZM19.8536 15.6464C19.6583 15.4512 19.3417 15.4512 19.1464 15.6464L16.5
                18.2929L15.6036 17.3964C15.4083 17.2012 15.0917 17.2012 14.8964 17.3964C14.7012 17.5917 14.7012 17.9083
                14.8964 18.1036L16.1464 19.3536C16.3417 19.5488 16.6583 19.5488 16.8536 19.3536L19.8536 16.3536C20.0488
                16.1583 20.0488 15.8417 19.8536 15.6464Z"
                fill="#212121"
            />
        </g>
    </svg>
);

const IconNo = (
    <span className={`icon-yes-no icon-no`}>
        No
    </span>
);

const IconParagraph = (
    <svg width="12" height="10" className="icon-paragraph">
        <path
            className="icon-highlight"
            d="M0 0h12v1H0V0zm0 6h12v1H0V6zm0-3h12v1H0V3zm0 6h8v1H0V9z"
            fill="#2196F3"
        />
    </svg>
);

const IconPencil = (
    <svg fill="#000000" viewBox="0 0 16 16" width="16" height="16" className={`icon-pencil`}>
        <path d="M 12.03125 2.023438 C 11.535156 2.023438 11.066406 2.269531 10.675781 2.65625 L 2.5625 10.726563 L 1.207031 14.785156 L 5.265625 13.433594 L 5.351563 13.351563 L 13.386719 5.367188 C 13.773438 4.976563 14.015625 4.507813 14.015625 4.011719 C 14.015625 3.515625 13.773438 3.046875 13.386719 2.65625 C 12.996094 2.269531 12.527344 2.023438 12.03125 2.023438 Z M 10.027344 4.710938 L 11.320313 6.007813 L 4.726563 12.5625 L 2.789063 13.207031 L 3.4375 11.265625 Z"/>
    </svg>
);

const IconPlay = (
    <svg width="16" height="16" className="icon-play">
        <polygon fill="white" stroke="#fff" strokeWidth="1" points="0,0 0,16 12,8" />
    </svg>
);

const IconPlus = (
    <svg width="12" height="12">
        <path d="M7 0v5h5v2H7v5H5V7H0V5h5V0h2z" fill="#FFF" fillRule="evenodd" />
    </svg>
);

const IconProfile = (
    <svg width="21" height="21" className="icon-profile">
        <path
            d="M16.796 15.895l-3.405-.852-.387-1.552a5.253 5.253 0 0 0 1.683-3.404h.04c.417 0 .768-.31.82-.724l.207-1.652a.825.825 0 0 0-.81-.926l.1-2.068.413-.413c.534-.568.534-1.248 0-2.065C14.92 1.42 13.984 1 12.673 1c-.693 0-1.895.037-3 .826-3.327.034-3.716 1.42-3.716 2.891 0 .52.194 2.066.194 2.066h-.085a.826.826 0 0 0-.82.928l.207 1.652a.825.825 0 0 0 .82.724h.135c.098 1.24.7 2.487 1.605 3.337l-.404 1.62-3.405.851A4.23 4.23 0 0 0 1 19.998V20h19v-.002a4.23 4.23 0 0 0-3.204-4.103h0z"
            stroke="#000"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="icon-highlight"
        />
    </svg>
);

const IconQuestionMark = (
    <div className={`icon-question-mark`}>
        ?
    </div>
);

const IconQuestionMarkAnimated = (
    <div className={`icon-question-mark-animated`}>
        ?
    </div>
);

const IconRefresh = (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 383.748 383.748" className={`icon-refresh`}>
        <g>
            <path d="M62.772,95.042C90.904,54.899,137.496,30,187.343,30c83.743,0,151.874,68.13,151.874,151.874h30
		C369.217,81.588,287.629,0,187.343,0c-35.038,0-69.061,9.989-98.391,28.888C70.368,40.862,54.245,56.032,41.221,73.593
		L2.081,34.641v113.365h113.91L62.772,95.042z"/>
            <path d="M381.667,235.742h-113.91l53.219,52.965c-28.132,40.142-74.724,65.042-124.571,65.042
		c-83.744,0-151.874-68.13-151.874-151.874h-30c0,100.286,81.588,181.874,181.874,181.874c35.038,0,69.062-9.989,98.391-28.888
		c18.584-11.975,34.707-27.145,47.731-44.706l39.139,38.952V235.742z"/>
        </g>
    </svg>
);

const IconSearch = (
    <svg width="12" height="12" className="icon-search">
        <path
            d="M4.5 0a4.5 4.5 0 0 1 3.516 7.309l3.107 3.107a.5.5 0 0 1-.637.765l-.07-.058L7.31 8.016A4.5 4.5 0 1 1 4.5 0zm0 1a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7z"
            fill="#000"
            fillRule="nonzero"
        />
    </svg>
);

const IconSettings = (
    <svg width="16" height="16" className="icon-settings">
        <path
            d="M6.46 0h3c.4 0 .747.269.842.64l.02.104.245 1.713c.205.096.403.204.597.324l.287.19 1.625-.653a.88.88 0 0 1 1 .289l.061.096 1.506 2.605c.187.342.131.76-.135 1.036l-.086.078-1.363 1.065a5.413 5.413 0 0 1 .014.85l-.014.176 1.365 1.067a.876.876 0 0 1 .264 1.016l-.05.107-1.495 2.584a.866.866 0 0 1-.979.423l-.104-.033-1.612-.648a6.54 6.54 0 0 1-.583.362l-.298.152-.244 1.703a.863.863 0 0 1-.756.747L9.46 16h-3a.868.868 0 0 1-.843-.64l-.02-.104-.246-1.713a5.902 5.902 0 0 1-.597-.324l-.286-.189-1.625.653a.88.88 0 0 1-1-.289l-.061-.096-1.506-2.606A.88.88 0 0 1 .41 9.656l.086-.077L1.86 8.513a5.59 5.59 0 0 1-.013-.85l.013-.176L.495 6.42a.87.87 0 0 1-.266-1.018l.053-.104 1.494-2.585a.865.865 0 0 1 .979-.423l.103.034 1.612.647c.193-.133.387-.254.584-.362l.297-.151.245-1.706a.864.864 0 0 1 .756-.745L6.46 0h3-3zm2.888 1H6.57l-.268 1.874a.5.5 0 0 1-.306.392 5.23 5.23 0 0 0-1.156.672.5.5 0 0 1-.404.089l-.083-.025-1.759-.707-1.392 2.409L2.695 6.87a.5.5 0 0 1 .188.456A5.407 5.407 0 0 0 2.835 8c0 .202.016.417.048.673a.5.5 0 0 1-.125.397l-.063.059-1.493 1.168 1.392 2.408L4.354 12a.5.5 0 0 1 .49.068c.363.278.745.5 1.153.668a.5.5 0 0 1 .285.307l.02.085L6.57 15h2.778l.269-1.873a.5.5 0 0 1 .305-.392c.392-.16.773-.384 1.158-.672a.5.5 0 0 1 .403-.09l.083.026 1.759.706 1.392-2.408-1.493-1.168a.5.5 0 0 1-.188-.456c.033-.265.049-.469.049-.673 0-.204-.016-.408-.05-.673a.5.5 0 0 1 .125-.397l.064-.059 1.493-1.167-1.392-2.409-1.76.707a.5.5 0 0 1-.49-.068 4.962 4.962 0 0 0-1.153-.668.5.5 0 0 1-.285-.307l-.02-.085L9.348 1zM7.96 4.565A3.438 3.438 0 0 1 11.394 8a3.438 3.438 0 0 1-3.434 3.435A3.438 3.438 0 0 1 4.525 8 3.438 3.438 0 0 1 7.96 4.565zm0 1A2.438 2.438 0 0 0 5.525 8a2.438 2.438 0 0 0 2.435 2.435A2.438 2.438 0 0 0 10.394 8 2.438 2.438 0 0 0 7.96 5.565z"
            fill="#000"
            className="icon-highlight"
        />
    </svg>
);

const IconShare = (
    <svg width="16" height="14" className="icon-share">
        <g fill="none">
            <path className="icon-bg" fill="#fff" d="M1 2.5h5.5l7 4.5v6H1z" />
            <path
                d="M1.738 2L6 2.003a.5.5 0 0 1 0 1L1.738 3C1.336 3 1 3.351 1 3.794v8.412c0 .443.336.794.738.794h10.524c.402 0 .738-.351.738-.794V7.5a.5.5 0 1 1 1 0v4.706c0 .986-.773 1.794-1.738 1.794H1.738C.773 14 0 13.192 0 12.206V3.794C0 2.808.773 2 1.738 2zm14.143.822l.01-.011-.009.011a.503.503 0 0 1-.017.02l-2.011 2.012a.5.5 0 0 1-.765-.638l.057-.07L14.292 3h-1.023c-3.165 0-5.176 2.52-5.266 6.239L8 9.5a.5.5 0 0 1-1 0c0-4.275 2.313-7.369 6.03-7.496L13.27 2h1.022L13.146.854a.5.5 0 0 1-.057-.638l.057-.07a.5.5 0 0 1 .638-.057l.07.057 2.012 2.013a.503.503 0 0 1 .122.231l.007.038v.006L16 2.5a.509.509 0 0 1-.023.151l-.015.04a.494.494 0 0 1-.04.076l-.027.039-.014.016z"
                fill="#6A7B83"
                className="icon-highlight"
            />
        </g>
    </svg>
);

const IconShowVideo = (
    <svg width="20" height="16" className="icon-show-video">
        <path
            d="M2.389 16C1.063 16 0 14.874 0 13.5v-11C0 1.126 1.063 0 2.389 0H17.61C18.937 0 20 1.126 20 2.5v11c0 1.374-1.063 2.5-2.389 2.5H4.085zM17.61 1H2.39C1.629 1 1 1.666 1 2.5v11c0 .834.629 1.5 1.389 1.5h1.235a3.173 3.173 0 0 1 2.364-2.578l1.859-.465.115-.462a3.87 3.87 0 0 1-.906-1.821 1.023 1.023 0 0 1-.654-.83L6.272 7.8a1.022 1.022 0 0 1 .52-1.022l-.046-.448-.022-.278-.008-.206c0-1.472.725-2.265 2.69-2.322C10.03 3.118 10.657 3 11.457 3c.983 0 1.734.335 2.176 1.008.456.696.456 1.38-.065 1.933l-.124.124-.035.732c.334.2.542.585.49 1.004l-.13 1.044c-.047.361-.277.659-.59.802a3.855 3.855 0 0 1-.96 1.898l.104.412 1.86.465.18.051A3.172 3.172 0 0 1 16.547 15h1.065c.76 0 1.389-.666 1.389-1.5v-11c0-.834-.629-1.5-1.389-1.5zm-6.153 3c-.666 0-1.14.097-1.603.429a.5.5 0 0 1-.286.093c-1.525.014-1.853.356-1.853 1.325l.001.056.01.158.03.336.078.693a.5.5 0 0 1-.496.562h-.054c-.013 0-.023.011-.02.025l.13 1.042c0 .012.01.02.021.02H7.5a.5.5 0 0 1 .498.46c.053.658.377 1.33.86 1.782a.5.5 0 0 1 .142.486l-.256 1.023a.5.5 0 0 1-.364.364l-2.15.538c-.8.2-1.404.831-1.587 1.608h10.884l-.042-.153a2.171 2.171 0 0 0-1.544-1.455l-2.15-.538a.5.5 0 0 1-.364-.363l-.246-.98a.5.5 0 0 1 .152-.495c.504-.45.845-1.14.899-1.817a.5.5 0 0 1 .498-.46h.025c.012 0 .02-.008.022-.019l.13-1.043c.002-.013-.008-.024-.021-.024a.5.5 0 0 1-.493-.524l.063-1.306a.5.5 0 0 1 .146-.33l.25-.249c.166-.176.166-.352-.054-.688-.24-.364-.67-.556-1.34-.556z"
            fill="#000"
            className="icon-highlight"
        />
    </svg>
);

const IconSort = (
    <svg width="11" height="12" className="icon-sort">
        <path
            d="M5.748 8.383a.5.5 0 0 1 .638-.058l.069.058 1.251 1.253V1.158a.5.5 0 0 1 .993-.09l.008.09-.001 8.48 1.252-1.255a.5.5 0 0 1 .638-.058l.07.058a.5.5 0 0 1 .057.638l-.057.07-2.106 2.105-.015.015a.503.503 0 0 1-.032.026l.047-.041a.502.502 0 0 1-.353.146h-.012a.503.503 0 0 1-.062-.005l.074.005a.502.502 0 0 1-.285-.088l-.017-.013a.503.503 0 0 1-.04-.033l-.012-.012L5.748 9.09a.5.5 0 0 1 0-.707zM.9 2.98l.057-.07L3.064.805 3.079.79c.01-.009.02-.018.032-.026l-.047.041a.502.502 0 0 1 .353-.146h.024l.039.004-.063-.004a.502.502 0 0 1 .285.088l.017.013c.014.01.027.022.04.033l.012.012L5.876 2.91a.5.5 0 0 1-.638.765l-.069-.058-1.252-1.25v8.475a.5.5 0 0 1-.992.09l-.008-.09V2.366l-1.251 1.25a.5.5 0 0 1-.638.059l-.07-.058a.5.5 0 0 1-.057-.638z"
            fill="#000"
            fillRule="nonzero"
        />
    </svg>
);

const IconStar = (
    <svg className={`icon-star`} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"/>
    </svg>
);

const IconStarSlash = (
    <svg className={`icon-star-slash`} width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.0502 7.67001C15.8102 7.49001 15.6102 7.26001 15.5002 7.03001L13.7402 3.51001C12.7902 1.60001 11.2302 1.60001 10.2702 3.51001L8.50016 7.03001C8.38016 7.28001 8.16016 7.51001 7.91016 7.70001" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5.28089 18.65L5.85089 16.18C5.98089 15.6 5.75089 14.79 5.33089 14.37L2.85089 11.89C1.39089 10.43 1.86089 8.94999 3.90089 8.60999" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M20.0995 8.60999C22.1395 8.94999 22.6195 10.43 21.1495 11.89L18.6695 14.37C18.2495 14.79 18.0195 15.6 18.1495 16.18L18.8595 19.25C19.4195 21.68 18.1295 22.62 15.9795 21.35L12.9895 19.58C12.4495 19.26 11.5595 19.26 11.0095 19.58L8.01953 21.35" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M22 2L2 22" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

const IconTextBubble = (
    <svg width="20" height="18" className="icon-text-bubble">
        <g
            stroke="#00A3D1"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M1 3h9.19M1 5h5.578M1 1h9.19M16.652 1H3.348A2.348 2.348 0 0 0 1 3.348v7.826a2.348 2.348 0 0 0 2.348 2.348h1.565v3.913l4.696-3.913h7.043A2.348 2.348 0 0 0 19 11.174V3.348A2.348 2.348 0 0 0 16.652 1z" />
        </g>
    </svg>
);

const IconTick = (
    <svg width="14" height="14" viewBox="0 0 24 24" className="icon-tick">
        <path
            d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"
            fill="#f00"
            className="icon-highlight"
        />
    </svg>
);

const IconTickThin = (
    <svg width="9" height="7" className="icon-tick-thin">
        <path
            d="M0 3.5l3.2 3L8 .5"
            stroke="#FFF"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

const IconTickWithCircle = (
    <svg width="80" height="80" className="icon-tick-with-circle">
        <g
            // transform="translate(-717 -268)"
            stroke="#59C427"
            strokeWidth={2}
            fill="none"
            fillRule="evenodd"
        >
            <circle fill="#FFF" cx={40} cy={40} r={39} />
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M56 28L36.23 55.299 23 44"
            />
        </g>
    </svg>
);

const IconTime = (
    <svg width="16" height="16" className="icon-extend-time">
        <g transform="translate(.5 .5)" fill="none">
            <circle className="icon-bg" fill="#FFF" cx="7.5" cy="7.5" r="6.5" />
            <path
                className="icon-highlight"
                d="M7.5 0a7.5 7.5 0 1 1 0 15 7.5 7.5 0 0 1 0-15zm0 1a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13zm0 2a.5.5 0 0 1 .5.5V7h3a.5.5 0 1 1 0 1H7.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5z"
                fill="#6A7B83"
            />
        </g>
    </svg>
);

const IconTrash = (
    <svg viewBox="0 0 32 32" className="icon-trash">
        <g fill="#444">
            <rect height="12" width="2" x="15" y="12"/>
            <rect height="12" width="2" x="19" y="12"/>
            <rect height="12" width="2" x="11" y="12"/>
            <path d="M20,6V5a3,3,0,0,0-3-3H15a3,3,0,0,0-3,3V6H4V8H6V27a3,3,0,0,0,3,3H23a3,3,0,0,0,3-3V8h2V6ZM14,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H14ZM24,27a1,1,0,0,1-1,1H9a1,1,0,0,1-1-1V8H24Z"/>
        </g>
    </svg>
);

const IconWordCount = (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
         width="16.000000pt" height="16.000000pt" viewBox="0 0 189.000000 201.000000"
         preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,201.000000) scale(0.084210,-0.079602)"
           fill="#000000" stroke="none">
            <path d="M37 1900 c-14 -11 -28 -33 -32 -49 -7 -33 15 -85 40 -95 22 -8 1778
    -8 1800 0 25 10 47 62 40 95 -4 16 -18 38 -32 49 -25 20 -39 20 -908 20 -869
0 -883 0 -908 -20z"/>
            <path d="M42 1493 c-7 -3 -19 -18 -28 -35 -20 -38 -10 -81 23 -108 25 -20 40
    -20 607 -20 626 0 619 0 644 53 16 34 1 92 -28 107 -19 10 -1193 13 -1218 3z"/>
            <path d="M37 1070 c-45 -36 -44 -102 2 -137 12 -10 222 -12 908 -13 l891 0 27
32 c32 38 29 81 -11 116 l-25 22 -883 0 c-870 0 -884 0 -909 -20z"/>
            <path d="M1107 718 c-76 -21 -134 -108 -125 -186 3 -26 6 -27 66 -30 l62 -3 0
30 c0 70 34 104 76 76 19 -12 24 -24 24 -58 0 -58 -33 -120 -135 -255 -85
-112 -85 -113 -85 -172 l0 -60 180 0 180 0 0 55 0 54 -105 3 -106 3 76 100
c95 126 118 173 123 252 7 112 -48 185 -148 196 -25 3 -62 1 -83 -5z"/>
            <path d="M642 699 c-43 -20 -43 -21 -40 -67 l3 -47 42 3 43 4 2 -264 3 -263
63 -3 62 -3 0 331 0 330 -67 -1 c-45 0 -83 -7 -111 -20z"/>
            <path d="M1500 666 l0 -55 95 -3 94 -3 -59 -72 c-33 -40 -60 -76 -60 -81 0 -4
13 -21 29 -36 22 -21 34 -26 55 -20 19 4 33 1 46 -11 17 -15 20 -31 20 -106 0
-79 -2 -89 -22 -103 -29 -20 -34 -20 -58 4 -12 12 -20 33 -20 55 l0 35 -66 0
-67 0 6 -55 c11 -109 71 -160 185 -159 94 1 148 48 163 146 19 117 -12 215
-77 249 l-31 15 59 74 c56 71 59 76 56 124 l-3 51 -172 3 -173 2 0 -54z"/>
            <path d="M50 667 c-62 -32 -62 -118 0 -150 30 -15 57 -17 203 -15 l169 3 24
28 c31 36 31 78 0 114 l-24 28 -174 3 c-127 2 -180 -1 -198 -11z"/>
            <path d="M42 223 c-7 -3 -19 -18 -28 -35 -20 -38 -10 -81 23 -108 24 -18 40
    -20 200 -20 l175 0 30 30 c26 27 30 36 25 68 -11 68 -25 72 -231 71 -99 0
-187 -3 -194 -6z"/>
        </g>
    </svg>
);

const IconYes = (
    <span className={`icon-yes-no icon-yes`}>
        Yes
    </span>
);

export {
    IconArrowDown,
    IconAttachment,
    IconChain,
    IconCircleCheckAnimated,
    IconClock,
    IconClose,
    IconComment,
    IconCopy,
    IconCross,
    IconCrossAnimated,
    IconCrossThin,
    IconCV,
    IconDisguiseVoice,
    IconDomino,
    IconExclamationMark,
    IconFile,
    IconFilter,
    IconFunnel,
    IconGlobe,
    IconHamburger,
    IconHideVideo,
    IconInformation,
    IconInformationAnimated,
    IconLive,
    IconMail,
    IconMailDelivered,
    IconMailOpen,
    IconNo,
    IconPageUp,
    IconParagraph,
    IconPencil,
    IconPlay,
    IconPlus,
    IconProfile,
    IconQuestionMark,
    IconQuestionMarkAnimated,
    IconRefresh,
    IconSearch,
    IconSettings,
    IconShare,
    IconShowVideo,
    IconSort,
    IconStar,
    IconStarSlash,
    IconTextBubble,
    IconThumbDown,
    IconThumbUp,
    IconTime,
    IconTick,
    IconTickThin,
    IconTickThinImport,
    IconTickWithCircle,
    IconTrash,
    IconWordCount,
    IconYes,

    LogoAlcamiBlue,
    LogoInsideSherpa,
    LogoJobAdder,
    LogoPageUp,
    LogoSAP,
    LogoSmartRecruiters
};