import React from 'react';
import * as PropTypes from 'prop-types';
import {getInterviewSteps} from '../../api/';
import CustomModal from '../CustomModal';
import QuestionItem from "../JobDraft/StepQuestions/QuestionItem";
import {
    eInterviewStepType,
    eJobStageTypes,
    eQuestionDisplayType,
    objectToArray
} from "../../utils";
import Loader from "../Loader";

import './style.scss';


class InterviewStructure extends React.Component {
    constructor(props) {
        super(props);

        this.retrieveInterviewSteps = this.retrieveInterviewSteps.bind(this);
        this.onClose = this.onClose.bind(this);

        this.state = {
            interviewSteps: null
        };
    }

    async retrieveInterviewSteps() {
        const { job_id, job_stage_type, job_stage_id, candidate_id } = this.props;
        const interviewSteps = await getInterviewSteps(job_id, job_stage_type, job_stage_id, candidate_id);

        this.setState({
            interviewSteps
        });

    }

    onClose() {
        const { onClose } = this.props;
        onClose?.();
    }

    async componentDidMount() {
        await this.retrieveInterviewSteps();
    }

    render() {
        const { interviewSteps } = this.state;
        const { title, interview_id } = this.props;

        return (
            <CustomModal
                title={title}
                classes={`interview-structure open`}
                onClose={this.onClose}>
                <div className="questions">
                    {!interviewSteps && (
                        <Loader />
                    )}
                    {interviewSteps && interviewSteps
                        .filter(({interview_step_type}) => interview_step_type === eInterviewStepType.QUESTION)
                        .map((interview_step) => {
                            const { job_interview_step_id } = interview_step;
                            return (
                                <QuestionItem
                                    keyProp={job_interview_step_id}
                                    displayType={eQuestionDisplayType.VIEWING}
                                    item={{
                                        ...interview_step
                                    }}
                                    onChange={this.onQuestionItemInputChange}
                                />
                            );
                        })
                    }
                </div>
                <div className={`interview_id`}>Interview ID: {interview_id}</div>
                <div className={`actions`}>
                    <button
                        type="button"
                        className="btn"
                        onClick={this.onClose}
                    >
                        OK
                    </button>
                </div>
            </CustomModal>
        );
    }
}

InterviewStructure.propTypes = {
    title: PropTypes.string,
    job_id: PropTypes.number,
    job_stage_type: PropTypes.oneOf(objectToArray(eJobStageTypes)),
    job_stage_id: PropTypes.number,
    interview_id: PropTypes.number,
    candidate_id: PropTypes.number,
    onClose: PropTypes.func
};

InterviewStructure.defaultProps = {
    title: `Interview Structure`,
    job_id: null,
    job_stage_type: null,
    job_stage_id: null,
    interview_id: null,
    candidate_id: null,
    onClose: null
};

export default InterviewStructure;
