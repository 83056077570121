import React from "react";
import * as PropTypes from "prop-types";
import CustomTextField from "../../Form/CustomTextField";
import { IconTrash } from "../../Icons";
import CustomDatePicker from "../../Pickers/CustomDatePicker";
import {
    convertDateToCommonFormat,
    convertDayToCommonFormat,
    eDeadlineDay,
    eDropdownTypes,
    eUserSettings,
    generateTemporaryId,
    getLaterDate,
    isAdminUserSettingEnabled
} from "../../../utils";
import { isEqual } from "lodash";
import TypedDropdown from "../../TypedDropdown";

import './style.scss';


class Candidate extends React.Component {
    constructor(props) {
        super(props);

        this.changeRecord = this.changeRecord.bind(this);
        this.updateRecord = this.updateRecord.bind(this);

        const { first_name, last_name, email, phone, deadline, deadline_timezone } = props.candidate;

        this.state = {
            first_name: first_name || "",
            last_name: last_name || "",
            email: email || "",
            phone: phone || "",
            deadline,
            deadline_timezone,
            temporaryID: generateTemporaryId()
        };
    }

    changeRecord(e, autoUpdate = false) {
        const { target: { id, value} } = e;
        // eslint-disable-next-line no-unused-vars
        const [ fieldId ] = id.split("-");

        this.setState({
            [fieldId]: value
        },() => {
            if (autoUpdate) {
                this.updateRecord();
            }
        });
    }

    updateRecord() {
        const { onUpdate, candidate : originalRecord } = this.props;
        const updatedRecord = {...originalRecord, ...this.state};

        delete updatedRecord.temporaryID;

        if (!!onUpdate && !isEqual(updatedRecord, originalRecord)) {
            onUpdate(updatedRecord);
        }
    }

    render() {
        const {
            classes,
            candidate,
            isEmailEditable,
            isRemovable,
            onRemove
        } = this.props;

        const { isEmailError, isEmailDuplicate, deadline_timezone } = candidate;
        const { first_name, last_name, email, phone, deadline, temporaryID } = this.state;

        const minimumDeadline = getLaterDate(deadline, convertDayToCommonFormat(eDeadlineDay.YESTERDAY));
        const showCandidateDeadlineTimezone = isAdminUserSettingEnabled(eUserSettings.CANDIDATE_DEADLINE_TIMEZONE);

        return (
            <div className={`candidate ${classes} has-candidate-deadline-timezone-${showCandidateDeadlineTimezone}`}>
                <div className={`first-name`}>
                    <CustomTextField
                        classes={`edit-mode`}
                        id={`first_name-${temporaryID}`}
                        required={true}
                        value={first_name}
                        onChange={this.changeRecord}
                        onBlur={this.updateRecord}
                    />
                </div>
                <div className={`last-name`}>
                    <CustomTextField
                        classes={`edit-mode`}
                        id={`last_name-${temporaryID}`}
                        required={true}
                        value={last_name}
                        onChange={this.changeRecord}
                        onBlur={this.updateRecord}
                    />
                </div>
                <div className={`email ${isEmailDuplicate ? 'duplicate' : ''}`}>
                    {isEmailEditable && (
                        <CustomTextField
                            classes={`edit-mode`}
                            id={`email-${temporaryID}`}
                            required={true}
                            value={email}
                            isWarning={isEmailDuplicate}
                            isError={isEmailError}
                            onChange={(e) => this.changeRecord(e, true)}
                            onBlur={this.updateRecord}
                        />
                    )}
                    {!isEmailEditable && (
                        <div className={`read-only`}>{email}</div>
                    )}
                </div>
                <div className={`phone`}>
                    <CustomTextField
                        classes={`edit-mode`}
                        id={`phone-${temporaryID}`}
                        required={false}
                        value={phone}
                        onChange={this.changeRecord}
                        onBlur={this.updateRecord}
                    />
                </div>
                <div className={`interview-closing-time`}>
                    <CustomDatePicker
                        id={`deadline-${temporaryID}`}
                        classes={`deadline edit-mode`}
                        required={false}
                        label=""
                        value={deadline}
                        minDate={minimumDeadline}
                        minMessage=""
                        onChange={(e) => this.changeRecord({
                            target: {
                                id: `deadline`,
                                value: convertDateToCommonFormat(e)
                            }
                        }, true)}
                    />
                </div>
                {showCandidateDeadlineTimezone && (
                    <TypedDropdown
                        id={"deadline_timezone"}
                        classes={'deadline-timezone edit-mode'}
                        dropdownType={eDropdownTypes.TIMEZONES}
                        required={false}
                        initialValue={deadline_timezone}
                        isMultipleSelection={false}
                        showDropdownOnInitialFocus={true}
                        onUpdate={(e) => this.changeRecord(e, true)}
                    />
                )}
                {isRemovable && (
                    <div className={`remove`} onClick={() => onRemove(candidate)}>{IconTrash}</div>
                )}
                {!isRemovable && (
                    <div>&nbsp;</div>
                )}
            </div>
        );
    }
}

Candidate.propTypes = {
    classes: PropTypes.string,
    candidate: PropTypes.object,
    jobTimezone: PropTypes.string,
    onUpdate: PropTypes.func,
    isEmailEditable: PropTypes.bool,
    isRemovable: PropTypes.bool,
    onRemove: PropTypes.func
};

Candidate.defaultProps = {
    classes: "",
    candidate: {},
    jobTimezone: "",
    onUpdate: null,
    isEmailEditable: true,
    isRemovable: true,
    onRemove: null
};

export default Candidate;
