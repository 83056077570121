import React from 'react';
import * as moment from 'moment';

import { getCompanyDetails, getReportingUsageUrl } from '../../../api';

import Loader, { eLoaderSize } from "../../../components/Loader";
import Container from "../../../components/Container";
import CustomRadioButton from "../../../components/Form/CustomRadioButton";

import './style.scss';


const TenantType = {
  CURRENT: "current",
  ALL: "all"
}

class Reporting extends React.Component {
  constructor(props) {
    super(props);

    this.loadCompany = this.loadCompany.bind(this);
    this.onInputChange = this.onInputChange.bind(this);

    const start_at = moment().startOf('month').format('YYYY-MM-DD');
    const end_at = moment().endOf('month').format('YYYY-MM-DD');

    this.state = {
      loading: true,
      company: null,
      tenant: TenantType.CURRENT,
      start_at,
      end_at
    };
  }

  componentDidMount() {
    this.loadCompany();
  }

  async loadCompany() {
    const company = await getCompanyDetails();
    this.setState({
      loading: false,
      company
    });
  }

  onInputChange(e) {
    const { target: {id, value} } = e;
    this.setState({
      [id]: value
    });
  }

  render() {
    const { loading, company, tenant, start_at, end_at } = this.state;
    const { default_timezone } = (company || {});

    if (!this.state.company) {
      return (
        <div className="reporting">
          <Loader size={eLoaderSize.PAGE_WITH_HEADER} />
        </div>
      );
    }

    const shallShowTenantSelection = company.tenant_id === "alcami";

    return (
      <Container loading={loading}>
        <div className="reporting">
          <div className="reporting-usage">
            <h2>Completed interviews (usage)</h2>
            <form className="reporting-interview-usage" method="get" action={getReportingUsageUrl(tenant === TenantType.ALL)}>
              {shallShowTenantSelection && (
                  <div className={`tenant-selection`}>
                    <span>Tenant Type:</span>
                    <CustomRadioButton
                        id={`tenant-${TenantType.CURRENT}`}
                        classes={`tenant-${TenantType.CURRENT}`}
                        value={TenantType.CURRENT}
                        selected={tenant === TenantType.CURRENT}
                        onChange={() => this.onInputChange({target: {id: "tenant", value: TenantType.CURRENT}})}
                    />
                    <CustomRadioButton
                        id={`tenant-${TenantType.ALL}`}
                        classes={`tenant-${TenantType.ALL}`}
                        value={TenantType.ALL}
                        selected={tenant === TenantType.ALL}
                        onChange={() => this.onInputChange({target: {id: "tenant", value: TenantType.ALL}})}
                    />
                  </div>
              )}
              <label>Start at <input type="date" defaultValue={start_at} name="start_at" /> @ 00:00</label>
              <label>End at <input type="date" defaultValue={end_at} name="end_at" /> @ 23:59</label>
              <label>
                Timezone
                { default_timezone === 'Australia/Sydney' && (
                    <input name="timestamp" type="text" defaultValue="Australia/Sydney" disabled={true} />
                )}
                { default_timezone !== 'Australia/Sydney' && (
                    <select name="timestamp" defaultValue={default_timezone}>
                      <option value={default_timezone}>{default_timezone}</option>
                      <option value="Australia/Sydney">Australia/Sydney</option>
                    </select>
                )}
              </label>
              <div className="buttons">
                <button type="submit">Download CSV</button>
              </div>
            </form>
          </div>
        </div>
      </Container>
    );
  }
}

export default Reporting;
