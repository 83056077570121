import React from "react";
import PropTypes from 'prop-types';
import {IconRefresh, IconSettings} from "../../../../components/Icons";
import CustomTextField from "../../../../components/Form/CustomTextField";
import Dropdown from "../../../../components/Dropdown";
import CustomDatePicker from "../../../../components/Pickers/CustomDatePicker";
import {
    convertDateToCommonFormat,
    DEFAULT_CANDIDATE_FOR_EMAIL_PREVIEW,
    deformatTemplateContentPlaceholders,
    deformatTemplateSubjectPlaceholders,
    eInterviewTerminologies,
    objectToArray
} from "../../../../utils";
import CustomModal from "../../../../components/CustomModal";
import {capitalize} from "lodash";
import {previewEmail} from "../../../../api";

import './style.scss';


const PREVIEW_SETTINGS_TYPES = {
    JOB: "job",
    CANDIDATE_INTERVIEW: "candidate_interview"
};

class EmailTemplatePreview extends React.Component {
    constructor(props) {
        super(props);

        this.generatePreview = this.generatePreview.bind(this);
        this.togglePreviewSettings = this.togglePreviewSettings.bind(this);
        this.getTerminologyTypes = this.getTerminologyTypes.bind(this);
        this.onPreviewSettingsChanged = this.onPreviewSettingsChanged.bind(this);

        this.state = {
            isLoading: true,
            isPreviewSettingsShown: false,
            preview: undefined,
            previewSettings: {
                job: this.props.job,
                candidate_interview: this.props.candidate,
            }
        };
    }

    async generatePreview() {
        this.setState({
            isLoading: true
        });

        const { previewSettings: { job, candidate_interview } } = this.state;
        const { template: { subject, content, header_banner_img_src, footer_banner_img_src } } = this.props;
        const preview = await previewEmail(
            job,
            candidate_interview,
            deformatTemplateSubjectPlaceholders(subject),
            deformatTemplateContentPlaceholders(content),
            header_banner_img_src || null,
            footer_banner_img_src || null
        );
        this.setState({
            isLoading: false,
            preview
        });
    }

    togglePreviewSettings() {
        const { isPreviewSettingsShown } = this.state;

        this.setState({
            isPreviewSettingsShown: !isPreviewSettingsShown
        });

        if (isPreviewSettingsShown) {
            this.generatePreview();
        }
    }

    getTerminologyTypes() {
        const { previewSettings: { job: { interview_terminology = null } } = {} } = this.state;
        return objectToArray(eInterviewTerminologies).map((type) => {
            return {
                id: type,
                firstLine: capitalize(type),
                selected: type === interview_terminology,
                onClick: () => this.onPreviewSettingsChanged(PREVIEW_SETTINGS_TYPES.JOB, {target: {id: "interview_terminology", value: type}})
            };
        });
    }

    onPreviewSettingsChanged(type, e) {
        const { target: { id, value } } = e;
        const { previewSettings } = this.state;

        const updatedState = {
            previewSettings: {...previewSettings}
        };

        updatedState.previewSettings[type][id] = value;

        this.setState(updatedState);
    }

    componentDidMount() {
        this.generatePreview();
    }

    render() {
        const {
            isPreviewSettingsShown,
            preview: {
                subject,
                html
            } = {},
            previewSettings: {
                job,
                candidate_interview
            }
        } = this.state;
        const {
            hideSettings
        } = this.props;

        return (
            <React.Fragment>
                <div className={`email-template-preview`}>
                    <div className={`header ${hideSettings ? 'hide-settings' : ''}`}>
                        <div className={`subject`}>
                            {subject}
                        </div>
                        {!hideSettings && (
                            <React.Fragment>
                                <div
                                    className={`action refresh-preview`}
                                    onClick={this.generatePreview}
                                >
                                    {IconRefresh}
                                </div>
                                <div
                                    className={`action preview-settings-link`}
                                    onClick={this.togglePreviewSettings}
                                >
                                    {IconSettings}
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                    <iframe
                        className={`preview-iframe`}
                        title="New template preview"
                        srcDoc={html}
                    />
                </div>
                {isPreviewSettingsShown && (
                    <CustomModal
                        title={`Preview Settings`}
                        classes={`preview-settings open`}
                        onClose={this.togglePreviewSettings}
                    >
                        <div className={`job-settings`}>
                            <div className={`header`}>Job</div>
                            <CustomTextField
                                classes={`job-title`}
                                id={`title`}
                                name={`job-title`}
                                label={`Job Title`}
                                value={job.title}
                                onChange={(e) => this.onPreviewSettingsChanged(PREVIEW_SETTINGS_TYPES.JOB, e)}
                            />
                            <Dropdown
                                required={true}
                                styles="floating-label fixed-label"
                                classes="terminology_dropdown"
                                label="Terminology"
                                items={this.getTerminologyTypes()}
                            />
                        </div>

                        <div className={`interview-settings`}>
                            <div className={`header`}>Interview</div>
                            <CustomTextField
                                classes={`email`}
                                id={`email`}
                                name={`email`}
                                label={`Email`}
                                value={candidate_interview.email}
                                onChange={(e) => this.onPreviewSettingsChanged(PREVIEW_SETTINGS_TYPES.CANDIDATE_INTERVIEW, e)}
                            />
                            <CustomTextField
                                classes={`first_name`}
                                id={`first_name`}
                                name={`first_name`}
                                label={`First Name`}
                                value={candidate_interview.first_name}
                                onChange={(e) => this.onPreviewSettingsChanged(PREVIEW_SETTINGS_TYPES.CANDIDATE_INTERVIEW, e)}
                            />
                            <CustomDatePicker
                                classes={`deadline`}
                                id={`deadline`}
                                label={`Deadline`}
                                value={candidate_interview.deadline}
                                onChange={(e) => this.onPreviewSettingsChanged(
                                    PREVIEW_SETTINGS_TYPES.CANDIDATE_INTERVIEW,
                                    {
                                        target: {
                                            id: `deadline`,
                                            value: convertDateToCommonFormat(e)
                                        }
                                    }
                                )}
                            />
                        </div>
                    </CustomModal>
                )}
            </React.Fragment>
        );
    }
}

EmailTemplatePreview.propTypes = {
    template: PropTypes.shape({
       subject: PropTypes.string,
       content: PropTypes.object,
       header_banner_img_src: PropTypes.string,
       footer_banner_img_src: PropTypes.string
    }),
    job: PropTypes.object,
    candidate: PropTypes.object,
    hideSettings: PropTypes.bool
};

EmailTemplatePreview.defaultProps = {
    template: {
        subject: "",
        content: null,
        header_banner_img_src: "",
        footer_banner_img_src: ""
    },
    job: {
        title: 'Customer Support Representative',
        interview_terminology: 'interview'
    },
    candidate: DEFAULT_CANDIDATE_FOR_EMAIL_PREVIEW,
    hideSettings: false
};

export default EmailTemplatePreview;
