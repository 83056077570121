import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';


const SubNav = ({ classes, activeRoute, activeRouteState, urlFor, mainItems = [], rightItems = [] }) => {
    return (
        <div className={`sub-nav-container ${classes}`}>
            <div className="sub-nav-main">
                {mainItems
                    .map(({classes: c, label, route, routeState = {}, isActiveForRouteState = null, onClick}, index) => {
                        const isItemActive = (activeRoute === route) && (!isActiveForRouteState || isActiveForRouteState(activeRouteState));
                        if (onClick) {
                            return (
                                <button
                                    key={index}
                                    className={`link sub-nav-item ${c} ${isItemActive ? 'selected' : ''}`}
                                    onClick={onClick}
                                >
                                   {label}
                                </button>
                            );
                        } else {
                            const href = urlFor(route, routeState);
                            if (href) {
                                return (
                                     <a
                                         key={index}
                                         className={`sub-nav-item ${classes} ${isItemActive ? 'selected' : ''}`}
                                         href={href}
                                         onClick={onClick}
                                     >
                                         {label}
                                     </a>
                                );
                            } else {
                                return null;
                            }
                        }
                    })
                    .filter((el) => !!el)
                }
            </div>
            <div className="sub-nav-right">
                {rightItems.map(
                    ({classes: c, label, route, onClick}, index) => (
                        <a
                            key={index}
                            className={`sub-nav-item ${c} ${activeRoute === route ? 'selected' : ''}`}
                            href={route ? urlFor(route, {}) : undefined}
                            onClick={onClick}
                        >
                            {label}
                        </a>
                    )
                )}
            </div>
        </div>
    )
};

const itemPropTypes = PropTypes.shape({
    classes: PropTypes.string,
    label: PropTypes.string.isRequired,
    // Require either `route` or `onClick`
    route: PropTypes.string,
    routeState: PropTypes.object,
    isActiveForRouteState: PropTypes.func,
    onClick: PropTypes.func
});

SubNav.propTypes = {
    activeRoute: PropTypes.string.isRequired,
    activeRouteState: PropTypes.object,
    mainItems: PropTypes.arrayOf(itemPropTypes),
    rightItems: PropTypes.arrayOf(itemPropTypes),
    urlFor: PropTypes.func.isRequired
};

export default SubNav;
