import React from 'react';
import * as PropTypes from "prop-types";
import CustomModal from '../CustomModal';
import QuestionThumbnail from "../QuestionThumbnail";
import { get } from "lodash";
import {eMediaTypes, generateTemporaryId, isSafari} from "../../utils";

import './style.scss';


/**
 * The source element is supposed to support media types like
 * 'video/webm;codecs=h264', but your mileage may vary.
 */
function fixMimeType(mimeType) {
    if (!mimeType) {
        return mimeType;
    }

    const idx = mimeType.indexOf(';');
    return idx < 0 ? mimeType : mimeType.substring(0, idx);
}


class MediaPreview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sizeOverride: null,
            playbackSelected: false,
            playbackSpeed: 1,
            allowThumbnailAutoScroll: true
        };

        this.resizeVideo = this.resizeVideo.bind(this);
        this.scrollToVideo = this.scrollToVideo.bind(this);
        this.pauseOtherMedia = this.pauseOtherMedia.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

    }

    scrollToVideo(distance) {
        const { allowThumbnailAutoScroll } = this.state;
        if (allowThumbnailAutoScroll) {
            const el = document.getElementsByClassName("thumbnails");
            if (el && el[0]) {
                el[0].scrollLeft = distance;
                this.setState({
                    allowThumbnailAutoScroll: false
                });
            }
        }
    }

    resizeVideo() {
        const { videoHeight, videoWidth } = this.media;
        if (videoHeight > videoWidth) {
            this.setState({
                sizeOverride: "portrait"
            });
        }
    }

    setPlaybackSpeed(speed = 1) {
        this.media.playbackRate = speed;
        this.setState({
            playbackSelected: !this.state.playbackSelected,
            playbackSpeed: speed
        });
    }

    pauseOtherMedia() {
        const allMedia = Array.from(document.querySelectorAll("video, audio"));
        allMedia.forEach((media) => {
            if (media !== this.media) {
                media.pause();
            }
        });
    }

    componentDidMount() {
        const { items } = this.props;

        // only add observer for scrollable view
        if (!items || !items.length) {
            const observer = new IntersectionObserver((entries) => {
                const entry = entries[0];
                if(!entry.isIntersecting && this.media) {
                    this.media.pause();
                }
            }, { threshold: [0] });

            observer.observe(this.media);
        }

        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate() {
        const { pause } = this.props;
        if (!!pause) {
            this.media.pause();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(e) {
        const targetClassName = e.target.className;
        if (!targetClassName ||
            (
                !!targetClassName &&
                typeof targetClassName === "string" &&
                !targetClassName.includes("media-speed-control") &&
                !targetClassName.includes("playback-speed")
            )
        ) {
            this.setState({
                playbackSelected: false
            });
        }
    }

    render() {
        const {
            classes = "",
            media = [], // for video response's different formats
            mediaURL,
            thumbnail,
            // mediaType = "video/mp4",
            onClose,
            showPlaybackSpeed = false,
            // rawMediaURL,
            // rawMediaType,

            items,
            displayText,
            selectedItemId,
            onChange,
            isInline = false,
            autoplay
        } = this.props;

        const { sizeOverride } = this.state;

        // if have items, get the selected video
        const selectedVideoFromItems = items.length ?
            items.find((item) => {
                const {
                    stock_library_question_video_id,
                    tenant_library_question_video_id
                } = item;
                const itemId = stock_library_question_video_id || tenant_library_question_video_id;
                return itemId === selectedItemId;
            }) :
            null;

        const selectedVideoIndex = items.findIndex((item) => item === selectedVideoFromItems);

        const areMultipleVideos = !!items && items.length > 1;

        const shownVideo = (selectedVideoFromItems || {}).video || mediaURL;

        const firstMedia = get(media, [0], []),
            firstMediaType = get(firstMedia, "type", ""),
            isAudioFile = [eMediaTypes.AUDIO, eMediaTypes.DISGUISED_VOICE].includes(firstMediaType);

        const key = selectedItemId || shownVideo || (media || [])[0].url || generateTemporaryId();

        const content = (
            <React.Fragment>
                {areMultipleVideos && (
                    <div className={`question-thumbnails`}>
                        <div className={`thumbnails`}
                             onLoad={() => setTimeout(() => {this.scrollToVideo(selectedVideoIndex * (54 + 4) - 27)}, 0)}
                        >
                            {items.map((item) => {
                                const {
                                    stock_library_question_video_id,
                                    tenant_library_question_video_id,
                                    image,
                                    video
                                } = item;
                                const videoId = stock_library_question_video_id || tenant_library_question_video_id;

                                return (
                                    <QuestionThumbnail
                                        key={videoId}
                                        image={image}
                                        text={displayText}
                                        mediaURL={video}
                                        selected={videoId === selectedItemId}
                                        onClick={() => onChange(videoId, areMultipleVideos)}
                                        onSelect={() => onChange(videoId, areMultipleVideos)}
                                    />
                                );
                            })}
                        </div>
                    </div>
                )}

                {!!displayText && (
                    <div className={`display-text`}>{displayText}</div>
                )}

                <div className={`media-container ${sizeOverride ? sizeOverride : ''}`}>
                    {!isAudioFile && (
                        <video
                            key={key}
                            ref={media => this.media = media}
                            width="100%"
                            height="auto"
                            controls
                            disablePictureInPicture={true}
                            controlsList="nodownload noremoteplayback disablepictureinpicture"
                            onCanPlay={!sizeOverride ? this.resizeVideo : () => null}
                            onPlay={this.pauseOtherMedia}
                            autoPlay={autoplay}
                            poster={thumbnail}
                        >
                            {!!shownVideo && (
                                <source
                                    src={shownVideo}
                                    type="video/mp4"
                                />
                            )}
                            {(media || []).map(({url, mimeType}) => (
                                <source
                                    key={`source-${url}`}
                                    src={url}
                                    type={fixMimeType(mimeType)}
                                />
                            ))}
                        </video>
                    )}
                    {isAudioFile && (
                        <audio
                            key={key}
                            ref={media => this.media = media}
                            controls
                            controlsList={`nodownload`}
                            onPlay={this.pauseOtherMedia}
                            autoPlay={autoplay}
                        >
                            {(media || []).map(({url, mimeType}) => (
                                <source
                                    key={url}
                                    src={url}
                                    type={fixMimeType(mimeType)}
                                />
                            ))}
                        </audio>
                    )}
                    {showPlaybackSpeed && isSafari() && (
                        <div className={`media-speed-control ${this.state.playbackSelected ? 'selection-active' : ''}`}>
                            {[0.75,1,1.25,1.5].map((speed) => {
                                return (
                                    <span
                                        key={speed}
                                        data-current-speed={this.state.playbackSpeed}
                                        data-speed={speed}
                                        className={`playback-speed ${this.state.playbackSpeed === speed ? 'active' : ''}`}
                                        onClick={()=> this.setPlaybackSpeed(speed)}
                                    >
                                    {speed}x
                                </span>
                                )
                            })}
                        </div>
                    )}
                </div>
            </React.Fragment>
        );

        return (
            <React.Fragment>
                {isInline && (
                    <div className={`media-preview ${classes} ${isAudioFile ? 'audio' : ''} open inline`}>
                        {isAudioFile && (
                            <span className={`audio-message`}>This is an audio response.</span>
                        )}
                        {content}
                    </div>
                )}
                {!isInline && (
                    <CustomModal
                        classes={`media-preview ${classes} open ${isAudioFile ? 'audio' : ''} ${sizeOverride ? sizeOverride : 'landscape'} ${areMultipleVideos ? 'has-thumbnails' : ''}`}
                        onClose={onClose}>
                        {content}
                    </CustomModal>
                )}
            </React.Fragment>
        );
    }
}

MediaPreview.propTypes = {
    classes: PropTypes.string,
    media: PropTypes.array,
    mediaURL: PropTypes.string,
    mediaType: PropTypes.string,
    onClose: PropTypes.func,
    showPlaybackSpeed: PropTypes.bool,
    // rawMediaURL: PropTypes.string,
    // rawMediaType: PropTypes.string,

    items: PropTypes.array,
    displayText: PropTypes.string,
    selectedItemId: PropTypes.number,
    onChange: PropTypes.func,
    isInline: PropTypes.bool,
    autoplay: PropTypes.bool,
    pause: PropTypes.bool
};

MediaPreview.defaultProps = {
    classes: "",
    media: [],
    mediaURL: "",
    mediaType: "video/mp4",
    onClose: null,
    showPlaybackSpeed: false,
    // rawMediaURL: "",
    // rawMediaType: "",

    items: [],
    displayText: "",
    selectedItemId: null,
    onChange: null,
    isInline: false,
    autoplay: true,
    pause: false
};

export default MediaPreview;
