import React from "react";
import * as PropTypes from "prop-types";
import CustomTextField from "../CustomTextField";
import {convertToRGBA, isValidColor, objectToArray} from "../../../utils";
import { compact, isEqual } from "lodash";

import './style.scss';
import {IconTick} from "../../Icons";


export const eColorPickerButtonShapes = {
    SQUARE: "square",
    CIRCLE: "circle"
};

export const ePresetColorsStyles = {
    GRID: "grid",
    CIRCLE: "circle"
};

class CustomColorPicker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            displayColorPicker: false
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.onColorPickerChange = this.onColorPickerChange.bind(this);
    }

    handleClick() {
        const { displayColorPicker } = this.state;
        this.setState({ displayColorPicker: !displayColorPicker })
    }

    handleClose() {
        this.setState({ displayColorPicker: false })
    }

    onColorPickerChange(id, name, _e, opacity = 1) {
        const { onChange } = this.props;
        const value = `rgba(var(${name}), ${opacity})`;
        onChange({target: { id, value }});
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(e) {
        const { presetColors } = this.props;

        if (!!presetColors.length) {
            const targetClassName = e.target.className;
            if (!targetClassName ||
                (
                    typeof targetClassName === "string" &&
                    !targetClassName.includes("preset-color")
                )
            ) {
                this.handleClose();
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
    }

    render() {
        const {
            id,
            classes,
            showManualInput = false,
            disableColorPicker,
            colorPickerButtonShape,
            colorPickerPosition,
            presetColors,
            presetColorsStyle,
            required = false,
            label = "",
            value,
            onChange
        } = this.props;

        const filteredPresetColors = compact(presetColors.map((color) => {
            const { value } = color;
            return !!value ? {...color, value } : null;
        }));

        return (
            <div className={`customColorPicker ${classes || ''} ${!!showManualInput ? 'has-manual-input' : ''}`}>
                {!!showManualInput && (
                    <CustomTextField
                        id={id}
                        classes={`customColorPickerManualInput`}
                        required={required}
                        label={label}
                        value={value || ""}
                        isError={!!value && !isValidColor(value)}
                        onChange={onChange}
                    />
                )}
                <button
                    className={`color-picker-button ${colorPickerButtonShape} ${!!disableColorPicker ? 'disabled' : ''}`}
                    onClick={!disableColorPicker ? this.handleClick : null}
                    style={{backgroundColor: value}}
                />
                {this.state.displayColorPicker ?
                    <div className={`color-picker preset-${presetColorsStyle} ${colorPickerPosition}`}>
                        <div className={`color-picker-arrow-border`}>
                            <div className={`color-picker-arrow`}>&nbsp;</div>
                        </div>
                        <div className={`preset-colors ${presetColorsStyle} width-${(filteredPresetColors.length)}`}>
                            {filteredPresetColors.map((color, i) => {
                                const { name, value : presetValue } = color;
                                if (presetColorsStyle === ePresetColorsStyles.CIRCLE) {
                                    return (
                                        <div
                                            key={`${presetColorsStyle}-${i}`}
                                            className={`preset-color`}
                                            style={{ backgroundColor: presetValue }}
                                            onClick={() => this.onColorPickerChange(id, name, presetValue)}
                                        >&nbsp;</div>
                                    );
                                } else {
                                    // ePresetColorsStyles.GRID
                                    const opacities = [1, 0.75, 0.55, 0.35, 0.15, 0.05];
                                    return (
                                        <div
                                            key={`${presetColorsStyle}-${i}`}
                                            className={`preset-color-group`}
                                        >
                                            {opacities.map((opacity, x) => {
                                                const isSelected = convertToRGBA(presetValue, opacity) === value;
                                                return (
                                                    <div
                                                        key={`${presetColorsStyle}-${i}-${x}`}
                                                        className={`preset-color-container`}
                                                    >
                                                        <span
                                                            className={`preset-color ${isSelected ? 'selected' : ''}`}
                                                            style={{ backgroundColor: convertToRGBA(presetValue, opacity) }}
                                                            onClick={() => this.onColorPickerChange(id, name, presetValue, opacity)}
                                                        >
                                                            &nbsp;
                                                            {isSelected && IconTick}
                                                        </span>
                                                    </div>
                                                );
                                            }
                                            )}
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </div> :
                    null
                }
            </div>
        );
    }
}

CustomColorPicker.propTypes = {
    id: PropTypes.string,
    classes: PropTypes.string,
    required: PropTypes.bool,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onChange: PropTypes.func,
    showManualInput: PropTypes.bool,
    disableColorPicker: PropTypes.bool,
    colorPickerButtonShape: PropTypes.oneOf(objectToArray(eColorPickerButtonShapes)),
    presetColors: PropTypes.array,
    presetColorsStyle: PropTypes.oneOf(objectToArray(ePresetColorsStyles)),
    colorPickerPosition: PropTypes.string
};

CustomColorPicker.defaultProps = {
    id: null,
    classes: "",
    required: false,
    label: "",
    value: "",
    onChange: null,
    showManualInput: false,
    disableColorPicker: false,
    colorPickerButtonShape: eColorPickerButtonShapes.CIRCLE,
    presetColors: [],
    presetColorsStyle: ePresetColorsStyles.GRID,
    colorPickerPosition: "bottom left"
};

export default CustomColorPicker;
