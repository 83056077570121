import React from "react";
import {compact, concat, slice} from "lodash";

import './style.scss';


class EvaluationAttributes extends React.Component {
    constructor(props) {
        super(props);

        this.setValuesFromProps = this.setValuesFromProps.bind(this);
        this.toggleEditFlag = this.toggleEditFlag.bind(this);
        this.onChange = this.onChange.bind(this);

        this.state = {
            isEditing: false,
            values: []
        };
    }

    componentDidMount() {
        this.setValuesFromProps();
    }

    componentDidUpdate(prevProps) {
        const { items: oldItems } = prevProps;
        const { items: newItems } = this.props;
        if (oldItems !== newItems) {
            this.setValuesFromProps();
        }
    }

    setValuesFromProps(autofill = false) {
        const { items } = this.props;

        let newValues = [];

        items.forEach((attribute) => {
            newValues.push(attribute);
        });

        if (autofill) {
            newValues = slice(concat(compact(newValues), "", "", "", "", ""), 0, 5);
        }

        this.setState({
            values: newValues
        });
    }

    toggleEditFlag() {
        const { id, isEditable, onSave } = this.props;
        if (isEditable) {
            const { isEditing, values } = this.state;

            if (isEditing) {
                const newValues = values.filter(value => !!(value || "").trim());

                // save
                onSave({ target: { id, value: newValues } });
                this.setState({
                    values: newValues
                });
            } else {
                // initialise
                this.setValuesFromProps(true);
            }

            this.setState({
                isEditing: !isEditing
            });
        }
    }

    onChange(e) {
        const { values } = this.state;
        const index = e.target.id;
        const value = e.target.value;
        const newValues = values;

        values[index] = value;

        this.setState({
            values: newValues
        });
    }

    render() {
        const { isEditable } = this.props;
        const { isEditing, values } = this.state;
        return (
            <div className="attributes">
                {values.map((value, index) => (
                    <input
                        key={index}
                        id={`${index}`}
                        type="text"
                        className={`${isEditable ? 'editable' : ''} ${!isEditing ? 'read-only' : ''}`}
                        disabled={!isEditable || !isEditing}
                        placeholder="Enter attribute here"
                        value={value}
                        onChange={this.onChange}
                    />
                ))}
                <div className={`action ${!isEditable ? 'disabled' : ''}`} onClick={this.toggleEditFlag}>
                    {isEditing ? "Save" : "Edit"}
                </div>
            </div>
        );
    }
}

export default EvaluationAttributes;
