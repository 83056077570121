import React from "react";
import * as PropTypes from "prop-types";
import CustomCheckbox, {eCustomCheckboxSize} from "../Form/CustomCheckbox";
import {eEmailTemplateTypes, JOB_STAGES_FIELDS} from "../../utils";
import {getEmailTemplates} from "../../api";
import Dropdown from "../Dropdown";
import {capitalize} from "lodash/string";
import EmailTemplateDetails from "../../pages/templates/email/details";

import './style.scss';


class EmailTemplateSelection extends React.Component {
    constructor(props) {
        super(props);

        this.toggleEmailSelection = this.toggleEmailSelection.bind(this);
        this.getEmailTemplatesAsDropdown = this.getEmailTemplatesAsDropdown.bind(this);
        this.setEmailTemplate = this.setEmailTemplate.bind(this);
        this.getEmailTemplateID = this.getEmailTemplateID.bind(this);
        this.toggleEmailTemplatePreview = this.toggleEmailTemplatePreview.bind(this);

        this.state = {
            emailTemplateItems: [],
            selectedTemplate: null
        };
    }

    toggleEmailSelection(type) {
        const { job_stage, onChange } = this.props;
        onChange?.({ target: { id: type, value: !job_stage[type] } });
    }

    getEmailTemplatesAsDropdown(type = eEmailTemplateTypes.INVITATION) {
        const { emailTemplateItems } = this.state;
        const selectedEmailTemplateId = this.getEmailTemplateID(type);
        return emailTemplateItems
            .filter(
                ({email_template_type}) => email_template_type === type
            )
            .map(({email_template_id, email_template_type}) => ({
                id: email_template_id,
                firstLine: `${capitalize(email_template_type)} Email Template #${email_template_id}`,
                selected: email_template_id === selectedEmailTemplateId,
                onClick: () => this.setEmailTemplate(email_template_type, email_template_id)
            }));
    }

    setEmailTemplate(type, value) {
        const { onChange } = this.props;
        const id =
            type === eEmailTemplateTypes.INVITATION ? JOB_STAGES_FIELDS.INVITATION_EMAIL_TEMPLATE_ID :
            type === eEmailTemplateTypes.ACKNOWLEDGEMENT ? JOB_STAGES_FIELDS.ACKNOWLEDGEMENT_EMAIL_TEMPLATE_ID :
            type === eEmailTemplateTypes.SUCCESSFUL ? JOB_STAGES_FIELDS.SUCCESSFUL_EMAIL_TEMPLATE_ID :
            type === eEmailTemplateTypes.UNSUCCESSFUL ? JOB_STAGES_FIELDS.UNSUCCESSFUL_EMAIL_TEMPLATE_ID :
                null;

        if (!!id) {
            onChange?.({ target: { id, value }});
        }
    }

    getEmailTemplateID(type) {
        const { job_stage } = this.props;
        return type === eEmailTemplateTypes.INVITATION ? job_stage[JOB_STAGES_FIELDS.INVITATION_EMAIL_TEMPLATE_ID] :
            type === eEmailTemplateTypes.ACKNOWLEDGEMENT ? job_stage[JOB_STAGES_FIELDS.ACKNOWLEDGEMENT_EMAIL_TEMPLATE_ID] :
            type === eEmailTemplateTypes.SUCCESSFUL ? job_stage[JOB_STAGES_FIELDS.SUCCESSFUL_EMAIL_TEMPLATE_ID] :
            type === eEmailTemplateTypes.UNSUCCESSFUL ? job_stage[JOB_STAGES_FIELDS.UNSUCCESSFUL_EMAIL_TEMPLATE_ID] :
            null;
    }

    toggleEmailTemplatePreview(type) {
        const { emailTemplateItems } = this.state;
        if (type) {
            const email_template_id = this.getEmailTemplateID(type);
            const template = emailTemplateItems.find((item) => item.email_template_id === email_template_id);

            this.setState({
                selectedTemplate: template
            })
        } else {
            this.setState({
                selectedTemplate: null
            });
        }
    }

    async componentDidMount() {
        this.mounted = true;

        const response = await getEmailTemplates();
        if (response.error) {
            // ToDo: show error here?
            return false;
        }

        if (this.mounted) {
            this.setState({
                emailTemplateItems: response
            });
        }

    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        const { selectedTemplate } = this.state;
        const {
            job,
            job_stage: {
                sends_invitation_email,
                sends_acknowledgement_email,
                sends_successful_email,
                sends_unsuccessful_email
            },
            candidate,
            is_open_registration
        } = this.props;

        return (
            <div className={`email-template-selection`}>
                <div className={`header`}>Emails</div>
                <div className={`subheader`}>Invitation emails are required for Open Registration.</div>
                <div className={`email-template-row ${sends_invitation_email ? '' : 'disabled'}`}>
                    <CustomCheckbox
                        id={`sends_invitation_email_checkbox`}
                        size={eCustomCheckboxSize.MEDIUM}
                        classes="email-checkbox"
                        checked={sends_invitation_email}
                        disabled={is_open_registration}
                        onChange={() => this.toggleEmailSelection(JOB_STAGES_FIELDS.SENDS_INVITATION_EMAIL)}
                    />
                    <div className={`type`}>Send <span className="email-type">invitation</span> email using</div>
                    <Dropdown
                        required={false}
                        classes="email-dropdown"
                        disabled={!sends_invitation_email}
                        items={this.getEmailTemplatesAsDropdown(eEmailTemplateTypes.INVITATION)}
                    />
                    <div className={`email-preview ${!sends_invitation_email ? 'disabled' : ''}`} onClick={sends_invitation_email ? () => this.toggleEmailTemplatePreview(eEmailTemplateTypes.INVITATION) : null}>Preview</div>
                </div>

                <div className={`email-template-row ${sends_acknowledgement_email ? '' : 'disabled'}`}>
                    <CustomCheckbox
                        id={`sends_acknowledgement_email_checkbox`}
                        size={eCustomCheckboxSize.MEDIUM}
                        classes="email-checkbox"
                        checked={sends_acknowledgement_email}
                        onChange={() => this.toggleEmailSelection(JOB_STAGES_FIELDS.SENDS_ACKNOWLEDGEMENT_EMAIL)}
                    />
                    <div className={`type`}>Send <span className="email-type">acknowledgement</span> email using</div>
                    <Dropdown
                        required={false}
                        classes="email-dropdown"
                        disabled={!sends_acknowledgement_email}
                        items={this.getEmailTemplatesAsDropdown(eEmailTemplateTypes.ACKNOWLEDGEMENT)}
                    />
                    <div className={`email-preview ${!sends_acknowledgement_email ? 'disabled' : ''}`} onClick={sends_acknowledgement_email ? () => this.toggleEmailTemplatePreview(eEmailTemplateTypes.ACKNOWLEDGEMENT) : null}>Preview</div>
                </div>

                <div className={`email-template-row ${sends_successful_email ? '' : 'disabled'}`}>
                    <CustomCheckbox
                        id={`sends_successful_email_checkbox`}
                        size={eCustomCheckboxSize.MEDIUM}
                        classes="email-checkbox"
                        checked={sends_successful_email}
                        onChange={() => this.toggleEmailSelection(JOB_STAGES_FIELDS.SENDS_SUCCESSFUL_EMAIL)}
                    />
                    <div className={`type`}>Send <span className="email-type">successful</span> email using</div>
                    <Dropdown
                        required={false}
                        classes="email-dropdown"
                        disabled={!sends_successful_email}
                        items={this.getEmailTemplatesAsDropdown(eEmailTemplateTypes.SUCCESSFUL)}
                    />
                    <div className={`email-preview ${!sends_successful_email ? 'disabled' : ''}`} onClick={sends_successful_email ? () => this.toggleEmailTemplatePreview(eEmailTemplateTypes.SUCCESSFUL) : null}>Preview</div>
                </div>

                <div className={`email-template-row ${sends_unsuccessful_email ? '' : 'disabled'}`}>
                    <CustomCheckbox
                        id={`sends_unsuccessful_email_checkbox`}
                        size={eCustomCheckboxSize.MEDIUM}
                        classes="email-checkbox"
                        checked={sends_unsuccessful_email}
                        onChange={() => this.toggleEmailSelection(JOB_STAGES_FIELDS.SENDS_UNSUCCESSFUL_EMAIL)}
                    />
                    <div className={`type`}>Send <span className="email-type">unsuccessful</span> email using</div>
                    <Dropdown
                        required={false}
                        classes="email-dropdown"
                        disabled={!sends_unsuccessful_email}
                        items={this.getEmailTemplatesAsDropdown(eEmailTemplateTypes.UNSUCCESSFUL)}
                    />
                    <div className={`email-preview ${!sends_unsuccessful_email ? 'disabled' : ''}`} onClick={sends_unsuccessful_email ? () => this.toggleEmailTemplatePreview(eEmailTemplateTypes.UNSUCCESSFUL) : null}>Preview</div>
                </div>

                {selectedTemplate && (
                    <EmailTemplateDetails
                        template={selectedTemplate}
                        job={job}
                        candidate={candidate}
                        isPreviewOnly={true}
                        onClose={this.toggleEmailTemplatePreview}
                    />
                )}
            </div>
        )
    }
}

EmailTemplateSelection.propTypes = {
    job: PropTypes.object,
    job_stage: PropTypes.object,
    candidate: PropTypes.object,
    onChange: PropTypes.func
};

EmailTemplateSelection.defaultProps = {
    job: PropTypes.object,
    job_stage: {},
    candidate: undefined,
    onChange: null
};

export default EmailTemplateSelection;