import React from "react";
import * as PropTypes from "prop-types";

import CustomTextArea from "../../Form/CustomTextArea";
import { eTextAreaType } from "../../../utils";

import "./style.scss";


class FinalComment extends React.Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    onChange(e) {
        const { is_read_only, onChange } = this.props;
        if (!is_read_only && onChange) {
            onChange(e, e.target.value);
        }
    }

    onBlur(e) {
        const { value, is_read_only, onUpdate } = this.props;
        if (!is_read_only && onUpdate) {
            onUpdate(e, value);
        }
    }

    render() {
        const { value, is_read_only } = this.props;
        return (
            <div className={`final-comment`}>
                <div className={`final-comment-title`}>Final Comment</div>
                <CustomTextArea
                    classes={`final-comment-content`}
                    type={eTextAreaType.STANDARD}
                    id="final-comment"
                    label=""
                    value={value}
                    readOnly={is_read_only}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                />
            </div>
        );
    }
}

FinalComment.propTypes = {
    value: PropTypes.string,
    is_read_only: PropTypes.bool,
    onChange: PropTypes.func,
    onUpdate: PropTypes.func
};

FinalComment.defaultProps = {
    value: "",
    is_read_only: false,
    onChange: null,
    onUpdate: null
};

export default FinalComment;