import React from "react";
import * as PropTypes from "prop-types";
import ErrorBoundary from "../../ErrorBoundary";
import ReactQuill, { Quill } from "react-quill";
import getPlaceholderModule from "quill-placeholder-module";
/*
 * Direct CSS import won't work until we have proper Webpack builds. For now, we
 * import via C0_admin.scss.
 */
//import "quill-placeholder-module/dist/toolbar.css";

import { eQuillToolbarSize, eTextAreaType, objectToArray } from "../../../utils";
import autosize from "autosize";

import './style.scss';


const Link = Quill.import('formats/link');
Link.sanitize = (url) => {
    if (url.substring(0, 4) === "www.") {
        return `https://${url}`;
    } else if (url.substring(0, 2) === "//") {
        return `https:${url}`;
    }

    return url;
}

Quill.register('modules/placeholder', getPlaceholderModule(Quill));


/*
 * Support for <small>, thanks:
 * https://github.com/quilljs/quill/issues/1109
 */
const Inline = Quill.import('blots/inline');
class Small extends Inline { }
Small.blotName = 'small';
Small.tagName = 'small';
Quill.register(Small);



/*
 * Support for soft line breaks, thanks:
 *   https://github.com/zenoamaro/react-quill/issues/513#issuecomment-523783053
 */
const Delta = Quill.import("delta");
const Break = Quill.import("blots/break");
const Embed = Quill.import("blots/embed");

const lineBreakMatcher = () => {
    let newDelta = new Delta();
    newDelta.insert({ break: "" });
    return newDelta;
};

class SmartBreak extends Break {
    length() {
        return 1;
    }
    value() {
        return "\n";
    }

    detach() {
        /*
         * Prevent this Quill/Parchment bug: https://github.com/quilljs/parchment/issues/87.
         *
         * The overriden `detach()` method is the below, but also `delete
         * this.domNode[Registry.DATA_KEY]`. Deleting that triggers a bug in a
         * subsequent `optimize()` pass.
         *
         * We could alternatively prevent the bug by removing the
         * `lineBreakMatcher` from `webModules` and `emailModules` below, but if
         * we do that, then Quill removes some `<br>` tags from the HTML just
         * via the process of loading and displaying it, which is a bit crappy.
         */
        if (this.parent != null) {
            this.parent.removeChild(this);
        }
    }

    insertInto(parent, ref) {
        Embed.prototype.insertInto.call(this, parent, ref);
    }
}

SmartBreak.blotName = "break";
SmartBreak.tagName = "BR";
Quill.register(SmartBreak);

const keyboard = {
    bindings: {
        linebreak: {
            key: 13,
            shiftKey: true,
            handler: function(range) {
                // const currentLeaf = this.quill.getLeaf(range.index)[0];
                // const nextLeaf = this.quill.getLeaf(range.index + 1)[0];
                this.quill.insertEmbed(range.index, "break", true, "user");

                // Now that we've inserted a line break, move the cursor forward
                this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
            }
        }
    }
};


/* ToDo: matchVisual will be dropped on Quill 2.0, so please retest when upgrading
 * https://github.com/quilljs/quill/blob/ee827ffb605ba491246f201d497ce0e7d9e193a0/docs/guides/upgrading-to-2-0.md#configuration
 * https://github.com/zenoamaro/react-quill/issues/409#issuecomment-434059797
 */
const webModules = (id) => ({
    clipboard: {
        matchers: [["BR", lineBreakMatcher]],
        matchVisual: false
    },
    keyboard,
    toolbar: { container: `#quill-toolbar-${id}` }
});

const webFormats = [
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet',
    'header',
    'link',
    'small',
    'break'
];


const emailModules = (id, placeholders) => ({
    clipboard: {
        matchers: [["BR", lineBreakMatcher]],
        matchVisual: false
    },
    keyboard,
    toolbar: { container: `#quill-toolbar-${id}` },
    placeholder: {
        delimiters: ['{{', '}}'],
        placeholders
    }
});

const emailFormats = [
    'bold', 'italic', 'underline',
    'list', 'bullet',
    'link',
    'small',
    'placeholder', 'break'
];


function QuillToolbar({ id, isEmail, placeholders }) {
    return (
        <div id={`quill-toolbar-${id}`}>
            <span className="ql-formats">
                <button className="ql-bold" />
                <button className="ql-italic" />
                <button className="ql-underline" />
                {!isEmail && (<button className="ql-strike" />)}
                {!isEmail && (<button className="ql-blockquote" />)}
            </span>
            <span className="ql-formats">
                <button className="ql-list" value="ordered" />
                <button className="ql-list" value="bullet" />
            </span>
            <span className="ql-formats">
                 {!isEmail && (
                     <select className="ql-header" defaultValue="" onChange={e => e.persist()}>
                         <option value="1" key="1" />
                         <option value="2" key="2" />
                         <option value="3" key="3" />
                         <option value="4" key="4" />
                         <option value=""  key="0" />
                     </select>
                 )}
            </span>
            <span className="ql-formats">
                <button className="ql-link" />
            </span>
            <span className="ql-formats">
                <button className="ql-small" style={{width: '100%', fontSize: '0.75em', marginBottom: '1px'}}><small>small</small></button>
            </span>
            {!!isEmail && (
                <span className="ql-formats">
                    <select className="ql-placeholder">
                        {placeholders.map(({id, label}) => (
                            <option value={id} key={id}>{label}</option>
                        ))}
                    </select>
                </span>
            )}
        </div>
    );
}


class CustomTextArea extends React.Component {
    constructor(props) {
        super(props);

        this.toggleToolbar = this.toggleToolbar.bind(this);
        this.setActiveStatus = this.setActiveStatus.bind(this);
        this.onReactQuillInteraction = this.onReactQuillInteraction.bind(this);

        this.modules = !!props.isEmail ? emailModules(props.id, props.emailPlaceholders) : webModules(props.id);
        this.formats = !!props.isEmail ? emailFormats : webFormats;

        this.state = {
            isActive: false,
            isToolbarShown: !props.readOnly || !props.showToolbarToggle
        };
    }

    componentDidMount() {
        if (this.textarea) {
            autosize(this.textarea);
        }
    }

    toggleToolbar() {
        const { isToolbarShown } = this.state;
        this.setState({
            isToolbarShown: !isToolbarShown
        });
    }

    setActiveStatus(status = false) {
        this.setState({
            isActive: status
        });
    }

    onReactQuillInteraction() {
        const activeElement = document.activeElement;
        const qlEditor = this.component ? this.component.querySelector(".ql-editor") : null;

        this.setState({
            isActive: activeElement === qlEditor
        });
    }

    render() {
        const {
            classes,
            hasPadding,
            type = eTextAreaType.STANDARD,
            showToolbarToggle,
            toolbarSize = eQuillToolbarSize.MEDIUM,
            resizable,
            id,
            required,
            isEmail,
            isError,
            label,
            placeholder,
            value,
            readOnly = false,
            isDisabled = false,
            helperText,
            showCharacterCount,
            onInitialInteraction,
            onChange,
            onBlur,
            emailPlaceholders,
            maxLength
        } = this.props;
        const { isActive, isToolbarShown } = this.state;
        return (
            <div
                className={`
                    customTextArea
                    ${classes}
                    ${hasPadding ? 'padding' : ''}
                    ${readOnly || isDisabled ? 'readOnly' : 'editable'}
                    ${isEmail ? 'email' : ''}
                `}
                ref={(i) => this.component = i}
            >
                {label && (
                    <span className={`label ${isActive ? 'active' : ''} ${isError ? 'error' : ''}`}>{label}{required ? "*" : ""}</span>
                )}
                {type === eTextAreaType.STANDARD && (
                    <textarea
                        className={resizable ? '' : 'non-resizable'}
                        id={id}
                        ref={c => (this.textarea = c)}
                        value={value || ""}
                        maxLength={maxLength}
                        placeholder={placeholder}
                        readOnly={readOnly}
                        disabled={isDisabled}
                        onChange={onChange}
                        onFocus={() => this.setActiveStatus(true)}
                        onBlur={(e) => {!!onBlur && onBlur(e); this.setActiveStatus(false)}}
                    />
                )}
                {type === eTextAreaType.RICH && (
                    <ErrorBoundary>
                        {showToolbarToggle && (
                            <div className={`toolbar-toggle ${label ? '' : 'no-label'} ${isToolbarShown ? 'active' : ''}`} onClick={this.toggleToolbar}>HTML</div>
                        )}
                        <div className={`toolbar ${toolbarSize} ${isToolbarShown ? '' : 'hidden'}`}>
                            <QuillToolbar id={id} isEmail={isEmail} placeholders={emailPlaceholders} />
                        </div>
                        <ReactQuill
                            id={id}
                            value={value}
                            placeholder={placeholder}
                            onFocus={this.onReactQuillInteraction && onInitialInteraction}
                            readOnly={readOnly}
                            onChange={(e) => {
                                !!onChange && onChange(
                                    {
                                        target: {
                                            id,
                                            value: e
                                        }
                                    }
                                );
                            }}
                            onBlur={() => {
                                this.onReactQuillInteraction();
                                !!onBlur && onBlur();
                            }}
                            modules={this.modules}
                            formats={this.formats}
                        />
                    </ErrorBoundary>
                )}
                {showCharacterCount && (
                    <div className={`char-count`}>{value.length}{maxLength ? ` / ${maxLength}` : ''} character{value.length === 1 ? '' : 's'}</div>
                )}
                <div className={`helper-text ${!helperText ? 'empty' : ''}`}>{helperText}</div>
            </div>
        );
    }
}

CustomTextArea.propTypes = {
    classes: PropTypes.string,
    hasPadding: PropTypes.bool,
    type: PropTypes.oneOf(objectToArray(eTextAreaType)),
    showToolbar: PropTypes.bool,
    showToolbarToggle: PropTypes.bool,
    toolbarSize: PropTypes.oneOf(objectToArray(eQuillToolbarSize)),
    resizable: PropTypes.bool,
    id: PropTypes.string,
    required: PropTypes.bool,
    isError: PropTypes.bool,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    isEmail: PropTypes.bool,
    value: PropTypes.string,
    maxLength: PropTypes.number,
    readOnly: PropTypes.bool,
    helperText: PropTypes.oneOf([PropTypes.string, PropTypes.object]),
    showCharacterCount: PropTypes.bool,
    onInitialInteraction: PropTypes.func,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    emailPlaceholders: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
    }))
};

CustomTextArea.defaultProps = {
    classes: "",
    hasPadding: false,
    type: eTextAreaType.STANDARD,
    showToolbar: true,
    showToolbarToggle: false,
    toolbarSize: eQuillToolbarSize.MEDIUM,
    resizable: true,
    id: "",
    required: false,
    isEmail: false,
    isError: false,
    label: "",
    placeholder: "",
    value: "",
    maxLength: null,
    readOnly: false,
    helperText: null,
    showCharacterCount: false,
    onInitialInteraction: null,
    onChange: null,
    onBlur: null
};

export default CustomTextArea;
