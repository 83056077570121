import React from "react";
import PropTypes from "prop-types";
import { IconPageUp } from "../Icons";
import Tooltip from "../Tooltip";
import { createPageupPackage } from "../../api";

import './style.scss';


class SendToPageUpLink extends React.Component {
    constructor(props) {
        super(props);
        this.showOutcome = this.showOutcome.bind(this);
        this.hideOutcome = this.hideOutcome.bind(this);
        this.state = {
            stateValue: '',
            isOutcomeShown: false,
            outcomeMessage: ''
        };
    }

    showOutcome() {
        this.setState({
            isOutcomeShown: true
        });
        setTimeout(() => this.hideOutcome(), 2000);
    }

    hideOutcome() {
        this.setState({
            isOutcomeShown: false,
            outcomeMessage: ''
        });
    }

    render() {
        const { value, jobId } = this.props;
        const { isOutcomeShown, stateValue, outcomeMessage } = this.state;
        const displayedValue = value || stateValue;

        if (!displayedValue) {
            return null;
        } else {
            const content = displayedValue;
            return (
                <div className={`send-to-pageup-link`}>
                    <div className="send-to-pageup-link-content">{content}</div>
                    <Tooltip classes = "tooltip-icon-pageup" positioning="onLeft lines-3" description={<span>Send to PageUp</span>}>
                        <img className="icon-pageup" alt="Send To Page Up icon" src={IconPageUp} onClick={() => createPageupPackage(jobId).then((response) => {

                                                                                                                                    if (response.error) {
                                                                                                                                        this.setState({outcomeMessage: `Error!`});
                                                                                                                                        this.showOutcome();
                                                                                                                                        return false;
                                                                                                                                    }

                                                                                                                                    this.setState({outcomeMessage: `Sent!`});
                                                                                                                                    this.showOutcome();
                                                                                                                                })} />
                    </Tooltip>
                    <div className={`outcome ${isOutcomeShown ? "shown" : ""}`}>{outcomeMessage}</div>
                </div>
            );
        }
    }
}

SendToPageUpLink.propTypes = {
    value: PropTypes.string,
    jobId: PropTypes.number
};

export default SendToPageUpLink;
