import React from "react";
import {eConfirmDialogResponseType, eInterviewTerminologies, eTemplatesType} from "../../utils";
import {
    getEmailTemplates,
    getInterviewTemplates,
    getSMSTemplates,
    removeEmailTemplate,
    removeInterviewTemplate,
    removeSMSTemplate
} from "../../api";
import {capitalize} from "lodash";

class Templates extends React.Component {
    async retrieveTemplates(type) {
        let items = [];

        if (type === eTemplatesType.INTERVIEW) {
            items = await getInterviewTemplates();
        }
        if (type === eTemplatesType.EMAIL) {
            items = await getEmailTemplates();
        }
        if (type === eTemplatesType.SMS) {
            items = await getSMSTemplates();
        }

        this.setState({
            loading: false,
            isPreviewShown: false,
            items
        });
    }

    onNew(type, subType = null) {
        let template = {};

        if (type === eTemplatesType.INTERVIEW) {
            template = {
                interviewTerminology: eInterviewTerminologies.INTERVIEW,
                interviewSteps: []
            };
        }
        if (type === eTemplatesType.EMAIL) {
            template = {
                is_default_for_new_job: false,
                email_template_type: subType
            };
        }
        this.toggleTemplateDetails(template);
    }

    toggleTemplateDetails(template = null, isPreviewShown = false) {
        this.setState({
            selectedTemplate: template || null,
            isPreviewShown
        })
    }

    isItemMatchingWithTemplate(type, item, template) {
        if (type === eTemplatesType.INTERVIEW) {
            return item.interviewTemplateId === template.interviewTemplateId;
        }
        if (type === eTemplatesType.EMAIL) {
            return item.email_template_id === template.email_template_id;
        }
        if (type === eTemplatesType.SMS) {
            return item.sms_template_id === template.sms_template_id;
        }
        return false;
    }

    onTemplateSubmit(type, template, is_new_record) {
        const { items } = this.state;

        let updatedItems = [...items];

        if (is_new_record) {
            updatedItems.push(template)
        } else {
            updatedItems = updatedItems.map((item) => {
                const matching = this.isItemMatchingWithTemplate(type, item, template);

                return matching ? template : item;
            });
        }

        this.setState({
            items: updatedItems
        });
    }

    deleteTemplateConfirmation(type, template) {
        let templateId = null,
            templateName = null;

        if (type === eTemplatesType.INTERVIEW) {
            templateId = template.interviewTemplateId;
            templateName = template.interviewTemplateName;
        }
        if (type === eTemplatesType.EMAIL) {
            templateId = template.email_template_id;
            templateName = `${capitalize(template.email_template_type)} #${template.email_template_id}`;
        }
        if (type === eTemplatesType.SMS) {
            templateId = template.sms_template_id;
            templateName = `${capitalize(template.sms_template_type)} #${template.sms_template_id}`;
        }

        this.updateConfirmDialogResponseProps(
            <span>Delete template <strong>{templateName}</strong>?</span>,
            eConfirmDialogResponseType.QUESTION,
            () => this.deleteTemplate(type, templateId)
        );
    }

    async deleteTemplate(type, templateId) {
        let response = [];

        if (type === eTemplatesType.INTERVIEW) {
            response = await removeInterviewTemplate(templateId);
        }
        if (type === eTemplatesType.EMAIL) {
            response = await removeEmailTemplate(templateId);
        }
        if (type === eTemplatesType.SMS) {
            response = await removeSMSTemplate(templateId);
        }

        if (response.error) {
            this.updateConfirmDialogResponseProps(<span>Unable to delete template.<br/>Please try again later.</span>, eConfirmDialogResponseType.ERROR);
            return;
        }

        const { items } = this.state;
        const updatedItems = items.filter(
            (item) => {
                if (type === eTemplatesType.INTERVIEW) {
                    return item.interviewTemplateId !== templateId;
                }
                if (type === eTemplatesType.EMAIL) {
                    return item.email_template_id !== templateId;
                }
                if (type === eTemplatesType.SMS) {
                    return item.sms_template_id !== templateId;
                }
                return false;
            }
        );
        this.setState({
            items: updatedItems
        });
        this.updateConfirmDialogResponseProps("Template deleted.", eConfirmDialogResponseType.SUCCESS);
    }

    updateConfirmDialogResponseProps(sentence, type = eConfirmDialogResponseType.INFO, onProceed = null) {
        this.setState({
            confirmDialogResponseProps: {
                type,
                sentence,
                onProceed,
                onClose: () => this.setState({confirmDialogResponseProps: null})
            }
        });
    }
}

export default Templates;