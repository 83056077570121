import React from "react";
import * as PropTypes from "prop-types";
import CustomTextField from "../../Form/CustomTextField";
import CustomTextArea from "../../Form/CustomTextArea";
import {
    defaultOngoingDeadlineDays,
    eBrandingType,
    eDeadlineType,
    eInterviewTerminologies,
    eTextAreaType,
    objectToArray
} from "../../../utils";
import Dropdown from "../../Dropdown";
import CustomModal from "../../CustomModal";
import BrandingPreview from "../../BrandingPreview";
import JobDeadline from "../../JobDeadline";

import './style.scss';


class StepJobDetails extends React.Component {
    constructor(props) {
        super(props);

        this.getCurrentBranding = this.getCurrentBranding.bind(this);
        this.getBrandingsAsDropdown = this.getBrandingsAsDropdown.bind(this);
        this.showPreviewBranding = this.showPreviewBranding.bind(this);
        this.hidePreviewBranding = this.hidePreviewBranding.bind(this);

        this.state = {
            isBrandingPreviewShown: false
        }
    }

    getCurrentBranding() {
        const { brandings, branding_id: selected_branding_id } = this.props;
        return brandings.find((branding) => branding[eBrandingType.ID] === selected_branding_id);
    }

    getBrandingsAsDropdown() {
        const { branding_id : selected_branding_id, brandings = [], onInputChange } = this.props;

        return brandings.map(({ branding_id, branding_name }) => {
            return {
                id: `${branding_id}`,
                firstLine: branding_name,
                selected: `${branding_id}` === `${selected_branding_id}`,
                onClick: () => onInputChange({ target: { id: "branding_id", value: branding_id } })
            };
        });
    }

    showPreviewBranding() {
        this.setState({
            isBrandingPreviewShown: true
        });
    }

    hidePreviewBranding() {
        this.setState({
            isBrandingPreviewShown: false
        });
    }

    render() {
        const {
            // job
            title,
            location,
            position_description,
            branding_id,

            // job_stage
            interview_terminology,
            deadline_type,
            deadline,
            ongoing_deadline_days,

            // other
            classes,
            onInputChange
        } = this.props;
        const { isBrandingPreviewShown } = this.state;

        return (
            <div className={`step-job-details ${classes}`}>
                <CustomTextField
                    classes={`title`}
                    id={`title`}
                    required={true}
                    label={`Job Title`}
                    value={title}
                    onChange={onInputChange}
                />
                <CustomTextField
                    classes={`location`}
                    id={`location`}
                    required={true}
                    label={`Location`}
                    value={location}
                    onChange={onInputChange}
                />
                <JobDeadline
                    deadline_type={deadline_type}
                    deadline={deadline}
                    ongoing_deadline_days={ongoing_deadline_days}
                    onInputChange={onInputChange}
                />
                <CustomTextArea
                    classes={`position_description`}
                    hasPadding={true}
                    type={eTextAreaType.RICH}
                    id={`position_description`}
                    placeholder={`Insert job description here`}
                    value={position_description}
                    onChange={onInputChange}
                />
                <div className={`branding-container`}>
                    <div className={`branding-header`}>Branding</div>
                    <Dropdown
                        required={false}
                        styles="floating-label fixed-label"
                        classes="branding-dropdown"
                        label="App Theme"
                        items={this.getBrandingsAsDropdown()}
                    />
                    <button className={`link branding-preview-link`} onClick={this.showPreviewBranding}>Preview</button>
                </div>

                {isBrandingPreviewShown && (
                    <CustomModal
                        classes={`branding-preview-container open`}
                        onClose={this.hidePreviewBranding}
                    >
                        <BrandingPreview job={{title, deadline, interview_terminology, branding_id}} branding={this.getCurrentBranding()} />
                    </CustomModal>
                )}
            </div>
        );
    }
}

StepJobDetails.propTypes = {
    // job
    title: PropTypes.string,
    location: PropTypes.string,
    position_description: PropTypes.string,
    branding_id: PropTypes.number,

    // job_stage
    interview_terminology: PropTypes.oneOf(objectToArray(eInterviewTerminologies)),
    deadline_type: PropTypes.oneOf(objectToArray(eDeadlineType)),
    deadline: PropTypes.string,
    ongoing_deadline_days: PropTypes.number,

    // other
    classes: PropTypes.string,
    brandings: PropTypes.array,
    onInputChange: PropTypes.func
};

StepJobDetails.defaultProps = {
    // job
    title: null,
    location: null,
    position_description: null,
    branding_id: null,

    // job_stage
    interview_terminology: eInterviewTerminologies.INTERVIEW,
    deadline_type: eDeadlineType.FIXED,
    deadline: "",
    ongoing_deadline_days: defaultOngoingDeadlineDays,

    // other
    classes: "",
    brandings: [],
    onInputChange: null
};

export default StepJobDetails;
