import React from "react";
import * as PropTypes from 'prop-types';

import {getSearchResults} from "../../api";
import CustomTextField from "../Form/CustomTextField";
import { debounce, get, groupBy } from "lodash";
import Avatar from "../Avatar";
import Loader from "../Loader";

import './style.scss';


const SEARCH_DELAY = 1000;

class Search extends React.Component {
    constructor(props) {
        super(props);

        this.updateSearchQuery = this.updateSearchQuery.bind(this);
        this.doSearch = debounce(this.doSearch.bind(this), SEARCH_DELAY);
        this.getJobURL = this.getJobURL.bind(this);

        this.state = {
            searchQuery: "",
            lastSearchQuery: "",
            isSearching: false,
            searchResults: null
        };
    }

    updateSearchQuery(e) {
        const { target: { value } } = e;
        this.setState({
            searchQuery: value
        });

        this.doSearch();
    }

    async doSearch() {
        const { searchQuery, lastSearchQuery } = this.state;

        if(searchQuery.length < 2) {
            this.setState({
                searchResults: null,
                lastSearchQuery: searchQuery
            });
            return;
        }

        if (searchQuery === lastSearchQuery) {
            return;
        }

        this.setState({
            isSearching: true,
        });

        const searchResults = await getSearchResults(searchQuery);
        this.setState({
            searchResults,
            isSearching: false,
            lastSearchQuery: searchQuery
        });
    }

    getJobURL(job_id, status, candidateId) {
        const { is_manager, urlFor } = this.props;

        let route;

        if (status === 'draft') {
            route = 'manage-existing-job-draft';
        } else if (is_manager) {
            route = 'manage-job-details';
        } else {
            route = 'assess-job-details';
        }

        return urlFor(route, { job_id, scrollToCandidateId: candidateId });
    }

    render() {
        const { searchQuery, isSearching, searchResults } = this.state;
        const { onClose } = this.props;
        const { "search-information" : searchInformation, items = [] } = (searchResults || {});
        const { "total-results" : totalResults } = (searchInformation || {});

        return (
            <div className={`react-search`}>
                <CustomTextField
                    id={`search`}
                    classes={`search-field`}
                    label={`Search candidate name, email, or phone`}
                    value={searchQuery}
                    onChange={this.updateSearchQuery}
                    autoFocus={true}
                />
                {isSearching && (<Loader />)}
                {!!searchResults && (
                    <div className={`search-results`}>
                        {!totalResults && (
                            <div className={`no-results`}>
                                No results.
                            </div>
                        )}
                        {!!totalResults && (
                            <div className={`results-count`}>
                                {totalResults} result
                                {totalResults > 1 ? 's': ''}
                            </div>
                        )}
                        {!!items && (
                            <div className={`items`}>
                                {Object.entries(
                                        groupBy(get(items, "candidates", []), (({job}) => job.job_id))
                                    ).map(([jobId, candidates], key) => (
                                    <div key={`item_${key}`} className={`item`}>
                                        <div className={`job-id-and-title`}>
                                            <div className={`id`}><sup>#{jobId}</sup></div>
                                            <a className={`title`}
                                                href={this.getJobURL(jobId, get(candidates, [0, "job", "status"]))}
                                                onClick={onClose}
                                            >
                                                {get(candidates, [0, "job", "title"], "")}
                                            </a>
                                        </div>
                                        <div className={`candidates`}>
                                            {candidates.map((candidate) => (
                                                <div key={`candidate_${candidate.candidate_id}`} className={`candidate`}>
                                                    <Avatar classes={`candidate-avatar`} size="ml" data={{id: candidate.candidate_id, ...candidate}} />
                                                    <a
                                                        className={`candidate-name`}
                                                        href={this.getJobURL(jobId,
                                                            get(candidates, [0, "job", "status"]),
                                                            candidate.candidate_id)}
                                                        onClick={onClose}
                                                    >
                                                        {candidate.first_name} {candidate.last_name}
                                                    </a>
                                                    <div className={`candidate-email`}>{candidate.email}</div>
                                                    <div className={`candidate-phone`}>{candidate.phone}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}


Search.propTypes = {
    is_manager: PropTypes.bool.isRequired,
    urlFor: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};


export default Search;
