import React from 'react';
import * as PropTypes from "prop-types";

import './style.scss';


const CustomRadioButton = ({id, name, classes, value, displayValue = true, selected, onChange}) => (
    <div
        className={`custom-radio-button  ${displayValue ? 'show-value' : ''} ${classes}`}
        onClick={onChange}
    >
        <input
            id={id}
            name={name}
            className={classes}
            type="radio"
            value={value || ""}
            checked={selected}
            onChange={onChange}
        />
        {displayValue ? value : null}
    </div>
);

CustomRadioButton.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    classes: PropTypes.string,
    value: PropTypes.string,
    selected: PropTypes.bool,
    onChange: PropTypes.func
};

CustomRadioButton.defaultProps = {
    id: "",
    name: "",
    classes: "",
    value: "",
    selected: false,
    onChange: null
};

export default CustomRadioButton;
