import React from 'react';
import { DatePicker } from '@material-ui/pickers';

import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import Picker from './Picker';
import {convertDayToCommonFormat, convertDateToPickerFormat} from "../../utils";

import './style.scss';


class CustomDatePicker extends React.Component {
    render() {
        const {
            id,
            classes,
            label,
            value,
            required,
            disabled,
            isError,
            minDate,
            minMessage = "Please select a valid date.",
            placeholder,
            onChange,
            onBlur
        } = this.props;

        const labelText = (
            <React.Fragment>
                {label}
                {!!required && (<span>*</span>)}
            </React.Fragment>
        );

        return (
            <div className={`customDatePicker`}>
                <Picker>
                    <DatePicker
                        id={id}
                        className={`customDateValue ${classes} ${isError ? 'error' : ''} ${disabled ? 'disabled' : ''}`}
                        clearable={true}
                        disabled={disabled}
                        leftArrowIcon={<KeyboardArrowLeftIcon/>}
                        rightArrowIcon={<KeyboardArrowRightIcon/>}
                        label={labelText}
                        value={convertDateToPickerFormat(value) || null}
                        minDate={convertDayToCommonFormat(minDate)}
                        placeholder={placeholder}
                        minDateMessage={minMessage}
                        format="dd MMM yyyy"
                        onChange={onChange}
                        onBlur={onBlur}
                        showTodayButton={true}
                        okLabel='Select'
                        autoOk={true}
                    />
                </Picker>
            </div>
        );
    }
}

export default CustomDatePicker;
