import React from "react";
import * as PropTypes from "prop-types";
import { get } from "lodash";
import Avatar from "../Avatar";
import CandidateDetails from "./CandidateDetails";
import CandidateFeedback from "./CandidateFeedback";
import CandidateProfileAdmin from "../CandidateProfileAdmin";
import CandidateResponses from "./CandidateResponses";
import Container from "../Container";
import CustomModal from "../CustomModal";
import Decision from "./Decision";
import EvaluatorsFinalComments from "./EvaluatorsFinalComments";
import ScoringTable from "./ScoringTable";
import StatusMessage from "../StatusMessage";
import {
    getCandidate,
    updateCandidateDecisions
} from "../../api";
import {
    average,
    eDecisionTypes,
    eInterviewStepType,
    eJobStageTypes,
    eRecommendationTypes,
    eStatusMessageStyle,
    eStatusMessageType,
    eUserTypes,
    objectToArray
} from "../../utils";
import CandidateInterview from "../CandidateInterview";
import CandidateLiveInterview from "./CandidateLiveInterview";
import ShareCandidateResponse from "../ShareCandidateResponse";
import StatusPanel from "./StatusPanel";
import CandidatesPanel from "./CandidatesPanel";

import './style.scss';


class CandidateProfile extends CandidateInterview {
    constructor(props) {
        super(props);

        this.loadData = this.loadData.bind(this);
        this.prepareInformation = this.prepareInformation.bind(this);
        this.createScoresTable = this.createScoresTable.bind(this);
        this.getInterviewSteps = this.getInterviewSteps.bind(this);

        this.getComponentContainerClassNames = this.getComponentContainerClassNames.bind(this);

        this.getCandidateEvaluations = this.getCandidateEvaluations.bind(this);
        this.updateDecision = this.updateDecision.bind(this);
        this.updateStatusMessage = this.updateStatusMessage.bind(this);
        this.toggleAdmin = this.toggleAdmin.bind(this);

        this.state = {
            loading: true,
            evaluations: [],

            // assessment
            overall_score: null,
            assessment_attributes: [],
            has_recommendation_record: false,
            combined_live_interview_notes: [],
            final_comments: [],

            statusMessage: {
                type: null,
                message: null,
                reset: null
            },

            showAdmin: false,

            isError: false,
            isCandidateSharingPanelShown: false,
            confirmDialogResponseProps: null,
            confirmDialogProps: null
        };
    }

    async loadData() {
        await this.retrieveJobSummary();
        await this.prepareInformation();
    }

    async prepareInformation(loading = true) {
        this.setState({loading, confirmDialogProps: null});

        const {
            job_id,
            job_stage_id,
            candidate_id
        } = this.props;

        const {
            job_stage_type,
            evaluation_attributes = [],
            evaluators = []
        } = this.getJobStage();

        const candidate = await getCandidate(job_id, job_stage_type, job_stage_id, candidate_id);

        if (!candidate.candidate_id) {
            this.setState({
                loading: false,
                isError: true,
                confirmDialogProps: {
                    title: "Candidate Not Found",
                    message: "The candidate you are trying to access cannot be found.",
                    actions: [
                        {
                            label: "Back to Job Details",
                            onClick: () => window.location.href = this.getJobDetailsURL('manage-job-details')
                        }
                    ]
                }
            });
            return false;
        }

        this.setState({
            candidate
        });

        const candidate_evaluations = this.getCandidateEvaluations();
        let has_recommendation_record = false;

        // create attributes table
        const returnValue = [], header = [], recommendations = [],
            scores = [],
            combined_live_interview_notes = [],
            final_comments = [];

        header.push(<span>Interview Outcome</span>);
        recommendations.push("");

        candidate_evaluations.forEach(({staff_id, live_interview_notes, final_comment, recommendation, score, submitted_at}) => {
            const isLiveInterviewStage = job_stage_type === eJobStageTypes.LIVE;

            const reviewers = isLiveInterviewStage ? candidate.interviewers : evaluators;
            const evaluator = reviewers.find((ev) => ev.staff_id === staff_id);
            const isSubmitted = !!submitted_at;

            const avatar = <Avatar classes={``} size="xs" data={{id: staff_id, ...evaluator}} />;

            header.push(avatar);

            if (isSubmitted && !!recommendation) {
                has_recommendation_record = true;
                recommendations.push(<div className={`attributes-score ${recommendation}`}>{recommendation}</div>);
            } else {
                recommendations.push(<div className={`attributes-score ${eRecommendationTypes.IN_PROGRESS}`}>-</div>);
            }

            if (score && isSubmitted) {
                scores.push(score);
            }

            if (isLiveInterviewStage) {
                combined_live_interview_notes.push([
                    avatar,
                    `${evaluator.first_name} ${evaluator.last_name}`,
                    live_interview_notes
                ]);
            }

            final_comments.push([
                avatar,
                `${evaluator.first_name} ${evaluator.last_name}`,
                final_comment
            ]);
        });

        returnValue.push(header);
        returnValue.push(recommendations);

        // create scores table
        returnValue.push(...this.createScoresTable(evaluation_attributes, candidate_evaluations));

        let newState = {
            loading: false,
            overall_score: average(scores),
            assessment_attributes: returnValue,
            has_recommendation_record,
            combined_live_interview_notes,
            final_comments
        };

        this.setState(newState);
    }

    createScoresTable(evaluationAttributes, candidateEvaluations) {
        const returnValue = [];
        evaluationAttributes.forEach((attribute) => {
            const arr = [];
            arr.push(attribute);

            candidateEvaluations.forEach(({attributes, submitted_at}) => {
                const attributeAndScore = attributes.find(({attribute: attr}) => attr === attribute) || {};
                const { score } = attributeAndScore;
                const isSubmitted = !!submitted_at;
                arr.push(<span className={`score${!isSubmitted ? '-pending' : ''}`}>{isSubmitted ? score || '-' : '-'}</span>);
            });

            returnValue.push(arr);
        });

        return returnValue;
    }

    getInterviewSteps(interviewSteps, jobInterviewSteps) {
        return interviewSteps || jobInterviewSteps;
    }

    getComponentContainerClassNames() {
        const { viewType, integrationProvider } = this.props;
        let returnValue = `candidate-profile-container ${viewType}`;

        if (integrationProvider) {
            returnValue += ' integration';
        }

        if (this.hasStatusPanel()) {
            returnValue += ` has-status-panel`;
        }

        return returnValue;
    }

    getCandidateEvaluations() {
        const { candidate } = this.state;
        return get(candidate, "evaluations", []).filter(({started_at}) => !!started_at);
    }

    updateDecision(decision) {
        if (!objectToArray(eDecisionTypes).includes(decision)) {
            return false;
        }
        const { job_id, job_stage_type, job_stage_id, candidate_id } = this.props;

        updateCandidateDecisions(job_id, job_stage_type, job_stage_id, [candidate_id], decision).then(() => {
            const { candidate } = this.state;
            this.setState({
                candidate: {
                    ...candidate,
                    decision
                }
            }, () => {
                const { sends_successful_email, sends_unsuccessful_email } = this.getJobStage();
                if (
                    (decision === eDecisionTypes.ACCEPTED && sends_successful_email) ||
                    (decision === eDecisionTypes.DECLINED && sends_unsuccessful_email)
                ) {
                    this.updateStatusMessage("Candidate decision updated.", eStatusMessageType.INFO);
                }
            });
        });
    }

    updateStatusMessage(message, type = eStatusMessageType.INFO) {
        this.setState({
            statusMessage: {
                type,
                message,
                reset: new Date()
            }
        })
    }

    componentDidMount() {
        this.loadData();
    }

    toggleAdmin() {
        const { showAdmin } = this.state;
        this.setState({
            showAdmin: !showAdmin
        });
    }

    componentDidUpdate(prevProps) {
        const { candidate_id: previous_candidate_id } = prevProps;
        const { candidate_id } = this.props;

        if (candidate_id !== previous_candidate_id) {
            this.prepareInformation();
        }
    }

    render() {
        const {
            loading,
            job = {},
            candidate = {},

            overall_score,
            assessment_attributes,
            has_recommendation_record,
            combined_live_interview_notes,
            final_comments,
            statusMessage: { type, message, reset },

            showAdmin,

            isError,
            isCandidateSharingPanelShown,
            confirmDialogResponseProps,
            confirmDialogProps
        } = this.state;

        const { title, location } = job;

        const {
            viewType,
            candidate_id,
            is_alcami_staff
        } = this.props;

        const jobStage = this.getJobStage();

        const {
            job_stage_type,
            evaluators = [],
            job_interview_steps = [],
            evaluation_attributes = [],
            requires_response_evaluation,
            sends_successful_email,
            sends_unsuccessful_email
        } = jobStage;

        const isOneWayStage = job_stage_type === eJobStageTypes.ONE_WAY,
            isLiveInterviewStage = job_stage_type === eJobStageTypes.LIVE;

        const { is_completed, completed_at, candidate_responses, feedback, decision } = candidate;

        const hasAttributes = evaluation_attributes?.length > 2;

        const interviewSteps = this.getInterviewSteps(candidate?.interview_steps, job_interview_steps);
        const questions = interviewSteps.filter(({interview_step_type}) => interview_step_type === eInterviewStepType.QUESTION);

        const showCompletedTimestamp = this.shallShowCompletedTimestamp();
        const hasStatusPanel = this.hasStatusPanel();

        return (
            <Container
                loading={loading}
                confirmDialogProps={confirmDialogProps}
                confirmDialogResponseProps={confirmDialogResponseProps}
            >
                {!isError && (
                    <React.Fragment>
                        <StatusMessage
                            type={type}
                            style={eStatusMessageStyle.BAR}
                            message={message}
                            duration={5000}
                            reset={reset}
                        />

                        {is_alcami_staff && showAdmin && (
                            <CustomModal title={`Reset Candidate Responses`} classes={`candidate-profile-admin-modal open`} onClose={this.toggleAdmin}>
                                <CandidateProfileAdmin
                                    candidate={candidate}
                                    job_stage={jobStage}
                                    onUpdate={() => this.prepareInformation(false)}
                                />
                            </CustomModal>
                        )}

                        <div className={this.getComponentContainerClassNames()}>
                            <CandidatesPanel
                                backLinkURL={this.getJobDetailsURL('manage-job-details')}
                                title={title}
                                location={location}
                            />

                            {hasStatusPanel && (
                                <StatusPanel
                                    is_completed={is_completed}
                                    showCompletedTimestamp={showCompletedTimestamp}
                                    completed_at={completed_at}
                                />
                            )}

                            <div className={`candidate-profile`}>
                                <CandidateDetails
                                    candidate={{...candidate, id: candidate_id}}
                                    onEdit={is_alcami_staff ? this.toggleAdmin : null}
                                    onShareCandidate={this.toggleCandidateSharing}
                                />

                                {isOneWayStage && (
                                    <CandidateFeedback feedback={feedback} />
                                )}

                                <div className={`evaluations-panel`}>
                                    {isOneWayStage && (
                                        <CandidateResponses
                                            view_type={viewType}
                                            questions={questions}
                                            responses={candidate_responses}
                                            requires_star_rating={requires_response_evaluation}
                                            evaluators={evaluators}
                                            evaluations={this.getCandidateEvaluations()}
                                        />
                                    )}
                                    {isLiveInterviewStage && (
                                        <CandidateLiveInterview live_interview_notes={combined_live_interview_notes} />
                                    )}
                                </div>

                                <div id={`assessment-and-comments`}
                                    className={`
                                    assessment-and-comments
                                    ${(has_recommendation_record || hasAttributes) ? 'has-assessment' : ''}
                                    ${has_recommendation_record ? 'has-decisions' : ''}
                                `}>
                                    {(has_recommendation_record || hasAttributes) && (
                                        <ScoringTable
                                            overall_score={overall_score}
                                            attributes={assessment_attributes}
                                        />
                                    )}
                                    <EvaluatorsFinalComments items={final_comments} />
                                    {has_recommendation_record && (
                                        <Decision
                                            value={decision}
                                            sends_successful_email={sends_successful_email}
                                            sends_unsuccessful_email={sends_unsuccessful_email}
                                            onUpdate={this.updateDecision}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}
                {isCandidateSharingPanelShown && (
                    <ShareCandidateResponse
                        candidates={[candidate]}
                        job_id={job.job_id}
                        job_stage_type={jobStage.job_stage_type}
                        job_stage_id={jobStage.job_stage_id}
                        onUpdate={this.showCompletedDialog}
                        onClose={this.toggleCandidateSharing}
                        closeOnOverlayClick={false}
                    />
                )}
            </Container>
        )
    }
}

CandidateProfile.propTypes = {
    viewType: PropTypes.oneOf(objectToArray(eUserTypes)),
    staff_id: PropTypes.number.isRequired,
    is_alcami_staff: PropTypes.bool,
    integrationProvider: PropTypes.string,
    job_id: PropTypes.number.isRequired,
    job_stage_type: PropTypes.oneOf(objectToArray(eJobStageTypes)).isRequired,
    job_stage_id: PropTypes.number.isRequired,
    candidate_id: PropTypes.number.isRequired,
    urlFor: PropTypes.func.isRequired,
    urlHasTrailingEvaluate: PropTypes.bool
};

CandidateProfile.defaultProps = {
    viewType: eUserTypes.MANAGER,
    integrationProvider: "",
    urlHasTrailingEvaluate: false
};

export default CandidateProfile;
