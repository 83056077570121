import React from "react";
import {cloneDeep} from "lodash";
import {eDeadlineType, eJobDraftSteps, JOB_STAGES_FIELDS} from "../../utils";
import {getCurrentJobStage, isJobStageUsingInterviewTemplate} from "../../utils/job";

class Job extends React.Component {
    constructor(props) {
        super(props);

        this.onTemplateSelection = this.onTemplateSelection.bind(this);
        this.cleanseJobStage = this.cleanseJobStage.bind(this);
    }

    onTemplateSelection({interviewTemplateId, interviewTemplateName, interviewTerminology, interviewSteps}) {
        const { job, currentJobStageId } = this.state;

        const updatedJobState = {...job};
        const { job_stages } = job;
        updatedJobState.job_stages = job_stages.map((job_stage) => {
            const {job_stage_id} = job_stage;
            if (job_stage_id === currentJobStageId) {
                return {
                    ...job_stage,
                    interview_template_id: interviewTemplateId,
                    interview_template_name: interviewTemplateName,
                    interview_template_is_reusable: true,
                    interview_terminology: interviewTerminology,
                    job_interview_steps: cloneDeep(interviewSteps),
                    interview_template_steps: interviewSteps
                }
            } else {
                return job_stage;
            }
        });

        this.setState({
            job: updatedJobState
        });
    }

    async cleanseJobStage(job_stage_id, currentStep) {
        const { job } = this.state;
        const currentJobStage = getCurrentJobStage(job, job_stage_id);

        if (currentStep === eJobDraftSteps.JOB_DETAILS) {
            const { deadline_type } = currentJobStage;
            if (deadline_type === eDeadlineType.NONE) {
                await this.onJobInputChange({target: {id: JOB_STAGES_FIELDS.DEADLINE, value: null}});
                await this.onJobInputChange({target: {id: JOB_STAGES_FIELDS.ONGOING_DEADLINE_DAYS, value: null}});
            }
        }

        if (currentStep === eJobDraftSteps.QUESTIONS) {
            if (!isJobStageUsingInterviewTemplate(job, job_stage_id)) {
                await this.onJobInputChange({target: {id: JOB_STAGES_FIELDS.INTERVIEW_TEMPLATE_ID, value: null}});
                await this.onJobInputChange({target: {id: JOB_STAGES_FIELDS.INTERVIEW_TEMPLATE_NAME, value: null}});
                await this.onJobInputChange({target: {id: JOB_STAGES_FIELDS.INTERVIEW_TEMPLATE_IS_REUSABLE, value: false}});
            }
        }

        if (currentStep === eJobDraftSteps.CANDIDATES) {
            const {
                sends_invitation_email,
                sends_acknowledgement_email,
                sends_successful_email,
                sends_unsuccessful_email
            } = currentJobStage;
            if (!sends_invitation_email) {
                await this.onJobInputChange({target: {id: JOB_STAGES_FIELDS.INVITATION_EMAIL_TEMPLATE_ID, value: null}});
            }
            if (!sends_acknowledgement_email) {
                await this.onJobInputChange({target: {id: JOB_STAGES_FIELDS.ACKNOWLEDGEMENT_EMAIL_TEMPLATE_ID, value: null}});
            }
            if (!sends_successful_email) {
                await this.onJobInputChange({target: {id: JOB_STAGES_FIELDS.SUCCESSFUL_EMAIL_TEMPLATE_ID, value: null}});
            }
            if (!sends_unsuccessful_email) {
                await this.onJobInputChange({target: {id: JOB_STAGES_FIELDS.UNSUCCESSFUL_EMAIL_TEMPLATE_ID, value: null}});
            }
        }
    }
}

export default Job;