import React from "react";
import * as PropTypes from "prop-types";
import {
    addDays,
    convertDateToCommonFormat,
    eDeadlineType,
    eStatusMessageType,
    eUserSettings,
    generateTemporaryId,
    integrationPartner,
    isAdminUserSettingEnabled,
    isNewCandidate,
    validateCandidates
} from "../../utils";
import CandidateAdd from "./CandidateAdd";
import Candidate from "./Candidate";
import CandidatesImport from "../WorldManager/CandidatesImport";
import CustomModal from "../CustomModal";

import './style.scss';
import { deleteCandidate } from "../../api";


class Candidates extends React.Component {
    constructor(props) {
        super(props);

        this.getCandidates = this.getCandidates.bind(this);

        this.getPhone = this.getPhone.bind(this);
        this.getDeadline = this.getDeadline.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.validateCandidate = this.validateCandidate.bind(this);
        this.addCandidate = this.addCandidate.bind(this);
        this.updateCandidate = this.updateCandidate.bind(this);
        this.removeCandidate = this.removeCandidate.bind(this);

        this.showImportScreen = this.showImportScreen.bind(this);
        this.hideImportScreen = this.hideImportScreen.bind(this);
        this.updateStatusMessage = this.updateStatusMessage.bind(this);

        this.state = {
            isImportScreenShown: false,
            statusMessage: {
                type: null,
                message: null,
                reset: null
            },
        };
    }

    getCandidates() {
        const { candidates } = this.props;
        return candidates || [];
    }

    getPhone(cols) {
        if (cols.length > 3) {
            return cols[3].trim();
        }
        return "";
    }

    getDeadline(cols) {
        let deadline = null;
        if (cols.length > 4) {
            const dateField = cols[4].trim();

            if (!!dateField) {
                let day, month, year;

                if (dateField.includes("-")) {
                    // we assume they are using YYYY-MM-DD format
                    const dateFields = dateField.split('-');
                    if (dateFields.length === 3) {
                        day = parseInt(dateFields[2], 10);
                        month = parseInt(dateFields[1], 10) - 1;
                        year  = parseInt(dateFields[0], 10);
                    }
                } else {
                    // we assume they are using DD/MM/YYYY format
                    const dateFields = dateField.split('/');
                    if (dateFields.length === 3) {
                        day = parseInt(dateFields[0], 10);
                        month = parseInt(dateFields[1], 10) - 1;
                        year  = parseInt(dateFields[2], 10);
                    }
                }

                deadline = convertDateToCommonFormat(new Date(year, month, day));
            }
        }

        return deadline;
    }

    handleUpload(event) {
        const file = event.target.files[0];

        const { job_stage: { job_stage_id } = {}, onCandidateChange } = this.props;
        const candidates = this.getCandidates();
        const updatedCandidates = [...candidates];

        const reader  = new FileReader();

        reader.addEventListener("load", () => {
            const lines = reader.result.split('\n');

            const rowValidator = (row) => {
                return ([3,4,5].includes(row.length)) &&
                    row[0].trim() !== '' &&
                    row[1].trim() !== '' &&
                    row[2].trim() !== '';
            };

            let errors = '';

            for (let i = 0; i < lines.length; i++) {
                if (lines[i] === '') {
                    continue;
                }

                const cols = lines[i].split(',');

                if (!rowValidator(cols)) {
                    if (errors.length === 0) {
                        errors = 'CSV file could not be fully uploaded as the file does not contain one of each the following: first name, surname, and email address. Each row should also have optional 4th and 5th columns: phone and candidate-specific interview closing time. The following rows failed:<br><br>';
                    }
                    errors += 'Row: ' + (i + 1) + '. Content: ' + lines[i] + '<br>';
                    continue;
                }

                const first_name = cols[0].trim(),
                    last_name = cols[1].trim(),
                    email = cols[2].trim(),
                    phone = this.getPhone(cols);

                const deadline = this.getDeadline(cols);

                updatedCandidates.push({
                    candidate_id: generateTemporaryId(),
                    first_name,
                    last_name,
                    email,
                    phone,
                    deadline
                });
            }

            const { candidates : validatedCandidates } = validateCandidates(updatedCandidates);

            onCandidateChange(job_stage_id, validatedCandidates, false);
        }, false);

        if (file) {
            reader.readAsBinaryString(file);
        }
    }

    validateCandidate(candidate) {
        const { email: candidateEmail } = candidate;
        const candidates = this.getCandidates();

        const isValidCandidate = !candidates.find(({email}) => email === candidateEmail);

        if (!isValidCandidate) {
            this.updateStatusMessage("You have previously added this email address.", eStatusMessageType.WARNING);
        }

        return isValidCandidate;
    }

    addCandidate(candidate) {
        const { job_stage: { job_stage_id } = {}, onCandidateChange } = this.props;
        const candidates = this.getCandidates();

        const updatedCandidates = [...candidates];
        updatedCandidates.push(candidate);

        const { candidates: validatedCandidates } = validateCandidates(updatedCandidates);

        onCandidateChange(job_stage_id, validatedCandidates, false);
    }

    updateCandidate(candidate) {
        const { job_stage: { job_stage_id } = {}, onCandidateChange } = this.props;
        const candidates = this.getCandidates();

        const updatedCandidates = [...candidates].map((jobCandidate) => {
            return jobCandidate.candidate_id === candidate.candidate_id ? candidate : jobCandidate;
        });

        const { candidates : validatedCandidates } = validateCandidates(updatedCandidates);
        onCandidateChange(job_stage_id, validatedCandidates);
    }

    isEmailEditable() {
        const { areEmailsEditable = true } = this.props;
        return areEmailsEditable;
    }

    isRemovable(candidate) {
        const { areExistingCandidatesRemovable } = this.props;
        const { candidate_id } = candidate;

        return areExistingCandidatesRemovable || isNewCandidate(candidate_id);
    }

    removeCandidate(candidate) {
        const { job, job_stage, candidates, onCandidateChange } = this.props;
        const { job_id } = job;
        const { job_stage_type, job_stage_id } = job_stage;
        const { candidate_id } = candidate;

        if (!isNewCandidate(candidate_id)) {
            deleteCandidate(job_id, job_stage_type, job_stage_id, candidate_id);
        }

        const updatedCandidates = candidates.filter((item) => item !== candidate);
        const { candidates : validatedCandidates } = validateCandidates(updatedCandidates);

        onCandidateChange(job_stage_id, validatedCandidates, false);
    }

    showImportScreen() {
        this.setState({
            isImportScreenShown: true
        });
    }

    hideImportScreen() {
        this.setState({
            isImportScreenShown: false
        });
    }

    updateStatusMessage(message, type = eStatusMessageType.INFO) {
        this.setState({
            statusMessage: {
                type,
                message,
                reset: new Date()
            }
        });
    }

    render() {
        const { classes, isDisabled, job, job_stage, showOnlyNewCandidates, integrationProviders, onRefresh } = this.props;
        const { isImportScreenShown } = this.state;
        const { job_id, job_timezone } = job;
        const {
            deadline_type,
            deadline,
            ongoing_deadline_days
        } = job_stage;

        const candidates_list = this.getCandidates();

        let jobDeadline = null;

        if (deadline_type === eDeadlineType.FIXED) {
            jobDeadline = deadline;
        }

        if (deadline_type === eDeadlineType.ONGOING) {
            jobDeadline = addDays(ongoing_deadline_days);
        }

        return (
            <div className={`candidates-container ${classes}`}>
                <div className={`import-and-upload`}>
                    <div className="candidate-import">
                        {integrationProviders.includes(integrationPartner.WORLD_MANAGER) && (
                            <button
                                className={`import-button react-button info ${isDisabled ? 'disabled' : ''}`}
                                onClick={!isDisabled ? this.showImportScreen : null}
                            >
                                Import from World Manager...
                            </button>
                        )}
                    </div>
                    <div className="upload-candidates">
                        <button className={`upload-button react-button info ${isDisabled ? 'disabled' : ''}`}>
                            Upload CSV
                        </button>
                        {!isDisabled && (
                            <input
                                aria-label="Upload"
                                className="upload"
                                type="file"
                                onClick={(e) => e.target.value = null}
                                onChange={this.handleUpload}
                                accept=".csv"
                                title=""
                            />
                        )}
                    </div>
                </div>
                <CandidateAdd
                    isDisabled={isDisabled}
                    deadline={jobDeadline}
                    showCandidateDeadlineTimezone={isAdminUserSettingEnabled(eUserSettings.CANDIDATE_DEADLINE_TIMEZONE)}
                    deadlineTimezone={job_timezone}
                    beforeAdd={this.validateCandidate}
                    onAdd={this.addCandidate}
                />
                <div className={`candidates-list-container`}>
                    <div className={`candidates-list length-${Math.min(candidates_list.length, 3)}`}>
                        {candidates_list
                            // newly added candidate has "temp_" at the start of their id
                            .filter(({candidate_id}) => showOnlyNewCandidates ? candidate_id !== parseInt(candidate_id) : true)
                            .filter(({candidate_id}) => showOnlyNewCandidates ? candidate_id !== parseInt(candidate_id) : true)
                            .map((candidate) => {
                            return (
                                <Candidate
                                    key={candidate.candidate_id}
                                    classes={`${isNewCandidate(candidate.candidate_id) ? 'new-candidate' : ''}`}
                                    candidate={candidate}
                                    isEmailEditable={this.isEmailEditable()}
                                    jobTimezone={job_timezone}
                                    isRemovable={this.isRemovable(candidate)}
                                    onUpdate={this.updateCandidate}
                                    onRemove={this.removeCandidate}
                                />
                            );
                        })}
                    </div>
                </div>
                {isImportScreenShown && (
                    <CustomModal
                        classes={`candidates-import-popup open`}
                        title={`Import from World Manager`}
                        onClose={this.hideImportScreen}
                    >
                        <CandidatesImport jobId={job_id} onRefresh={onRefresh} onClose={this.hideImportScreen} />
                    </CustomModal>
                )}
            </div>
        );
    }
}

Candidates.propTypes = {
    classes: PropTypes.string,
    isDisabled: PropTypes.bool,
    job: PropTypes.object,
    job_stage: PropTypes.object,
    candidates: PropTypes.array,
    areEmailsEditable: PropTypes.bool,
    areExistingCandidatesRemovable: PropTypes.bool,
    onCandidateChange: PropTypes.func,
    showOnlyNewCandidates: PropTypes.bool,
    integrationProviders: PropTypes.array,
    onRefresh: PropTypes.func
};

Candidates.defaultProps = {
    classes: "",
    isDisabled: false,
    job: {},
    job_stage: {},
    candidates: [],
    areEmailsEditable: true,
    areExistingCandidatesRemovable: false,
    onCandidateChange: null,
    showOnlyNewCandidates: false,
    integrationProviders: [],
    onRefresh: null
};

export default Candidates;
