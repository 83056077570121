import React from 'react';

import './style.scss';


function IntegrationJobCreationSuccess() {
  return (
    <div className="job-creation-success">
      Job successfully created.
    </div>
  );
}

export default IntegrationJobCreationSuccess;
