import React from "react";
import * as PropTypes from "prop-types";

import { activeNavItemFor } from '../../routing';

import Avatar from '../Avatar';
import CustomModal from "../CustomModal";
import Search from "../Search";
import Tenant from "../Tenant";
import CustomToggleField, { eToggleSize } from "../Form/CustomToggleField";
import { eUserSettings, objectToArray } from "../../utils";

import './style.scss';


const navigationItems = [{
    label: "Manage",
    type: "link",
    route: "manage-job-list"
}, {
    label: "Assess",
    type: "link",
    route: "assess-job-list"
}, {
    label: "Libraries",
    type: "link",
    route: "library-custom"
}, {
    label: "Templates",
    type: "link",
    route: "templates-interview"
}, {
    label: "Company",
    type: "link",
    route: "company-details"
}, {
    label: "Search",
    type: "link",
    route: null // this is hijacked below based on the `label`.
}, {
    label: "New Job",
    type: "button",
    route: "manage-new-job-draft"
}];


class Navigation extends React.Component {
    constructor(props) {
        super(props);

        this.toggleUserMenu = this.toggleUserMenu.bind(this);
        this.toggleSearch = this.toggleSearch.bind(this);
        this.shallShowAdminMenu = this.shallShowAdminMenu.bind(this);
        this.toggleTenant = this.toggleTenant.bind(this);

        this.state = {
            isMenuOpen: false,
            isSearchOpen: false,
            isTenantOpen: false
        };
    }

    toggleUserMenu() {
        const { isMenuOpen } = this.state;

        this.setState({
            isMenuOpen: !isMenuOpen
        });
    }

    toggleSearch() {
        this.setState({
            isSearchOpen: !this.state.isSearchOpen
        });
    }

    shallShowAdminMenu() {
        const { company: { tenant_id } = {}, user: { is_alcami_staff } = {} } = this.props;
        return is_alcami_staff && tenant_id === "alcami";
    }

    toggleTenant() {
        this.setState({
            isTenantOpen: !this.state.isTenantOpen
        });
    }

    render() {
        const { activeRoute, urlFor, user, userSettings } = this.props;
        const { is_alcami_staff, staff_id, first_name, last_name, picture_url } = user;
        const { isMenuOpen, isSearchOpen, isTenantOpen } = this.state;

        const avatarData = {
            id: staff_id,
            first_name,
            last_name,
            picture_url
        };

        const activeNavItem = activeNavItemFor(activeRoute);

        const menuItems = navigationItems
              .map(({ label, type, route }) => {
                  if (label === "Search") {
                      return (
                          <button
                              key={label}
                              className={`nav-item ${type} ${label}`}
                              onClick={this.toggleSearch}
                          >
                              {label}
                          </button>
                      );
                  }

                  const link = urlFor(route, {});

                  if (link === null) {
                      // route is not enabled
                      return null;
                  }

                  if (type === "button") {
                      return (
                          <a role="button" key={label} className={`react-button active ripple`}
                                  href={link}>
                              {label}
                          </a>
                      );
                  }

                  const routeActiveNavItem = activeNavItemFor(route);
                  return (
                      <a
                          key={label}
                          className={`nav-item ${type} ${label} ${activeNavItem && routeActiveNavItem && (activeNavItem === routeActiveNavItem) ? 'active' : ''}`}
                          href={link}
                      >
                          {label}
                      </a>
                  );
              })
              .filter((el) => !!el);

        return (
            <div className="react-navigation">
                {isSearchOpen && (
                    <CustomModal classes={`search-dialog open`} title={`Search`} onClose={this.toggleSearch} closeOnOverlayClick={false} >
                        <Search is_manager={user.is_manager} urlFor={urlFor} onClose={this.toggleSearch} />
                    </CustomModal>
                )}
                {isTenantOpen && (
                    <CustomModal classes={`tenant-dialog open`} title={`Tenant Setup`} onClose={this.toggleTenant} closeOnOverlayClick={false} >
                        <Tenant />
                    </CustomModal>
                )}
                <div className="logo" />
                <div className="nav">
                    {menuItems}
                </div>
                <div className="user" onClick={this.toggleUserMenu}>
                    <div className={`userMenu ${isMenuOpen ? 'open' : ''} ${!is_alcami_staff ? 'blank' : ''}`}>
                        <div className="username">{(first_name + " " + last_name).trim()}</div>
                        {this.shallShowAdminMenu() && (
                            <div className={`settings`}>
                                <div className={`settings-header`}>Admin</div>
                                <div className={`settings-content`}>
                                    <div className={`settings-item`} onClick={this.toggleTenant}>Tenant Setup</div>
                                </div>
                            </div>
                        )}
                        {is_alcami_staff && (
                            <div className={`settings`}>
                                <div className={`settings-header`}>Settings</div>
                                <div className={`settings-content`}>
                                    {objectToArray(eUserSettings).map((item) => {
                                        return (
                                            <CustomToggleField
                                                classes={`${item}-toggle`}
                                                size={eToggleSize.X_SMALL}
                                                id={`${item}-toggle`}
                                                key={`${item}-toggle`}
                                                label={item.replace(/[-_]/g," ")}
                                                checked={userSettings[item]}
                                                onChange={() => this.props.onUpdateUserSetting(item, !userSettings[item])}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                        <button className="logout one" onClick={this.props.onLogout}>Log out</button>
                    </div>
                    <Avatar data={avatarData} size="m" />
                    <div className="username">{(first_name + " " + last_name).trim()}</div>
                    <button className="logout two" onClick={this.props.onLogout}>Log out</button>
                </div>
            </div>
        );
    }
}

Navigation.propTypes = {
    activeRoute: PropTypes.string,
    user: PropTypes.shape({
        staff_id: PropTypes.number.isRequired,
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        is_manager: PropTypes.bool.isRequired,
        is_alcami_staff: PropTypes.bool.isRequired,
        picture_url: PropTypes.string
    }).isRequired,
    urlFor: PropTypes.func.isRequired,
    userSettings: PropTypes.object.isRequired,
    onLogout: PropTypes.func.isRequired,
    onUpdateUserSetting: PropTypes.func.isRequired
};

export default Navigation;
