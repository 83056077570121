import React from 'react';
import * as PropTypes from 'prop-types';
import { sortBy, isEqual } from 'lodash';

import CustomModal  from '../CustomModal';
import QuestionThumbnail from "../QuestionThumbnail";

import './style.scss';
import CustomToggleField, { eToggleSize } from "../Form/CustomToggleField";


const defaultEvaluationOptions = {
    hide_video: false,
    hide_personal_details: false,
    disguise_voice: false,
    hide_feedback: false
};

class EvaluatorPreferences extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            questions: null,
            evaluation_options: null,
            status: "", // "", "sending", "success", "fail"
            statusText: "",
            isOpened: false
        };

        this.onCloseIconClick = this.onCloseIconClick.bind(this);

        this.toggleOpenState = this.toggleOpenState.bind(this);
        this.onQuestionSelectionUpdate = this.onQuestionSelectionUpdate.bind(this);
        this.onToggleUpdate = this.onToggleUpdate.bind(this);
        this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this);
        this.areAllQuestionsSelected = this.areAllQuestionsSelected.bind(this);
    }

    onCloseIconClick() {
        this.toggleOpenState(false);

        const { onClose } = this.props;
        if (onClose) {
            onClose();
        }
    }

    toggleOpenState(status) {
        const newStatus = {
            isOpened: status
        };

        if (!status) {
            newStatus.questions = null;
            newStatus.evaluation_options = null;
        }

        this.setState(newStatus);
    }

    isQuestionSelected(questionId) {
        const { questions } = this.state;
        return !!questions && questions.indexOf(questionId) >= 0;
    }

    onQuestionSelectionUpdate(questionId) {
        const { questions } = this.state;

        let newQuestions = [...questions];
        if (this.isQuestionSelected(questionId)) {
            newQuestions = newQuestions.filter((question) => (question !== questionId));
        } else {
            newQuestions.push(questionId);
        }

        this.setState({
            questions: newQuestions
        });
    }

    onToggleUpdate(e) {
        const { evaluation_options } = this.state;

        // for hide_video and hide_personal_video, do not update if disguise_voice is true
        if ((e.target.id === "hide_video" || e.target.id === "hide_personal_details") && evaluation_options.disguise_voice) {
            return false;
        }

        let newEvaluationOptions = {
            ...evaluation_options,
            [e.target.id]: e.target.value
        };

        // for disguise_voice, also override hide_video and hide_personal_details
        if (e.target.id === "disguise_voice") {
            newEvaluationOptions.hide_video = e.target.value;
            newEvaluationOptions.hide_personal_details = e.target.value;
        }

        this.setState({
            evaluation_options: newEvaluationOptions
        });
    }

    onSubmitButtonClick() {
        if (
            this.state.status === "processing" ||
            this.state.status === "success"
        ) {
            return;
        }

        const { details: { evaluators } } = this.props;
        const { questions, evaluation_options } = this.state;

        const { onUpdate, onClose } = this.props;
        const updatedQuestions = this.areAllQuestionsSelected() ? null: questions;

        if (onUpdate) {
            onUpdate(evaluators, updatedQuestions, evaluation_options);

            onClose();
        }

        this.toggleOpenState(false);
    }

    areAllQuestionsSelected() {
        const { details: { questions } } = this.props;
        const { questions: stateQuestions } = this.state;

        return isEqual(stateQuestions.sort(), (questions.map(({job_interview_step_id}) => (job_interview_step_id))).sort());
    }

    areMultipleEvaluators() {
        const { details: { evaluators } } = this.props;
        return evaluators.length > 1;
    }

    hasDataChanged() {
        const { details: { questions, evaluators } } = this.props;
        const evaluator = evaluators[0];
        const { questions: evaluatorQuestions, evaluation_options: initialEvaluationOptions } = evaluator;
        const { questions: stateQuestions, evaluation_options } = this.state;

        return !!stateQuestions && // not null
            stateQuestions.length > 0 && // not empty
            (
                // if not previously selected, make sure it is not the same as job questions
                ( !evaluatorQuestions && !(isEqual(sortBy(questions.map(({job_interview_step_id}) => job_interview_step_id)), sortBy(stateQuestions))) ) ||
                // if previously selected, make sure the content has changed
                ( !!evaluatorQuestions && !(isEqual(sortBy(evaluatorQuestions), sortBy(stateQuestions))) ) ||

                // evaluation option(s) have changed
                !(isEqual(evaluation_options, initialEvaluationOptions))
            );
    }

    componentDidUpdate(_prevProps, prevState) {
        // don't worry about it if the modal has just been closed
        if (prevState.isOpened && !this.state.isOpened) {
            return;
        }

        // open the modal
        if (!this.state.isOpened) {
            this.setState({
                isOpened: true
            });
        }
    }

    render() {
        const {
            details: {
                evaluators,
                questions,
                isDisabled
            },
            onUpdate
        } = this.props;

        const { isOpened, status, statusText, questions: stateQuestions, evaluation_options: stateEvaluationOptions } = this.state;

        const shouldComponentDisplay =
            onUpdate ? true :
            (
                isOpened && // component-level status
                !!(evaluators)
            );

        if (!shouldComponentDisplay) {
            return (<div></div>);
        }

        const newState = {};

        // only setup questions state if not already set
        if (stateQuestions === null) {
            newState.questions = (evaluators.length === 1 && evaluators[0].questions) ? evaluators[0].questions : questions.map(({job_interview_step_id}) => job_interview_step_id);
        }

        if (stateEvaluationOptions === null) {
            newState.evaluation_options = (evaluators.length === 1 && evaluators[0].evaluation_options) ? evaluators[0].evaluation_options : defaultEvaluationOptions;
        }

        if (Object.keys(newState).length) {
            this.setState(newState);
        }

        return (
            <CustomModal title={`Evaluator Preferences`} classes={`evaluator-preferences ${shouldComponentDisplay ? 'open' : ''}`}
                onClose={this.onCloseIconClick}>
                <div className="evaluators">
                    <div className="label">Evaluator{this.areMultipleEvaluators() ? "s": ""}</div>
                    <div className="values">
                        {evaluators && evaluators.map(({staff_id, first_name, last_name}) => (
                            <div key={staff_id} className="value">{first_name + (last_name ? " " + last_name : "")}</div>
                        ))}
                    </div>
                </div>
                <div className="preferences">
                    {!!(questions.length) && (
                        <div className={`questions-container ${isDisabled ? 'disabled': ''}`}>
                            <div className="header">Questions to Evaluate</div>
                            <div className={`questions`}>
                                {questions
                                    .map((job_interview_step, index) => {
                                        return (
                                            <div key={job_interview_step.job_interview_step_id} className="question">
                                                <div className="selection">
                                                    <input type="checkbox" id={`${job_interview_step.job_interview_step_id}-selection`} className="filled-in"
                                                           checked={this.isQuestionSelected(job_interview_step.job_interview_step_id)}
                                                           onChange={() => this.onQuestionSelectionUpdate(job_interview_step.job_interview_step_id)}
                                                    />
                                                    <label htmlFor={`${job_interview_step.job_interview_step_id}-selection`}></label>
                                                </div>
                                                <QuestionThumbnail
                                                    questionNumber={index + 1}
                                                    image={job_interview_step.image}
                                                    text={job_interview_step.display_text}
                                                    mediaURL={job_interview_step.video}
                                                />
                                                <div className="text">
                                                    <span>{job_interview_step.display_text}</span>
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        </div>
                    )}
                    {stateEvaluationOptions && (
                        <div className="options">
                            <div className="header">Bias Reduction</div>
                            <CustomToggleField
                                classes="hide_video"
                                id="hide_video"
                                label="Hide Video"
                                size={eToggleSize.X_SMALL}
                                checked={stateEvaluationOptions.hide_video}
                                isDisabled={stateEvaluationOptions.disguise_voice}
                                onChange={this.onToggleUpdate}
                            />
                            <CustomToggleField
                                classes="hide_personal_details"
                                id="hide_personal_details"
                                label="Hide Personal Details"
                                size={eToggleSize.X_SMALL}
                                checked={stateEvaluationOptions.hide_personal_details}
                                isDisabled={stateEvaluationOptions.disguise_voice}
                                onChange={this.onToggleUpdate}
                            />
                            <CustomToggleField
                                classes="disguise_voice"
                                id="disguise_voice"
                                label={<div>Disguise Voice <span className="superscript">BETA</span></div>}
                                size={eToggleSize.X_SMALL}
                                checked={stateEvaluationOptions.disguise_voice}
                                onChange={this.onToggleUpdate}
                            />
                            <CustomToggleField
                                classes="hide_feedback"
                                id="hide_feedback"
                                label="Hide Candidate Feedback"
                                size={eToggleSize.X_SMALL}
                                checked={stateEvaluationOptions.hide_feedback}
                                onChange={this.onToggleUpdate}
                            />
                        </div>
                    )}
                </div>
                <div className={`actions ${status}`}>
                    <div className="status-text">
                        {statusText}
                    </div>
                    {isDisabled && (
                        <button
                            type="button"
                            className="btn"
                            onClick={this.onCloseIconClick}
                        >
                            OK
                        </button>
                    )}
                    {!isDisabled && (
                        <button
                            disabled={!this.hasDataChanged()}
                            type="submit"
                            className={`btn ${status}`}
                            onClick={this.onSubmitButtonClick}
                        >
                            Apply
                        </button>
                    )}
                </div>
            </CustomModal>
        );
    }
}

EvaluatorPreferences.propTypes = {
    details: PropTypes.shape({
        evaluators: PropTypes.arrayOf(
            PropTypes.shape({
                staff_id: PropTypes.number,
                first_name: PropTypes.string,
                last_name: PropTypes.string,
                questions: PropTypes.arrayOf(PropTypes.number),
                evaluation_options: PropTypes.shape({
                    hide_video: PropTypes.bool,
                    hide_personal_details: PropTypes.bool,
                    disguise_voice: PropTypes.bool,
                    hide_feedback: PropTypes.bool
                }),
            })
        ),
        questions: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                image: PropTypes.string,
                display_text: PropTypes.string,
                video: PropTypes.string
            })
        ),
        isDisabled: PropTypes.bool
    }),
    onUpdate: PropTypes.func,
    onClose: PropTypes.func
};

EvaluatorPreferences.defaultProps = {
    details: {
        evaluators: [{
            staff_id: null,
            first_name: "",
            last_name: "",
            questions: null,
            evaluation_options: defaultEvaluationOptions,
        }],
        questions: [{
            id: null,
            image: "",
            display_text: "",
            video: ""
        }],
        isDisabled: false
    },
    onUpdate: null,
    onClose: null
};

export default EvaluatorPreferences;
