import React from "react";
import { compact, isEqual, uniq } from "lodash";
import {
    asFormData,
    defaultLibraryCategory,
    eLibraryAssetType,
    eMediaType,
    eQuillToolbarSize,
    eResponseType,
    objectToArray,
    eTextAreaType,
    eUserSettings,
    getUserSetting,
    isAlcamiStaff,
    eDropdownTypes,
    generateTemporaryId,
    getPageFeatures,
    ANSWER_TIMES,
    isAdminUserSettingEnabled,
    getIntegrationProviderFromPath,
    INTEGRATION_PROVIDER
} from "../../utils";
import {
    addLibraryItem,
    editLibraryItem,
    addVideoToLibraryItem,
    removeVideoFromLibraryItem,
    addAttachmentToLibraryItem,
    removeAttachmentFromLibraryItem
} from "../../api";
import * as PropTypes from "prop-types";
import CustomTextField from "../Form/CustomTextField";
import Dropdown from "../Dropdown";
import CustomChip from "../Form/CustomChip";
import QuestionThumbnail from "../QuestionThumbnail";
import CustomTextArea from "../Form/CustomTextArea";
import CustomModal from "../CustomModal";
import RecordVideo from "../RecordVideo";
import ConfirmDialog from "../ConfirmDialog";
import CustomToggleField, { eToggleSize } from "../Form/CustomToggleField";
import CustomList from "../Form/CustomList";
import { IconCrossThin, IconFile } from "../Icons";

import './style.scss';

const ALLOW_MULTIPLE_VIDEOS = false;
const ALLOW_MULTIPLE_ATTACHMENTS = true;
const MAX_FILE_SIZE = 20000000;
const ALLOW_UNSCORE_QUESTION = false;

class LibraryItem extends React.Component {
    constructor(props) {
        super(props);

        this.responseTypeAsDropdown = this.responseTypeAsDropdown.bind(this);
        this.toggleItem = this.toggleItem.bind(this);
        this.getCategoryItems = this.getCategoryItems.bind(this);
        this.onValueSelected = this.onValueSelected.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onAnswerSelectionChange = this.onAnswerSelectionChange.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.handleAttachment = this.handleAttachment.bind(this);
        this.removeAttachment = this.removeAttachment.bind(this);
        this.uploadRecordedVideo = this.uploadRecordedVideo.bind(this);
        this.showRecordVideoModal = this.showRecordVideoModal.bind(this);
        this.confirmRemoveUpload = this.confirmRemoveUpload.bind(this);
        this.cancelRemoveUpload = this.cancelRemoveUpload.bind(this);
        this.removeUpload = this.removeUpload.bind(this);
        this.handleEnterKey = this.handleEnterKey.bind(this);
        this.addTag = this.addTag.bind(this);
        this.removeTag = this.removeTag.bind(this);
        this.toggleApplyPostProcessing = this.toggleApplyPostProcessing.bind(this);
        this.isSaveItemEnabled = this.isSaveItemEnabled.bind(this);
        this.saveItem = this.saveItem.bind(this);

        const { integrationProvider } = this.props;
        const { enableLibraryRecording } = getPageFeatures(integrationProvider);

        this.state = {
            page_title: "",
            display_text: "",
            html_content: "",
            attachments: [],
            exclude_from_scoring: false,
            evaluation_guide: "",
            answer_selection: [],
            category: defaultLibraryCategory,
            question_tags: [],
            tagInput: "",
            previous_preparation_time: 30,
            preparation_time: 30,
            answer_time: 60,
            isLive: false,
            response_type: eResponseType.VIDEO,
            response_template: "",
            videos: [],
            apply_post_processing: true,
            errorMessage: "",
            isRecordVideoEnabled: !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) && !!window.MediaRecorder && enableLibraryRecording,
            isRecordVideoModalShown: false,
            confirmDialogProps: undefined,

            isAdditionalInformationShown: false,
            isAttachmentShown: false,
            isEvaluationGuideShown: false,
            isResponseTemplateShown: false,

            isSaving: false
        };
    }

    getCategoryItems() {
        const { items } = this.props;
        return compact(uniq(items.map(({category}) => category))).sort();
    }

    onValueSelected(name, value) {
        const updatedState = {};
        updatedState[name] = value;
        this.setState(updatedState);
    }

    responseTypeAsDropdown() {
        const { response_type } = this.state;
        const items = [eResponseType.VIDEO, eResponseType.TEXT, eResponseType.DOCUMENT];

        return items.map((item, index) => ({
            id: index,
            firstLine: item === eResponseType.TEXT ? 'written' : item,
            selected: item === response_type,
            onClick: () => this.onChange({target: { id: `response_type`, value: item }})
        }));
    }

    toggleItem(item) {
        const state = this.state;

        const updatedState = {};
        updatedState[item] = !state[item];

        this.setState(updatedState);
    }

    onChange(e) {
        const { target : {id, value}} = e;
        const newState = {
            [id]: value
        };

        if (id === "response_type") {
            if (value === eResponseType.TEXT) {
                const { preparation_time } = this.state;
                newState["previous_preparation_time"] = preparation_time;
                newState["preparation_time"] = 0;
            } else if (value === eResponseType.VIDEO) {
                const { previous_preparation_time, answer_time } = this.state;
                newState["preparation_time"] = previous_preparation_time;
                if (!answer_time) {
                    newState["answer_time"] = ANSWER_TIMES[1].value;
                }
            }
        }

        this.setState(newState);
    }

    onAnswerSelectionChange(items) {
        this.setState({
            answer_selection: items
        });
    }

    handleUpload(e) {
        const { display_text, question_tags, videos } = this.state;

        const existingVideoNames = videos.map((video) => video.name);

        const files = e.target.files;

        const newStateValues = {
            display_text,
            videos: [...videos],
            // apply_post_processing: false,
            question_tags: [...question_tags]
        };

        let uploadCount = 0;

        Array.from(files).forEach((file) => {
            if (file.size > MAX_FILE_SIZE) {
                const fileSizeString = MAX_FILE_SIZE / 1000000 + "MB";
                this.setState({
                    errorMessage: "The maximum file size is " + fileSizeString + "."
                });
            }

            // skip duplicated files
            else if (!existingVideoNames.includes(file.name)) {
                this.setState({
                    errorMessage: ""
                });

                const reader = new FileReader();

                reader.addEventListener("load", () => {
                    uploadCount++;

                    // file.mediaURL = URL.createObjectURL(file);
                    // file.mediaURL = e.target.result;
                    file.mediaURL = URL.createObjectURL(file);
                    file.value = reader.result;

                    if (ALLOW_MULTIPLE_VIDEOS) {
                        newStateValues.videos = [...newStateValues.videos, file];
                    } else {
                        newStateValues.videos = [file];
                    }

                    /*
                    // disable smart naming and tagging for now
                    const fileName = file.name.substring(0, file.name.lastIndexOf('.'));
                    const newText = fileName.substring(0, fileName.lastIndexOf('[')).trim();
                    const newTags = fileName.substring(
                        fileName.lastIndexOf("[") + 1,
                        fileName.lastIndexOf("]")
                    ).split(',').map((tag) => tag.trim());

                    // use existing file name (and only populate if there is none)
                    if (!newStateValues.display_text && newText) {
                        newStateValues.display_text = newText;
                    }

                    if (newTags.length) {
                        newStateValues.question_tags = uniq([...newStateValues.question_tags, ...newTags]);
                    }
                    */

                    if (uploadCount === files.length) {
                        this.setState(newStateValues);
                    }

                }, false);

                /*reader.addEventListener("loadend", (e) => {
                    // console.log("video loaded: e:", e);
                    // console.log("e.target:", e.target);
                    const video = document.createElement("video");
                    video.id = 'video-1';
                    video.src = e.target.result;

                    /!*video.addEventListener('loadeddata', () => {
                        this.setVideoCapture(video);
                    });*!/

                    const list = document.getElementById('upload-list');
                    list.appendChild(video);
                }, false);*/

                reader.readAsDataURL(file);
            }
        })
    }

    handleAttachment(e) {
        const { attachments } = this.state;

        const files = e.target.files;

        const newStateValues = {
            attachments: [...attachments]
        };

        let uploadCount = 0;

        Array.from(files).forEach((file) => {
            if (file.size > MAX_FILE_SIZE) {
                this.setState({
                    errorMessage: `The maximum file size is ${MAX_FILE_SIZE / 1000000} MB.`
                });
            } else {
                // skip duplicated files?
                // if (!attachmentNames.includes(file.name)) {
                    this.setState({
                        errorMessage: ""
                    });

                    const reader = new FileReader();

                    reader.addEventListener("load", async () => {
                        uploadCount++;

                        file.mediaURL = URL.createObjectURL(file);
                        file.value = reader.result;

                        if (ALLOW_MULTIPLE_ATTACHMENTS) {
                            newStateValues.attachments = [...newStateValues.attachments, file];
                        } else {
                            newStateValues.attachments = [file];
                        }

                        if (uploadCount === files.length) {
                            this.setState(newStateValues);
                        }

                    }, false);

                    reader.readAsDataURL(file);
                // }
            }
        })
    }

    removeAttachment(index) {
        const { attachments } = this.state;

        this.setState({
            attachments: attachments.filter((_attachment, i) => i !== index)
        });
    }

    /*setVideoCapture(video) {
        const output = document.getElementById('upload-list');

        const canvas = document.createElement('canvas');
        const img = document.createElement('img');

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);

        img.src = canvas.toDataURL();
        output.appendChild(img);
    }*/

    uploadRecordedVideo(video) {
        const { videos } = this.state;

        const updatedVideos = ALLOW_MULTIPLE_VIDEOS ? [...videos, video] : [video];

        this.setState({
            videos: updatedVideos,
            // apply_post_processing: true,
            isRecordVideoModalShown: false
        });
    }

    showRecordVideoModal() {
        this.setState({
            isRecordVideoModalShown: true
        });
    }

    hideRecordVideoModal() {
        this.setState({
            isRecordVideoModalShown: false
        });
    }

    confirmRemoveUpload(videoName) {
        this.setState({
            confirmDialogProps: {
                title: "Remove Video",
                message: "Are you sure you want to remove the video?",
                actions: [
                    {
                        label: "Cancel",
                        classes: "info outline",
                        onClick: () => this.cancelRemoveUpload()
                    },
                    {
                        label: "Delete",
                        classes: "warning",
                        onClick: () => this.removeUpload(videoName)
                    }
                ]
            }
        });
    }

    cancelRemoveUpload() {
        this.setState({
            confirmDialogProps: undefined
        });
    }

    removeUpload(videoName) {
        const { videos } = this.state;

        const updatedVideos = [...videos].filter((video) => {
            return video.name !== videoName;
        });

        this.setState({
            videos: updatedVideos,
            confirmDialogProps: undefined
        });
    }

    handleEnterKey(e) {
        if (e.keyCode === 13) {
            this.addTag(e.target.value.trim());
        }
    }

    addTag(tag) {
        if (tag) {
            const { question_tags } = this.state;
            if (!question_tags.includes(tag)) {
                // add tag
                this.setState({
                    question_tags: [...question_tags, tag],
                    tagInput: ""
                });
            }
        }
    }

    removeTag(tag) {
        const { question_tags } = this.state;
        const newTags = [...question_tags].filter((item) => item !== tag);

        if (!isEqual(question_tags, newTags)) {
            this.setState({
                question_tags: newTags
            })
        }
    }

    toggleApplyPostProcessing() {
        const { apply_post_processing } = this.state;

        this.setState({
            apply_post_processing: !apply_post_processing
        });
    }

    isSaveItemEnabled() {
      const { display_text, page_title, html_content, question_type, message_type, videos } = this.state;
        const { libraryAssetType } = this.props;
        return (
            question_type === eMediaType.VIDEO || message_type === eMediaType.VIDEO ? videos.length > 0 : true
        ) &&
        (
            (
                libraryAssetType === eLibraryAssetType.QUESTION && !!display_text.length
            ) ||
            (
                libraryAssetType === eLibraryAssetType.MESSAGE
                && !!page_title
                && !!html_content
                && html_content !== "<p><br></p>"
            )
        );
    }

    async saveItem() {
        this.setState({
            isSaving: true
        });
        const { libraryAssetType, item, onClose } = this.props;
        const {
            page_title,
            display_text,
            html_content,
            attachments,
            exclude_from_scoring,
            evaluation_guide,
            answer_selection,
            category,
            preparation_time,
            answer_time,
            response_type,
            response_template,
            videos,
            apply_post_processing,
            question_tags,

            isAdditionalInformationShown,
            isAttachmentShown,
            isEvaluationGuideShown,
            isResponseTemplateShown
        } = this.state;

        const isQuestionItem = libraryAssetType === eLibraryAssetType.QUESTION,
            isMessageItem = libraryAssetType === eLibraryAssetType.MESSAGE;

        const itemToSave = {
            display_text
        };

        if (isAdditionalInformationShown || isMessageItem) {
            itemToSave.html_content = html_content;
        }

        itemToSave.answer_selection =
            response_type === eResponseType.SELECTION ?
            answer_selection.filter((i) => !!i).join('|') :
            [];

        if (!item) {
            // videos are only being observed for adding an item and not updating
            if (videos.length) {
                itemToSave.videos = videos.map((video) => {
                    return video.file || video;
                });
                // backward compatibility
                itemToSave.video = itemToSave.videos[0];

                itemToSave.apply_post_processing = apply_post_processing;
            }
        }

        if (isMessageItem) {
            itemToSave.page_title = page_title;
            itemToSave.message_type = videos.length ? eMediaType.VIDEO : eMediaType.TEXT;
            if (item) {
                itemToSave.library_display_name = item.library_display_name;
            }
        }

        if (isQuestionItem) {
            itemToSave.category = category;

            if (isEvaluationGuideShown) {
                itemToSave.evaluation_guide = evaluation_guide;
            }

            itemToSave.response_type = response_type;

            if (response_type === eResponseType.VIDEO) {
                itemToSave.preparation_time = preparation_time;
            }

            if (response_type === eResponseType.TEXT && !!response_template && isResponseTemplateShown) {
                itemToSave.response_template = response_template;
            }

            itemToSave.answer_time = answer_time;
            itemToSave.question_tags = question_tags;

            if (videos.length) {
                itemToSave.question_type = "video";
            } else {
                itemToSave.question_type = "text";
            }

            itemToSave.exclude_from_scoring = exclude_from_scoring;
        }

        const formData = asFormData(itemToSave);

        let response;

        if (item) {
            const questionId =
                libraryAssetType === eLibraryAssetType.QUESTION ? item.tenant_library_question_id :
                    libraryAssetType === eLibraryAssetType.MESSAGE ? item.tenant_library_message_id :
                        null;

            // TODO: enable delete/add video for Message items (after API is ready)

            // add videos
            const videosToUpload = videos.filter(
                ({tenant_library_question_video_id}) => {
                    return !tenant_library_question_video_id;
                }
            );

            if (!!videosToUpload.length) {
                const upload = {};
                // since there can only be one video for now...
                upload.videos = videos.map((video) => {
                    return video.file || video;
                });
                // backward compatibility
                upload.video = upload.videos[0];
                await addVideoToLibraryItem(libraryAssetType, questionId, asFormData(upload));
            }

            // add attachments
            if (isAttachmentShown) {
                const attachmentsToUpload = attachments.filter(({tenant_library_question_attachment_id}) => !tenant_library_question_attachment_id);

                const uploadAttachmentPromises = attachmentsToUpload?.map(async (attachment) => {
                    await addAttachmentToLibraryItem(libraryAssetType, questionId, asFormData({attachment}));
                });

                await Promise.all(uploadAttachmentPromises);
            }

            // delete videos
            const deletedVideoIds = [];
            if (!!item.videos?.length) {
                const removeVideosPromises = item.videos.map(async (video) => {
                    const {
                        // tenant_library_message_video_id,
                        tenant_library_question_video_id
                    } = video;

                    const isMatchingVideo = !!(videos.filter(
                        (video) => video.tenant_library_question_video_id === tenant_library_question_video_id
                    ) || []).length;

                    if (!isMatchingVideo) {
                        // delete video
                        deletedVideoIds.push(tenant_library_question_video_id);
                        await removeVideoFromLibraryItem(libraryAssetType, questionId, tenant_library_question_video_id);
                    }
                });

                await Promise.all(removeVideosPromises);
            }

            // remove attachments
            if (!isAttachmentShown) {
                const removeAttachmentsPromises = item.attachments.map(async ({ tenant_library_question_attachment_id }) => {
                    await removeAttachmentFromLibraryItem(libraryAssetType, questionId, tenant_library_question_attachment_id);
                });

                await Promise.all(removeAttachmentsPromises);
            } else if (!!item.attachments?.length) {
                const removeAttachmentsPromises = item.attachments.map(async ({_name, tenant_library_question_attachment_id}) => {
                    const isMatchingAttachment = !!(attachments.filter(
                        (attachment) => attachment.tenant_library_question_attachment_id === tenant_library_question_attachment_id
                    ) || []).length;

                    if (!isMatchingAttachment) {
                        await removeAttachmentFromLibraryItem(libraryAssetType, questionId, tenant_library_question_attachment_id);
                    }
                    return true;
                });

                await Promise.all(removeAttachmentsPromises);
            }

            // update item
            response = await editLibraryItem(libraryAssetType, questionId, formData);
            response = {
                ...response,
                data: {
                    ...response.data,
                    videos: response.data.videos?.filter(({tenant_library_question_video_id}) => {
                        return !deletedVideoIds.includes(tenant_library_question_video_id);
                    })
                }
            };
        } else {
            // create
            response = await addLibraryItem(libraryAssetType, formData);

            if (attachments?.length) {
                // upload the attachments
                const questionId = response.data.tenant_library_question_id;
                const uploadAttachmentPromises = attachments?.map(async(attachment) => {
                    response = await addAttachmentToLibraryItem(libraryAssetType, questionId, asFormData({attachment}));
                });

                await Promise.all(uploadAttachmentPromises);
            }
        }

        if (!!response) {
            const { data } = response;
            const hasData = !(!data || data === "");
            if (!hasData) {
                window.location.reload();
            }
            onClose?.(!hasData ? item : data, !!item);
        }
    }

    componentDidMount() {
        const { libraryAssetType, item, isLive } = this.props;

        const isQuestionItem = libraryAssetType === eLibraryAssetType.QUESTION,
            isMessageItem = libraryAssetType === eLibraryAssetType.MESSAGE;

        if (!!item) {
            const {
                page_title,
                display_text,
                html_content,
                attachments = [],
                exclude_from_scoring,
                evaluation_guide,
                answer_selection = [],
                category,
                preparation_time,
                answer_time,
                response_type,
                response_template,
                videos,
                video,
                image,
                apply_post_processing,
                question_type,
                message_type,
                question_tags = [],
            } = item;

            const isAdditionalInformationShown = !!html_content || isMessageItem,
                isEvaluationGuideShown = isQuestionItem && !!evaluation_guide,
                isResponseTemplateShown = isQuestionItem &&
                    response_type === eResponseType.TEXT &&
                    !!response_template;

            const isAttachmentShown = !!attachments?.length;

            const videosToLoad =
                (isQuestionItem ? videos || [] :
                    isMessageItem && !!video ? [{name: image, mediaURL: video, image}] :
                        []
                ).map((video) => ({
                    ...video,
                    name: video.name || video.image || generateTemporaryId()
                }));

            // load item
            this.setState({
                page_title,
                display_text,
                html_content,
                attachments,
                exclude_from_scoring,
                evaluation_guide,
                category: category || defaultLibraryCategory,
                preparation_time: preparation_time || 0,
                answer_time,
                response_type,
                response_template,
                answer_selection,
                videos: videosToLoad,
                apply_post_processing,
                question_type,
                message_type,
                question_tags,

                isAdditionalInformationShown,
                isAttachmentShown,
                isEvaluationGuideShown,
                isResponseTemplateShown,

                originalItem: item,
                isLive
            });
        } else {
            this.setState({
                isLive,
                response_type: isLive ? eResponseType.TEXT : eResponseType.VIDEO
            });
        }
    }

    render() {
        const {
            page_title,
            display_text,
            html_content,
            attachments,
            exclude_from_scoring,
            evaluation_guide,
            answer_selection,
            category,
            preparation_time,
            answer_time,
            question_type,
            question_tags,
            message_type,
            tagInput,
            response_type,
            response_template,
            videos,
            apply_post_processing,
            errorMessage,
            isRecordVideoEnabled,
            isRecordVideoModalShown,
            confirmDialogProps,

            isAdditionalInformationShown,
            isAttachmentShown,
            isEvaluationGuideShown,
            isResponseTemplateShown,

            isSaving,
            isLive
        } = this.state;
        const { libraryAssetType, item, onClose } = this.props;
        const isVideoRequired = question_type === eMediaType.VIDEO || message_type === eMediaType.VIDEO;

        const isVideoUploadEnabled = !videos.length || ALLOW_MULTIPLE_VIDEOS;
        const showApplyPostProcessing = isAlcamiStaff() && getUserSetting(eUserSettings.APPLY_POST_PROCESSING);

        const timeTooltipIcon = <span className={`label`}>?</span>;
        const timeTooltipDescription =
            <div className={`content`}>
                Custom value examples:<br/>
                <strong>00:30</strong> - 30 seconds<br/>
                <strong>45:30</strong> - 45 minutes and 30 seconds<br/>
                <strong>01:30:00</strong> - 1 hour and 30 minutes<br/>
                Maximum value: <strong>05:59:59</strong>
            </div>;

        const hasAttachments = !!attachments?.length;
        const canUnscoreQuestion = ALLOW_UNSCORE_QUESTION &&
            (
                getIntegrationProviderFromPath() === INTEGRATION_PROVIDER.CRITERIA ||
                isAdminUserSettingEnabled(eUserSettings.UNSCORE_QUESTION)
            );

        return (
            <React.Fragment>
                {!!item && (
                    <p className={`item-notice`}>
                        <strong>Important:</strong> You should only edit a {libraryAssetType} to make cosmetic changes, such as
                        correcting spelling mistakes, fixing formatting, or providing a better-quality video for an existing {libraryAssetType}.<br/>
                        <br/>
                        When you edit a {libraryAssetType} that is being used in a job, we will show the edited version of the {libraryAssetType} to
                        candidates who access the interview from then on. The question will also appear throughout the system as though
                        it has always been your edited version.<br/>
                        <br/>
                        If you want to make meaningful changes on a {libraryAssetType} whilst maintaining historical record of
                        the {libraryAssetType} in existing jobs, you might want to create a new {libraryAssetType} instead of editing an existing one.
                    </p>
                )}
                <div className={`library-item ${libraryAssetType} item-${question_type || message_type} response-${response_type} ${isLive ? 'isLive' : ''}`}>
                    <div className={`item-details`}>
                        {libraryAssetType === eLibraryAssetType.MESSAGE && (
                            <React.Fragment>
                                <CustomTextField
                                    classes="page-title"
                                    id="page_title"
                                    required={true}
                                    label={`Title`}
                                    value={page_title}
                                    placeholder={`Type your message title here...`}
                                    onChange={this.onChange}
                                />
                                <CustomTextArea
                                    classes="html-content"
                                    type={eTextAreaType.RICH}
                                    showToolbarToggle={true}
                                    toolbarSize={eQuillToolbarSize.SMALL}
                                    id="html_content"
                                    required={true}
                                    label="Message"
                                    value={html_content}
                                    placeholder={`Type your message here...`}
                                    onChange={this.onChange}
                                />
                            </React.Fragment>
                        )}
                        {libraryAssetType !== eLibraryAssetType.MESSAGE && (
                            <React.Fragment>
                                <CustomTextArea
                                    classes="display-text"
                                    type={eTextAreaType.STANDARD}
                                    id="display_text"
                                    required={true}
                                    label={`Question`}
                                    value={display_text}
                                    placeholder={`Type your question here...`}
                                    onChange={this.onChange}
                                />
                                <CustomToggleField
                                    classes="additional-information-toggle"
                                    size={eToggleSize.X_SMALL}
                                    id="additional-information-toggle"
                                    label="Include additional information"
                                    checked={isAdditionalInformationShown}
                                    onChange={() => this.toggleItem(`isAdditionalInformationShown`)}
                                />
                                {isAdditionalInformationShown && (
                                    <CustomTextArea
                                        classes="html-content"
                                        type={eTextAreaType.RICH}
                                        showToolbarToggle={true}
                                        toolbarSize={eQuillToolbarSize.SMALL}
                                        id="html_content"
                                        required={false}
                                        value={html_content}
                                        placeholder={`Type the additional information here...`}
                                        onChange={this.onChange}
                                    />
                                )}
                                <CustomToggleField
                                    classes="attachment-toggle"
                                    size={eToggleSize.X_SMALL}
                                    id="attachment-toggle"
                                    label={`Include attachment${ALLOW_MULTIPLE_ATTACHMENTS ? '(s)' : ''}`}
                                    checked={isAttachmentShown}
                                    onChange={() => this.toggleItem(`isAttachmentShown`)}
                                />
                                {isAttachmentShown && (
                                    <div className={`attachments`}>
                                        {hasAttachments && (
                                            <div id="attachment-list" className="attachment-list">
                                                {attachments?.map((attachment, index) => {
                                                    const {
                                                        name,
                                                        mediaURL,
                                                        url
                                                    } = attachment;
                                                    return (
                                                        <div key={mediaURL} className={`attachment-item ${index % 2 === 0 ? 'highlighted' : ''}`}>
                                                            {IconFile}
                                                            <div className={`attachment-file`}>
                                                                <a
                                                                    title={name}
                                                                    className={`attachment-name`}
                                                                    href={mediaURL || url} target={`_blank`} rel={`noreferrer`}
                                                                >
                                                                    {name}
                                                                </a>
                                                            </div>

                                                            <div
                                                                title={`Remove`}
                                                                className={`attachment-remove`}
                                                                onClick={() => this.removeAttachment(index)}
                                                            >
                                                                {IconCrossThin}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                        {(ALLOW_MULTIPLE_ATTACHMENTS || !hasAttachments) && (
                                            <React.Fragment>
                                                <div className={`attachment-description`}>
                                                    Maximum file size is {MAX_FILE_SIZE / 1000000}MB.
                                                </div>
                                                <div className={"attachment-upload"}>
                                                    <div className={`react-button info upload-button`}>Upload</div>
                                                    <input
                                                        type="file"
                                                        multiple={ALLOW_MULTIPLE_ATTACHMENTS}
                                                        onChange={this.handleAttachment}
                                                        accept="*"
                                                        title=""
                                                        onClick={(e) => e.target.value = ""}
                                                    />
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </div>
                                )}
                                {canUnscoreQuestion && (
                                    <React.Fragment>
                                        <CustomToggleField
                                            classes="exclude-from-scoring-toggle"
                                            size={eToggleSize.X_SMALL}
                                            id="exclude-from-scoring-toggle"
                                            label="Turn off scoring"
                                            checked={exclude_from_scoring}
                                            onChange={() => this.toggleItem(`exclude_from_scoring`)}
                                        />
                                        <div className={`exclude-from-scoring-toggle-description`}>
                                        Question won't be scored when toggle is turned on.
                                        </div>
                                    </React.Fragment>
                                )}
                                <div className={`evaluation-guide-container ${exclude_from_scoring ? 'off' : ''}`}>
                                    <CustomToggleField
                                        classes={`evaluation-guide-toggle`}
                                        size={eToggleSize.X_SMALL}
                                        id="evaluation-guide-toggle"
                                        label="Include an evaluation guide"
                                        checked={isEvaluationGuideShown}
                                        isDisabled={exclude_from_scoring}
                                        onChange={() => exclude_from_scoring ? null : this.toggleItem(`isEvaluationGuideShown`)}
                                    />
                                    {canUnscoreQuestion && (
                                        <div className={`evaluation-guide-toggle-description`}>
                                            Evaluation guide is not applicable when scoring is turned off.
                                        </div>
                                    )}
                                    {isEvaluationGuideShown && (
                                        <CustomTextArea
                                            classes="evaluation_guide"
                                            id="evaluation_guide"
                                            required={false}
                                            placeholder={`Type the evaluation guide here...`}
                                            value={evaluation_guide}
                                            isDisabled={exclude_from_scoring}
                                            onChange={this.onChange}
                                        />
                                    )}
                                </div>

                                <div className={`item-details-more`}>
                                    <Dropdown
                                        required={false}
                                        styles="floating-label"
                                        classes="category"
                                        label="Category"
                                        items={this.getCategoryItems()}
                                        dropdownType={eDropdownTypes.CATEGORY}
                                        value={category}
                                        onSelect={this.onValueSelected}
                                        manualInput={true}
                                    />
                                    <Dropdown
                                        required={false}
                                        disabled={[eResponseType.TEXT, eResponseType.DOCUMENT, eResponseType.SELECTION].includes(response_type)}
                                        styles="floating-label"
                                        classes="preparation-time"
                                        label="Prep. Time"
                                        tooltipIcon={timeTooltipIcon}
                                        tooltipDescription={timeTooltipDescription}
                                        dropdownType={eDropdownTypes.PREPARATION_TIME}
                                        value={preparation_time}
                                        onSelect={this.onValueSelected}
                                        manualInput={true}
                                    />
                                    <div className={`response-section`}>
                                        <Dropdown
                                            styles="floating-label"
                                            classes="response-type"
                                            label="Response Type"
                                            items={this.responseTypeAsDropdown()}
                                            disabled={!!item}
                                        />
                                    </div>
                                    <Dropdown
                                        required={false}
                                        styles="floating-label"
                                        classes="answer-time"
                                        label="Answer Time"
                                        tooltipIcon={timeTooltipIcon}
                                        tooltipDescription={timeTooltipDescription}
                                        dropdownType={eDropdownTypes.ANSWER_TIME}
                                        hasUntimedOption={![eResponseType.VIDEO].includes(response_type)}
                                        value={answer_time}
                                        onSelect={this.onValueSelected}
                                        manualInput={true}
                                    />
                                    {[eResponseType.TEXT, eResponseType.SELECTION].includes(response_type) && (
                                        <div className={`response-details`}>
                                            {response_type === eResponseType.TEXT && (
                                                <React.Fragment>
                                                    <CustomToggleField
                                                        classes="response-template-toggle"
                                                        size={eToggleSize.X_SMALL}
                                                        id="response-template-toggle"
                                                        label="Include a response template"
                                                        checked={isResponseTemplateShown}
                                                        onChange={() => this.toggleItem(`isResponseTemplateShown`)}
                                                    />
                                                    {isResponseTemplateShown && (
                                                        <CustomTextArea
                                                            classes="response-template"
                                                            type={eTextAreaType.STANDARD}
                                                            id="response_template"
                                                            required={false}
                                                            placeholder={`Type the response template here...`}
                                                            value={response_template}
                                                            onChange={this.onChange}
                                                        />
                                                    )}
                                                </React.Fragment>
                                            )}
                                            {response_type === eResponseType.SELECTION && (
                                                <React.Fragment>
                                                    <div className={`answer-selection-title`}>Answer Selection</div>
                                                    <div className={`answer-selection`}>
                                                        <CustomList
                                                            items={answer_selection}
                                                            onChange={this.onAnswerSelectionChange}
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </div>
                                    )}
                                    <div className={`question-tags`}>
                                        <CustomTextField
                                            classes="tag-input"
                                            id="tagInput"
                                            required={false}
                                            label="Tags"
                                            value={tagInput}
                                            onKeyDown={this.handleEnterKey}
                                            onChange={this.onChange}
                                        />
                                        {question_tags && (
                                            <div className={`tag-list`}>
                                                {question_tags.map((tag) => (
                                                    <CustomChip key={tag} classes={'tag-name'} value={tag} onDelete={() => this.removeTag(tag)} />
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </div>

                    {!isLive && (
                        <div className={`item-files`}>
                            <div className={`item-files-title`}>Video {libraryAssetType}</div>
                            <div className="item-file-selection">
                                <div className={"item-file-upload"}>
                                    <div className={`react-button info outline upload-button ${!isVideoUploadEnabled ? 'disabled' : ''}`}>Upload</div>
                                    {isVideoUploadEnabled && (
                                        <input type="file" multiple={ALLOW_MULTIPLE_VIDEOS} onChange={this.handleUpload} accept="video/*" title="" onClick={(e) => e.target.value = ""} />
                                    )}
                                </div>
                                {isRecordVideoEnabled && (
                                    <React.Fragment>
                                        <div className={`or ${!!videos.length && !ALLOW_MULTIPLE_VIDEOS ? 'disabled' : ''}`}>or</div>
                                        <div className={"item-file-record"}>
                                            <div
                                                className={`react-button info outline record-button ${!!videos.length && !ALLOW_MULTIPLE_VIDEOS ? 'disabled' : ''}`}
                                                onClick={!videos.length || (!!videos.length && ALLOW_MULTIPLE_VIDEOS) ? this.showRecordVideoModal : null}
                                            >
                                                <span className={`record-icon`}>&nbsp;</span>
                                                Record
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}
                                {!videos.length && isVideoRequired && (
                                    <div className="error-message">Please add a video.</div>
                                )}
                                {errorMessage && (
                                    <div className="error-message">{errorMessage}</div>
                                )}
                            </div>

                            {!!videos.length && (
                                <React.Fragment>
                                    <div id="item-list" className="item-list">
                                        {videos.map((video) => {
                                            return (
                                                <div key={video.name} className={`item-file`}>
                                                    <QuestionThumbnail
                                                        key={video.name}
                                                        name={video.name}
                                                        mediaURL={video.mediaURL || video.value || video.file}
                                                        image={video.image || null}
                                                        onDelete={!!item && item.message_type === eMediaType.VIDEO ? null : this.confirmRemoveUpload}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>

                                    {showApplyPostProcessing && (
                                        <div id="video-transcoding" className="video-transcoding">
                                            <CustomToggleField
                                                classes="video-transcoding-toggle"
                                                size={eToggleSize.X_SMALL}
                                                id="video-transcoding-toggle"
                                                label="Apply Processing"
                                                checked={apply_post_processing}
                                                onChange={this.toggleApplyPostProcessing}
                                            />
                                        </div>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                    )}

                    <div className={`item-actions`}>
                        <button
                            className={`react-button submit ${this.isSaveItemEnabled() && !isSaving ? 'active ripple' : 'disabled'}`}
                            onClick={() => !isSaving && this.isSaveItemEnabled() && this.saveItem()}
                        >
                            {isSaving ? 'Saving...' : !item ? 'Create' : 'Save'}
                        </button>
                        {!isSaving && (
                            <button className={`react-button cancel button-link`} onClick={() => onClose()}>Cancel</button>
                        )}
                    </div>

                    {isRecordVideoModalShown && (
                        <CustomModal
                            classes={`record-video-modal open`}
                            closeOnOverlayClick={false}
                        >
                            <RecordVideo
                                display_text={display_text || page_title}
                                html_content={isAdditionalInformationShown || libraryAssetType === eLibraryAssetType.MESSAGE ? html_content : null}
                                onUpload={this.uploadRecordedVideo}
                                onClose={() => this.hideRecordVideoModal()}
                            />
                        </CustomModal>
                    )}

                    {confirmDialogProps && (
                        <ConfirmDialog {...confirmDialogProps} />
                    )}
                </div>
            </React.Fragment>
        );
    }
}

LibraryItem.propTypes = {
    libraryAssetType: PropTypes.oneOf(objectToArray(eLibraryAssetType)),
    // questionItemState: PropTypes.oneOf(objectToArray(eQuestionItemState)),
    items: PropTypes.array,
    item: PropTypes.object,
    onClose: PropTypes.func
};

LibraryItem.defaultProps = {
    libraryAssetType: eLibraryAssetType.QUESTION,
    items: [],
    item: null,
    onClose: null
};

export default LibraryItem;
