import {eCompanyFeatures, isCompanyFeatureEnabled} from "./utils";

const toInt = (val) => parseInt(val, 10);
const toBool = (val) => !!val;

/*
 * Routes have `matcher` functions.  Given a `path` (which does not have the
 * fragment's leading '#'), the matcher returns either `true` or a route state
 * map to indicate a matcher, or false to indicate no match. They have
 * `constructor` functions which take a route state map and return a path.
 */

const isUserManagerFunction = ({ user }) => {
    const { is_manager } = user || {};
    return !!is_manager;
};

const regexFields = [{ name: 'integrationProvider' },
    { name: 'job_id', converter: toInt },
    { name: 'job_stage_type', defaultValue: 'one-way' },
    { name: 'job_stage_id', converter: toInt, defaultValue: 1 },
    { name: 'candidate_id', converter: toInt }
];

const routes = [
    /*
     * Manage tab jobs list
     *
     *     /all/jobs
     *     /all/jobs/active
     *     /all/jobs/draft
     *     /all/jobs/archived
     */
    {
        route: 'manage-job-list',
        activeNavItem: 'manage',
        isEnabled: isUserManagerFunction,
        isDefault: ({ is_manager }) => is_manager,
        matcher: (path) => {
            if ([ '/all/jobs', '/all/jobs/active' ].includes(path)) {
                return { jobStatus: 'active' };
            } else if (path === '/all/jobs/draft') {
                return { jobStatus: 'draft' };
            } else if (path === '/all/jobs/archived') {
                return { jobStatus: 'archived' };
            } else {
                return false;
            }
        },
        constructor: ({ jobStatus = 'active' }) => {
            return '/all/jobs/' + jobStatus;
        }
    },

    /*
     * Manage tab job summary
     *
     *     /all/jobs/<job_id>/summary
     *     /<integrationProvider>/all/jobs/<job_id>/summary
     */
    {
        route: 'manage-job-details-summary',
        activeNavItem: 'manage',
        isEnabled: isUserManagerFunction,
        isDefault: () => false,
        matcher: regexRouteMatcher( /(?:\/([^/]+))?\/all\/jobs\/(\d+)\/summary$/,
            [{ name: 'integrationProvider' },
                { name: 'job_id', converter: toInt }
            ]
        ),
        constructor: ({ integrationProvider, job_id }) => {
            return (integrationProvider ? '/' + integrationProvider : '') + '/all/jobs/' + job_id + '/summary';
        }
    },

    /*
     * Manage tab job details
     *
     *     /all/jobs/<job_id>
     *     /<integrationProvider>/all/jobs/<job_id>
     */
    {
        route: 'manage-job-details',
        activeNavItem: 'manage',
        isEnabled: isUserManagerFunction,
        isDefault: () => false,
        matcher: regexRouteMatcher( /(?:\/([^/]+))?\/all\/jobs\/(\d+)$/,
                                    [{ name: 'integrationProvider' },
                                     { name: 'job_id', converter: toInt }
                                    ],
                                    [{ name: 'scrollToCandidateId', converter: toInt }]
                                  ),
        constructor: ({ integrationProvider, job_id, scrollToCandidateId }) => {
            return (integrationProvider ? '/' + integrationProvider : '') + '/all/jobs/' + job_id
                + (scrollToCandidateId ? ('?scrollToCandidateId=' + scrollToCandidateId) : '');
        }
    },

    /*
     * Manage tab job edit
     *
     *     /all/jobs/<job_id>/edit
     *     /<integrationProvider>/all/jobs/<job_id>
     */
    {
        route: 'manage-job-edit',
        activeNavItem: 'manage',
        isEnabled: isUserManagerFunction,
        isDefault: () => false,
        matcher: regexRouteMatcher( /(?:\/([^/]+))?\/all\/jobs\/(\d+)\/edit$/,
                                    [{ name: 'integrationProvider' },
                                     { name: 'job_id', converter: toInt }
                                    ]
                                  ),
        constructor: ({ integrationProvider, job_id }) => {
            return (integrationProvider ? '/' + integrationProvider : '') + '/all/jobs/' + job_id + '/edit';
        }
    },

    /*
     * Manage tab new job draft
     *
     *     /all/jobs/job/draft
     *     /<integrationProvider>/all/jobs/job/draft
     */
    {
        route: 'manage-new-job-draft',
        activeNavItem: 'manage',
        isEnabled: isUserManagerFunction,
        isDefault: () => false,
        matcher: regexRouteMatcher( /(?:\/([^/]+))?\/all\/jobs\/job\/draft$/,
                                    [{ name: 'integrationProvider' }]
                                  ),
        constructor: ({ integrationProvider }) => {
            return (integrationProvider ? '/' + integrationProvider : '') + '/all/jobs/job/draft';
        }
    },

    /*
     * Manage tab existing job draft
     *
     *     /all/jobs/job/draft/<job_id>
     *     /<integrationProvider>/all/jobs/job/draft/<job_id>
     */
    {
        route: 'manage-existing-job-draft',
        activeNavItem: 'manage',
        isEnabled: isUserManagerFunction,
        isDefault: () => false,
        matcher: regexRouteMatcher( /(?:\/([^/]+))?\/all\/jobs\/job\/draft\/(\d+)$/,
                                    [{ name: 'integrationProvider' },
                                     { name: 'job_id', converter: toInt }
                                    ]
                                  ),
        constructor: ({ integrationProvider, job_id }) => {
            return (integrationProvider ? '/' + integrationProvider : '') + '/all/jobs/job/draft/' + job_id;
        }
    },

    /*
     * Integration post-job creation "success" message
     *
     *     /<integrationProvider>/all/jobs/job/success
     */
    {
        route: 'integration-job-creation-success',
        activeNavItem: 'manage',
        isEnabled: isUserManagerFunction,
        isDefault: () => false,
        matcher: regexRouteMatcher( /(?:\/([^/]+))\/all\/jobs\/job\/success$/,
                                    [{ name: 'integrationProvider' }]
                                  ),
        constructor: ({ integrationProvider }) => ('/' + integrationProvider + '/all/jobs/job/success')
    },

    /*
     * (Integration only) Manage tab candidate edit
     *
     *     /all/jobs/<job_id>/stage/<stage_id>/candidate/<candidate_id>/edit
     *     /<integrationProvider>/all/jobs/<job_id>/stage/<job_stage_type>/<job_stage_id>/candidate/<candidate_id>/edit
     */
    {
        route: 'manage-candidate-edit',
        activeNavItem: 'manage',
        isEnabled: isUserManagerFunction,
        isDefault: () => false,
        matcher: regexRouteMatcher( /(?:\/([^/]+))?\/all\/jobs\/(\d+)(?:\/stage\/(one-way|live)\/(\d+))?\/(?:candidate|application)\/(\d+)\/edit/,
            regexFields
        ),
        constructor: ({ integrationProvider, job_id, job_stage_type = 'one-way', job_stage_id = 1, candidate_id }) => {
            return `${integrationProvider ? '/' + integrationProvider : ''}/all/jobs/${job_id}/stage/${job_stage_type}/${job_stage_id}/candidate/${candidate_id}/edit`;
        }
    },

    /*
     * Manage tab candidate profile
     *
     *     /all/jobs/<job_id>/stage/<job_stage_type>/<job_stage_id>/candidate/<candidate_id>
     *     /all/jobs/<job_id>/application/<candidate_id>
     *     /<integrationProvider>/all/jobs/<job_id>/application/<candidate_id>
     */
    {
        route: 'manage-candidate-profile',
        activeNavItem: 'manage',
        isEnabled: isUserManagerFunction,
        isDefault: () => false,
        matcher: regexRouteMatcher( /(?:\/([^/]+))?\/all\/jobs\/(\d+)(?:\/stage\/(one-way|live)\/(\d+))?\/(?:candidate|application)\/(\d+)/,
                                    [{ name: 'integrationProvider' },
                                     { name: 'job_id', converter: toInt },
                                     { name: 'job_stage_type', defaultValue: 'one-way' },
                                     { name: 'job_stage_id', converter: toInt, defaultValue: 1 },
                                     { name: 'candidate_id', converter: toInt }
                                    ]
                                  ),
        constructor: ({ integrationProvider, job_id, job_stage_type = 'one-way', job_stage_id = 1, candidate_id }) => {
            return (integrationProvider ? '/' + integrationProvider : '')
                + `/all/jobs/${job_id}`
                + (job_stage_type === 'one-way' && job_stage_id === 1 ? '/application/' : `/stage/${job_stage_type}/${job_stage_id}/candidate/`)
                + candidate_id;
        }
    },

    /*
     * Manage tab candidate profile - responses only
     *
     *     /all/jobs/<job_id>/stage/<job_stage_type>/<job_stage_id>/responses/<candidate_id>
     *     /all/jobs/<job_id>/responses/<candidate_id>
     *     /<integrationProvider>/all/jobs/<job_id>/responses/<candidate_id>
     */
    {
        route: 'manage-candidate-profile-responses-only',
        activeNavItem: 'manage',
        isEnabled: () => true,
        isDefault: () => false,
        matcher: regexRouteMatcher( /(?:\/([^/]+))?\/all\/jobs\/(\d+)(?:\/stage\/(one-way|live)\/(\d+))?\/responses\/(\d+)/,
            [{ name: 'integrationProvider' },
                { name: 'job_id', converter: toInt },
                { name: 'job_stage_type', defaultValue: 'one-way' },
                { name: 'job_stage_id', converter: toInt, defaultValue: 1 },
                { name: 'candidate_id', converter: toInt }
            ]
        ),
        constructor: ({ integrationProvider, job_id, job_stage_type = 'one-way', job_stage_id = 1, candidate_id }) => {
            return (integrationProvider ? '/' + integrationProvider : '')
                + `/all/jobs/${job_id}`
                + (job_stage_type === 'one-way' && job_stage_id === 1 ? '' : `/stage/${job_stage_type}/${job_stage_id}`)
                + '/responses/'
                + candidate_id;
        }
    },

    /*
     * Manage tab candidate evaluation (especially for live interview)
     *
     *     /all/jobs/<job_id>/stage/<stage_id>/candidate/<candidate_id>/evaluation
     *     /<integrationProvider>/all/jobs/<job_id>/stage/<stage_id>/candidate/<candidate_id>/evaluation
     */
    {
        route: 'manage-candidate-evaluation',
        activeNavItem: 'manage',
        isEnabled: isUserManagerFunction,
        isDefault: () => false,
        matcher: regexRouteMatcher( /(?:\/([^/]+))?\/all\/jobs\/(\d+)(?:\/stage\/(one-way|live)\/(\d+))?\/(?:candidate|application)\/(\d+)\/evaluation/,
            regexFields
        ),
        constructor: ({ integrationProvider, job_id, job_stage_type = 'one-way', job_stage_id, candidate_id }) => {
            return (integrationProvider ? '/' + integrationProvider : '')
                + '/all/jobs/' + job_id
                + '/stage/' + job_stage_type + '/' + job_stage_id
                + '/candidate/' + candidate_id
                + '/evaluation';
        }
    },

    /*
     * Assess tab jobs list
     *
     *     /my/jobs
     */
    {
        route: 'assess-job-list',
        activeNavItem: 'assess',
        isEnabled: () => true,
        isDefault: ({ is_manager }) => !is_manager,
        matcher: (path) => ['/my/jobs'].includes(path),
        constructor: () => '/my/jobs'
    },


    /*
     * Assess tab job summary
     *
     *     /my/jobs/<job_id>/summary
     *     /<integrationProvider>/my/jobs/<job_id>/summary
     */
    {
        route: 'assess-job-details-summary',
        activeNavItem: 'assess',
        isEnabled: () => true,
        isDefault: () => false,
        matcher: regexRouteMatcher( /(?:\/([^/]+))?\/my\/jobs\/(\d+)\/summary$/,
            [{ name: 'integrationProvider' },
                { name: 'job_id', converter: toInt }
            ]
        ),
        constructor: ({ integrationProvider, job_id }) => {
            return (integrationProvider ? '/' + integrationProvider : '') + '/my/jobs/' + job_id + '/summary';
        }
    },

    /*
     * Assess tab job details
     *
     *     /my/jobs/<job_id>
     *     /<integrationProvider>/my/jobs/<job_id>
     */
    {
        route: 'assess-job-details',
        activeNavItem: 'assess',
        isEnabled: () => true,
        isDefault: () => false,
        matcher: regexRouteMatcher( /(?:\/([^/]+))?\/my\/jobs\/(\d+)$/,
                                    [{ name: 'integrationProvider' },
                                     { name: 'job_id', converter: toInt }
                                    ],
                                    ['scrollToCandidateId']
                                  ),
        constructor: ({ integrationProvider, job_id }) => {
            return (integrationProvider ? '/' + integrationProvider : '') + '/my/jobs/' + job_id;
        }
    },

    /*
     * Assess tab candidate profile (evaluate page)
     *
     *     /my/jobs/<job_id>
     *     /<integrationProvider>/my/jobs/<job_id>
     */
    {
        route: 'assess-candidate-evaluation',
        activeNavItem: 'assess',
        isEnabled: () => true,
        isDefault: () => false,
        matcher: regexRouteMatcher( /(?:\/([^/]+))?\/my\/jobs\/(\d+)(?:\/stage\/(one-way|live)\/(\d+))?\/(?:candidate|application)\/(\d+)(\/evaluate)?/,
                                    [{ name: 'integrationProvider' },
                                     { name: 'job_id', converter: toInt },
                                     { name: 'job_stage_type', defaultValue: 'one-way' },
                                     { name: 'job_stage_id', converter: toInt, defaultValue: 1 },
                                     { name: 'candidate_id', converter: toInt },
                                     { name: 'hasTrailingEvaluate', converter: toBool, defaultValue: false }
                                    ]
                                  ),
        constructor: ({ integrationProvider, job_id, job_stage_type = 'one-way', job_stage_id = 1, candidate_id, hasTrailingEvaluate }) => {
            return (integrationProvider ? '/' + integrationProvider : '')
                + '/my/jobs/' + job_id
                + (job_stage_type === 'one-way' && job_stage_id === 1 ? '/application/' : `/stage/${job_stage_type}/${job_stage_id}/candidate/`)
                + candidate_id
                + (hasTrailingEvaluate ? '/evaluate' : '');
        }
    },

    /*
     * Integration post-evaluation "evaluations complete" message
     *
     *     /<integrationProvider>/my/jobs/<job_id>/completed
     */
    {
        route: 'integration-evaluations-completed',
        activeNavItem: 'assess',
        isEnabled: () => true,
        isDefault: () => false,
        matcher: regexRouteMatcher( /\/([^/]+)\/my\/jobs\/(\d+)\/completed$/,
                                    [{ name: 'integrationProvider' },
                                     { name: 'job_id', converter: toInt }
                                    ]
                                  ),
        constructor: ({ integrationProvider, job_id }) => {
            return (integrationProvider ? '/' + integrationProvider : '')
                + '/my/jobs/' + job_id
                + '/completed';
        }
    },

    /*
     * Library - custom questions
     *
     *     /library/custom
     */
    {
        route: 'library-custom',
        activeNavItem: 'library',
        isEnabled: isUserManagerFunction,
        isDefault: () => false,
        matcher: (path) => path === '/library/custom',
        constructor: () => '/library/custom'
    },

    /*
     * Integration - Library - custom questions
     *
     *     /<integrationProvider>/library/custom
     */
    {
        route: 'integration-library-custom',
        activeNavItem: 'library',
        isEnabled: isUserManagerFunction,
        isDefault: () => false,
        matcher: regexRouteMatcher( /\/([^/]+)\/library\/custom$/,
            [{ name: 'integrationProvider' }]
        ),
        constructor: ({ integrationProvider }) => {
            return (integrationProvider ? '/' + integrationProvider : '')
                + '/library/custom';
        }
    },

    /*
     * Library - stock questions
     *
     *     /library/stock
     */
    {
        route: 'library-stock',
        activeNavItem: 'library',
        isEnabled: isUserManagerFunction,
        matcher: (path) => path === '/library/stock',
        constructor: () => '/library/stock'
    },

    /*
     * Integration - Library - stock questions
     *
     *     /<integrationProvider>/library/stock
     */
    {
        route: 'integration-library-stock',
        activeNavItem: 'library',
        isEnabled: isUserManagerFunction,
        isDefault: () => false,
        matcher: regexRouteMatcher( /\/([^/]+)\/library\/stock$/,
            [{ name: 'integrationProvider' }]
        ),
        constructor: ({ integrationProvider }) => {
            return (integrationProvider ? '/' + integrationProvider : '')
                + '/library/stock';
        }
    },

    /*
     * Library - messages
     *
     *     /library/messages
     */
    {
        route: 'library-messages',
        activeNavItem: 'library',
        isEnabled: isUserManagerFunction,
        isDefault: () => false,
        matcher: (path) => path === '/library/messages',
        constructor: () => '/library/messages'
    },

    /*
     * Integration - Library - messages
     *
     *     /<integrationProvider>/library/messages
     */
    {
        route: 'integration-library-messages',
        activeNavItem: 'library',
        isEnabled: isUserManagerFunction,
        isDefault: () => false,
        matcher: regexRouteMatcher( /\/([^/]+)\/library\/messages$/,
            [{ name: 'integrationProvider' }]
        ),
        constructor: ({ integrationProvider }) => {
            return (integrationProvider ? '/' + integrationProvider : '')
                + '/library/messages';
        }
    },

    /*
     * Templates: Interview
     *
     *     /templates/interview
     */
    {
        route: 'templates-interview',
        activeNavItem: 'templates',
        isEnabled: isUserManagerFunction,
        isDefault: () => false,
        matcher: (path) => path === '/templates/interview',
        constructor: () => '/templates/interview'
    },

    /*
     * Interview Templates: Edit
     *
     *     /<integrationProvider>/templates/interview/<interview_template_id>/edit
     */
    {
        route: 'templates-interview-edit',
        activeNavItem: 'templates',
        isEnabled: () => true,
        isDefault: () => false,
        matcher: regexRouteMatcher( /(?:\/([^/]+))?\/templates\/interview\/(\d+)\/edit$/,
            [
                { name: 'integrationProvider' },
                { name: 'interview_template_id', converter: toInt }
            ]
        ),
        constructor: ({ integrationProvider, interview_template_id }) => {
            return (integrationProvider ? '/' + integrationProvider : '') + '/templates/interview/' + interview_template_id + '/edit';
        }
    },

    /*
     * Email Templates
     *
     *     /templates/email
     */
    {
        route: 'templates-email',
        activeNavItem: 'templates',
        isEnabled: ({ user , userSettings, company }) => {
            const { is_alcami_staff } = user || {};
            const { email_templates } = userSettings || {};
            const { features } = company || {};
            return (!!is_alcami_staff && !!email_templates)
                || (isUserManagerFunction({user}) && isCompanyFeatureEnabled(eCompanyFeatures.TEMPLATES_EMAIL, features));
        },
        isDefault: () => false,
        matcher: (path) => path === '/templates/email',
        constructor: () => '/templates/email'
    },

    /*
     * SMS Templates
     *
     *     /templates/sms
     */
    {
        route: 'templates-sms',
        activeNavItem: 'templates',
        isEnabled: ({ user , userSettings }) => {
            const { is_alcami_staff } = user || {};
            const { sms_templates } = userSettings || {};
            return !!is_alcami_staff && !!sms_templates;
        },
        isDefault: () => false,
        matcher: (path) => path === '/templates/sms',
        constructor: () => '/templates/sms'
    },

    /*
     * Company details
     *
     *     /company/details
     */
    {
        route: 'company-details',
        activeNavItem: 'company',
        isEnabled: isUserManagerFunction,
        isDefault: () => false,
        matcher: (path) => path === '/company/details',
        constructor: () => '/company/details'
    },

    /*
     * Company branding
     *
     *     /company/branding
     */
    {
        route: 'company-branding',
        activeNavItem: 'company',
        isEnabled: isUserManagerFunction,
        isDefault: () => false,
        matcher: (path) => path === '/company/branding',
        constructor: () => '/company/branding'
    },

    /*
     * Company staff
     *
     *     /company/staff
     */
    {
        route: 'company-staff',
        activeNavItem: 'company',
        isEnabled: isUserManagerFunction,
        isDefault: () => false,
        matcher: (path) => path === '/company/staff',
        constructor: () => '/company/staff'
    },

    /*
     * Company marketplace (integrations)
     *
     *     /company/marketplace
     *     /company/integrations
     */
    {
        route: 'company-marketplace',
        activeNavItem: 'company',
        isEnabled: ({ user , company }) => {
            const { is_manager } = user || {};
            const { features } = company || {};
            return !!is_manager && (features || []).includes('integrations');
        },
        isDefault: () => false,
        matcher: (path) => path === '/company/marketplace' || path === '/company/integrations',
        constructor: () => '/company/marketplace'
    },

    /*
     * Company reporting
     *
     *     /company/reporting
     */
    {
        route: 'company-reporting',
        activeNavItem: 'company',
        isEnabled: ({ user , userSettings }) => {
            const { is_alcami_staff } = user || {};
            const { reporting } = userSettings || {};
            return !!is_alcami_staff && !!reporting;
        },
        isDefault: () => false,
        matcher: (path) => path === '/company/reporting',
        constructor: () => '/company/reporting'
    },

    /*
     * Default route so we're guaranteed to return something.
     */
    {
        route: 'not-found',
        activeNavItem: null,
        isEnabled: () => true,
        isDefault: () => false,
        matcher: (path) => {
            console.error('No route for path "' + path + '".');
            return { path };
        },
        constructor: () => {
            throw Error("Can't construct the 'not-found' route!");
        }
    }
];



/**
 * Turn a query string into a map.
 */
export function parseQueryString(queryString = '') {
    return queryString.split('&').reduce((result, kv) => {
        const [k, v] = kv.split('=');
        return { ...result, [k]: v };
    }, {});
}



/**
 * Given a URL fragment, determine the route, returning:
 *
 *     { route, activeNavItem, routeState }
 */
export function parseRoute({ user = {}, company = { features: [] }, userSettings = {} }, path, queryString) {
    // Default to '/' if empty.
    const pathToMatch = path || '/';
    const queryParams = parseQueryString(queryString);

    for (const routeItem of routes) {
        const { route, activeNavItem, isEnabled, matcher } = routeItem;
        if (!isEnabled({ user, company, userSettings })) {
            continue;
        }

        const match = matcher(pathToMatch, queryParams);
        if (match) {
            return { route, activeNavItem, routeState: match === true ? {} : match };
        }
    }

    throw Error("Somehow the default 'not-found' route didn't match!");
}



/**
 * Given a route name and route state, return the URL to use to link to that
 * route, or null if the route is not enabled for this user.
 *
 * IMPORTANT USAGE NOTE: rather than calling this directly from a component, you
 * should probably arrange for the App component to give you a `urlFor` prop,
 * which is this function, but with the first argument (the context) already
 * taken care of.
 */
export function urlFor({ user = {}, company = { features: [] }, userSettings = {} }, routeName, routeState = {}) {
    const route = routes.find(({ route: r }) => routeName === r);
    if (!route) {
        throw Error('No such route "' + route + '"');
    } else if (!route.isEnabled({ user, company, userSettings })) {
        return null;
    }

    return '#' + route.constructor(routeState);
}


/**
 * Identify the active nav item for a given route name.
 */
export function activeNavItemFor(routeName) {
    const route = routes.find(({ route: r }) => routeName === r);
    if (!route) {
        throw Error('No such route "' + route + '"');
    } else {
        return route.activeNavItem;
    }
}



/**
 * Produces a router matcher from a regex. Produces a route match from any path
 * that matches the regex.  If the regex has capturing groups, then `fields`
 * should contain `{name, isInt}` field descriptors to populate the resulting
 * route state object. If the route needs to allow for query params, then
 * `expectedQueryParams` should contain `{name, isInt}` field descriptors for
 * any expected params.
 *
 * For example:
 *
 *     regexRouteMatcher( /\/all\/jobs\/(\d+)$/,
 *                        [{name: 'job_id', converter: toInt}],
 *                        [{name: 'scrollTo', converter: toInt}]
 *                      )
 *
 * matched on '/all/jobs/1234' with queryParams `{scrollTo: "5678", ab: "0"}`
 * will produce `{job_id: 1234, scrollTo: 5678}`.
 */
function regexRouteMatcher(regex, fields = [], expectedQueryParams = []) {
    return (path, queryParams) => {
        const match = path.match(regex);
        if (match && fields.length === 0) {
            return true;
        } else if (match) {
            const pathParams = fields.reduce((accum, { name, converter = (val) => val, defaultValue = null }, index) => {
                const rawValue = match[index + 1];
                return {
                    ...accum,
                    [name]: (typeof rawValue === 'undefined' || rawValue === null) ? defaultValue : converter(rawValue)
                };
            }, {});

            const keptQueryParams = expectedQueryParams.reduce((accum, { name, converter = (val) => val }) => {
                if (queryParams[name]) {
                    return { ...accum, [name]: converter(queryParams[name]) };
                } else {
                    return accum;
                }
            }, {});

            return { ...keptQueryParams, ...pathParams };
        } else {
            return false;
        }
    };
}


/**
 * Get user's default page
 */
export const getDefaultPage = (user) => {
    const route = routes.find(({ isDefault }) => isDefault(user));
    if (!route) {
        throw Error("No default page found.");
    }

    return '#' + route.constructor(user);
};
