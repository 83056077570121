import React from "react";
import * as PropTypes from "prop-types";

import './style.scss';


const CustomRadioField = ({classes, required = false, label, items, onChange}) => {
    return (
        <div className={`customRadioField ${classes}`}>
            <div className="label">
                {label}
                {!!required && (<sup>*</sup>)}
            </div>

            {items.map(({id, label: itemLabel, value}) => (
                <div key={value} className={`item item-${value}`}>
                    <label>
                        <input
                            id={id}
                            type="radio"
                            value={value}
                            checked={value}
                            onChange={onChange}
                        />
                        {itemLabel}
                    </label>
                </div>
            ))}
        </div>
    );
};

CustomRadioField.propTypes = {
    id: PropTypes.string,
    classes: PropTypes.string,
    required: PropTypes.bool,
    label: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string
        })
    ),
    // defaultValue: PropTypes.string,
    onChange: PropTypes.func
};

CustomRadioField.defaultProps = {
    id: null,
    classes: "",
    required: false,
    label: "",
    items: [],
    // defaultValue: "",
    onChange: null
};

export default CustomRadioField;
