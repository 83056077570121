import React from 'react';
import * as PropTypes from 'prop-types';

import CustomModal from '../CustomModal';
import CustomTextField from "../Form/CustomTextField";
import { createStaff, updateStaff } from '../../api';
import { asFormData, eStatusMessageType, eVerificationType, isValidEmail } from '../../utils';
import StatusMessage from "../../components/StatusMessage";
import Dropdown from "../../components/Dropdown";
import { IconCrossThin, IconPencil, IconPlus, IconProfile } from '../Icons';

import './style.scss';


const eStaffEditAction = {
    CREATE: "create",
    UPDATE: "update"
};

class StaffDetails extends React.Component {
    constructor(props) {
        super(props);

        const {
            staff_id,
            first_name,
            last_name,
            email,
            is_manager,
            loginModes,
            login_mechanism,
            position,
            business_unit,
            location,
            phone,
            picture_url
        } = this.props;

        this.state = {
            staff_id,
            first_name: first_name || "",
            last_name: last_name || "",
            email: email || "",
            is_email_valid: true,
            is_manager,
            login_mechanism: login_mechanism || (loginModes.can_login_with_email ? eVerificationType.VERIFICATION_CODE : eVerificationType.PASSWORD),
            position,
            business_unit,
            location: location || "",
            phone: phone || "",
            picture_url,
            pictureFile: null,
            pictureImage: null,
            isImageError: false,
            statusMessage: {
                message: null,
                reset: null
            }
        };

        this.onInputChange = this.onInputChange.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        this.handleImageUpload = this.handleImageUpload.bind(this);
        this.hasFieldsChanged = this.hasFieldsChanged.bind(this);
        this.isReadyForSaving = this.isReadyForSaving.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.updateStatusMessage = this.updateStatusMessage.bind(this);
        this.removePicture = this.removePicture.bind(this);

        this.getSubmitButtonClassNames = this.getSubmitButtonClassNames.bind(this);
    }

    isImage(mimeType) {
        const acceptableImageFormats = ["image/png", "image/jpg", "image/jpeg"];
        return acceptableImageFormats.includes(mimeType);
    }

    validateEmail() {
        const { email } = this.state;
        this.setState({
            is_email_valid: isValidEmail(email)
        });
    }

    handleImageUpload(event) {
        const file = event.target.files[0];

        if (!this.isImage(file.type)) {
            this.setState({
                isImageError: true
            });
            return;
        }

        const reader  = new FileReader();

        reader.addEventListener("load", () => {
            this.setState({
                pictureFile: file,
                pictureImage: reader.result,
                picture_url: null
            });
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    removePicture() {
        this.setState({
            pictureFile: null,
            pictureImage: null,
            picture_url: null
        });
    }

    getSubmitButtonClassNames(action) {
        let returnValue = 'submit-button react-button';

        if (
            this.isReadyForSaving() &&
            (
                action === eStaffEditAction.CREATE ||
                (action === eStaffEditAction.UPDATE && this.hasFieldsChanged())
            )
        ) {
            returnValue += ' active ripple';
        } else {
            returnValue += ' disabled';
        }

        return returnValue;
    }

    hasFieldsChanged() {
        const {
            first_name: pFirstName,
            last_name: pLastName,
            email: pEmail,
            is_manager: pIsManager,
            login_mechanism: pLoginMechanism,
            position: pPosition,
            business_unit: pBusinessUnit,
            location: pLocation,
            phone: pPhone,
            picture_url: pPictureUrl
        } = this.props;

        const {
            first_name: sFirstName,
            last_name: sLastName,
            email: sEmail,
            is_manager: sIsManager,
            login_mechanism: sLoginMechanism,
            position: sPosition,
            business_unit: sBusinessUnit,
            location: sLocation,
            phone: sPhone,
            picture_url: sPictureUrl,
            pictureFile: sPictureFile
        } = this.state;

        return (
            pFirstName !== sFirstName ||
            pLastName !== sLastName ||
            pEmail !== sEmail ||
            pIsManager !== sIsManager ||
            pLoginMechanism !== sLoginMechanism ||
            pPosition !== sPosition ||
            pBusinessUnit !== sBusinessUnit ||
            pLocation !== sLocation ||
            pPhone !== sPhone ||
            ( pPictureUrl !== null && sPictureUrl === null ) ||
            sPictureFile !== null
        );
    }

    isReadyForSaving() {
        const { first_name, last_name, email, phone, position, is_manager } = this.state;
        return (
            isValidEmail(email) &&
            !!first_name && !!last_name &&
            (is_manager ? !!phone && !!position : true)
        );
    }

    onInputChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    updateStatusMessage(message) {
        this.setState({
            statusMessage: {
                message,
                reset: new Date()
            }
        })
    }

    async onCreate() {
        const { first_name, last_name, email, is_manager, login_mechanism, position, business_unit, location, phone, pictureFile } = this.state;
        const { onAfterCreate, onClose } = this.props;

        const data = {
            first_name,
            last_name,
            email,
            is_manager: !!is_manager,
            login_mechanism,
            position: position || null,
            business_unit: business_unit || null,
            location: location || null,
            phone: phone || null
        };

        if (pictureFile) {
            data.new_picture = pictureFile;
        }

        const formData = asFormData(data);

        const response = await createStaff(formData);

        if (response.error) {
            this.updateStatusMessage("Error. Please try again.");
        } else {
            onAfterCreate(response);
            onClose();
        }
    }

    async onUpdate() {
        const { picture_url: oldPictureUrl } = this.props;
        const { staff_id, first_name, last_name, email, is_manager, login_mechanism, position, business_unit, location, phone, picture_url: newPictureUrl, pictureFile } = this.state;

        const data = {
            staff_id,
            first_name,
            last_name,
            email,
            is_manager,
            login_mechanism,
            position,
            business_unit,
            location,
            phone: phone || null
        };

        if (pictureFile) {
            // picture added / updated
            data.new_picture = pictureFile;
        } else if (oldPictureUrl !== null && newPictureUrl === null) {
            // picture deleted
            data.is_remove_picture = true;
        } else {
            // picture unchanged, no need to send to server
        }

        const formData = asFormData(data);
        const response = await updateStaff(staff_id, formData);

        if (response.error) {
            this.updateStatusMessage("Error. Please try again.");
        } else {
            const { onAfterUpdate, onClose } = this.props;
            onAfterUpdate(response);
            onClose();
        }
    }

    render() {
        const {
            staff_id,
            first_name,
            last_name,
            email,
            is_email_valid,
            is_manager,
            login_mechanism,
            position,
            business_unit,
            location,
            phone,
            picture_url,
            pictureImage,
            isImageError,
            statusMessage: { message, reset },
        } = this.state;
        const { loginModes, onClose } = this.props;
        const showLoginModes = loginModes.can_login_with_password && loginModes.can_login_with_email;
        const action = !staff_id ? eStaffEditAction.CREATE : eStaffEditAction.UPDATE;
        const title = action === eStaffEditAction.CREATE ? "Add New Staff Member" : "Edit Staff Member";

        const roleDropdownItems = [{
            id: 1,
            firstLine: "evaluator",
            selected: !is_manager,
            onClick: () => {
                this.onInputChange({ target: { id: "is_manager", value: false } });
            }
          }, {
            id: 2,
            firstLine: "manager",
            selected: is_manager,
            onClick: () => {
                this.onInputChange({ target: { id: "is_manager", value: true } });
            }
          }];

        const loginModesItems = [
            {
                id: 1,
                firstLine: "Password",
                selected: login_mechanism === eVerificationType.PASSWORD,
                onClick: () => {
                    this.onInputChange({ target: { id: "login_mechanism", value: eVerificationType.PASSWORD } })
                }
            },
            {
                id: 2,
                firstLine: "Email Verification Code",
                selected: login_mechanism === eVerificationType.VERIFICATION_CODE,
                onClick: () => {
                    this.onInputChange({ target: { id: "login_mechanism", value: eVerificationType.VERIFICATION_CODE } })
                }
            },
        ];

        const hasPicture = !!picture_url || !!pictureImage;

        return (
            <CustomModal classes={`staff-details open`} title={title} onClose={onClose} closeOnOverlayClick={false}>
                <div className="staff-form">
                    <div className="picture">
                        <div className="picture-upload">
                            {!hasPicture && (
                                <React.Fragment>
                                    <div className="placeholder" />
                                    {IconProfile}
                                </React.Fragment>
                            )}
                            {hasPicture && (
                                <React.Fragment>
                                    <div className="loaded">
                                      <img src={pictureImage ? pictureImage : picture_url} alt="Staff avatar" />
                                    </div>
                                    <span className="image-remove" onClick={this.removePicture}>{IconCrossThin}</span>
                                </React.Fragment>
                            )}

                            <div className="uploads">
                                <div className={`image-button upload`}>
                                    <span className={`icon ${hasPicture ? 'icon-edit' : 'icon-add'}`}>{hasPicture ? IconPencil : IconPlus}</span>
                                    <input
                                        type="file"
                                        onClick={(e) => e.target.value = null}
                                        onChange={this.handleImageUpload}
                                        accept="image/*"
                                        title=""
                                    />
                                </div>
                            </div>
                        </div>
                        {!!isImageError && (
                            <div className="upload-error">Please upload a valid picture file (png/jpg/jpeg).</div>
                        )}
                    </div>
                    <div className="email">
                        <CustomTextField id="email" required={true} isError={!is_email_valid} autoFocus={true} label="Email" value={email} onChange={this.onInputChange} onBlur={this.validateEmail} />
                    </div>
                    <div className="role">
                        <Dropdown
                            styles="floating-label"
                            classes="role-dropdown"
                            label="Role*"
                            items={roleDropdownItems}
                        />
                    </div>
                    {showLoginModes && (
                        <div className={`login_modes`}>
                            <Dropdown
                                styles="floating-label"
                                classes="login-modes-dropdown"
                                label="Login Method*"
                                items={loginModesItems}
                            />
                        </div>
                    )}
                    <div className="first_name">
                        <CustomTextField id="first_name" required={true} label="First Name" value={first_name} onChange={this.onInputChange} />
                    </div>
                    <div className="last_name">
                        <CustomTextField id="last_name" required={true} label="Last Name" value={last_name} onChange={this.onInputChange} />
                    </div>
                    <div className="phone">
                        <CustomTextField
                            id="phone"
                            required={is_manager}
                            label="Phone"
                            onChange={this.onInputChange}
                            value={phone}
                        />
                    </div>
                    <div className="position">
                        <CustomTextField id="position" required={is_manager} label="Position" value={position} onChange={this.onInputChange} />
                    </div>
                    <div className="business_unit">
                        <CustomTextField id="business_unit" label="Department" value={business_unit} onChange={this.onInputChange} />
                    </div>
                    <div className="location">
                        <CustomTextField id="location" label="Location" value={location} onChange={this.onInputChange} />
                    </div>
                </div>
                <div className="staff-submit">
                    <div className="status-container">
                        <StatusMessage
                            type={eStatusMessageType.WARNING}
                            message={message}
                            duration={5000}
                            reset={reset}
                        />
                    </div>
                    {action === eStaffEditAction.CREATE && (
                        <button
                            className={this.getSubmitButtonClassNames(action)}
                            onClick={() => !!this.isReadyForSaving() && this.onCreate()}
                        >
                            Create
                        </button>
                    )}
                    {action === eStaffEditAction.UPDATE && (
                        <button
                            className={this.getSubmitButtonClassNames(action)}
                            onClick={() => !!this.hasFieldsChanged() && !!this.isReadyForSaving() && this.onUpdate()}
                        >
                            Update
                        </button>
                    )}
                </div>
            </CustomModal>
        )
    }
}

StaffDetails.propTypes = {
    staff_id: PropTypes.number,
    loginModes: PropTypes.object,
    onAfterCreate: PropTypes.func,
    onAfterUpdate: PropTypes.func,
    onClose: PropTypes.func
};

StaffDetails.defaultProps = {
    staff_id: null,
    loginModes: {},
    onAfterCreate: null,
    onAfterUpdate: null,
    onClose: null
};

export default StaffDetails;
