import React from "react";
import PropTypes from "prop-types";
import { IconCopy } from "../Icons";
import {copyToClipboard} from "../../utils";

import './style.scss';


class ShortLink extends React.Component {
    constructor(props) {
        super(props);
        this.copyLink = this.copyLink.bind(this);
        this.showOutcome = this.showOutcome.bind(this);
        this.hideOutcome = this.hideOutcome.bind(this);
        this.state = {
            stateValue: '',
            isOutcomeShown: false
        };
    }

    copyLink(value) {
        copyToClipboard(value);
        this.showOutcome();
    }

    showOutcome() {
        this.setState({
            isOutcomeShown: true
        });
        setTimeout(() => this.hideOutcome(), 2000);
    }

    hideOutcome() {
        this.setState({
            isOutcomeShown: false
        });
    }

    render() {
        const { classes, label, value } = this.props;
        const { isOutcomeShown, stateValue } = this.state;
        const displayedValue = value || stateValue;

        if (!displayedValue) {
            return null;
        } else {
            const content = label || displayedValue;
            return (
                <div className={`short-link ${classes ? classes : ''}`}>
                    <div className="short-link-content">{content}</div>
                    <img className="icon-copy" alt="Copy icon" src={IconCopy} onClick={() => this.copyLink(displayedValue)} />
                    <div className={`outcome ${isOutcomeShown ? "shown" : ""}`}>Copied.</div>
                </div>
            );
        }
    }
}

ShortLink.propTypes = {
    classes: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string
};

export default ShortLink;
