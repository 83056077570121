import React from "react";
import {IconInformation, IconStar} from "../../../Icons";

import "./style.scss";


const RatingsTooltip = () => (
    <div className="ratings-tooltip">
        {IconInformation}
        <div className="tooltip">
            <div className="rating-paragraph">
                {IconStar}{IconStar}{IconStar}{IconStar}{IconStar}
                <div className={`description`}>
                    An excellent response provided, question
                    answered with enough detail and insight
                    and communication considered strong.
                </div>
            </div>
            <div className="rating-paragraph">
                {IconStar}{IconStar}{IconStar}{IconStar}
                <div className={`description`}>
                    A good answer provided, included
                    sufficient detail/depth, communicated
                    well.
                </div>
            </div>
            <div className="rating-paragraph">
                {IconStar}{IconStar}{IconStar}
                <div className={`description`}>
                    Adequate answer provided, may have
                    lacked enough detail/depth,
                    communication was considered adequate.
                </div>
            </div>
            <div className="rating-paragraph">
                {IconStar}{IconStar}
                <div className={`description`}>
                    Limited answer or details that did not
                    address the question/ demonstrated
                    communication below expectation.
                </div>
            </div>
            <div className="rating-paragraph">
                {IconStar}
                <div className={`description`}>
                    Did not answer the question/poor
                    communication.
                </div>
            </div>
        </div>
    </div>
);

export default RatingsTooltip;
