import React from "react";
import * as PropTypes from "prop-types";
import QuestionThumbnail, { eMediaTypes } from "../QuestionThumbnail";
import {
    convertDateToCommonFormat,
    eDeadlineDay,
    eInterviewStepType,
    eJobStageTypes,
    eResponseType,
    eStatusMessageType,
    objectToArray
} from "../../utils";
import { retakeInterview } from "../../api";
import CustomDatePicker from "../Pickers/CustomDatePicker";
import QuestionItem from "../JobDraft/StepQuestions/QuestionItem";
import { get } from "lodash";
import ShortLink from "../ShortLink";
import CustomToggleField from "../Form/CustomToggleField";

import './style.scss';


class CandidateProfileAdmin extends React.Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.toggleRetake = this.toggleRetake.bind(this);
        this.retakeQuestions = this.retakeQuestions.bind(this);
        this.isQuestionSelected = this.isQuestionSelected.bind(this);
        this.isRetakeAlreadyActive = this.isRetakeAlreadyActive.bind(this);

        this.state = {
            loading: false,
            originalSelectedJobInterviewStepIds: null,
            selectedJobInterviewStepIds: null,
            retakeDeadline: null,
            statusType: null,
            statusText: null
        };
    }

    onChange(e) {
        const { target: { id, value} } = e;
        this.setState({
            [id]: value
        });
    }

    toggleRetake(job_interview_step_id) {
        const { selectedJobInterviewStepIds = [] } = this.state;
        let updatedSelectedJobInterviewStepIds = selectedJobInterviewStepIds;

        if (this.isQuestionSelected(job_interview_step_id)) {
            // remove from list
            updatedSelectedJobInterviewStepIds = updatedSelectedJobInterviewStepIds.filter((id) => id !== job_interview_step_id);
        } else {
            // add to list
            updatedSelectedJobInterviewStepIds.push(job_interview_step_id);
        }

        this.setState({
            selectedJobInterviewStepIds: updatedSelectedJobInterviewStepIds
        });
    }

   async retakeQuestions() {
        const { candidate: { candidate_id }, job_stage: { job_id, job_stage_id, job_stage_type }, onUpdate } = this.props;
        const { selectedJobInterviewStepIds, retakeDeadline } = this.state;

        const response = await retakeInterview(job_id, job_stage_type, job_stage_id, candidate_id, selectedJobInterviewStepIds || [], retakeDeadline);

        if (response.error) {
            this.setState({
                statusType: eStatusMessageType.WARNING,
                statusText: "Error occurred. Please refresh and try again."
            });
        } else {
            this.setState({
                originalSelectedJobInterviewStepIds: [...selectedJobInterviewStepIds],
                statusType: eStatusMessageType.INFO,
                statusText: "Retake interview submitted."
            });

            !!onUpdate && onUpdate();
        }
    }

    isQuestionSelected(jobInterviewStepId) {
        const { selectedJobInterviewStepIds } = this.state;
        return (selectedJobInterviewStepIds || []).includes(jobInterviewStepId);
    }

    isRetakeAlreadyActive(jobInterviewStepId) {
        const { originalSelectedJobInterviewStepIds } = this.state;
        return (originalSelectedJobInterviewStepIds || []).includes(jobInterviewStepId);
    }

    addSelectedQuestionId(jobInterviewStepId) {
        const { selectedJobInterviewStepIds } = this.state;
        let updatedSelectedJobInterviewStepIds = selectedJobInterviewStepIds || [];

        if (!(updatedSelectedJobInterviewStepIds.includes(jobInterviewStepId))) {
            updatedSelectedJobInterviewStepIds.push(jobInterviewStepId);
            this.setState({
                selectedJobInterviewStepIds: updatedSelectedJobInterviewStepIds
            });
        }
    }

    componentDidMount() {
        const { candidate : { candidate_responses = [], deadline } = {}, job_stage: { job_interview_steps = [] } }  = this.props;

        // include previously reset questions (i.e. is_completed is false)
        const selectedJobInterviewStepIds = job_interview_steps.filter(({interview_step_type, job_interview_step_id}) => {
            const { is_completed } = candidate_responses.find((candidate_response) => candidate_response.job_interview_step_id === job_interview_step_id) || {};
            return interview_step_type === eInterviewStepType.QUESTION && !is_completed;
        }).map(({job_interview_step_id}) => job_interview_step_id);

        this.setState({
            originalSelectedJobInterviewStepIds: [...selectedJobInterviewStepIds],
            selectedJobInterviewStepIds,
            retakeDeadline: deadline
        });
    }

    render() {
        const { retakeDeadline, statusType, statusText } = this.state;
        const {
            job_stage: { job_interview_steps = [] } = {},
            candidate = {}
        } = this.props;

        const { candidate_responses = [], interview_url_id, is_deadline_expired } = candidate;

        const interviewURL = interview_url_id ?
            window.location.origin + '/interview?id=' + interview_url_id :
            null;

        return (
            <div className={`candidate-profile-admin`}>
                <div className="candidate-labels interview-url">
                    <div className="label">Interview URL</div>
                    <ShortLink value={interviewURL} />
                </div>
                <div className="candidate-labels">
                    <div className="label">Candidate</div>
                    <div className="values">
                        {[candidate].map(({candidate_id, first_name, last_name}) => (
                            <div key={candidate_id} className="value">{first_name + (last_name ? " " + last_name : "")}</div>
                        ))}
                    </div>
                </div>
                {!!job_interview_steps.length && !!candidate_responses.length && (
                    <div className="form-container">
                        <div className="questions">
                            <div className="headers">
                                <span className="header">Question</span>
                                <span className="header">Response</span>
                                <span className="header">Retake</span>
                            </div>
                            {job_interview_steps
                                .filter(({interview_step_type}) => interview_step_type === eInterviewStepType.QUESTION)
                                .map((job_interview_step, index) => {
                                    const { display_text, image, video, job_interview_step_id, response_type } = job_interview_step;

                                    const {
                                        response_text,
                                        media = [],
                                        thumbnail
                                    } = candidate_responses.find(({job_interview_step_id: questionId}) => job_interview_step_id === questionId) || {};

                                    const item = {
                                        question_number: index + 1,
                                        display_text,
                                        image,
                                        video
                                    };

                                    const mediaURL = get(media, [0, "url"], "");
                                    const showResponseThumbnail = (thumbnail && mediaURL) || !!response_text;

                                    const isRetakeAlreadyActive = this.isRetakeAlreadyActive(job_interview_step_id);

                                    return (
                                        <div key={index} className="question-with-response-and-selection">
                                            <QuestionItem item={item} />
                                            {showResponseThumbnail && (
                                                <QuestionThumbnail
                                                    classes={"response"}
                                                    image={thumbnail}
                                                    mediaURL={mediaURL}
                                                    text={response_text}
                                                    onSelect={null}
                                                />
                                            )}
                                            {(
                                                (response_type === eResponseType.VIDEO && !showResponseThumbnail) ||
                                                (response_type === eResponseType.TEXT && !response_text) ||
                                                (response_type === eResponseType.DOCUMENT)
                                             ) && (
                                                <span className={`response`}>-</span>
                                            )}
                                            <CustomToggleField
                                                classes={`retake-toggle`}
                                                id={`retake-toggle-${job_interview_step_id}`}
                                                label=""
                                                checked={this.isQuestionSelected(job_interview_step_id)}
                                                isDisabled={isRetakeAlreadyActive}
                                                onChange={() => !isRetakeAlreadyActive && this.toggleRetake(job_interview_step_id)}
                                            />
                                        </div>
                                    );
                                }
                            )}
                        </div>

                        <CustomDatePicker
                            id={`retakeDeadline`}
                            classes={`deadline`}
                            required={is_deadline_expired}
                            label={`Deadline`}
                            value={retakeDeadline}
                            minDate={eDeadlineDay.YESTERDAY}
                            onChange={(e) => this.onChange({
                                target: {
                                    id: `retakeDeadline`,
                                    value: convertDateToCommonFormat(e)
                                }
                            })}
                        />

                        <div className={`actions`}>
                            <div className={`status-text ${statusType}`}>{statusText}</div>
                            <button
                                className={`retake-button react-button info`}
                                onClick={this.retakeQuestions}
                            >
                                Retake
                            </button>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

CandidateProfileAdmin.propTypes = {
    candidate: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
        candidate_responses: PropTypes.arrayOf(PropTypes.shape({
            job_interview_step_id: PropTypes.number,
            answerMedia: PropTypes.shape({
                thumbnail: PropTypes.string,
                url: PropTypes.string,
                type: PropTypes.oneOf(objectToArray(eMediaTypes))
            })
        }))
    }),
    job_stage: PropTypes.shape({
        job_id: PropTypes.number.isRequired,
        job_stage_id: PropTypes.number.isRequired,
        job_stage_type: PropTypes.oneOf(objectToArray(eJobStageTypes)),
        job_interview_steps: PropTypes.array
    }),
    onUpdate: PropTypes.func
};

CandidateProfileAdmin.defaultProps = {
    job: {},
    candidate: {},
    job_stage: {
        job_id: null,
        job_stage_id: null,
        job_stage_type: eJobStageTypes.ONE_WAY,
        job_interview_steps: []
    },
    onUpdate: null
};

export default CandidateProfileAdmin;
