import React from 'react';
import * as PropTypes from 'prop-types';
import {eStatusMessageType, eStatusMessageStyle, objectToArray} from "../../utils";
import { IconClose } from "../Icons";
import { isEqual } from "lodash";

import './style.scss';


class StatusMessage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isMessageShown: false,
            timeoutHandler: null
        };

        this.showMessage = this.showMessage.bind(this);
        this.hideMessage = this.hideMessage.bind(this);
        this.shouldShowMessage = this.shouldShowMessage.bind(this);

        this.onClose = this.onClose.bind(this);
    }

    async showMessage() {
        // reset previous message
        this.hideMessage();

        // set new message
        this.setState({ isMessageShown: true });

        // set timeout if applicable
        const { duration } = this.props;
        if (!!duration) {
            const { timeoutHandler } = this.state;
            setTimeout(
                () => this.setState({
                    timeoutHandler: setTimeout(this.hideMessage, duration)
                }), !!timeoutHandler ? 250 : 0
            );
        }
    }

    hideMessage() {
        const { timeoutHandler } = this.state;
        this.setState({
            isMessageShown: false,
            timeoutHandler: clearTimeout(timeoutHandler)
        });
    }

    componentDidUpdate(prevProps) {

        const hasPropsChanged = !isEqual(prevProps, this.props);
        const { message, children } = this.props;

        if (hasPropsChanged && (message || children)){
            this.showMessage();
        }
    }

    componentWillUnmount() {
        this.hideMessage();
    }

    shouldShowMessage() {
        const { isMessageShown } = this.state;
        return isMessageShown;
    }

    onClose() {
        this.setState({
            isMessageShown: false
        });
    }

    render() {
        const { type, style, message, showCloseIcon, children } = this.props;
        return (
            <div className={`status-message ${type} ${style} ${this.shouldShowMessage() ? 'message-show' : 'message-hide'}`}>
                {message || children}
                {showCloseIcon && (
                    <span className="close-icon" onClick={this.onClose}>{IconClose}</span>
                )}
            </div>
        )
    }
}

StatusMessage.propTypes = {
    type: PropTypes.oneOf(objectToArray(eStatusMessageType)),
    style: PropTypes.oneOf(objectToArray(eStatusMessageStyle)),
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    duration: PropTypes.number,
    showCloseIcon: PropTypes.bool,
    onComplete: PropTypes.func,
    reset: PropTypes.object
};

StatusMessage.defaultProps = {
    type: eStatusMessageType.INFO,
    style: eStatusMessageStyle.INLINE,
    message: "",
    duration: 0,
    showCloseIcon: false,
    onComplete: null,
    reset: null
};

export default StatusMessage;

/* README
 * ======
 *
 * Usage:
 *
 * 1. If you are loading this component through a conditional rendering inside the parent component, then
 *    make sure the `duration` is not being set (or being set to 0).
 *
 * 2. If you are loading this component as a 'listener' component, then it will render when any of
 *    the value of the props changes. There is a 'reset' prop that you can set to a different value
 *    (such as `new Date()`) to force it to re-display the message.
 */
