import React from 'react';
import * as PropTypes from 'prop-types';
import {shareCandidateResponseLink} from '../../api/';
import {
    eDropdownTypes,
    eJobStageTypes,
    eStatusMessageStyle,
    eStatusMessageType,
    eTextAreaType,
    objectToArray
} from '../../utils';

import CustomModal from '../CustomModal';
import CustomTextArea from "../Form/CustomTextArea";
import TypedDropdown from "../TypedDropdown";
import StatusMessage from "../StatusMessage";

import './style.scss';


class ShareCandidateResponse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
            email: "",
            areRecipientsValid: false,
            message: "",
            status: "", // "", "sending", "success", "fail"
            statusMessage: {
                type: null,
                message: null,
                reset: null
            }
        };

        this.onClose = this.onClose.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.updateEmails = this.updateEmails.bind(this);
        this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this);
        this.isFormSubmittable = this.isFormSubmittable.bind(this);
        this.getSubmitButtonLabel = this.getSubmitButtonLabel.bind(this);
        this.updateStatusMessage = this.updateStatusMessage.bind(this);
    }

    onClose() {
        const { onClose } = this.props;
        onClose?.();
    }

    onInputChange(event) {
        this.setState({[event.target.id]: event.target.value});
    }

    updateEmails(values) {
        this.setState(values);
    }

    async onSubmitButtonClick() {
        if (!this.isFormSubmittable()) {
            return;
        }

        const { candidates, job_id, job_stage_type, job_stage_id, onUpdate } = this.props;
        const { email, message } = this.state;

        const postData = {
            candidate_ids: candidates.map(({candidate_id}) => (candidate_id)),
            "recipient-emails": email.split(",").map(em => em.trim()),
            message
        };

        this.setState({ submitting: true });
        const response = await shareCandidateResponseLink(job_id, job_stage_type, job_stage_id, postData);
        this.setState({ submitting: false });

        if (response) {
            const { error } = response;

            if (error) {
                this.updateStatusMessage("Error has occurred. Please try again.");
                return false;
            }

            if(!!onUpdate) {
                onUpdate();
            }

            this.onClose();
        }
    }

    getSubmitButtonLabel() {
        const { submitting } = this.state;

        if (submitting) {
            return 'Sending...';
        }
        return 'Send Link';
    }

    updateStatusMessage(message, type = eStatusMessageType.INFO) {
        this.setState({
            statusMessage: {
                type,
                message,
                reset: new Date()
            }
        });
    }

    isFormSubmittable() {
        const { status, areRecipientsValid } = this.state;
        return !(["sending", "success"].includes(status)) && areRecipientsValid;
    }

    render() {
        const { candidates, closeOnOverlayClick } = this.props;
        const {
            submitting,
            message,
            statusMessage: { type, message: statusMessage, reset }
        } = this.state;

        return (
            <CustomModal
                title="Share Candidate Response"
                classes={`share-candidate-response open`}
                onClose={this.onClose}
                closeOnOverlayClick={closeOnOverlayClick}
            >
                <div className="candidate-labels">
                    <div className="label">Candidate{candidates && candidates.length > 1 ? "s": ""}</div>
                    <div className="values">
                        {candidates && candidates.map(({candidate_id, first_name, last_name}) => (
                            <div key={candidate_id} className="value">{first_name + (last_name ? " " + last_name : "")}</div>
                        ))}
                    </div>
                </div>
                <div className="send-to">
                    <TypedDropdown
                        id="email"
                        label={`Send to`}
                        dropdownType={eDropdownTypes.STAFF}
                        isMultipleSelection={true}
                        onUpdate={this.updateEmails}
                    />
                </div>
                <div className="message">
                    <CustomTextArea
                        type={eTextAreaType.STANDARD}
                        id="message"
                        label="Message"
                        value={message}
                        onChange={this.onInputChange}
                    />
                </div>
                <div className={`actions`}>
                    <StatusMessage
                        type={type}
                        style={eStatusMessageStyle.INLINE}
                        message={statusMessage}
                        duration={5000}
                        reset={reset}
                    />
                    <button
                        className={`submit-button react-button ${(submitting || !this.isFormSubmittable()) ? 'disabled' : 'info ripple'}`}
                        type="submit"
                        onClick={this.onSubmitButtonClick}
                        disabled={submitting || !this.isFormSubmittable()}
                    >
                        {this.getSubmitButtonLabel()}
                    </button>
                </div>
            </CustomModal>
        );
    }
}

ShareCandidateResponse.propTypes = {
    candidates: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            first_name: PropTypes.string,
            last_name: PropTypes.string,
        })
    ),
    job_id: PropTypes.number,
    job_stage_type: PropTypes.oneOf(objectToArray(eJobStageTypes)),
    job_stage_id: PropTypes.number,
    onUpdate: PropTypes.func,
    onClose: PropTypes.func,
    closeOnOverlayClick: PropTypes.bool
};

ShareCandidateResponse.defaultProps = {
    candidates: [],
    job_id: null,
    job_stage_type: null,
    job_stage_id: null,
    onUpdate: null,
    onClose: null,
    closeOnOverlayClick: true
};

export default ShareCandidateResponse;
