import React from "react";
import CustomModal from '../CustomModal';
import {getCandidateActivity} from "../../api";
import { IconYes, IconNo, IconShare } from "../Icons";
import {copyToClipboard, eDateFormatTypes, formatDateTime, getFullName} from "../../utils";

import './style.scss';


class CandidateActivity extends React.Component {
    constructor(props) {
        super(props);

        this.retrieveCandidateActivity = this.retrieveCandidateActivity.bind(this);
        this.showUserAgentDetails = this.showUserAgentDetails.bind(this);
        this.onClose = this.onClose.bind(this);

        this.state = {
            isLoading: true,
            activity: []
        };
    }

    async retrieveCandidateActivity() {
        const { job_id, job_stage_type, job_stage_id, candidate: { candidate_id } } = this.props;
        const response = await getCandidateActivity(job_id, job_stage_type, job_stage_id, candidate_id);

        this.setState({
            isLoading: false
        });

        if (!response.error) {
            this.setState({
                activity: response
            });
        }
    }

    showUserAgentDetails(value) {
        copyToClipboard(value);
        window.open('https://developers.whatismybrowser.com/useragents/parse/', '_blank');
    }

    onClose() {
        const { onClose } = this.props;
        onClose?.();
    }

    async componentDidMount() {
        await this.retrieveCandidateActivity();
    }

    render() {
        const { isLoading, activity } = this.state;
        const { candidate: { first_name, last_name } } = this.props;
        return (
            <CustomModal
                isLoading={isLoading}
                title={`Candidate Activity - ${getFullName(first_name, last_name)}`}
                classes={`candidate-activity open`}
                closeOnOverlayClick={true}
                onClose={this.onClose}>

                {!activity.length && (
                    <span>No activity log found.</span>
                )}

                {activity.map((item, index) => {
                    const {
                        reconnects_candidate_interview_session_id,
                        is_media_recorder_session,
                        session_timestamp,
                        closed_at,
                        reconnected_by_session_id,
                        is_auto_reconnection_of_previous_session,
                        ip_address,
                        user_agent,
                        was_closed_and_auto_reconnected,
                        candidate_interview_session_id,
                        session_activity
                    } = item;
                    return (
                        <div className={`candidate-session`} key={`candidate-session-${index}`}>
                            <div className={`session-header`}>
                                <span className={`session-id`}>#{candidate_interview_session_id}</span>
                                <span className={`timestamp`}>
                                    {formatDateTime(session_timestamp, eDateFormatTypes.FULL_WITH_SECONDS_AM_PM)} - {formatDateTime(closed_at, eDateFormatTypes.FULL_WITH_SECONDS_AM_PM)}
                                </span><br/>
                                <span className={`item-label`}>User Agent:</span> {user_agent}
                                <span className={`copy-user-agent`} onClick={() => this.showUserAgentDetails(user_agent)}>{IconShare}</span><br/>
                                <span className={`item-label`}>IP Address:</span> {ip_address}<br/>
                                <span className={`item-label`}>Media Recorder Session:</span> {is_media_recorder_session ? IconYes : IconNo}<br/>
                                <span className={`item-label`}>Closed and auto-reconnected:</span> {was_closed_and_auto_reconnected ? IconYes : IconNo}<br/>
                                {!!is_auto_reconnection_of_previous_session && <span>Auto Reconnection of Previous Session<br/></span>}
                                {!!reconnects_candidate_interview_session_id && <span><span className={`item-label`}>Reconnects Candidate Interview Session ID:</span> {reconnects_candidate_interview_session_id}<br/></span>}
                                {!!reconnected_by_session_id && <span><span className={`item-label`}>Reconnected By Session ID:</span> {reconnected_by_session_id}<br/></span>}
                            </div>
                            {session_activity.map((activity_item, activity_index) => {
                                const {
                                    question_number,
                                    recorded_video_mime_type,
                                    job_interview_step_id,
                                    recorded_video_filesize,
                                    is_current_response_for_this_question,
                                    attempt_number,
                                    transcoded_video_status,
                                    candidate_interview_session_activity_type,
                                    recorded_at
                                } = activity_item;
                                return (
                                    <div className={`session-item`} key={`session-item-${activity_index}`}>
                                        {question_number !== null ? (
                                            <span><span className={`question-id`}>Question #{question_number}</span> (Interview Step ID: {job_interview_step_id})<br/></span>
                                        ) : ( 
                                            <span><span className={`question-id`}>Practice Question</span> (Interview Step ID: {job_interview_step_id})<br/></span>
                                        )}
                                        <span className={`activity-type`}>Type: {candidate_interview_session_activity_type}<br/></span>
                                        {typeof attempt_number !== "undefined" && <span className={`attempt-number`}><span className={`item-label`}>Attempt Number:</span> {attempt_number || '-'}<br/></span>}
                                        {is_current_response_for_this_question !== null &&
                                        <span className={`is-current-response`}><span className={`item-label`}>Is current response:</span> {is_current_response_for_this_question ? IconYes : IconNo}<br/></span>
                                        }
                                        {!!recorded_video_filesize && <span><span className={`item-label`}>Recorded Video File Size:</span> {recorded_video_filesize}<br/></span>}
                                        {!!recorded_video_mime_type && <span><span className={`item-label`}>Recorded Video MIME Type:</span> {recorded_video_mime_type}<br/></span>}
                                        {!!recorded_at && <span><span className={`item-label`}>Recorded at:</span> {formatDateTime(recorded_at, eDateFormatTypes.FULL_WITH_SECONDS_AM_PM)}<br/></span>}
                                        {!!transcoded_video_status && <span><span className={`item-label`}>Transcoded Video Status:</span> {transcoded_video_status}<br/></span>}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </CustomModal>
        )
    }
}

export default CandidateActivity;
