import React from 'react';

import './style.scss';

type TooltipProps = {
    classes: string
    positioning: "onLeft" | "onRight" | "onBottom" | "onTop"
    item: object | string | any
    itemLink?: string
    description: object | any
    children: object | any
};

const Tooltip = ({classes, positioning, item, itemLink, description, children}: TooltipProps) => {
    return (
        <div className={`react-tooltip ${classes} ${positioning}`}>
            {!!item && (
                <React.Fragment>
                    {!itemLink && (
                        <div className={`item`}>
                            {item}
                        </div>
                    )}
                    {!!itemLink && (
                        <a
                            className={`item`}
                            href={itemLink}
                            target={`_blank`}
                            rel={`noopener noreferrer`}
                        >
                            {item}
                        </a>
                    )}
                    {!!description && (
                        <div className={`description`}>
                            {description}
                        </div>
                    )}
                </React.Fragment>
            )}
            {!!children && children}
            {!item && !!description && (
                <div className={`description`}>
                    {description}
                </div>
            )}
        </div>
    );
};

Tooltip.defaultProps = {
    classes: "",
    positioning: "onLeft",
    children: null
};

export default Tooltip;
