import React from "react";
import * as PropTypes from "prop-types";

import "./style.scss";

const EvaluatorsFinalComments = ({ items }) => (
    <div className={`evaluators-final-comments`}>
        <div className={`section-title`}>Final Comments</div>
        {items.filter(item => !!item[2]).map(([avatar, name, comment], index) => (
            <div key={`final-comment-${index}`} className={`evaluator-final-comment`}>
                {avatar}
                <div className={`evaluator-name`}>{name}</div>
                <div className={`final-comment-text`}>
                    {comment}
                </div>
            </div>
        ))}
    </div>
);

EvaluatorsFinalComments.propTypes = {
    items: PropTypes.array
};

EvaluatorsFinalComments.defaultProps = {
    items: []
};

export default EvaluatorsFinalComments;