import React from 'react';
import * as PropTypes from "prop-types";
import { IconProfile } from '../Icons';
import CustomCheckbox, {eCheckboxType} from "../Form/CustomCheckbox";

import './style.scss';


class Avatar extends React.Component {
    constructor(props) {
        super(props);

        this.setEnableSelectable = this.setEnableSelectable.bind(this);

        this.state = {
            enableSelectable: false
        };
    }

    setEnableSelectable(value) {
        this.setState({
            enableSelectable: value
        });
    }

    render() {
        const {
            classes,
            data: {
                id = 0,
                staff_id = 0,
                first_name,
                last_name,
                picture_url
            },
            badgeText,
            size,
            selectable,
            selected,
            bordered,
            onClick,
            isLazyLoadEnabled
        } = this.props;

        const { enableSelectable } = this.state;

        const initials = (first_name ? first_name.charAt(0) : '') + (last_name ? last_name.charAt(0) : '');
        const backgroundClass = `bg-${Math.floor((id || staff_id) % 5) + 1}`;

        return (
            <div
                key={id}
                className={`
                    react-avatar
                    ${classes}
                    ${size}
                    ${selectable || enableSelectable ? 'selectable' : ''}
                    ${selected ? 'selected' : ''}
                    ${bordered ? 'bordered': ''}
                `}
                onClick={onClick}
                onMouseEnter={() => !!onClick && this.setEnableSelectable(true)}
                onMouseLeave={() => this.setEnableSelectable(false)}
            >
                {!initials && (
                    <span className="icon">{IconProfile}</span>
                )}
                {!!initials && (
                    <span className={`name ${backgroundClass}`}>{initials}</span>
                )}
                {(selectable || enableSelectable) && (
                    <span className={`selection ${backgroundClass}`}>
                        <CustomCheckbox
                            type={eCheckboxType.NATIVE}
                            checked={selected}
                        />
                    </span>
                )}
                {picture_url && !!isLazyLoadEnabled && (
                    <span className={`img lazy-load`} data-image={picture_url} />
                )}
                {picture_url && !isLazyLoadEnabled && (
                    <span className={`img`} style={{backgroundImage: `url(${picture_url})` }} />
                )}
                {!!badgeText && (
                    <span className="badge">{badgeText}</span>
                )}
            </div>
        );
    }
}

Avatar.propTypes = {
    classes: PropTypes.string,
    data: PropTypes.object,
    badgeText: PropTypes.string,
    size: PropTypes.string,
    selectable: PropTypes.bool,
    selected: PropTypes.bool,
    bordered: PropTypes.bool,
    onClick: PropTypes.func,
};

Avatar.defaultProps = {
    classes: "",
    data: {},
    badgeText: "",
    size: "",
    selectable: false,
    selected: false,
    bordered: false,
    onClick: null
};

export default Avatar;
