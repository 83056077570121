import React from "react";
import * as PropTypes from "prop-types";
import QuestionThumbnails from "../../../QuestionThumbnails";
import QuestionThumbnail from "../../../QuestionThumbnail";
import LabelValueTile from "../../../LabelValueTile";
import CustomTextArea from "../../../Form/CustomTextArea";
import {
    eDropdownTypes,
    eInterviewStepType,
    eLibraryAssetType,
    eLibraryQuestionType,
    eMediaType,
    eQuestionContainerTypes,
    eQuestionDisplayType,
    eResponseType,
    eTextAreaType,
    fancyTimeFormat,
    objectToArray
} from "../../../../utils";
import {
    IconDomino,
    IconFile,
    IconAttachment,
    IconPencil,
    IconPlay,
    IconShowVideo,
    IconTextBubble,
    IconTickThin,
    IconTrash,
    IconStarSlash
} from "../../../Icons";
import Tooltip from "../../../Tooltip";
import Dropdown from "../../../Dropdown";

import './style.scss';


export class QuestionItem extends React.Component {
    constructor(props) {
        super(props);

        this.toggleOptionsDisplay = this.toggleOptionsDisplay.bind(this);

        const showOptionsLink = this.shouldShowOptionLink();

        this.state = {
            showOptionsLink,
            optionsPreviouslyDisplayed: false,
            optionsDisplayed: !showOptionsLink
        };
    }

    toggleOptionsDisplay() {
        const { optionsDisplayed } = this.state;
        this.setState({
            optionsPreviouslyDisplayed: true,
            optionsDisplayed: !optionsDisplayed
        });
    }

    getLibraryQuestionType() {
        const { item: { stock_library_question_id } } = this.props;
        return !!stock_library_question_id ? eLibraryQuestionType.STOCK : eLibraryQuestionType.CUSTOM;
    }

    shouldShowOptionLink() {
        const { item: { videos = [] } } = this.props;
        return (this.isDisplayType(eQuestionDisplayType.SELECTING) || this.isDisplayType(eQuestionDisplayType.LISTING)) &&
            videos?.length >= 1;
    }

    isDisplayType(type) {
        const { displayType = eQuestionDisplayType.VIEWING } = this.props;
        return displayType === type;
    }

    isMessage() {
        const { item: { library_asset_type } } = this.props;
        return library_asset_type === eLibraryAssetType.MESSAGE;
    }

    isPracticeQuestion() {
        const { item: { interview_step_type } } = this.props;
        return interview_step_type === eInterviewStepType.PRACTICE;
    }

    isVideoResponse() {
        const { item: { response_type } } = this.props;
        return response_type === eResponseType.VIDEO;
    }

    isTextResponse() {
        const { item: { response_type } } = this.props;
        return response_type === eResponseType.TEXT;
    }

    isDocumentResponse() {
        const { item: { response_type } } = this.props;
        return response_type === eResponseType.DOCUMENT;
    }

    hasPreparationTime() {
        const { item: { preparation_time } } = this.props;
        return !this.isMessage() && this.isVideoResponse() && preparation_time !== undefined;
    }

    hasAnswerTime() {
        const { item: { answer_time } } = this.props;
        return !this.isMessage() && (answer_time > 0 || answer_time === null);
    }

    getQuestionId() {
        const {
            item: {
                questionId,
                stock_library_question_id,
                tenant_library_question_id,
                tenant_library_message_id
            }
        } = this.props;

        if (this.isDisplayType(eQuestionDisplayType.UPDATING)) {
            return questionId;
        }

        if (this.isMessage()) {
            return tenant_library_message_id;
        }
        
        return stock_library_question_id || tenant_library_question_id;
    }

    getComponentId() {
        const {
            item: {
                stock_library_question_id,
                tenant_library_question_id,
                tenant_library_message_id
            }
        } = this.props;
        if (!!tenant_library_message_id) {
            return `message-${tenant_library_message_id}`;
        } else if (!!stock_library_question_id) {
            return `question-stock-${stock_library_question_id}`;
        } else if (!!tenant_library_question_id) {
            return `question-custom-${tenant_library_question_id}`;
        }

        return null;
    }

    getQuestionText() {
        const {
            item: {
                page_title, title, // used interchangeably
                display_text
            }
        } = this.props;

        const question_text = this.isMessage() ? page_title || title || display_text : display_text;

        if (question_text) {
            return (
                <div className={`question-text`}>
                    {question_text} {this.getResponseTooltip()} {this.getAttachmentTooltip()} {this.getExcludeFromScoringTooltip()}
                </div>
            );
        }

        return null;
    }

    getShownVideos() {
        const {
            item: {
                videos,
                stock_library_question_id,
                stock_library_question_video_id,
                tenant_library_question_video_id
            }
        } = this.props;
        if (this.isDisplayType(eQuestionDisplayType.EDITING)) {
            return (videos || []).filter((videoItem) => {
                const {
                    stock_library_question_video_id: stock_video_id,
                    tenant_library_question_video_id: tenant_video_id
                } = videoItem;

                if (!!stock_library_question_id) {
                    return stock_library_question_video_id === stock_video_id;
                }

                return tenant_library_question_video_id === tenant_video_id;
            });
        }

        return videos;
    }

    getResponseTooltip() {
        const { isLive } = this.props;

        if (!isLive) {
            if (this.isVideoResponse()) {
                return <Tooltip
                    classes="response-type-tooltip video-tooltip"
                    positioning="onRight"
                    item={IconShowVideo}
                    description={<span>Video response</span>}
                />;
            }

            if (this.isTextResponse()) {
                return <Tooltip
                    classes="response-type-tooltip text-tooltip"
                    positioning="onRight"
                    item={IconTextBubble}
                    description={<span>Written response</span>}
                />;
            }

            if (this.isDocumentResponse()) {
                return <Tooltip
                    classes="response-type-tooltip document-tooltip"
                    positioning="onRight"
                    item={IconFile}
                    description={<span>Document response</span>}
                />;
            }
        }

        return null;
    }

    getAttachmentTooltip() {
        const {
            item: {
                attachments
            }
        } = this.props;

        return attachments?.map(({name, url}) => (
            <Tooltip
                classes="attachment-tooltip"
                positioning="onRight"
                item={IconAttachment}
                itemLink={url}
                description={<span>{name}</span>}
            />
        ));
    }

    getExcludeFromScoringTooltip() {
        const { item: { exclude_from_scoring } } = this.props;
        if (exclude_from_scoring) {
            return <Tooltip
                classes="scoring-disabled-tooltip text-tooltip"
                positioning="onRight"
                item={IconStarSlash}
                description={<span>Excluded from scoring</span>}
            />;
        }

        return null;
    }

    getQuestionContent() {
        const {
            isLive,
            item: {
                video,
                image,
                html_content
            }
        } = this.props;

        const contentOnly = isLive || !video;

        if (!contentOnly || !!html_content) {
            return <div className={`thumbnail-and-content ${contentOnly ? 'content-only' : ''}`}>
                {!contentOnly && ( // during editing and viewing
                    <QuestionThumbnail
                        image={image}
                        mediaURL={video}
                        // selected={true}
                        onSelect={null}
                    />
                )}
                {!!html_content && (
                    <div className={`content`}>
                        <div className={`library-html_content`} dangerouslySetInnerHTML={{ __html: html_content }} />
                    </div>
                )}
            </div>;
        }

        return null;
    }

    getQuestionThumbnails() {
        const {
            displayType = eQuestionDisplayType.VIEWING,
            item: {
                question_type,
                display_text,
                video,
                stock_library_question_video_id,
                tenant_library_question_video_id
            },
            selectedVideoTags,
            onVideoSelect,
            optionsAlwaysDisplayed,
            isLive
        } = this.props;

        if (isLive) {
            return null;
        }

        const { optionsPreviouslyDisplayed, optionsDisplayed } = this.state;

        const isVideoQuestion = question_type === eMediaType.VIDEO;
        const shownVideos = this.getShownVideos();

        if (!video && isVideoQuestion && !!shownVideos) {
            const questionId = this.getQuestionId();

            return (
                <div className={`question-thumbnails-container`}>
                    {this.shouldShowOptionLink() && !optionsAlwaysDisplayed && (
                        <div
                            className={`options-link ${optionsAlwaysDisplayed || optionsDisplayed ? '' : 'closed'} ${optionsAlwaysDisplayed ? 'always-open' : ''}`}
                            onClick={!optionsAlwaysDisplayed && this.toggleOptionsDisplay}
                        >
                            {IconPlay}
                            {shownVideos.length} Option{shownVideos.length > 1 ? "s" : ''}
                        </div>
                    )}
                    {(optionsPreviouslyDisplayed || this.isDisplayType(eQuestionDisplayType.EDITING)) && (
                        <QuestionThumbnails
                            questionType={this.getLibraryQuestionType()}
                            questionId={questionId}
                            displayText={display_text}
                            videos={shownVideos}
                            displayType={displayType}
                            selectedStockVideoId={stock_library_question_video_id}
                            selectedTenantVideoId={tenant_library_question_video_id}
                            selectedVideoTags={selectedVideoTags}
                            onSelect={onVideoSelect}
                        />
                    )}
                </div>
            );
        }

        return null;
    }

    getEvaluationGuide() {
        const {
            item: {
                exclude_from_scoring,
                library_asset_type,
                evaluation_guide
            },
            onChange
        } = this.props;

        const showEvaluationGuide =
            library_asset_type === eLibraryAssetType.QUESTION &&
            !this.isPracticeQuestion() &&
            !exclude_from_scoring;

        if (showEvaluationGuide) {
            if (this.isDisplayType(eQuestionDisplayType.EDITING)) {
                return <CustomTextArea
                    classes={`evaluation-guide`}
                    type={eTextAreaType.STANDARD}
                    id="evaluation_guide"
                    label="Evaluation Guide"
                    value={evaluation_guide}
                    onChange={(e) => onChange(library_asset_type, this.getLibraryQuestionType(), this.getQuestionId(), "evaluation_guide", e.target.value)}
                />;
            }

            if (
                (
                    this.isDisplayType(eQuestionDisplayType.VIEWING) ||
                    this.isDisplayType(eQuestionDisplayType.LISTING)
                ) &&
                !!evaluation_guide // actually has value
            ) {
                return <LabelValueTile classes="evaluation-guide" label="Evaluation Guide" value={evaluation_guide} />;
            }
        }

        return null;
    }

    shouldShowTimes() {
        const { isLive } = this.props;
        return !isLive && (
            this.isDisplayType(eQuestionDisplayType.EDITING) ||
            this.isDisplayType(eQuestionDisplayType.VIEWING) ||
            this.isDisplayType(eQuestionDisplayType.UPDATING)
        ) && (this.hasPreparationTime() || this.hasAnswerTime());
    }

    getTimes() {
        const {
            item: { library_asset_type },
            onChange
        } = this.props;

        if (!this.shouldShowTimes()) {
            return null;
        }

        const libraryQuestionType = this.getLibraryQuestionType();
        const questionId = this.getQuestionId();
        const onSelect = (type, value) => onChange(library_asset_type, libraryQuestionType, questionId, type, value);

        return (
            <div className="times">
                {this.getPrepTime(onSelect)}
                {this.getAnswerTime(onSelect)}
                {this.getNumberOfAttempts(onSelect)}
            </div>
        );
    }

    getPrepTime(onSelect) {
        const { item: { preparation_time } } = this.props;

        const isEditing = this.isDisplayType(eQuestionDisplayType.EDITING);
        const isUpdating = this.isDisplayType(eQuestionDisplayType.UPDATING);

        if (this.hasPreparationTime()) {
            return <div className="prep-time">
                {(isEditing || isUpdating) && (
                    <Dropdown
                        required={false}
                        styles="floating-label underlined"
                        classes="preparation-time"
                        label="Prep. Time"
                        dropdownType={eDropdownTypes.PREPARATION_TIME}
                        showUnderline={false}
                        value={preparation_time}
                        onSelect={onSelect}
                        manualInput={true}
                    />
                )}
                {!isEditing && !isUpdating && (
                    <LabelValueTile
                        label="Prep. Time"
                        value={fancyTimeFormat(preparation_time)}
                    />
                )}
            </div>;
        }

        return null;
    }

    getAnswerTime(onSelect) {
        const { item: { answer_time } } = this.props;

        const isEditing = this.isDisplayType(eQuestionDisplayType.EDITING);
        const isUpdating = this.isDisplayType(eQuestionDisplayType.UPDATING);

        if (this.hasAnswerTime()) {
            return (
                <div className="answer-time">
                    {(isEditing || isUpdating) && (
                        <Dropdown
                            required={false}
                            styles="floating-label underlined"
                            classes="answer-time"
                            label="Answer Time"
                            dropdownType={eDropdownTypes.ANSWER_TIME}
                            hasUntimedOption={!this.isVideoResponse()}
                            showUnderline={false}
                            value={answer_time}
                            onSelect={onSelect}
                            manualInput={true}
                        />
                    )}
                    {!isEditing && !isUpdating && (
                        <LabelValueTile
                            label="Answer Time"
                            value={fancyTimeFormat(answer_time)}
                        />
                    )}
                </div>
            );
        }

        return null;
    }

    getNumberOfAttempts(onSelect) {
        const {
            item: { number_of_attempts }
        } = this.props;

        if (!this.isPracticeQuestion() && this.isVideoResponse()) {
            const isEditing = this.isDisplayType(eQuestionDisplayType.EDITING);
            const isUpdating = this.isDisplayType(eQuestionDisplayType.UPDATING);

            return (
                <div className={`no-of-attempts`}>
                    {(isEditing || isUpdating) && (
                        <Dropdown
                            required={false}
                            styles="floating-label underlined"
                            classes="number-of-attempts"
                            label="Attempts"
                            dropdownType={eDropdownTypes.NUMBER_OF_ATTEMPTS}
                            showUnderline={false}
                            value={number_of_attempts}
                            onSelect={onSelect}
                            manualInput={false}
                        />
                    )}
                    {!isEditing && !isUpdating && (
                        <LabelValueTile
                            label="Attempts"
                            value={number_of_attempts}
                        />
                    )}
                </div>
            );
        }

        return null;
    }

    getAction() {
        const {
            isLive,
            isSelected = false,
            isDisabled = false,
            onSelect,
            onDeselect,
            onEdit,
            onRemove,
            item: { library_asset_type },
            selectionType = eQuestionContainerTypes.INTERVIEW
        } = this.props;

        const libraryQuestionType = this.getLibraryQuestionType();
        const questionId = this.getQuestionId();

        const isViewing = this.isDisplayType(eQuestionDisplayType.VIEWING);
        const isUpdating = this.isDisplayType(eQuestionDisplayType.UPDATING);
        const isSelecting = this.isDisplayType(eQuestionDisplayType.SELECTING);
        const isEditing = this.isDisplayType(eQuestionDisplayType.EDITING);
        const isListing = this.isDisplayType(eQuestionDisplayType.LISTING);

        if (isViewing || isUpdating) {
            return null;
        }

        return (
            <div className="action">
                {isEditing && !this.isPracticeQuestion() && (
                    <div className="deselect" onClick={() => onDeselect(library_asset_type, libraryQuestionType, questionId)}>{IconTrash}</div>
                )}

                {!!onEdit && libraryQuestionType === eLibraryQuestionType.CUSTOM && (isListing || isSelecting) && (
                    <React.Fragment>
                        {isSelecting && isLive && (
                            <button className={`react-button outline`} onClick={() => onEdit(library_asset_type, questionId)}>
                                Edit
                            </button>
                        )}
                        {isListing && (
                            <span className="edit-icon" onClick={() => onEdit(library_asset_type, questionId)}>
                                {IconPencil}
                            </span>
                        )}
                    </React.Fragment>
                )}

                {isSelecting && (
                    <button
                        className={`
                            react-button
                            ${isDisabled ? 'disabled' : 'ripple active'}
                            ${isSelected && 'outline'}
                        `}
                        onClick={() => {
                            if (isDisabled) {
                                return false;
                            }
                            if (isSelected) {
                                onDeselect(library_asset_type, libraryQuestionType, questionId);
                                return false;
                            }
                            onSelect(library_asset_type, libraryQuestionType, questionId, selectionType);
                        }}
                    >
                        {isSelected ? <span>{IconTickThin}</span> : 'Add'}
                    </button>
                )}

                {!!onRemove && isListing && (
                    <span
                        className="remove"
                        onClick={onRemove}
                    >
                        {IconTrash}
                    </span>
                )}
            </div>
        );
    }

    render() {
        const {
            keyProp,
            displayType = eQuestionDisplayType.VIEWING,
            provided = {},
            snapshot = {},
            innerRef = null,
            classes,
            item: {
                question_number
            },
            isLive,
            isDraggable = true,
            onSwap
        } = this.props;

        const isEditing = this.isDisplayType(eQuestionDisplayType.EDITING);
        const isViewing = this.isDisplayType(eQuestionDisplayType.VIEWING);
        const isUpdating = this.isDisplayType(eQuestionDisplayType.UPDATING);
        const isSelecting = this.isDisplayType(eQuestionDisplayType.SELECTING);

        const isPracticeInterviewQuestion = this.isPracticeQuestion();
        const showQuestionNumber = (isEditing || isViewing || isUpdating) && !!question_number;

        let componentId = this.getComponentId();

        return (
            <div
                key={keyProp}
                id={componentId}
                {...provided.draggableProps}
                ref={innerRef}
                className={`
                    question-item
                    ${displayType} ${classes}
                    ${snapshot.isDragging ? 'is-dragging' : ''}
                    ${showQuestionNumber ? 'has-question-number' : ''}
                    ${isPracticeInterviewQuestion ? 'practice-question' : ''}
                    ${this.shouldShowTimes() ? 'has-times' : ''}
                    ${isLive ? 'isLive' : ''}
                `}
            >
                {isEditing && (
                    <div className="row-index" {...provided.dragHandleProps}>
                        {(isDraggable && !isPracticeInterviewQuestion) && (
                            <span className="dragHandle">{IconDomino}</span>
                        )}
                    </div>
                )}

                {showQuestionNumber && (
                    <div className="question-number">{question_number}</div>
                )}

                <div className={`question-container`}>
                    {!!isPracticeInterviewQuestion && !isSelecting && (
                        <div className={`practice`}>
                            <span>Practice Question / Video Check</span>
                            {!this.isDisplayType(eQuestionDisplayType.VIEWING) && !!onSwap && <div className={`swap-link`} onClick={onSwap}>(<span>Change</span>)</div>}
                        </div>
                    )}

                    {this.getQuestionText()}

                    {this.getQuestionContent()}
                    {this.getQuestionThumbnails()}
                    {this.getEvaluationGuide()}
                </div>

                {this.getTimes()}
                {this.getAction()}
            </div>
        );
    }
}

QuestionItem.propTypes = {
    displayType: PropTypes.oneOf(objectToArray(eQuestionDisplayType)),
    provided: PropTypes.object,
    snapshot: PropTypes.object,
    innerRef: PropTypes.func,
    classes: PropTypes.string,
    item: PropTypes.shape({
        library_asset_type: PropTypes.oneOf(objectToArray(eLibraryAssetType)),
        stock_library_question_id: PropTypes.number,
        tenant_library_question_id: PropTypes.number,
        stock_library_question_video_id: PropTypes.number,
        tenant_library_question_video_id: PropTypes.number,

        image: PropTypes.string,

        video: PropTypes.string, // old
        videos: PropTypes.arrayOf(
            PropTypes.shape({
                video_tags: PropTypes.array,
                video: PropTypes.string,
                image: PropTypes.string,
                stock_library_question_video_id: PropTypes.number,
                tenant_library_question_video_id: PropTypes.number
            })
        ),

        display_text: PropTypes.string,
        html_content: PropTypes.string,
        preparation_time: PropTypes.number,
        answer_time: PropTypes.number,
        response_type: PropTypes.oneOf(objectToArray(eResponseType)),

        exclude_from_scoring: PropTypes.bool,
        evaluation_guide: PropTypes.string,
        question_number: PropTypes.number
    }),
    isDraggable: PropTypes.bool,
    selectionType: PropTypes.oneOf(objectToArray(eQuestionContainerTypes)),
    onSwap: PropTypes.func,
    onSelect: PropTypes.func,
    onDeselect: PropTypes.func,
    onEdit: PropTypes.func,
    onRemove: PropTypes.func,
    onChange: PropTypes.func,
    isSelected: PropTypes.bool,
    isPracticeQuestion: PropTypes.bool,
    selectedVideoTags: PropTypes.array,
    onVideoSelect: PropTypes.func,
    optionsAlwaysDisplayed: PropTypes.bool
};

QuestionItem.defaultProps = {
    displayType: eQuestionDisplayType.VIEWING,
    provided: {},
    snapshot: {},
    innerRef: null,
    classes: "",
    item: {},
    isDraggable: true,
    selectionType: eQuestionContainerTypes.INTERVIEW,
    onSwap: null,
    onSelect: null,
    onDeselect: null,
    onEdit: null,
    onRemove: null,
    onChange: null,
    isSelected: false,
    isDisabled: false,
    selectedVideoTags: [],
    onVideoSelect: null,
    optionsAlwaysDisplayed: false
};

export default QuestionItem;
