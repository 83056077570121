import React from 'react';
import * as PropTypes from "prop-types";

const ENABLE_CONSOLE_INFO = false,
    ENABLE_PAGE_TRACKING = false;


const INTERCOM_APP_ID =
      !window.location.hostname.endsWith('.video')
  ? 'ctq38w3e' // presume localhost, using the APAC (old 'Alcami') Intercom account's test workspace
  /*: ( window.location.hostname.endsWith('alcami-cc.video')
      || window.location.hostname.endsWith('alcami-cc-staging.video')
      || window.location.hostname.endsWith('us.criteria.video')
      || window.location.hostname.endsWith('us.criteria-staging.video')
    )*/
  : 'o78exqrb';  // US ('Criteria') Intercom account
  // : 'cxlm60mq'; // APAC (old 'Alcami') Intercom account


class Intercom extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            intercom: null
        };

        this.initialiseIntercom = this.initialiseIntercom.bind(this);
        this.connectToIntercom = this.connectToIntercom.bind(this);
        this.setListenerOnWidgetShown = this.setListenerOnWidgetShown.bind(this);
        this.sendUserInfo = this.sendUserInfo.bind(this);
        this.update = this.update.bind(this);
        this.shutdown = this.shutdown.bind(this);
    }

    initialiseIntercom() {
        console.info("initialiseIntercom");
        /*
         * Copy/paste from the script supplied by Intercom for inclusion in the
         * page, except removing the dynamic <script> tag it writes, and which we
         * already include in our HTML.
         */
        var w = window;
        var ic = w.Intercom;

        if (typeof ic === "function") {
            ic('reattach_activator');
            ic('update', w.intercomSettings);
        } else {
            var i = function() {
                i.c(arguments);
            };
            i.q = [];
            i.c = function(args) {
                i.q.push(args);
            };
            w.Intercom = i;
        }
        /* End copy/paste. */
    }

    connectToIntercom() {
        console.info("connectToIntercom");
        if (!!window.Intercom) {
            console.log('Booting up intercom');
            window.Intercom("boot", { app_id: INTERCOM_APP_ID });
        }
    }

    setListenerOnWidgetShown() {
        ENABLE_CONSOLE_INFO && console.info("Intercom: setListenerOnWidgetShown()");
        const { intercom } = this.state;
        intercom('onShow', this.sendUserInfo);
    }

    sendUserInfo() {
        ENABLE_CONSOLE_INFO && console.info("Intercom: sendUserInfo()");
        const { company, user } = this.props;
        const payload = {};
        if (company) {
            payload["Company name"] = company.tenant_id;
        }
        if (user) {
            payload.email = user.email;
            payload.name = user.first_name + ' ' + user.last_name;
            payload.phone = user.phone;
            payload["Role"] = user.role;
        }

        this.update(payload);
    }

    update(content) {
        ENABLE_CONSOLE_INFO && console.info("Intercom: update(", content ,")");
        const { intercom } = this.state;
        intercom('update', content);
    }

    shutdown() {
        ENABLE_CONSOLE_INFO && console.info("Intercom: shutdown()");
        const { intercom } = this.state;
        intercom('shutdown');
    }

    componentDidMount() {
        ENABLE_CONSOLE_INFO && console.info("Intercom: componentDidMount()");

        const { company: { uses_intercom } = {} } = this.props;
        if (uses_intercom) {
            this.initialiseIntercom();
            this.connectToIntercom();
        }
        this.setState({
            intercom: window.Intercom
        }, () => {
            this.setListenerOnWidgetShown();
        });
    }

    componentDidUpdate(prevProps) {
        const { path : prevPath } = prevProps;
        const { path } = this.props;

        if (ENABLE_PAGE_TRACKING && path !== prevPath) {
            this.update({path});
        }
    }

    render() {
        return (null);
    }
}

Intercom.propTypes = {
    path: PropTypes.string,
    company: PropTypes.object,
    user: PropTypes.object
};

Intercom.defaultProps = {
    path: "",
    company: null,
    user: null
};

export default Intercom;
