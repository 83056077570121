import React from "react";
import PropTypes from 'prop-types';

import { getLibrary, removeLibraryItem } from "../../api";
import {
    eConfirmDialogResponseType,
    eLibraryAssetType,
    eLibraryQuestionType,
    eQuestionDisplayType,
    eQuestionItemState,
    getLibraryItemById
} from "../../utils";

import Container from "../../components/Container";
import SubNav from "../../components/SubNav";
import CustomModal from "../../components/CustomModal";
import LibraryItem from "../../components/LibraryItem";
import Library from "../../components/Library";
import {capitalize} from "lodash/string";

import './style.scss';


function filterItemsByLibraryType(items, libraryAssetType, libraryQuestionType) {
    return items.filter(
        ({library_asset_type, is_stock_library_question}) =>
            library_asset_type === libraryAssetType &&
            (
                library_asset_type === eLibraryAssetType.QUESTION ?
                    is_stock_library_question === (libraryQuestionType === eLibraryQuestionType.STOCK) :
                    true
            )
    );
}

class LibraryPage extends React.Component {
    constructor(props) {
        super(props);

        this.getClasses = this.getClasses.bind(this);
        this.getSubNavMainItems = this.getSubNavMainItems.bind(this);
        this.retrieveLibrary = this.retrieveLibrary.bind(this);

        this.updateConfirmDialogResponseProps = this.updateConfirmDialogResponseProps.bind(this);

        this.editItem = this.editItem.bind(this);
        this.removeItem = this.removeItem.bind(this);

        this.openItem = this.openItem.bind(this);
        this.closeItem = this.closeItem.bind(this);

        this.state = {
            loading: true,
            items: [],
            questionItemState: null,
            questionItemId: null,
            confirmDialogResponseProps: null
        };
    }

    componentDidMount() {
        this.retrieveLibrary();
    }

    getClasses() {
        const { integrationProvider } = this.props;
        return integrationProvider ? 'integration' : '';
    }

    getSubNavMainItems() {
        const { activeRoute, integrationProvider } = this.props;
        const isIntegration = activeRoute.includes('integration');
        const prefix = isIntegration ? 'integration-': '';
        const routeState = isIntegration ? {integrationProvider}: {};

        return [{
            label: "Custom Library",
            route: `${prefix}library-custom`,
            routeState
        }, {
            label: "Stock Library",
            route: `${prefix}library-stock`,
            routeState
        }, {
            label: "Messages",
            route: `${prefix}library-messages`,
            routeState
        }];
    }

    async retrieveLibrary() {
        const items = await getLibrary();

        this.setState({
            loading: false,
            items
        });
    }

    editItem(_libraryAssetType, questionId) {
        this.setState({
            questionItemState: eQuestionItemState.EDITING,
            questionItemId: questionId
        })
    }

    removeItem(libraryAssetType, questionId) {
        removeLibraryItem(libraryAssetType, questionId).then(() => {
            const { items } = this.state;
            const updatedItems = items.filter(
                ({
                     id,
                     library_asset_type,
                     tenant_library_question_id,
                     tenant_library_message_id
                 }) => {
                    if (!!id) {
                        return id !== questionId;
                    }

                    const question_id = library_asset_type === "question" ? tenant_library_question_id : tenant_library_message_id;

                    return !(
                        library_asset_type === libraryAssetType &&
                        question_id === questionId
                    );
                }

            );

            this.setState({
                items: updatedItems
            });

            this.updateConfirmDialogResponseProps(`Item deleted.`, eConfirmDialogResponseType.SUCCESS);
        });
    }

    updateConfirmDialogResponseProps(sentence, type = eConfirmDialogResponseType.INFO) {
        this.setState({
            confirmDialogResponseProps: {
                type,
                sentence,
                onClose: () => this.setState({confirmDialogResponseProps: null})
            }
        });
    }

    openItem() {
        this.setState({
            questionItemState: eQuestionItemState.ADDING,
            questionItemId: null
        });
    }

    closeItem(item = null, isUpdate = false) {
        if (item) {
            let items;
            if (isUpdate) {
                items = this.state.items.map((stateItem) => {
                    return (
                        stateItem.library_asset_type === item.library_asset_type &&
                        stateItem.tenant_library_question_id === item.tenant_library_question_id &&
                        stateItem.tenant_library_message_id === item.tenant_library_message_id
                    ) ? item : stateItem;
                });
            } else {
                items = [...this.state.items, item];
            }

            this.setState({
                items
            });

            this.updateConfirmDialogResponseProps(`Item successfully ${isUpdate ? 'updated' : 'created'}.`, eConfirmDialogResponseType.SUCCESS);
        }

        this.setState({
            questionItemState: null,
            questionItemId: null
        });
    }

    render() {
        const { activeRoute, urlFor, features } = this.props;
        const {
            loading,
            items,
            questionItemState,
            questionItemId,
            confirmDialogResponseProps
        } = this.state;

        let libraryAssetType, libraryQuestionType;
        switch (activeRoute) {
            case 'library-custom':
            case 'integration-library-custom':
                libraryAssetType = eLibraryAssetType.QUESTION;
                libraryQuestionType = 'custom';
                break;
            case 'library-stock':
            case 'integration-library-stock':
                libraryAssetType = eLibraryAssetType.QUESTION;
                libraryQuestionType = 'stock';
                break;
            case 'library-messages':
            case 'integration-library-messages':
                libraryAssetType = eLibraryAssetType.MESSAGE;
                libraryQuestionType = null;
                break;
            default:
                throw Error('Unexpected route "' + activeRoute + '".');
        }

        const subNavRightItems = libraryQuestionType !== eLibraryQuestionType.STOCK ?
            [{
                classes: "react-button add-button active ripple",
                label: "Add New",
                onClick: this.openItem
            }] :
            [];

        let item = null;
        if (questionItemState === eQuestionItemState.EDITING) {
            item = getLibraryItemById(items, libraryAssetType, questionItemId);
        }

        const classes = this.getClasses();

        return (
            <Container
                loading={loading}
                confirmDialogResponseProps={confirmDialogResponseProps}
            >
                <SubNav
                    classes={classes}
                    activeRoute={activeRoute}
                    urlFor={urlFor}
                    mainItems={this.getSubNavMainItems()}
                    rightItems={subNavRightItems}
                />

                <div className={`library-page has-intercom ${classes}`}>
                    {!!questionItemState && (
                        <CustomModal
                            title={`${questionItemState === eQuestionItemState.ADDING ? 'Create' : 'Edit'} ${capitalize(libraryAssetType)}`}
                            classes={`item-details open`}
                            onClose={() => this.closeItem()}
                            closeOnOverlayClick={false}
                        >
                            <LibraryItem
                                libraryAssetType={libraryAssetType}
                                // questionItemState={questionItemState}
                                items={items}
                                item={item}
                                onClose={this.closeItem}
                                features={features}
                            />
                        </CustomModal>
                    )}

                    <Library
                        items={filterItemsByLibraryType(items, libraryAssetType, libraryQuestionType)}
                        libraryAssetType={libraryAssetType}
                        libraryQuestionType={libraryQuestionType}
                        displayType={eQuestionDisplayType.LISTING}
                        onEdit={this.editItem}
                        onRemove={this.removeItem}
                    />
                </div>
            </Container>
        );
    }
}


LibraryPage.propTypes = {
    activeRoute: PropTypes.oneOf(['library-custom', 'library-stock', 'library-messages']),
    urlFor: PropTypes.func.isRequired,
    features: PropTypes.array
};


export default LibraryPage;

