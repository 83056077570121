import React from "react";
import * as PropTypes from "prop-types";
import { getStaff, deleteStaff, getLoginModes, sendInvitationEmail, sendLoginEmail } from '../../../api/';
import Avatar from "../../../components/Avatar";
import StaffDetails from "../../../components/StaffDetails";
import Container from "../../../components/Container";
import { eStatusMessageType, eStatusMessageStyle, eVerificationType, eConfirmDialogResponseType } from "../../../utils";
import StatusMessage from "../../../components/StatusMessage";
import { sortBy } from "lodash";
import SubNav from "../../../components/SubNav";
import Tooltip from "../../../components/Tooltip";
import ActionDropdown from "../../../components/ActionDropdown";
import { IconCircleCheckAnimated } from "../../../components/Icons";

import './style.scss';


class CompanyStaff extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            staffs: [],
            loading: true,
            isStaffDetailsOpened: false,
            selectedStaffId: null,
            loginModes: {},
            showLoginModes: false,
            confirmDialogProps: null,
            statusMessage: {
                type: null,
                message: null,
                reset: null
            }
        };

        this.retrieveStaffRecords = this.retrieveStaffRecords.bind(this);
        this.getActionItems = this.getActionItems.bind(this);
        this.openStaffDetails = this.openStaffDetails.bind(this);
        this.closeStaffDetails = this.closeStaffDetails.bind(this);
        this.onStaffCreated = this.onStaffCreated.bind(this);
        this.onStaffUpdated = this.onStaffUpdated.bind(this);
        this.confirmStaffRemoval = this.confirmStaffRemoval.bind(this);
        this.cancelConfirmationDialog = this.cancelConfirmationDialog.bind(this);
        this.removeStaff = this.removeStaff.bind(this);
        this.sendEmailConfirmation = this.sendEmailConfirmation.bind(this);
        this.sendEmailInvite = this.sendEmailInvite.bind(this);
        this.sendEmailLogin = this.sendEmailLogin.bind(this);
        this.updateStatusMessage = this.updateStatusMessage.bind(this);
    }

    async retrieveStaffRecords() {
        const staffRecords = await getStaff();
        this.setState({
            staffs: sortBy(staffRecords, ['first_name', 'last_name']),
            loading: false
        });
    }

    getActionItems(staff) {
        const { staff_id, login_mechanism, can_send_invitation_email } = staff;
        const actionItems = [
            {
                id: "edit",
                firstLine: "Edit",
                onClick: () => this.openStaffDetails(staff_id)
            }
        ];

        if (login_mechanism === eVerificationType.PASSWORD && can_send_invitation_email) {
            actionItems.push({
                id: "send-invitation-email",
                firstLine: "Send Invitation Email",
                onClick: () => this.sendEmailInvite(staff_id)
            });
        }

        if (login_mechanism === eVerificationType.VERIFICATION_CODE) {
            actionItems.push({
                id: "send-login-email",
                firstLine: "Send Login Email",
                onClick: () => this.sendEmailLogin(staff_id)
            });
        }

        actionItems.push({
            id: "delete",
            firstLine: "Delete...",
            onClick: () => this.confirmStaffRemoval(staff_id)
        });

        return actionItems;
    }


    openStaffDetails(staffId) {
        this.setState({
            isStaffDetailsOpened: true,
            selectedStaffId: staffId
        });
    }

    closeStaffDetails() {
        this.setState({
            isStaffDetailsOpened: false,
            selectedStaffId: null
        });
    }

    getSelectedStaff() {
        const { selectedStaffId } = this.state;
        return this.state.staffs.find(({staff_id}) => staff_id === selectedStaffId);
    }

    onStaffCreated(staffData) {
        const { staffs } = this.state;

        staffs.push({...staffData});
        this.setState({
            staffs
        });

        const { login_mechanism } = staffData;
        if ([eVerificationType.PASSWORD, eVerificationType.VERIFICATION_CODE].includes(login_mechanism)) {
            this.sendEmailConfirmation(staffData, login_mechanism);
        } else {
            this.updateStatusMessage("Staff record created.");
        }
    }

    onStaffUpdated(updatedStaffData) {
        const { staff_id: selectedStaffId } = updatedStaffData;
        const { staffs } = this.state;

        const updatedStaffsData = staffs.map((staff) => {
            const { staff_id } = staff;

            return (staff_id === selectedStaffId) ? updatedStaffData : staff;
        });
        this.setState({
            staffs: updatedStaffsData
        });
        this.updateStatusMessage("Staff record updated.");
    }

    confirmStaffRemoval(staffId, e = null) {
        !!e && e.preventDefault() && e.stopPropagation();
        this.setState({
            confirmDialogProps: {
                title: "Delete Staff",
                message: "Are you sure you want to delete this record?",
                actions: [
                    {
                        label: "Cancel",
                        classes: "outline",
                        onClick: () => this.cancelConfirmationDialog()
                    },
                    {
                        label: "Remove",
                        classes: "warning",
                        onClick: () => this.removeStaff(staffId)
                    }
                ]
            }
        });
    }

    cancelConfirmationDialog() {
        this.setState({
            confirmDialogProps: null
        });
    }

    removeStaff(staffId) {
        deleteStaff(staffId).then((_response) => {
            const { staffs } = this.state;
            const updatedStaffs = staffs.filter(({staff_id}) => staffId !== staff_id);
            this.setState({
                confirmDialogProps: null,
                confirmDialogResponseProps: {
                    type: eConfirmDialogResponseType.SUCCESS,
                    sentence: 'Staff record deleted.',
                    onClose: () => this.setState({confirmDialogResponseProps: null})
                },
                staffs: updatedStaffs
            });
        });
    }

    sendEmailConfirmation(staff, type) {
        const { staff_id } = staff;

        const term = type === eVerificationType.PASSWORD ? 'invitation' : 'login link',
            action = type === eVerificationType.PASSWORD ? () => this.sendEmailInvite(staff_id, true) : () => this.sendEmailLogin(staff_id, true);

        this.setState({
            confirmDialogProps: {
                classes: 'email-confirmation',
                title: ' ',
                message:
                    <React.Fragment>
                        {IconCircleCheckAnimated}
                        <div className={`success`}>Staff record created.</div>
                        <div>Would you like to send an email {term} to this staff now?</div>
                    </React.Fragment>,
                actions: [
                    {
                        label: "Later",
                        classes: "button-link",
                        onClick: () => this.cancelConfirmationDialog()
                    },
                    {
                        label: "Send Now",
                        classes: "info",
                        onClick: action
                    }
                ]
            }
        });
    }

    async sendEmailInvite(staff_id, isConfirmDialog = false) {
        const response = await sendInvitationEmail(staff_id);

        if (response.error) {
            this.updateStatusMessage("Error occurred. Please try again later.", eStatusMessageType.WARNING);
            return false;
        }

        if (isConfirmDialog) {
            this.setState({
                confirmDialogProps: {
                    classes: 'email-sent',
                    title: ' ',
                    message:
                        <React.Fragment>
                            {IconCircleCheckAnimated}
                            <div className={`success`}>Email invite sent.</div>
                        </React.Fragment>,
                    actions: [
                        {
                            label: "Close",
                            classes: "info",
                            onClick: () => this.cancelConfirmationDialog()
                        }
                    ]
                }
            });
        } else {
            this.updateStatusMessage("Email invitation sent.");
        }
    }

    async sendEmailLogin(staff_id, isConfirmDialog = false) {
        const response = await sendLoginEmail(staff_id);

        if (response.error) {
            this.updateStatusMessage("Error occurred. Please try again later.", eStatusMessageType.WARNING);
            return false;
        }

        if (isConfirmDialog) {
            this.setState({
                confirmDialogProps: {
                    classes: 'email-sent',
                    title: ' ',
                    message:
                        <React.Fragment>
                            {IconCircleCheckAnimated}
                            <div className={`success`}>Login link sent.</div>
                        </React.Fragment>,
                    actions: [
                        {
                            label: "Close",
                            classes: "info",
                            onClick: () => this.cancelConfirmationDialog()
                        }
                    ]
                }
            });
        } else {
            this.updateStatusMessage("Login link sent.");
        }

    }

    updateStatusMessage(message, type = eStatusMessageType.INFO) {
        this.setState({
            statusMessage: {
                type,
                message,
                reset: new Date()
            }
        });
    }

    async componentDidMount() {
        this.retrieveStaffRecords();

        const loginModes = await getLoginModes();
        this.setState({
            loginModes
        });
    }

    render() {
        const { activeRoute, urlFor, subNavMainItems } = this.props;
        const { staffs, isStaffDetailsOpened, loading, confirmDialogProps, confirmDialogResponseProps, statusMessage: {type, message, reset}, loginModes } = this.state;
        const showLoginModes = loginModes.can_login_with_password && loginModes.can_login_with_email;

        const subNavRightItems = [{
            classes: "react-button active",
            label: "Add New",
            onClick: this.openStaffDetails
        }];

        return (
            <React.Fragment>
                <SubNav activeRoute={activeRoute} urlFor={urlFor}  mainItems={subNavMainItems} rightItems={subNavRightItems} />
                {isStaffDetailsOpened && (
                    <StaffDetails {...this.getSelectedStaff()} loginModes={loginModes} onAfterCreate={this.onStaffCreated} onAfterUpdate={this.onStaffUpdated} onClose={this.closeStaffDetails} />
                )}
                <Container
                    loading={loading}
                    confirmDialogProps={confirmDialogProps}
                    confirmDialogResponseProps={confirmDialogResponseProps}
                >
                    <StatusMessage
                        type={type}
                        style={eStatusMessageStyle.BAR}
                        message={message}
                        duration={3000}
                        showCloseIcon={true}
                        reset={reset}
                    />

                    <div className={`company-staff has-intercom`}>
                        <ol className="collection collection-container">
                            {/* Header */}
                            <li className="item item-container">
                                <div className="attribute">Staff</div>
                                <div className="attribute details" />
                                <div className="attribute role">Role</div>
                                <div className="attribute position">Position</div>
                                <div className="attribute department">Department</div>
                                <div className="attribute location">Location</div>
                                <div className="attribute edit-remove" />
                            </li>

                            {/* Content */}
                            {staffs.map(
                                (staff) => {
                                    const {
                                        staff_id,
                                        first_name,
                                        last_name,
                                        picture_url,
                                        email,
                                        phone,
                                        is_manager,
                                        login_mechanism,
                                        position,
                                        business_unit,
                                        location
                                    } = staff;
                                    const avatarData = {
                                        id: staff_id,
                                        first_name,
                                        last_name,
                                        picture_url
                                    };
                                    return (
                                        <div
                                            key={`staff-${staff_id}`}
                                            className="item item-container"
                                        >
                                            <div className="attribute">
                                                <Avatar data={avatarData} size="ml" />
                                            </div>
                                            <div className="attribute details">
                                                <div className="full-name">{((first_name || "") + " " + (last_name || "")).trim()}</div>
                                                <div className="email">{email}</div>
                                                <div className="phone">{phone}</div>
                                            </div>
                                            <div className="attribute role">
                                                <div>{is_manager ? "Manager" : "Evaluator"}</div>
                                                {showLoginModes && (
                                                    <React.Fragment>
                                                        {login_mechanism === eVerificationType.PASSWORD && (
                                                            <Tooltip
                                                                classes="login-mode-tooltip"
                                                                positioning="onBottom"
                                                                item={<span className={`login-mode-tooltip-icon`}>P</span>}
                                                                description={'Login with password'}
                                                            />
                                                        )}
                                                        {login_mechanism === eVerificationType.VERIFICATION_CODE && (
                                                            <Tooltip
                                                                classes="login-mode-tooltip"
                                                                positioning="onBottom"
                                                                item={<span className={`login-mode-tooltip-icon`}>E</span>}
                                                                description={'Login with email verification code'}
                                                            />
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </div>
                                            <div className="attribute position">{position}</div>
                                            <div className="attribute department">{business_unit}</div>
                                            <div className="attribute location">{location}</div>
                                            <div className="attribute-container actions">
                                                <ActionDropdown items={this.getActionItems(staff)} hasBorder={true} />
                                            </div>
                                        </div>
                                    );
                                }
                            )}
                        </ol>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

CompanyStaff.propTypes = {
    activeRoute: PropTypes.string.isRequired,
    urlFor: PropTypes.func.isRequired,
    subNavMainItems: PropTypes.array
};

CompanyStaff.defaultProps = {
    activeRoute: 'company-staff',
    subNavMainItems: []
};

export default CompanyStaff;
