import React from 'react';
import { slice } from "lodash";
import Tooltip from "../Tooltip";
import Avatar from "../Avatar";
import LabelValueTile from "../LabelValueTile";

import './style.scss';


const EvaluatorsThumbnails = ({evaluators = []}) => (
    <LabelValueTile classes="evaluators-thumbnails" label="Evaluators">
        {!evaluators.length && (
            <div className={`no-evaluator`}>-</div>
        )}
        {(slice(evaluators, 0, 7) || []).map((evaluator) => {
            const { first_name, last_name, staff_id } = evaluator;
            return (
                <Tooltip key={`evaluator-${staff_id}`} positioning="onRight" description={<div className={`content`}>{first_name} {last_name}</div>}>
                    <Avatar key={evaluator.staff_id} data={{id: evaluator.staff_id, ...evaluator}} bordered={true} />
                </Tooltip>
            );
        })}
        {evaluators.length > 7 && (
            <span className={`evaluators-more`}>&nbsp;& {evaluators.length - 7} more</span>
        )}
    </LabelValueTile>
);

export default EvaluatorsThumbnails;