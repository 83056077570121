import React from "react";
import * as PropTypes from "prop-types";
import LibraryItem from "../../../LibraryItem";
import CustomModal from "../../../CustomModal";
import {
    capitalize,
    eLibraryAssetType,
    eLibraryQuestionType,
    eQuestionContainerTypes,
    eQuestionDisplayType,
    eQuestionItemState,
    getLibraryItemById
} from "../../../../utils";
import Library from "../../../Library";

import './style.scss';


class InterviewItemsSelection extends React.Component {
    constructor(props) {
        super(props);

        this.hasStockLibrary = this.hasStockLibrary.bind(this);
        this.setLibraryType = this.setLibraryType.bind(this);
        this.getItemsFilteredByLibraryTypeAndSelectionType = this.getItemsFilteredByLibraryTypeAndSelectionType.bind(this);
        this.setSelectedQuestionId = this.setSelectedQuestionId.bind(this);

        this.openItem = this.openItem.bind(this);
        this.editItem = this.editItem.bind(this);
        this.closeItem = this.closeItem.bind(this);
        this.jumpToNewItem = this.jumpToNewItem.bind(this);

        this.state = {
            questionItemState: null,
            selectionType: eQuestionContainerTypes.INTERVIEW,
            selectedQuestionId: null,
            libraryAssetType: eLibraryAssetType.QUESTION,
            libraryQuestionType: eLibraryQuestionType.CUSTOM
        };
    }

    hasStockLibrary() {
        const { libraryQuestionType } = this.state;
        const { items = [], selectedQuestion } = this.props;
        const hasStockLibrary = !!(items.find(({stock_library_question_id}) => !!stock_library_question_id));

        if (!hasStockLibrary && !selectedQuestion && libraryQuestionType === eLibraryQuestionType.STOCK) {
            this.setLibraryType(eLibraryAssetType.QUESTION, eLibraryQuestionType.CUSTOM);
        }

        return hasStockLibrary;
    }

    setLibraryType(libraryAssetType, libraryQuestionType) {
        this.setState({
            libraryAssetType,
            libraryQuestionType
        });
    }

    getItemsFilteredByLibraryTypeAndSelectionType() {
        const { libraryAssetType, libraryQuestionType } = this.state;
        const { items } = this.props;
        return items.filter(
            ({library_asset_type, is_stock_library_question}) =>
                library_asset_type === libraryAssetType &&
                (
                    library_asset_type === eLibraryAssetType.QUESTION ?
                        is_stock_library_question === (libraryQuestionType === eLibraryQuestionType.STOCK) :
                        true
                )
        );
    }

    setSelectedQuestionId() {
        const { selectedQuestion } = this.props;
        if (!!selectedQuestion) {
            const { libraryAssetType, libraryQuestionType } = this.state;
            const { library_asset_type, stock_library_question_id, tenant_library_question_id, tenant_library_message_id } = selectedQuestion;

            const selectedQuestionId = `${library_asset_type}-` + (
                stock_library_question_id ? `stock-${stock_library_question_id}` :
                tenant_library_question_id ? `tenant-${tenant_library_question_id}` :
                tenant_library_message_id ? `tenant-${tenant_library_message_id}` :
                ''
            );

            const updatedState = {};

            if (libraryAssetType !== library_asset_type) {
                updatedState.libraryAssetType = library_asset_type;
            }

            const library_question_type = stock_library_question_id ? eLibraryQuestionType.STOCK : eLibraryQuestionType.CUSTOM;
            if (libraryQuestionType !== library_question_type) {
                updatedState.libraryQuestionType = library_question_type;
            }

            this.setState({
                ...updatedState,
                selectionType: eQuestionContainerTypes.PRE_INTERVIEW,
                selectedQuestionId
            });
        }
    }

    openItem(questionItemState, questionItemId = null) {
        this.setState({
            questionItemState,
            questionItemId
        });
    }

    editItem(_libraryAssetType, questionItemId) {
        this.openItem(eQuestionItemState.EDITING, questionItemId);
    }

    closeItem(item = null, isUpdate = false) {
        if (item) {
            const { onLibraryUpdate } = this.props;
            onLibraryUpdate?.(item, isUpdate);
        }

        this.setState({
            libraryAssetType: eLibraryAssetType.QUESTION,
            libraryQuestionType: eLibraryQuestionType.CUSTOM,
            questionItemState: null,
            questionItemId: null
        }, () => this.jumpToNewItem(item));
    }

    jumpToNewItem(item) {
        if (item) {
            const { tenant_library_message_id, tenant_library_question_id }= item;
            // element ID should match what is being set on QuestionItem
            const elementID = !!tenant_library_message_id ? `message-${tenant_library_message_id}` :
                !!tenant_library_question_id ? `question-custom-${tenant_library_question_id}` :
                    "";

            if (elementID) {
                const el = document.getElementById(elementID);
                if (el) {
                    el.classList.add("highlighted");
                    el.scrollIntoView();
                }
            }
        }
    }

    componentDidMount() {
        this.setSelectedQuestionId();
    }

    render() {
        const {
            libraryAssetType,
            libraryQuestionType,
            questionItemState,
            questionItemId,
            selectionType,
            selectedQuestionId
        } = this.state;
        const { isLive, items, interview_steps, onSelect, onDeselect, onVideoSelect, integrationProvider } = this.props;

        let item = null;
        if (questionItemState === eQuestionItemState.EDITING) {
            item = getLibraryItemById(items, libraryAssetType, questionItemId);
        }

        return (
            <React.Fragment>
                {questionItemState && (
                    <CustomModal
                        title={`${questionItemState === eQuestionItemState.ADDING ? 'Create' : 'Edit'} ${capitalize(libraryAssetType)}`}
                        classes={`item-details open`}
                        onClose={() => this.closeItem()}
                        closeOnOverlayClick={false}
                    >
                        <LibraryItem
                            libraryAssetType={libraryAssetType}
                            items={items}
                            item={questionItemState === eQuestionItemState.EDITING ? item : null}
                            isLive={isLive}
                            onClose={this.closeItem}
                            integrationProvider={integrationProvider}
                        />
                    </CustomModal>
                )}
                <div className="item-selection-header">
                    <div className={`item-selection-title`}>
                        {selectionType === eQuestionContainerTypes.PRE_INTERVIEW && (
                            <span>
                                Change
                                <span className={`desktop-only`}>&nbsp;Question</span>
                            </span>
                        )}
                        {selectionType === eQuestionContainerTypes.INTERVIEW && (
                            <span>
                                Add
                                <span className={`desktop-only`}>&nbsp;Questions</span>
                            </span>
                        )}
                    </div>
                    <div className={`item-selection-type ${this.hasStockLibrary() ? 'has-stock-library' : ''}`}>
                        {!!items.length && items[0].hasOwnProperty('library_asset_type') && (
                            <React.Fragment>
                                <div
                                    className={`type-link ${libraryQuestionType === eLibraryQuestionType.CUSTOM ? 'active' : ''}`}
                                    onClick={() => this.setLibraryType(eLibraryAssetType.QUESTION, eLibraryQuestionType.CUSTOM)}
                                >
                                    Custom<span className={`desktop-only`}>&nbsp;Library</span>
                                </div>
                                {this.hasStockLibrary() && (
                                    <div
                                        className={`type-link ${libraryQuestionType === eLibraryQuestionType.STOCK ? 'active' : ''}`}
                                        onClick={() => this.setLibraryType(eLibraryAssetType.QUESTION, eLibraryQuestionType.STOCK)}
                                    >
                                        Stock<span className={`desktop-only`}>&nbsp;Library</span>
                                    </div>
                                )}
                                {selectionType === eQuestionContainerTypes.INTERVIEW && (
                                    <div
                                        className={`type-link ${libraryAssetType === eLibraryAssetType.MESSAGE ? 'active' : ''}`}
                                        onClick={() => this.setLibraryType(eLibraryAssetType.MESSAGE, "")}
                                    >
                                        Messages
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                    {libraryQuestionType !== eLibraryQuestionType.STOCK && (
                        <div className={`question-add`}>
                            <button
                                className={`react-button outline active`}
                                onClick={() => this.openItem(eQuestionItemState.ADDING)}
                            >Add New</button>
                        </div>
                    )}
                </div>
                <Library
                    classes={`item-selection-library`}
                    interview_steps={interview_steps}
                    isLive={isLive}
                    items={this.getItemsFilteredByLibraryTypeAndSelectionType()}
                    libraryAssetType={libraryAssetType}
                    libraryQuestionType={libraryQuestionType}
                    displayType={eQuestionDisplayType.SELECTING}
                    selectionType={selectionType}
                    selectedQuestionId={selectedQuestionId}
                    // onEdit={this.editItem}
                    onSelect={onSelect}
                    onDeselect={onDeselect}
                    onVideoSelect={onVideoSelect}
                />
            </React.Fragment>
        );
    }
}

InterviewItemsSelection.propTypes = {
    items: PropTypes.array,
    interview_steps: PropTypes.array,
    selectedQuestion: PropTypes.object,
    onLibraryUpdate: PropTypes.func,
    onSelect: PropTypes.func,
    onDeselect: PropTypes.func,
    onVideoSelect: PropTypes.func,
    integrationProvider: PropTypes.string
};

InterviewItemsSelection.defaultProps = {
    items: [],
    interview_steps: [],
    selectedQuestion: null,
    onLibraryUpdate: null,
    onSelect: null,
    onDeselect: null,
    onVideoSelect: null,
    integrationProvider: ""
};

export default InterviewItemsSelection;