import React from 'react';
import * as PropTypes from 'prop-types';
import ConfirmDialog from "../index";
import {eConfirmDialogResponseType, objectToArray} from "../../../utils";
import {IconQuestionMarkAnimated, IconCircleCheckAnimated, IconCrossAnimated, IconInformationAnimated} from "../../Icons";

import './style.scss';


const getClassName = (type) => {
    const isQuestion = type === eConfirmDialogResponseType.QUESTION,
        isSuccess = type === eConfirmDialogResponseType.SUCCESS,
        isError = type === eConfirmDialogResponseType.ERROR;

    if (isQuestion) {
        return "question";
    }
    if (isSuccess) {
        return "success";
    }
    if (isError) {
        return "error";
    }
    return "info";
}

const getQuestionIcon = (type) => {
    const isQuestion = type === eConfirmDialogResponseType.QUESTION,
        isSuccess = type === eConfirmDialogResponseType.SUCCESS,
        isError = type === eConfirmDialogResponseType.ERROR;

    if (isQuestion) {
        return IconQuestionMarkAnimated;
    }
    if (isSuccess) {
        return IconCircleCheckAnimated;
    }
    if (isError) {
        return IconCrossAnimated;
    }
    return IconInformationAnimated;
}

const ConfirmDialogResponse = ({type, sentence, onProceed, onClose, closeLabel = 'OK'}) => {
    if (!type) {
        return null;
    }

    const actions = [];
    if (!!onClose) {
        actions.push({
            label: !!onProceed ? 'Cancel' : closeLabel,
            classes: !!onProceed ? 'outline' : 'info',
            onClick: onClose
        });
    }
    if (!!onProceed) {
        actions.push({
            label: "Proceed",
            classes: "info",
            onClick: onProceed
        });
    }

    const confirmDialogProps = {
        classes: `confirm-dialog-response ${getClassName(type)}`,
        message:
            <React.Fragment>
                {getQuestionIcon(type)}
                <div>{sentence}</div>
            </React.Fragment>,
        actions
    };

    return (
        <ConfirmDialog {...confirmDialogProps} />
    );
};

ConfirmDialogResponse.propTypes = {
    type: PropTypes.oneOf(objectToArray(eConfirmDialogResponseType)),
    sentence: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onProceed: PropTypes.func,
    onClose: PropTypes.func
};

ConfirmDialogResponse.defaultProps = {
    type: null,
    sentence: null,
    onProceed: null,
    onClose: null
};

export default ConfirmDialogResponse;
