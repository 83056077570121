import React from "react";
import * as PropTypes from "prop-types";
import {getSMSTemplates} from "../../api";
import {eDropdownTypes, eSMSTemplateTypes, hasDuplicateSMSReminders, hasIncompleteSMSReminders} from "../../utils";
import Dropdown from "../../components/Dropdown";
import { IconTrash } from "../Icons";
import { capitalize, sortBy } from "lodash";

import './style.scss';


const MAX_SMS_REMINDERS = 2;

class SMSTemplateSelection extends React.Component {
    constructor(props) {
        super(props);

        this.getSMSDropdownItems = this.getSMSDropdownItems.bind(this);
        this.onSMSAdd = this.onSMSAdd.bind(this);
        this.onSMSTemplateChange = this.onSMSTemplateChange.bind(this);
        this.onSMSIntervalChange = this.onSMSIntervalChange.bind(this);
        this.onSMSDelete = this.onSMSDelete.bind(this);

        this.state = {
            smsTemplateItems: []
        };
    }

    getSMSDropdownItems(type, array_number = null, selected_template = {}) {
        const { smsTemplateItems } = this.state;
        const {
            sms_template_id: selected_sms_template_id,
            sms_template_type: selected_sms_template_type
        } = selected_template;

        const noSelectionItem = {
            id: null,
            firstLine: '(None)',
            selected: null === selected_sms_template_id,
            onClick: () => this.onSMSTemplateChange(type, array_number, null)
        };

        let is_item_in_dropdown = false;

        const items = type === eSMSTemplateTypes.INVITATION ? [noSelectionItem] : [];

        const selectionItems = smsTemplateItems
            .filter(({sms_template_type}) => sms_template_type === type)
            .map((item) => {
                const { sms_template_id, sms_template_type } = item;
                const sms_template_name = `${capitalize(sms_template_type)} SMS Template ${sms_template_id}`;
                const is_selected = sms_template_id === selected_sms_template_id;

                if (is_selected) {
                    is_item_in_dropdown = true;
                }

                return {
                    id: sms_template_id,
                    firstLine: sms_template_name,
                    selected: is_selected,
                    onClick: () => this.onSMSTemplateChange(type, array_number, sms_template_id)
                };
            });

        if (!is_item_in_dropdown && !!selected_sms_template_id) {
            selectionItems.push({
                id: selected_sms_template_id,
                firstLine: `${capitalize(selected_sms_template_type)} SMS Template ${selected_sms_template_id}`,
                selected: true,
                disabled: true,
                onClick: null
            })
        }

        items.push(...selectionItems);

        return sortBy(items, ["firstLine"]);
    }

    onSMSAdd() {
        // currently only for reminder
        const { onChange, reminder_sms_templates } = this.props;
        reminder_sms_templates.push({
            content: "",
            created_at: Date.now(),
            interval_before_deadline: null,
            is_default_for_new_job: false,
            sms_template_id: null,
            sms_template_type: eSMSTemplateTypes.REMINDER
        });

        onChange({target: {id: "reminder_sms_templates", value: reminder_sms_templates}});
    }

    onSMSTemplateChange(type, array_number, selectedValue) {
        const { onChange, reminder_sms_templates } = this.props;

        let id = null,
            value = null;

        if (type === eSMSTemplateTypes.INVITATION) {
            id = 'invitation_sms_template_id';
            value = selectedValue;
        }
        if (type === eSMSTemplateTypes.REMINDER) {
            id = 'reminder_sms_templates';
            value = reminder_sms_templates.map((template, index) => {
                return (index === array_number) ? {...template, sms_template_id: selectedValue} : template;
            });
        }

        onChange({target: {id, value}});
    }

    onSMSIntervalChange(array_number, selectedValue) {
        const { onChange, reminder_sms_templates } = this.props;

        const value = reminder_sms_templates.map((template, index) => {
            return (index === array_number) ? {...template, interval_before_deadline: selectedValue} : template;
        });

        onChange({target: {id: "reminder_sms_templates", value}});
    }

    onSMSDelete(index) {
        const { onChange, reminder_sms_templates } = this.props;
        reminder_sms_templates.splice(index, 1);

        onChange({target: {id: "reminder_sms_templates", value: reminder_sms_templates}});
    }

    async componentDidMount() {
        const smsTemplateItems = await getSMSTemplates();

        this.setState({
            smsTemplateItems
        });
    }

    render() {
        const {
            invitation_sms_template_id,
            reminder_sms_templates
        } = this.props;

        const has_reminder_sms_template_dropdown = this.getSMSDropdownItems(eSMSTemplateTypes.REMINDER).length;

        let reminder_sms_ids = [];
        const has_duplicate_sms_reminders = hasDuplicateSMSReminders(reminder_sms_templates);
        const has_incomplete_sms_reminders = hasIncompleteSMSReminders(reminder_sms_templates);

        return (
            <div className={`sms-template-selection`}>
                <div className={`header`}>Invitation SMS</div>
                <Dropdown
                    // required={true}
                    styles="floating-label fixed-label"
                    classes="invitation_sms_dropdown"
                    label="Invitation SMS"
                    items={this.getSMSDropdownItems(eSMSTemplateTypes.INVITATION, null, {sms_template_type: eSMSTemplateTypes.INVITATION, sms_template_id: invitation_sms_template_id})}
                />

                <div className={`reminder-sms-list`}>
                    <div className={`header`}>Reminder SMS</div>
                    <div className={`subheader`}>
                        You can add up to {MAX_SMS_REMINDERS} SMS reminders. Applicable when deadline is specified.
                        {!has_reminder_sms_template_dropdown && (
                            <React.Fragment>
                                <div className={`no-reminder-templates`}>
                                    <br />
                                    You currently have no Reminder SMS templates available to choose.<br />
                                    Please create a new Reminder SMS template first, and then return to this page to add it into this job.
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                    {reminder_sms_templates.map((template, index) => {
                        const { sms_template_id, interval_before_deadline } = template;
                        const unique_id = `${sms_template_id}|${interval_before_deadline}`;
                        let is_duplicate = false;
                        if (reminder_sms_ids.includes(unique_id)) {
                            is_duplicate = true;
                        } else {
                            reminder_sms_ids.push(unique_id);
                        }
                        return (
                            <div className={`reminder-sms ${is_duplicate ? 'is_duplicate' : ''}`}>
                                <div className={`index`}><span>{index + 1}</span></div>
                                <div className={`send`}>Send</div>
                                <div className={`template`}>
                                    <Dropdown
                                        classes="reminder_sms_dropdown"
                                        items={this.getSMSDropdownItems(eSMSTemplateTypes.REMINDER, index, template)}
                                    />
                                </div>
                                <div className={`at`}>at</div>
                                <div className={`interval`}>
                                    <Dropdown
                                        required={false}
                                        styles="underlined"
                                        classes="sms-interval"
                                        dropdownType={eDropdownTypes.SMS_INTERVAL}
                                        showUnderline={false}
                                        value={interval_before_deadline}
                                        onSelect={(_type, value) => this.onSMSIntervalChange(index, value)}
                                        // manualInput={true}
                                    />
                                </div>
                                <div className={`before-deadline`}>before deadline.</div>
                                <div className={`actions`}>
                                    <div onClick={() => this.onSMSDelete(index)}>{IconTrash}</div>
                                </div>
                            </div>
                        );
                    })}
                    {has_duplicate_sms_reminders && (
                        <div className={`duplicate`}>Please edit or remove any duplicate SMS reminders.</div>
                    )}
                    {reminder_sms_templates.length < MAX_SMS_REMINDERS && (
                        <div className={`react-button info add ${!has_reminder_sms_template_dropdown || has_duplicate_sms_reminders || has_incomplete_sms_reminders ? 'disabled' : ''}`} onClick={has_reminder_sms_template_dropdown && !has_duplicate_sms_reminders && !has_incomplete_sms_reminders ? this.onSMSAdd : null}>Add New</div>
                    )}
                </div>
            </div>
        );
    }
}

SMSTemplateSelection.propTypes = {
    invitation_sms_template_id: PropTypes.number,
    reminder_sms_templates: PropTypes.array,
    // reminder_sms_template_id: PropTypes.number,
    onChange: PropTypes.func
};

SMSTemplateSelection.defaultProps = {
    invitation_sms_template_id: null,
    reminder_sms_templates: [],
    // reminder_sms_template_id: null,
    onChange: null
};

export default SMSTemplateSelection;