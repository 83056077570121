import React from "react";
import * as PropTypes from "prop-types";
import StatusMessage from "../StatusMessage";
import {
    DEFAULT_CANDIDATE_FOR_EMAIL_PREVIEW,
    defaultEvaluationOptions,
    eBrandingType,
    eCompanyFeatures,
    eDeadlineType,
    eJobsType,
    eStatusMessageType,
    eTextAreaType,
    eUserSettings,
    formatDate,
    getEndOfDay,
    getUserSetting,
    getValue,
    hasDuplicateSMSReminders,
    hasIncompleteSMSReminders,
    isAdminUserSettingEnabled,
    isAlcamiStaff,
    isCompanyFeatureEnabled,
    isValidEmail,
    JOB_STAGES_FIELDS,
    objectToArray
} from "../../utils";
import Container from "../Container";
import {getBrandings, getCompanyDetails, getJobSummary, getStaff, updateJob, updateJobStage} from "../../api";
import {cloneDeep, get, isEqual, sortBy} from "lodash";
import LabelValueTile from "../LabelValueTile";
import CustomTextArea from "../Form/CustomTextArea";
import EvaluatorSelection from "../EvaluatorSelection";
import Evaluators from "../Evaluators";
import {
    getInterviewQuestions,
    getCurrentJobStage,
    addEvaluationAttributesToggleAndDisplay,
    sanitiseJobStageData
} from "../../utils/job";
import CustomTextField from "../Form/CustomTextField";
import Dropdown from "../Dropdown";
import CustomModal from "../CustomModal";
import BrandingPreview from "../BrandingPreview";
import JobDeadline from "../JobDeadline";
import StaffSelection from "../StaffSelection";
import {IconRefresh} from "../Icons";
import EmailTemplateSelection from "../EmailTemplateSelection";
import SMSTemplateSelection from "../SMSTemplateSelection";
import StepQuestions from "../JobDraft/StepQuestions";
import Job from "../Job";

import './style.scss';


class JobEdit extends Job {
    constructor(props) {
        super(props);

        this.state = {
            job: {},
            originalJob: {},
            staff: [],
            brandings: [],
            loading: true,
            statusMessage: {
                message: null,
                reset: null
            },
            removeEvaluatorConfirmationShown: false,
            confirmDialogProps: null,
            integrationProviders: [],
            isBrandingPreviewShown: false,
            hasDescriptionBeenManuallyChanged: false,
            isCandidateContactNameError: false,
            isCandidateContactEmailError: false
        };

        this.populateJob = this.populateJob.bind(this);
        this.retrieveStaffRecords = this.retrieveStaffRecords.bind(this);
        this.showEmailTemplates = this.showEmailTemplates.bind(this);

        this.updateStatusMessage = this.updateStatusMessage.bind(this);

        this.getBrandingsAsDropdown = this.getBrandingsAsDropdown.bind(this);
        this.onInitialDescriptionChange = this.onInitialDescriptionChange.bind(this);
        this.onJobInputChange = this.onJobInputChange.bind(this);

        this.isFieldEmpty = this.isFieldEmpty.bind(this);
        this.isValueEmpty = this.isValueEmpty.bind(this);

        this.isJobDeadlineError = this.isJobDeadlineError.bind(this);
        this.hasJobChanged = this.hasJobChanged.bind(this);
        this.isJobReadyToSave = this.isJobReadyToSave.bind(this);
        this.saveJobChanges = this.saveJobChanges.bind(this);
        this.displayErrorMessage = this.displayErrorMessage.bind(this);
        this.cancelJobEdit = this.cancelJobEdit.bind(this);

        this.getAvailableStaff = this.getAvailableStaff.bind(this);
        this.addEvaluator = this.addEvaluator.bind(this);
        this.confirmRemoveEvaluator = this.confirmRemoveEvaluator.bind(this);
        this.removeEvaluator = this.removeEvaluator.bind(this);

        this.getCurrentBranding = this.getCurrentBranding.bind(this);
        this.showPreviewBranding = this.showPreviewBranding.bind(this);
        this.hidePreviewBranding = this.hidePreviewBranding.bind(this);
        this.getCandidateForPreview = this.getCandidateForPreview.bind(this);

        this.getStepContent = this.getStepContent.bind(this);

        this.closeConfirmDialog = this.closeConfirmDialog.bind(this);
    }

    componentDidMount() {
        const { job_id } = this.props;

        if (!!job_id) {
            this.populateJob(job_id);
        } else {
            this.setState({
                loading: false
            });
            this.updateStatusMessage("Job not found. Please go back and try again.");
        }
    }

    async populateJob(job_id) {
        const newState = {
            loading: false
        };

        let response = await getJobSummary(job_id);

        if (response) {
            response = {
                ...addEvaluationAttributesToggleAndDisplay(response)
            };

            response = {
                ...response,
                job_stages: response?.job_stages.map((job_stage) => sanitiseJobStageData(job_stage))
            };

            newState.originalJob = cloneDeep(response);
            newState.job = cloneDeep(response);

            const { integration_providers } = await getCompanyDetails();

            if (!!integration_providers && !!integration_providers.length) {
                newState.integrationProviders = integration_providers;
            }
        }

        newState.currentJobStageId = response.job_stages?.[0].job_stage_id;

        const brandings = await getBrandings();

        if (brandings) {
            newState.brandings = brandings;
        }

        this.setState(newState);

        await this.retrieveStaffRecords();
    }

    async retrieveStaffRecords() {
        let staff = await getStaff();

        if (staff) {
            staff = sortBy(staff, ['first_name', 'last_name'])
        }

        this.setState({
            staff
        });
    }

    showEmailTemplates() {
        const { features } = this.props;

        return isAdminUserSettingEnabled(eUserSettings.TEMPLATES_EMAIL) ||
            isCompanyFeatureEnabled(eCompanyFeatures.TEMPLATES_EMAIL, features);
    }

    getBrandingsAsDropdown() {
        const { job : { branding_id: selectedBrandingID }, brandings = [] } = this.state;

        return sortBy(brandings, ["branding_name"]).map(({ branding_id, branding_name }) => {
            return {
                id: `${branding_id}`,
                firstLine: branding_name,
                selected: `${branding_id}` === `${selectedBrandingID}`,
                onClick: () => this.onJobInputChange({ target: { id: "branding_id", value: branding_id } })
            };
        });
    }

    onInitialDescriptionChange() {
        const { job, originalJob, hasDescriptionBeenManuallyChanged } = this.state;

        if (!hasDescriptionBeenManuallyChanged) {
            const updatedOriginalJob = {
                ...originalJob,
                position_description: job.position_description
            };

            this.setState({
                originalJob: updatedOriginalJob,
                hasDescriptionBeenManuallyChanged: true
            });
        }
    }

    onJobInputChange(event) {
        const { target: { id, value } } = event;
        const {
            job,
            isCandidateContactNameError: previousNameError,
            isCandidateContactEmailError: previousEmailError
        } = this.state;

        const updatedJobState = {...job};

        if (objectToArray(JOB_STAGES_FIELDS).includes(id)) {
            // job stage level
            updatedJobState.job_stages = updatedJobState.job_stages.map((job_stage) => {
                return {
                    ...job_stage,
                    [id]: value
                };
            });
        } else {
            // job level
            updatedJobState[id] = value;
        }

        let isCandidateContactNameError = previousNameError,
            isCandidateContactEmailError = previousEmailError;

        if (['candidate_contact_name','candidate_contact_email'].includes(id)) {
            const { candidate_contact_name, candidate_contact_email } = job;
            const validatedContact = this.validateCandidateContact(id, value, candidate_contact_name, candidate_contact_email);

            isCandidateContactNameError = validatedContact.isCandidateContactNameError;
            isCandidateContactEmailError = validatedContact.isCandidateContactEmailError;
        }

        this.setState({
            job: updatedJobState,
            isCandidateContactNameError,
            isCandidateContactEmailError
        });
    }

    validateCandidateContact(id, value, name, email) {
        const candidateContactName = id === 'candidate_contact_name' ? value : name;
        const candidateContactEmail = id === 'candidate_contact_email' ? value : email;

        return {
            isCandidateContactNameError: !!(getValue(candidateContactEmail, '')).trim() && !(getValue(candidateContactName, '')).trim(),
            isCandidateContactEmailError: (!!(getValue(candidateContactName, '')).trim() && !(getValue(candidateContactEmail, '')).trim()) ||
            (!!(getValue(candidateContactEmail, '')).trim() && !isValidEmail(candidateContactEmail))
        };
    }

    isFieldEmpty(field) {
        const { job = {} } = this.state;
        const value = job[field];
        return typeof value === "string" ? value.trim() === "" : !value;
    }

    isValueEmpty(value) {
        return typeof value === "string" ? value.trim() === "" : !value;
    }

    isJobDeadlineError() {
        const { job = {} } = this.state;

        const jobStage = getCurrentJobStage(job);

        const {
            deadline_type,
            deadline,
            ongoing_deadline_days
        } = jobStage;

        if (deadline_type === eDeadlineType.FIXED) {
            return this.isValueEmpty(deadline);
        }

        if (deadline_type === eDeadlineType.ONGOING) {
            return this.isValueEmpty(ongoing_deadline_days);
        }

        return false;
    }

    hasJobChanged() {
        const { job, originalJob, hasDescriptionBeenManuallyChanged } = this.state;
        const {
            title: jobTitle,
            location: jobLocation,
            branding_id: jobBrandingId,
            owner_staff_id: jobOwnerStaffId,
            candidate_contact_name: jobCandidateContactName,
            candidate_contact_email: jobCandidateContactEmail,
            position_description: jobDescription,
            job_stages = []
        } = job;

        const {
            deadline_type: jobDeadlineType,
            deadline: jobDeadline,
            ongoing_deadline_days: jobOngoingDeadlineDays,
            interview_terminology: jobTerminology,
            job_interview_steps: jobInterviewSteps,
            candidates: jobCandidates,
            evaluators: jobEvaluators,
            ask_for_feedback: jobAskForFeedback,
            requires_response_evaluation: jobRequiresResponseEvaluation,
            evaluation_attributes_enabled: jobEvaluationAttributesEnabled,
            evaluation_attributes_display: jobEvaluationAttributesDisplay,
            sends_invitation_email,
            sends_acknowledgement_email,
            sends_successful_email,
            sends_unsuccessful_email,
            invitation_email_template_id,
            acknowledgement_email_template_id,
            successful_email_template_id,
            unsuccessful_email_template_id,
            invitation_sms_template_id: invitationSMSTemplateId,
            reminder_sms_templates: reminderSMSTemplates
        } = job_stages[0] || {};

        const {
            title: originalJobTitle,
            location: originalJobLocation,
            branding_id: originalJobBrandingId,
            owner_staff_id: originalJobOwnerStaffId,
            candidate_contact_name: originalCandidateContactName,
            candidate_contact_email: originalCandidateContactEmail,
            position_description: originalJobDescription,
            job_stages: originalJobStages = []
        } = originalJob;

        const {
            // job stage
            deadline_type: originalJobDeadlineType,
            deadline: originalJobDeadline,
            ongoing_deadline_days: originalOngoingDeadlineDays,
            interview_terminology: originalJobTerminology,
            job_interview_steps: originalJobInterviewSteps,
            candidates: originalJobCandidates,
            evaluators: originalJobEvaluators,
            ask_for_feedback: originalJobAskForFeedback,
            requires_response_evaluation: originalJobRequiresResponseEvaluation,
            evaluation_attributes_enabled: originalJobEvaluationAttributesEnabled,
            evaluation_attributes_display: originalJobEvaluationAttributesDisplay,
            sends_invitation_email: originalSendsInvitationEmail,
            sends_acknowledgement_email: originalSendsAcknowledgementEmail,
            sends_successful_email: originalSendsSuccessfulEmail,
            sends_unsuccessful_email: originalSendsUnsuccessfulEmail,
            invitation_email_template_id: originalInvitationEmailTemplateId,
            acknowledgement_email_template_id: originalAcknowledgementEmailTemplateId,
            successful_email_template_id: originalSuccessfulEmailTemplateId,
            unsuccessful_email_template_id: originalUnsuccessfulEmailTemplateId,
            invitation_sms_template_id: originalInvitationSMSTemplateId,
            reminder_sms_templates: originalReminderSMSTemplates
        } = originalJobStages[0] || {};


        const hasJobTitleChanged = jobTitle !== originalJobTitle;
        const hasLocationChanged = jobLocation !== originalJobLocation;
        const hasBrandingIdChanged = jobBrandingId !== originalJobBrandingId;
        const hasJobOwnerStaffIdChanged = !isEqual(jobOwnerStaffId, originalJobOwnerStaffId);
        const hasCandidateContactNameChanged = !isEqual(jobCandidateContactName, originalCandidateContactName);
        const hasCandidateContactEmailChanged = !isEqual(jobCandidateContactEmail, originalCandidateContactEmail);

        const hasJobDeadlineTypeChanged = jobDeadlineType !== originalJobDeadlineType;
        const hasJobDeadlineChanged = jobDeadline !== originalJobDeadline;
        const hasOngoingDeadlineDaysChanged = jobOngoingDeadlineDays !== originalOngoingDeadlineDays;
        const hasTerminologyChanged = jobTerminology !== originalJobTerminology;
        const hasDescriptionChanged = jobDescription !== originalJobDescription;
        const hasInterviewStepsChanged = !isEqual(jobInterviewSteps, originalJobInterviewSteps);
        const hasCandidatesChanged = !isEqual(jobCandidates, originalJobCandidates);
        const hasEvaluatorsChanged = !isEqual(jobEvaluators, originalJobEvaluators);
        const hasAskForFeedbackChanged = !isEqual(jobAskForFeedback, originalJobAskForFeedback);
        const hasRequireResponseEvaluationChanged = !isEqual(jobRequiresResponseEvaluation, originalJobRequiresResponseEvaluation);
        const hasEvaluationAttributesEnabledChanged = !isEqual(jobEvaluationAttributesEnabled, originalJobEvaluationAttributesEnabled);
        const hasEvaluationAttributesChanged = !isEqual(jobEvaluationAttributesDisplay, originalJobEvaluationAttributesDisplay);

        const hasSendsInvitationEmailChanged = !isEqual(sends_invitation_email, originalSendsInvitationEmail);
        const hasSendsAcknowledgementEmailChanged = !isEqual(sends_acknowledgement_email, originalSendsAcknowledgementEmail);
        const hasSendsSuccessfulEmailChanged = !isEqual(sends_successful_email, originalSendsSuccessfulEmail);
        const hasSendsUnsuccessfulEmailChanged = !isEqual(sends_unsuccessful_email, originalSendsUnsuccessfulEmail);

        const hasInvitationEmailTemplateChanged = !isEqual(invitation_email_template_id, originalInvitationEmailTemplateId);
        const hasAcknowledgementEmailTemplateChanged = !isEqual(acknowledgement_email_template_id, originalAcknowledgementEmailTemplateId);
        const hasSuccessfulEmailTemplateChanged = !isEqual(successful_email_template_id, originalSuccessfulEmailTemplateId);
        const hasUnsuccessfulEmailTemplateChanged = !isEqual(unsuccessful_email_template_id, originalUnsuccessfulEmailTemplateId);

        const hasInvitationSMSTemplateChanged = !isEqual(invitationSMSTemplateId, originalInvitationSMSTemplateId);
        const hasReminderSMSTemplatesChanged = !isEqual(reminderSMSTemplates, originalReminderSMSTemplates);

        return (
            hasJobTitleChanged ||
            hasLocationChanged ||
            (
                hasJobDeadlineTypeChanged ||
                (
                    (jobDeadlineType === eDeadlineType.FIXED && hasJobDeadlineChanged) ||
                    (jobDeadlineType === eDeadlineType.ONGOING && hasOngoingDeadlineDaysChanged)
                )
            ) ||
            hasTerminologyChanged ||
            hasBrandingIdChanged ||
            (hasDescriptionChanged && hasDescriptionBeenManuallyChanged) ||
            hasInterviewStepsChanged ||
            hasCandidatesChanged ||
            hasEvaluatorsChanged ||
            hasAskForFeedbackChanged ||
            hasRequireResponseEvaluationChanged ||
            hasEvaluationAttributesEnabledChanged ||
            (jobEvaluationAttributesEnabled && hasEvaluationAttributesChanged) ||
            hasJobOwnerStaffIdChanged ||
            hasCandidateContactNameChanged ||
            hasCandidateContactEmailChanged ||
            hasSendsInvitationEmailChanged ||
            hasSendsAcknowledgementEmailChanged ||
            hasSendsSuccessfulEmailChanged ||
            hasSendsUnsuccessfulEmailChanged ||
            hasInvitationEmailTemplateChanged ||
            hasAcknowledgementEmailTemplateChanged ||
            hasSuccessfulEmailTemplateChanged ||
            hasUnsuccessfulEmailTemplateChanged ||
            hasInvitationSMSTemplateChanged ||
            hasReminderSMSTemplatesChanged
        );
    }

    isJobReadyToSave() {
        const {
            job = {},
            isCandidateContactNameError,
            isCandidateContactEmailError
        } = this.state;

        const { reminder_sms_templates } = job.job_stages?.[0] || {};

        const showCandidateContact = isAlcamiStaff() && getUserSetting(eUserSettings.CANDIDATE_CONTACT);

        return this.hasJobChanged() &&
            !this.isFieldEmpty("title") &&
            !this.isFieldEmpty("location") &&
            (
                // candidate contact
                showCandidateContact ?
                    !isCandidateContactNameError && !isCandidateContactEmailError :
                    true
            ) &&
            // job stage
            !this.isJobDeadlineError() &&
            // reminder sms
            (
                !!reminder_sms_templates ?
                    !hasDuplicateSMSReminders(reminder_sms_templates) &&
                    !hasIncompleteSMSReminders(reminder_sms_templates) :
                    true
            )
        ;
    }

    async saveJobChanges() {
        const { job } = this.state;

        job.candidate_contact_name = (getValue(job.candidate_contact_name, '')).trim();
        job.candidate_contact_email = (getValue(job.candidate_contact_email, '')).trim();

        const currentJobStageId = job.job_stages?.[0]?.job_stage_id;
        await this.cleanseJobStage(currentJobStageId);

        const response = await updateJob(job);

        if (response.error) {
            this.displayErrorMessage(response);
            return;
        }

        // update Job Stage
        const payload = this.state.job.job_stages?.[0]; // job object might have been updated by steps above, thus the repeat retrieval

        // delete candidates (no longer saved from this section)
        delete payload.candidates;

        payload.evaluation_attributes = payload.evaluation_attributes_enabled ? payload.evaluation_attributes_display : [];

        const { job_stage_type, job_stage_id } = payload;
        const job_stage_response = await updateJobStage(job.job_id, job_stage_type, job_stage_id, payload);

        if (job_stage_response.error) {
            this.displayErrorMessage(response);
            return;
        }

        this.cancelJobEdit();
    }

    displayErrorMessage(response) {
        const errorMessage = get(response, ["error", "response", "statusText"]) ||
            get(response, ["error", "response", "data"]) ||
            get(response, ["error", "message"]);
        this.updateStatusMessage(errorMessage);
    }

    /*confirmDiscardChanges(e) {
        this.setState({
            confirmDialogProps: {
                header: "Confirm Changes",
                message: "Do you want to save your changes?",
                actions: [
                    {
                        label: "Discard",
                        classes: "outline",
                        onClick: this.closeConfirmDialog
                    },
                    {
                        label: "Save",
                        classes: "info",
                        onClick: () => null
                    }
                ]
            }
        })
    }*/

    closeConfirmDialog() {
        this.setState({
            confirmDialogProps: null
        });
    }

    cancelJobEdit() {
        window.location.href = this.props.urlFor('manage-job-details', { job_id: this.props.job_id });
    }

    updateStatusMessage(message) {
        this.setState({
            statusMessage: {
                message,
                reset: new Date()
            }
        });
    }

    getAvailableStaff() {
        const { job, staff = [] } = this.state;
        const { evaluators = [] } = getCurrentJobStage(job);

        const invitedEvaluatorIds = evaluators.map(({staff_id}) => (staff_id));

        return staff.filter(({staff_id}) => !invitedEvaluatorIds.includes(staff_id));
    }

    addEvaluator(staffId) {
        const { staff, job = {} } = this.state;
        const { evaluators = [] } = getCurrentJobStage(job);

        const newEvaluator = staff.find(({staff_id}) => staff_id === staffId);

        const newEvaluators = Array.from(evaluators);
        newEvaluators.push({
            ...newEvaluator,
            evaluation_options: defaultEvaluationOptions
        });

        this.onJobInputChange({
            target: {
                id: "evaluators",
                value: newEvaluators
            }
        });
    }

    confirmRemoveEvaluator(staffId) {
        const { removeEvaluatorConfirmationShown, originalJob } = this.state;
        const { evaluators = [] } = getCurrentJobStage(originalJob);

        const hasStaffIdBeenAdded = evaluators.map(({staff_id}) => staff_id).includes(staffId);

        if (!removeEvaluatorConfirmationShown && hasStaffIdBeenAdded) {
            this.setState({
                confirmDialogProps: {
                    title: "Remove Evaluator",
                    message:
                        <div>
                            Removing an evaluator from a job will also hide all evaluations they have performed for the job.<br/>
                            <br/>
                            Do you wish to remove this evaluator?
                        </div>,
                    actions: [
                        {
                            label: "Cancel",
                            classes: "outline",
                            onClick: this.closeConfirmDialog
                        },
                        {
                            label: "Remove",
                            classes: "warning",
                            onClick: () => this.setState({
                                removeEvaluatorConfirmationShown: true
                            }, () => this.removeEvaluator(staffId))
                        }
                    ]
                }
            });
        } else {
            this.removeEvaluator(staffId);
        }
    }

    removeEvaluator(staffId) {
        const { job } = this.state;
        const { evaluators = [] } = getCurrentJobStage(job);
        const updatedEvaluators = evaluators.filter(({staff_id}) => staff_id !== staffId);

        this.onJobInputChange({
            target: {
                id: "evaluators",
                value: updatedEvaluators
            }
        });

        this.closeConfirmDialog();
    }

    getCurrentBranding() {
        const { brandings, job : { branding_id: selectedBrandingID } } = this.state;
        return brandings.find((branding) => branding[eBrandingType.ID] === selectedBrandingID) || {};
    }

    showPreviewBranding() {
        this.setState({
            isBrandingPreviewShown: true
        });
    }

    hidePreviewBranding() {
        this.setState({
            isBrandingPreviewShown: false
        });
    }

    getCandidateForPreview() {
        const { job = {} } = this.state;
        const jobStage = getCurrentJobStage(job);

        const {
            deadline_type,
            deadline,
            ongoing_deadline_days
        } = jobStage;

        let deadlineString = '';

        if (deadline_type === eDeadlineType.FIXED) {
            deadlineString = deadline;
        }

        if (deadline_type === eDeadlineType.ONGOING) {
            deadlineString = getEndOfDay(ongoing_deadline_days);
        }

        return {
            ...DEFAULT_CANDIDATE_FOR_EMAIL_PREVIEW,
            deadline: deadlineString
        };
    }

    getStepContent(step) {
        if (step === "templates") {
            const showEmailTemplates = this.showEmailTemplates();
            const showSMSTemplates = isAdminUserSettingEnabled(eUserSettings.TEMPLATES_SMS);

            if (showEmailTemplates || showSMSTemplates) {
                const { job = {} } = this.state;
                const jobStage = getCurrentJobStage(job);

                const { invitation_sms_template_id, reminder_sms_templates, is_open_registration } = jobStage;

                return (
                    <div className={`step templates`}>
                        <div className={`step-header-text`}>
                            <span className={`header-title`}>
                                Templates
                            </span>
                        </div>
                        <div className={`step-content`}>
                            {showEmailTemplates && (
                                <EmailTemplateSelection
                                    job={job}
                                    job_stage={jobStage}
                                    candidate={this.getCandidateForPreview()}
                                    is_open_registration={is_open_registration}
                                    onChange={this.onJobInputChange}
                                />
                            )}
                            {showSMSTemplates && (
                                <SMSTemplateSelection
                                    invitation_sms_template_id={invitation_sms_template_id}
                                    reminder_sms_templates={getValue(reminder_sms_templates, [])}
                                    onChange={this.onJobInputChange}
                                />
                            )}
                        </div>
                    </div>
                );
            }
        }

        return null;
    }

    render() {
        const { integration } = this.props;
        const {
            job = {},
            originalJob = {},
            staff = [],
            loading,
            statusMessage: { message, reset },
            confirmDialogProps,
            isBrandingPreviewShown,
            isCandidateContactNameError,
            isCandidateContactEmailError
        } = this.state;

        const {
            job_id,
            title,
            location,
            owner_staff_id,
            candidate_contact_name,
            candidate_contact_email,
            position_description,
            status,
        } = job;

        const jobStage = getCurrentJobStage(job);

        const {
            job_stage_id,
            candidates = [],
            deadline_type,
            deadline,
            ongoing_deadline_days,
            interview_terminology,
            job_interview_steps = [],
            evaluators = []
        } = jobStage;

        const { title: originalJobTitle } = originalJob;

        const interviewQuestions = getInterviewQuestions(job_interview_steps);
        const showCandidateContact = isAdminUserSettingEnabled(eUserSettings.CANDIDATE_CONTACT);
        const isReadOnly = status === eJobsType.ARCHIVED;

        return (
            <Container
                loading={loading}
                confirmDialogProps={confirmDialogProps}
            >
                <StatusMessage
                    type={eStatusMessageType.WARNING}
                    message={message}
                    duration={5000}
                    reset={reset}
                />
                <div className={`job-edit has-intercom ${integration ? 'integration' :''}`}>
                    <div className={`job-header`}>
                        <div className={`back-container`}>
                            <div className={'back-link'} onClick={this.cancelJobEdit}>&lt; <span>back</span></div>
                        </div>
                        <div className={`job-name`}>
                            <div className={`job-title`}>
                                <span>{originalJobTitle}</span><sup className={`job-id`}>#{job_id}</sup></div>
                        </div>
                        <div className={`job-actions`}>
                            {!isReadOnly && (
                                <button
                                    className={`react-button save-button ${this.isJobReadyToSave() ? 'active ripple' : 'disabled'}`}
                                    onClick={() => this.isJobReadyToSave() && this.saveJobChanges()}
                                >
                                    Save
                                </button>
                            )}
                        </div>
                    </div>

                    <div className={`job-steps`}>
                        <div className={`step job-details`}>
                            <div className={`step-header-text`}>
                                <span className={`header-title`}>
                                    Job Details
                                </span>
                            </div>
                            <div className={`step-content ${isReadOnly ? 'read-only' : ''}`}>
                                {isReadOnly && (
                                    <React.Fragment>
                                        <LabelValueTile classes="job-title" label="Job Title" value={title} />
                                        <LabelValueTile classes="location" label="Location" value={location} />
                                    </React.Fragment>
                                )}
                                {!isReadOnly && (
                                    <React.Fragment>
                                        <CustomTextField
                                            id={`title`}
                                            classes={`job-title`}
                                            required={true}
                                            disabled={isReadOnly}
                                            label="Job Title"
                                            value={title}
                                            isError={this.isFieldEmpty("title")}
                                            onChange={this.onJobInputChange}
                                        />
                                        <CustomTextField
                                            id={`location`}
                                            classes={`location`}
                                            required={true}
                                            disabled={isReadOnly}
                                            label="Location"
                                            value={location}
                                            isError={this.isFieldEmpty("location")}
                                            onChange={this.onJobInputChange}
                                        />
                                    </React.Fragment>
                                )}
                                <LabelValueTile classes="terminology" label="Terminology" value={interview_terminology} />
                                {isReadOnly && (
                                    <React.Fragment>
                                        <LabelValueTile classes="job-deadline" label="Deadline" value={`${formatDate(deadline)} (${deadline_type})`} />
                                        <LabelValueTile classes="job-branding" label="Branding" value={this.getCurrentBranding().branding_name || null} />
                                    </React.Fragment>
                                )}
                                {!isReadOnly && (
                                    <React.Fragment>
                                        <JobDeadline
                                            deadline_type={deadline_type}
                                            deadline={deadline}
                                            ongoing_deadline_days={ongoing_deadline_days}
                                            showDeadlineDescription={!!candidates.length}
                                            isError={this.isJobDeadlineError()}
                                            onInputChange={this.onJobInputChange}
                                        />
                                        <div className={`branding-container`}>
                                            <div className={`branding-header`}>Branding</div>
                                            <Dropdown
                                                required={false}
                                                styles="floating-label fixed-label"
                                                classes="branding-dropdown"
                                                label="App Theme"
                                                items={this.getBrandingsAsDropdown()}
                                            />
                                            <button className={`link branding-preview-link`} onClick={this.showPreviewBranding}>Preview</button>
                                        </div>
                                    </React.Fragment>
                                )}
                                {/*<CustomRadioField
                                    classes={'interview-terminology'}
                                    id={'interview_terminology'}
                                    required={false}
                                    label='Terminology'
                                    items={objectToDropdownItems(eInterviewTerminologies)}
                                    defaultValue={interview_terminology}
                                    onChange={this.onJobInputChange}
                                />*/}
                                <CustomTextArea
                                    classes={`position_description`}
                                    hasPadding={true}
                                    type={eTextAreaType.RICH}
                                    id={`position_description`}
                                    value={position_description}
                                    readOnly={isReadOnly}
                                    onInitialInteraction={this.onInitialDescriptionChange}
                                    onChange={this.onJobInputChange}
                                />
                            </div>
                        </div>

                        <div className={`step questions`}>
                            <div className={`step-header-text`}>
                                <span className={`header-title`}>
                                    Questions
                                </span>
                            </div>
                            <div className={`step-content`}>
                                <div className={`step-interview`}>
                                    <StepQuestions
                                        currentJobStageId={job_stage_id}
                                        job={job}
                                        areInterviewStepsReadOnly={!isAdminUserSettingEnabled(eUserSettings.JOB_EDIT_EDIT_QUESTIONS)}
                                        areAttributesReadOnly={true}
                                        onInputChange={this.onJobInputChange}
                                        onTemplateSelection={this.onTemplateSelection}
                                    />
                                </div>
                            </div>
                        </div>

                        {this.getStepContent("templates")}

                        <div className={`step evaluators`}>
                            <div className={`step-header-text`}>
                                <span className={`header-title`}>
                                    Evaluators
                                </span>
                            </div>
                            <div className={`step-content`}>
                                <div className={`job-owner`}>
                                    <StaffSelection
                                        header={<div><span>Job Owner</span><span title={`Refresh`} onClick={this.retrieveStaffRecords}>{IconRefresh}</span></div>}
                                        staffRecords={staff}
                                        staffFilter={({is_manager}) => !!is_manager}
                                        selectedStaffId={owner_staff_id}
                                        readOnly={isReadOnly}
                                        onInputChange={this.onJobInputChange}
                                        selectionIdProp="owner_staff_id"
                                    />
                                </div>
                                {showCandidateContact && (
                                    <div className={`candidate-contact`}>
                                        <div className={`candidate-contact-header`}>
                                            Candidate Support Contact
                                        </div>
                                        <div className={`candidate-contact-note`}>
                                            Please enter the name and email address of someone in your organisation who would be able
                                            to assist the candidates with any enquiries they may have. {/*The name and email address
                                            provided below will be made available to the candidates in the invitation emails. Alcami's
                                            support team will also direct non-technical enquiries to this contact.*/}Alcami's
                                            support team will direct non-technical enquiries to this contact.
                                        </div>
                                        <CustomTextField
                                            classes={`candidate-contact-name`}
                                            id={`candidate_contact_name`}
                                            required={false}
                                            label={`Name`}
                                            value={getValue(candidate_contact_name, '')}
                                            isError={isCandidateContactNameError}
                                            onChange={this.onJobInputChange}
                                        />
                                        <CustomTextField
                                            classes={`candidate-contact-email`}
                                            id={`candidate_contact_email`}
                                            required={false}
                                            label={`Email`}
                                            value={getValue(candidate_contact_email, '')}
                                            isError={isCandidateContactEmailError}
                                            onChange={this.onJobInputChange}
                                        />
                                    </div>
                                )}
                                <div className={`job-evaluators`}>
                                    {!isReadOnly && (
                                        <EvaluatorSelection
                                            header={<div><span>Evaluators</span><span title={`Refresh`} onClick={this.retrieveStaffRecords}>{IconRefresh}</span></div>}
                                            evaluators={this.getAvailableStaff()}
                                            onAdd={this.addEvaluator}
                                        />
                                    )}
                                    {isReadOnly && (
                                        <div className={`job-evaluators-header`}>Job Evaluators</div>
                                    )}
                                    <Evaluators
                                        classes={`evaluator-list`}
                                        evaluators={evaluators}
                                        questions={interviewQuestions.filter(({interview_step_type}) => interview_step_type === "question")}
                                        editable={!isReadOnly}
                                        removable={true}
                                        onUpdate={this.onJobInputChange}
                                        onRemove={this.confirmRemoveEvaluator}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {isBrandingPreviewShown && (
                        <CustomModal
                            classes={`branding-preview-container open`}
                            onClose={this.hidePreviewBranding}
                        >
                            <BrandingPreview job={job} branding={this.getCurrentBranding()} />
                        </CustomModal>
                    )}
                </div>
            </Container>
        );
    }
}

JobEdit.propTypes = {
    integration: PropTypes.bool,
    features: PropTypes.array,
    job_id: PropTypes.number.isRequired,
    urlFor: PropTypes.func.isRequired
};

JobEdit.defaultProps = {
    integration: false,
    features: [],
    job_id: null,
    urlFor: null
};

export default JobEdit;
