import React from "react";
import * as PropTypes from "prop-types";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CustomTextField from "../CustomTextField";
import { KEYBOARDKEYCODES } from "../../../utils";
import { IconDomino, IconTrash } from "../../Icons";

import "./style.scss";


class CustomList extends React.Component {
    constructor(props) {
        super(props);

        this.onDataChange = this.onDataChange.bind(this);
        this.addItem = this.addItem.bind(this);
        this.deleteListItem = this.deleteListItem.bind(this);
        this.reorderItems = this.reorderItems.bind(this);

        this.state = {
             newItem: ''
        };
    }

    async onDataChange(index, e) {
        const { items, onChange } = this.props;
        const isEnter = e.which === KEYBOARDKEYCODES.ENTER;

        if (index === null) {
            const value = e.target.value;
            if (isEnter && !!value) {
                this.addItem();
            } else {
                this.setState({
                    newItem: e.target.value
                });
            }
        } else {
            items[index] = e.target.value;
            !!onChange && onChange(items);
        }
    }

    addItem() {
        const { items, onChange } = this.props;
        const { newItem } = this.state;
        items.push(newItem);
        !!onChange && onChange(items);
        this.setState({
            newItem: ''
        });
    }

    deleteListItem(i) {
        const { items, onChange } = this.props;
        items.splice(i, 1);
        !!onChange && onChange(items);
    }

    reorderItems(result) {
        const { items } = this.props;
        const { source, destination } = result;

        const sourceIndex = source.index;
        const item = items[sourceIndex];
        items.splice(source.index, 1);
        items.splice(destination.index, 0, item);
    }

    render() {
        const { classes, items } = this.props;
        const { newItem } = this.state;
        return (
            <div className={`custom-list ${classes}`}>
                <div className={`custom-list-add`}>
                    <CustomTextField
                        id={`custom-list-item-add`}
                        classes={`custom-list-item`}
                        value={newItem}
                        onKeyDown={(e) => this.onDataChange(null, e)}
                        onChange={(e) => this.onDataChange(null, e)}
                    />
                    <div
                        className={`custom-list-item-add-button react-button ${!newItem ? 'disabled' : ''}`}
                        onClick={!!newItem ? this.addItem : null}
                    >
                        Add
                    </div>
                </div>
                <DragDropContext onDragEnd={this.reorderItems}>
                    <Droppable droppableId={`customListDroppable`}>
                        {provided => (
                            <div {...provided.droppableProps}
                                 ref={provided.innerRef}
                                 className={`custom-list-items`}
                            >
                                {items && items.map((item, index) => (
                                    <Draggable
                                        key={`custom-list-item-line-${index}`}
                                        draggableId={`custom-list-item-line-${index}`}
                                        index={index}
                                    >
                                        {(p, snapshot) => (
                                            <div
                                                {...p.draggableProps}
                                                className={`custom-list-item-line`}
                                                ref={p.innerRef}
                                                provided={p}
                                                snapshot={snapshot}
                                            >
                                                <div className={`row-index`} {...p.dragHandleProps}>
                                                    <span className={`dragHandle`}>{IconDomino}</span>
                                                </div>
                                                <CustomTextField
                                                    id={`custom-list-item-${index}`}
                                                    classes={`custom-list-item`}
                                                    value={item}
                                                    onKeyDown={(e) => this.onDataChange(index, e)}
                                                    onChange={(e) => this.onDataChange(index, e)}
                                                />
                                                <div
                                                    className={`custom-list-item-delete`}
                                                    onClick={() => this.deleteListItem(index)}
                                                    >
                                                    {IconTrash}
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        );
    }
}

CustomList.propTypes = {
    classes: PropTypes.string,
    items: PropTypes.array,
    onChange: PropTypes.func
};

CustomList.defaultProps = {
    classes: "",
    items: [],
    onChange: null
};

export default CustomList;