import React from "react";
import Avatar from "../../Avatar";

import './style.scss';


export const DropdownItem = ({id, item}) => {
    const { avatar, firstLine, secondLine } = item;

    return (
        <div
            id={`dropdown_${id}`}
            className={`dropdown-item`}
        >
            {!!avatar && <Avatar classes='item-avatar' data={{id, ...avatar}} />}
            <span className="firstLine">{firstLine}</span>
            {!!secondLine && <span className="secondLine">{secondLine || "-"}</span>}
            <div className={`dropdown-overlay-${id}`}></div>
        </div>
    );
};
