import React from "react";
import * as PropTypes from "prop-types";
import {IconTickThin} from "../../Icons";
import {objectToArray} from "../../../utils";

import './style.scss';


export const eCheckboxType = {
    NATIVE: "native",
    CUSTOM: "custom"
};

export const eCustomCheckboxSize = {
    SMALL: "small",
    MEDIUM: "medium"
};

class CustomCheckbox extends React.Component {
    render() {
        const {
            type = eCheckboxType.NATIVE,
            id,
            size = eCustomCheckboxSize.SMALL,
            classes,
            required = false,
            disabled = false,
            label,
            // value,
            checked,
            onChange
        } = this.props;

        if (type === eCheckboxType.NATIVE) {
            return (
                <div className={`customCheckbox native ${classes || ''} ${size || ''}`}>
                    <input
                        name={id}
                        // id="select-all"
                        // aria-label="Select all"
                        // ref="select-all"
                        disabled={disabled}
                        type="checkbox"
                        checked={checked}
                        onChange={onChange}
                    />
                    {label && (
                        <div className={`label ${disabled ? 'disabled' : ''}`}>{label}{required && (<sup className="required">*</sup>)}</div>
                    )}
                </div>
            );
        } else {
            return (
                <div className={`customCheckbox custom ${classes || ''} ${size || ''}`}>
                    <input type="checkbox" disabled={disabled} name={id} checked={checked} onChange={onChange} />
                    <span className={`checkbox ${checked ? 'checked' : ''} ${disabled ? 'disabled' : ''}`} onClick={() => !disabled && onChange()}>
                        {IconTickThin}
                    </span>
                    <div className={`label ${disabled ? 'disabled' : ''}`}>{label}{required && (<sup className="required">*</sup>)}</div>
                </div>
            );
        }
    }
}

CustomCheckbox.propTypes = {
    type: PropTypes.oneOf(objectToArray(eCheckboxType)),
    id: PropTypes.string,
    size: PropTypes.oneOf(objectToArray(eCustomCheckboxSize)),
    classes: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    checked: PropTypes.bool,
    onChange: PropTypes.func
};

CustomCheckbox.defaultProps = {
    type: eCheckboxType.NATIVE,
    id: null,
    size: eCustomCheckboxSize.SMALL,
    classes: "",
    required: false,
    disabled: false,
    label: "",
    checked: false,
    onChange: null
};

export default CustomCheckbox;
