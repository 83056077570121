import React from "react";
import * as PropTypes from "prop-types";
import Avatar from "../Avatar";
import {
    ANSWER_TIMES,
    ANSWER_TIMES_WITH_UNTIMED_OPTION,
    durationAsSeconds,
    eDropDownPosition,
    eDropdownTypes,
    fancyTimeFormat,
    isValidDuration,
    NUMBER_OF_ATTEMPTS,
    objectToArray,
    PREPARATION_TIMES,
    SMS_INTERVALS
} from "../../utils";
import CustomTextField from "../Form/CustomTextField";
import {IconArrowDown, IconStar} from "../Icons";
import Tooltip from "../Tooltip";
import {compact, sortBy, uniq} from "lodash";

import './style.scss';


const eDropdownItemType = {
    SELECTOR: "selector",
    SELECTION: "selection"
};

const DropdownSelector = ({id, type, disabled = false, showArrow, persistLabel, isDropdownOpen, icon, label, tooltipIcon, tooltipDescription, item, selected, onClick, link}) => {
    const { avatar, isGroupName, firstLine, secondLine } = item;

    const showAvatar = !persistLabel && avatar !== undefined,
        showFirstLine = !persistLabel && firstLine !== undefined,
        showSecondLine = !persistLabel && secondLine !== undefined;

    const linkProps = {};
    if (!!link) {
        linkProps.href = link;
        linkProps.target = "_blank"
    } else if (!disabled) {
        linkProps.onClick = onClick;
    }

    return (
        <a
            id={`dropdown_${id}`}
            // eslint-disable-next-line jsx-a11y/aria-role
            role={`${type === eDropdownItemType.SELECTION ? 'option' : 'button'}`}
            // eslint-disable-next-line jsx-a11y/aria-proptypes
            aria-haspopup={`${type === eDropdownItemType.SELECTOR ? 'listbox' : ''}`}
            aria-expanded={isDropdownOpen}
            aria-labelledby={`dropdown_${id} dropdown_${id}_${type}`}
            className={`
                ${type}
                ${showAvatar ? 'withAvatar' : ''}
                ${isGroupName ? 'groupName' : ''}
                ${showFirstLine ? 'withFirstLine' : ''}
                ${showSecondLine ? 'withSecondLine' : ''}
                ${isDropdownOpen ? 'selecting' : ''}
                ${disabled ? 'disabled' : ''}
                ${selected ? 'selected': ''}
                ${persistLabel ? 'persistLabel' : ''}
            `}
            {...linkProps}
        >
            {showAvatar && (<Avatar classes='item-avatar' data={{id, ...avatar}} />)}
            {type === eDropdownItemType.SELECTOR && (
                <span id={`dropdown_${id}_placeholder`} className="placeholder" title={!tooltipIcon && !tooltipDescription ? label : ''}>
                {icon}
                    {label && (<span className={`label`}>{label}</span>)}
                    {tooltipIcon && tooltipDescription && (
                        <Tooltip
                            positioning="onRight"
                            item={tooltipIcon}
                            description={tooltipDescription}
                        />
                    )}
            </span>
            )}
            {showFirstLine && (<span className="firstLine">{firstLine}</span>)}
            {showSecondLine && (<span className="secondLine">{secondLine}</span>)}
            {type === eDropdownItemType.SELECTOR && showArrow && (<span className="arrow">{IconArrowDown}</span>)}
            <div className={`dropdown-overlay-${id}`} />
        </a>
    );
};

class Dropdown extends React.Component {
    constructor(props) {
        super(props);

        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.openDropdown = this.openDropdown.bind(this);
        this.closeDropdown = this.closeDropdown.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.updateNewValue = this.updateNewValue.bind(this);
        this.addNewDropdownValue = this.addNewDropdownValue.bind(this);

        this.state = {
            id: parseInt("" + Math.random() * 10000, 0),
            isDropdownOpen: false,
            newValue: '',
            customValues: [],
            isManualInputError: false
        };

        const { items } = props;

        items.forEach((_item, index) => {
            this[`item_${index}`] = React.createRef();
        });
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    openDropdown() {
        this.setState({
            isDropdownOpen: true
        });
    }

    closeDropdown() {
        this.setState({
            isDropdownOpen: false
        });
    }

    handleClickOutside(e) {
        const { id } = this.state;
        const targetClassName = e.target.className;
        if (!targetClassName ||
            (
              !!targetClassName &&
              typeof targetClassName === "string" &&
              !targetClassName.includes(`dropdown-overlay-${id}`) &&
              !targetClassName.includes("selector") &&
              !targetClassName.includes("selections") &&
              !targetClassName.includes("manual-input") &&
              !targetClassName.includes("manual-input-field")
            )
        ) {
            this.setState({
                isDropdownOpen: false
            });
        }
    }

    toggleDropdown() {
        this.setState({
            isDropdownOpen: !this.state.isDropdownOpen
        });
    }

    isItemSelected() {
        return this.getSelectedItem()[this.props.itemIdProp || 'id'] !== undefined;
    }

    hasItem(item) {
        const { items } = this.props;
        return !!(items.length) && !!items[0][item];
    }

    getSelectedItem() {
        const items = this.getItems();

        const hasAvatar = !!compact(items.map(({avatar}) => avatar)).length,
            hasFirstLine = !!compact(items.map(({firstLine}) => firstLine)).length,
            hasSecondLine = !!compact(items.map(({secondLine}) => secondLine)).length;

        const placeholderItem = {
            avatar: hasAvatar ? null : undefined,
            firstLine: hasFirstLine ? null : undefined,
            secondLine: hasSecondLine ? null : undefined
        };

        if (!(items.length)) {
            ["avatar", "firstLine", "secondLine"].forEach((item) => {
                if (this.hasItem(item)) {
                    placeholderItem[item] = null;
                }
            });
        }

        return items.find((item) => item.selected) || placeholderItem;
    }

    getLabel() {
        const { label, required, persistLabel, items } = this.props;
        return (persistLabel ? label : label || (items.find(item => item.selected) || {}).label || "") + ( required ? "*" : "");
    }

    getItems() {
        const { dropdownType, hasUntimedOption, items, value, onSelect } = this.props;
        const { customValues } = this.state;

        const isPreparationTime = dropdownType === eDropdownTypes.PREPARATION_TIME,
            isAnswerTime = dropdownType === eDropdownTypes.ANSWER_TIME,
            isNumberOfAttempts = dropdownType === eDropdownTypes.NUMBER_OF_ATTEMPTS,
            isSMSInterval = dropdownType === eDropdownTypes.SMS_INTERVAL;

        const defaultItems =
            isPreparationTime ? PREPARATION_TIMES :
            isAnswerTime ? hasUntimedOption ? ANSWER_TIMES_WITH_UNTIMED_OPTION : ANSWER_TIMES :
            isNumberOfAttempts ? NUMBER_OF_ATTEMPTS :
            isSMSInterval ? SMS_INTERVALS :
            items;

        let isSelectedValueInList = false;

        const returnValues = (uniq(compact([...defaultItems, ...customValues]))).map((item, index) => {
            let itemName, itemValue, isItemDefault;
            if (["string", "number"].includes(typeof item)) {
                itemName = item;
                itemValue = item;
                isItemDefault = false;
            } else {
                const {name, value, is_default} = item;
                itemName = name;
                itemValue = value;
                isItemDefault = is_default;
            }

            if (itemValue === value) {
                isSelectedValueInList = true;
            }

            const returnValue = {
                id: index,
                value: itemValue,
                selected: itemValue === value,
                onClick: () => onSelect(dropdownType, itemValue)
            };

            if (dropdownType === eDropdownTypes.BRANDING) {
                returnValue.firstLine = <span className={`branding-name`}>{isItemDefault ? IconStar : ''}{itemName}</span>;
            } else {
                returnValue.firstLine = (`${itemName || ''}`).trim();
            }

            return returnValue;
        });

        if (value !== undefined && value !== null && !isSelectedValueInList) {
            returnValues.push({
                id: `new-${value}`,
                value,
                firstLine: isPreparationTime || isAnswerTime ? fancyTimeFormat(value) : value,
                selected: true,
                onClick: () => onSelect(dropdownType, value)
            });
        }

        return objectToArray(eDropdownTypes).includes(dropdownType) ?
            sortBy(returnValues, ['value']) :
            items;
    }

    updateNewValue(e) {
        const { manualInputValidation } = this.props;
        const { isManualInputError } = this.state;
        const { target : { value } } = e;

        const updatedState = { newValue: value };

        if (isManualInputError) {
            const isValidValue = !!manualInputValidation ? manualInputValidation(value) : true;
            updatedState.isManualInputError = !isValidValue;
        }

        this.setState(updatedState);
    }

    addNewDropdownValue(e) {
        const { dropdownType, onSelect } = this.props;
        const { customValues } = this.state;
        const { key, target: { value } } = e;
        if (key === 'Enter') {
            const isPreparationTime = dropdownType === eDropdownTypes.PREPARATION_TIME,
                isAnswerTime = dropdownType === eDropdownTypes.ANSWER_TIME;
            const isValidValue = isPreparationTime || isAnswerTime ? isValidDuration(value) : true;
            if (isValidValue) {
                let newValue = null;
                if (isPreparationTime || isAnswerTime) {
                    const valueAsSeconds = durationAsSeconds(value);
                    onSelect(dropdownType, valueAsSeconds);

                    // check if already exists
                    let currentSelection = isPreparationTime ? [...PREPARATION_TIMES] : [...ANSWER_TIMES];
                    currentSelection = [...currentSelection, ...customValues];

                    const currentValues = currentSelection.map(({value}) => value);
                    if (!currentValues.includes(valueAsSeconds)) {
                        newValue = {name: value, value: valueAsSeconds};
                    }
                } else {
                    newValue = value;
                    onSelect(dropdownType, value);
                }

                let newValues = [...customValues];
                if (newValue) {
                    newValues = [...customValues, newValue];
                }

                this.setState({
                    newValue: '',
                    customValues: newValues,
                    isManualInputError: false,
                    isDropdownOpen: false
                });
            } else {
                this.setState({
                    isManualInputError: true
                });
            }
        }
    }

    render() {
        const { styles, classes, disabled, showArrow, showUnderline, persistLabel, tooltipIcon, tooltipDescription, manualInput, manualInputPlaceholder, icon, itemSettings, itemIdProp = 'id', autoToggleOpen, autoToggleClose } = this.props;
        const items = this.getItems();
        const { style, positioning } = itemSettings;
        const { id: stateId, isDropdownOpen, newValue, isManualInputError } = this.state;
        return (
            <div className={`react-dropdown ${styles} ${classes} ${showUnderline ? 'underlined' : ''} ${disabled ? 'disabled' : ''} ${isDropdownOpen ? 'selecting' : ''}`}
                 onMouseEnter={!!autoToggleOpen ? this.openDropdown : null}
                 onMouseLeave={!!autoToggleClose ? this.closeDropdown : null}
            >
                <DropdownSelector
                    id={`${stateId}_button`}
                    type={eDropdownItemType.SELECTOR}
                    disabled={!(items.length) || disabled}
                    showArrow={showArrow}
                    persistLabel={persistLabel}
                    isDropdownOpen={isDropdownOpen}
                    icon={icon}
                    label={this.getLabel()}
                    tooltipIcon={tooltipIcon}
                    tooltipDescription={tooltipDescription}
                    item={this.getSelectedItem()}
                    selected={this.isItemSelected()}
                    onClick={() => !!(items.length) ? this.toggleDropdown() : null}
                />
                {items && (
                    <ul
                        id={`dropdown_${stateId}_list`}
                        aria-labelledby={`dropdown_${stateId}`}
                        //aria-activedescendant=""
                        role="listbox"
                        style={style}
                        className={`
                            selections
                            ${isDropdownOpen ? 'open' : ''}
                            ${positioning}
                        `}
                    >
                        {manualInput && (
                            <div className={`manual-input`}>
                                <CustomTextField
                                    classes={`manual-input-field`}
                                    value={newValue}
                                    placeholder={manualInputPlaceholder}
                                    onChange={this.updateNewValue}
                                    isError={isManualInputError}
                                    helperText={isManualInputError ? "Please enter a valid value" : ""}
                                    onKeyDown={this.addNewDropdownValue}
                                />
                            </div>
                        )}
                        {
                            items.map(({[itemIdProp]: itemId, avatar, isGroupName, firstLine, secondLine, disabled, selected, onClick, toggleDropdownOnClick = true, link}, _index) => {
                                return (
                                    <DropdownSelector
                                        id={`${stateId}_${itemId}`}
                                        key={itemId}
                                        type={eDropdownItemType.SELECTION}
                                        label={this.getLabel()}
                                        item={{avatar, isGroupName, firstLine, secondLine}}
                                        disabled={disabled}
                                        selected={selected}
                                        link={link}
                                        onClick={() => {!!onClick && onClick(); !!toggleDropdownOnClick && this.toggleDropdown();}}
                                    />
                                );
                            })
                        }
                    </ul>
                )}
            </div>
        );
    }
}

Dropdown.propTypes = {
    styles: PropTypes.string,
    classes: PropTypes.string, // "pop-up", "no-border", "floating-label" (w or w/o "fixed-label")
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    icon: PropTypes.object,
    label: PropTypes.string,
    tooltipIcon: PropTypes.object,
    tooltipDescription: PropTypes.object,
    showArrow: PropTypes.bool,
    showUnderline: PropTypes.bool,
    persistLabel: PropTypes.bool,
    manualInput: PropTypes.bool,
    manualInputPlaceholder: PropTypes.string,
    onItemAdd: PropTypes.func,
    dropdownType: PropTypes.oneOf(objectToArray(eDropdownTypes)),
    hasUntimedOption: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onSelect: PropTypes.func,
    items: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                avatar: PropTypes.shape({
                    id: PropTypes.number,
                    first_name: PropTypes.string,
                    last_name: PropTypes.string,
                    picture_url: PropTypes.string
                }),
                isGroupName: PropTypes.bool,
                firstLine: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
                secondLine: PropTypes.string,
                disabled: PropTypes.bool,
                selected: PropTypes.bool,
                onClick: PropTypes.func,
                toggleDropdownOnClick: PropTypes.bool
            })
        ])
    ),
    itemSettings: PropTypes.shape({
        style: PropTypes.object,
        positioning: PropTypes.oneOf(objectToArray(eDropDownPosition))
    }),
    autoToggleOpen: PropTypes.bool,
    autoToggleClose: PropTypes.bool
};

Dropdown.defaultProps = {
    styles: "",
    classes: "",
    required: false,
    disabled: false,
    icon: null,
    label: "",
    tooltipIcon: null,
    tooltipDescription: null,
    showArrow: true,
    showUnderline: true,
    persistLabel: false,
    manualInput: false,
    manualInputPlaceholder: "Custom...",
    onItemAdd: null,
    dropdownType: null,
    hasUntimedOption: false,
    value: null,
    onSelect: null,
    items: [],
    itemSettings: {
        style: {},
        positioning: null
    },
    autoToggleOpen: false,
    autoToggleClose: false
};

export default Dropdown;
