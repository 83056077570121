import React from "react";
import {capitalize} from "lodash";
import Container from "../../../components/Container";
import {
    eStatusMessageStyle,
    isAlcamiStaff,
    getUserSetting,
    eUserSettings,
    eTemplatesType
} from "../../../utils";
import StatusMessage from "../../../components/StatusMessage";
import ActionDropdown from "../../../components/ActionDropdown";
import PropTypes from "prop-types";
import SMSTemplateDetails from "../sms/details";
import {IconPlus} from "../../../components/Icons";
import Templates from "../../../components/Templates";

import './style.scss';

class SMSTemplateRow extends React.Component {
    constructor(props) {
        super(props);

        this.getActionItems = this.getActionItems.bind(this);
    }

    getActionItems(sms_template_id) {
        const { onEdit, onDelete } = this.props;
        const actionItems = [];

        if (!!onEdit) {
            actionItems.push({
                id: `edit-${sms_template_id}`,
                firstLine: "Edit",
                onClick: onEdit
            });
        }

        if (!!onDelete) {
            actionItems.push({
                id: "delete",
                firstLine: "Delete",
                onClick: onDelete
            });
        }

        return actionItems;
    }

    render() {
        const { template = {} } = this.props;
        const {
            sms_template_id,
            sms_template_type,
            content
        } = template;

        const sms_template_name = `${capitalize(sms_template_type)} SMS Template #${sms_template_id}`;

        return (
            <div className={`sms-template-row`}>
                <div className={`template-type`}>{capitalize(sms_template_type)}</div>
                <div className={`template-name`}>{sms_template_name}</div>
                <div className={`template-content`}>{content}</div>
                <div className={`sms-template-actions`}>
                    <ActionDropdown items={this.getActionItems(sms_template_id)} hasBorder={true} />
                </div>
            </div>
        );
    }
}

SMSTemplateRow.propTypes = {
    template: PropTypes.object,
    onEdit: PropTypes.func
};


class SMSTemplates extends Templates {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            items: [],
            selectedTemplate: null,
            confirmDialogResponseProps: null,
            statusMessage: {
                type: null,
                message: null,
                reset: null
            }
        };
    }

    async componentDidMount() {
        await this.retrieveTemplates(eTemplatesType.SMS);
    }

    render() {
        const {
            loading,
            confirmDialogResponseProps,
            statusMessage: { type, message, reset },
            items,
            selectedTemplate
        } = this.state;

        const shouldShowComponent = isAlcamiStaff() && getUserSetting(eUserSettings.TEMPLATES_SMS);

        return (
            <Container
                loading={loading}
                error={!shouldShowComponent}
                confirmDialogResponseProps={confirmDialogResponseProps}
            >
                <StatusMessage
                    type={type}
                    style={eStatusMessageStyle.BAR}
                    message={message}
                    duration={3000}
                    showCloseIcon={true}
                    reset={reset}
                />

                {!!items.length && (
                    <div className={`sms-templates`}>
                        <div className={`sms-templates-header header-row`}>
                            <div className={`header-column type`}>Type</div>
                            <div className={`header-column name`}>Name</div>
                            <div className={`header-column content`}>Content</div>
                            <div className={'header-column action'}>
                                <div className={`new-button`} onClick={() => this.onNew(eTemplatesType.SMS)}>{IconPlus}</div>
                            </div>
                        </div>
                        {items.map((template) => {
                            return (
                                <SMSTemplateRow
                                    template={template}
                                    onEdit={() => this.toggleTemplateDetails(template)}
                                    onDelete={() => this.deleteTemplateConfirmation(eTemplatesType.SMS, template)}
                                />
                            );
                        })}
                    </div>
                )}

                {!items.length && (
                    <div className={`no-items`}>
                        There are no SMS templates.<br /><br />
                        <div className={`react-button create-button`}
                             onClick={() => this.onNew(eTemplatesType.SMS)}
                        >
                            Create new
                        </div>
                    </div>
                )}

                {!!selectedTemplate && (
                    <SMSTemplateDetails
                        templates={items}
                        template={selectedTemplate}
                        onSubmit={(response, is_new_record) => this.onTemplateSubmit(eTemplatesType.SMS, response, is_new_record)}
                        onClose={() => this.toggleTemplateDetails(null)}
                    />
                )}
            </Container>
        );
    }
}

export default SMSTemplates;