import React from "react";
import * as PropTypes from 'prop-types';
import {objectToArray} from "../../../utils";

import './style.scss';


export const eToggleSize = {
    X_SMALL: "xsmall",
    SMALL: "small"
};

class CustomToggleField extends React.Component {
    constructor(props) {
        super(props);

        this.onToggleChange = this.onToggleChange.bind(this);
    }

    onToggleChange(e) {
        const { onChange } = this.props;

        if (!onChange) {
            return false;
        }

        const { target: { id, checked } } = e;

        onChange({
            target: {
                id,
                value: checked
            }
        });
    }

    render() {
        const { classes = '', size = eToggleSize.SMALL, id, label, checked, isDisabled = false } = this.props;
        return (
            <div className={`customToggleField ${classes}`}>
                <input type="checkbox" id={id} className={`switch ${size} ${isDisabled ? 'disabled' : ''}`} checked={checked} onChange={this.onToggleChange} />
                <label htmlFor={id}>{label}</label>
            </div>
        );
    }
}

CustomToggleField.propTypes = {
    classes: PropTypes.string,
    size: PropTypes.oneOf(objectToArray(eToggleSize)),
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func
};

CustomToggleField.defaultProps = {
    classes: "",
    size: eToggleSize.SMALL,
    checked: false,
    isDisabled: false,
    onChange: null
};

export default CustomToggleField;

/*
// placeholder for tri-state switch
<div class="wrapper">
  <label for="yes_radio" id="yes-lbl">si</label><input type="radio" value="" name="choice_radio" id="yes_radio">

  <label for="maybe_radio" id="maybe-lbl">?</label><input type="radio" value="" name="choice_radio" id="maybe_radio" checked="checked">

  <label for="no_radio" id="no-lbl">no</label><input type="radio" value="" name="choice_radio" id="no_radio">

  <div class="toggle"></div>
</div>
 */
