import React from "react";
import * as PropTypes from "prop-types";
import { objectToArray } from "../../../utils";

import './style.scss';
import Tooltip from "../../Tooltip";


export const eFileUploadType = {
    ALL: "*",
    IMAGE: "image/*",
};

export const eTooltipType = {
    LOGO: "logo",
    IMAGE: "image"
};

const logoTooltip = (
    <div>
        <div className={`list-header`}>Logo Requirements</div>
        <div><span className={`list-item`}>-</span>Max. file size is 1MB</div>
        <div><span className={`list-item`}>-</span>Min. width is 400px</div>
        <div><span className={`list-item`}>-</span>Transparent background</div>
    </div>
);

const imageTooltip = (
    <div>
        <div className={`list-header`}>Image Requirements</div>
        <div><span className={`list-item`}>-</span>Max. file size is 1MB</div>
        <div><span className={`list-item`}>-</span>Square or landscape</div>
        <div><span className={`list-item`}>-</span>Min. height is 750px</div>
        <div><span className={`list-item`}>-</span>Doesn't include any text or graphics</div>
    </div>
);

const CustomFileUpload = ({id, classes, accept, preview, required = false, label = "", tooltipType, onChange}) => {
    let description = '';

    if (tooltipType === eTooltipType.LOGO) {
        description = logoTooltip;
    }
    if (tooltipType === eTooltipType.IMAGE) {
        description = imageTooltip;
    }

    return (
        <div className={`customFileUpload ${classes || ''}`}>
            <div className={`file-title`}>
                {label}{required ? <sup>*</sup> : ''}
                {!!tooltipType && (
                    <Tooltip
                        classes="info-tooltip"
                        positioning="onRight"
                        item={<span className={`info-tooltip-icon`}>?</span>}
                        description={description}
                    />
                )}
            </div>
            {!!preview && (
                <div className={`preview`}>
                    {accept === eFileUploadType.IMAGE && (
                        <img alt="Preview" src={preview} />
                    )}
                </div>
            )}
            <div className={`file-upload-button`}>
                <div className={`react-button upload-button outline`}>Upload</div>
                <input
                    key={id}
                    id={id}
                    type="file"
                    accept={accept}
                    onClick={(e) => e.target.value = null}
                    onChange={onChange}
                />
            </div>
        </div>
    );
};

CustomFileUpload.propTypes = {
    id: PropTypes.string,
    classes: PropTypes.string,
    accept: PropTypes.oneOf(objectToArray(eFileUploadType)),
    preview: PropTypes.string,
    required: PropTypes.bool,
    label: PropTypes.string,
    tooltipType: PropTypes.oneOf(objectToArray(eTooltipType)),
    onChange: PropTypes.func
};

CustomFileUpload.defaultProps = {
    id: "",
    classes: null,
    accept: eFileUploadType.FILE,
    preview: "",
    required: false,
    label: "",
    tooltipType: null,
    onChange: null
};

export default CustomFileUpload;
