import React from 'react';
import * as PropTypes from 'prop-types';
import { IconTickWithCircle } from "../Icons";
import { resetPassword } from "../../api";
import StatusMessage from "../../components/StatusMessage";
import {getQueryParam, objectToArray} from "../../utils";
import PasswordStrengthMeter from "../PasswordStrengthMeter";
import { get } from "lodash";
import CustomTextField from "../Form/CustomTextField";

import './style.scss';


const eResetPasswordErrorTypes = {
    TOKEN_NOT_FOUND: "token not found",
    PASSWORD_REJECTED: "password rejected"
};

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: getQueryParam("token"),
            password: "",
            confirmPassword: "",
            passwordScore: 0,
            showConfirmPasswordMessage: false,
            isPasswordSuccessfullyReset: false,
            errorMessage: ""
        };

        this.onInputChange = this.onInputChange.bind(this);
        this.isTokenValid = this.isTokenValid.bind(this);
        this.getConfirmPasswordClassNames = this.getConfirmPasswordClassNames.bind(this);
        this.isPasswordMatching = this.isPasswordMatching.bind(this);
        this.setConfirmPasswordMessageDisplay = this.setConfirmPasswordMessageDisplay.bind(this);
        this.isConfirmPasswordValidating = this.isConfirmPasswordValidating.bind(this);
        this.isPasswordSubmittable = this.isPasswordSubmittable.bind(this);
        this.passwordScoreListener = this.passwordScoreListener.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.resetErrorMessage = this.resetErrorMessage.bind(this);
    }

    onInputChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    isTokenValid() {
        const { token } = this.state;
        return !!token;
    }

    getConfirmPasswordClassNames() {
        let returnValue = 'confirm-password';

        if (this.isPasswordMatching()) {
            returnValue += ' matching';
        } else if (this.isConfirmPasswordValidating()) {
            returnValue += ' non-matching';
        }

        return returnValue;
    }

    isPasswordMatching() {
        const { password, confirmPassword } = this.state;
        return password.length !== 0 &&
            password === confirmPassword;
    }

    isConfirmPasswordValidating() {
        const { password, confirmPassword } = this.state;
        const activeField = document.activeElement.id;
        return password.length !== 0 &&
            confirmPassword.length !== 0 &&
            activeField !== "confirmPassword";
    }

    setConfirmPasswordMessageDisplay(value) {
        this.setState({
            showConfirmPasswordMessage: value
        });
    }

    isPasswordSubmittable() {
        const { passwordScore } = this.state;
        return this.isPasswordMatching() &&
            (passwordScore >= 3);
    }

    passwordScoreListener(value) {
        this.setState({
            passwordScore: value
        });
    }

    async onSubmit() {
        this.resetErrorMessage();
        const { token, password } = this.state;
        const response = await resetPassword({token, password});
        if (response.error) {
            this.setState({
                errorMessage: get(response, "error.response.data.error", "error")
            });
        } else {
            this.setState({
                isPasswordSuccessfullyReset: true
            });
        }
    }

    resetErrorMessage() {
        this.setState({
            errorMessage: ""
        });
    }

    render() {
        const { backToLoginPage, backToForgetPasswordPage } = this.props;
        const { errorMessage, isPasswordSuccessfullyReset, password, confirmPassword, showConfirmPasswordMessage } = this.state;
        return (
            <div className="reset-password">
                {!isPasswordSuccessfullyReset && (
                    <React.Fragment>
                        {!this.isTokenValid() && (
                            <div className="reset-password-error-message">
                                Your password reset link is not valid.<br />
                                Please use the latest link emailed to you,<br />
                                or <button className={`react-button text-link`} onClick={backToForgetPasswordPage}>click here to receive a new link</button>.
                            </div>
                        )}

                        {this.isTokenValid() && (
                            <React.Fragment>
                                <p className="reset-password-message">Please enter your new password</p>

                                <CustomTextField
                                    type="password"
                                    id={`password`}
                                    classes={`new-password`}
                                    label={`New Password`}
                                    required={true}
                                    value={password}
                                    autoComplete={`new-password`}
                                    onChange={this.onInputChange}
                                />
                                <PasswordStrengthMeter password={password} listener={this.passwordScoreListener} />
                                <CustomTextField
                                    type="password"
                                    id={`confirmPassword`}
                                    classes={this.getConfirmPasswordClassNames()}
                                    label={`Confirm Password`}
                                    required={true}
                                    value={confirmPassword}
                                    autoComplete={`new-password`}
                                    onChange={this.onInputChange}
                                    onFocus={() => this.setConfirmPasswordMessageDisplay(false)}
                                    onBlur={() => this.setConfirmPasswordMessageDisplay(true)}
                                />

                                {showConfirmPasswordMessage && this.isConfirmPasswordValidating() && (
                                    <div className="confirm-password-validation">
                                        {this.isPasswordMatching() && (
                                            <span className="matching">&nbsp;</span>
                                        )}
                                        {!this.isPasswordMatching() && (
                                            <span className="non-matching">Passwords do not match.</span>
                                        )}
                                    </div>
                                )}

                                <button
                                    className={`reset-password-button react-button ${this.isPasswordSubmittable() ? 'active ripple' : 'disabled'}`}
                                    onClick={() => this.isPasswordSubmittable() && this.onSubmit()}
                                >
                                Reset password
                                </button>
                            </React.Fragment>
                        )}

                        {errorMessage && (
                            <StatusMessage>
                                {errorMessage === eResetPasswordErrorTypes.TOKEN_NOT_FOUND && (
                                    <span className="warning">
                                        Your password reset link is incorrect or has expired.
                                        Please use the latest link emailed to you,
                                        or <button className='link' onClick={backToForgetPasswordPage}>click here to receive a new link</button>.
                                    </span>
                                )}
                                {errorMessage === eResetPasswordErrorTypes.PASSWORD_REJECTED && (
                                    <span className="warning">
                                        Your password is not secure enough.
                                        Please choose a stronger password and try again.
                                    </span>
                                )}
                                {!objectToArray(eResetPasswordErrorTypes).includes(errorMessage) && (
                                    <span className="warning">
                                        Sorry, we are unable to reset your password. Please try again shortly.
                                    </span>
                                )}
                            </StatusMessage>
                        )}

                        <span className="reset-password-link">
                            Remember your password? <button className='link' onClick={backToLoginPage}>Sign in</button>
                        </span>
                    </React.Fragment>
                )}
                {!!isPasswordSuccessfullyReset && (
                    <React.Fragment>
                        <p className="reset-password-confirm">
                            {IconTickWithCircle}
                            <span>Your password has been reset.</span>
                        </p>
                        <span className="reset-password-link"><button className='link' onClick={backToLoginPage}>Click here to login.</button></span>

                    </React.Fragment>
                )}
            </div>
        );
    }
}

ResetPassword.propTypes = {
    backToLoginPage: PropTypes.func,
    backToForgetPasswordPage: PropTypes.func
};

ResetPassword.defaultProps = {
    backToLoginPage: null,
    backToForgetPasswordPage: null
};

export default ResetPassword;
