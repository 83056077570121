import React from 'react';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import './style.scss';


const standard_color = '#7d8b92';
const primary_color = '#00A1DF';
const error_color = '#f44336';

const pickerTheme = createTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: primary_color
            }
        },
        MuiFormControl: {
            root: {
                width: '100%',
                backgroundColor: 'transparent',
                padding: 0,
                textTransform: 'capitalize'
            }
        },
        MuiFormControlLabel: {
            root: {
                width: '100%',
                height: '2rem',
                zIndex: '1',
            }
        },
        MuiFormLabel: {
            root: {
                fontFamily: '"jaf-bernino-sans", sans-serif',
                color: standard_color,
                paddingLeft: '6px',
                "&$focused": {
                    color: primary_color
                }
            }
        },
        MuiFormHelperText: {
            root: {
                "&$error": {
                    marginTop: 2,
                    marginLeft: 2,
                    backgroundColor: 'transparent',
                    textTransform: 'none'
                }
            }
        },
        MuiInputLabel: {
            root: {
                // zIndex: '1',
            },
            shrink: {
                paddingLeft: 0,
                paddingTop: 5,
            }
        },
        MuiPickersDay: {
            root: {
                "&$selected": {
                    backgroundColor: primary_color
                }
            },
            daySelected: {
                backgroundColor: primary_color,
                '&:hover': {
                    backgroundColor: primary_color
                }
            }
        },
        MuiButton: {
            textPrimary: {
                color: primary_color
            }
        },
        MuiInput: {
            root: {
                borderBottomColor: standard_color,
                "&$focused": {
                    borderBottomColor: primary_color
                },
                "&$error": {
                    color: error_color
                }
            },
            input: {
                'input[type=text]&': {
                    marginTop: -14,
                    marginBottom: 0,
                    paddingTop: '22px',
                    paddingRight: '22px',
                    paddingLeft: '6px',
                    paddingBottom: '5px',
                    fontSize: 16,
                    borderBottomColor: 'transparent',
                    backgroundColor: 'transparent',
                    borderRadius: 0,
                    '&:hover': {
                        borderBottomColor: standard_color
                    },
                    '&:focus': {
                        borderBottomColor: primary_color
                    }
                }
            },
            formControl: {
                'label + &': {
                    marginTop: 16
                }
            },
            underline: {
                '&:before': {
                    display: 'none'
                },
                '&:after': {
                    display: 'none'
                }
            }
        },
        MuiSelect: {
            select: {
                fontWeight: 400
            }
        }
    }
});

const Picker = ({children}) => (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={pickerTheme}>
            {children}
        </ThemeProvider>
    </MuiPickersUtilsProvider>
);

export default Picker;
