import React from "react";
import * as PropTypes from "prop-types";
import CandidateDetails from "./CandidateDetails";
import CandidateResponses from "./CandidateResponses";
import CandidateLiveInterview from "./CandidateLiveInterview";
import Container from "../Container";
import StatusMessage from "../StatusMessage";
import {
    getCandidate,
    getCandidateWithJob
} from "../../api";
import {
    eInterviewStepType,
    eJobStageTypes,
    eStatusMessageStyle,
    eUserTypes,
    getQueryParam,
    objectToArray
} from "../../utils";
import ShareCandidateResponse from "../ShareCandidateResponse";
import StatusPanel from "./StatusPanel";
import CandidatesPanel from "./CandidatesPanel";
import CandidateInterview from "../CandidateInterview";

import './style.scss';

class CandidateProfileResponsesOnly extends CandidateInterview {
    constructor(props) {
        super(props);

        this.loadData = this.loadData.bind(this);
        this.prepareInformation = this.prepareInformation.bind(this);

        this.state = {
            token: getQueryParam("token"),
            loading: true,
            evaluations: [],

            // assessment
            combined_live_interview_notes: [],

            statusMessage: {
                type: null,
                message: null,
                reset: null
            },

            isError: false,
            isCandidateSharingPanelShown: false,
            confirmDialogResponseProps: null,
            confirmDialogProps: null
        };
    }

    async loadData() {
        const { token } = this.state;
        const { is_manager } = this.props;

        if (is_manager || !token) {
            await this.retrieveJobSummary();
        }

        await this.prepareInformation();
    }

    async prepareInformation(loading = true) {
        this.setState({loading, confirmDialogProps: null});

        const { token } = this.state;

        const {
            is_manager,
            job_id,
            job_stage_id,
            job_stage_type,
            candidate_id
        } = this.props;

        let candidate;

        if (is_manager || !token) {
            candidate = await getCandidate(job_id, job_stage_type, job_stage_id, candidate_id);
        } else {
            candidate = await getCandidateWithJob(token, job_id, job_stage_type, job_stage_id, candidate_id);
        }

        if (!candidate.candidate_id) {
            this.setState({
                loading: false,
                isError: true,
                confirmDialogProps: {
                    title: "Page Not Found",
                    message: "The page you are trying to access cannot be found. Please check your URL and try again."
                }
            });
            return false;
        }

        const updatedState = {
            candidate,
            loading: false
        };

        if (candidate.job) {
            updatedState.job = candidate.job;
        }

        this.setState(updatedState);
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        const { candidate_id: previousCandidateId } = prevProps;
        const { candidate_id } = this.props;

        if (candidate_id !== previousCandidateId) {
            this.prepareInformation();
        }
    }

    render() {
        const {
            loading,
            job = {},
            candidate = {},

            statusMessage: { type, message, reset },

            isError,
            isCandidateSharingPanelShown,
            confirmDialogResponseProps,
            confirmDialogProps
        } = this.state;

        const { title, location } = job;

        const {
            staff_id,
            is_manager,
            job_stage_type,
            job_stage_id,
            candidate_id,
            integrationProvider
        } = this.props;

        const isOneWayStage = job_stage_type === eJobStageTypes.ONE_WAY,
            isLiveInterviewStage = job_stage_type === eJobStageTypes.LIVE;

        const { first_name, last_name, picture_url, is_completed, completed_at, candidate_responses } = candidate;

        const interviewSteps = candidate?.interview_steps; // || job_interview_steps;
        const questions = interviewSteps?.filter(({interview_step_type}) => interview_step_type === eInterviewStepType.QUESTION);

        const showCompletedTimestamp = this.shallShowCompletedTimestamp();
        const hasStatusPanel = this.hasStatusPanel();

        return (
            <Container
                loading={loading}
                confirmDialogProps={confirmDialogProps}
                confirmDialogResponseProps={confirmDialogResponseProps}
            >
                {!isError && (
                    <React.Fragment>
                        <StatusMessage
                            type={type}
                            style={eStatusMessageStyle.BAR}
                            message={message}
                            duration={5000}
                            reset={reset}
                        />

                        <div className={`
                            candidate-profile-container
                            ${!staff_id ? 'full-page' : ''}
                            ${hasStatusPanel ? "has-status-panel" : ""}
                            ${integrationProvider ? "integration" : ""}
                        `}>
                            <CandidatesPanel
                                title={title}
                                location={location}
                            />

                            {hasStatusPanel && (
                                <StatusPanel
                                    is_completed={is_completed}
                                    showCompletedTimestamp={showCompletedTimestamp}
                                    completed_at={completed_at}
                                />
                            )}

                            <div className={`candidate-profile ${integrationProvider ? "integration" : ""}`}>
                                <CandidateDetails
                                    candidate={{id: candidate_id, first_name, last_name, picture_url}}
                                    onShareCandidate={is_manager ? this.toggleCandidateSharing : null}
                                />

                                <div className={`evaluations-panel`}>
                                    {isOneWayStage && (
                                        <CandidateResponses
                                            view_type={eUserTypes.MANAGER}
                                            questions={questions}
                                            responses={candidate_responses}
                                            // no evaluators and evaluations passed here
                                        />
                                    )}
                                    {isLiveInterviewStage && (
                                        <CandidateLiveInterview />
                                    )}
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}
                {isCandidateSharingPanelShown && (
                    <ShareCandidateResponse
                        candidates={[candidate]}
                        job_id={job.job_id}
                        job_stage_type={job_stage_type}
                        job_stage_id={job_stage_id}
                        onUpdate={this.showCompletedDialog}
                        onClose={this.toggleCandidateSharing}
                        closeOnOverlayClick={false}
                    />
                )}
            </Container>
        )
    }
}

CandidateProfileResponsesOnly.propTypes = {
    staff_id: PropTypes.number,
    is_manager: PropTypes.bool,
    integrationProvider: PropTypes.string,
    job_id: PropTypes.number.isRequired,
    job_stage_type: PropTypes.oneOf(objectToArray(eJobStageTypes)).isRequired,
    job_stage_id: PropTypes.number.isRequired,
    candidate_id: PropTypes.number.isRequired,
    urlHasTrailingEvaluate: PropTypes.bool
};

CandidateProfileResponsesOnly.defaultProps = {
    is_manager: false,
    integrationProvider: "",
    urlHasTrailingEvaluate: false
};

export default CandidateProfileResponsesOnly;
