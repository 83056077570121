import React from 'react';
import { isEqual } from "lodash";
import * as PropTypes from 'prop-types';
import { editCandidate, resendInvites } from '../../api/';
import CustomModal from '../CustomModal';
import {
    eCandidateActionTypes,
    eConfirmDialogResponseType,
    eJobStageTypes,
    eResponseStatus,
    isValidEmail,
    objectToArray
} from "../../utils";
import CustomTextField from "../Form/CustomTextField";
import CustomCheckbox, {eCustomCheckboxSize} from "../Form/CustomCheckbox";
import ConfirmDialogResponse from "../ConfirmDialog/Response";

import './style.scss';


class CandidateEdit extends React.Component {
    constructor(props) {
        super(props);

        this.onInputChange = this.onInputChange.bind(this);

        this.hasDataChanged = this.hasDataChanged.bind(this);
        this.isFormValid = this.isFormValid.bind(this);
        this.toggleResendEmailInvite = this.toggleResendEmailInvite.bind(this);

        this.onSubmitButtonClick = this.onSubmitButtonClick.bind(this);
        this.onClose = this.onClose.bind(this);

        const { candidate } = props;

        this.state = {
            original_candidate: candidate,
            candidate: candidate,
            resend_email_invite: false,
            status: "", // "", "sending", "success", "fail"
            confirmDialogResponseProps: null,
            confirmDialogProps: null
        };
    }

    onInputChange(e) {
        const {target: {id, value}} = e;
        this.setState({
            candidate: {
                ...this.state.candidate,
                [id]: value
            }
        });
    }

    isEmailResendRequired() {
        const { actionType } = this.props;
        return actionType === eCandidateActionTypes.RESEND || this.hasDeadlineChanged();
    }

    async onSubmitButtonClick() {
        if (
            this.state.status === "processing" ||
            this.state.status === "success"
        ) {
            return;
        }

        const { original_candidate, candidate, resend_email_invite } = this.state;
        const { first_name, last_name, email, phone, candidate_id } = candidate;
        const {
            job_id,
            job_stage_type,
            job_stage_id,
            onUpdate,
            onClose
        } = this.props;

        const payload = {first_name, last_name, email, phone: phone || null};

        const response = await editCandidate(job_id, job_stage_type, job_stage_id, candidate_id, payload);

        if (response.error) {
            this.setState({
                confirmDialogResponseProps: {
                    type: eConfirmDialogResponseType.ERROR,
                    sentence: <span>Cannot update candidate record.<br/>Please try again.</span>,
                    onClose: () => this.setState({confirmDialogResponseProps: null})
                }
            });

            return false;
        }

        const emailResendRequired = original_candidate.email !== email && resend_email_invite;

        const emailResendStatus = emailResendRequired ? await resendInvites(job_id, job_stage_type, job_stage_id, [candidate_id]) : true;

        const updatedData = {job_stage_id, candidates: [{candidate_id, ...payload}]};

        const { status, timestamp } = emailResendStatus;
        if (status === eResponseStatus.SUCCESS) {
            updatedData.timestamp = timestamp;
        }

        onUpdate?.(updatedData);

        this.setState({
            confirmDialogResponseProps: {
                type: eConfirmDialogResponseType.SUCCESS,
                sentence: `Candidate record updated.`,
                onClose
            }
        });
    }

    hasDataChanged() {
        const { original_candidate, candidate } = this.state;
        return !isEqual(original_candidate, candidate);
    }

    isFormValid() {
        const { candidate: { first_name, email } } = this.state;
        return !!first_name && !!email; //  && !!phone;
    }

    toggleResendEmailInvite() {
        this.setState({
            resend_email_invite: !this.state.resend_email_invite
        });
    }

    onClose() {
        const { onClose } = this.props;
        onClose?.();
    }

    render() {
        const {
            original_candidate,
            candidate: { first_name, last_name, email, phone },
            resend_email_invite,
            status,
            confirmDialogResponseProps
        } = this.state;

        const hasEmailAddressChanged = original_candidate.email !== email;

        return (
            <React.Fragment>
                {(!confirmDialogResponseProps || confirmDialogResponseProps?.type === eConfirmDialogResponseType.ERROR) && (
                    <CustomModal
                        title={`Edit Candidate Details`}
                        classes={`candidate-edit open`}
                        closeOnOverlayClick={false}
                        onClose={this.onClose}>

                        <div className={`item-notice`}>
                            <strong>Note:</strong> Please only change the email address field if the current email address is invalid
                            AND the email delivery has failed. When email was successfully sent, you should not change the email
                            address using the form below as it will create unintended consequences. Instead, please delete the current
                            candidate record and create a new candidate record with the correct email address.
                        </div>
                        <CustomTextField
                            id={`first_name`}
                            name={`first_name`}
                            required={true}
                            label={`First Name`}
                            value={first_name || ""}
                            isError={!first_name}
                            onChange={this.onInputChange}
                        />
                        <CustomTextField
                            id={`last_name`}
                            name={`last_name`}
                            label={`Last Name`}
                            value={last_name || ""}
                            onChange={this.onInputChange}
                        />
                        <CustomTextField
                            id={`email`}
                            name={`email`}
                            required={true}
                            // disabled={true} // email is a special ID field for backend
                            label={`Email`}
                            value={email || ""}
                            isError={!isValidEmail(email)}
                            onChange={this.onInputChange}
                        />
                        {hasEmailAddressChanged && isValidEmail(email) && (
                            <CustomCheckbox
                                size={eCustomCheckboxSize.SMALL}
                                key={`resend-email-invite-toggle`}
                                id={`resend-email-invite-toggle`}
                                label={`Resend email invite`}
                                checked={resend_email_invite}
                                onChange={this.toggleResendEmailInvite}
                            />
                        )}
                        <CustomTextField
                            id={`phone`}
                            name={`phone`}
                            required={false}
                            label={`Phone`}
                            value={phone || ""}
                            // isError={false}
                            onChange={this.onInputChange}
                        />

                        <div className={`actions`}>
                            <button
                                type="button"
                                className="react-button link"
                                onClick={this.onClose}
                            >
                                Cancel
                            </button>
                            <button
                                disabled={!this.hasDataChanged() || !this.isFormValid()}
                                type="submit"
                                className={`react-button ${status} ${!this.hasDataChanged() || !this.isFormValid() ? "disabled" : ''}`}
                                onClick={this.onSubmitButtonClick}
                            >
                                Update
                            </button>
                        </div>
                    </CustomModal>
                )}
                <ConfirmDialogResponse {...confirmDialogResponseProps} />
            </React.Fragment>
        );
    }
}

CandidateEdit.propTypes = {
    candidate: PropTypes.shape({
        candidate_id: PropTypes.number,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        email: PropTypes.string
    }),

    job_id: PropTypes.number,
    job_stage_type: PropTypes.oneOf(objectToArray(eJobStageTypes)),
    job_stage_id: PropTypes.number,

    onUpdate: PropTypes.func,
    onClose: PropTypes.func
};

CandidateEdit.defaultProps = {
    candidates: {
        id: null,
        first_name: "",
        last_name: "",
        email: "",
        phone: ""
    },
    job_id: null,
    job_stage_type: null,
    job_stage_id: null,

    onUpdate: null,
    onClose: null
};

export default CandidateEdit;
