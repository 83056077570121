import React from "react";
import * as PropTypes from "prop-types";

import './style.scss';


const CandidateFeedback = ({ feedback }) => (
    <div className={`candidate-feedback`}>
        <div className={`candidate-feedback-title`}>Candidate Feedback</div>
        <div className={`candidate-feedback-section`}>
            { feedback || <span className={`no-candidate-feedback`}>(No candidate feedback)</span>}
        </div>
    </div>
);

CandidateFeedback.propTypes = {
    feedback: PropTypes.string
};

CandidateFeedback.defaultProps = {
    feedback: null
};

export default CandidateFeedback;