import React from 'react';

import Container from '../Container';
import LabelValueTile from "../LabelValueTile";
import { LogoInsideSherpa, LogoJobAdder, LogoPageUp, LogoSAP, LogoSmartRecruiters } from "../Icons";

import {getIntegrations, createPendingIntegration, deleteIntegration} from '../../api/';

import './style.scss';


class Marketplace extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            integrations: [],
            loadedData: false,
            loadingData: false,
        };
    }

    componentDidMount() {
        const {loadingData, loadedData} = this.state;

        if (!loadingData && !loadedData) {
            const self = this;
            this.setState({loadingData: true});
            getIntegrations().then(integrations => {
                self.setState({
                    integrations,
                    loadingData: false,
                    loadedData: true
                });
            });
        }
    }

    _createIntegration = provider => {
        createPendingIntegration({provider}).then(response => {
            window.location.replace(`${response.oauth_authorize_uri}`);
        });
    }

    _deleteIntegration = provider => {
        deleteIntegration({provider}).then(_response => {
            console.log('Deleted ' + provider + ' integration');

            this.setState({loadingData: true});
            getIntegrations().then(integrations => {
                this.setState({
                    integrations,
                    loadingData: false,
                    loadedData: true
                });
            });
        });
    }

    render() {
        const {loadingData, integrations} = this.state;

        const jobAdderIntegration = (integrations.filter(integration => integration.provider === 'jobadder').length > 0)
        const smartRecruitersIntegration = (integrations.filter(integration => integration.provider === 'smartrecruiters').length > 0);
        const pageupIntegration = (integrations.filter(integration => integration.provider === 'pageup').length > 0);

        return (
            <Container loading={loadingData}>
                <div className={`marketplace`}>
                    <div className="marketplace-items">
                        <div className="item">
                            <div className="logo"><img src={LogoJobAdder} alt="JobAdder" /></div>
                            <LabelValueTile classes="item-status" label="Status" value={jobAdderIntegration ? "Enabled" : "Disabled"} />
                            <div className="description">
                                THE recruitment platform for recruiters and hiring managers.
                            </div>
                            {!jobAdderIntegration && (
                                <span
                                    className={`react-button ripple active enable`}
                                    onClick={() => this._createIntegration('jobadder')}
                                >
                                    Enable
                                </span>
                            )}
                            {jobAdderIntegration && (
                                <span className={`react-button active`}>
                                    Enabled
                                </span>
                            )}
                            {jobAdderIntegration && (
                                <span
                                    className={`react-button ripple warning disable hidden`}
                                    onClick={() => this._deleteIntegration('jobadder')}
                                >
                                    Disable
                                </span>
                            )}
                        </div>

                        <div className="item">
                            <div className="logo"><img src={LogoSmartRecruiters} alt="Smart Recruiters" /></div>
                            <LabelValueTile classes="item-status" label="Status" value={smartRecruitersIntegration ? "Enabled" : "Disabled"} />
                            <div className="description">
                                Enterprise-grade recruiting software, designed for hiring success.
                            </div>
                            {!smartRecruitersIntegration && (
                                <span
                                    className={`react-button ripple active enable`}
                                    onClick={() => this._createIntegration('smartrecruiters')}
                                >
                                    Enable
                                </span>
                            )}
                            {smartRecruitersIntegration && (
                                <span className={`react-button active`}>
                                    Enabled
                                </span>
                            )}
                            {smartRecruitersIntegration && (
                                <span
                                    className={`react-button ripple warning disable hidden`}
                                    onClick={() => this._deleteIntegration('smartrecruiters')}
                                >
                                    Disable
                                </span>
                            )}
                        </div>

                        <div className="item">
                            <div className="logo"><img src={LogoPageUp} alt="PageUp" /></div>
                            <LabelValueTile classes="item-status" label="Status" value={false ? "Enabled" : "Disabled"} />
                            <div className="description">
                                A powerful ATS delivering an exceptional recruiting experience for candidates, people leaders and HR teams.
                            </div>
                            {!pageupIntegration && (
                                <span
                                    className={`react-button ripple active enable`}
                                    onClick={() => this._createIntegration('pageup')}
                                >
                                    Enable
                                </span>
                            )}
                            {pageupIntegration && (
                                <span className={`react-button active`}>
                                    Enabled
                                </span>
                            )}
                            {pageupIntegration && (
                                <span
                                    className={`react-button ripple warning disable hidden`}
                                    onClick={() => this._deleteIntegration('pageup')}
                                >
                                    Disable
                                </span>
                            )}
                        </div>

                        <div className="item">
                            <div className="logo"><img src={LogoSAP} alt="SAP SuccessFactors" /></div>
                            <LabelValueTile classes="item-status" label="Status" value={false ? "Enabled" : "Disabled"} />
                            <div className="description">
                                Industry leading cloud human capital management (HCM) suite.
                            </div>
                            <span
                                className={`react-button disabled`}
                            >
                                Coming Soon
                            </span>
                        </div>

                        <div className="item">
                            <div className="logo"><img src={LogoInsideSherpa} alt="InsideSherpa" /></div>
                            <LabelValueTile classes="item-status" label="Status" value={false ? "Enabled" : "Disabled"} />
                            <div className="description">
                                InsideSherpa is an open access platform designed to unlock exciting careers for students.
                            </div>
                            <span
                                className={`react-button disabled`}
                            >
                                Coming Soon
                            </span>
                        </div>
                    </div>
                </div>
            </Container>
        );
    }
}

export default Marketplace;
