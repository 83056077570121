import React from "react";
import * as PropTypes from "prop-types";
import SubNav from "../../components/SubNav";
import { getJobsSummary } from "../../api";
import JobTile from "../JobTile";
import { convertDateToCommonFormat, eJobsType, eUserTypes, objectToArray } from "../../utils";
import { compact, flattenDeep, isEqual, omit, orderBy, sortBy, uniq, uniqWith } from "lodash";
import Container from "../Container";
import Dropdown from "../Dropdown";
import CustomCheckbox from "../Form/CustomCheckbox";
import { IconFunnel } from "../Icons";
import CustomDatePicker from "../Pickers/CustomDatePicker";

import './style.scss';


const eFilterValues = {
    DEADLINE_FROM: "deadline_from",
    DEADLINE_TO: "deadline_to",
    LOCATION: "location",
    EVALUATORS: "evaluators",
};


const subNavItems = [{
    label: "Active",
    route: "manage-job-list",
    routeState: {
        jobStatus: "active"
    },
    isActiveForRouteState: ({ jobStatus }) => jobStatus === 'active'
}, {
    label: "Archived",
    route: "manage-job-list",
    routeState: {
        jobStatus: eJobsType.ARCHIVED
    },
    isActiveForRouteState: ({ jobStatus }) => jobStatus === eJobsType.ARCHIVED
}, {
    label: "Draft",
    route: "manage-job-list",
    routeState: {
        jobStatus: "draft"
    },
    isActiveForRouteState: ({ jobStatus }) => jobStatus === 'draft'
}];


class Manage extends React.Component {
    constructor(props) {
        super(props);

        this.getActiveJobs = this.getActiveJobs.bind(this);
        this.getSortedFilteredActiveJobs = this.getSortedFilteredActiveJobs.bind(this);

        this.sortMenuItems = this.sortMenuItems.bind(this);
        this.updateSort = this.updateSort.bind(this);

        this.setFilterValues = this.setFilterValues.bind(this);
        this.getFilterValues = this.getFilterValues.bind(this);
        this.isFilterValueSelected = this.isFilterValueSelected.bind(this);
        this.toggleFilterContainer = this.toggleFilterContainer.bind(this);
        this.toggleFilterOption = this.toggleFilterOption.bind(this);
        this.setFilterOption = this.setFilterOption.bind(this);

        this.state = {
            jobs: [],
            loading: true,
            sort: {
                sortLabel: 'Recent First',
                sortField: 'deadline',
                sortOrder: 'desc'
            },
            filters: {
                deadline_from: null,
                deadline_to: null,
                location: [],
                evaluators: [],
            },
            filterValues: {
                location: [],
                evaluators: [],
            },
            isFilterContainerShown: false
        };
    }

    componentDidMount() {
        getJobsSummary().then((jobs) => {
            const filterValues = this.setFilterValues(jobs);
            this.setState({
                jobs,
                filterValues,
                loading: false
            });
        });
    }

    getActiveJobs(jobs) {
        const { jobs: jobsState } = this.state;

        let returnValues = jobs || jobsState;

        // applying status
        returnValues = returnValues.filter(({status}) => status === this.props.jobStatus);

        return returnValues;
    }

    getSortedFilteredActiveJobs() {
        const {
            sort: { sortField, sortOrder },
            filters: { deadline_from, deadline_to, location: locationFilter, evaluators: evaluatorsFilter }
        } = this.state;

        let returnValues = this.getActiveJobs();

        returnValues = returnValues.map((job) => {
            const { deadline, location, evaluators } = job;

            const isShown = (!!deadline_from ? convertDateToCommonFormat(deadline) >= deadline_from : true) &&
                (!!deadline_to ? convertDateToCommonFormat(deadline) <= deadline_to : true) &&
                (!!locationFilter.length ? isEqual((location || "").toLowerCase(), locationFilter) : true) &&
                (!!evaluatorsFilter.length ? (evaluators || []).some(
                    (evaluator) => evaluatorsFilter.find((evaluatorFilter) => evaluatorFilter.staff_id === evaluator.staff_id)
                ) : true);

            return {
                ...job,
                isShown
            };
        });

        // ordered
        returnValues = orderBy(returnValues, [sortField, "id"], [sortOrder, sortOrder]);

        return returnValues;
    }

    sortMenuItems() {
        const { sort: { sortLabel: selectedSortLabel } } = this.state;
        const sortItems = [
            {
                sortLabel: "Recent First",
                sortField: 'deadline',
                sortOrder: 'desc'
            },
            {
                sortLabel: "Oldest First",
                sortField: 'deadline',
                sortOrder: 'asc'
            },
            {
                sortLabel: "A - Z",
                sortField: 'title',
                sortOrder: 'asc'
            },
            {
                sortLabel: "Z - A",
                sortField: 'title',
                sortOrder: 'desc'
            }
        ];

        return sortItems.map(({sortLabel, sortField, sortOrder}, index) => ({
            id: index,
            firstLine: sortLabel,
            selected: selectedSortLabel === sortLabel,
            onClick: () => this.updateSort({sortLabel, sortField, sortOrder})
        }));
    }

    updateSort(sort) {
        this.setState({
            sort
        });
    }

    setFilterValues(jobs) {
        const filters = objectToArray(eFilterValues);

        const filterValues = {};
        const activeJobs = this.getActiveJobs(jobs);

        filters.forEach((filter) => {
            let returnValues = [...activeJobs];

            // get the filter values (in lower case)
            returnValues = returnValues.map((job) => {
                const values = job[filter];
                if (values) {
                    if (filter === eFilterValues.EVALUATORS) {
                        return values.map(({staff_id, first_name, last_name}) => (
                            {staff_id, first_name, last_name}
                        ));
                    } else {
                        return values.toLowerCase();
                    }
                } else {
                  return null;
                }
            });

            // flatten
            if (filter === eFilterValues.EVALUATORS) {
                returnValues = flattenDeep(returnValues);
            }

            // uniq
            if (filter === eFilterValues.EVALUATORS) {
                returnValues = uniqWith(returnValues, isEqual);
            } else {
                returnValues = uniq(returnValues);
            }

            // compact
            returnValues = compact(returnValues);

            // sort
            if (filter === eFilterValues.EVALUATORS) {
                returnValues = sortBy(returnValues, ['first_name', 'last_name'], ['asc', 'asc']);
            } else {
                returnValues = sortBy(returnValues, [item => item], ['asc']);
            }

            // add "blank"
            if (filter === eFilterValues.LOCATION) {
                returnValues.unshift("");
            }

            filterValues[filter] = returnValues;
        });

        // return returnValues;
        return filterValues;
    }

    getFilterValues(field) {
        const { filterValues } = this.state;
        return filterValues[field]
    }

    isFilterValueSelected(fieldName, value) {
        const { filters } = this.state;

        const listedFilters = filters[fieldName];
        return listedFilters.includes(value);
    }

    toggleFilterContainer() {
        const { isFilterContainerShown } = this.state;
        this.setState({
            isFilterContainerShown: !isFilterContainerShown
        });
    }

    toggleFilterOption(fieldName, value) {
        const { filters } = this.state;

        let fieldFilterValues = [...filters[fieldName]];

        if (fieldFilterValues.includes(value)) {
            fieldFilterValues = fieldFilterValues.filter((item) => item !== value);
        } else {
            fieldFilterValues.push(value);
        }

        this.setState({
            filters: {...filters, [fieldName]: fieldFilterValues}
        });
    }

    setFilterOption(fieldName, value) {
        const { filters } = this.state;

        this.setState({
            filters: {...filters, [fieldName]: value}
        });
    }


    render() {
        const { activeRoute, jobStatus, urlFor } = this.props;
        const { loading, isFilterContainerShown, filters : { deadline_from, deadline_to, location: filterLocation } } = this.state;
        return (
            <Container loading={loading}>
                <div className={`react-manage`}>
                    <SubNav activeRoute={activeRoute} activeRouteState={{ jobStatus }} urlFor={urlFor} mainItems={subNavItems} />

                    <div className="sort-and-filter">
                        <div className="sort">
                            <Dropdown
                                styles="popup no-border"
                                classes="sort-dropdown small"
                                label="Sort by"
                                items={this.sortMenuItems()}
                                showUnderline={false}
                            />
                        </div>
                        <div className="filter-icon" onClick={this.toggleFilterContainer}>
                            <img alt="Filter" title={`Filter`} src={IconFunnel} />
                        </div>
                    </div>

                    <div className={`tiles-and-filters ${isFilterContainerShown ? '' : 'filters-hidden'}`}>
                        <div className={`job-tiles`}>
                            {this.getSortedFilteredActiveJobs().map((job) => {
                                return (
                                    <div key={job.job_id} className={`job-tile-container ${job.isShown ? '' : 'filtered'}`}>
                                        <JobTile
                                            id={job.job_id}
                                            job={omit(job, "isShown")}
                                            type={jobStatus}
                                            viewType={eUserTypes.MANAGER}
                                            urlFor={urlFor}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        <div className={`filters`}>
                            <div className="filter-header">Filters</div>
                            <div className={`filter`}>
                                <div className="filter-name">Deadline</div>
                                <CustomDatePicker
                                    id="deadline-from"
                                    label="From"
                                    value={deadline_from}
                                    onChange={(e) => this.setFilterOption(eFilterValues.DEADLINE_FROM, convertDateToCommonFormat(e))}
                                />
                                <CustomDatePicker
                                    id="deadline-to"
                                    label="To"
                                    value={deadline_to}
                                    onChange={(e) => this.setFilterOption(eFilterValues.DEADLINE_TO, convertDateToCommonFormat(e))}
                                />
                            </div>

                            <div className={`filter`}>
                                <div className="filter-name">Location</div>
                                <Dropdown
                                    classes="location small"
                                    label="Location"
                                    items={
                                        this.getFilterValues(eFilterValues.LOCATION).map((location, index) => (
                                            {
                                                id: index,
                                                firstLine: location,
                                                selected: location === filterLocation,
                                                onClick: () => this.setFilterOption(eFilterValues.LOCATION, location)
                                            }
                                        ))
                                    }
                                />
                            </div>

                            <div className={`filter`}>
                                <div className="filter-name">Evaluators</div>
                                {this.getFilterValues(eFilterValues.EVALUATORS).map((evaluator) => {
                                    const {staff_id, first_name, last_name} = evaluator;
                                    return (
                                        <CustomCheckbox
                                            key={`checkbox-${eFilterValues.EVALUATORS}-${staff_id}`}
                                            id={`checkbox-${eFilterValues.EVALUATORS}-${staff_id}`}
                                            classes={`small`}
                                            label={(`${first_name} ${last_name}`).trim()}
                                            checked={this.isFilterValueSelected(eFilterValues.EVALUATORS, evaluator)}
                                            onChange={() => this.toggleFilterOption(eFilterValues.EVALUATORS, evaluator)}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        );
    }
}


Manage.propTypes = {
    urlFor: PropTypes.func.isRequired,
    activeRoute: PropTypes.oneOf(['manage-job-list']).isRequired,
    jobStatus: PropTypes.oneOf(objectToArray(eJobsType)).isRequired
};


export default Manage;
