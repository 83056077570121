import React from "react";
import * as PropTypes from "prop-types";

import "./style.scss";
import {eDecisionTypes, objectToArray} from "../../../utils";

const Decision = ({ value, sends_successful_email, sends_unsuccessful_email, onUpdate }) => {
    const isAccepted = value === eDecisionTypes.ACCEPTED,
        isDeclined = value === eDecisionTypes.DECLINED,
        isEditable = !value ||
            (value === eDecisionTypes.ACCEPTED && !sends_unsuccessful_email) ||
            (value === eDecisionTypes.DECLINED && !sends_successful_email);
    return (
        <div className={`decision`}>
            <div className={`section-title`}>Decision</div>
            <div className={`actions`}>
                {(!value || isAccepted || (isDeclined && isEditable)) && (
                    <span
                        className={`react-button ripple ${isAccepted ? 'selected' : 'outline'} active`}
                        onClick={() => value !== eDecisionTypes.ACCEPTED && onUpdate(eDecisionTypes.ACCEPTED)}
                    >
                        {isAccepted ? 'Accepted' : 'Accept'}
                    </span>
                )}
                {(!value || isDeclined || (isAccepted && isEditable)) && (
                    <span
                        className={`react-button ripple ${isDeclined ? 'selected' : 'outline'} warning`}
                        onClick={() => value !== eDecisionTypes.DECLINED && onUpdate(eDecisionTypes.DECLINED)}
                    >
                        {isDeclined ? 'Declined' : 'Decline'}
                    </span>
                )}
            </div>
        </div>
    );
}

Decision.propTypes = {
    value: PropTypes.oneOf(objectToArray(eDecisionTypes)),
    sends_successful_email: PropTypes.bool,
    sends_unsuccessful_email: PropTypes.bool,
    onUpdate: PropTypes.func
};

Decision.defaultProps = {
    value: null,
    sends_successful_email: false,
    sends_unsuccessful_email: false,
    onUpdate: null
};

export default Decision;