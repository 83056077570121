import React from "react";
import * as PropTypes from "prop-types";
import CustomDatePicker from "../Pickers/CustomDatePicker";
import {
    combineDateAndTime,
    eDateFormatTypes,
    eDeadlineDay,
    eJobStageTypes,
    eQuillToolbarSize,
    eStatusMessageType,
    eTextAreaType,
    formatDate,
    formatDateTime,
    isUpcomingDateTime,
    objectToArray,
    openLiveInterview,
    roundTime
} from "../../utils";
import CustomTimePicker from "../Pickers/CustomTimePicker";
import StaffSelection from "../StaffSelection";
import {
    addCandidateLiveInterviewDetails,
    createInterviewStage,
    getStaff,
    updateCandidateLiveInterviewDetails
} from "../../api";
import { sortBy, uniq } from "lodash";
import LabelValueTile from "../LabelValueTile";
import CustomCheckbox from "../Form/CustomCheckbox";
import CustomTextField from "../Form/CustomTextField";
import CustomTextArea from "../Form/CustomTextArea";

import './style.scss';
import ShortLink from "../ShortLink";
import EvaluatorSelection from "../EvaluatorSelection";
import { IconRefresh } from "../Icons";


const ACTIONS = {
    ADD: "add",
    UPDATE: "update"
};

class LiveInterview extends React.Component {
    constructor(props) {
        super(props);

        this.retrieveStaffRecords = this.retrieveStaffRecords.bind(this);
        this.onChange = this.onChange.bind(this);

        this.getJobStageIdFromJobStage = this.getJobStageIdFromJobStage.bind(this);

        this.getStepLinkClassNames = this.getStepLinkClassNames.bind(this);
        this.getStepLinkOnClick = this.getStepLinkOnClick.bind(this);
        this.getNextButtonClassNames = this.getNextButtonClassNames.bind(this);

        this.isExistingRecord = this.isExistingRecord.bind(this);
        this.getAvailableInterviewers = this.getAvailableInterviewers.bind(this);
        this.addInterviewer = this.addInterviewer.bind(this);
        this.removeInterviewer = this.removeInterviewer.bind(this);
        this.isNextButtonEnabled = this.isNextButtonEnabled.bind(this);
        this.backToPreviousStep = this.backToPreviousStep.bind(this);
        this.progressToNextStep = this.progressToNextStep.bind(this);
        this.goToStep = this.goToStep.bind(this);

        this.toggleSendEmail = this.toggleSendEmail.bind(this);

        this.state = {
            loading: false,
            activeStep: 1,
            maxStep: 4,
            enabledSteps: [1],
            staff: [],
            interviewers: [],
            interview_date: roundTime(new Date(), eDateFormatTypes.DATE),
            interview_time: roundTime(new Date(), eDateFormatTypes.TIME),

            send_email: true,
            subject: "Interview Invitation for [job_title]",
            message_1: "Hi [first_name],\n\nWe would like you to participate in a live video interview.",
            message_2: "Please click START INTERVIEW a few minutes prior to interview time.",

            interview_guide_summary: "",
            interview_guide: "",

            isDateTimeError: false,
            action: ACTIONS.ADD
        };
    }

    async retrieveStaffRecords() {
        this.setState({
            loading: true
        });
        const staff = await getStaff();
        const managers = staff.filter(({is_manager}) => is_manager);

        this.setState({
            staff: sortBy(managers, ['first_name', 'last_name']),
            loading: false
        });
    }

    onChange(event) {
        const { target: { id, value } } =  event;
        this.setState({
            [id]: value
        });
    }

    getJobStageIdFromJobStage() {
        const { job: { job_stages } } = this.props;
        const jobStage = job_stages.find(({job_stage_type}) => job_stage_type === eJobStageTypes.LIVE) || {};
        return jobStage.job_stage_id;
    }

    getStepLinkClassNames(step) {
        const {
            activeStep,
            enabledSteps,
            interviewers,
            interview_date,
            interview_time
        } = this.state;
        let returnValue = 'step-title';

        if (activeStep === step) {
            returnValue += ' active';
        } else if (enabledSteps.includes(step)) {
            returnValue += ' completed';
        }

        if (activeStep < step) {
            returnValue += ' disabled';
        }

        if (this.isExistingRecord()) {
            if (
                (step === 1 && !isUpcomingDateTime(interview_date, interview_time)) ||
                (step === 2 && !interviewers.length)
            ) {
                returnValue += ' error';
            }
        }

        return returnValue;
    }

    getStepLinkOnClick(step) {
        const { enabledSteps } = this.state;
        return () => {
            if (enabledSteps.includes(step)) {
                this.goToStep(step);
            }
        }
    }

    getNextButtonClassNames() {
        let returnValue = 'next-button react-button';

        if (!this.isNextButtonEnabled()) {
            returnValue += ' disabled';
        }

        return returnValue;
    }

    getNextButtonLabel() {
        const {
            activeStep,
            maxStep
        } = this.state;
        if (this.isExistingRecord()) {
            return 'Save';
        }
        if (activeStep === maxStep) {
            return 'Send Invite';
        }
        return 'Next';
    }

    isExistingRecord() {
        const { details: { interview_at } } = this.props;
        return !!interview_at;
    }

    getAvailableInterviewers() {
        const { staff, interviewers } = this.state;
        const interviewerIds = interviewers.map(({staff_id}) => staff_id);
        return staff.filter(({staff_id}) => !interviewerIds.includes(staff_id));
    }

    addInterviewer(interviewerId) {
        const { staff, interviewers } = this.state;
        const newInterviewer = staff.find(({staff_id}) => staff_id === interviewerId);

        this.setState({
            interviewers: [...interviewers, newInterviewer]
        });
    }

    removeInterviewer(interviewerId) {
        const { interviewers } = this.state;
        const updatedInterviewers = interviewers.filter(({staff_id}) => staff_id !== interviewerId);

        this.setState({
            interviewers: updatedInterviewers
        });
    }

    isNextButtonEnabled() {
        const { interviewers, interview_date, interview_time, activeStep } = this.state;

        const is_valid_interview_date_time = isUpcomingDateTime(interview_date, interview_time),
            has_interviewer = !!interviewers.length;

        if (this.isExistingRecord()) {
            return is_valid_interview_date_time && has_interviewer;
        } else {
            return (activeStep === 1 && is_valid_interview_date_time) ||
                (activeStep === 2 && has_interviewer) ||
                [3, 4].includes(activeStep);
        }
    }

    backToPreviousStep() {
        const { activeStep } = this.state;
        this.setState({
            activeStep: activeStep - 1
        });
    }

    async progressToNextStep() {
        const {
            activeStep,
            maxStep,
            enabledSteps,
            interviewers,
            interview_date,
            interview_time,
            send_email,
            subject,
            message_1,
            message_2,
            interview_guide_summary,
            interview_guide,
            action
        } = this.state;

        if (this.isExistingRecord() || activeStep === maxStep) {
            const {
                job: {job_id},
                candidate: {candidate_id},
                details: {job_stage_id},
                updateStatusMessage,
                onComplete,
            } = this.props;

            const interviewer_staff_ids = interviewers.map(({staff_id}) => staff_id);

            const data = {
                candidate_id,
                interviewer_staff_ids,
                interview_date,
                interview_time,
                interview_at: combineDateAndTime(interview_date, interview_time),
                send_email,
                subject,
                message_1,
                message_2,
                interview_guide_summary,
                interview_guide
            };

            let jobStageId = job_stage_id || this.getJobStageIdFromJobStage(),
                response,
                newJobStage;

            if (!jobStageId) {
                newJobStage = await createInterviewStage(job_id, eJobStageTypes.LIVE);
                jobStageId = newJobStage.job_stage_id;
            }

            if (action === ACTIONS.ADD) {
                // if it succeed, then the stage is successfully created
                // if it failed, then most likely the stage has been previous created
                response = await addCandidateLiveInterviewDetails(job_id, jobStageId, data);
            } else {
                // action === ACTIONS.UPDATE
                response = await updateCandidateLiveInterviewDetails(job_id, jobStageId, candidate_id, data);
            }

            if (response.error) {
                const errorMessage = `Unable to ${action} interview details. Please try again.`;
                updateStatusMessage?.(errorMessage, eStatusMessageType.WARNING);
                return false;
            }

            const statusMessage = `Live interview details successfully ${action === ACTIONS.ADD ? 'added' : 'updated'}.`;
            updateStatusMessage?.(statusMessage);

            onComplete?.(response, !!newJobStage);

            return false;
        }

        if (activeStep === 1) {
            if (!isUpcomingDateTime(interview_date, interview_time)) {
                this.setState({
                    isDateTimeError: true
                });
                return false;
            }
        }

        enabledSteps.push(activeStep + 1);

        this.setState({
            activeStep: activeStep + 1,
            enabledSteps: uniq(enabledSteps)
        });

        return true;
    }

    goToStep(step) {
        this.setState({
            activeStep: step
        });
    }

    toggleSendEmail() {
        const { send_email } = this.state;
        this.setState({
            send_email: !send_email
        });
    }

    componentDidMount() {
        const { job: { title } = {}, candidate = {} } = this.props;
        const { subject, message_1, maxStep } = this.state;
        this.retrieveStaffRecords();

        const { first_name } = candidate;

        const { details: { interviewers = [], interview_at, interview_guide = "" } = {} } = this.props;

        let updatedState = {
            subject: subject.replace("[job_title]", title),
            message_1: message_1.replace("[first_name]", first_name)
        };

        if (this.isExistingRecord()) {
            updatedState = {
                ...updatedState,
                interviewers,
                interview_date: formatDateTime(interview_at, eDateFormatTypes.DATE),
                interview_time: formatDateTime(interview_at, eDateFormatTypes.TIME),
                // send_email: true,
                // subject: "Interview Invitation for [job_title]",
                // message_1: "Hi [first_name],\n\nWe would like you to participate in a live video interview.",
                // message_2: "Please click START INTERVIEW a few minutes prior to interview time.",
                // interview_guide_summary: "",
                interview_guide,
                action: ACTIONS.UPDATE,
                enabledSteps: [...Array(maxStep).keys()].map(i => i + 1)
            }
        }

        this.setState(updatedState);
    }

    componentDidUpdate(_prevProps, prevState) {
        const { interview_date : prev_interview_date,
            interview_time : prev_interview_time,
            activeStep: prev_active_step
        } = prevState;
        const { interview_date, interview_time, activeStep } = this.state;

        if (
            prev_interview_date !== interview_date ||
            prev_interview_time !== interview_time ||
            (prev_active_step !== activeStep && activeStep === 1)
        ) {
            this.setState({
                isDateTimeError: !isUpcomingDateTime(interview_date, interview_time)
            });
        }
    }

    render() {
        const {
            activeStep,
            staff,
            interviewers,
            interview_date,
            interview_time,

            send_email,
            subject,
            message_1,
            message_2,

            interview_guide_summary,
            interview_guide,

            isDateTimeError
        } = this.state;
        const {
            job : { job_id, title } = {},
            candidate: { first_name, last_name, candidate_id, live_interview_url_id } = {},
            company_id,
            staff_id
        } = this.props;

        const interviewerIds = interviewers.map(({staff_id: staffId}) => staffId);
        const interviewerNames = interviewers.map(
            ({first_name: firstName, last_name: lastName}) => `${firstName} ${lastName}`.trim()
        );

        const isInterviewer = interviewerIds.includes(staff_id);

        return (
            <div className={`live-interview`}>
                <div className={`steps`}>
                    <div
                        className={this.getStepLinkClassNames(1)}
                        onClick={this.getStepLinkOnClick(1)}
                    >
                        Time
                    </div>
                    <div className={`arrow`}>&gt;</div>
                    <div
                        className={this.getStepLinkClassNames(2)}
                        onClick={this.getStepLinkOnClick(2)}
                    >
                        Interviewers
                    </div>
                    <div className={`arrow`}>&gt;</div>
                    <div
                        className={this.getStepLinkClassNames(3)}
                        onClick={this.getStepLinkOnClick(3)}
                    >
                        Invitation
                    </div>
                    <div className={`arrow`}>&gt;</div>
                    <div
                        className={this.getStepLinkClassNames(4)}
                        onClick={this.getStepLinkOnClick(4)}
                    >
                        Interview Guide
                    </div>
                </div>
                {activeStep === 1 && (
                    <div className={`step step-${activeStep}`}>
                        <div className={`step-1-info`}>
                            <LabelValueTile classes="candidate-name" label="Candidate" value={(first_name + " " + last_name).trim()} />
                            {!!live_interview_url_id && (
                                <React.Fragment>
                                    <LabelValueTile classes="interview-link-candidate" label="Interview Link (for Candidate)">
                                        <ShortLink
                                            label={window.location.host + `/live?id=${live_interview_url_id}`}
                                            value={window.location.host + `/live?id=${live_interview_url_id}`}
                                        />
                                    </LabelValueTile>
                                    {isInterviewer && (
                                        <button className={`react-button join-interview-button`} onClick={() => openLiveInterview(company_id, staff_id, job_id, candidate_id)}>Join Interview</button>
                                    )}
                                </React.Fragment>
                            )}
                        </div>
                        <div className={`step-1-details`}>
                            <div className={`step-1-details-header`}>Interview Time</div>
                            <div className={`interview-date-time`}>
                                <CustomDatePicker
                                    id={`interview_date`}
                                    classes={`interview-date`}
                                    required={true}
                                    label={`Date`}
                                    value={interview_date}
                                    minDate={eDeadlineDay.YESTERDAY}
                                    onChange={(e) => this.onChange({
                                        target: {
                                            id: `interview_date`,
                                            value: formatDateTime(e, eDateFormatTypes.DATE)
                                        }
                                    })}
                                />
                                <span className={`at`}>at</span>
                                <CustomTimePicker
                                    id="interview_time"
                                    classes={`interview-time`}
                                    required={true}
                                    label="Time"
                                    value={interview_time}
                                    onChange={this.onChange}
                                />
                            </div>
                            {isDateTimeError && (
                                <div className={`date-time-error`}>Please select a valid and current date and time.</div>
                            )}
                        </div>
                    </div>
                )}

                {activeStep === 2 && (
                    <div className={`step step-${activeStep}`}>
                        <EvaluatorSelection
                            header={<div><span>Interviewers</span><span title={`Refresh`} onClick={this.retrieveStaffRecords}>{IconRefresh}</span></div>}
                            placeholder={`Select an Interviewer`}
                            evaluators={this.getAvailableInterviewers()}
                            staffFilter={({is_manager}) => !!is_manager}
                            onAdd={this.addInterviewer}
                        />
                        <div className={`selected-interviewers`}>
                            {interviewers.map(({staff_id: staffId}) =>
                                <StaffSelection
                                    header={``}
                                    staffRecords={staff}
                                    selectedStaffId={staffId || null}
                                    readOnly={true}
                                    onRemove={this.removeInterviewer}
                                />
                            )}
                        </div>
                    </div>
                )}

                {activeStep === 3 && (
                    <div className={`step step-${activeStep}`}>
                        <CustomCheckbox
                            id={`send-email`}
                            label={`Send an email invitation to candidate`}
                            checked={send_email}
                            onChange={this.toggleSendEmail}
                        />
                        <CustomTextField
                            id={`subject`}
                            label={`Subject`}
                            value={subject}
                            onChange={this.onChange}
                        />

                        <div className={`email-preview`}>
                            <CustomTextArea
                                id={`message_1`}
                                value={message_1}
                                onChange={this.onChange}
                            />
                            <div className={`job-position`}>
                                <span className={`label`}>Position:</span>
                                <span className={`value`}> {title}</span>
                            </div>
                            <div className={`interview-time`}>
                                <span className={`label`}>Interview Time:</span>
                                <span className={`value`}> {formatDate(interview_date)} at {interview_time}</span>
                            </div>
                            <div className={`interviewer`}>
                                <span className={`label`}>Interviewer{interviewerNames.length > 1 ? 's' : ''}:</span>
                                <span className={`value`}> {interviewerNames.join(", ")}</span>
                            </div>
                            <div className={`learn-more`}>
                                Learn more about the live video interview process
                            </div>
                            <CustomTextArea
                                id={`message_2`}
                                value={message_2}
                                onChange={this.onChange}
                            />
                            <div className={`email-button react-button`}>
                                Start Interview
                            </div>
                        </div>
                    </div>
                )}

                {activeStep === 4 && (
                    <div className={`step step-${activeStep}`}>
                        <CustomTextArea
                            id={`interview_guide_summary`}
                            label={`Live Interview Guide Summary`}
                            value={interview_guide_summary}
                            placeholder={`Enter live interview guide summary here...`}
                            onChange={this.onChange}
                        />
                        <CustomTextArea
                            hasPadding={true}
                            type={eTextAreaType.RICH}
                            showToolbarToggle={true}
                            toolbarSize={eQuillToolbarSize.SMALL}
                            id={`interview_guide`}
                            label={`Live Interview Guide`}
                            value={interview_guide}
                            placeholder={`Enter live interview guide here...`}
                            onChange={this.onChange}
                        />
                    </div>
                )}

                <div className={`step-buttons`}>
                    <div className={`button-left`}>
                        {activeStep > 1 && (
                            <div
                                className={`previous-button react-button outline`}
                                onClick={this.backToPreviousStep}
                            >
                                Back
                            </div>
                        )}
                    </div>
                    <div className={`button-right`}>
                        <div
                            className={this.getNextButtonClassNames()}
                            onClick={this.isNextButtonEnabled() ? this.progressToNextStep : null}
                        >
                            {this.getNextButtonLabel()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

LiveInterview.propTypes = {
    job: PropTypes.shape({
        job_id: PropTypes.number,
        title: PropTypes.string,
    }),
    job_stage: {
        job_stage_id: PropTypes.number,
        job_stage_type: PropTypes.oneOf(objectToArray(eJobStageTypes))
    },
    details: PropTypes.shape({
        interviewers: PropTypes.array,
        interview_at: PropTypes.string,
        interview_guide: PropTypes.string
    }),
    candidate: PropTypes.object,
    company_id: PropTypes.number,
    staff_id: PropTypes.number,
    updateStatusMessage: PropTypes.func,
    onComplete: PropTypes.func
};

LiveInterview.defaultProps = {
    job: {
        job_id: null,
        title: null
    },
    job_stage: {
        job_stage_id: null,
        job_stage_type: null
    },
    details: {
        interviewers: [],
        interview_at: null,
        interview_guide: null
    },
    candidate: {},
    company_id: 0,
    staff_id: 0,
    updateStatusMessage: null,
    onComplete: null
};

export default LiveInterview;
