import React, { createRef } from "react";
import * as PropTypes from "prop-types";
import CustomTextField from "../../components/Form/CustomTextField";
import {
    isInternetExplorer,
    isValidEmail,
    isValidPassword,
    objectToArray,
    eVerificationType,
    getPageFeatures,
    eConfirmDialogResponseType,
    getIntegrationProviderFromPath
} from "../../utils";
import { getLoginModes, initiateStaffLogin, postAuthenticate, sendPasswordResetEmail, sendVerificationCode } from "../../api";
import { IconTickWithCircle } from "../../components/Icons";
import Loader from "../../components/Loader";
import ResetPassword from "../../components/ResetPassword";

import './style.scss';
import ConfirmDialogResponse from "../../components/ConfirmDialog/Response";


export const eLoginPageType = {
    LOGIN: "login",
    PASSWORD: "password",
    FORGET: "forget",
    FORGET_RESET: "forget_reset",
    RESET: "reset",
    SUCCESS: "success",
    CHECK_EMAIL: "check_email"
};

const eMessageType = {
    ERROR: "error",
    CONFIRMATION: "confirmation"
};

export const eStaffLoginMethods = {
    PASSWORD: "ask_for_password",
    VERIFICATION_CODE: "ask_for_email_verification_code"
}

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.setLoading = this.setLoading.bind(this);
        this.unsetLoading = this.unsetLoading.bind(this);
        this.retrieveLoginModes = this.retrieveLoginModes.bind(this);

        this.onInputChange = this.onInputChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.updateEmailValidity = this.updateEmailValidity.bind(this);
        this.updatePasswordValidity = this.updatePasswordValidity.bind(this);
        this.updateVerificationCodeValidity = this.updateVerificationCodeValidity.bind(this);
        this.isValidVerificationCode = this.isValidVerificationCode.bind(this);
        this.toggleVerification = this.toggleVerification.bind(this);

        this.resetPassword = this.resetPassword.bind(this);

        this.onButtonClick = this.onButtonClick.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onAuthSuccess = this.onAuthSuccess.bind(this);

        this.requestVerificationCode = this.requestVerificationCode.bind(this);

        this.setPageShown = this.setPageShown.bind(this);
        this.setMessage = this.setMessage.bind(this);
        this.clearMessage = this.clearMessage.bind(this);

        this.state = {
            isLoading: false,
            minimumLoadingTime: 500,
            loadingTimer: 0,
            defaultLoginModes: null,
            currentLoginModes: {},
            pageShown: eLoginPageType.LOGIN,
            uid: '',
            email: '',
            password: '',
            verification_code: '',
            passwordRef: createRef(), // TODO: remove createRef once the joint email/password layout is removed
            isEmailValid: true,
            isPasswordValid: true,
            isVerificationCodeValid: true,
            verificationType: eVerificationType.PASSWORD,
            errorMessage: null,
            confirmationMessage: null,
            isLoggingIn: false,

            confirmDialogResponseProps: {
                type: eConfirmDialogResponseType.ERROR,
                sentence: 'Unable to retrieve user.'
            }
        };
    }

    setLoading() {
        this.setState({
            isLoading: true
        });
        const { minimumLoadingTime } = this.state;
        this.timerInterval = setInterval(
            () => {
                const { loadingTimer } = this.state;
                this.setState({loadingTimer: loadingTimer + minimumLoadingTime});
            }, minimumLoadingTime
        );
    }

    unsetLoading() {
        const { loadingTimer, minimumLoadingTime } = this.state;
        setTimeout(() => {
            clearInterval(this.timerInterval);
            this.setState({
                isLoading: false,
                loadingTimer: 0
            });
        }, Math.max(0, minimumLoadingTime - loadingTimer));
    }

    async retrieveLoginModes() {
        const { defaultLoginModes } = this.state;

        let response;
        if (!!defaultLoginModes) {
            response = defaultLoginModes;
        } else {

            const { pathname, search, hash } = window.location;
            const targetPath = encodeURIComponent(pathname + search + hash);

            response = await getLoginModes(targetPath);
            if (response.error) {
                response = {
                    can_login_with_password: true,
                    can_login_with_email: false,
                    oauth_provider: null,
                    oauth_url: null
                };
            }
        }

        const { can_login_with_password } = response;

        this.setState({
            defaultLoginModes: response,
            currentLoginModes: response,
            verificationType: can_login_with_password ? eVerificationType.PASSWORD : eVerificationType.VERIFICATION_CODE
        });
    }

    onInputChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        });

        if (document.activeElement !== event.target) {
            if (event.target.id === "email") {
                setTimeout(this.updateEmailValidity, 1);
            }
            if (event.target.id === "password") {
                setTimeout(this.updatePasswordValidity, 1);
            }
            if (event.target.id === "verification_code") {
                setTimeout(this.updateVerificationCodeValidity, 1);
            }
        }
    }

    onKeyDown(e) {
        if (e.keyCode === 13){ // 'Enter'
            const { target: { id } } = e;
            const { currentLoginModes, email, password, passwordRef } = this.state;
            const { can_login_with_password, can_login_with_email } = currentLoginModes;
            e.preventDefault();
            this.clearMessage();
            if (id === "email" && can_login_with_password && !can_login_with_email) {
                // for now
                if (!isValidEmail(email)) {
                    this.setMessage(eMessageType.ERROR, `Please enter your valid email address.`);
                    return false;
                }
                !!passwordRef && !!passwordRef.current && passwordRef.current.focus();
            } else if (id === "password" && can_login_with_password) {
                if (!isValidPassword(password)) {
                    this.setMessage(eMessageType.ERROR, `Please enter your password.`);
                    return false;
                }
                this.onButtonClick();
            } else {
                this.onButtonClick();
            }
            return false;
        }
    }

    updateEmailValidity() {
        const { email } = this.state;
        this.setState({
            isEmailValid: email === '' || isValidEmail(email)
        });
    }

    updatePasswordValidity() {
        const { password } = this.state;
        this.setState({
            isPasswordValid: password === '' || !!(password || "").length
        });
    }

    updateVerificationCodeValidity() {
        this.setState({
            isVerificationCodeValid: this.isValidVerificationCode()
        });
    }

    isValidVerificationCode() {
        const { verification_code } = this.state;
        return verification_code.length === 6;
    }

    toggleVerification(type) {
        this.setState({
            verificationType: type
        });
    }

    async onButtonClick(e = null) {
        !!e && e.preventDefault();

        const { currentLoginModes, pageShown, email, password } = this.state;
        const { can_login_with_password, can_login_with_email } = currentLoginModes;

        this.clearMessage();

        if (pageShown === eLoginPageType.LOGIN) {
            if (!isValidEmail(email)) {
                this.setMessage(eMessageType.ERROR, `Please enter your valid email address.`);
                return false;
            }

            if (can_login_with_password && !can_login_with_email && isValidPassword(password)) {
                // the original login
                return this.onSubmit();
            }

            if (can_login_with_password || can_login_with_email) {
                const payload = { email };
                const response = await initiateStaffLogin(payload);

                if (response) {
                    const { next } = response;

                    const currentLoginModes = {
                        ...this.state.currentLoginModes,
                        can_login_with_password: next.includes(eStaffLoginMethods.PASSWORD),
                        can_login_with_email: next.includes(eStaffLoginMethods.VERIFICATION_CODE)
                    };

                    this.setState({
                        currentLoginModes,
                        verificationType: next.includes(eStaffLoginMethods.VERIFICATION_CODE) ?
                            eVerificationType.VERIFICATION_CODE : eVerificationType.PASSWORD
                    });
                    return this.setPageShown(eLoginPageType.PASSWORD);
                } else {
                    this.setMessage(eMessageType.ERROR, `An error has occurred. Please try again later.`);
                }
            }
        }

        if (pageShown === eLoginPageType.PASSWORD) {
            // email check performed on the previous step
            if (can_login_with_password && !isValidPassword(password)) {
                this.setMessage(eMessageType.ERROR, `Please enter your password.`);
                return false;
            }

            if (can_login_with_email && !this.isValidVerificationCode()) {
                this.setMessage(eMessageType.ERROR, `Please enter your 6-digit verification code.`);
                return false;
            }

            return this.onSubmit();
        }

        if (pageShown === eLoginPageType.FORGET) {
            if (!isValidEmail(email)) {
                this.setMessage(eMessageType.ERROR, `Please enter your valid email address.`);
                return false;
            }

            return this.onSubmit();
        }

        return false;
    }

    async onSubmit() {
        const { pageShown, uid, email, password, verification_code, verificationType } = this.state;

        if ([eLoginPageType.LOGIN, eLoginPageType.PASSWORD].includes(pageShown)) {
            const payload = {};

            if (!!uid) {
                payload.uid = uid;
            } else if (!!email) {
                payload.email = email;
            }

            if (verificationType === eVerificationType.PASSWORD) {
                payload.password = password;
            }

            if (verificationType === eVerificationType.VERIFICATION_CODE) {
                payload.verification_code = verification_code;
            }

            this.setLoading();
            const response = await postAuthenticate(payload);
            this.unsetLoading();

            if (response.error) {
                this.setMessage(eMessageType.ERROR, `Your ${!!email ? 'email and/or ' : ''}${verificationType === eVerificationType.PASSWORD ? 'password' : 'verification code'} is incorrect. Please check and try again.`);
            } else {
                this.onAuthSuccess(response);
            }
        } else if (pageShown === eLoginPageType.FORGET) {
            return this.resetPassword();
        }
    }

    async resetPassword() {
        this.setLoading();

        const { email } = this.state;
        const payload = { email };
        const response = await sendPasswordResetEmail(payload);

        this.unsetLoading();

        if (response) {
            const {success, error} = response;
            if (success) {
                this.setState({
                    pageShown: eLoginPageType.FORGET_RESET
                });
            } else if (!!error) {
                this.setMessage(eMessageType.ERROR, "We are unable to process your request. Please refresh this page and try again.");
            }
        }
    }

    async onAuthSuccess(response) {
        this.setState({
            pageShown: eLoginPageType.SUCCESS
        });

        this.props.onLoggedIn(response);
    }

    async requestVerificationCode() {
        const { email } = this.state;
        const payload = { email };

        const response = await sendVerificationCode(payload);

        if (response.error) {
            this.setMessage(eMessageType.ERROR, `An error has occurred. Please try again.`);
        } else {
            this.setMessage(
                eMessageType.CONFIRMATION,
                <div>
                    We have sent a verification code to your email. Please check your inbox.
                </div>
            );
        }
    }

    setPageShown(pageShown) {
        const { passwordRef } = this.state;
        this.setState({
            pageShown,
            errorMessage: null
        });

        if (pageShown === eLoginPageType.LOGIN) {
            return this.retrieveLoginModes();
        }

        if (pageShown === eLoginPageType.PASSWORD) {
            !!passwordRef && !!passwordRef.current && passwordRef.current.focus();
        }
    }

    setMessage(type, message) {
        const messages = {
            errorMessage: null,
            confirmationMessage: null
        };

        if (type === eMessageType.ERROR) {
            messages.errorMessage = message;
        } else if (type === eMessageType.CONFIRMATION) {
            messages.confirmationMessage = message;
        }

        this.setState(messages);
    }

    clearMessage() {
        this.setMessage();
    }

    async componentDidMount() {
        this.setLoading();
        await this.retrieveLoginModes();
        this.unsetLoading();

        let nextState = {};

        const { attr, pageShown } = this.props;
        if (!!attr) {
            nextState = {...attr};
        }

        if (!!pageShown) {
            nextState.pageShown = pageShown
        }

        this.setState({
            ...nextState
        });
    }

    render() {
        const {
            isLoading,

            currentLoginModes,
            pageShown,

            email,
            password,
            verification_code,

            passwordRef,

            isEmailValid,
            isPasswordValid,

            verificationType,

            errorMessage,
            confirmationMessage
        } = this.state;
        const { path } = this.props;
        const { can_login_with_password, can_login_with_email, oauth_provider, oauth_url } = currentLoginModes;

        const submitButtonLabel =
            pageShown === eLoginPageType.LOGIN && can_login_with_password && can_login_with_email ? "Continue" :
            pageShown === eLoginPageType.FORGET ? "Reset Password" :
                "Login";

        const integrationProvider = getIntegrationProviderFromPath(path);
        const { loginToShowErrorInstead } = getPageFeatures(integrationProvider);

        if (loginToShowErrorInstead) {
            const { confirmDialogResponseProps } = this.state;
            return (
                <ConfirmDialogResponse {...confirmDialogResponseProps} />
            );
        }

        return (
            <div className={`login-page`}>
                <div className={`form`}>
                    <div className={`form-logo`} />
                    {isInternetExplorer() && (
                        <React.Fragment>
                            <div className={`form-header`}>Sorry!</div>
                            <div className={`unsupported-browser`}>
                                You are using an unsupported browser.
                                Please use another browser such as Chrome, Firefox, Safari, or Edge.
                            </div>
                        </React.Fragment>
                    )}
                    {!isInternetExplorer() && (
                        <React.Fragment>
                            {isLoading && (
                                <Loader />
                            )}
                            {!isLoading && (
                                <React.Fragment>
                                    <div className={`form-header`}>
                                        {
                                            [eLoginPageType.LOGIN, eLoginPageType.PASSWORD, eLoginPageType.CHECK_EMAIL].includes(pageShown) ? "Sign In" : ""
                                        }
                                        {pageShown === eLoginPageType.FORGET ? "Forget Password" : ""}
                                        {pageShown === eLoginPageType.RESET ? "Reset Password" : ""}
                                        {pageShown === eLoginPageType.SUCCESS ? "Logging in..." : ""}
                                    </div>
                                    {[eLoginPageType.LOGIN, eLoginPageType.PASSWORD, eLoginPageType.FORGET].includes(pageShown) && (
                                        <form className={`login-form`} onSubmit={this.onButtonClick}>
                                            <div className={`form-subheader`}>
                                                {
                                                    oauth_provider && oauth_url ? "" :
                                                    pageShown === eLoginPageType.LOGIN ? "Sign in to your account" :
                                                    pageShown === eLoginPageType.FORGET ? "Please enter your email address" :
                                                    ""
                                                }
                                            </div>
                                            {oauth_provider && oauth_url && (
                                                <React.Fragment>
                                                    {
                                                        oauth_provider === 'smartrecruiters' ? 
                                                        <a className={`react-button login-with-smartrecruiters-button`} href={oauth_url}>
                                                            <div className='sr-button-inner-div'>
                                                                <div className={'srLogo'}/>
                                                                    <div className={'sr-login-text-and-logo-container'}>
                                                                        <div className={'sr-login-text'}>Sign in with</div>
                                                                        <div className={'sr-login-logo'}/>
                                                                    </div>
                                                                </div>
                                                        </a> : <a className={`react-button login-with-oauth-button`} href={oauth_url}>Login with {oauth_provider}</a>
                                                    }
                                                    <span className={`or-section`}><hr className='oauth-hr-left' />OR<hr className='oauth-hr-right' /></span>
                                                </React.Fragment>

                                            )}
                                            {pageShown === eLoginPageType.PASSWORD && (
                                                <React.Fragment>
                                                    <button className={`email-field react-button text-link`} onClick={() => this.setPageShown(eLoginPageType.LOGIN)}>{email}</button>
                                                    {can_login_with_password && can_login_with_email && (
                                                        <div className={`password-verification-toggle`}>
                                                            <div className={`active-background ${verificationType}_active`} />
                                                            <div
                                                                onClick={() => this.toggleVerification(eVerificationType.PASSWORD)}
                                                                className={`password-toggle ${verificationType === eVerificationType.PASSWORD ? 'active' : ''}`}
                                                            >
                                                                Password
                                                            </div>
                                                            <div
                                                                onClick={() => this.toggleVerification(eVerificationType.VERIFICATION_CODE)}
                                                                className={`verification-toggle  ${verificationType === eVerificationType.VERIFICATION_CODE ? 'active' : ''}`}
                                                            >
                                                                Email code
                                                            </div>
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )}

                                            {(
                                                (pageShown === eLoginPageType.LOGIN && (can_login_with_password || can_login_with_email)) ||
                                                [eLoginPageType.FORGET].includes(pageShown)
                                            ) && (
                                                <CustomTextField
                                                    type="text"
                                                    id={`email`}
                                                    isError={!isEmailValid}
                                                    classes={`email`}
                                                    label={`Email`}
                                                    value={email}
                                                    required={true}
                                                    autoComplete={`username email`}
                                                    onChange={this.onInputChange}
                                                    onKeyDown={this.onKeyDown}
                                                    onBlur={this.updateEmailValidity}
                                                    autoFocus={true}
                                                />
                                            )}

                                            {(
                                                (pageShown === eLoginPageType.LOGIN && can_login_with_password && !can_login_with_email) ||
                                                (pageShown === eLoginPageType.PASSWORD && can_login_with_password && verificationType === eVerificationType.PASSWORD)
                                            ) && (
                                                <CustomTextField
                                                    type="password"
                                                    id={`password`}
                                                    inputRef={passwordRef}
                                                    isError={!isPasswordValid}
                                                    classes={`password`}
                                                    label={`Password`}
                                                    value={password}
                                                    required={true}
                                                    autoComplete={`current-password`}
                                                    onChange={this.onInputChange}
                                                    onKeyDown={this.onKeyDown}
                                                    onBlur={this.updatePasswordValidity}
                                                    // autoFocus={true}
                                                />
                                            )}

                                            {pageShown === eLoginPageType.PASSWORD && can_login_with_email && verificationType === eVerificationType.VERIFICATION_CODE && (
                                                <React.Fragment>
                                                    <div className={`verification-code-message`}>
                                                        We've just sent you an email containing a verification code. Please
                                                        enter the code to the field below to continue.
                                                    </div>
                                                    <CustomTextField
                                                        type="text"
                                                        id={`verification_code`}
                                                        // isError={!isVerificationCodeValid}
                                                        classes={`verification_code`}
                                                        label={`Verification Code`}
                                                        value={verification_code}
                                                        maxLength={6}
                                                        required={true}
                                                        onChange={this.onInputChange}
                                                        onKeyDown={this.onKeyDown}
                                                        onBlur={this.updateVerificationCodeValidity}
                                                        autoFocus={true}
                                                    />
                                                </React.Fragment>
                                            )}
                                            <button
                                                type="submit"
                                                className={`form-submit react-button active`}
                                            >
                                                {submitButtonLabel}
                                            </button>
                                            {errorMessage && (
                                                <div className={`form-error`}>
                                                    {errorMessage}
                                                </div>
                                            )}
                                            {confirmationMessage && (
                                                <div className={`form-confirmation`}>
                                                    {confirmationMessage}
                                                </div>
                                            )}

                                            <div className={`form-footer`}>
                                                {[eLoginPageType.LOGIN].includes(pageShown) && can_login_with_password && !can_login_with_email && (
                                                    <button type='button' className={`link footer-link`} onClick={() => this.setPageShown(eLoginPageType.FORGET)}>Forget your password?</button>
                                                )}
                                                {[eLoginPageType.PASSWORD].includes(pageShown) && (
                                                    <React.Fragment>
                                                        {verificationType === eVerificationType.PASSWORD && (
                                                            <button type='button' className={`link footer-link`} onClick={() => this.setPageShown(eLoginPageType.FORGET)}>Forget your password?</button>
                                                        )}
                                                        {verificationType === eVerificationType.VERIFICATION_CODE && (
                                                            <div>
                                                                Don't have your code? <button type='button' className={`link footer-link`} onClick={this.requestVerificationCode}>Click here</button>
                                                            </div>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                                {[eLoginPageType.FORGET].includes(pageShown) && (
                                                    <div>
                                                        Remember your password? <button type='button' className={`link footer-link`} onClick={() => this.setPageShown(eLoginPageType.LOGIN)}>Sign in.</button>
                                                    </div>
                                                )}
                                                {[eLoginPageType.VERIFICATION].includes(pageShown) && (
                                                    <button type='button' className={`link footer-link`} onClick={() => this.setPageShown(eLoginPageType.LOGIN)}>Sign in</button>
                                                )}
                                            </div>
                                        </form>
                                    )}
                                    {[eLoginPageType.CHECK_EMAIL].includes(pageShown) && (
                                        <p className="check-email">
                                            We’ve sent you an email with<br/>
                                            a link for you to access your account.
                                        </p>
                                    )}
                                    {[eLoginPageType.FORGET_RESET].includes(pageShown) && (
                                        <React.Fragment>
                                            {IconTickWithCircle}
                                            <p className="forgot-password-confirm">
                                                We’ve sent you an email with<br/>
                                                a link to reset your password.
                                            </p>
                                            <span className="forgot-password-link">
                                                <button className='link' onClick={() => this.setPageShown(eLoginPageType.LOGIN)}>Sign in</button>
                                            </span>
                                        </React.Fragment>
                                    )}
                                    {[eLoginPageType.RESET].includes(pageShown) && (
                                        <ResetPassword
                                            backToLoginPage={() => this.setPageShown(eLoginPageType.LOGIN)}
                                            backToForgetPasswordPage={() => this.setPageShown(eLoginPageType.FORGET)}
                                        />
                                    )}
                                    {[eLoginPageType.SUCCESS].includes(pageShown) && (
                                        <div className={`login-success`}>
                                            <Loader />
                                        </div>
                                    )}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    }
}

LoginPage.propTypes = {
    pageShown: PropTypes.oneOf(objectToArray(eLoginPageType)).isRequired,
    path: PropTypes.string,
    onLoggedIn: PropTypes.func.isRequired
};

LoginPage.defaultProps = {
    pageShown: null,
    path: "",
    onLoggedIn: null
};

export default LoginPage;
