import React from "react";
import * as PropTypes from "prop-types";
import Candidates from "../Candidates";
import { validateCandidates } from "../../utils";
import { addCandidates } from "../../api";
import NoInvitationEmailMessage from "../NoInvitationEmailMessage";

import './style.scss';


class CandidatesInvite extends React.Component {
    constructor(props) {
        super(props);

        this.getJobStage = this.getJobStage.bind(this);
        this.addNewCandidates = this.addNewCandidates.bind(this);

        this.state = {
            is_submitting: false
        };
    }

    getJobStage(job, job_stage_id) {
        return job.job_stages.find((job_stage) => job_stage.job_stage_id === job_stage_id);
    }

    async addNewCandidates() {
        const {
            job,
            job_stage_id,
            candidates,
            onCandidateChange,
            onUpdate,
            onStatusUpdate,
            onClose
        } = this.props;
        const { job_timezone } = job;
        const { job_stage_type } = this.getJobStage(job, job_stage_id);
        const { hasErrors, candidates: validatedCandidates } = validateCandidates(candidates, job_timezone);

        if (hasErrors) {
            await onCandidateChange(job_stage_id, validatedCandidates);
            return false;
        }

        // TASK: call the upcoming API which will allow adding new candidates
        const { job_id } = job;
        const new_candidates = validatedCandidates
            .filter(({candidate_id}) => candidate_id !== parseInt(candidate_id)) // it has "temp_" at the start
            .map((candidate) => ({
                ...candidate,
                deadline: candidate.deadline === "" ? null : candidate.deadline,
                candidate_id: null
            }));

        this.setState({
            is_submitting: true
        });

        const payload = {
            candidates: new_candidates
        };

        addCandidates(job_id, job_stage_type, job_stage_id, payload).then((response) => {
            // replace temporary records with returned responses
            const updatedCandidates = candidates.filter(({candidate_id}) => candidate_id === parseInt(candidate_id)).concat(response);

            onCandidateChange(job_stage_id, updatedCandidates);

            onStatusUpdate(`Candidate${new_candidates.length > 1 ? 's have': ' has'} been successfully added to the job.`);

            this.setState({
                is_submitting: false
            });

            onUpdate?.();
            onClose?.();
        });
    }

    render() {
        const {
            job,
            job_stage_id,
            hasInvitationEmail,
            candidates,
            integrationProvider,
            is_deadline_expired,
            onCandidateChange,
            onRefresh
        } = this.props;

        const { is_submitting } = this.state;

        const job_stage = this.getJobStage(job, job_stage_id);
        const new_candidates = candidates.filter(({candidate_id}) => candidate_id !== parseInt(candidate_id)); // it has "temp_" at the start

        const hasErrors = new_candidates.filter(({isEmailError}) => isEmailError).length > 0;

        return (
            <div className={`candidates-invite`}>
                <div className={`header`}>Invite Candidates</div>
                {is_deadline_expired && (
                    <div className={`closed`}>Since the deadline for this job has passed, you need to select a candidate-specific deadline for any new candidates.</div>
                )}
                {!hasInvitationEmail && <NoInvitationEmailMessage />}
                <Candidates
                    job={job}
                    job_stage={job_stage}
                    candidates={candidates}
                    showOnlyNewCandidates={true}
                    onCandidateChange={onCandidateChange}
                    onRefresh={onRefresh}
                    integrationProviders={[integrationProvider]}
                />
                {!!new_candidates.length && (
                    <div className={`candidates-add`}>
                        <div className={`candidates-add-text`}>
                            You are about to add {new_candidates.length} candidate{new_candidates.length > 1 ? 's' : ''}
                        </div>
                        <button className={`candidates-add-button react-button ${hasErrors || is_submitting ? 'disabled': 'active'}`}
                                onClick={hasErrors || is_submitting ? null : this.addNewCandidates}>
                            {is_submitting ? 'Inviting...': 'Invite Now'}
                        </button>
                    </div>
                )}
            </div>
        );
    }
}

CandidatesInvite.propTypes = {
    job: PropTypes.object,
    job_stage_id: PropTypes.number,
    hasInvitationEmail: PropTypes.bool,
    candidates: PropTypes.array,
    integrationProvider: PropTypes.string,
    is_deadline_expired: PropTypes.bool,
    onCandidateChange: PropTypes.func,
    onUpdate: PropTypes.func,
    onRefresh: PropTypes.func,
    onStatusUpdate: PropTypes.func,
    onClose: PropTypes.func
};

CandidatesInvite.defaultProps = {
    job: {},
    job_stage_id: null,
    hasInvitationEmail: false,
    candidates: [],
    integrationProvider: "",
    is_deadline_expired: false,
    onCandidateChange: null,
    onUpdate: null,
    onRefresh: null,
    onStatusUpdate: null,
    onClose: null
};

export default CandidatesInvite;