import React from 'react';
import PropTypes from 'prop-types';
import {
    eDropdownItemType,
    eDropdownTypes,
    eInterviewTerminologies,
    isValidEmail,
    KEYBOARDKEYCODES,
    objectToDropdownItems
} from "../../utils";
import {IconCross} from "../Icons";
import {DropdownItem} from "./DropdownItem";
import {forEach, sortBy} from "lodash";
import {getStaff, getTimezones} from "../../api";

import './style.scss';


const ENABLE_EMAIL_PHRASE = true;
const ENABLE_WORD_PHRASE = true;
const ENABLE_DUPLICATES = false;

class TypedDropdown extends React.Component {
    constructor(props) {
        super(props);

        this.getTypedDropdownSelectionLabelClassNames = this.getTypedDropdownSelectionLabelClassNames.bind(this);
        this.getTypedDropdownSelectionLabel = this.getTypedDropdownSelectionLabel.bind(this);
        this.getTypedDropdownSelectionValueClassNames = this.getTypedDropdownSelectionValueClassNames.bind(this);

        this.toggleSelectionFocus = this.toggleSelectionFocus.bind(this);
        this.isEmpty = this.isEmpty.bind(this);
        this.isInputActive = this.isInputActive.bind(this);
        this.focusInputField = this.focusInputField.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.updateSuggestions = this.updateSuggestions.bind(this);
        this.getSuggestions = this.getSuggestions.bind(this);
        this.getSuggestionsForStaff = this.getSuggestionsForStaff.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.scrollSuggestions = this.scrollSuggestions.bind(this);
        this.selectSuggestion = this.selectSuggestion.bind(this);
        this.addPhrase = this.addPhrase.bind(this);
        this.deleteSelection = this.deleteSelection.bind(this);
        this.getValueToSave = this.getValueToSave.bind(this);
        this.updateParentValue = this.updateParentValue.bind(this);

        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.loadInitialValue = this.loadInitialValue.bind(this);

        this.state = {
            id: parseInt("" + Math.random() * 10000, 0),
            isSelectionFocused: false,
            value: '',
            selections: [],
            suggestions: [],
            selectedSuggestionIndex: -1,

            list: []
        }
    }

    getTypedDropdownSelectionLabelClassNames() {
        const { required } = this.props;
        let returnValue = 'selection-label';
        if (required && this.isEmpty()) {
            returnValue += ' error';
        }
        return returnValue;
    }

    getTypedDropdownSelectionLabel() {
        const { label, required } = this.props;
        return <span>{label}{required ? <sup>*</sup> : ''}</span>;
    }

    getTypedDropdownSelectionValueClassNames() {
        const { isSelectionFocused } = this.state;
        const { required, isMultipleSelection } = this.props;

        let returnValue = 'selection-value';

        if (isMultipleSelection) {
            returnValue += ' multiple-selection';
        } else {
            returnValue += ' single-selection'
        }

        if (isSelectionFocused) {
            returnValue += ' focused';
        }

        if (required && this.isEmpty()) {
            returnValue += ' error';
        }

        return returnValue;
    }

    toggleSelectionFocus(value) {
        this.setState({
            isSelectionFocused: value
        }, () => !!value && this.updateSuggestions());
    }
    
    isEmpty() {
        const { selections, value } = this.state;
        return !(selections?.length) && value !== null;
    }

    isInputActive() {
        const { selections } = this.state;
        const { isMultipleSelection } = this.props;

        return isMultipleSelection || !(selections?.length);
    }

    focusInputField() {
        this.inputField?.focus();
    }

    onInputChange(e) {
        const { target: {id, value} } = e;

        this.setState({
            [id]: value
        }, this.updateSuggestions);
    }

    updateSuggestions() {
        const { value } = this.state;
        const { dropdownType, showDropdownOnInitialFocus } = this.props;

        const updatedState = {};

        const values = value.split(" ");
        const searchedValue = values[values.length - 1];

        if (!searchedValue && !showDropdownOnInitialFocus) {
            updatedState.suggestions = [];
        } else {
            const { selections, list } = this.state;

            if (list.length) {
                if (dropdownType === eDropdownTypes.STAFF) {
                    const selection_ids = selections.map(({staff_id}) => staff_id);
                    updatedState.suggestions = this.getSuggestionsForStaff(list, searchedValue, selection_ids);
                } else {
                    updatedState.suggestions = this.getSuggestions(list, searchedValue);
                }
            }
        }

        updatedState.selectedSuggestionIndex = updatedState.suggestions?.length ? 0 : -1;

        this.setState(updatedState);
    }

    handleSelection(e) {
        const { keyCode } = e;
        let { suggestions, selectedSuggestionIndex } = this.state;

        if (!!suggestions.length) {
            // NAVIGATION
            if ([KEYBOARDKEYCODES.UP, KEYBOARDKEYCODES.DOWN].includes(keyCode)) {
                e.preventDefault();

                if (keyCode === KEYBOARDKEYCODES.UP) {
                    selectedSuggestionIndex-= 1;
                } else if (keyCode === KEYBOARDKEYCODES.DOWN) {
                    selectedSuggestionIndex+= 1;
                }

                const suggestionsLength = suggestions.length;
                const maxSuggestionIndex = suggestionsLength - 1;

                if (selectedSuggestionIndex < 0) {
                    selectedSuggestionIndex = maxSuggestionIndex;
                } else if (selectedSuggestionIndex > maxSuggestionIndex) {
                    selectedSuggestionIndex = 0;
                }

                this.scrollSuggestions(selectedSuggestionIndex, maxSuggestionIndex);

                this.setState({
                    selectedSuggestionIndex
                });
                return false;
            }
        }
    }

    handleSubmit(e) {
        const { keyCode } = e;
        const { value, selectedSuggestionIndex } = this.state;
        if ([KEYBOARDKEYCODES.ENTER, KEYBOARDKEYCODES.SPACE_BAR, KEYBOARDKEYCODES.COMMA].includes(keyCode)) {
            // SELECTION
            e.preventDefault();

            if (selectedSuggestionIndex >= 0) {
                this.selectSuggestion(selectedSuggestionIndex);
            } else {
                this.addPhrase(value);
            }

            return false;
        }
    }

    handleDelete(e) {
        const { keyCode } = e;
        const { value, selections } = this.state;

        // only delete when there are no in-progress text
        if (!value) {
            // DELETION
            if ([KEYBOARDKEYCODES.BACKSPACE, KEYBOARDKEYCODES.DELETE].includes(keyCode)) {
                e.preventDefault();

                this.deleteSelection(selections.length - 1, true);
                return false;
            }
        }
    }

    getSuggestionsForStaff(list, searchedValue, selectionIds) {
        return list
            .filter(
                (({staff_id}) => !selectionIds.includes(staff_id))
            )
            .filter(
                !searchedValue ? () => true :
                    ({first_name, last_name}) =>
                        `${first_name} ${last_name}`.trim().toLowerCase().includes(searchedValue.toLowerCase())
            );
    }

    getSuggestions(list, searchedValue) {
        if (!searchedValue) {
            return list;
        }
        return list.filter(({label}) => label.trim().toLowerCase().includes(searchedValue.toLowerCase()));
    }

    onKeyDown(e) {
        this.handleSelection(e);
        this.handleSubmit(e);
        this.handleDelete(e);
    }

    scrollSuggestions(index, max_index) {
        const STEP_SIZE = 50;
        const WINDOW_SIZE = 3 * STEP_SIZE;
        const el = this.suggestionsElement;

        const destinationTop = (index * STEP_SIZE) - WINDOW_SIZE;
        const maxTop = (max_index * STEP_SIZE);

        let top;

        if (destinationTop < 0) {
            top = 0;
        } else if (destinationTop > maxTop) {
            top = maxTop;
        } else {
            top = destinationTop;
        }

        console.log(destinationTop, maxTop, top);

        el.scroll({top});
    }

    selectSuggestion(suggestionIndex) {
        const { selections, suggestions } = this.state;
        const { isMultipleSelection } = this.props;
        selections.push(suggestions[suggestionIndex]);

        this.setState({
            value: '',
            selections,
            suggestions: [],
            selectedSuggestionIndex: -1
        }, this.updateParentValue);

        if (isMultipleSelection) {
            this.focusInputField();
        }
        return false;
    }

    addPhrase(word) {
        const { selections } = this.state;

        if (ENABLE_EMAIL_PHRASE && isValidEmail(word)) {
            if (
                ENABLE_DUPLICATES ||
                !selections.map(({email}) => email).includes(word)
            ) {
                selections.push({email: word});
            } else {
                return false;
            }
        } else if (ENABLE_WORD_PHRASE && !!word) {
            selections.push({word});
        } else {
            return false;
        }

        this.setState({
            value: '',
            selections,
            suggestions: []
        }, this.updateParentValue);

        this.focusInputField();
    }

    deleteSelection(selectionIndex, keepValue = false) {
        const { selections } = this.state;
        const { showDropdownOnInitialFocus } = this.props;
        const removedSelection = selections.splice(selectionIndex, 1)[0];

        this.setState({
            selections
        }, () => {
            this.updateParentValue();
            this.focusInputField();

            if (showDropdownOnInitialFocus) {
                this.updateSuggestions();
            }
        });

        if (keepValue && !!removedSelection && !removedSelection.staff_id) {
            this.setState({
                value: removedSelection.email
            });
        }

        return false;
    }

    updateParentValue() {
        const { selections } = this.state;
        const { id, dropdownType, onUpdate } = this.props;

        let values;

        if (dropdownType === eDropdownTypes.STAFF) {
            values = {};
            values.email = selections.filter(({email}) => !!email).map(({email}) => email).join();
            values.areRecipientsValid = !!selections.length && !selections.filter(({word}) => !!word).length;
            onUpdate?.(values);
        } else {
            values = selections.map(({value}) => value).join();
            onUpdate?.({target: {id, value: values}});
        }
    }

    getValueToSave() {
        const { selections } = this.state;

        let returnValue = '';

        forEach(selections, ({first_name = '', last_name = '', email = '', word = ''}) => {
            const full_name = `${first_name} ${last_name}`.trim();

            if (!!full_name) {
                returnValue += `{{${full_name}}} `;
            } else if (!!email) {
                returnValue += `{{${email}}} `;
            } else {
                returnValue += `${word} `;
            }
        });

        return returnValue.trim();
    }

    handleClickOutside(e) {
        const { id } = this.state;
        let targetClassName = e.target.className;
        if (typeof targetClassName === 'object') {
            targetClassName = targetClassName.baseVal;
        }
        if (!targetClassName ||
            (
                // !!targetClassName &&
                typeof targetClassName === "string" &&
                !targetClassName.includes("selection-value") &&
                !targetClassName.includes(`dropdown-overlay-${id}`) &&
                !targetClassName.includes('suggestions') &&
                !targetClassName.includes("selections") &&
                !targetClassName.includes("icon-cross")
            )
        ) {
            this.setState({
                suggestions: []
            });
        }
    }

    loadInitialValue() {
        const { dropdownType, initialValue } = this.props;

        if (!!initialValue) {
            const { list } = this.state;
            if (dropdownType === eDropdownTypes.STAFF) {
                // TASK: implement
            } else {
                const selectedIndex = list.findIndex(({value}) => value === initialValue);

                if (selectedIndex >= 0) {
                    const selectedItem = list[selectedIndex];
                    this.setState({
                        selections: [selectedItem]
                    });
                }
            }
        }
    }

    async componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);

        const { dropdownType, initialList, initialValue } = this.props;

        const updatedState = {};

        if (dropdownType === eDropdownTypes.STAFF) {
            const staffRecords = await getStaff();
            updatedState.list = sortBy(staffRecords, ['first_name', 'last_name']);
        } else if (dropdownType === eDropdownTypes.TIMEZONES) {
            const timezones = await getTimezones();
            if (!timezones.error) {
                updatedState.list = timezones.map(({timezone_name, utc_offset}, index) => {
                    const isUTCPositive = utc_offset.charAt(0) !== "-";
                    return {
                        timezone_id: index,
                        label: `${timezone_name} (UTC ${isUTCPositive ? '+': ''}${utc_offset})`,
                        value: timezone_name
                    }
                });
            }
        } else if (dropdownType === eDropdownTypes.INTERVIEW_TERMINOLOGY) {
            updatedState.list = objectToDropdownItems(eInterviewTerminologies);
        } else {
            updatedState.list = objectToDropdownItems(initialList);
        }

        this.setState(updatedState, () => {
            if (!!initialValue) {
                this.loadInitialValue();
            }
        });
    }

    componentDidUpdate(prevProps) {
        const { initialValue: prevInitialValue } = prevProps;
        const { initialValue } = this.props;

        if (initialValue !== prevInitialValue) {
            this.loadInitialValue();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    render() {
        const { id, value, selections, suggestions, selectedSuggestionIndex } = this.state;
        const { classes, dropdownType, helperText, placeholder } = this.props;

        return (
            <div className={`typed-dropdown ${classes}`}>
                <div className={this.getTypedDropdownSelectionLabelClassNames()}>{this.getTypedDropdownSelectionLabel()}</div>
                {helperText && (
                    <span className={`helper-text`}>{helperText}</span>
                )}
                <div
                    className={this.getTypedDropdownSelectionValueClassNames()}
                    onClick={this.focusInputField}
                >
                    {selections.map((item, index) => {
                        if (dropdownType === eDropdownTypes.STAFF) {
                            const { first_name = '', last_name = '', email, word = '' } = item;
                            const full_name = `${first_name} ${last_name}`.trim();
                            const is_email = !full_name && !!email;

                            if (full_name || is_email) {
                                return (
                                    <div className={`selection ${is_email ? 'is_email' : ''}`}>
                                        {full_name || email}
                                        <span className={`clear`} onClick={() => this.deleteSelection(index)}>
                                            {IconCross}
                                        </span>
                                    </div>
                                );
                            } else {
                                return (
                                    <div className={`selection is_error`}>
                                        {word}
                                        <span className={`clear`} onClick={() => this.deleteSelection(index)}>
                                            {IconCross}
                                        </span>
                                    </div>
                                );
                            }
                        } else {
                            const { label } = item;

                            return (
                                <div className={`selection`}>
                                    <span className={`selection-name`}>{label}</span>
                                    <span className={`clear`} onClick={() => this.deleteSelection(index)}>
                                        {IconCross}
                                    </span>
                                </div>
                            );
                        }
                    })}
                    {this.isInputActive() && (
                        <input
                            ref={(i) => this.inputField = i}
                            className={`value`}
                            id={`value`}
                            type={'text'}
                            value={value}
                            placeholder={placeholder}
                            onFocus={() => this.toggleSelectionFocus(true)}
                            onBlur={() => this.toggleSelectionFocus(false)}
                            onChange={this.onInputChange}
                            onKeyDown={this.onKeyDown}
                        />
                    )}
                </div>

                {!!suggestions?.length && (
                    <div className={`suggestions ${dropdownType}`} ref={(i) => this.suggestionsElement = i}>
                        {suggestions.map((item, index) => {
                            if (dropdownType === eDropdownTypes.STAFF) {
                                const { staff_id, first_name, last_name, position, picture_url } = item;
                                const avatar = { first_name, last_name, picture_url };
                                const firstLine = `${first_name} ${last_name}`.trim();
                                const secondLine = position;
                                return (
                                    <div
                                        key={`suggestion-${staff_id}`}
                                        className={`suggestion ${index === selectedSuggestionIndex ? 'selected' : ''}`}
                                        onClick={() => this.selectSuggestion(index)}
                                    >
                                        <DropdownItem
                                            id={id}
                                            type={eDropdownItemType.SELECTION}
                                            item={{avatar, firstLine, secondLine}}
                                        />
                                    </div>
                                );
                            } else {
                                const { timezone_id, label } = item;
                                return (
                                    <div
                                        id={`suggestion-${timezone_id || index}`}
                                        key={`suggestion-${timezone_id || index}`}
                                        className={`suggestion ${index === selectedSuggestionIndex ? 'selected' : ''}`}
                                        onClick={() => this.selectSuggestion(index)}
                                    >
                                        <DropdownItem
                                            id={id}
                                            type={eDropdownItemType.SELECTION}
                                            item={{firstLine: label}}
                                        />
                                    </div>
                                );
                            }
                        })}
                    </div>
                )}

                {/*<span>{this.getValueToSave()}</span>*/}
            </div>
        )
    }
}

TypedDropdown.propTypes = {
    id: PropTypes.string,
    classes: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    dropdownType: PropTypes.oneOf([eDropdownTypes]),
    required: PropTypes.bool,
    initialList: PropTypes.object,
    initialValue: PropTypes.string,
    placeholder: PropTypes.string,
    showDropdownOnInitialFocus: PropTypes.bool,
    isMultipleSelection: PropTypes.bool,
    onUpdate: PropTypes.func
}

TypedDropdown.defaultProps = {
    id: "",
    classes: "",
    label: "",
    dropdownType: null,
    required: false,
    initialList: [],
    initialValue: null,
    placeholder: "",
    showDropdownOnInitialFocus: false,
    isMultipleSelection: false,
    onUpdate: null
}

export default TypedDropdown;
