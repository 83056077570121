import React from "react";
import PropTypes from 'prop-types';

import CompanyDetails from "./details";
import CompanyStaff from "./staff";
import Branding from "./branding";
import Reporting from './reporting';
import Marketplace from "../../components/Marketplace";
import SubNav from "../../components/SubNav";
import {eCompanyPages, objectToArray} from "../../utils";

import './style.scss';


const subNavMainItems = [{
    label: "Details",
    route: eCompanyPages.DETAILS
}, {
    label: "Branding",
    route: eCompanyPages.BRANDING
}, {
    label: "Staff",
    route: eCompanyPages.STAFF
}, {
    label: "Marketplace",
    route: eCompanyPages.MARKETPLACE
}, {
    label: "Reporting",
    route: eCompanyPages.REPORTING
}];



class CompanyPage extends React.Component {
    constructor(props) {
        super(props);

        this.renderRouteContent = this.renderRouteContent.bind(this);
    }


    renderRouteContent(activeRoute, _is_alcami_staff, urlFor) {
        switch (activeRoute) {
            case eCompanyPages.DETAILS: {
                return (<CompanyDetails {...this.props.companyDetails} />);
            }
            case eCompanyPages.BRANDING:
                return (<Branding />);
            case eCompanyPages.STAFF:
                return (<CompanyStaff activeRoute={activeRoute} urlFor={urlFor} subNavMainItems={subNavMainItems} />);
            case eCompanyPages.MARKETPLACE:
                return (<Marketplace />);
            case eCompanyPages.REPORTING:
                return (<Reporting />);
            default:
                throw Error('Unexpected route "' + activeRoute + '".');
        }
    }


    render() {
        const { activeRoute, is_alcami_staff, urlFor } = this.props;

        return (
            <div className="company">
                {(activeRoute !== 'company-staff') && (
                    /* Staff page renders its own subnav, to add the 'Add New' button. */
                    <SubNav activeRoute={activeRoute} urlFor={urlFor} mainItems={subNavMainItems} />
                )}

                {this.renderRouteContent(activeRoute, is_alcami_staff, urlFor)}
            </div>
        );
    }
}

CompanyPage.propTypes = {
    activeRoute: PropTypes.oneOf(objectToArray(eCompanyPages)).isRequired,
    is_alcami_staff: PropTypes.bool.isRequired,
    urlFor: PropTypes.func.isRequired,
    companyDetails: PropTypes.object.isRequired
};

export default CompanyPage;
