import React from "react";
import Avatar from "../Avatar";
import * as PropTypes from "prop-types";
import { IconShowVideo, IconHideVideo, IconDisguiseVoice, IconSettings, IconTrash } from "../Icons";
import EvaluatorPreferences from "../EvaluatorPreferences";
import { defaultEvaluationOptions } from "../../utils";
import Tooltip from "../Tooltip";

import './style.scss';


class Evaluators extends React.Component {
    constructor(props) {
        super(props);

        this.applicableQuestionsDisplay = this.applicableQuestionsDisplay.bind(this);
        this.onEditOpen = this.onEditOpen.bind(this);
        this.onEditClose = this.onEditClose.bind(this);

        this.getEvaluatorPreferencesDetails = this.getEvaluatorPreferencesDetails.bind(this);
        this.onEvaluatorPreferenceUpdate = this.onEvaluatorPreferenceUpdate.bind(this);

        this.state = {
            editedEvaluatorIds: []
        };
    }

    applicableQuestionsDisplay(evaluatorQuestions) {
        const { questions } = this.props;
        if (!evaluatorQuestions) {
            return "Questions: All";
        }

        const jobQuestions = questions.filter(({interview_step_type}) => (interview_step_type === 'question'));

        const outcome = [];

        evaluatorQuestions.forEach((questionId) => {
            const questionIndex = jobQuestions.findIndex(function(question) {
                return question.job_interview_step_id === questionId;
            });
            if (questionIndex >= 0) {
                outcome.push(questionIndex + 1);
            }
        });

        if (outcome.length === jobQuestions.length) {
            // all are matching
            return "Questions: All";
        } else if (outcome.length === 0) {
            // none are matching
            return (
                <span className='error'>Questions: None</span>
            );
        } else {
            return "Questions: " + outcome.sort().join(", ");
        }
    }

    onEditOpen(_e, staff_id) {
        this.setState({
            editedEvaluatorIds: [staff_id]
        });
    }

    onEditClose() {
        this.setState({
            editedEvaluatorIds: []
        });
    }

    getEvaluatorPreferencesDetails() {
        const { editedEvaluatorIds } = this.state;
        const { evaluators, questions } = this.props;

        const selectedEvaluators = evaluators.filter(({staff_id}) => editedEvaluatorIds.includes(staff_id)).map(
            ({first_name, last_name, evaluation_options = defaultEvaluationOptions, ...rest}) => {
                return {first_name, last_name, evaluation_options, ...rest};
            }
        );

        return {
            evaluators: selectedEvaluators,
            questions
        };
    }

    onEvaluatorPreferenceUpdate(editedEvaluators, questions, evaluation_options) {
        const { evaluators, onUpdate } = this.props;

        const editedEvaluatorIds = editedEvaluators.map(({staff_id}) => (staff_id));

        const newEvaluators = evaluators.map((evaluator) => {
            const { staff_id } = evaluator;
            if (editedEvaluatorIds.includes(staff_id)) {
                return {
                    ...evaluator,
                    questions,
                    evaluation_options
                };
            } else {
                return evaluator;
            }
        });

        if(!!onUpdate) {
            onUpdate({
                target: {
                    id: "evaluators",
                    value: newEvaluators
                }
            })
        }
    }

    render() {
        const { classes, evaluators, editable = false, removable = false, onRemove } = this.props;
        const { editedEvaluatorIds } = this.state;

        return (
            <div id="react-evaluator" className={`react-evaluators ${classes}`}>
                {evaluators && evaluators.map((evaluator) => {
                    const {
                        staff_id,
                        first_name,
                        last_name,
                        position,
                        questions,
                        evaluation_options = {}
                    } = evaluator;

                    const { hide_video = false, hide_personal_details = false, disguise_voice = false, hide_feedback = false } = evaluation_options;

                    const playbackIcon = disguise_voice ? IconDisguiseVoice :
                        hide_video ? IconHideVideo :
                            IconShowVideo;

                    const playbackTooltip = disguise_voice ? "Disguised audio playback" :
                        hide_video ? "Audio playback" :
                            "Video playback";

                    const badgeNumber = (hide_video ? 1 : 0) +
                        (hide_personal_details ? 1 : 0) +
                        (disguise_voice ? 1 : 0) +
                        (hide_feedback ? 1 : 0);

                    const tooltipDescription = (
                        <React.Fragment>
                            {hide_video && <span>Hide video</span>}
                            {hide_personal_details && <span>Hide personal details</span>}
                            {disguise_voice && <span>Disguise voice</span>}
                            {hide_feedback && <span>Hide feedback</span>}
                            {editable && !hide_video && !hide_personal_details && !disguise_voice && !hide_feedback && <span>Edit evaluation options</span>}
                        </React.Fragment>
                    );

                    const isRemovable = removable && !!onRemove;

                    return (
                        <div key={staff_id} className={`evaluator ${!!position ? "has-position": ""} ${isRemovable ? "removable": ""}`}>
                            <div className="newAvatar"><Avatar data={evaluator} /></div>
                            <div className="names">{first_name} {last_name}</div>
                            {!!position && (
                                <div className="position">{position}</div>
                            )}
                            <div className="questions">{this.applicableQuestionsDisplay(questions)}</div>
                            <div className="playback">
                                <Tooltip
                                    classes="playback-icon"
                                    item={playbackIcon}
                                    description={<span>{playbackTooltip}</span>}
                                />
                            </div>
                            <div className="options">
                                {(!!badgeNumber || editable) && (
                                    <button className={`link edit ${editable ? "editable" : ""}`} onClick={(e) => editable && this.onEditOpen(e, staff_id)}>
                                        <Tooltip
                                            classes={`tooltip lines-${badgeNumber}`}
                                            item={
                                                <div>
                                                    {IconSettings}
                                                    {!!badgeNumber && (<div className="badge">{badgeNumber}</div>)}
                                                </div>
                                            }
                                            description={tooltipDescription}
                                        />
                                    </button>
                                )}
                            </div>
                            {isRemovable && (<div className="remove" onClick={() => onRemove(staff_id)}>{IconTrash}</div>)}
                        </div>
                    );
                })}
                {!!editedEvaluatorIds.length && (
                    <EvaluatorPreferences details={this.getEvaluatorPreferencesDetails()} onUpdate={this.onEvaluatorPreferenceUpdate} onClose={this.onEditClose} />
                )}
            </div>
        );

    }
}

Evaluators.propTypes = {
    classes: PropTypes.string,
    evaluators: PropTypes.arrayOf(
        PropTypes.shape({
            staff_id: PropTypes.number,
            first_name: PropTypes.string,
            last_name: PropTypes.string,
            position: PropTypes.string,
            picture_url: PropTypes.string, // for avatar
            questions: PropTypes.arrayOf(PropTypes.number),
            evaluation_options: PropTypes.shape({
                hide_video: PropTypes.bool,
                hide_personal_details: PropTypes.bool,
                disguise_voice: PropTypes.bool,
                hide_feedback: PropTypes.bool
            }),
        })
    ),
    questions: PropTypes.arrayOf(
        PropTypes.shape({
            job_interview_step_id: PropTypes.number,
            image: PropTypes.string,
            display_text: PropTypes.string
        })
    ),
    editable: PropTypes.bool,
    removable: PropTypes.bool,
    onRemove: PropTypes.func,
    onUpdate: PropTypes.func
};

export default Evaluators;
