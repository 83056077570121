import React from "react";

import Container from "../../../components/Container";
import CustomColorPicker from "../../../components/Form/CustomColorPicker";
import {cloneBranding, getBrandings, updateBranding, deleteBranding} from "../../../api";
import CustomFileUpload, {eFileUploadType, eTooltipType} from "../../../components/Form/CustomFileUpload";
import CustomTextField from "../../../components/Form/CustomTextField";
import {
    asFormData,
    convertToRGBValues,
    eBrandingType,
    eBrandingItemType,
    eDropdownTypes,
    eStatusMessageStyle,
    eStatusMessageType,
    getValue,
    isAlcamiStaff,
    isValidColor
} from "../../../utils";
import BrandingPreview from "../../../components/BrandingPreview";
import CustomToggleField, { eToggleSize } from "../../../components/Form/CustomToggleField";
import Dropdown from "../../../components/Dropdown";
import StatusMessage from "../../../components/StatusMessage";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { isEqual, sortBy } from "lodash";
import { IconPencil, IconTrash } from "../../../components/Icons";
import Loader from "../../../components/Loader";

import './style.scss';


const eDialogTypes = {
    SAVE_AS: "save-as",
    EDIT: "edit"
};

class Branding extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            brandings: [],
            currentBranding: null,
            currentBrandingSnapshot: null,
            isEditMode: false,
            isColorPickerShown: false,
            isAdvancedSettingsShown: false,
            statusMessage: {
                type: null,
                message: null,
                reset: null
            },
            confirmDialogProps: undefined
        };

        this.loadData = this.loadData.bind(this);
        this.getBrandingsAsDropdown = this.getBrandingsAsDropdown.bind(this);
        this.selectBranding = this.selectBranding.bind(this);
        this.selectDefaultBranding = this.selectDefaultBranding.bind(this);
        this.getBranding = this.getBranding.bind(this);
        this.prepareBrandingProperties = this.prepareBrandingProperties.bind(this);
        this.getBrandingPropertyValue = this.getBrandingPropertyValue.bind(this);
        this.enableEditMode = this.enableEditMode.bind(this);
        this.cancelEditing = this.cancelEditing.bind(this);
        this.updateCurrentBranding = this.updateCurrentBranding.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.getUpdatedProperties = this.getUpdatedProperties.bind(this);
        this.getUpdatedBrandingPropertyForImage = this.getUpdatedBrandingPropertyForImage.bind(this);
        this.toggleColorPicker = this.toggleColorPicker.bind(this);
        this.hasBrandingChanged = this.hasBrandingChanged.bind(this);
        this.onSaveAs = this.onSaveAs.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.confirmEditBranding = this.confirmEditBranding.bind(this);
        this.confirmDeleteBranding = this.confirmDeleteBranding.bind(this);
        this.saveBranding = this.saveBranding.bind(this);
        this.updateStatusMessage = this.updateStatusMessage.bind(this);
        this.cancelConfirmDialog = this.cancelConfirmDialog.bind(this);
        this.toggleAdvancedSettings = this.toggleAdvancedSettings.bind(this);
        this.updateDialogProps = this.updateDialogProps.bind(this);

        this.getDefaultBrandingToggleLabel = this.getDefaultBrandingToggleLabel.bind(this);
        this.getDefaultBrandingIsDisabled = this.getDefaultBrandingIsDisabled.bind(this);
        this.getDefaultBrandingOnChange = this.getDefaultBrandingOnChange.bind(this);
        this.getMainBrandingItem = this.getMainBrandingItem.bind(this);
    }

    loadData() {
        getBrandings().then((brandings) => {
            const currentBranding =
                (
                    brandings.find(({is_default_for_new_job}) => !!is_default_for_new_job) ||
                    brandings[0] ||
                    { branding_id: null }
                );

            currentBranding[eBrandingType.PROPERTIES] = this.prepareBrandingProperties(currentBranding[eBrandingType.PROPERTIES]);

            this.setState({
                brandings,
                currentBranding,
                currentBrandingSnapshot: currentBranding
            });
        });
    }

    getBrandingsAsDropdown() {
        const { brandings = [] } = this.state;

        return sortBy(brandings, ["branding_name"]).map(({branding_id, branding_name, is_default_for_new_job}) => (
            {
                name: branding_name,
                value: branding_id,
                is_default: is_default_for_new_job
            }
        ));
    }

    selectDefaultBranding() {
        const { brandings } = this.state;
        const { branding_id } = brandings.find(({is_default_for_new_job}) => !!is_default_for_new_job);

        this.selectBranding(branding_id);
    }

    selectBranding(_name, id) {
        const newBranding = this.getBranding(id);
        this.setState({
            currentBranding: newBranding,
            currentBrandingSnapshot: newBranding
        });
    }

    getBranding(id) {
        const { brandings } = this.state;
        const selectedBranding = brandings.find((branding) => branding[eBrandingType.ID] === id);
        selectedBranding[eBrandingType.PROPERTIES] = this.prepareBrandingProperties(selectedBranding[eBrandingType.PROPERTIES]);

        return selectedBranding;
    }

    prepareBrandingProperties(properties = []) {
        return properties.map((property) => {
            const { name } = property;

            if (name === "--color-5") {
                return {...property, value: "black"};
            } else if (name === "--color-6") {
                return {...property, value: "white"};
            } else if (name.includes("-rgb")) {
                let value;
                if (name === "--color-5-rgb") {
                    value = convertToRGBValues("black");
                } else if (name === "--color-6-rgb") {
                    value = convertToRGBValues("white");
                } else {
                    const colorName = name.replace(/-rgb/g, "");
                    const color = properties.find(({name: n}) => n === colorName).value;
                    value = convertToRGBValues(color);
                }
                return {...property, value};
            } else {
                return property;
            }
        });
    }

    getBrandingPropertyValue(name) {
        const { currentBranding : { properties } } = this.state;
        const property = properties.find(({name: id}) => {
            return id === name;
        }) || {};

        return property.value;
    }

    enableEditMode() {
        this.setState({
            isEditMode: true,
            isColorPickerShown: true
        });
    }

    cancelEditing() {
        const { currentBrandingSnapshot } = this.state;
        this.setState({
           isEditMode: false,
           isColorPickerShown: false,
           currentBranding: currentBrandingSnapshot
        });
    }

    updateCurrentBranding(updatedBranding, callback = null) {
        this.setState({
            currentBranding: updatedBranding
        }, () => !!callback && callback());

    }

    getLabelName(key) {
        const labelNames = {
            "--welcome-logo-input": "Start Page",
            "--interview-logo-input": "Interview Page",
            "--welcome-background-image-input": "BG Image",
            "--welcome-background-position": "Position"
        };

        return !!labelNames[key] ?
            labelNames[key] :
            key.replace(/-|file|input/g, " ").trim();
    }

    getHelperText(key) {
        const dictionary = {
            "--welcome-details-link-color": "Deprecated: now using --link-color",
            "--welcome-logo-margin-correction-factor": "0 = no adjustment 1 = logo flush with top of page",
            "--breadcrumbs-active-background-color": "Set this for a button effect on the active breadcrumb",
            "--breadcrumbs-active-label-padding": "If --BREADCRUMBS-ACTIVE-BACKGROUND-COLOR is set, you probably want to set a value here, too."
        };

        return dictionary[key] || "";
    }

    isPropertyDisabled(key) {
        const dictionary = {
            "--welcome-details-link-color": true
        };

        return dictionary[key] || false;
    }

    onInputChange(e) {
        const { id, value, files } = e.target;
        const { currentBranding } = this.state;
        if ([eBrandingType.NAME].includes(id)) {
            const updatedBranding = {
                ...currentBranding,
                [id]: value
            };

            this.updateCurrentBranding(updatedBranding);
        } else {
            const isImageInput = ["--welcome-logo-input" , "--interview-logo-input", "--welcome-background-image-input"].includes(id);

            let updatedStyleId = id;
            const { properties } = currentBranding;

            let updatedProperties = this.getUpdatedProperties(properties, id, value);

            if (isImageInput) {
                const file = files[0];

                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    const fileId = `${id}`.replace(/-input/g, "") + `-${eBrandingItemType.IMAGE_FILE}`;

                    updatedProperties = updatedProperties.filter(({name}) => name !== fileId);
                    updatedProperties.push({ name: fileId, value: file });

                    this.updateCurrentBranding({
                        ...currentBranding,
                        properties: updatedProperties
                    });
                });
                reader.readAsDataURL(file);

                updatedStyleId = `${id}`.replace(/-input/g, "");
                updatedProperties = updatedProperties.filter(({name}) => name !== updatedStyleId);
                updatedProperties.push({ name: updatedStyleId, value: URL.createObjectURL(file) });
            }

            const updatedBranding = {
                ...currentBranding,
                properties: updatedProperties
            };

            this.updateCurrentBranding(updatedBranding);
        }
    }

    getUpdatedProperties(properties, id, value) {
        const doesPropertyExist = properties.find(({name}) => name === id);
        const isMainColorInput = ["--color-1", "--color-2", "--color-3", "--color-4", "--color-5", "--color-6"].includes(id);

        let updatedProperties = properties;

        if (doesPropertyExist) {
            updatedProperties = updatedProperties.map((property) => {
                const returnValue = {...property};

                const { name } = property;
                if (name === id) {
                    returnValue.value = value;
                }
                if (name === `${id}-rgb` && isValidColor(value) && isMainColorInput) {
                    returnValue.value = convertToRGBValues(value);
                }

                return returnValue;
            })
        } else {
            // new property
            updatedProperties.push({name: id, value});
        }

        return updatedProperties;
    }

    getUpdatedBrandingPropertyForImage(property, id, value) {
        if (property.name === id) {
            return {
                ...property,
                value: `multipart-file:${value}`
            }
        }

        return property;
    }

    toggleColorPicker() {
        const { isColorPickerShown } = this.state;

        this.setState({
            isColorPickerShown: !isColorPickerShown
        });
    }

    hasBrandingChanged() {
        const { currentBranding, currentBrandingSnapshot } = this.state;
        return !isEqual(currentBrandingSnapshot, currentBranding);
    }

    onSaveAs() {
        const { brandings, currentBranding : { branding_name } } = this.state;
        const brandingNames = brandings.map(({branding_name: n}) => n);

        let newBrandingName = branding_name;

        while (brandingNames.includes(newBrandingName)) {
            newBrandingName += " Copy";
        }

        this.setState({
            confirmDialogProps: {
                type: eDialogTypes.SAVE_AS,
                title: "Save Branding As...",
                message: "",
                originalBrandingName: "",
                newBrandingName: newBrandingName,
                isDefaultBranding: false,
                helperText: "",
                actions: [
                    {
                        label: "Cancel",
                        classes: "button-link",
                        onClick: () => this.cancelConfirmDialog()
                    },
                    {
                        label: "Save",
                        classes: "info",
                        disabled: false,
                        onClick: () => this.saveBranding(true)
                    }
                ]
            }
        })
    }

    cancelConfirmDialog() {
        this.setState({
            confirmDialogProps: undefined
        });
    }

    toggleAdvancedSettings() {
        const { isAdvancedSettingsShown } = this.state;
        this.setState({
            isAdvancedSettingsShown: !isAdvancedSettingsShown
        });
    }

    updateDialogProps(e) {
        const { id, value } = e.target;
        const {brandings, confirmDialogProps} = this.state;

        const updatedDialogProps = {...confirmDialogProps};

        // update the value
        updatedDialogProps[id] = value;

        // update everything else (helper text and button disabled status)
        const { type, originalBrandingName, newBrandingName, isDefaultBranding, actions : updatedDialogActions } = updatedDialogProps;

        let isSubmittable;

        const brandingNames = brandings
            .filter(({branding_name}) => branding_name !== originalBrandingName)
            .map(({branding_name}) => branding_name);

        const isBrandingNameUnique = !brandingNames.includes(newBrandingName.trim());
        const isBrandingNameValid = !!(newBrandingName.trim()) && isBrandingNameUnique;
        const helperText = !isBrandingNameUnique ? "Branding name already exists." : "";

        if (type === eDialogTypes.SAVE_AS) {
            isSubmittable = isBrandingNameValid;
        } else if (type === eDialogTypes.EDIT) {
            const { currentBranding: { is_default_for_new_job } } = this.state;
            isSubmittable = isBrandingNameValid && (newBrandingName !== originalBrandingName || isDefaultBranding !== is_default_for_new_job);
        }

        const proceedAction = updatedDialogActions.pop();
        proceedAction.disabled = !isSubmittable;
        updatedDialogActions.push(proceedAction);

        this.setState({
            confirmDialogProps: {
                ...updatedDialogProps,
                helperText,
                actions: updatedDialogActions
            }
        });
    }

    getDefaultBrandingToggleLabel() {
        const { confirmDialogProps: { type }, currentBranding } = this.state;
        if (type === eDialogTypes.EDIT && currentBranding[eBrandingType.IS_DEFAULT]) {
            return "This is a default branding";
        }
        return "Set this as default branding";
    }

    getDefaultBrandingIsDisabled() {
        const { confirmDialogProps: { type }, currentBranding } = this.state;
        if (type === eDialogTypes.EDIT) {
            return currentBranding[eBrandingType.IS_DEFAULT];
        }
        return false;
    }

    getDefaultBrandingOnChange() {
        const { confirmDialogProps: { type, isDefaultBranding }, currentBranding } = this.state;
        if (type === eDialogTypes.EDIT && currentBranding[eBrandingType.IS_DEFAULT]) {
            return null;
        }
        return () => this.updateDialogProps({target: { id: "isDefaultBranding", value: !isDefaultBranding }})
    }

    getMainBrandingItem(id, value, type, group, tooltipType, index) {
        const label = this.getLabelName(id);

        let returnElement = [];

        if (!!group) {
            returnElement.push(
                <div key={index} className={`property-group`}>
                    {group}
                </div>
            );
        }

        if (type === eBrandingItemType.COLOR) {
            returnElement.push(
                <CustomColorPicker
                    key={id}
                    id={id}
                    showManualInput={true}
                    disableColorPicker={true}
                    label={label}
                    value={value}
                    onChange={this.onInputChange}
                />
            );
        } else if (type === eBrandingItemType.IMAGE_INPUT) {
            const preview = this.getBrandingPropertyValue(id.replace('-input', ''));
            returnElement.push(
                <CustomFileUpload
                    key={id}
                    id={id}
                    accept={eFileUploadType.IMAGE}
                    preview={preview}
                    label={label}
                    value={value}
                    tooltipType={tooltipType}
                    onChange={this.onInputChange}
                />
            );
        } else {
            returnElement = (
                <CustomTextField
                    key={id}
                    id={id}
                    label={label}
                    value={value}
                    onChange={this.onInputChange}
                />
            );
        }

        return returnElement;
    }

    onEdit() {
        const { currentBranding : { branding_name, is_default_for_new_job: isDefaultBranding } } = this.state;

        this.setState({
            confirmDialogProps: {
                type: eDialogTypes.EDIT,
                title: "Edit Branding",
                message: "",
                originalBrandingName: branding_name,
                newBrandingName: branding_name,
                isDefaultBranding,
                helperText: "",
                actions: [
                    {
                        label: "Cancel",
                        classes: "button-link",
                        onClick: () => this.cancelConfirmDialog()
                    },
                    {
                        label: "Save",
                        classes: "info",
                        disabled: true,
                        onClick: () => this.confirmEditBranding()
                    }
                ]
            }
        })
    }

    onDelete() {
        this.setState({
            confirmDialogProps: {
                type: eDialogTypes.DELETE,
                title: "Delete Branding",
                message: "Are you sure you want to delete this branding?",
                actions: [
                    {
                        label: "Cancel",
                        classes: "button-link",
                        onClick: () => this.cancelConfirmDialog()
                    },
                    {
                        label: "Delete",
                        classes: "warning",
                        onClick: () => this.confirmDeleteBranding()
                    }
                ]
            }
        });
    }

    confirmDeleteBranding() {
        const { currentBranding : { branding_id } } = this.state;
        deleteBranding(branding_id).then(async (response) => {
            if (response.error) {
                this.updateStatusMessage("Branding cannot be deleted.", eStatusMessageType.WARNING);
            } else {
                const { brandings } = this.state;
                const updatedBrandings = brandings.filter(({branding_id: brandingID}) => brandingID !== branding_id);
                await this.selectDefaultBranding();
                this.setState({
                    brandings: updatedBrandings,
                    confirmDialogProps: undefined
                }, () => this.updateStatusMessage("Branding successfully deleted."));
            }
        });
    }

    async confirmEditBranding() {
        // update branding name
        const {
            confirmDialogProps : { newBrandingName, isDefaultBranding },
            brandings,
            currentBranding : { branding_id : currentBrandingID }
        } = this.state;

        const updatedBrandings = brandings.map((branding) => {
            const { branding_id } = branding;

            const returnValue = branding;
            if (branding_id === currentBrandingID) {
                returnValue[eBrandingType.NAME] = newBrandingName.trim();
                returnValue[eBrandingType.IS_DEFAULT] = isDefaultBranding;
            } else {
                if (isDefaultBranding) {
                    // un-default others
                    returnValue[eBrandingType.IS_DEFAULT] = false;
                }
            }
            return returnValue;
        });

        this.setState(updatedBrandings, () => this.saveBranding());
    }

    async saveBranding(isNewBranding = false) {
        await this.setState({
            isLoading: true
        });

        const { brandings, currentBranding } = this.state;
        const { branding_id : currentBrandingID, properties } = currentBranding;

        let payload = {};

        const imagesToUpload = [
            '--welcome-logo-file',
            '--interview-logo-file',
            '--welcome-background-image-file'
        ];

        let brandingProperties = properties;

        // update image values
        imagesToUpload.forEach((propertyName) => {
            const propertyValue = getValue(properties.find(({name}) => name === propertyName), {}).value;

            if (!!propertyValue) {
                const id = propertyName.replace("-file", "");
                const transportedName = propertyName.replace(/--/g, "");

                brandingProperties = brandingProperties.map((property) =>
                    this.getUpdatedBrandingPropertyForImage(property, id, transportedName)
                );

                payload[transportedName] = propertyValue;
            }
        });

        // remove values that are not required to be sent
        const excludedItems = [
            '--welcome-logo-file',
            '--interview-logo-file',
            '--welcome-background-image-file',
            '--welcome-logo-input',
            '--interview-logo-input',
            '--welcome-background-image-input'
        ];

        brandingProperties = JSON.stringify(
            brandingProperties.filter(({name}) => !excludedItems.includes(name))
        );

        if (isNewBranding) {
            const { confirmDialogProps : { newBrandingName, isDefaultBranding } } = this.state;
            payload = {...payload, branding_name: newBrandingName.trim(), is_default_for_new_job: isDefaultBranding };

            cloneBranding(currentBrandingID, payload).then(({branding_id}) => {
                const secondPayload = {
                    ...currentBranding,
                    branding_id,
                    ...payload,
                    properties: brandingProperties
                };

                updateBranding(branding_id, secondPayload).then((branding) => {
                    if (branding.error) {
                        this.setState({isLoading: false});
                        this.updateStatusMessage("Unable to save branding. Please try again later", eStatusMessageType.WARNING);
                        return false;
                    }

                    branding.properties = this.prepareBrandingProperties(branding.properties);

                    const updatedBrandings = brandings;
                    brandings.push(branding);

                    const updatedState = {
                        isLoading: false,
                        isEditMode: false,
                        isColorPickerShown: false,
                        currentBranding: branding,
                        currentBrandingSnapshot: branding,
                        brandings: updatedBrandings,
                        confirmDialogProps: undefined
                    };

                    this.setState(updatedState, () => this.updateStatusMessage("Branding successfully created."));
                });
            });
        } else {
            payload = {
                ...payload,
                ...currentBranding,
                properties: brandingProperties
            };

            const response = await updateBranding(currentBrandingID, asFormData(payload));

            if (response.error) {
                this.setState({isLoading: false});
                this.updateStatusMessage("Unable to save branding. Please try again later", eStatusMessageType.WARNING);
                return false;
            }

            const updatedBrandings = brandings.map((branding) => {
                const { branding_id } = branding;
                if (branding_id === currentBrandingID) {
                    response.properties = this.prepareBrandingProperties(response.properties);
                    return response;
                } else {
                    return branding;
                }
            });

            // status message success
            this.setState({
                isLoading: false,
                isEditMode: false,
                isColorPickerShown: false,
                brandings: updatedBrandings,
                currentBranding: response,
                currentBrandingSnapshot: response,
                confirmDialogProps: undefined
            });
            this.updateStatusMessage("Branding successfully saved.");
        }
    }

    updateStatusMessage(message, type = eStatusMessageType.INFO) {
        this.setState({
            statusMessage: {
                type,
                message,
                reset: new Date()
            }
        })
    }

    componentDidMount() {
        setTimeout(this.loadData, 0);
    }

    shouldComponentUpdate() {
        // TASK: can we not re-render the logo / background image all the time?
        return true;
    }

    render() {
        const { isLoading, currentBranding, isEditMode, isColorPickerShown, statusMessage: { type, message, reset }, confirmDialogProps, isAdvancedSettingsShown } = this.state;
        if (!currentBranding) {
            return (<div className={`branding empty`}></div>);
        }

        const properties = currentBranding[eBrandingType.PROPERTIES],
            isDefaultBranding = currentBranding[eBrandingType.IS_DEFAULT];

        const mainBrandingItems = [
            {name: '--color-1', type: eBrandingItemType.COLOR, group: "Colors"},
            {name: '--color-2', type: eBrandingItemType.COLOR},
            {name: '--color-3', type: eBrandingItemType.COLOR},
            {name: '--color-4', type: eBrandingItemType.COLOR},
            {name: '--welcome-logo-input', type: eBrandingItemType.IMAGE_INPUT, group: "Logo", tooltipType: eTooltipType.LOGO},
            {name: '--interview-logo-input', type: eBrandingItemType.IMAGE_INPUT, tooltipType: eTooltipType.LOGO},
            {name: '--welcome-background-image-input', type: eBrandingItemType.IMAGE_INPUT, group: "Image", tooltipType: eTooltipType.IMAGE},
            {name: '--welcome-background-position', type: eBrandingItemType.TEXT},
        ];

        const mainBrandingContent = mainBrandingItems.map(({name, type: t, group = "", tooltipType = ""}) => {
            const value = getValue(properties.find(({name: id}) => {
                return id === name;
            }), {}).value;
            return {
                name,
                value,
                type: t,
                group,
                tooltipType
            };
        });

        const hasBrandingChanged = this.hasBrandingChanged();

        return (
            <Container loading={false}>
                <StatusMessage
                    type={type}
                    style={eStatusMessageStyle.BAR}
                    message={message}
                    duration={5000}
                    reset={reset}
                />

                <div className={`branding`}>
                    <div className={`branding-container edit-mode-${!!isEditMode}`}>
                        <div className={`branding-settings`}>
                            <Dropdown
                                required={false}
                                styles="floating-label"
                                classes="branding-name"
                                label="Select Branding"
                                items={this.getBrandingsAsDropdown()}
                                dropdownType={eDropdownTypes.BRANDING}
                                value={currentBranding.branding_id}
                                onSelect={this.selectBranding}
                                disabled={isEditMode}
                            />
                            <div
                                className={`edit-branding disabled-${!!isEditMode}`}
                                onClick={isEditMode ? null : this.onEdit}
                            >
                                {IconPencil}
                            </div>
                            {isAlcamiStaff() && !isDefaultBranding && (
                                <div className={`delete-button`} onClick={this.onDelete}>{IconTrash}</div>
                            )}
                            {!isEditMode && (
                                <button className={`react-button edit-button`} onClick={this.enableEditMode}>Edit</button>
                            )}
                            {isEditMode && (
                                <React.Fragment>
                                    <button className={`react-button cancel-button button-link`} onClick={this.cancelEditing}>Cancel</button>
                                    <button
                                        className={`react-button save-button ${!hasBrandingChanged ? 'disabled' : ''}`}
                                        onClick={hasBrandingChanged ? () => this.saveBranding() : null}
                                    >
                                        {isLoading ? <Loader /> : "Save"}
                                    </button>
                                </React.Fragment>
                            )}
                            <button className={`react-button add-button`} onClick={this.onSaveAs}>Save As...</button>
                            {isEditMode && (
                                <CustomToggleField
                                    classes="color-picker-toggle"
                                    id="color-picker-toggle"
                                    label="Color Pickers"
                                    checked={isColorPickerShown}
                                    onChange={this.toggleColorPicker}
                                />
                            )}
                        </div>
                        <div className={`branding-properties`}>
                            {mainBrandingContent.map(({name: id, value, type: t, group, tooltipType}, index) =>
                                this.getMainBrandingItem(id, value, t, group, tooltipType, index)
                            )}
                        </div>
                        <BrandingPreview
                            branding={currentBranding}
                            onInputChange={isColorPickerShown ? this.onInputChange : null}
                        />
                        {isAlcamiStaff() && (
                            <div className={`branding-properties-advanced`}>
                                <div className={`property-group advanced-settings-link ${isAdvancedSettingsShown ? 'shown' : ''}`} onClick={this.toggleAdvancedSettings}>Advanced</div>
                                {isAdvancedSettingsShown && properties.map(({name, value}) => {
                                    return (
                                        <CustomTextField
                                            key={name}
                                            id={name}
                                            label={name}
                                            value={value}
                                            disabled={this.isPropertyDisabled(name)}
                                            helperText={this.getHelperText(name)}
                                            onChange={this.onInputChange}
                                        />
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
                {confirmDialogProps && (
                    <ConfirmDialog isLoading={isLoading} {...confirmDialogProps}>
                        {[eDialogTypes.SAVE_AS, eDialogTypes.EDIT].includes(confirmDialogProps.type) && (
                            <React.Fragment>
                                <CustomTextField
                                    id={`newBrandingName`}
                                    classes={`new-branding-name`}
                                    required={true}
                                    label={"Branding name"}
                                    value={confirmDialogProps.newBrandingName}
                                    helperText={confirmDialogProps.helperText}
                                    onChange={this.updateDialogProps}
                                    autoFocus={true}
                                    onFocus={e => e.currentTarget.select()}
                                />
                                <CustomToggleField
                                    classes="default-branding-toggle"
                                    size={eToggleSize.X_SMALL}
                                    id="default-branding-toggle"
                                    label={this.getDefaultBrandingToggleLabel()}
                                    checked={confirmDialogProps.isDefaultBranding}
                                    isDisabled={this.getDefaultBrandingIsDisabled()}
                                    onChange={this.getDefaultBrandingOnChange()}
                                />
                            </React.Fragment>
                        )}
                    </ConfirmDialog>
                )}
            </Container>
        );
    }
}

export default Branding;
