import React from "react";
import * as PropTypes from "prop-types";
import Avatar from "../Avatar";
import SendInvite from "../SendInvite";
import { capitalize, orderBy, toLower } from "lodash";
import {
    convertDateToCommonFormat,
    eDateFormatTypes,
    eRecommendationTypes,
    eCandidateActionTypes,
    eCandidateSortTypes,
    eCandidateTableTypes,
    eDecisionTypes,
    eDropDownPosition,
    eStatusMessageStyle,
    eStatusMessageType,
    formatDate,
    formatDateTime,
    objectToArray,
    openLiveInterview,
    eCompanyFeatures,
    isAlcamiStaff,
    getUserSetting,
    eUserSettings,
    eDeadlineType,
    eJobStageTypes,
    getFullName,
    isAdminUserSettingEnabled,
    isCompanyFeatureEnabled
} from "../../utils";
import CandidateAction from "../CandidateAction";
import ShareCandidateResponse from "../ShareCandidateResponse";
import Tooltip from "../Tooltip";
import {
    IconAttachment,
    IconClock,
    IconCross,
    IconFilter,
    IconGlobe,
    IconLive,
    IconMail,
    IconMailDelivered,
    IconMailOpen,
    IconPencil,
    IconRefresh,
    IconSearch,
    IconShare,
    IconSort,
    IconTick
} from "../Icons";
import AvatarWithLabel from "../AvatarWithLabel";
import Dropdown from "../Dropdown";
import {deleteCandidate, getInterviewSteps, updateCandidateDecisions} from "../../api";
import StatusMessage from "../StatusMessage";
import CustomCheckbox, { eCheckboxType } from "../Form/CustomCheckbox";
import CustomModal from "../CustomModal";
import LiveInterview from "../LiveInterview";
import Loader from "../Loader";
import InterviewStructure from "../InterviewStructure";

import './style.scss';
import CandidateEdit from "../CandidateEdit";
import CandidateActivity from "../CandidateActivity";
import CustomRadioButton from "../Form/CustomRadioButton";
import CustomTextField from "../Form/CustomTextField";
import ActionDropdown from "../ActionDropdown";

const CANDIDATES_TABLE_SORT_VALUES = {
    [eCandidateTableTypes.INVITED]: [
        eCandidateSortTypes.CANDIDATE,
        eCandidateSortTypes.EMAIL,
        eCandidateSortTypes.PHONE,
        eCandidateSortTypes.LAST_INVITE_SENT_ON,
        eCandidateSortTypes.DEADLINE
    ],
    [eCandidateTableTypes.RESPONDED]: [
        eCandidateSortTypes.CANDIDATE,
        eCandidateSortTypes.EMAIL,
        eCandidateSortTypes.PHONE,
        eCandidateSortTypes.Q_SCORE,
        eCandidateSortTypes.ATTR_SCORE,
        eCandidateSortTypes.TOTAL_SCORE,
        eCandidateSortTypes.RECOMMENDATION,
        eCandidateSortTypes.DECISION
    ],
    [eCandidateTableTypes.EVALUATING]: [
        eCandidateSortTypes.CANDIDATE,
        eCandidateSortTypes.EMAIL,
        eCandidateSortTypes.TOTAL_SCORE,
        eCandidateSortTypes.RECOMMENDATION,
        eCandidateSortTypes.EVALUATION_STATUS
    ],
    [eCandidateTableTypes.LIVE_INTERVIEW]: [
        eCandidateSortTypes.INTERVIEW_AT
    ],
    [eCandidateTableTypes.LIVE_INTERVIEW_EVALUATING]: [
        eCandidateSortTypes.INTERVIEW_AT
    ]
};

const CANDIDATES_TABLE_DEFAULT_SORT_VALUES = {
    [eCandidateTableTypes.INVITED]: {type: null, questionNumber: 0},
    [eCandidateTableTypes.RESPONDED]: {type: eCandidateSortTypes.TOTAL_SCORE, questionNumber: 0},
    [eCandidateTableTypes.EVALUATING]: {type: eCandidateSortTypes.CANDIDATE, firstNameOrLastName: eCandidateSortTypes.CANDIDATE_FIRST_NAME},
    [eCandidateTableTypes.LIVE_INTERVIEW]: {type: eCandidateSortTypes.INTERVIEW_AT},
    [eCandidateTableTypes.LIVE_INTERVIEW_EVALUATING]: {type: eCandidateSortTypes.INTERVIEW_AT}
};

const DELETION_REASONS = {
    WITHDRAWN: "withdrawn",
    DUPLICATE: "duplicate",
    OTHER: "other"
};

const DELETION_REASONS_VALUES = {
    "withdrawn": "Candidate has withdrawn the application",
    "duplicate": "Candidate has duplicated applications",
    "other": "Other (please specify):"
};

class CandidatesTable extends React.Component {
    constructor(props) {
        super(props);

        this.retrieveInterviewSteps = this.retrieveInterviewSteps.bind(this);

        this.isItemSelected = this.isItemSelected.bind(this);
        this.areItemsSelected = this.areItemsSelected.bind(this);
        this.areAllItemsSelected = this.areAllItemsSelected.bind(this);
        this.toggleSelectRow = this.toggleSelectRow.bind(this);
        this.toggleSelectAll = this.toggleSelectAll.bind(this);

        this.toggleInterviewTemplate = this.toggleInterviewTemplate.bind(this);
        this.toggleCandidateSharing = this.toggleCandidateSharing.bind(this);
        this.toggleCandidateAction = this.toggleCandidateAction.bind(this);
        this.toggleCandidateActivity = this.toggleCandidateActivity.bind(this);

        this.shouldShowDecisionConfirmationDialog = this.shouldShowDecisionConfirmationDialog.bind(this);
        this.shouldShowClearDecisionConfirmationDialog = this.shouldShowClearDecisionConfirmationDialog.bind(this);
        this.updateDecisionsConfirmation = this.updateDecisionsConfirmation.bind(this);
        this.updateDecisionConfirmationCancel = this.updateDecisionConfirmationCancel.bind(this);
        this.updateDecisions = this.updateDecisions.bind(this);

        this.toggleLiveInterview = this.toggleLiveInterview.bind(this);
        this.onLiveInterviewSuccess = this.onLiveInterviewSuccess.bind(this);

        this.getExtendedTooltipText = this.getExtendedTooltipText.bind(this);
        this.getTableActions = this.getTableActions.bind(this);
        this.getCandidateActions = this.getCandidateActions.bind(this);

        this.isDeadlineUpdated = this.isDeadlineUpdated.bind(this);

        this.onSharingSuccess = this.onSharingSuccess.bind(this);

        this.updateStatusMessage = this.updateStatusMessage.bind(this);

        this.getCandidateProfileLink = this.getCandidateProfileLink.bind(this);
        this.getCandidateEvaluationLink = this.getCandidateEvaluationLink.bind(this);

        this.deleteCandidateConfirmation = this.deleteCandidateConfirmation.bind(this);
        this.deleteCandidateReasonUpdate = this.deleteCandidateReasonUpdate.bind(this);
        this.deleteCandidateReasonOtherUpdate = this.deleteCandidateReasonOtherUpdate.bind(this);
        this.deleteCandidateConfirmed = this.deleteCandidateConfirmed.bind(this);
        this.closeDeleteCandidateDialog = this.closeDeleteCandidateDialog.bind(this);

        this.getLiveInterviewDetails = this.getLiveInterviewDetails.bind(this);

        this.sortCandidates = this.sortCandidates.bind(this);
        this.updateActiveSortValues = this.updateActiveSortValues.bind(this);

        const { displayType } = props;

        this.state = {
            selectedItems: [],
            temporarilySelectedItem: null,
            selectedInterview: {
                isShown: false,
                interviewId: this.props.candidates?.[0].interview_id,
                sampleCandidateId: this.props.candidates?.[0].candidate_id,
                interviewSteps: []
            },
            showCandidateAction: false,
            showCandidateActivity: false,
            candidateActionType: null,
            showCandidateSharing: false,
            showLiveInterviewDialog: false,
            areItemsSelectable: false,
            statusMessage: {
                type: null,
                message: null,
                reset: null
            },
            decisionDialog: {
                isShown: false,
                decision: null,
                candidateId: null
            },
            candidateDeleteDialog: {
                isShown: false,
                reason: null,
                reasonOther: null,
                candidateId: null
            },

            availableSortValues: CANDIDATES_TABLE_SORT_VALUES[displayType],
            activeSortValues: CANDIDATES_TABLE_DEFAULT_SORT_VALUES[displayType]
        };
    }

    toggleSelectRow(item) {
        const { candidate_id } = item;
        const { selectedItems } = this.state;
        this.setState({
            selectedItems: this.isItemSelected(candidate_id) ?
                selectedItems.filter(({candidate_id: itemId}) => itemId !== candidate_id) : // remove item
                [...selectedItems, item] // add item
        });
    }

    async retrieveInterviewSteps() {
        const { selectedInterview } = this.state;
        const { sampleCandidateId } = selectedInterview;
        const { job: { job_id }, job_stage: { job_stage_type, job_stage_id }, displayType, staff_id } = this.props;
        const interviewSteps = await getInterviewSteps(job_id, job_stage_type, job_stage_id, sampleCandidateId, displayType === eCandidateTableTypes.EVALUATING ? staff_id: null);

        this.setState({
            selectedInterview: {
                ...selectedInterview,
                interviewSteps
            }
        });
    }

    isItemSelected(candidate_id) {
        const { selectedItems } = this.state;
        return !!(selectedItems.filter(({candidate_id: itemId}) => itemId === candidate_id).length);
    }

    areItemsSelected() {
        const { selectedItems } = this.state;
        return !!(selectedItems.length);
    }

    areAllItemsSelected() {
        const { selectedItems } = this.state;
        const { candidates } = this.props;
        return selectedItems.length === candidates.length;
    }

    toggleSelectAll() {
        const { candidates } = this.props;
        const newSelection = this.areAllItemsSelected() ? [] : candidates;

        this.setState({
            selectedItems: newSelection
        });
    }

    shouldShowDecisionConfirmationDialog(type) {
        const { job_stage: { sends_successful_email, sends_unsuccessful_email } = {} } = this.props;

        if (type === eDecisionTypes.ACCEPTED) {
            return sends_successful_email;
        }
        if (type === eDecisionTypes.DECLINED) {
            return sends_unsuccessful_email;
        }
        return false;
    }

    shouldShowClearDecisionConfirmationDialog() {
        const { job_stage: { sends_successful_email, sends_unsuccessful_email } = {} } = this.props;
        return sends_successful_email || sends_unsuccessful_email;
    }

    updateDecisionsConfirmation(decision, candidateId = null) {
        this.setState({
            decisionDialog: {
                isShown: true,
                decision,
                candidateId
            }
        });
    }

    updateDecisionConfirmationCancel() {
        this.setState({
            decisionDialog: {
                isShown: false,
                decision: null,
                candidateId: null
            }
        });
    }

    updateDecisions(decision, candidateId = null) {
        if (!objectToArray(eDecisionTypes).includes(decision)) {
            return false;
        }
        const { job_stage : { job_id, job_stage_type, job_stage_id }, candidates, onDecisionUpdate } = this.props;
        const { selectedItems } = this.state;
        const selectedItemIds = (selectedItems)?.map(({candidate_id}) => candidate_id);
        const candidateIds = candidateId ?
            [candidateId] :
            (candidates)?.filter(({candidate_id, decision: d}) => selectedItemIds.includes(candidate_id) && !d)
                .map(({candidate_id}) => candidate_id);

        if (!candidateIds.length) {
            this.updateStatusMessage("There are no applicable records for this action.", eStatusMessageType.WARNING);
            return false;
        }

        updateCandidateDecisions(job_id, job_stage_type, job_stage_id, candidateIds, decision).then((response) => {
            this.updateDecisionConfirmationCancel();

            if (response.error) {
                let msg = `${candidateIds.length < selectedItems.length ? "Applicable candidate" : "Candidate"} decision`;
                msg += `${candidateIds.length > 1 ? "s" : ""} cannot be updated.`;

                this.updateStatusMessage(msg, eStatusMessageType.WARNING);
                return false;
            }

            const message = (candidateIds.length < selectedItems.length ? "Applicable candidate" : "Candidate") +
                " decision" + (candidateIds.length > 1 ? "s" : "") + " updated.";

            this.updateStatusMessage(message, eStatusMessageType.INFO);

            onDecisionUpdate(job_stage_id, candidateIds, decision);
        })
    }

    toggleCandidateAction(type, item, e) {
        !!e && e.stopPropagation();
        const { showCandidateAction } = this.state;

        this.setState({
            showCandidateAction: !showCandidateAction,
            candidateActionType: showCandidateAction ? null : type,
            temporarilySelectedItem: showCandidateAction ? null : item
        });
    }

    toggleCandidateActivity(item, e) {
        !!e && e.stopPropagation();
        const { showCandidateActivity } = this.state;

        this.setState({
            showCandidateActivity: !showCandidateActivity,
            temporarilySelectedItem: showCandidateActivity ? null : item
        });
    }

    toggleLiveInterview(item, e) {
        !!e && e.stopPropagation();
        const { showLiveInterviewDialog } = this.state;

        this.setState({
            showLiveInterviewDialog: !showLiveInterviewDialog,
            temporarilySelectedItem: showLiveInterviewDialog ? null : item
        });
    }

    onLiveInterviewSuccess(response, reload) {
        const { onLiveDetailsUpdate } = this.props;
        if (!!onLiveDetailsUpdate) {
            onLiveDetailsUpdate(response, reload);
        }
        this.toggleLiveInterview();
    }

    onSharingSuccess() {
        const { selectedItems } = this.state;
        this.updateStatusMessage(`Candidate response${selectedItems.length > 1 ? 's': ''} shared.`);
    }

    toggleInterviewTemplate() {
        const { selectedInterview } = this.state;

        this.setState({
            selectedInterview: {
                ...selectedInterview,
                isShown: !selectedInterview.isShown
            }
        })
    }

    toggleCandidateSharing(item, e) {
        e?.stopPropagation?.();
        const { showCandidateSharing } = this.state;

        this.setState({
            showCandidateSharing: !showCandidateSharing,
            temporarilySelectedItem: showCandidateSharing ? null : item
        });
    }

    getExtendedTooltipText({ deadline, question_overrides }) {
        const tooltips = [];
        if (this.isDeadlineUpdated(deadline)) {
            tooltips.push("Deadline set at " + formatDate(deadline));
        }

        if (!!question_overrides.length) {
            tooltips.push("Times and/or number of attempts modified");
        }

        if (!tooltips.length) {
            tooltips.push("Extend candidate times");
        }

        return tooltips;
    }

    getTableActions() {
        const { displayType } = this.props;
        const { selectedItems } = this.state;

        const selectedItemsWithoutDecision = selectedItems.filter(({decision}) => !decision);

        let returnValue = [];

        if (this.areItemsSelected()) {
            if (displayType === eCandidateTableTypes.RESPONDED) {
                returnValue.push({
                    id: "share",
                    firstLine: "Share...",
                    selected: false,
                    onClick: this.toggleCandidateSharing
                });
                returnValue.push({
                    id: "accept",
                    firstLine: `Accept${this.shouldShowDecisionConfirmationDialog(eDecisionTypes.ACCEPTED) ? '...' : ''}`,
                    selected: false,
                    disabled: selectedItemsWithoutDecision.length < selectedItems.length,
                    onClick: () => this.shouldShowDecisionConfirmationDialog(eDecisionTypes.ACCEPTED) ?
                        this.updateDecisionsConfirmation(eDecisionTypes.ACCEPTED) :
                        this.updateDecisions(eDecisionTypes.ACCEPTED)
                });
                returnValue.push({
                    id: "decline",
                    firstLine: `Decline${this.shouldShowDecisionConfirmationDialog(eDecisionTypes.DECLINED) ? '...' : ''}`,
                    selected: false,
                    disabled: selectedItemsWithoutDecision.length < selectedItems.length,
                    onClick: () => this.shouldShowDecisionConfirmationDialog(eDecisionTypes.DECLINED) ?
                        this.updateDecisionsConfirmation(eDecisionTypes.DECLINED) :
                        this.updateDecisions(eDecisionTypes.DECLINED)
                });
            } else if (displayType === eCandidateTableTypes.INVITED) {
                returnValue.push({
                    id: "extend",
                    firstLine: "Extend...",
                    selected: false,
                    onClick: () => this.toggleCandidateAction(eCandidateActionTypes.EXTEND)
                });
                returnValue.push({
                    id: "resend",
                    firstLine: "Resend...",
                    selected: false,
                    onClick: () => this.toggleCandidateAction(eCandidateActionTypes.RESEND)
                });
            }
        }

        return returnValue;
    }

    getCandidateActions(_displayType, rowData) {
        const { test, candidate_id, first_name, last_name, decision } = rowData;

        let returnValue = [];

        if (isAlcamiStaff()) {
            if (getUserSetting((eUserSettings.EDIT_CANDIDATE_DETAILS))) {
                returnValue.push({
                    id: "edit",
                    firstLine: "Edit",
                    selected: false,
                    onClick: () => this.toggleCandidateAction(eCandidateActionTypes.EDIT, rowData, null)
                });
            }

            returnValue.push({
                id: "activity-log",
                firstLine: `Activity Log...`,
                selected: false,
                onClick: () => this.toggleCandidateActivity(rowData, null)
            });

            if (getUserSetting(eUserSettings.CLEAR_DECISION) && !!decision) {
                returnValue.push({
                    id: "undecide",
                    firstLine: `Clear Decision ${this.shouldShowClearDecisionConfirmationDialog() ? '...' : ''}`,
                    selected: false,
                    onClick: () => this.shouldShowClearDecisionConfirmationDialog() ?
                        this.updateDecisionsConfirmation(null, candidate_id) :
                        this.updateDecisions(eDecisionTypes.NONE, candidate_id)
                });
            }
        }

        /*if (displayType === eCandidateTableTypes.RESPONDED) {
            returnValue.push({
                id: "share",
                firstLine: "Share Responses...",
                selected: false,
                onClick: () => this.toggleCandidateSharing(rowData)
            });
        }*/

        if (isAlcamiStaff() || test) {
            returnValue.push({
                id: "delete",
                firstLine: "Delete...",
                selected: false,
                onClick: () => this.deleteCandidateConfirmation(candidate_id, getFullName(first_name, last_name))
            });
        }

        return returnValue;
    }

    isDeadlineUpdated(deadline) {
        const { job_stage : { deadline: jobDeadline } } = this.props;

        return convertDateToCommonFormat(deadline) !== convertDateToCommonFormat(jobDeadline);
    }

    updateStatusMessage(message, type = eStatusMessageType.INFO) {
        const { onStatusMessageUpdate } = this.props;
        !!onStatusMessageUpdate && onStatusMessageUpdate(message, type);
    }

    getCandidateProfileLink(candidate_id) {
        const { displayType, urlFor, job_stage: { job_id, job_stage_type, job_stage_id }, integrationProvider } = this.props;
        const route = displayType === eCandidateTableTypes.EVALUATING ? 'assess-candidate-evaluation' : 'manage-candidate-profile';
        return urlFor(route, { job_id, job_stage_type, job_stage_id, candidate_id, integrationProvider });
    }

    getCandidateEvaluationLink(candidate_id) {
        const { urlFor, job_stage: { job_id, job_stage_type, job_stage_id }, integrationProvider } = this.props;
        return urlFor('assess-candidate-evaluation', { job_id, job_stage_type, job_stage_id, candidate_id, integrationProvider });
    }

    deleteCandidateConfirmation(candidateId, fullName) {
        this.setState({
            candidateDeleteDialog: {
                isShown: true,
                reason: null,
                reasonOther: "",
                candidateId,
                fullName
            }
        });
    }

    deleteCandidateReasonUpdate(reason) {
        this.setState({
            candidateDeleteDialog: {
                ...this.state.candidateDeleteDialog,
                reason
            }
        });
    }

    deleteCandidateReasonOtherUpdate(e) {
        this.setState({
            candidateDeleteDialog: {
                ...this.state.candidateDeleteDialog,
                reasonOther: e.target.value
            }
        });
    }

    deleteCandidateConfirmed() {
        const { job_stage : { job_id, job_stage_type, job_stage_id }, onCandidateDelete } = this.props;
        const { candidateDeleteDialog : { candidateId, reason, reasonOther } } = this.state;
        const payload = {
            data: {
                reason: reason === DELETION_REASONS.OTHER ? reasonOther : reason
            }
        };

        deleteCandidate(job_id, job_stage_type, job_stage_id, candidateId, payload).then(() => {
            this.closeDeleteCandidateDialog();
            !!onCandidateDelete && onCandidateDelete(job_stage_id, candidateId);
        })
    }

    closeDeleteCandidateDialog() {
        this.setState({
            candidateDeleteDialog: {
                isShown: false,
                candidateId: null,
                fullName: null
            }
        });
    }

    getLiveInterviewDetails() {
        const { displayType } = this.props;
        const { temporarilySelectedItem: { candidate_id: candidateId = null } = {} } = this.state;

        // TASK: simplify this logic
        if (displayType === eCandidateTableTypes.LIVE_INTERVIEW) {
            const { candidates } = this.props;
            return candidates.find(({candidate_id}) => candidate_id === candidateId);
        } else {
            const { liveInterviewCandidates = [] } = this.props;
            return liveInterviewCandidates.find(({candidate_id}) => candidate_id === candidateId);
        }
    }

    sortCandidates() {
        const { candidates } = this.props;

        if (!candidates) {
            return null;
        }

        if (candidates.length < 2) {
            // no need to sort if less than two records
            return candidates;
        }

        const { availableSortValues, activeSortValues } = this.state;

        let currentSortValues = [...availableSortValues];
        const { type: activeSortType, firstNameOrLastName } = activeSortValues;

        if (activeSortType) {
            // move active sort value to the front of the array
            currentSortValues.splice(availableSortValues.indexOf(activeSortType), 1);
            currentSortValues = [activeSortType, ...currentSortValues];
        }

        // replace "candidate" with "first_name" and "last_name"
        const candidateIndex = currentSortValues.indexOf(eCandidateSortTypes.CANDIDATE);
        if (candidateIndex >= 0) {
            if (firstNameOrLastName === eCandidateSortTypes.CANDIDATE_FIRST_NAME) {
                currentSortValues.splice(
                    candidateIndex,
                    1,
                    candidate => candidate[eCandidateSortTypes.CANDIDATE_FIRST_NAME].toLowerCase(),
                    candidate => candidate[eCandidateSortTypes.CANDIDATE_LAST_NAME]?.toLowerCase()
                );
            } else {
                currentSortValues.splice(
                    candidateIndex,
                    1,
                    candidate => candidate[eCandidateSortTypes.CANDIDATE_LAST_NAME]?.toLowerCase(),
                    candidate => candidate[eCandidateSortTypes.CANDIDATE_FIRST_NAME].toLowerCase()
                );
            }
        }

        // append QUESTION_SCORE with question number
        const questionIndex = currentSortValues.indexOf(eCandidateSortTypes.QUESTION_SCORE);
        const { questionNumber } = activeSortValues;
        if (questionIndex >= 0 && activeSortType === eCandidateSortTypes.QUESTION_SCORE) {
            currentSortValues.splice(
                questionIndex,
                1,
                `${eCandidateSortTypes.QUESTION_SCORE}${questionNumber}`
            );
        }

        // replace "recommendation" with "recommendation_yes", "recommendation_maybe", "recommendation_no"
        const recommendationIndex = currentSortValues.indexOf(eCandidateSortTypes.RECOMMENDATION);
        if (recommendationIndex >= 0) {
            currentSortValues.splice(
                recommendationIndex,
                1,
                eCandidateSortTypes.RECOMMENDATION_YES,
                eCandidateSortTypes.RECOMMENDATION_MAYBE,
                eCandidateSortTypes.RECOMMENDATION_NO,
            );
        }

        /* this would replace null with 0 so it can be sorted, with the top scorers at the top
         * however, it is actually beneficial to show the null records at the top to increase visibility
         * that some of the responses are yet to be evaluated. which is why this section is commented
         */
        /*
        const advancedCurrentSortValues = currentSortValues.map((sortValue) => (
            [
                `${eCandidateSortTypes.QUESTION_SCORE}${questionNumber}`,
                eCandidateSortTypes.Q_SCORE,
                eCandidateSortTypes.ATTR_SCORE,
                eCandidateSortTypes.TOTAL_SCORE
            ].includes(sortValue) ?
            (item) => item[sortValue] || 0 :
            sortValue
        ));
        */

        // TASK: enable sort directions in the UI?
        const sortDirections = currentSortValues.map(
            (value) => [
                `${eCandidateSortTypes.QUESTION_SCORE}${questionNumber}`,
                eCandidateSortTypes.Q_SCORE,
                eCandidateSortTypes.ATTR_SCORE,
                eCandidateSortTypes.TOTAL_SCORE,
                eCandidateSortTypes.RECOMMENDATION_YES,
                eCandidateSortTypes.RECOMMENDATION_MAYBE,
                eCandidateSortTypes.RECOMMENDATION_NO
            ].includes(value) ? 'desc' : 'asc');

        return orderBy(candidates, currentSortValues, sortDirections);
    }

    updateActiveSortValues(type, questionNumber) {
        const { activeSortValues } = this.state;
        const updatedActiveSortValues = {...activeSortValues, type};

        if (questionNumber) {
            updatedActiveSortValues.questionNumber = questionNumber;
        }

        // if clicking candidate twice, we will swap sorting the first name and last name
        if (type === eCandidateSortTypes.CANDIDATE) {
            if (
                activeSortValues.type === eCandidateSortTypes.CANDIDATE &&
                activeSortValues.firstNameOrLastName === eCandidateSortTypes.CANDIDATE_FIRST_NAME
            ) {
                updatedActiveSortValues.firstNameOrLastName = eCandidateSortTypes.CANDIDATE_LAST_NAME;
            } else {
                updatedActiveSortValues.firstNameOrLastName = eCandidateSortTypes.CANDIDATE_FIRST_NAME;
            }
        }

        this.setState({
            activeSortValues: updatedActiveSortValues
        })
    }

    async componentDidMount() {
        await this.retrieveInterviewSteps();
    }

    render() {
        const {
            displayType,
            title,
            interview_name,
            job,
            job_stage,
            liveInterviewCandidates = [],
            classes,
            onSaveCandidateOverrides,
            onInviteSuccess,
            company_id,
            staff_id: staffId,
            is_alcami_staff,
            isReadOnly,
            features,
            isLazyLoadEnabled
        } = this.props;

        const { job_timezone } = job;

        const {
            availableSortValues,
            activeSortValues,
            selectedInterview: {
                isShown: showInterview,
                interviewId: selectedInterviewId,
                sampleCandidateId: selectedInterviewSampleCandidateId,
                interviewSteps
            },
            showCandidateAction,
            showCandidateActivity,
            candidateActionType,
            showCandidateSharing,
            showLiveInterviewDialog,
            areItemsSelectable,
            selectedItems,
            temporarilySelectedItem,
            statusMessage: { type, message, reset },
            decisionDialog: { isShown: showDecisionDialog, decision : decisionDialogDecision, candidateId : decisionDialogCandidateId },
            candidateDeleteDialog: {
                isShown: showCandidateDeleteDialog,
                fullName: showCandidateDeleteDialogFullName,
                reason: candidateDeleteReason,
                reasonOther: candidateDeleteReasonOther
            }
        } = this.state;
        const candidates = this.sortCandidates();
        const { type: activeSortType, questionNumber: activeSortQuestionNumber } = activeSortValues;
        const {
            job_id,
            job_stage_type,
            job_stage_id,
            requires_response_evaluation,
            evaluation_attributes = [],
            evaluators = [],
            invitation_email_template_id
        } = job_stage;

        const defaultActiveSortValue = eCandidateSortTypes.CANDIDATE;

        const sortItems = availableSortValues.map((item) => ({
                id: item,
                firstLine: item.replace(/_/g, ' '),
                selected: (activeSortType === item) || (!activeSortType && defaultActiveSortValue === item),
                onClick: () => this.updateActiveSortValues(item)
            })
        );

        const isInvitedView = displayType === eCandidateTableTypes.INVITED,
            isRespondedView = displayType === eCandidateTableTypes.RESPONDED,
            isEvaluatingView = displayType === eCandidateTableTypes.EVALUATING,
            isLiveInterviewView = displayType === eCandidateTableTypes.LIVE_INTERVIEW,
            isLiveInterviewEvaluatingView = displayType === eCandidateTableTypes.LIVE_INTERVIEW_EVALUATING;

        const number_of_questions = interviewSteps.length;

        const confirmation_decision_text = decisionDialogDecision === eDecisionTypes.ACCEPTED ? 'accept' : 'decline';

        // const liveCandidateIds = liveInterviewCandidates.map(({candidate_id}) => candidate_id);

        const hasLiveStage = job.job_stages.find(({job_stage_type: t}) => t === eJobStageTypes.LIVE);

        const showLiveInterview = hasLiveStage && (
            isCompanyFeatureEnabled(eCompanyFeatures.LIVE_INTERVIEW, features) ||
            (isAdminUserSettingEnabled(eUserSettings.LIVE_INTERVIEW))
        );

        let titleAlt = "";

        if (displayType === eCandidateTableTypes.INVITED) {
            titleAlt = "Invited";
        }
        if (displayType === eCandidateTableTypes.EVALUATING) {
            titleAlt = "Applications";
        }
        if (displayType === eCandidateTableTypes.RESPONDED) {
            titleAlt = "Completed";
        }
        if (displayType === eCandidateTableTypes.LIVE_INTERVIEW) {
            titleAlt = "Live Interviews";
        }

        const showDeletionReason = isAlcamiStaff() && getUserSetting(eUserSettings.DELETE_CANDIDATE_REASON);
        const candidateDeleteReasonPopulated = !showDeletionReason ||
            (
                !!candidateDeleteReason && (
                    candidateDeleteReason !== DELETION_REASONS.OTHER || !!candidateDeleteReasonOther
                )
            );

        const isLoading = !candidates;

        const candidatesTableClasses = classes || `candidates-${displayType}`;

        return (
            <React.Fragment>
                <StatusMessage
                    type={type}
                    style={eStatusMessageStyle.BAR}
                    message={message}
                    duration={5000}
                    reset={reset}
                />
                <div className={`candidates-table ${candidatesTableClasses}`}>
                    <div className="header-mobile">
                        {title || titleAlt}
                    </div>
                    <div className="header">
                        <div className="subtitle">
                            {!isLoading && !!candidates.length && (
                                <React.Fragment>
                                    <span>{candidates.length} Candidate{candidates.length > 1 ? 's' : ''}</span>
                                    {interview_name && (
                                        <span className={`interview_info`}> on <span className={`interview_name`} onClick={this.toggleInterviewTemplate}>{interview_name}</span>
                                        </span>
                                    )}
                                </React.Fragment>
                            )}

                        </div>
                        <div className="title">
                            {title || titleAlt}
                        </div>
                        <div className="menu">
                            <div className="search">
                                {IconSearch}
                            </div>
                            <div className="filter">
                                {IconFilter}
                                <span className={`label`}>Filter</span>
                            </div>
                            <div className="sort">
                                <Dropdown
                                    styles="popup no-border no-arrow small"
                                    classes="sort-dropdown"
                                    icon={IconSort}
                                    label="Sort"
                                    showArrow={false}
                                    showUnderline={false}
                                    persistLabel={true}
                                    items={sortItems}
                                    itemSettings={{
                                        positioning: eDropDownPosition.TOP_RIGHT
                                    }}
                                />
                            </div>
                            {(isInvitedView || isRespondedView) && (
                                <div className="actions">
                                    <Dropdown
                                        styles="popup no-border no-arrow small"
                                        classes="actions-dropdown"
                                        label="..."
                                        showArrow={false}
                                        showUnderline={false}
                                        persistLabel={true}
                                        items={this.getTableActions()}
                                        itemSettings={{
                                            positioning: eDropDownPosition.TOP_RIGHT
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    {isLoading && (
                        <div className={`candidate-loading`}>
                            <Loader />
                        </div>
                    )}

                    {!isLoading && !candidates.length && (
                        <div className={`candidate-row empty`}>
                            0 records found.
                        </div>
                    )}

                    {!isLoading && !!candidates.length && (
                        <div
                            className={`candidate-header ${toLower(displayType)} ${this.areItemsSelected() || areItemsSelectable ? 'selected' : ''}`}
                        >
                            {(isInvitedView || isRespondedView || isLiveInterviewView) && (
                                <div className="header-selection">
                                    <CustomCheckbox
                                        type={eCheckboxType.NATIVE}
                                        checked={this.areAllItemsSelected()}
                                        onChange={() => this.toggleSelectAll()}
                                        />
                                </div>
                            )}
                            <div className={`header-candidate ${activeSortType === eCandidateSortTypes.CANDIDATE ? 'sorted' : ''}`} onClick={() => this.updateActiveSortValues(eCandidateSortTypes.CANDIDATE)}>Candidate</div>
                            {(isInvitedView || isLiveInterviewView) && (
                                <div className={`header-email ${activeSortType === eCandidateSortTypes.EMAIL ? 'sorted' : ''}`} onClick={() => this.updateActiveSortValues(eCandidateSortTypes.EMAIL)}>Email</div>
                            )}
                            {isInvitedView && (
                                <React.Fragment>
                                    <div className={`header-phone ${activeSortType === eCandidateSortTypes.PHONE ? 'sorted' : ''}`} onClick={() => this.updateActiveSortValues(eCandidateSortTypes.PHONE)}>Phone</div>
                                    <div className={`header-invite-sent-on ${activeSortType === eCandidateSortTypes.LAST_INVITE_SENT_ON ? 'sorted' : ''}`} onClick={() => this.updateActiveSortValues(eCandidateSortTypes.LAST_INVITE_SENT_ON)}>Invite Sent On</div>
                                </React.Fragment>
                            )}
                            {(isEvaluatingView || isRespondedView) && (
                                <React.Fragment>
                                    <div className={`header-questions-scores`}>
                                        {requires_response_evaluation && [...Array(number_of_questions)].fill(1).map((_x, i) =>
                                            <div key={`header-question-score-${i+1}`} className={`header-question-score ${activeSortType === eCandidateSortTypes.QUESTION_SCORE && activeSortQuestionNumber === (i+1) ? 'sorted' : ''}`} onClick={() => this.updateActiveSortValues(eCandidateSortTypes.QUESTION_SCORE, i+1)}>
                                                {i === 0 ? <span className={"header-question-score-label"}>Q</span> : ''}{i+1}
                                            </div>
                                        )}
                                        {requires_response_evaluation && (
                                            <div className={`header-q-score ${activeSortType === eCandidateSortTypes.Q_SCORE ? 'sorted' : ''}`} onClick={() => this.updateActiveSortValues(eCandidateSortTypes.Q_SCORE)}>
                                                Avg.
                                            </div>
                                        )}
                                        {!!evaluation_attributes.length && (
                                            <div className={`header-attr-score ${activeSortType === eCandidateSortTypes.ATTR_SCORE ? 'sorted' : ''}`} onClick={() => this.updateActiveSortValues(eCandidateSortTypes.ATTR_SCORE)}>
                                                Attr.
                                                <Tooltip
                                                    classes={`action-tooltip`}
                                                    positioning="onRight"
                                                    item={<div className="label">?</div>}
                                                    description={
                                                        <div className="content">
                                                            Average attributes score
                                                        </div>
                                                    }
                                                />
                                            </div>
                                        )}
                                        {(requires_response_evaluation || !!evaluation_attributes.length) && (
                                            <div className={`header-total-score ${activeSortType === eCandidateSortTypes.TOTAL_SCORE ? 'sorted' : ''}`} onClick={() => this.updateActiveSortValues(eCandidateSortTypes.TOTAL_SCORE)}>
                                                Total
                                                <Tooltip
                                                    classes={`action-tooltip`}
                                                    positioning="onRight"
                                                    item={<div className="label">?</div>}
                                                    description={
                                                        <div className="content">
                                                            {requires_response_evaluation && !!evaluation_attributes.length && (
                                                                <span>
                                                                    Total score (in percentage format) is made up of:<br/>
                                                                    1) Average questions score (weighted at 60%)<br/>
                                                                    2) Average attributes score (weighted at 40%)
                                                                </span>
                                                            )}
                                                            {requires_response_evaluation && !evaluation_attributes.length && (
                                                                <span>Average questions score in percentage format</span>
                                                            )}
                                                            {!requires_response_evaluation && !!evaluation_attributes.length && (
                                                                <span>Average attributes score in percentage format</span>
                                                            )}
                                                        </div>
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                </React.Fragment>
                            )}
                            {(isLiveInterviewView || isLiveInterviewEvaluatingView) && (
                                <div className={`header-interview-at ${activeSortType === eCandidateSortTypes.INTERVIEW_AT ? 'sorted' : ''}`} onClick={() => this.updateActiveSortValues(eCandidateSortTypes.INTERVIEW_AT)}>Interview At</div>
                            )}

                            {(isRespondedView || isEvaluatingView || isLiveInterviewView || isLiveInterviewEvaluatingView) && (
                                <div className={`header-recommendation ${activeSortType === eCandidateSortTypes.RECOMMENDATION ? 'sorted' : ''}`} onClick={() => this.updateActiveSortValues(eCandidateSortTypes.RECOMMENDATION)}>
                                    Recommendation
                                </div>
                            )}

                            {(isInvitedView || isRespondedView || isLiveInterviewView) && (
                                <div className={`header-live-button`}>&nbsp;</div>
                            )}
                            {isInvitedView && (
                                <div className={`header-deadline ${activeSortType === eCandidateSortTypes.DEADLINE ? 'sorted' : ''}`} onClick={() => this.updateActiveSortValues(eCandidateSortTypes.DEADLINE)}>Deadline</div>
                            )}
                            {(isRespondedView || isLiveInterviewView) && (
                                <div className={`header-evaluation-or-decision ${activeSortType === eCandidateSortTypes.DECISION ? 'sorted' : ''}`} onClick={() => this.updateActiveSortValues(eCandidateSortTypes.DECISION)}>Decision</div>
                            )}
                            {(isEvaluatingView || isLiveInterviewEvaluatingView) && (
                                <div className={`header-evaluation-or-decision ${activeSortType === eCandidateSortTypes.EVALUATION_STATUS ? 'sorted' : ''}`} onClick={() => this.updateActiveSortValues(eCandidateSortTypes.EVALUATION_STATUS)}>Evaluation</div>
                            )}
                        </div>
                    )}

                    {!!candidates && candidates.map((candidate) => {
                        const {
                            candidate_id,
                            interviewers = [],
                            interview_url_id = null,
                            evaluation_url = null,
                            interview_at,
                            first_name,
                            last_name,
                            cvUrl,
                            picture_url,
                            email,
                            phone,
                            deadline,
                            deadline_timezone,
                            question_overrides = [],
                            evaluations = [],
                            q_score,
                            attr_score,
                            total_score,
                            decision,
                            invited_at,
                            is_invitation_email_delivered,
                            has_opened_interview,
                            is_deadline_expired,
                            test
                        } = candidate;

                        let live_interview_url_id;
                        const liveCandidate = liveInterviewCandidates.find(({candidate_id : candidateId}) => candidateId === candidate_id);

                        if (job_stage_type === eJobStageTypes.LIVE) {
                            live_interview_url_id = interview_url_id;
                        } else {
                            live_interview_url_id = (liveCandidate || {}).interview_url_id || null;
                        }
                        const avatarData = { id: candidate_id, first_name, last_name, picture_url };
                        const rowData = {
                            candidate_id,
                            interview_url_id,
                            live_interview_url_id,
                            evaluation_url,
                            first_name,
                            last_name,
                            phone,
                            email,
                            deadline,
                            deadline_timezone,
                            question_overrides,
                            decision,
                            invited_at,
                            is_invitation_email_delivered,
                            has_opened_interview,
                            test
                        };
                        const fullName = getFullName(first_name, last_name);

                        const evaluationsWithEvaluatorDetails = evaluations.map(evaluation => {
                            const {staff_id: sId} = evaluation;
                            const evaluator = (job_stage_type === eJobStageTypes.LIVE ? interviewers : evaluators).find(({staff_id}) => staff_id === sId) || {};
                            return {...evaluation, ...evaluator}
                        });

                        const submittedEvaluations = evaluationsWithEvaluatorDetails.filter(({submitted_at}) => !!submitted_at);
                        const unsubmittedEvaluations = evaluationsWithEvaluatorDetails.filter(({started_at, submitted_at}) => !!started_at && !submitted_at);

                        const recommendationsYes = submittedEvaluations.filter(({recommendation}) => recommendation === eRecommendationTypes.YES);
                        const recommendationsMaybe = submittedEvaluations.filter(({recommendation}) => recommendation === eRecommendationTypes.MAYBE);
                        const recommendationsNo = submittedEvaluations.filter(({recommendation}) => recommendation === eRecommendationTypes.NO);

                        const evaluatorEvaluation = (evaluations.find(({staff_id}) => staff_id === staffId) || {}),
                            isEvaluatorEvaluationSubmitted = !!evaluatorEvaluation.submitted_at,
                            evaluatorRecommendation = /*isEvaluatorEvaluationSubmitted ?*/ evaluatorEvaluation.recommendation /*: eRecommendationTypes.IN_PROGRESS*/;

                        const lastInvited = (invited_at || []).slice(-1).pop();

                        const isDeadlineUpdated = this.isDeadlineUpdated(deadline);
                        const isFirmRecommendation = isEvaluatorEvaluationSubmitted;

                        const enableCandidateProfileLink =
                            (isInvitedView && is_alcami_staff) ||
                            (isEvaluatingView && isFirmRecommendation) ||
                            isRespondedView ||
                            isLiveInterviewView;

                        const live_interviewer_ids = isLiveInterviewView ?
                            interviewers.map(({staff_id}) => staff_id) :
                            ((liveCandidate || {}).interviewers || []).map(({staff_id}) => staff_id);

                        const isLiveInterviewer = live_interviewer_ids.includes(staffId);
                        const candidateActions = this.getCandidateActions(displayType, rowData),
                            hasCandidateActions = !!candidateActions.length,
                            showCandidateActions = hasCandidateActions && !this.areItemsSelected();

                        const deadlineClasses = isDeadlineUpdated ? `deadline-updated` : '';

                        const showSIEvaluationLayout = isCompanyFeatureEnabled(eCompanyFeatures.STRUCTURED_INTERVIEWING_EVALUATIONS, features);

                        return (
                            <div key={candidate_id}
                                 id={`id_${candidate_id}`}
                                 className={`candidate-row ${toLower(displayType)} ${this.isItemSelected(candidate_id) ? 'selected' : ''}`}
                            >
                                <Avatar
                                    data={avatarData}
                                    classes="candidate-avatar"
                                    size="xs"
                                    selectable={isEvaluatingView ? false : this.areItemsSelected()}
                                    selected={isEvaluatingView ? false : this.isItemSelected(candidate_id)}
                                    onClick={isEvaluatingView ? null : () => this.toggleSelectRow(rowData)}
                                    isLazyLoadEnabled={isLazyLoadEnabled}
                                />
                                <div className={`candidate-name ${test ? 'test-candidate' : ''}`}>
                                    {enableCandidateProfileLink && (
                                        <a href={this.getCandidateProfileLink(candidate_id)}>
                                            {fullName}
                                        </a>
                                    )}
                                    {!enableCandidateProfileLink && (
                                        <span>{fullName}</span>
                                    )}
                                    {(isRespondedView || isEvaluatingView) && cvUrl && (
                                        <a className={`cv-link`} href={cvUrl} target="_blank" rel="noopener noreferrer">
                                            {IconAttachment}
                                        </a>
                                    )}
                                </div>

                                {(isInvitedView || isRespondedView || isLiveInterviewView) && (
                                    <div className="candidate-actions">
                                        {(isRespondedView) && (
                                            <div
                                                className={`
                                                    candidate-sharing
                                                    ${this.areItemsSelected() ? 'alwaysHide' : ''}
                                                `}
                                                onClick={(e) => !this.areItemsSelected() && this.toggleCandidateSharing(rowData, e)}
                                            >
                                                <Tooltip
                                                    classes={`
                                                    action-tooltip
                                                    lines-1
                                                `}
                                                    positioning="onLeft"
                                                    item={<div className="label">{IconShare}</div>}
                                                    description={
                                                        <div className="content">
                                                            Share candidate response
                                                        </div>
                                                    }
                                                />
                                            </div>
                                        )}
                                        {!isRespondedView && (
                                            <div>&nbsp;</div>
                                        )}

                                        {(isInvitedView || isRespondedView) && (
                                            <button className={`
                                                candidate-extension
                                                ${isDeadlineUpdated || !!question_overrides.length ? 'updated' : ''}
                                                ${selectedItems.length ? 'inactive' : ''}
                                                ${(!isDeadlineUpdated && !question_overrides.length && (this.areItemsSelected() || isRespondedView || isReadOnly)) ? 'alwaysHide' : ''}
                                            `}
                                                onClick={(e) => !this.areItemsSelected() && this.toggleCandidateAction(eCandidateActionTypes.EXTEND, rowData, e)}
                                            >
                                                <Tooltip
                                                    classes={`
                                                        action-tooltip
                                                        lines-${this.getExtendedTooltipText({deadline, question_overrides}).length}
                                                    `}
                                                    positioning="onLeft"
                                                    item={<div className="label">{IconClock}</div>}
                                                    description={
                                                        <div className="content">
                                                            {this.getExtendedTooltipText({deadline, question_overrides}).map((sentence, index) => (
                                                                <span key={`tooltip-${index}`} className="tooltip-sentence">{sentence}</span>
                                                            ))}
                                                        </div>
                                                    }
                                                />
                                            </button>
                                        )}
                                        {isLiveInterviewView && (
                                            <div
                                                className={`
                                                    candidate-edit
                                                    ${this.areItemsSelected() ? 'alwaysHide' : ''}
                                                `}
                                                onClick={(e) => !this.areItemsSelected() && this.toggleLiveInterview(rowData, e)}
                                            >
                                                <Tooltip
                                                    classes={`
                                                        action-tooltip
                                                        lines-1
                                                    `}
                                                    positioning="onLeft"
                                                    item={<div className="label">{IconPencil}</div>}
                                                    description={
                                                        <div className="content">
                                                            Edit Live Interview Schedule
                                                        </div>
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}

                                {(isInvitedView || isLiveInterviewView) && (
                                    <div className="email">{email}</div>
                                )}

                                {isInvitedView && (
                                    <div className="phone">{phone}</div>
                                )}

                                {(isLiveInterviewView || isLiveInterviewEvaluatingView) && (
                                    <React.Fragment>
                                        <div className="interview-at">
                                            {isLiveInterviewer && (
                                                <div className="interview-url" onClick={() => {
                                                      window.location.href = this.props.urlFor('assess-candidate-evaluation', {job_id, job_stage_type, job_stage_id, candidate_id});
                                                      openLiveInterview(company_id, staffId, job_id, job_stage_id, candidate_id);
                                                  }}>
                                                    {formatDateTime(interview_at, eDateFormatTypes.FULL_WITH_AM_PM)}
                                                </div>
                                            )}
                                            {!isLiveInterviewer && formatDateTime(interview_at, eDateFormatTypes.FULL_WITH_AM_PM)}
                                        </div>
                                        {/*<div className="evaluate-link">
                                            {isLiveInterviewer && (
                                                <a className={`
                                                        react-button active
                                                        evaluate
                                                        ${isFirmRecommendation ? 'outline decided' : 'undecided'}
                                                    `}
                                                    href={this.getCandidateEvaluationLink(candidate_id)}
                                                >
                                                {isFirmRecommendation ?
                                                    <React.Fragment>{IconTick}<span className={`button-text`}>Evaluated</span></React.Fragment> :
                                                    'Evaluate'}
                                                </a>
                                            )}
                                        </div>*/}
                                    </React.Fragment>
                                )}

                                {(isEvaluatingView || isRespondedView) && (
                                    <div className={`questions-scores`}>
                                        {!!requires_response_evaluation && [...Array(number_of_questions)].fill(1).map((_x, i) =>
                                            <div key={`question-score-${i+1}`} className={`question-score`}>
                                            <span className={`score-label`}>
                                                {i === 0 ? <span className={"score-label-q"}>Q</span> : ''}{i+1}
                                            </span>
                                                <span className={`score-value`}>{candidate[`q${i+1}`] || '-'}</span>
                                            </div>
                                        )}
                                        {!!requires_response_evaluation && (
                                            <div className="q-score">
                                                <span className={`score-label`}>Avg.</span>
                                                <span className={`score-value`}>{q_score || '-'}</span>
                                            </div>
                                        )}
                                        {!!evaluation_attributes.length && (
                                            <div className="attr-score">
                                                <span className={`score-label`}>Attr.</span>
                                                <span className={`score-value`}>{attr_score || '-'}</span>
                                            </div>
                                        )}
                                        {(requires_response_evaluation || !!evaluation_attributes.length) && (
                                            <div className="total-score">
                                                <span className={`score-label`}>Total</span>
                                                <span className={`score-value`}>{total_score || '-'}</span>
                                            </div>
                                        )}
                                    </div>
                                )}

                                {isInvitedView && (
                                    <div className="invite-sent-on">
                                        {!!lastInvited && (
                                            <React.Fragment>
                                                {is_invitation_email_delivered && (
                                                    <span className={`invitation-email-status`}>{has_opened_interview ?
                                                        <Tooltip
                                                            positioning="onRight"
                                                            item={<div className="label">{IconMailOpen}</div>}
                                                            description={<div className="content">Invite opened</div>}
                                                        /> :
                                                        <Tooltip
                                                            positioning="onRight"
                                                            item={<div className="label">{IconMailDelivered}</div>}
                                                            description={<div className="content">Invite delivered</div>}
                                                        />
                                                    }</span>
                                                )}
                                                {!is_invitation_email_delivered && (
                                                    <span className={`invitation-email-status`}>
                                                        <Tooltip
                                                            positioning="onRight"
                                                            item={<div className="label">{IconMail}</div>}
                                                            description={<div className="content">Invite sent</div>}
                                                        />
                                                    </span>
                                                )}
                                                <span className={`sent-on-full`}>{formatDateTime(lastInvited)}</span>
                                                <span className={`sent-on-simpler`}>{formatDateTime(lastInvited, eDateFormatTypes.SIMPLER)}</span>
                                                {!isReadOnly && !is_deadline_expired && (
                                                    <SendInvite
                                                        label={
                                                            <Tooltip
                                                                positioning="onLeft"
                                                                item={<div className="label">{IconRefresh}</div>}
                                                                description={
                                                                    <div className="content">
                                                                        {!!invitation_email_template_id ? "Resend Invite" : "No invitation email to send."}
                                                                    </div>
                                                                }
                                                            />
                                                        }
                                                        job_stage={job_stage}
                                                        candidate_id={candidate_id}
                                                        isDisabled={!invitation_email_template_id}
                                                        onInviteSuccess={onInviteSuccess}
                                                    />
                                                )}
                                            </React.Fragment>
                                        )}
                                    </div>
                                )}

                                {(isRespondedView || isEvaluatingView || isLiveInterviewView || isLiveInterviewEvaluatingView) && (
                                    <div className={`recommendation ${isEvaluatingView || isLiveInterviewEvaluatingView ? 'single' : ''}`}>
                                        {(isRespondedView || isLiveInterviewView) && (
                                            <React.Fragment>
                                                {!!recommendationsYes.length &&
                                                    recommendationsYes.map((item, index) => (
                                                        <AvatarWithLabel
                                                            key={`recommendation-yes-${index}`}
                                                            classes="recommendation-yes"
                                                            data={item}
                                                            size="xs"
                                                            label={eRecommendationTypes.YES}
                                                        />
                                                    ))
                                                }
                                                {!!recommendationsMaybe.length &&
                                                    recommendationsMaybe.map((item, index) => (
                                                        <AvatarWithLabel
                                                            key={`recommendation-maybe-${index}`}
                                                            classes="recommendation-maybe"
                                                            data={item}
                                                            size="xs"
                                                            label={eRecommendationTypes.MAYBE}
                                                        />
                                                    ))
                                                }
                                                {!!recommendationsNo.length &&
                                                    recommendationsNo.map((item, index) => (
                                                        <AvatarWithLabel
                                                            key={`recommendation-no-${index}`}
                                                            classes="recommendation-no"
                                                            data={item}
                                                            size="xs"
                                                            label={eRecommendationTypes.NO}
                                                        />
                                                    ))
                                                }
                                                {!!unsubmittedEvaluations.length &&
                                                    unsubmittedEvaluations.map((item, index) => {
                                                        const avatar = {
                                                            classes: "evaluation-in-progress",
                                                            data: item,
                                                            size: "xs"
                                                        };
                                                        return (
                                                            <Avatar key={`in-progress-${index}`} {...avatar} badgeText={"..."} />
                                                        );
                                                    })
                                                }
                                            </React.Fragment>
                                        )}
                                        {(isEvaluatingView || isLiveInterviewEvaluatingView) && (
                                            <React.Fragment>
                                                {!!evaluatorRecommendation && (
                                                    <span className={`recommendation-label ${evaluatorRecommendation} ${!isEvaluatorEvaluationSubmitted ? 'in-progress' : ''}`}>
                                                        {evaluatorRecommendation}
                                                    </span>
                                                )}
                                                {!evaluatorRecommendation && (
                                                    <span className={`recommendation`}>-</span>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </div>
                                )}

                                {showLiveInterview && (isInvitedView || isRespondedView) && (
                                    <div className={`live-button`}>
                                        <div
                                            className={`
                                                live-button-link
                                                ${this.areItemsSelected() ? 'inactive' : ''}
                                                ${!!liveCandidate ? 'scheduled' : 'unscheduled'}
                                            `}
                                            onClick={(e) => !this.areItemsSelected() && this.toggleLiveInterview(rowData, e)}
                                        >
                                            <Tooltip
                                                positioning="onLeft"
                                                item={<div className={`label react-button ${!!liveCandidate ? isLiveInterviewer ? 'info' : 'active' : 'outline'}`}>{IconLive}</div>}
                                                description={
                                                    <div className={`content`}>
                                                        {!!liveCandidate ? 'Live Interview Scheduled' : 'Schedule Live Interview'}
                                                    </div>
                                                }
                                            />
                                        </div>
                                    </div>
                                )}

                                {isInvitedView && (
                                    <div className={`deadline ${deadlineClasses}`}>
                                        <span className={`deadline-text ${showCandidateActions ? 'has-action-dropdown' : ''}`}>
                                            <span>{formatDateTime(deadline, "DD MMM YYYY")}</span>
                                            {deadline_timezone && deadline_timezone !== job_timezone &&
                                                <Tooltip
                                                    positioning='onLeft'
                                                    item={IconGlobe}
                                                    description={
                                                        <div className="content">
                                                            {deadline_timezone}
                                                        </div>
                                                    }
                                                />
                                            }
                                        </span>
                                        {showCandidateActions && (
                                            <ActionDropdown
                                                classes={`${this.areItemsSelected() ? 'hide' : ''}`}
                                                items={candidateActions}
                                                autoToggleClose={true}
                                            />
                                        )}
                                    </div>
                                )}

                                {(isRespondedView || isEvaluatingView || isLiveInterviewView || isLiveInterviewEvaluatingView) && (
                                    <div className={`decisions`}>
                                        {(isEvaluatingView || isLiveInterviewEvaluatingView) && (
                                            <a
                                                className={`
                                                    react-button
                                                    evaluate
                                                    active
                                                    ${isFirmRecommendation ? 'outline decided' : 'undecided'}
                                                `}
                                                href={showSIEvaluationLayout ? evaluation_url : this.getCandidateEvaluationLink(candidate_id)}
                                                target={showSIEvaluationLayout ? '_blank' : '_self'}
                                                rel="noopener noreferrer"
                                            >
                                                {isFirmRecommendation ?
                                                    <React.Fragment>{IconTick}<span className={`button-text`}>Evaluated</span></React.Fragment> :
                                                    'Evaluate'
                                                }
                                            </a>
                                        )}
                                        {isRespondedView && (
                                            <React.Fragment>
                                                {!decision && !!submittedEvaluations.length && (
                                                    <React.Fragment>
                                                        <button
                                                            className={`
                                                                react-button
                                                                undecided outline
                                                                ${eDecisionTypes.ACCEPTED} active
                                                                ${showCandidateActions ? 'has-action-dropdown' : ''}
                                                            `}
                                                            onClick={() => this.shouldShowDecisionConfirmationDialog(eDecisionTypes.ACCEPTED) ?
                                                                this.updateDecisionsConfirmation(eDecisionTypes.ACCEPTED, candidate_id) :
                                                                this.updateDecisions(eDecisionTypes.ACCEPTED, candidate_id)
                                                            }
                                                        >
                                                            <span className={`button-text`}>Accept</span>
                                                        </button>
                                                        <button
                                                            className={`
                                                                react-button
                                                                undecided outline
                                                                ${eDecisionTypes.DECLINED} warning
                                                                ${showCandidateActions ? 'has-action-dropdown' : ''}
                                                            `}
                                                            onClick={() => this.shouldShowDecisionConfirmationDialog(eDecisionTypes.DECLINED) ?
                                                                this.updateDecisionsConfirmation(eDecisionTypes.DECLINED, candidate_id) :
                                                                this.updateDecisions(eDecisionTypes.DECLINED, candidate_id)
                                                            }
                                                        >
                                                            <span className={`button-text`}>Decline</span>
                                                        </button>
                                                    </React.Fragment>
                                                )}
                                                {decision && (
                                                    <React.Fragment>
                                                        <button className={`
                                                            react-button outline decided
                                                            ${decision}
                                                            ${decision === eDecisionTypes.ACCEPTED ? 'active': ''}
                                                            ${decision === eDecisionTypes.DECLINED ? 'warning': ''}
                                                            ${showCandidateActions ? 'has-action-dropdown' : ''}
                                                        `}
                                                        >
                                                            {decision === eDecisionTypes.ACCEPTED && IconTick}
                                                            {decision === eDecisionTypes.DECLINED && IconCross}
                                                            <span className={`button-text`}>
                                                                {decision}
                                                            </span>
                                                        </button>
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        )}
                                        {isLiveInterviewView && (
                                            <React.Fragment>
                                                {1 < 2 && (
                                                    <React.Fragment>
                                                        <button
                                                            className={`
                                                                react-button
                                                                undecided outline
                                                                ${eDecisionTypes.ACCEPTED} active
                                                                ${showCandidateActions ? 'has-action-dropdown' : ''}
                                                            `}
                                                            /*onClick={() => this.shouldShowDecisionConfirmationDialog(eDecisionTypes.ACCEPTED) ?
                                                                this.updateDecisionsConfirmation(eDecisionTypes.ACCEPTED, candidate_id) :
                                                                this.updateDecisions(eDecisionTypes.ACCEPTED, candidate_id)
                                                            }*/
                                                        >
                                                            <span className={`button-text`}>Accept</span>
                                                        </button>
                                                        <button
                                                            className={`
                                                                react-button
                                                                undecided outline
                                                                ${eDecisionTypes.DECLINED} warning
                                                                ${showCandidateActions ? 'has-action-dropdown' : ''}
                                                            `}
                                                            /*onClick={() => this.shouldShowDecisionConfirmationDialog(eDecisionTypes.DECLINED) ?
                                                                this.updateDecisionsConfirmation(eDecisionTypes.DECLINED, candidate_id) :
                                                                this.updateDecisions(eDecisionTypes.DECLINED, candidate_id)
                                                            }*/
                                                        >
                                                            <span className={`button-text`}>Decline</span>
                                                        </button>
                                                    </React.Fragment>
                                                )}
                                                {1 === 2 && (
                                                    <button
                                                        className={`
                                                            react-button outline decided
                                                            ${decision}
                                                            ${decision === eDecisionTypes.ACCEPTED ? 'active': ''}
                                                            ${decision === eDecisionTypes.DECLINED ? 'warning': ''}
                                                        `}
                                                    >
                                                        {decision === eDecisionTypes.ACCEPTED && IconTick}
                                                        {decision === eDecisionTypes.DECLINED && IconCross}
                                                        <span className={`button-text`}>
                                                            {decision}
                                                        </span>
                                                    </button>
                                                )}
                                            </React.Fragment>
                                        )}
                                        {!(isEvaluatingView || isLiveInterviewEvaluatingView) && showCandidateActions && (
                                            <ActionDropdown
                                                classes={`${this.areItemsSelected() ? 'hide' : ''}`}
                                                items={this.getCandidateActions(displayType, rowData)}
                                                autoToggleClose={true}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>

                {showInterview && (
                    <InterviewStructure
                        title={interview_name}
                        job_id={job_id}
                        job_stage_type={job_stage_type}
                        job_stage_id={job_stage_id}
                        interview_id={selectedInterviewId}
                        candidate_id={selectedInterviewSampleCandidateId}
                        onClose={this.toggleInterviewTemplate}
                    />
                )}

                {showCandidateAction && (
                    <React.Fragment>
                        {[eCandidateActionTypes.EDIT].includes(candidateActionType) && (
                            <CandidateEdit
                                candidate={temporarilySelectedItem}
                                job_id={job_id}
                                job_stage_type={job_stage_type}
                                job_stage_id={job_stage_id}
                                onUpdate={onSaveCandidateOverrides}
                                onClose={this.toggleCandidateAction}
                            />
                        )}
                        {[eCandidateActionTypes.EXTEND, eCandidateActionTypes.RESEND].includes(candidateActionType) && (
                            <CandidateAction
                                actionType={candidateActionType}
                                candidates={this.areItemsSelected() ? selectedItems : [temporarilySelectedItem]}
                                job_id={job_id}
                                job_stage_type={job_stage_type}
                                job_stage_id={job_stage_id}
                                job_timezone={job_timezone}
                                hasInvitationEmail={!!invitation_email_template_id}
                                isDisabled={isRespondedView || isReadOnly}
                                onUpdate={onSaveCandidateOverrides}
                                onClose={this.toggleCandidateAction}
                            />
                        )}
                    </React.Fragment>
                )}

                {showCandidateActivity && (
                    <CandidateActivity
                        candidate={temporarilySelectedItem}
                        job_id={job_id}
                        job_stage_type={job_stage_type}
                        job_stage_id={job_stage_id}
                        onUpdate={onSaveCandidateOverrides}
                        onClose={this.toggleCandidateActivity}
                    />
                )}

                {showCandidateSharing && (
                    <ShareCandidateResponse
                        candidates={this.areItemsSelected() ? selectedItems : [temporarilySelectedItem]}
                        job_id={job_id}
                        job_stage_type={job_stage_type}
                        job_stage_id={job_stage_id}
                        onUpdate={this.onSharingSuccess}
                        onClose={this.toggleCandidateSharing}
                    />
                )}

                {showDecisionDialog && (
                    <CustomModal
                        title={`${decisionDialogDecision ? capitalize(confirmation_decision_text) + ' Candidate' : 'Clear Candidate Decision'}`}
                        classes={`open`}
                        onClose={this.onClose}
                    >
                        <div className={`dialog`}>
                            <div className={`dialog-message`}>
                                {!!decisionDialogDecision && (
                                    `Marking the candidate as ${decisionDialogDecision} will also send an email to the candidate informing this decision.`
                                )}
                                {!decisionDialogDecision && (
                                    `An email regarding your decision was previously sent to the candidate. When you clear and then change a decision, an
                                    additional email with a different decision will be sent to the candidate. To avoid confusion, please contact the
                                    candidate directly and explain the situation.`
                                )}
                                <br />
                                <br />
                                Would you like to proceed?
                            </div>
                            <div className={`cancel-link`} onClick={this.updateDecisionConfirmationCancel}>Cancel</div>
                            <div className={`react-button confirm-link`} onClick={() => this.updateDecisions(decisionDialogDecision, decisionDialogCandidateId)}>Confirm</div>
                        </div>
                    </CustomModal>
                )}

                {showCandidateDeleteDialog && (
                    <CustomModal
                        title={`Remove Candidate`}
                        classes={`open`}
                        onClose={this.onClose}
                    >
                        <div className={`dialog`}>
                            {!showDeletionReason && (
                                <div className={`dialog-message`}>Are you sure you would like to remove <span className={`attention`}>{showCandidateDeleteDialogFullName}</span> from this job stage?</div>
                            )}
                            {showDeletionReason && (
                                <div className={`dialog-message`}>
                                    <div className={`message`}>Please specify a reason for deleting <span className={`attention`}>{showCandidateDeleteDialogFullName}</span> from this job stage:</div>
                                    <div className={`reason`}>
                                        {objectToArray(DELETION_REASONS).map((value) =>
                                            <div className={`reason`}>
                                                <CustomRadioButton
                                                    id={value}
                                                    classes="selection"
                                                    value={`${DELETION_REASONS_VALUES[value]}${value === candidateDeleteReason && candidateDeleteReason === DELETION_REASONS.OTHER ? "*" : ""}`}
                                                    selected={value === candidateDeleteReason}
                                                    onChange={() => this.deleteCandidateReasonUpdate(value)}
                                                />
                                            </div>
                                        )}
                                        <CustomTextField
                                            id={`reason_other`}
                                            classes={`reason_other`}
                                            name={`reason_other`}
                                            required={true}
                                            disabled={candidateDeleteReason !== DELETION_REASONS.OTHER}
                                            placeholder={`(Please specify)`}
                                            label={``}
                                            value={candidateDeleteReasonOther || ""}
                                            onChange={this.deleteCandidateReasonOtherUpdate}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className={`cancel-link`} onClick={this.closeDeleteCandidateDialog}>Cancel</div>
                            <div
                                className={`
                                    react-button
                                    confirm-link
                                    ${!candidateDeleteReasonPopulated ? 'disabled' : ''}
                                `}
                                 onClick={candidateDeleteReasonPopulated ? this.deleteCandidateConfirmed : null}
                            >
                                Delete
                            </div>
                        </div>
                    </CustomModal>
                )}

                {showLiveInterviewDialog && (
                    <CustomModal
                        title={`Live Interview`}
                        classes={`live-interview-modal open`}
                        onClose={this.toggleLiveInterview}
                        closeOnOverlayClick={false}
                    >
                        <LiveInterview
                            job={job}
                            job_stage={job_stage}
                            details={this.getLiveInterviewDetails()}
                            candidate={temporarilySelectedItem}
                            company_id={company_id}
                            staff_id={staffId}
                            updateStatusMessage={this.updateStatusMessage}
                            onComplete={this.onLiveInterviewSuccess}
                        />
                    </CustomModal>
                )}
            </React.Fragment>
        );
    }
}

CandidatesTable.propTypes = {
    displayType: PropTypes.oneOf(objectToArray(eCandidateTableTypes)),
    company_id: PropTypes.number.isRequired,
    staff_id: PropTypes.number.isRequired,
    is_alcami_staff: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    title: PropTypes.string,
    interview_name: PropTypes.string,
    job: PropTypes.shape({
        job_id: PropTypes.number,
        title: PropTypes.string
    }),
    job_stage: PropTypes.shape({
        job_id: PropTypes.number,
        job_stage_type: PropTypes.oneOf(objectToArray(eJobStageTypes)).isRequired,
        job_stage_id: PropTypes.number,
        deadline_type: PropTypes.oneOf(objectToArray(eDeadlineType)),
        deadline: PropTypes.string,
        ongoing_deadline_days: PropTypes.number,
        candidates: PropTypes.arrayOf(
            PropTypes.shape({
                last_name: PropTypes.string,
                cvFilename: PropTypes.string,
                email: PropTypes.string,
                phone: PropTypes.string,
                deadline: PropTypes.string,
                question_overrides: PropTypes.array,
                recommendedBy: PropTypes.array,
                maybeBy: PropTypes.array,
                rejectedBy: PropTypes.array,
            })
        ),
        evaluators: PropTypes.array,
        invitation_email_template_id: PropTypes.number
    }),
    liveInterviewCandidates: PropTypes.array,
    classes: PropTypes.string,
    onSaveCandidateOverrides: PropTypes.func,
    onDecisionUpdate: PropTypes.func,
    onCandidateDelete: PropTypes.func,
    onInviteSuccess: PropTypes.func,
    onLiveDetailsUpdate: PropTypes.func,
    onStatusMessageUpdate: PropTypes.func,
    urlFor: PropTypes.func.isRequired,
    integrationProvider: PropTypes.string,
    isLazyLoadEnabled: PropTypes.bool
};

CandidatesTable.defaultProps = {
    displayType: eCandidateTableTypes.INVITED,
    company_id: 0,
    staff_id: 0,
    is_alcami_staff: false,
    isReadOnly: false,
    title: '',
    interview_name: '',
    job: {
        job_id: null,
        title: null
    },
    job_stage: {},
    candidates: [],
    liveInterviewCandidates: [],
    classes: null,
    onSaveCandidateOverrides: null,
    onDecisionUpdate: null,
    onCandidateDelete: null,
    onInviteSuccess: null,
    onLiveDetailsUpdate: null,
    onStatusMessageUpdate: null,
    isLazyLoadEnabled: false
};

export default CandidatesTable;
