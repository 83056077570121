import React from "react";
import * as PropTypes from "prop-types";

import "./style.scss";


const ScoringTable = ({ overall_score, attributes }) => {
    const hasOverallScore = overall_score !== "" && overall_score !== 0;
    return (
        <div className={`scoring-table`}>
            <div className={`section-title`}>Final Assessment</div>
            <div className={`scoring-table-section ${hasOverallScore ? 'has-overall-score' : ''}`}>
                {hasOverallScore && (
                    <div className={`overall-score`}>
                        <div className={`header`}>Overall<br/>Score</div>
                        <div className={`score`}>{overall_score}</div>
                    </div>
                )}
                <div className={`attributes-table`}>
                    {attributes.map((row, index) => (
                        <div key={`attributes-row-${index}`} className={`attributes-row row-${index}`}>
                            {row.map((cell, i) => (
                                <div key={`attributes-item-${i}`} className={`${i === 0 ? 'header' : 'attributes-item'}`}>
                                    {cell}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

ScoringTable.propTypes = {
    overall_score: PropTypes.string,
    attributes: PropTypes.array
};

ScoringTable.defaultProps = {
    overall_score: "",
    attributes: []
};

export default ScoringTable;