import React from "react";
import * as PropTypes from 'prop-types';

import Container from "../Container";
import ShortLink from "../ShortLink";
import SendToPageUpLink from "../SendToPageUpLink";
import {
    cloneJob,
    deleteJob,
    getJobSummary,
    getJobStageCandidateSummary,
    getReportCandidateEvaluationsUrl,
    setJobStatus,
    setJobAsTemplate,
    createInterviewStage
} from "../../api";
import LabelValueTile from "../LabelValueTile";
import {
    eCandidateSortTypes,
    eCandidateTableTypes,
    eJobsType,
    eJobsTypeVerb,
    eUserTypes,
    eStatusMessageStyle,
    eStatusMessageType,
    formatDateTime,
    objectToArray,
    scrollToID,
    scrollToTop,
    eJobStageTypes,
    eUserSettings,
    eCompanyFeatures,
    enableLazyLoading,
    eInterviewTerminologies,
    getDeadlineValue,
    isAdminUserSettingEnabled,
    isCompanyFeatureEnabled,
    getValue
} from "../../utils";
import { capitalize, countBy, get, groupBy, orderBy } from "lodash";
import CandidatesInvite from "../CandidatesInvite";
import CandidatesTable from "../CandidatesTable";
import CustomModal from "../CustomModal";
import StatusMessage from "../StatusMessage";
import ActionDropdown from "../ActionDropdown";
import JobStageHeader from "./JobStageHeader";
import EvaluatorsInvite from "../EvaluatorsInvite";

import './style.scss';

class JobDetails extends React.Component {
    constructor(props) {
        super(props);

        this.loadData = this.loadData.bind(this);
        this.loadJobSummary = this.loadJobSummary.bind(this);
        this.loadCandidatesSummary = this.loadCandidatesSummary.bind(this);
        this.showLoadingError = this.showLoadingError.bind(this);
        this.goToEditPage = this.goToEditPage.bind(this);

        this.shouldShowShortURL = this.shouldShowShortURL.bind(this);
        this.shouldShowLiveInterview = this.shouldShowLiveInterview.bind(this);
        this.isManagerView = this.isManagerView.bind(this);
        this.isEvaluatorView = this.isEvaluatorView.bind(this);

        this.getJobStageMenu = this.getJobStageMenu.bind(this);
        this.getComponentClassNames = this.getComponentClassNames.bind(this);
        this.getJobActionItems = this.getJobActionItems.bind(this);
        this.getJobHeaderContent = this.getJobHeaderContent.bind(this);
        this.getJobSummaryContent = this.getJobSummaryContent.bind(this);

        this.sortCandidates = this.sortCandidates.bind(this);

        this.addJobStage = this.addJobStage.bind(this);
        this.duplicateJob = this.duplicateJob.bind(this);

        this.canJobBeArchived = this.canJobBeArchived.bind(this);
        this.confirmJobStatusChange = this.confirmJobStatusChange.bind(this);
        this.updateJobStatus = this.updateJobStatus.bind(this);

        this.toggleCandidatesInvite = this.toggleCandidatesInvite.bind(this);
        this.toggleEvaluatorsInvite = this.toggleEvaluatorsInvite.bind(this);
        this.getLiveInterviewCandidates = this.getLiveInterviewCandidates.bind(this);
        this.onCandidateChange = this.onCandidateChange.bind(this);
        this.updateStatusMessage = this.updateStatusMessage.bind(this);
        this.onDecisionUpdate = this.onDecisionUpdate.bind(this);
        this.onCandidateDelete = this.onCandidateDelete.bind(this);
        this.onLiveDetailsUpdate = this.onLiveDetailsUpdate.bind(this);

        this.onInviteSuccess = this.onInviteSuccess.bind(this);
        this.onSaveCandidateOverrides = this.onSaveCandidateOverrides.bind(this);
        this.refreshJob = this.refreshJob.bind(this);
        this.backToJobTilesLink = this.backToJobTilesLink.bind(this);
        this.updateActiveSortValue = this.updateActiveSortValue.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.clearDialogProps = this.clearDialogProps.bind(this);
        this.deleteJob = this.deleteJob.bind(this);

        this.highlightCandidateRow = this.highlightCandidateRow.bind(this);

        this.state = {
            loading: true,

            shortUrl: null,
            job: {},
            candidates_summary: [],
            statusMessage: {
                message: null,
                type: null,
                reset: null
            },
            confirmDialogProps: null,

            candidatesInviteProps: {
                shown: false,
                job_stage_id: null,
                hasInvitationEmail: null
            },
            evaluatorsInviteProps: {
                shown: false,
                job_stage_id: null
            },
            isLazyLoadEnabled: false
        };
    }

    async loadData() {
        const { job_id, scrollToCandidateId } = this.props;

        if (!job_id) {
            this.setState({
                loading: false
            });
            return false;
        }

        await this.loadJobSummary();

        if (!!get(this.state, ["job", "job_id"])) {
            await this.loadCandidatesSummary();

            if (!!scrollToCandidateId) {
                setTimeout(() => {
                    scrollToID(`id_${scrollToCandidateId}`);
                    this.highlightCandidateRow(`id_${scrollToCandidateId}`);
                }, 0);
            }
        }
    }

    async loadJobSummary(show_loading_indicator = true) {
        const { job_id } = this.props;

        if (show_loading_indicator) {
            this.setState({
                loading: true
            });
        }

        const job = await getJobSummary(job_id);

        if (job.error) {
            this.showLoadingError(this.isEvaluatorView());
            return false;
        }

        this.setState({
            loading: false,
            job
        });
    }

    async loadCandidatesSummary() {
        const { job: { job_stages } } = this.state;
        const { job_id } = this.props;
        const evaluatorStaffId = this.getEvaluatorStaffId();

        const candidatesSummaryPromises = job_stages.map(async(job_stage) => {
            const {job_stage_type, job_stage_id} = job_stage;

            const candidateSummary = await getJobStageCandidateSummary(job_id, job_stage_type, job_stage_id, evaluatorStaffId);
            if (candidateSummary.error) {
                this.showLoadingError(this.isEvaluatorView());
                return false;
            }
            const candidates = (getValue(candidateSummary, [])).map((candidate) => {
                const {
                    question_scores = [],
                    evaluator_recommendations = [],

                    questions_score = 0,
                    attributes_score = 0,
                    total_score,
                    recommendation,
                    submitted_at
                } = candidate;

                // recommendation counts
                let evaluations, recommendation_yes, recommendation_maybe, recommendation_no;
                const scores = {};
                if (this.isEvaluatorView()) {
                    recommendation_yes = (recommendation === 'yes') ? 1 : 0;
                    recommendation_no = (recommendation === 'no') ? 1 : 0;
                    recommendation_maybe = (recommendation === 'maybe') ? 1 : 0;

                    question_scores.forEach(({score}, index) => {
                        scores[`q${index + 1}`] = getValue(score, 0);
                    });

                    evaluations = [{ staff_id: evaluatorStaffId, submitted_at, recommendation }]
                } else {
                    (
                        {
                            yes: recommendation_yes = 0,
                            maybe: recommendation_maybe = 0,
                            no: recommendation_no = 0
                        } = countBy(evaluator_recommendations.map(({recommendation: r}) => r))
                    );

                    question_scores.forEach(({average_score}, index) => {
                        scores[`q${index + 1}`] = getValue(average_score, 0);
                    });

                    // CandidatesTable needs to see submitted_at in the evaluation, or it will ignore it.
                    evaluations = evaluator_recommendations.map((evaluation) => ({...evaluation, submitted_at: new Date()}))
                }

                return {
                    ...candidate,
                    last_invite_sent_on: getValue((getValue(candidate.invited_at, [])).slice(-1).pop(),null),
                    ...scores,
                    q_score: questions_score,
                    attr_score: attributes_score,
                    total_score,
                    recommendation_yes,
                    recommendation_maybe,
                    recommendation_no,
                    evaluations,
                    evaluation_status: this.isEvaluatorView() ? !!candidate.submitted_at : undefined
                };
            });
            return {job_stage_id, job_stage_type, candidates};
        });

        this.setState({
            candidates_summary: await Promise.all(candidatesSummaryPromises)
        }, () => {return true;});
    }

    showLoadingError(isEvaluatorView) {
        const { urlFor } = this.props;
        this.setState({
            confirmDialogProps: {
                title: "Job Not Found",
                message: "The job you are trying to access cannot be found. "
                    + (
                        isEvaluatorView ?
                            "This could indicate that the job might have been deleted, or you are not assigned as an evaluator for this job." :
                            "This could indicate that the job might have been deleted."
                    ),
                actions: [
                    {
                        label: "Back to Job List",
                        onClick: () => {
                            window.location.href = urlFor(isEvaluatorView ? "assess-job-list" : "manage-job-list")
                        }
                    }
                ]
            }
        });
    }

    goToEditPage(jobId) {
        window.location.href = this.props.urlFor('manage-job-edit', { job_id: jobId });
    }

    shouldShowShortURL(shortUrl) {
        return !this.isEvaluatorView() && !!shortUrl;
    }

    shouldShowLiveInterview() {
        const { features = [] } = this.props;
        return isAdminUserSettingEnabled(eUserSettings.LIVE_INTERVIEW) ||
            isCompanyFeatureEnabled(eCompanyFeatures.LIVE_INTERVIEW, features);
    }

    isManagerView() {
        return this.props.viewType === eUserTypes.MANAGER;
    }

    isEvaluatorView() {
        return this.props.viewType === eUserTypes.EVALUATOR;
    }

    getEvaluatorStaffId() {
        return this.isEvaluatorView() ? this.props.staff_id : null;
    }

    getJobStageMenu(jobStage, jobStageActionItems, isJobArchived) {
        const { job_stage_id, short_url } = jobStage;

        return (
            <div className={`job-stage-menu`}>
                {!isJobArchived && (
                    <div
                        className={`react-button active invite`}
                        onClick={() => this.toggleCandidatesInvite(job_stage_id)}
                    >
                        Invite
                    </div>
                )}
                {this.shouldShowShortURL(short_url) && (
                    <ShortLink classes="short-link" label="Link" value={short_url} />
                )}
                {!!jobStageActionItems.length && (
                    <div className="job-stage-actions">
                        <ActionDropdown items={jobStageActionItems} />
                    </div>
                )}
            </div>
        );
    }

    getComponentClassNames() {
        const { integrationProvider } = this.props;

        let returnValue = `job-details has-intercom`;

        if (!this.isManagerView()) {
            returnValue += ` evaluator`;
        }

        if (integrationProvider) {
            returnValue += ` integration`;
        }

        return returnValue;
    }

    getJobActionItems() {
        const { is_alcami_staff } = this.props;

        const { job } = this.state;
        const {
            job_id,
            job_stages = [],
            is_template,
            test,
            status
        } = job;

        const jobStages = job?.job_stages;
        const currentJobStage = jobStages?.[0];

        const jobStageType = currentJobStage?.job_stage_type;
        const jobStageId =  currentJobStage?.job_stage_id;

        const isJobArchived = status === eJobsType.ARCHIVED;

        const actionItems = [
            {
                id: "edit",
                firstLine: "Edit",
                disabled: status === eJobsType.ARCHIVED,
                onClick: () => this.goToEditPage(job_id)
            }
        ];

        if (!isJobArchived) {
            /* TASK: wait for API endpoint to support before enabling, like:
                actionItems.push({
                    id: "add-one-way-stage",
                    firstLine: "Add One-way Stage",
                    onClick: () => this.addJobStage(jobId)
                });
            */

            const hasLiveStage = job_stages.find(({job_stage_type}) => job_stage_type === eJobStageTypes.LIVE);

            if (this.shouldShowLiveInterview() && !hasLiveStage) {
                actionItems.push({
                    id: "add-live-stage",
                    firstLine: "Add Live Stage",
                    onClick: () => this.addJobStage(job_id, eJobStageTypes.LIVE)
                });
            }
        }

        actionItems.push({
            id: "clone",
            firstLine: "Clone",
            onClick: () => this.duplicateJob(job_id)
        });

        if (isJobArchived) {
            actionItems.push({
                id: "reactivate",
                firstLine: "Reactivate...",
                onClick: () => this.confirmJobStatusChange(job_id, eJobsType.ACTIVE)
            });
        } else {
            actionItems.push({
                id: "archive",
                firstLine: "Archive...",
                disabled: !(this.canJobBeArchived()),
                onClick: () => this.confirmJobStatusChange(job_id, eJobsType.ARCHIVED)
            });
        }

        if (isAdminUserSettingEnabled(eUserSettings.JOB_TEMPLATES)) {
            actionItems.push({
                id: "template",
                firstLine: <div><span className={`mark ${is_template ? 'filled' : ''}`} /> Job Template</div>,
                onClick: () => setJobAsTemplate(job_id, !is_template).then(() => {
                    this.setState({
                        job: {
                            ...this.state.job,
                            is_template: !is_template
                        }
                    });
                }),
                toggleDropdownOnClick: false
            });
        }

        actionItems.push({
            id: "export",
            firstLine: "Export",
            link: getReportCandidateEvaluationsUrl(job_id, jobStageType, jobStageId)
        });

        if (test || is_alcami_staff) {
            actionItems.push(
                {
                    id: "delete",
                    firstLine: "Delete...",
                    disabled: false,
                    onClick: () => this.confirmDelete(job_id)
                }
            );
        }
        return actionItems;
    }

    getJobHeaderContent() {
        const {
            showBreadcrumbs
        } = this.props;

        const { job } = this.state;
        const {
            job_id,
            title,
            id_for_pageup_battery,
            test
        } = job;

        return (
            <div className="header">
                {showBreadcrumbs && (
                    <div className={`breadcrumbs`}>
                        <a href={this.backToJobTilesLink()} className={'back-link'}>&lt; <span>back</span></a>
                    </div>
                )}
                <div className="title-id">
                    <div className={`title ${test ? 'test' : ''}`}>{title}</div>
                    <sup className="id">#{job_id}</sup>
                </div>
                <div className="menu">
                    {!this.isEvaluatorView() && (
                        <React.Fragment>
                            {(id_for_pageup_battery != null) && (
                                <SendToPageUpLink classes="send-to-pageup-link" value={id_for_pageup_battery} jobId={job_id} />
                            )}
                            <div className="actions">
                                <ActionDropdown items={this.getJobActionItems()} />
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    }

    getJobSummaryContent() {
        const { job } = this.state;
        const { location, active_at, status } = job;
        return (
            <div className="summary">
                <LabelValueTile classes="location" label="Location" value={location} />
                {!this.isEvaluatorView() && (
                    <React.Fragment>
                        <LabelValueTile classes="created-at" label="Created At" value={formatDateTime(active_at)} />
                        <LabelValueTile classes="job-status" label="Job Status" value={status} />
                    </React.Fragment>
                )}
            </div>
        );
    }

    sortCandidates(candidates, type) {
        return candidates ?
            groupBy(
                orderBy(
                    candidates.filter(
                        // using undefined check to exclude new candidates yet to be added
                        ({is_completed}) => {
                            if (type === eCandidateTableTypes.INVITED) {
                                return typeof is_completed !== "undefined" && !is_completed;
                            }
                            if (type === eCandidateTableTypes.RESPONDED) {
                                return !!is_completed;
                            }
                            return true;
                        }
                    ), ["created_at"], ["desc"]
                ), "interview_id"
            ) :
            {};
    }

    async addJobStage(job_id, job_stage) {
        const response = await createInterviewStage(job_id, job_stage);

        if (response.error) {
            this.updateStatusMessage(`Job stage cannot be created. Please try again later.`, eStatusMessageType.WARNING);
            return false;
        }

        await this.loadJobSummary(false);
        await this.loadCandidatesSummary();

        this.updateStatusMessage(`Job stage added.`);
    }

    duplicateJob(jobId) {
        cloneJob(jobId).then((response) => {
            window.location.href = this.props.urlFor('manage-existing-job-draft', { job_id: response.job_id });
        });
    }

    canJobBeArchived() {
        const { job : { status, is_archivable } } = this.state;

        // TASK: might need to check other things as well, since we now have several job stages
        /* hasOpenInterview is still required to cover a scenario where
         * user extends candidate deadline and then tries to archive a job.
         * Even though the backend would reject the request, it is a better
         * user experience to not allow that option in the first place
         * (especially when the option comes with confirmation dialog).
         */

        return status !== eJobsType.ARCHIVED && is_archivable; // && !hasOpenInterview;
    }

    confirmJobStatusChange(jobId, status) {
        this.setState({
            confirmDialogProps: {
                title: " Job",
                message: `Are you sure you want to ${eJobsTypeVerb[status]} this job?`,
                actions: [
                    {
                        label: "Cancel",
                        classes: "outline",
                        onClick: () => this.clearDialogProps()
                    },
                    {
                        label: "Yes",
                        classes: "info",
                        onClick: () => this.updateJobStatus(jobId, status)
                    }
                ]
            }
        });
    }

    updateJobStatus(jobId, status) {
        setJobStatus(jobId, status).then((response) => {
            this.clearDialogProps();

            if (response.error) {
                this.updateStatusMessage(`Job status cannot be updated. Please try again later.`, eStatusMessageType.WARNING);
                return false;
            }

            const { job } = this.state;

            let updatedJob;

            if (!!response) {
                updatedJob = {...job, ...response};
            }

            this.setState({
                job: updatedJob
            }, () => this.updateStatusMessage(`Job is now ${status}.`));
        });
    }

    toggleCandidatesInvite(jobStageId = null) {
        const { job: { job_stages }, candidatesInviteProps: { shown }, candidates_summary } = this.state;
        const jobStage = job_stages.find(({job_stage_id}) => job_stage_id === jobStageId) || [];
        const hasInvitationEmail = jobStage.invitation_email_template_id || null;

        this.setState({
            // remove temporarily added candidates
            candidates_summary: candidates_summary.map((summary) => (
                summary.job_stage_id === jobStageId ?
                    {
                        ...summary,
                        candidates: summary.candidates.filter(({candidate_id}) => candidate_id === parseInt(candidate_id))
                    } :
                    summary
            )),
            candidatesInviteProps: {
                shown: !shown,
                job_stage_id: jobStageId,
                hasInvitationEmail
            }
        });
    }

    toggleEvaluatorsInvite(job_stage_id = null) {
        const { evaluatorsInviteProps: { shown } } = this.state;
        this.setState({
            evaluatorsInviteProps: {
                shown: !shown,
                job_stage_id
            }
        });
    }

    getLiveInterviewCandidates() {
        // TASK: this needs to handle multiple live interview stages
        const { candidates_summary } = this.state;
        return (candidates_summary.find(
            ({job_stage_type}) => job_stage_type === eJobStageTypes.LIVE
        ) || {}).candidates;
    }

    onCandidateChange(jobStageId, candidates, autoSave) {
        const { candidates_summary } = this.state;

        let updatedCandidatesSummary;

        if (!!jobStageId) {
            updatedCandidatesSummary = candidates_summary.map((job_stage) => (
                (job_stage.job_stage_id === jobStageId) ?
                    {
                        ...job_stage,
                        candidates
                    } :
                    job_stage
            ));

            this.setState({
                candidates_summary: updatedCandidatesSummary,
                autoSave
            });
        }
    }

    updateStatusMessage(message, type = eStatusMessageType.INFO) {
        this.setState({
            statusMessage: {
                message,
                type,
                reset: new Date()
            }
        });
    }

    onDecisionUpdate(job_stage_id, candidate_ids, decision) {
        const { candidates_summary } = this.state;

        this.setState({
            candidates_summary: candidates_summary.map((summary) => (
                summary.job_stage_id === job_stage_id ?
                    {
                        ...summary,
                        candidates: summary.candidates.map((candidate) => {
                            if (candidate_ids.includes(candidate.candidate_id)) {
                                return {
                                    ...candidate,
                                    decision
                                }
                            } else {
                                return candidate;
                            }
                        })
                    } :
                    summary
            ))
        });
    }

    async onCandidateDelete(job_stage_id, candidate_id) {
        // update candidate counts
        await this.loadJobSummary(false);

        const { candidates_summary } = this.state;
        this.setState({
            candidates_summary: candidates_summary.map((summary) => (
                summary.job_stage_id === job_stage_id ?
                    {
                        ...summary,
                        candidates: summary.candidates.filter(
                            (candidate) => candidate.candidate_id !== candidate_id
                        )
                    } :
                    summary
            ))
        });

        this.updateStatusMessage("Candidate successfully removed.", eStatusMessageType.INFO);
    }

    onLiveDetailsUpdate(response, reload = false) {
        if (reload) {
            this.loadJobSummary();
        } else {
            const { job_stage_id: jobStageId, candidate_id: candidateId } = response;
            const { candidates_summary } = this.state;
            let candidate_updated = false;

            this.setState({
                candidates_summary: candidates_summary.map((summary) => (
                    summary.job_stage_id === jobStageId ?
                        {
                            ...summary,
                            candidates: summary.candidates.map((candidate) => {
                                if (candidate.candidate_id === candidateId) {
                                    candidate_updated = true;
                                    return response;
                                } else {
                                    return candidate;
                                }
                            }).concat(candidate_updated ? [] : [response])
                        } :
                        summary
                ))
            });
        }
    }

    onInviteSuccess(job_stage_id, candidate_id, timestamp) {
        const { candidates_summary } = this.state;

        this.setState({
            candidates_summary: candidates_summary.map((summary) => (
                summary.job_stage_id === job_stage_id ?
                    {
                        ...summary,
                        candidates: summary.candidates.map((candidate) => {
                            if (candidate.candidate_id === candidate_id) {
                                return {
                                    ...candidate,
                                    invited_at: [...candidate.invited_at, timestamp],
                                    is_invitation_email_delivered: false,
                                    is_deadline_expired: false,
                                    has_opened_interview: false
                                }
                            } else {
                                return candidate;
                            }
                        })
                    } :
                    summary
            ))
        });
    }

    mapCandidateSummaryForCandidateOverrides(candidates, deadline, deadlineTimezone, questionOverrides, timestamp, candidate) {
        const { job: { deadline: jobDeadline, job_timezone } } = this.state;
        const {candidate_id, invited_at} = candidate;
        let updatedCandidate = {...candidate};
        const matchingCandidate = candidates.find(({candidate_id: candidateId}) => candidateId === candidate_id);

        if (!!matchingCandidate) {
            updatedCandidate = {...updatedCandidate, ...matchingCandidate}
            if (deadline !== undefined) {
                updatedCandidate.deadline = deadline || jobDeadline;
            }
            if (deadlineTimezone !== undefined) {
                updatedCandidate.deadline_timezone = deadlineTimezone || job_timezone;
            }
            if (questionOverrides !== undefined) {
                updatedCandidate.question_overrides = questionOverrides;
            }
            if (timestamp !== undefined) {
                updatedCandidate.invited_at = [...invited_at, timestamp];
                updatedCandidate.is_deadline_expired = false;
            }
        }
        return updatedCandidate;
    }

    onSaveCandidateOverrides({job_stage_id, candidates, deadline, deadline_timezone, questionOverrides, timestamp}) {
        const { candidates_summary } = this.state;

        this.setState({
            candidates_summary: candidates_summary.map((summary) => {
                if (summary.job_stage_id === job_stage_id) {
                    return {
                        ...summary,
                        candidates: summary.candidates.map(
                            (candidate) => this.mapCandidateSummaryForCandidateOverrides(candidates, deadline, deadline_timezone, questionOverrides, timestamp, candidate)
                        )
                    };
                }

                return summary;
            })
        });
    }

    refreshJob(job) {
        this.setState({
            job
        });
    }

    backToJobTilesLink() {
        const { urlFor, viewType } = this.props;
        return urlFor(viewType === eUserTypes.EVALUATOR ? 'assess-job-list' : 'manage-job-list');
    }

    updateActiveSortValue(displayType, sortType, questionNumber = 0) {
        const { activeSortValues } = this.state;

        const updatedActiveSortValues = {...activeSortValues};

        if (
            objectToArray(eCandidateTableTypes).includes(displayType) &&
            objectToArray(eCandidateSortTypes).includes(sortType)
        ) {
            updatedActiveSortValues[displayType] = {type: sortType, questionNumber};
        }

        this.setState({
            activeSortValues: updatedActiveSortValues
        });
    }

    confirmDelete(jobId) {
        this.setState({
            confirmDialogProps: {
                title: "Delete Job",
                message: "Are you sure you want to delete this job?",
                actions: [
                    {
                        label: "Cancel",
                        classes: "outline",
                        onClick: () => this.clearDialogProps()
                    },
                    {
                        label: "Delete",
                        classes: "warning",
                        onClick: () => this.deleteJob(jobId)
                    }
                ]
            }
        });
    }

    clearDialogProps() {
        this.setState({
            confirmDialogProps: null
        });
    }

    async deleteJob(job_id) {
        const response = await deleteJob({job_id});

        if (response.error) {
            this.setState({
                confirmDialogProps: null
            });

            const errorMessage = get(response, ["error", "response", "statusText"]) ||
                get(response, ["error", "response", "data"]) ||
                get(response, ["error", "message"]);
            this.updateStatusMessage(errorMessage);
        } else {
            this.setState({
                confirmDialogProps: null
            });

            window.location.href = this.props.urlFor('manage-job-list', {});
        }
    }

    highlightCandidateRow(id) {
        const el = document.getElementById(id);
        console.log("highlightCandidateRow");
        if (!!el) {
            el.classList.add('highlight');
            setTimeout(() => el.classList.remove('highlight'), 5000);
        }
    }

    componentDidMount() {
        this.loadData().then(() => {
            const { isLazyLoadEnabled } = this.state;

            if (!!isLazyLoadEnabled) {
                enableLazyLoading();
            }
        });
    }

    componentDidUpdate(prevProps) {
        const { scrollToCandidateId : prevScrollToCandidateInterviewId } = prevProps;
        const { scrollToCandidateId } = this.props;

        if (scrollToCandidateId !== prevScrollToCandidateInterviewId) {
            if (!scrollToCandidateId) {
                scrollToTop('.job-details');
            } else {
                scrollToID(`id_${scrollToCandidateId}`);
                this.highlightCandidateRow(`id_${scrollToCandidateId}`);
            }
        }
    }

    render() {
        const {
            urlFor,
            integrationProvider,
            company_id,
            staff_id,
            is_alcami_staff,
            viewType,
            features = []
        } = this.props;

        const {
            job,
            candidates_summary,
            loading,
            statusMessage: { message, type, reset },
            confirmDialogProps,
            candidatesInviteProps: { shown: candidateInvitesShown, job_stage_id: candidateInvitesJobStageId, hasInvitationEmail },
            evaluatorsInviteProps: { shown: evaluatorInvitesShown, job_stage_id: evaluatorInvitesJobStageId },
            isLazyLoadEnabled
        } = this.state;

        const {
            job_stages = [],
            is_deadline_expired,
            status
        } = job;

        const isJobArchived = status === eJobsType.ARCHIVED;

        const respondedTableType = this.isEvaluatorView() ? eCandidateTableTypes.EVALUATING : eCandidateTableTypes.RESPONDED;
        const liveTableType = this.isManagerView() ? eCandidateTableTypes.LIVE_INTERVIEW : eCandidateTableTypes.LIVE_INTERVIEW_EVALUATING;

        const liveInterviewCandidates = this.getLiveInterviewCandidates();

        return (
            <React.Fragment>
                <Container
                    loading={loading}
                    confirmDialogProps={confirmDialogProps}
                >
                    <StatusMessage
                        type={type}
                        style={eStatusMessageStyle.BAR}
                        message={message}
                        duration={5000}
                        reset={reset}
                    />
                    <div className={this.getComponentClassNames()}>
                        {this.getJobHeaderContent()}
                        {this.getJobSummaryContent()}

                        {orderBy(job_stages, ["job_stage_id"], ["desc"]).map((jobStage) => {
                            const {
                                job_stage_id,
                                job_stage_type,
                                deadline,
                                deadline_type,
                                ongoing_deadline_days,
                                interview_terminology = eInterviewTerminologies.INTERVIEW,
                                evaluators = []
                            } = jobStage;

                            const { candidates = null } = candidates_summary.find(summary => summary.job_stage_id === job_stage_id) || {};

                            const jobStageActionItems = [];

                            jobStageActionItems.push({
                                id: "email-evaluators",
                                firstLine: "Email evaluator(s)...",
                                disabled: !evaluators.length,
                                onClick: () => this.toggleEvaluatorsInvite(job_stage_id)
                            });

                            const returnValue = [];

                            if (job_stage_type === eJobStageTypes.ONE_WAY) {
                                const {
                                    invited_count,
                                    received_count,
                                    evaluated_count,
                                    evaluated_by_me_count,
                                    declined_count,
                                    accepted_count
                                } = jobStage;

                                returnValue.push(
                                    <JobStageHeader
                                        view_type={viewType}
                                        job_stage_type={job_stage_type}
                                        job_stage_menu={this.getJobStageMenu(jobStage, jobStageActionItems, isJobArchived)}

                                        invited_count={invited_count}
                                        received_count={received_count}
                                        evaluated_by_me_count={evaluated_by_me_count}
                                        evaluated_count={evaluated_count}
                                        declined_count={declined_count}
                                        accepted_count={accepted_count}
                                        deadline_value={getDeadlineValue(deadline_type, deadline, ongoing_deadline_days)}
                                        evaluators={evaluators}
                                        interview_terminology={interview_terminology}
                                    />
                                );

                                if (!!received_count) {
                                    Object.entries(this.sortCandidates(candidates, "responded")).forEach(([interviewId, c]) => {
                                        returnValue.push(
                                            <CandidatesTable
                                                key={`candidates-responded-${interviewId}`}
                                                classes="candidates-responded"
                                                displayType={respondedTableType}
                                                company_id={company_id}
                                                staff_id={staff_id}
                                                is_alcami_staff={is_alcami_staff}
                                                interview_name={`${capitalize(interview_terminology)} ${interviewId.substr(interviewId.length - 4)}`}
                                                job={job}
                                                job_stage={jobStage}
                                                candidates={c}
                                                liveInterviewCandidates={liveInterviewCandidates}
                                                onSaveCandidateOverrides={this.onSaveCandidateOverrides}
                                                onDecisionUpdate={this.onDecisionUpdate}
                                                onCandidateDelete={this.onCandidateDelete}
                                                onLiveDetailsUpdate={this.onLiveDetailsUpdate}
                                                onStatusMessageUpdate={this.updateStatusMessage}
                                                urlFor={urlFor}
                                                features={features}
                                                integrationProvider={integrationProvider}
                                                isLazyLoadEnabled={isLazyLoadEnabled}
                                            />
                                        );
                                    });
                                }

                                if (this.isManagerView()) {
                                    Object.entries(this.sortCandidates(candidates, "invited")).forEach(([interviewId, c]) => {
                                        returnValue.push(
                                            <CandidatesTable
                                                key={`candidates-invited-${interviewId}`}
                                                classes="candidates-invited"
                                                displayType={eCandidateTableTypes.INVITED}
                                                company_id={company_id}
                                                staff_id={staff_id}
                                                is_alcami_staff={is_alcami_staff}
                                                isReadOnly={isJobArchived}
                                                interview_name={`${capitalize(interview_terminology)} ${interviewId.substr(interviewId.length - 4)}`}
                                                job={job}
                                                job_stage={jobStage}
                                                candidates={c}
                                                liveInterviewCandidates={liveInterviewCandidates}
                                                onSaveCandidateOverrides={this.onSaveCandidateOverrides}
                                                onInviteSuccess={this.onInviteSuccess}
                                                onCandidateDelete={this.onCandidateDelete}
                                                onLiveDetailsUpdate={this.onLiveDetailsUpdate}
                                                onStatusMessageUpdate={this.updateStatusMessage}
                                                urlFor={urlFor}
                                                features={features}
                                                integrationProvider={integrationProvider}
                                            />
                                        );
                                    })
                                }
                            } else if (job_stage_type === eJobStageTypes.LIVE && this.shouldShowLiveInterview()) {
                                returnValue.push(
                                    <JobStageHeader
                                        job_stage_type={job_stage_type}
                                        interview_terminology={interview_terminology}
                                    />
                                );

                                returnValue.push(
                                    <CandidatesTable
                                        key={`candidates-live`}
                                        displayType={liveTableType}
                                        company_id={company_id}
                                        staff_id={staff_id}
                                        is_alcami_staff={is_alcami_staff}
                                        job={job}
                                        job_stage={jobStage}
                                        candidates={candidates}
                                        onCandidateDelete={this.onCandidateDelete}
                                        onLiveDetailsUpdate={this.onLiveDetailsUpdate}
                                        onStatusMessageUpdate={this.updateStatusMessage}
                                        integrationProvider={integrationProvider}
                                        urlFor={urlFor}
                                        features={features}
                                    />
                                );
                            }
                            return returnValue;
                        })}
                    </div>
                </Container>
                {candidateInvitesShown && (
                    <CustomModal
                        // title={`Confirm Candidate Decision`}
                        classes={`candidates-invite-modal open`}
                        onClose={this.toggleCandidatesInvite}
                        closeOnOverlayClick={false}
                    >
                        <CandidatesInvite
                            job={job}
                            job_stage_id={candidateInvitesJobStageId}
                            hasInvitationEmail={hasInvitationEmail}

                            candidates={candidates_summary.find(summary => summary.job_stage_id === candidateInvitesJobStageId).candidates}
                            // job_stage={job_stage}
                            integrationProvider={integrationProvider}
                            is_deadline_expired={is_deadline_expired}

                            onCandidateChange={this.onCandidateChange}
                            onUpdate={() => this.loadJobSummary(false)}
                            onRefresh={this.refreshJob}
                            onStatusUpdate={this.updateStatusMessage}
                            onClose={this.toggleCandidatesInvite}
                        />
                    </CustomModal>
                )}
                {evaluatorInvitesShown && (
                    <EvaluatorsInvite
                        job={job}
                        job_stage_id={evaluatorInvitesJobStageId}
                        onClose={this.toggleEvaluatorsInvite}
                        integrationProvider={integrationProvider}
                        urlFor={urlFor}
                    />
                )}
            </React.Fragment>
        );
    }
}

JobDetails.propTypes = {
    viewType: PropTypes.oneOf(objectToArray(eUserTypes)),
    integrationProvider: PropTypes.string,
    company_id: PropTypes.number,
    staff_id: PropTypes.number.isRequired,
    is_alcami_staff: PropTypes.bool,
    job_id: PropTypes.number.isRequired,
    scrollToCandidateId: PropTypes.number,
    showBreadcrumbs: PropTypes.bool,
    urlFor: PropTypes.func.isRequired,
    features: PropTypes.array
};

export default JobDetails;
