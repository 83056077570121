import React from "react";
import PropTypes from "prop-types";
import Container from "../../../components/Container";
import {
    eEmailTemplateTypes,
    EmailInvitationPlaceholders,
    EmailNonInvitationPlaceholders,
    eTextAreaType,
    formatTemplateSubjectPlaceholders,
    formatTemplateContentPlaceholders,
    eConfirmDialogResponseType,
    eTemplatesType,
    isAlcamiStaff
} from "../../../utils";
import {IconPlus, IconStar} from "../../../components/Icons";
import {capitalize, sortBy} from "lodash";
import ActionDropdown from "../../../components/ActionDropdown";
import EmailTemplateDetails from "./details";
import CustomTextArea from "../../../components/Form/CustomTextArea";
import Templates from "../../../components/Templates";
import CustomModal from "../../../components/CustomModal";

import './style.scss';


class EmailTemplateRow extends React.Component {
    constructor(props) {
        super(props);

        this.toggleJobsAssociatedList = this.toggleJobsAssociatedList.bind(this);
        this.getActionItems = this.getActionItems.bind(this);

        this.state = {
            isJobsAssociatedListShown: false
        }
    }

    toggleJobsAssociatedList() {
        const { isJobsAssociatedListShown } = this.state;
        this.setState({
            isJobsAssociatedListShown: !isJobsAssociatedListShown
        });
    }

    getActionItems(email_template_id) {
        const { onEdit, onPreview, onClone, onDelete } = this.props;
        const actionItems = [];

        const is_alcami_staff = isAlcamiStaff();

        if (is_alcami_staff) {
            if (!!onEdit) {
                actionItems.push({
                    id: `edit-${email_template_id}`,
                    firstLine: "Edit",
                    onClick: onEdit
                });
            }
        }

        if (!!onPreview) {
            actionItems.push({
                id: `preview-${email_template_id}`,
                firstLine: "Preview",
                onClick: onPreview
            });
        }

        if (is_alcami_staff) {
            if (!!onClone) {
                actionItems.push({
                    id: `clone-${email_template_id}`,
                    firstLine: "Clone...",
                    onClick: onClone
                });
            }

            if (!!onDelete) {
                actionItems.push({
                    id: "delete",
                    firstLine: "Delete...",
                    onClick: onDelete
                });
            }
        }

        return actionItems;
    }

    render() {
        const { isJobsAssociatedListShown } = this.state;
        const { template = {}, urlFor } = this.props;
        const {
            email_template_id,
            email_template_type,
            subject,
            content,
            is_default_for_new_job,
            active_jobs_using_template
        } = template;

        return (
            <div className={`email-template-row`}>
                <div className={`template-id`}>{email_template_id}{is_default_for_new_job ? IconStar : ""}</div>
                <div className={`template-subject`}>
                    {formatTemplateSubjectPlaceholders(subject)}
                </div>
                {!!content && (
                    <CustomTextArea
                        id={`email-content-${email_template_type}-${email_template_id}`}
                        classes={`template-content html`}
                        key={`${email_template_type}-${email_template_id}`}
                        type={eTextAreaType.RICH}
                        readOnly={true}
                        isEmail={true}
                        emailPlaceholders={email_template_type === eEmailTemplateTypes.INVITATION ? EmailInvitationPlaceholders : EmailNonInvitationPlaceholders}
                        value={formatTemplateContentPlaceholders(content)}
                    />
                )}
                <div className={`jobs-associated`}
                     onClick={active_jobs_using_template?.length ? this.toggleJobsAssociatedList : null}
                >
                    <span className={`jobs-associated-label`}># of Jobs Associated: </span>
                    <span className={`jobs-associated-value ${active_jobs_using_template?.length ? 'has-value' : ''}`}>{active_jobs_using_template?.length || '-'}</span>
                </div>
                <div className={`email-template-actions`}>
                    <ActionDropdown items={this.getActionItems(email_template_id)} hasBorder={true} />
                </div>

                {isJobsAssociatedListShown && (
                    <CustomModal
                        title={`Jobs Associated to Email Template #${email_template_id}`}
                        classes={`jobs-associated-list open`}
                        closeOnOverlayClick={false}
                        onClose={this.toggleJobsAssociatedList}
                    >
                        {sortBy(active_jobs_using_template, ({job_id}) => job_id)?.map(
                            ({job_id, title}) => {
                                return (
                                    <a className={`job`} href={urlFor('manage-job-details', { job_id })}>
                                        <span className={`job-id`}>#{job_id}</span>
                                        <span className={`job-title`}>{title}</span>
                                    </a>
                                );
                            }
                        )}
                    </CustomModal>
                )}
            </div>
        );
    }
}

EmailTemplateRow.propTypes = {
    template: PropTypes.object,
    onEdit: PropTypes.func,
    urlFor: PropTypes.func.isRequired
};


class EmailTemplates extends Templates {
    constructor(props) {
        super(props);

        this.sortedEmailTemplates = this.sortedEmailTemplates.bind(this);

        this.state = {
            loading: true,
            items: [],
            selectedTemplates: null,
            confirmDialogResponseProps: null
        }
    }

    sortedEmailTemplates() {
        const { items } = this.state;

        const indexedItems = items.map((item) => {
            const { email_template_type } = item;

            let email_template_type_index;

            if (email_template_type === eEmailTemplateTypes.INVITATION) {
                email_template_type_index = 1;
            } else if (email_template_type === eEmailTemplateTypes.ACKNOWLEDGEMENT) {
                email_template_type_index = 2;
            } else if (email_template_type === eEmailTemplateTypes.SUCCESSFUL) {
                email_template_type_index = 3;
            } else if (email_template_type === eEmailTemplateTypes.UNSUCCESSFUL) {
                email_template_type_index = 4;
            } else {
                email_template_type_index = 5;
            }

            return {
                ...item,
                email_template_type_index
            }
        });

        return sortBy(indexedItems, ["email_template_type_index", "email_template_id"]);
    }

    onEmailTemplateSubmit(template, is_new_record) {
        const { is_default_for_new_job, email_template_type } = template;
        const { items } = this.state;

        let updatedItems = [...items];

        if (is_default_for_new_job) {
            // remove previous default
            updatedItems = updatedItems.map((item) => {
                return item.email_template_type === email_template_type ?
                    {...item, is_default_for_new_job: false} :
                    item;
            });
        }

        if (is_new_record) {
            updatedItems.push(template)
        } else {
            updatedItems = updatedItems.map((item) => {
                return item.email_template_id === template.email_template_id ? template : item
            });
        }

        this.setState({
            items: updatedItems
        });

        this.updateConfirmDialogResponseProps(
            `Email template ${is_new_record ? 'created' : 'updated'}.`,
            eConfirmDialogResponseType.SUCCESS
        );
    }

    async componentDidMount() {
        await this.retrieveTemplates(eTemplatesType.EMAIL);
    }

    render() {
        const {
            loading,
            confirmDialogResponseProps,
            items,
            selectedTemplate,
            isPreviewShown
        } = this.state;

        const { urlFor } = this.props;

        const sortedItems = this.sortedEmailTemplates();

        let current_template_type = null;

        return (
            <Container
                loading={loading}
                confirmDialogResponseProps={confirmDialogResponseProps}
            >
                {!!sortedItems.length && (
                    <div className={`email-templates`}>
                        {sortedItems.map((template) => {
                            const { email_template_type } = template;
                            const show_template_type = email_template_type !== current_template_type;
                            if (show_template_type) {
                                current_template_type = email_template_type;
                            }
                            return (
                                <React.Fragment>
                                    {show_template_type && (
                                        <React.Fragment>
                                            <div className={`template_type_header`}>
                                                {capitalize(email_template_type)}
                                                {isAlcamiStaff() && (
                                                    <div className={`action`}>
                                                        <div className={`new-button`} onClick={() => this.onNew(eTemplatesType.EMAIL, email_template_type)}>{IconPlus}</div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className={`email-templates-header header-row`}>
                                                <div className={`header-column id`}>ID</div>
                                                <div className={`header-column subject`}>Subject</div>
                                                <div className={`header-column content`}>Content</div>
                                                <div className={`header-column jobs-associated`}># of Jobs</div>
                                            </div>
                                        </React.Fragment>
                                    )}
                                    <EmailTemplateRow
                                        template={template}
                                        onEdit={() => this.toggleTemplateDetails(template)}
                                        onPreview={() => this.toggleTemplateDetails(template, true)}
                                        onClone={() => this.toggleTemplateDetails({...template, email_template_id: null})}
                                        onDelete={() => this.deleteTemplateConfirmation(eTemplatesType.EMAIL, template)}
                                        urlFor={urlFor}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </div>
                )}

                {!items.length && (
                    <div className={`no-items`}>
                        There are no email templates.<br /><br />
                        {isAlcamiStaff() && (
                            <div className={`react-button create-button`}
                                 onClick={() => this.onNew(eTemplatesType.EMAIL)}
                            >
                                Create new
                            </div>
                        )}
                    </div>
                )}

                {!!selectedTemplate && (
                    <EmailTemplateDetails
                        templates={items}
                        template={selectedTemplate}
                        isPreviewOnly={isPreviewShown}
                        onSubmit={(response, is_new_record) => this.onTemplateSubmit(eTemplatesType.EMAIL, response, is_new_record)}
                        onClose={() => this.toggleTemplateDetails(null)}
                    />
                )}
            </Container>
        );
    }
}

EmailTemplates.propTypes = {
    urlFor: PropTypes.func.isRequired
};

EmailTemplates.defaultProps = {
    urlFor: null
}

export default EmailTemplates;
