import React from 'react';
import {IconCrossThin} from "../Icons";
import Loader from "../Loader";

import './style.scss';


const CustomModal = ({classes, isLoading, title, closeText, onClose, closeOnOverlayClick = true, children}) => (
    <div className={`custom-modal ${classes}`}>
        <div className="overlay" onClick={() => !!closeOnOverlayClick && onClose?.()}></div>
        <div className={`overlay-form ${isLoading ? 'is-loading' : ''} ${title ? 'has-title' : ''}`}>
            {isLoading && (
                <Loader />
            )}
            {!isLoading && (
                <React.Fragment>
                    {title && (
                        <span className="modal-title">
                            {title}
                        </span>
                    )}
                    {onClose && (
                        <span className="close-icon" onClick={onClose}>
                            {closeText && <button className={`react-button active close-button`}>{closeText}</button>}
                            {!closeText && IconCrossThin}
                        </span>
                    )}
                    <div className={'container-form'}>
                        {children}
                    </div>
                </React.Fragment>
            )}
        </div>
    </div>
);

export default CustomModal;
