import React from "react";
import PropTypes from 'prop-types';
import {capitalize, cloneDeep, isEqual, uniq} from "lodash";
import CustomModal from "../../../../components/CustomModal";
import Loader from "../../../../components/Loader";
import CustomTextField from "../../../../components/Form/CustomTextField";
import Dropdown from "../../../../components/Dropdown";
import CustomTextArea from "../../../../components/Form/CustomTextArea";
import {
    eEmailTemplateFields,
    eEmailTemplateTypes,
    EmailInvitationPlaceholders,
    EmailNonInvitationPlaceholders,
    eTemplatesType,
    eTextAreaType,
    isValidImageURL,
    formatTemplateContentPlaceholders,
    objectToArray,
    formatTemplateSubjectPlaceholders,
    eConfirmDialogResponseType,
    getValue
} from "../../../../utils";
import CustomCheckbox, {eCustomCheckboxSize} from "../../../../components/Form/CustomCheckbox";
import EmailTemplatePreview from "../preview";
import ConfirmDialogResponse from "../../../../components/ConfirmDialog/Response";
import TemplateDetails from "../../../../components/Templates/details";

import './style.scss';


const placeholdersList = EmailInvitationPlaceholders.map(({label}) => label);
const EMPTY_CONTENT = "<p><br></p>";

class EmailTemplateDetails extends TemplateDetails {
    constructor(props) {
        super(props);

        this.isNewTemplate = this.isNewTemplate.bind(this);
        this.getEmailTemplateTypes = this.getEmailTemplateTypes.bind(this);

        this.getComponentTitle = this.getComponentTitle.bind(this);
        this.getComponentClassNames = this.getComponentClassNames.bind(this);

        this.onInitialContentChange = this.onInitialContentChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.getInvalidPlaceholders = this.getInvalidPlaceholders.bind(this);

        this.isSubmitEnabled = this.isSubmitEnabled.bind(this);

        const { subject, content, header_banner_img_src, footer_banner_img_src } = this.props.template;

        const initialTemplate = {
            ...this.props.template,
            subject: formatTemplateSubjectPlaceholders(subject),
            content: formatTemplateContentPlaceholders(content),
            header_banner_img_src: getValue(header_banner_img_src, ""),
            footer_banner_img_src: getValue(footer_banner_img_src, "")
        };

        this.state = {
            initialTemplate: cloneDeep(initialTemplate),
            template: cloneDeep(initialTemplate),
            hasContentBeenManuallyChanged: false,
            preview: {},
            isPreviewSettingsShown: false,
            confirmDialogResponseProps: null
        };
    }

    isNewTemplate() {
        const { template } = this.state;
        return !template.email_template_id;
    }

    getEmailTemplateTypes() {
        const { template: { email_template_type = null } = {} } = this.state;
        return objectToArray(eEmailTemplateTypes).map((type) => {
            return {
                id: type,
                firstLine: capitalize(type),
                selected: type === email_template_type,
                onClick: () => this.onInputChange({target: {id: eEmailTemplateFields.TYPE, value: type}})
            };
        });
    }

    getComponentTitle() {
        const { template: { email_template_id } } = this.state;
        const { isPreviewOnly } = this.props;

        const isCreate = this.isNewTemplate();

        if (isCreate) {
            return 'Create Email Template';
        }

        if (isPreviewOnly) {
            return `Preview Email Template #${email_template_id}`;
        }

        return `Edit Email Template #${email_template_id}`;
    }

    getComponentClassNames() {
        const { isPreviewOnly } = this.props;
        return `template-details email-template-details ${isPreviewOnly ? 'preview-only' : ''} open`;
    }

    onInitialContentChange() {
        const { initialTemplate, template, hasContentBeenManuallyChanged } = this.state;
        if (!hasContentBeenManuallyChanged) {
            this.setState({
                initialTemplate,
                template,
                hasContentBeenManuallyChanged: true
            })
        }
    }

    onInputChange(e) {
        const { target: { id, value } } = e;
        const { template, hasContentBeenManuallyChanged } = this.state;

        const updatedState = {
            template: {
                ...template,
                [id]: value
            }
        }

        if (id === eEmailTemplateFields.CONTENT && !hasContentBeenManuallyChanged) {
            updatedState.initialTemplate = {...updatedState.template};
        }

        this.setState(updatedState);
    }

    getInvalidPlaceholders(content) {
        if (!content) {
            return [];
        }

        const regex = /({{(.*?)}})/g; // |(%.*?%)/g;
        const placeholders = [...content.matchAll(regex)].map((arr) => arr[2]);

        const invalidPlaceholders = [];

        placeholders.forEach((placeholder) => {
            if (!placeholdersList.includes(placeholder)) {
                invalidPlaceholders.push('{{' + placeholder + '}}');
            }
        });

        return uniq(invalidPlaceholders);
    }

    isSubmitEnabled() {
        const { initialTemplate, template } = this.state;
        const { email_template_type, subject, header_banner_img_src, content, footer_banner_img_src } = template;

        return (this.isNewTemplate() || !isEqual(initialTemplate, template))
            && !!email_template_type
            && !!subject
            && !this.getInvalidPlaceholders(subject).length
            && (!!header_banner_img_src ? isValidImageURL(header_banner_img_src) : true)
            && !!content
            && content !== EMPTY_CONTENT
            && (!!footer_banner_img_src ? isValidImageURL(footer_banner_img_src) : true);
    }

    render() {
        const { template, confirmDialogResponseProps } = this.state;

        if (!template) {
            return (<Loader />);
        }

        const { isPreviewOnly, job, candidate, onClose } = this.props;
        const {
            email_template_type,
            subject,
            content,
            header_banner_img_src,
            footer_banner_img_src,
            is_default_for_new_job
        } = template;

        const isCreate = this.isNewTemplate();

        const subjectInvalidPlaceholders = this.getInvalidPlaceholders(subject);

        const subjectHelperText = !!subjectInvalidPlaceholders.length ?
            <div className={`invalid-placeholders`}>
                Invalid placeholders: {
                subjectInvalidPlaceholders.map((placeholder) => (<span className={`placeholder`}>{placeholder}</span>))
            }
            </div> :
            null;

        return (
            <React.Fragment>
                <ConfirmDialogResponse {...confirmDialogResponseProps} />
                {(!confirmDialogResponseProps  || confirmDialogResponseProps?.type === eConfirmDialogResponseType.ERROR) && (
                    <CustomModal
                        title={this.getComponentTitle()}
                        classes={this.getComponentClassNames()}
                        closeOnOverlayClick={false}
                        onClose={onClose}
                    >
                        {!isPreviewOnly && (
                            <div className={`edit`}>
                                {isCreate && (
                                    <Dropdown
                                        required={true}
                                        styles="floating-label fixed-label"
                                        classes="templates_type_dropdown"
                                        label="Template Type"
                                        items={this.getEmailTemplateTypes()}
                                    />
                                )}
                                <CustomCheckbox
                                    size={eCustomCheckboxSize.MEDIUM}
                                    classes={`default-checkbox`}
                                    key={`default-toggle`}
                                    id={`default-toggle`}
                                    label={`Set as default for new jobs`}
                                    checked={is_default_for_new_job}
                                    onChange={() => this.onInputChange({target: { id: "is_default_for_new_job", value: !is_default_for_new_job}})}
                                />
                                <CustomTextField
                                    classes={`subject`}
                                    id={eEmailTemplateFields.SUBJECT}
                                    name={`subject`}
                                    required={true}
                                    label={`Subject`}
                                    value={getValue(subject, "")}
                                    isError={subject === ""}
                                    autoFocus={true}
                                    onChange={this.onInputChange}
                                    helperText={subjectHelperText}
                                />
                                <CustomTextField
                                    classes={`header-banner`}
                                    id={eEmailTemplateFields.HEADER_BANNER}
                                    name={`header-banner`}
                                    label={`Header Banner URL`}
                                    value={getValue(header_banner_img_src, "")}
                                    isError={!isValidImageURL(header_banner_img_src)}
                                    onChange={this.onInputChange}
                                />
                                <CustomTextField
                                    classes={`footer-banner`}
                                    id={eEmailTemplateFields.FOOTER_BANNER}
                                    name={`footer-banner`}
                                    label={`Footer Banner URL`}
                                    value={getValue(footer_banner_img_src, "")}
                                    isError={!isValidImageURL(footer_banner_img_src)}
                                    onChange={this.onInputChange}
                                />
                                {(isCreate || !!content) && (
                                    <CustomTextArea
                                        id={eEmailTemplateFields.CONTENT}
                                        type={eTextAreaType.RICH}
                                        name={`content`}
                                        required={true}
                                        classes={`content`}
                                        label={`Content`}
                                        value={content}
                                        resizable={false}
                                        isEmail={true}
                                        emailPlaceholders={email_template_type === eEmailTemplateTypes.INVITATION ? EmailInvitationPlaceholders : EmailNonInvitationPlaceholders}
                                        isError={content === "" || content === EMPTY_CONTENT}
                                        onInitialInteraction={this.onInitialContentChange}
                                        onChange={this.onInputChange}
                                    />
                                )}
                            </div>
                        )}

                        <div className={`preview`}>
                            <EmailTemplatePreview
                                template={template}
                                job={job}
                                candidate={candidate}
                                hideSettings={isPreviewOnly}
                            />
                        </div>

                        {!isPreviewOnly && (
                            <div className={`actions`}>
                                <button className={`react-button cancel button-link`} onClick={onClose ? onClose : null}>Cancel</button>
                                <button
                                    className={`react-button submit ${this.isSubmitEnabled() ? 'active ripple' : 'disabled'}`}
                                    onClick={() => this.isSubmitEnabled() && this.onSubmit(eTemplatesType.EMAIL)}
                                >
                                    {isCreate ? 'Create' : 'Save'}
                                </button>
                            </div>
                        )}
                    </CustomModal>
                )}
            </React.Fragment>
        )
    }
}

EmailTemplateDetails.propTypes = {
    templates: PropTypes.array,
    template: PropTypes.object,
    job: PropTypes.object,
    candidate: PropTypes.object,
    isPreviewOnly: PropTypes.bool,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func
};

EmailTemplateDetails.defaultProps = {
    templates: [],
    template: null,
    job: undefined,
    candidate: undefined,
    isPreviewOnly: false,
    onSubmit: null,
    onClose: null
};

export default EmailTemplateDetails;