import React from "react";
import {eDateFormatTypes, formatDateTime} from "../../../utils";

import "./style.scss";


const StatusPanel = ({ is_completed, showCompletedTimestamp, completed_at }) => (
    <div className={`status-panel ${!is_completed ? 'pending' : 'completed'}`}>
        {!is_completed && `This candidate interview is in progress...`}
        {showCompletedTimestamp && `Interview completed at ${formatDateTime(completed_at, eDateFormatTypes.FULL_WITH_AM_PM)}`}
    </div>
);

export default StatusPanel;