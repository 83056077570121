import React from "react";
import * as PropTypes from "prop-types";
import Dropdown from "../../Dropdown";
import { orderBy } from "lodash";

import './style.scss';


class StepTemplates extends React.Component {
    constructor(props) {
        super(props);

        this.getJobsAsTemplatesDropdown = this.getJobsAsTemplatesDropdown.bind(this);
        this.setJobTemplateId = this.setJobTemplateId.bind(this);

        this.state = {
            job_template_id: null
        };
    }

    getJobsAsTemplatesDropdown() {
        const { job_templates } = this.props;
        const { job_template_id } = this.state;

        const items = job_templates.map(({ job_id: id, title }) => {
            return {
                id_sort: id,
                id: `${id}`,
                firstLine: `[#${id}] ${title}`,
                selected: `${id}` === `${job_template_id}`,
                onClick: () => this.setJobTemplateId(id)
            };
        });

        return orderBy(items, ['id_sort'], ['desc']);
    }

    setJobTemplateId(job_template_id) {
        this.setState({
            job_template_id
        });
    }

    render() {
        const { title, content, onTemplateSelectionConfirmed, onTemplateSelectionCancelled } = this.props;
        const { job_template_id } = this.state;
        return (
            <div className={`step-templates`}>
                <div className={`step-templates-header`}>{title}</div>
                {!!content && (
                    <div className={`step-templates-content`}>
                        {content}
                    </div>
                )}
                <div className={`step-template-selection`}>
                    <Dropdown
                        required={false}
                        styles="floating-label fixed-label"
                        classes="templates-dropdown"
                        label="Select Template"
                        items={this.getJobsAsTemplatesDropdown()}
                    />
                    <div
                        className={`react-button ${job_template_id ? 'info' : 'disabled'}`}
                        onClick={() => onTemplateSelectionConfirmed(job_template_id)}
                    >
                        Continue
                    </div>
                </div>
                {/*<div className={`separator`}>
                    <span className={`line`}>&nbsp;</span>
                    <div className={`or`}>
                        <span>or</span>
                    </div>
                </div>*/}
                <div className={`step-template-proceed`}>
                    or <span
                        className={`link`}
                        onClick={onTemplateSelectionCancelled}
                    >
                        Create one from scratch
                    </span>
                </div>
            </div>
        );
    }
}

StepTemplates.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    job_templates: PropTypes.array,
    onTemplateSelectionConfirmed: PropTypes.func,
    onTemplateSelectionCancelled: PropTypes.func
};

StepTemplates.defaultProps = {
    title: "New Job",
    content: '',
    job_templates: [],
    onTemplateSelectionConfirmed: null,
    onTemplateSelectionCancelled: null
};

export default StepTemplates;