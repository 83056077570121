import React from 'react';
import * as PropTypes from 'prop-types';
import zxcvbn from 'zxcvbn';
import { unsafePasswordPhrases } from "../../utils";
import Tooltip from "../Tooltip";
import { get } from "lodash";

import './style.scss';


class PasswordStrengthMeter extends React.Component {
    constructor(props) {
        super(props);

        this.evaluatePassword = this.evaluatePassword.bind(this);
        this.communicatePasswordStrength = this.communicatePasswordStrength.bind(this);
        this.hasFeedback = this.hasFeedback.bind(this);
    }

    createPasswordLabel = (result) => {
        switch (result.score) {
            case 0:
                return 'Weak';
            case 1:
                return 'Weak';
            case 2:
                return 'Weak';
            case 3:
                return 'Good';
            case 4:
                return 'Strong';
            default:
                return 'Weak';
        }
    };

    evaluatePassword() {
        const { password } = this.props;
        return zxcvbn(password, unsafePasswordPhrases);
    }

    hasFeedback(feedback) {
        return !!feedback &&
            (!!get(feedback, "warning", "") || !!get(feedback, "suggestions", []).length);
    }

    communicatePasswordStrength = () => {
        const { listener } = this.props;
        const score = get(this.evaluatePassword(), "score");
        return listener(score);
    };

    componentDidUpdate(prevProps) {
        if (prevProps.password !== this.props.password) {
            this.communicatePasswordStrength();
        }
    }

    render() {
        const { password, showProgress, showLabel } = this.props;
        const testedResult = this.evaluatePassword();
        return (
            <div className="password-strength-meter">
                {password && (
                    <React.Fragment>
                        {showProgress && (
                            <progress
                                className={`password-strength-meter-progress strength-${this.createPasswordLabel(testedResult)}`}
                                value={testedResult.score}
                                max="4"
                            />
                        )}
                        {showLabel && (
                            <label
                                className={`password-strength-meter-label ${this.createPasswordLabel(testedResult)}`}
                            >
                                <span className="label">Password strength: </span>
                                <span className={`value`}>
                                    {this.createPasswordLabel(testedResult)}
                                    {this.hasFeedback(testedResult.feedback) && (
                                        <Tooltip
                                            classes="feedback"
                                            positioning={`onBottom`}
                                            item={<span className="link">?</span>}
                                            description={
                                                <React.Fragment>
                                                    {testedResult.feedback.warning && (<span className="warning">{testedResult.feedback.warning}</span>)}
                                                    {testedResult.feedback.suggestions && testedResult.feedback.suggestions.map((suggestion) => <span className="suggestion">{suggestion}</span>)}
                                                </React.Fragment>
                                            }
                                        />
                                    )}
                                </span>


                            </label>
                        )}
                    </React.Fragment>
                )}
            </div>
        );
    }
}

PasswordStrengthMeter.propTypes = {
    password: PropTypes.string,
    listener: PropTypes.func,
    showProgress: PropTypes.bool,
    showLabel: PropTypes.bool
};

PasswordStrengthMeter.defaultProps = {
    password: "",
    listener: null,
    showProgress: true,
    showLabel: true
};

export default PasswordStrengthMeter;
