import axios from 'axios';
import { eJobStageTypes, eResponseStatus, isInIFrame } from '../utils';
import { get } from "lodash";

let baseURL;
let client;
let clientV2;
let alcamiAuthToken;

const eVersion = {
  v2: "v2"
}

const getDefaultBaseURL = (version = '') => {
  return `/api/${version ? version + '/' : ''}`;
};

const alcamiClient = axios.create({
  baseURL: getDefaultBaseURL(),
  withCredentials: true
});

const alcamiClientv2 = axios.create({
  baseURL: getDefaultBaseURL(eVersion.v2),
  withCredentials: true
});

const integrationClient = axios.create({
  baseURL: '/criteria/api/',
  withCredentials: true
});

const integrationClientv2 = axios.create({
  baseURL: '/api/v2/criteria',
  withCredentials: true
});

const getClient = (integration = false, version = '') => {
  const axiosClient = integration ?
      version === eVersion.v2 ? integrationClientv2 : integrationClient :
      version === eVersion.v2 ? alcamiClientv2 : alcamiClient;

  if (alcamiAuthToken) {
    axiosClient.defaults.headers.common['Authorization'] = alcamiAuthToken;
  }

  return axiosClient;
}

const updateClient = () => {
  client = axios.create({
    baseURL,
    withCredentials: true
  });

  clientV2 = axios.create({
    baseURL: baseURL.replace("/api/", "/api/v2/"),
    withCredentials: true
  });

  if (alcamiAuthToken) {
    client.defaults.headers.common['Authorization'] = alcamiAuthToken;
    clientV2.defaults.headers.common['Authorization'] = alcamiAuthToken;
  }

  addInterceptors(client);
};

const addInterceptors = (c) => {
  c.interceptors.response.use(response => {
    return response;
  },error => {
    if ([401, 503].includes(error.response.status)) {
      // by reloading the page, App.jsx will load the login/maintenance layout
      window.location.reload();
    }
    return Promise.reject(error);
  });
};

const setAlcamiAuthToken = (token) => {
  alcamiAuthToken = token;
  updateClient();
};

const setBaseURL = (url) => {
  if (!url) {
    baseURL = getDefaultBaseURL();
    updateClient();
  } else if (url !== baseURL) {
    baseURL = url;
    updateClient();
  }
};

const getURLPromise = (url) => {
  return new Promise((resolve) => {
    client.get(url)
    .then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      resolve({error});
    });
  })
}

const getLoginModes = (targetPath) => {
  const authClient = axios.create({
    baseURL: getDefaultBaseURL(),
    withCredentials: true
  });

  return new Promise((resolve) => {
    authClient.get(`/loginmodes?target_path=${targetPath}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve({error});
        });
  });
}

const initiateStaffLogin = (payload) => {
  const authClient = getClient();

  return new Promise((resolve) => {
    authClient.post(`/initiate-staff-login`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          resolve({next: "ask_for_password"});
        });
  });
}

const getAuthenticate = () => {
  const authClient = getClient();
  
  return new Promise((resolve) => {
    authClient.get(`/authenticate`)
      .then((response) => {
        setBaseURL('/api/' + response.data.aviia_endpoint);
        resolve(response.data);
      })
      .catch((error) => {
        resolve({error});
      });
  });
};

const postAuthenticate = (payload) => {
  const authClient = getClient();

  return new Promise((resolve) => {
    if (isInIFrame()) {
      // for jobAdder login
      payload.iframe = true;
    }
    authClient.post(`/authenticate`, payload)
      .then((response) => {
        setBaseURL('/api/' + response.data.aviia_endpoint);
        resolve(response.data);
      })
      .catch((error) => {
        resolve({error});
      });
  });
};

const authenticateLoginLink = (payload) => {
  const authClient = getClient();

  return new Promise((resolve) => {
    authClient.post(`/authenticateLoginLink`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve({error});
        });
  });
};

const sendVerificationCode = (data) => {
  setBaseURL();

  return new Promise((resolve) => {
    client.post(`/send-verification-code`, data)
        .then(() => {
          resolve({success: true});
        })
        .catch((error) => {
          console.log(error);
          resolve({error});
        })
  })
};

const getCompanyDetails = () => {
  return new Promise((resolve) => {
    client.get(``).then((response) => {
      resolve(response.data);
    });
  });
};

const getJobSummary = (jobId) => {
  const url = `/Job/${jobId}/Summary`;

  return new Promise((resolve) => {
    try {
      client.get(url)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve({error});
      });
    } catch(error) {
      resolve({error});
    }
  });
};

const getJobStageCandidateSummary = (job_id, job_stage_type, job_stage_id, staff_id) => {
  const url = (staff_id ? `/Staff/${staff_id}` : '') + `/Job/${job_id}/Stage/${job_stage_type}/${job_stage_id}/CandidateSummary`;

  return getURLPromise(url);
};

const getCandidate = (job_id, job_stage_type, job_stage_id, candidate_id, staff_id) => {
    const url = (staff_id ? `/Staff/${staff_id}` : '') + `/Job/${job_id}/Stage/${job_stage_type}/${job_stage_id}/Candidate/${candidate_id}`;

  return getURLPromise(url);
};

const getCandidateWithJob = (token, job_id, job_stage_type, job_stage_id, candidate_id) => {
  const authClient = getClient();
  const url = (token ? `/Share/${token}` : '') + `/Job/${job_id}/Stage/${job_stage_type}/${job_stage_id}/Candidate/${candidate_id}`;

  return new Promise((resolve) => {
    authClient.get(url).then((response) => {
      resolve(response.data);
    })
        .catch((error) => {
          resolve({error});
        });
  });
};

const getCandidateActivity = (job_id, job_stage_type, job_stage_id, candidate_id, staff_id) => {
  const url = (staff_id ? `/Staff/${staff_id}` : '') + `/Job/${job_id}/Stage/${job_stage_type}/${job_stage_id}/Candidate/${candidate_id}/Support`;

  return getURLPromise(url);
};

const updateEvaluation = (staff_id, job_id, job_stage_type, job_stage_id, candidate_id, payload) => {
  return new Promise((resolve) => {
    client.put(`/Staff/${staff_id}/Job/${job_id}/Stage/${job_stage_type}/${job_stage_id}/Candidate/${candidate_id}/Evaluation`, payload).then((response) => {
      resolve({...response.data, success: true});
    })
    .catch((error) => {
      resolve({error});
    });
  });
};

const updateCandidateDecisions = (job_id, job_stage_type, job_stage_id, candidate_ids, decision) => {
  return new Promise((resolve) => {
    client.put(`/Job/${job_id}/Stage/${job_stage_type}/${job_stage_id}/CandidateDecisions`, { candidate_ids, decision }).then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      resolve({error});
    });
  });
};

const unsubmitEvaluation = (staff_id, job_id, job_stage_type, job_stage_id, candidate_id) => {
  return new Promise((resolve) => {
    client.post(`/Staff/${staff_id}/Job/${job_id}/Stage/${job_stage_type}/${job_stage_id}/Candidate/${candidate_id}/Evaluation/unsubmit`, {}).then((response) => {
      resolve(response.data);
    });
  });
};

const getWorldManagerJob = (jobId, fromDate, toDate, positionGroupId) => {
  const url = `/Job/${jobId}/worldmanager?fromDate=${fromDate}&toDate=${toDate}&positionGroupId=${positionGroupId}`;

  return new Promise((resolve) => {
    client.get(url).then((response) => {
      resolve(response.data);
    });
  });
};

const cloneJob = (jobId) => {
  return new Promise((resolve) => {
    client.post(`/Job/${jobId}/clone`).then((response) => {
      resolve(response.data);
    });
  });
};

const applyJobAsTemplate = (templateJobId, jobId) => {
  return new Promise((resolve) => {
    client.post(`/Job/${jobId}/ApplyJobAsTemplate`, {"template_job_id": templateJobId}).then((response) => {
      resolve(response.data);
    });
  });
};

const setJobStatus = (jobId, status) => {
  return new Promise((resolve) => {
    const data = {status};
    client.put(`/Job/${jobId}/status`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve({error});
      });
  });
};

const getJobsSummary = () => {
  return new Promise((resolve) => {
    client.get('/JobSummary').then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      resolve({error});
    });
  });
};

const getJobTemplates = () => {
  return new Promise((resolve) => {
    client.get('/JobTemplates').then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      resolve({error});
    });
  });
};

const setJobAsTemplate = (jobId, isTemplate = true) => {
  return new Promise((resolve) => {
    client.put(`/Job/${jobId}/MarkTemplate`, { "isTemplate": isTemplate }).then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      resolve({error});
    });
  });
};

const getJobsForEvaluator = (evaluatorId) => {
  return new Promise((resolve) => {
    client.get(`/Staff/${evaluatorId}/JobSummary`)
    .then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      resolve({error});
    });
  });
};

const getLibrary = () => {
  return new Promise((resolve) => {
    client.get('/Library').then((response) => {
      resolve(response.data);
    });
  });
};

const addLibraryItem = (type, item) => {
  const typeCapitalized = type.charAt(0).toUpperCase() + type.slice(1);

  return new Promise((resolve) => {
    client.post(`/Library/${typeCapitalized}`, item).then((response) => {
      resolve(response);
    });
  });
};

const editLibraryItem = (type, questionId, item) => {
  const typeCapitalized = type.charAt(0).toUpperCase() + type.slice(1);

  return new Promise((resolve) => {
    client.put(`/Library/${typeCapitalized}/${questionId}`, item).then((response) => {
      resolve(response);
    });
  });
};

const removeLibraryItem = (type, id) => {
  const typeCapitalized = type.charAt(0).toUpperCase() + type.slice(1);

  return new Promise((resolve) => {
    client.delete(`/Library/${typeCapitalized}/${id}`).then((response) => {
      resolve(response);
    });
  });
};

const addVideoToLibraryItem = async (type, id, videos) => {
  const typeCapitalized = type.charAt(0).toUpperCase() + type.slice(1);

  return new Promise((resolve) => {
    client.post(`/Library/${typeCapitalized}/${id}/Video`, videos).then((response) => {
      resolve(response);
    });
  });
};

const removeVideoFromLibraryItem = (type, id, video_id) => {
  const typeCapitalized = type.charAt(0).toUpperCase() + type.slice(1);

  return new Promise((resolve) => {
    client.delete(`/Library/${typeCapitalized}/${id}/Video/${video_id}`).then((response) => {
      resolve(response);
    });
  });
};

const addAttachmentToLibraryItem = async (type, id, attachment) => {
  const typeCapitalized = type.charAt(0).toUpperCase() + type.slice(1);

  return new Promise((resolve) => {
    client.post(`/Library/${typeCapitalized}/${id}/Attachment`, attachment).then((response) => {
      resolve(response);
    });
  });
};

const removeAttachmentFromLibraryItem = (type, id, attachmentId) => {
  const typeCapitalized = type.charAt(0).toUpperCase() + type.slice(1);

  return new Promise((resolve) => {
    client.delete(`/Library/${typeCapitalized}/${id}/Attachment/${attachmentId}`).then((response) => {
      resolve(response);
    });
  });
};

const getInterviewTemplates = () => {
  return new Promise((resolve) => {
    getClient(true).get(`/interview-templates`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          resolve({error});
        });
  });
};

const getInterviewTemplate = (id) => {
  return new Promise((resolve) => {
    getClient(true, 'v2').get(`/interview-template/${id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          resolve({error});
        });
  });
};

const createInterviewTemplate = (payload) => {
  return new Promise((resolve) => {
    getClient(true).post(`/interview-templates`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          resolve({error});
        });
  });
}

const updateInterviewTemplate = (interview_template_id, payload) => {
  return new Promise((resolve) => {
    getClient(true).put(`/interview-template/${interview_template_id}`, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        resolve({error});
      });
  });
}

const removeInterviewTemplate = (interview_template_id) => {
  return new Promise((resolve) => {
    getClient(true).delete(`/interview-template/${interview_template_id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          resolve({error});
        });
  });
}

// at the moment it's the same as getCandidate, but ultimately we need to just get interview_steps
// without needing to go through the candidate
const getInterviewSteps = (job_id, job_stage_type, job_stage_id, candidate_id, staff_id) => {
  const url = (staff_id ? `/Staff/${staff_id}` : '') + `/Job/${job_id}/Stage/${job_stage_type}/${job_stage_id}/Candidate/${candidate_id}`;

  return new Promise((resolve) => {
    client.get(url).then((response) => {
      resolve(response.data?.interview_steps);
    })
        .catch((error) => {
          resolve({error});
        });
  });
};

const getSMSTemplates = () => {
  return new Promise((resolve) => {
    client.get(`/SMSTemplates`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        resolve({error});
      });
  });
};

const createSMSTemplate = (payload) => {
  return new Promise((resolve) => {
    client.post(`/SMSTemplates`, payload)
      .then((response) => {
        console.log("response:", response);
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        resolve({error});
      })
  });
};

const updateSMSTemplate = (sms_template_id, payload) => {
  return new Promise((resolve) => {
    client.put(`/SMSTemplates/${sms_template_id}`, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        resolve({error});
      })
  });
};

const removeSMSTemplate = (sms_template_id) => {
  return new Promise((resolve) => {
    client.delete(`/SMSTemplates/${sms_template_id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        resolve({error});
      })
  });
}

const getReportCandidateEvaluationsUrl = (job_id, job_stage_type, job_stage_id) => {
  return baseURL + `/Job/${job_id}/Stage/${job_stage_type}/${job_stage_id}/reports/candidates-evaluations/download`;
};

const getReportingUsageUrl = (allTenants = false) => {
  return baseURL + `/Reporting/Usage` + (allTenants ? "/Environment" : '');
};

const getIntegrations = () => {
  return new Promise((resolve) => {
    client.get('/Integration').then((response) => {
      resolve(response.data);
    });
  });
};

const createPendingIntegration = ({ provider }) => {
  return new Promise((resolve) => {
    const data = {
      provider
    };
    client.post('/Integration', data).then(response => {
      resolve(response.data);
    });
  });
};

const deleteIntegration = ({ provider }) => {
  return new Promise((resolve) => {
    client.delete(`/Integration/${provider}`).then(response => {
      resolve(response.data);
    });
  });
};

const createStaff = (formData) => {
  return new Promise((resolve) => {
    client.post(`/Staff`, formData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        resolve({error});
      });
  });
};

const getStaff = (id = null) => {
  return new Promise((resolve) => {
    const url = '/Staff' + (id ? `/${id}` : '');
    client.get(url).then((response) => {
      resolve(response.data);
    });
  });
};

const updateStaff = (staffId, formData) => {
    return new Promise((resolve) => {
        client.post(`/Staff/${staffId}`, formData)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            console.log(error);
            resolve({error});
          })
        ;
    });
};

const deleteStaff = (staffId) => {
  return new Promise((resolve) => {
    client.delete(`/Staff/${staffId}`).then((response) => {
      resolve(response);
    });
  });
};

const sendInvitationEmail = (staffId) => {
  return new Promise((resolve) => {
    client.post(`/Staff/${staffId}/SendInvitationEmail`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve({error});
      });
  });
}

const sendLoginEmail = (staffId) => {
  return new Promise((resolve) => {
    client.post(`/Staff/${staffId}/SendLoginEmail`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          resolve({error});
        });
  });
}

const sendEvaluationEmail = (staff_ids, deep_link_path = '', context = 'my-jobs') => {
  const payload = {
    staff_ids,
    deep_link_path,
    context
  };

  return new Promise((resolve) => {
    client.post(`/Staff/SendLinkEmail`, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          resolve({error});
        });
  })
};

const saveJob = (job) => {
  return new Promise((resolve) => {
    client.post(`/Job?new=true`, job)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve({error});
      });
  });
};

const updateJob = (job) => {
  return new Promise((resolve) => {
    client.put(`/Job/${job.job_id}?new=true`, job)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve({error});
        });
  });
};

const updateJobStage = (job_id, job_stage_type, job_stage_id, payload) => {
  return new Promise((resolve) => {
    client.put(`/Job/${job_id}/Stage/${job_stage_type}/${job_stage_id}`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve({error});
        });
  });
};

const deleteJob = (job) => {
  return new Promise((resolve) => {
    client.delete(`/Job/${job.job_id}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve({error});
        });
  });
};

const shareCandidateResponse = (jobId, jobStageType, jobStageId, data) => {
  return new Promise((resolve) => {
    client.post(`/Job/${jobId}/Stage/${jobStageType}/${jobStageId}/CandidateShare`, data)
        .then((response) => {
          resolve(response.data || {success: true});
        })
        .catch((error) => {
          resolve({error});
        });
  });
};

const shareCandidateResponseLink = (jobId, jobStageType, jobStageId, data) => {
  return new Promise((resolve) => {
    client.post(`/Job/${jobId}/Stage/${jobStageType}/${jobStageId}/CandidateShareLink`, data)
        .then((response) => {
          resolve(response.data || {success: true});
        })
        .catch((error) => {
          resolve({error});
        });
  });
};

const updateCandidateInterviewOverrides = async (job_id, job_stage_type, job_stage_id, candidate_ids, data) => {
  return new Promise((resolve) => {
    client.put(`/Job/${job_id}/Stage/${job_stage_type}/${job_stage_id}/CandidateInterviewOverrides`, { ...data, candidate_ids })
        .then((response) => {
          resolve(response.data || {success: true});
        })
        .catch((error) => {
            resolve({error});
        });
  });
};

const resetPassword = (data) => {
  setBaseURL();

  return new Promise((resolve) => {
    client.post(`/passwd`, data)
      .then((response) => {
        setBaseURL('/api/' + response.data.aviia_endpoint);

        resolve(response);
      })
      .catch((error) => {
        console.log(error);
        resolve({error});
      })
  });
};

const sendPasswordResetEmail = (data) => {
  setBaseURL();

  return new Promise((resolve) => {
    client.post(`/send-password-reset`, data)
      .then(() => {
        resolve({success: true});
      })
      .catch((error) => {
        console.log(error);
        resolve({error});
      })
  })
};

const resendInvites = async (job_id, job_stage_type, job_stage_id, candidate_ids) => {
  return new Promise((resolve) => {
    client.post(`/Job/${job_id}/Stage/${job_stage_type}/${job_stage_id}/ResendInvites`, { candidate_ids })
        .then(() => {
          resolve({status: eResponseStatus.SUCCESS, timestamp: new Date()});
        })
        .catch(() => {
          resolve({status: eResponseStatus.ERROR, timestamp: new Date()});
        });
  });
};

const inviteJobCandidates = async (jobId) => {
  try {
    await client.post(`Job/${jobId}/invite`, {});
    return eResponseStatus.SUCCESS;
  } catch(err) {
    return eResponseStatus.ERROR;
  }
};

const getSearchResults = async (query) => {
  return new Promise((resolve) => {
    client.get(`/search?q=${query}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve({error});
      });
  });
};

const serverLogout = () => {
  const logoutInstance = getClient();

  return new Promise((resolve) => {
    logoutInstance.post(`/logout`)
    .then((response) => {
      resolve({...response.data, success: true});
    })
    .catch(() => {
      resolve({});
    });
  });
};

const addCandidates = (job_id, job_stage_type, job_stage_id, candidates) => {
  // returns the list of newly added candidates
  return new Promise((resolve) => {
    client.post(`/Job/${job_id}/Stage/${job_stage_type}/${job_stage_id}/Candidates`, candidates)
        .then((response) => {
          resolve(response.data || {success: true});
        })
        .catch((error) => {
          resolve({error});
        });
  });
};

const editCandidate = (job_id, job_stage_type, job_stage_id, candidate_id, payload) => {
  return new Promise((resolve) => {
    client.put(`/Job/${job_id}/Stage/${job_stage_type}/${job_stage_id}/Candidate/${candidate_id}`, payload)
        .then((response) => {
          resolve(response.data || {success: true});
        })
        .catch((error) => {
          resolve({error});
        });
  });
}

const deleteCandidate = (job_id, job_stage_type, job_stage_id, candidate_id, payload) => {
  return new Promise((resolve) => {
    client.delete(`/Job/${job_id}/Stage/${job_stage_type}/${job_stage_id}/Candidate/${candidate_id}`, payload)
        .then((response) => {
          resolve(response.data || {success: true});
        })
        .catch((error) => {
          resolve({error});
        });
  });
};

const retakeInterview = async (job_id, job_stage_type, job_stage_id, candidate_id, selectedJobInterviewStepIds, retakeDeadline) => {
  return new Promise((resolve) => {
    client.post(`/Job/${job_id}/Stage/${job_stage_type}/${job_stage_id}/Candidate/${candidate_id}/Retake`,
                {
                  job_interview_step_ids: selectedJobInterviewStepIds,
                  deadline              : retakeDeadline
                })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        resolve({error});
      });
  });
};

const getBrandings = () => {
  return new Promise((resolve) => {
    client.get(`/Branding`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve({error});
        });
  });
};

const addBranding = (data) => {
  return new Promise((resolve) => {
    client.post(`/Branding`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve({error});
        })
  })
};

const cloneBranding = (id, data) => {
  return new Promise((resolve) => {
    client.post(`/Branding/${id}/clone`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve({error});
        })
  })
};

const updateBranding = (id, data) => {
  return new Promise((resolve) => {
    client.post(`/Branding/${id}`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve({error});
        })
  })
};

const deleteBranding = (id) => {
  return new Promise((resolve) => {
    client.delete(`/Branding/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          resolve({error});
        })
  });
};

const getEmailTemplates = () => {
  return new Promise((resolve) => {
    client.get(`/EmailTemplates`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        resolve([]);
      });
  });
};

const createEmailTemplate = (payload) => {
  return new Promise((resolve) => {
    client.post(`/EmailTemplates`, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        resolve({error});
      })
  });
};

const updateEmailTemplate = (email_template_id, payload) => {
  return new Promise((resolve) => {
    client.put(`/EmailTemplates/${email_template_id}`, payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          resolve({error});
        });
  });
};

const removeEmailTemplate = (email_template_id) => {
  return new Promise((resolve) => {
    client.delete(`/EmailTemplates/${email_template_id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          resolve({error});
        });
  });
}

const previewEmail = (job, candidate_interview, subject, content, header_banner_img_src, footer_banner_img_src) => {
  return new Promise((resolve) => {
    client.post('/EmailPreview', {
      job,
      candidate_interview,
      subject,
      content,
      header_banner_img_src,
      footer_banner_img_src
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        resolve({error});
      });
  });
};

const createInterviewStage = (job_id, job_stage = eJobStageTypes.ONE_WAY) => {
  return new Promise((resolve) => {
    client.post(`/Job/${job_id}/Stage/${job_stage}`, {})
        .then((response) => {
          resolve(response.data || {success: true});
        })
        .catch((error) => {
          resolve({error});
        });
  });
};

const addCandidateLiveInterviewDetails = (job_id, job_stage_id, data) => {
  return new Promise((resolve) => {
    client.post(`/Job/${job_id}/Stage/live/${job_stage_id}/Candidate`, data)
        .then((response) => {
          resolve(response.data || {success: true});
        })
        .catch((error) => {
          resolve({error});
        });
  });
};

const updateCandidateLiveInterviewDetails = (job_id, job_stage_id, candidate_id, data) => {
  return new Promise((resolve) => {
    client.put(`/Job/${job_id}/Stage/live/${job_stage_id}/Candidate/${candidate_id}`, data)
        .then((response) => {
          resolve(response.data || {success: true});
        })
        .catch((error) => {
          resolve({error});
        });
  });
};

const createPageupPackage = (jobId) => {
  return new Promise((resolve) => {
    client.put(`/Job/${jobId}/CreatePageupPackage`, { "placeholder_payload": true }).then((response) => {
      resolve(response.data);
    })
    .catch((error) => {
      resolve({error});
    });
  });
};

const getTimezones = () => {
  return new Promise((resolve) => {
    client.get(`/Timezone`)
        .then((response) => {
          resolve(response.data || {success: true});
        })
        .catch((error) => {
          resolve({error});
        });
  });
}

const createTenant = (formData) => {
  return new Promise((resolve) => {
    client.post(`/Tenant`, formData)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          const message = get(error.response, ["data", "message"]);
          resolve({error: message || error});
        });
  });
}

const getTenants = () => {
  return new Promise((resolve) => {
    client.get(`/Tenant`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve({error});
        });
  });
}

const getTenant = (tenantId) => {
  return new Promise((resolve) => {
    client.get(`/Tenant/${tenantId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve({error});
        });
  });
}

const updateTenant = (tenantId, formData) => {
  return new Promise((resolve) => {
    client.post(`/Tenant/${tenantId}`, formData)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          const message = get(error.response, ["data", "message"]);
          resolve({error: message || error});
        });
  });
}

const uploadLibraryFile = (formData) => {
  return new Promise((resolve) => {
    client.post(`/LibraryFiles`, formData)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve({error});
        });
  });
}

export {
  getLoginModes,
  initiateStaffLogin,
  getAuthenticate,
  postAuthenticate,
  authenticateLoginLink,
  sendVerificationCode,
  setAlcamiAuthToken,
  setBaseURL,
  getCompanyDetails,
  getIntegrations, createPendingIntegration, deleteIntegration,
  getJobSummary,
  getWorldManagerJob,
  cloneJob,
  applyJobAsTemplate,
  setJobStatus,
  getJobsSummary,
  getJobTemplates,
  setJobAsTemplate,
  getJobsForEvaluator,

  getJobStageCandidateSummary,
  getCandidate,
  getCandidateWithJob,
  getCandidateActivity,
  // getEvaluation,
  // getEvaluations,

  updateEvaluation,

  updateCandidateDecisions,
  unsubmitEvaluation,

  getLibrary,
  addLibraryItem,
  editLibraryItem,
  removeLibraryItem,

  addVideoToLibraryItem,
  removeVideoFromLibraryItem,

  addAttachmentToLibraryItem,
  removeAttachmentFromLibraryItem,

  getReportCandidateEvaluationsUrl,
  getReportingUsageUrl,
  resetPassword,

  saveJob,
  updateJob,
  updateJobStage,
  deleteJob,

  createStaff,
  getStaff,
  updateStaff,
  deleteStaff,
  sendInvitationEmail,
  sendLoginEmail,
  sendEvaluationEmail,

  shareCandidateResponse,
  shareCandidateResponseLink,
  resendInvites,
  sendPasswordResetEmail,
  addCandidates,
  editCandidate,
  deleteCandidate,
  updateCandidateInterviewOverrides,

  inviteJobCandidates,

  getSearchResults,

  serverLogout,
  retakeInterview,

  addBranding,
  cloneBranding,
  getBrandings,
  updateBranding,
  deleteBranding,

  getInterviewTemplates,
  getInterviewTemplate,
  createInterviewTemplate,
  updateInterviewTemplate,
  removeInterviewTemplate,

  getInterviewSteps,

  getSMSTemplates,
  createSMSTemplate,
  updateSMSTemplate,
  removeSMSTemplate,

  getEmailTemplates,
  createEmailTemplate,
  updateEmailTemplate,
  removeEmailTemplate,

  previewEmail,

  createInterviewStage,
  addCandidateLiveInterviewDetails,
  updateCandidateLiveInterviewDetails,

  createPageupPackage,

  getTimezones,
  createTenant,
  getTenants,
  getTenant,
  updateTenant,

  uploadLibraryFile
};
